import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { doLogout } from "../../App";
import { useGetUserInfo } from "../../pages/profile/graphql/useGetUserInfo";
import { Button, Center, Drawer, Flex, SimpleLoader, Typography } from "../UI";
import { Absolute } from "../UI/Absolute";
import { setCurrentUser } from "../slices/userSettings";
import { Menu } from "./Menu";

export default function MasterLayout({ local }: { local: boolean }) {
  const {
    loading: loadingGetUserInfo,
    error,
    called,
    userInfo,
  } = useGetUserInfo();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      dispatch(
        setCurrentUser({
          firstName: userInfo?.identity?.firstName,
          lastName: userInfo?.identity?.lastName,
          locale: userInfo?.identity?.locale,
          email: userInfo?.identity?.email,
          cpoUid: userInfo?.identity?.cpoUid,
        })
      );
    }

    return () => {
      dispatch(setCurrentUser(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userInfo]);

  return (
    <Flex width="100%" height="100%" flexDirection="row" position="relative">
      <Drawer>
        <Menu local={local} />
      </Drawer>
      <Flex flex={1}>
        {loadingGetUserInfo ? (
          <Center>
            <SimpleLoader />
          </Center>
        ) : (
          <>
            <Absolute top={0} right={0} zIndex={10000000}>
              <Flex mr={3} flexDirection="row" alignItems="center">
                {userInfo ? (
                  <>
                    <Link style={{ textDecoration: "none" }} to="/profile">
                      <Typography>
                        {" "}
                        {t("hello")}, {userInfo?.identity?.firstName}
                      </Typography>
                    </Link>
                    <Button
                      color="red"
                      display="flex"
                      variant="danger"
                      link
                      onClick={doLogout}
                    >
                      {t("logout")}
                    </Button>
                  </>
                ) : null}
              </Flex>
            </Absolute>
            <Outlet
              context={{
                currentUser: {
                  firstName: userInfo?.identity?.firstName,
                  lastName: userInfo?.identity?.lastName,
                  locale: userInfo?.identity?.locale,
                  email: userInfo?.identity?.email,
                  cpoUid: userInfo?.identity?.cpoUid,
                },
                local: local,
              }}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
}
