import { Marker, OverlayView, useGoogleMap } from "@react-google-maps/api";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Typography } from "../../../../../shared/UI";
import MarkerLocationBasic from "../../../../../shared/assets/svg/MarkerLocationBasic";
import MarkerOperationPublic from "../../../../../shared/assets/svg/MarkerOperationPublic";
import {
  BecEvc,
  useSiteSelectionEvcDataContext,
} from "../../../slices/EvcDataContextProvider";
import { buildSvgString } from "../../../utils/buildSVG";
import { InfoOverlay } from "../map-components/NewLocationTooltip";

const getOperationPublicMarker = (cpTot: number, evcModel: "AC" | "DC") => (
  <MarkerOperationPublic
    fontFamily="sans-serif"
    cpTot={cpTot}
    evcModel={evcModel}
  />
);

const getBasicLocationMarker = (cpTot: number, evcModel: "AC" | "DC") => (
  <MarkerLocationBasic
    fontFamily="sans-serif"
    cpTot={cpTot}
    evcModel={evcModel}
    fill="#FFC700"
    stroke="#122967"
  />
);

export const BecEvcsLayer = () => {
  const { t } = useTranslation();
  const { filteredBecEvcs, showBecEvcs } = useSiteSelectionEvcDataContext();
  const map = useGoogleMap();

  const [selectedEvc, setSelectedEvc] = useState<BecEvc | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const isOnGround = useCallback((evc: BecEvc) => {
    return ["Operation", "Commissioning"].includes(evc.projectPhase);
  }, []);

  return (
    <>
      {filteredBecEvcs
        ?.filter((evc) => {
          return (
            (evc.evcModelChargeMode === "DC" && showBecEvcs.dc) ||
            (evc.evcModelChargeMode === "AC" && showBecEvcs.ac)
          );
        })
        .map((evc) => {
          const cpTot = evc.cpAc + evc.cpDc;
          const evcModel = evc.evcModelChargeMode;

          const markerComponent = isOnGround(evc)
            ? getOperationPublicMarker(cpTot, evcModel)
            : getBasicLocationMarker(cpTot, evcModel);

          const svgString = buildSvgString(markerComponent);

          return (
            <Marker
              key={evc.evcUuid}
              position={{
                lat: evc.latitude,
                lng: evc.longitude,
              }}
              icon={{
                url: svgString,
                scaledSize: new google.maps.Size(46, 49),
                fillColor: isOnGround(evc) ? "#FFC700" : "#FFF",
              }}
              // icon={markerDataUri}
              onClick={() => {
                setSelectedEvc(evc);
                setIsOpen(true);
              }}
              zIndex={3}
            >
              {selectedEvc?.evcUuid === evc.evcUuid && (
                <OverlayView
                  position={{
                    lat: evc.latitude,
                    lng: evc.longitude,
                  }}
                  zIndex={100000}
                  mapPaneName="overlayMouseTarget"
                  getPixelPositionOffset={() => ({
                    x: 0,
                    y: -15,
                  })}
                  onLoad={() => {
                    map?.setCenter(map?.getCenter()!);
                    setTimeout(() => {
                      setIsOpen(true);
                    }, 10);
                  }}
                  onUnmount={() => {
                    setIsOpen(false);
                  }}
                >
                  {isOpen ? (
                    <InfoOverlay
                      width={200}
                      title={
                        !isOnGround(evc)
                          ? t("siteSelection.becEvcsLayer.workInProgress")
                          : evc.projectPhase
                      }
                      onClose={() => {
                        setSelectedEvc(null);
                      }}
                    >
                      <Flex padding={"4px"} color="black">
                        <Typography marginTop={"5px"} fontWeight={700}>
                          {t("siteSelection.becEvcsLayer.activationDate")}
                        </Typography>
                        <Typography variant={"body10"}>
                          {evc.installationCod.toLocaleString("it-IT", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })}
                        </Typography>

                        <Typography marginTop={"5px"} fontWeight={700}>
                          {t(
                            "siteSelection.becEvcsLayer.avgSessionPerMonthSince"
                          )}
                        </Typography>
                        <Typography>
                          {Math.floor(evc.avgMonthlyNSessions ?? 0)}
                        </Typography>

                        <Typography marginTop={"5px"} fontWeight={700}>
                          {t(
                            "siteSelection.becEvcsLayer.avgkWhPerMonthDispensed"
                          )}
                        </Typography>
                        <Typography>
                          {Math.round(evc.avgMonthlyKwh ?? 0)} kWh
                        </Typography>

                        <Flex marginTop={"5px"} flexDirection="row">
                          <Typography fontWeight={700}>Tipo:</Typography>
                          <Typography>{evc.evcModelChargeMode}</Typography>
                        </Flex>

                        <Typography marginTop={"5px"} fontWeight={700}>
                          {t("siteSelection.general.where")}
                        </Typography>
                        <Typography>{evc.locationAddress ?? "-"}</Typography>
                      </Flex>
                    </InfoOverlay>
                  ) : (
                    <></>
                  )}
                </OverlayView>
              )}
            </Marker>
          );
        })}
    </>
  );
};
