/** @format */

import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  Button,
  Flex,
  Input,
  InputLabel,
  Switch,
  Typography,
} from "../../../../../shared/UI";
import { Close } from "../../../../../shared/assets/svg";
import { useGetTokens } from "../../../gql/queries/useGetTokens";
import {
  MeterValueConfig,
  setCustomMeterValue,
  setDefaultRFID,
  setSendStatusNotificationToConnector0,
  setSendStatusNotificationToOtherConnectors,
} from "./siimulator.slice";
import { yupResolver } from "@hookform/resolvers/yup";
import { Token } from "../../../../../shared/gql/generated/graphql";

type SettingsProps = {
  defaultRFID: Token;
  customMeterValue: MeterValueConfig | null;
  sendStatusNotificationToConnector0: boolean;
  sendStatusNotificationToOtherConnectors: boolean;
  ninaStatus: "OPEN" | "CLOSED" | "CONNECTING" | "CLOSING" | undefined;
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Settings: React.FC<SettingsProps> = ({
  defaultRFID,
  customMeterValue,
  sendStatusNotificationToConnector0,
  sendStatusNotificationToOtherConnectors,
  ninaStatus,
  setShowSettings,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(settingsSchema),
  });
  const { getTokens } = useGetTokens();
  const dispatch = useDispatch();
  const isActiveConnected = ninaStatus === "OPEN";

  console.log(errors);
  useEffect(() => {
    reset({
      sendStatusToConnector0: sendStatusNotificationToConnector0,
      sendStatusToOthers: sendStatusNotificationToOtherConnectors,
      metervalueValue: customMeterValue?.value,
      metervalueTime: customMeterValue?.time,
      idTag: defaultRFID
        ? {
            label: [
              <Typography fontWeight={"bold"} variant={"title20"}>
                {defaultRFID?.label}
              </Typography>,
              <Typography variant={"body10"}>
                {defaultRFID?.PlatformParty?.name}
              </Typography>,
              <Typography variant={"caption10"}>
                {defaultRFID?.origin}
              </Typography>,
            ],
            value: defaultRFID,
          }
        : null,
      isCustomMeterValue: customMeterValue ? true : false,
    });
  }, [
    defaultRFID,
    reset,
    sendStatusNotificationToOtherConnectors,
    sendStatusNotificationToConnector0,
    customMeterValue,
  ]);

  const loadOptions = (inputValue: string) => {
    if (inputValue.length > 3) {
      return new Promise<Token[]>((resolve) => {
        getTokens({
          variables: {
            pagination: {
              limit: 10,
              page: 1,
            },
            filter: {
              text: inputValue,
            },
          },
        }).then((res) => {
          const options = res?.data?.Tokens?.results?.map(
            (token: any, index: number) => ({
              label: [
                <Typography fontWeight={"bold"} variant={"title20"}>
                  {token?.label}
                </Typography>,
                <Typography variant={"body10"}>
                  {token?.PlatformParty?.name}
                </Typography>,
                <Typography variant={"caption10"}>{token?.origin}</Typography>,
              ],
              value: token,
            })
          );
          resolve(options);
        });
      });
    }
  };
  const handleSave = (formData: any) => {
    const {
      metervalueValue,
      metervalueTime,
      idTag,
      isCustomMeterValue,
      sendStatusToOthers,
      sendStatusToConnector0,
    } = formData;
    const metervalue = {
      time: metervalueTime,
      value: metervalueValue,
    };
    if (sendStatusToOthers !== sendStatusNotificationToOtherConnectors) {
      dispatch(setSendStatusNotificationToOtherConnectors(sendStatusToOthers));
    }
    if (sendStatusToConnector0 !== sendStatusNotificationToConnector0) {
      dispatch(setSendStatusNotificationToConnector0(sendStatusToConnector0));
    }
    if (idTag !== defaultRFID) {
      dispatch(setDefaultRFID(idTag ? idTag.value : null));
    }
    if (metervalue !== customMeterValue) {
      if (isCustomMeterValue) {
        dispatch(setCustomMeterValue(metervalue));
      } else {
        dispatch(setCustomMeterValue(null));
      }
    }
    toast.success("saved !");
  };
  const defaultOptions = useMemo(
    () => [
      {
        label: [
          <Typography fontWeight={"bold"} variant={"title20"}>
            {defaultRFID?.label}
          </Typography>,
          <Typography variant={"body10"}>
            {defaultRFID?.PlatformParty?.name}
          </Typography>,
          <Typography variant={"caption10"}>{defaultRFID?.origin}</Typography>,
        ],

        value: defaultRFID,
      },
    ],
    [defaultRFID]
  );

  return (
    <Flex
      position={"absolute"}
      left={0}
      height="100%"
      minWidth={450}
      width={"80%"}
      zIndex={200}
      bottom={0}
      bg="white"
      border="1px solid #eee"
      p={2}
      // ref={settingsRef}
    >
      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <Typography variant={"title30"}>Settings</Typography>
        <Button
          withBorder={false}
          variant="gray"
          fixedSize
          $fill={false}
          onClick={() => setShowSettings(false)}
        >
          <Close size="5" />
        </Button>
      </Flex>
      <Flex height="2px" borderBottom={"2px solid #eee"} pt={2} />
      <form onSubmit={handleSubmit(handleSave)}>
        <Flex style={{ gap: 20 }} flex={1} p={2} pt={4}>
          <Flex flexDirection={"row"} flexWrap={"wrap"}>
            <Flex flex={1}>
              <InputLabel>Send Status Notification to connector N 0</InputLabel>
              <Switch
                disabled={isActiveConnected}
                {...register("sendStatusToConnector0")}
              />
            </Flex>
            <Flex flex={1}>
              <InputLabel>
                Send Status Notification to other connectors
              </InputLabel>
              <Switch
                variant="gray"
                disabled={isActiveConnected}
                {...register("sendStatusToOthers")}
              />
            </Flex>
          </Flex>

          <Flex>
            <InputLabel color="gray">Custom meterValue</InputLabel>
            <Switch
              disabled={isActiveConnected}
              {...register("isCustomMeterValue")}
            />
          </Flex>
          {watch("isCustomMeterValue") && (
            <Flex
              flexDirection={"row"}
              style={{ gap: 10 }}
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
              fullSize
            >
              <Typography color="gray" flexShrink={0} fontWeight={"bold"}>
                {t("Send")}
              </Typography>
              <Flex flex={1}>
                <Input
                  type="number"
                  {...register("metervalueValue")}
                  suffix={"Wh"}
                  min={0}
                  variant="gray"
                  disabled={isActiveConnected}
                  suffixProps={{
                    alignItems: "center",
                    justifyContent: "center",
                    px: 2,
                    bg: "gray",
                    color: "white",
                  }}
                  error={errors?.metervalueValue?.message}
                />
              </Flex>
              <Typography color="gray" fontWeight={"bold"} flexShrink={0}>
                {t("Every")}
              </Typography>
              <Flex flex={1}>
                <Input
                  type="number"
                  {...register("metervalueTime")}
                  suffix={"minute"}
                  min={0}
                  disabled={isActiveConnected}
                  variant="gray"
                  suffixProps={{
                    alignItems: "center",
                    justifyContent: "center",
                    px: 2,
                    bg: "gray",
                    color: "white",
                  }}
                  error={errors?.metervalueTime?.message}
                />
              </Flex>
            </Flex>
          )}

          <Flex>
            <InputLabel color="primary">Default RFID</InputLabel>
            <Flex fullSize flexDirection={"row"} style={{ gap: 10 }}>
              <Flex flex={1}>
                <Controller
                  name="idTag"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      styles={{
                        option: (styles) => {
                          return {
                            ...styles,
                            flexDirection: "column",
                            display: "flex",
                            gap: 5,
                          };
                        },
                        singleValue: (styles) => {
                          return {
                            ...styles,
                            flexDirection: "column",
                            display: "flex",
                            flexShrink: 0,
                            gap: 2,
                          };
                        },
                        placeholder: (styles) => {
                          return {
                            ...styles,
                            flexDirection: "column",
                            display: "flex",
                            flexShrink: 0,
                            gap: 2,
                          };
                        },
                      }}
                      menuPosition="fixed"
                      hideSelectedOptions={false}
                      defaultOptions={defaultRFID ? defaultOptions : []}
                      isClearable
                      cacheOptions
                      loadOptions={loadOptions}
                      placeholder={"Select Token (min 4 characters) "}
                      escapeClearsValue={false}
                      isDisabled={isActiveConnected}
                      {...field}
                    />
                  )}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex mt={3} width={150}>
            <Button
              $size="large"
              onClick={handleSubmit(handleSave)}
              disabled={isActiveConnected}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};

const settingsSchema = yup.object().shape(
  {
    isCustomMeterValue: yup.boolean().optional(),
    metervalueTime: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when("isCustomMeterValue", {
        is: true,
        then: (schema) => schema.required().typeError("required"),
      }),
    metervalueValue: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()

      .when("isCustomMeterValue", {
        is: true,
        then: (schema) => schema.required().typeError("required"),
      }),
  },
  [["metervalue", "metervalue"]]
);
