import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  Flex,
  Select,
  Typography,
} from "../../../../../../shared/UI";
import { useGetTokens } from "../../../../gql/queries/useGetTokens";
import debounce from "debounce";
import { useEffect, useState } from "react";
import { usePublishRemoteReservationRequest } from "../../../../gql/mutations/usePublishRemoteReservationRequest";
import { useOutletContext } from "react-router-dom";
import { Evse } from "@bepower/greta-types";
import { toast } from "react-toastify";
import { t } from "i18next";


export const ReservationEvseCommand = () => {
  const { control, watch } = useForm();
  const [searchValue, setSearchValue] = useState<string>("");
  const [isPublishRemoteReservation, setIsPublishRemoteReservation] =
    useState<boolean>(false);
  const { evse }: { evse: Evse } = useOutletContext();
  const {
    tokens,
    count,
    loading: loadingGetTokens,
    getTokens,
  } = useGetTokens();
  const { PublishRemoteReservationRequest, loading } =
    usePublishRemoteReservationRequest();
  useEffect(() => {
    if (searchValue.length > 3) {
      getTokens({
        variables: {
          pagination: {
            limit: 10,
            page: 1,
          },
          filter: {
            text: searchValue,
          },
        },
      });
    }
  }, [getTokens, searchValue]);
  const handleChangeInput = (value: string) => {
    setSearchValue(value);
  };
  const reserveEvc = () => {
    const { PlatformParty, __typename, ...token } = watch("token")?.value;
    if (evse && token) {
      setIsPublishRemoteReservation(true);
      PublishRemoteReservationRequest({
        variables: {
          input: {
            duration: 120000,
            evseUid: evse.uid,
            locationUid: evse?.EVC?.Location?.uid,
            token: token,
          },
        },
      })
        .then((res) => {
          const requestUid = res?.data?.publishRemoteReservation?.requestUid;
          if (requestUid) {
            setIsPublishRemoteReservation(false);
            toast.success("Reservation sent successfully");
          }
        })

        .catch((err) => {
          err.response.errors.forEach((error: any) => {
            toast.error(error.message);
          });
          setIsPublishRemoteReservation(false);
        })

        .finally(() => {
          setIsPublishRemoteReservation(false);
        });
    }
  };
  return (
    <Flex fullSize>
      <Typography mb={3} variant={"title20"}>
        {t ('reservation')}
      </Typography>
      <Card bg="white" p={3}>
        <Box mb={2}>
          <Typography variant={"title20"}>{t('start reservation')}</Typography>
        </Box>
        <Box maxWidth={500}>
          <Controller
            name="token"
            control={control}
            render={({ field }) => (
              <Select
                menuPosition="fixed"
                isLoading={loadingGetTokens}
                onInputChange={debounce(handleChangeInput, 1000)}
                getOptionValue={(option: any) => {
                  return option?.value;
                }}
                placeholder={"Select Token (min 4 characters) "}
                options={
                  tokens?.map((token: any) => {
                    return {
                      label: token?.label,
                      value: token,
                    };
                  }) || []
                }
                {...field}
              />
            )}
          />
        </Box>

        <Button
          mt={2}
          disabled={isPublishRemoteReservation || !watch("token")}
          loading$={isPublishRemoteReservation}
          variant="success"
          $size="large"
          onClick={reserveEvc}
        >
          Start
        </Button>
      </Card>
    </Flex>
  );
};
