import { gql, useLazyQuery } from "@apollo/client";

export const GET_TARIFFS = gql`
  query Tariffs(
    $filter: TariffFilterParams
    $pagination: PaginationParams
    $sort: TariffSortingParams
  ) {
    Tariffs(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        pricing {
          id
          conditions {
            upon
            eq
            gt
            gte
            in
            lt
            lte
            ne
            nin
            type
            path
          }
          elements {
            id
            priceComponent {
              price
              type
            }
            restrictions {
              dow
              fromDate
              fromH
              maxDuration
              maxKwh
              maxParkingTime
              maxPower
              minDuration
              minKwh
              minParkingTime
              minPower
              toDate
              toH
            }
          }
        }
        uid
        updatedAt
      }
    }
  }
`;

export const useGetTariffs = () => {
  const [
    executeTariffList,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_TARIFFS, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
    errorPolicy: "ignore",
    // errorPolicy: "ignore",
    // refetchWritePolicy: "overwrite",
    // pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    executeTariffList,
    error,
    count: data?.Tariffs?.total,
    tariffs: data?.Tariffs?.results,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
