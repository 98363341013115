import debounce from "debounce";
import { t } from "i18next";
import { ChangeEventHandler, ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Flex, Input, Popover } from "../UI";
import RenderOnRole from "../UI/RenderOnRole";
import { Add, Download, Refresh, TableSetting } from "../assets/svg";
import { Roles } from "@bepower/greta-types";

type Props = {
  size?: "small" | "default" | "large";
  padding?: number;
  withResetButton?: boolean;
  textInputProps?: {
    onChange: ChangeEventHandler<HTMLInputElement>;
    value?: string;
    defaultValue?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    onSuffixClick?: () => void;
    iconSize?: string;
  };
  popoverProps?: {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickButton?: () => void;
    children: React.ReactElement;
    active?: boolean;
  };
  onClickRefreshButton?: () => void;
  addButtonProps?: {
    onClick?: () => void;
    roles: any[];
  };
  tableSettingProps?: {
    disabled?: boolean;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactElement;
  };
  downloadButtonProps?: {
    loading?: boolean;
    onClick?: () => void;
    disabled?: boolean;
  };
  onClickFiltersListButton?: () => void;
  tableColumnsVisibilities?: {};
  extraProps?: ReactNode;
  resetCallback?: () => void;
};
export const FiltersList: React.FC<Props> = ({
  popoverProps,
  downloadButtonProps,
  tableSettingProps,
  textInputProps,
  extraProps,
  padding,
  withResetButton = true,
  size = "small",
  resetCallback,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const resetAllFilters = () => {
    setSearchParams({});
    resetCallback?.();
  };
  return (
    <Flex
      flexDirection="row"
      p={padding || 3}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      borderTopLeftRadius={5}
      borderTopRightRadius={5}
      flexWrap={"wrap"}
      style={{ gap: "10px 10px" }}
    >
      <Flex flex={1} flexDirection="row" minWidth={200}>
        {textInputProps && (
          <Input
            containerProps={{ maxWidth: 500, minWidth: 100 }}
            $size={size}
            id="search-input"
            defaultValue={textInputProps?.defaultValue}
            shape="default"
            placeholder={String(t("search"))}
            onChange={debounce(textInputProps.onChange, 1000)}
          />
        )}
      </Flex>

      <Flex minWidth={200} flexDirection="row" style={{ gap: "10px 10px" }}>
        {withResetButton && (
          <Button
            $size={size}
            withBorder={true}
            shape="default"
            onClick={resetAllFilters}
            variant="primary"
            $fill={false}
          >
           {t ('reset filters')}
          </Button>
        )}

        {props?.onClickRefreshButton && (
          <Button
            $size={size}
            fixedSize
            withBorder={false}
            shape="default"
            onClick={props?.onClickRefreshButton}
            title="Refesh"
          >
            <Refresh size="4" />
          </Button>
        )}
        {tableSettingProps && (
          <Popover
            variant="white"
            closeOnClickAway
            position="bottom"
            status={tableSettingProps.isOpen}
            setStatus={tableSettingProps.setIsOpen}
          >
            <Button
              $size={size}
              fixedSize
              $fill={false}
              width={50}
              shape="default"
              title ='toggle table elements'
            >
              <TableSetting size="5" />
            </Button>
            {tableSettingProps.children}
          </Popover>
        )}

        {downloadButtonProps && (
          <Button
            $size={size}
            fixedSize
            withBorder={false}
            alignItems="center"
            justifyContent="center"
            display="flex"
            shape="default"
            onClick={downloadButtonProps.onClick}
            loading$={downloadButtonProps?.loading}
            disabled={downloadButtonProps?.disabled}
            title={t("download")|| ''}
          >
            {downloadButtonProps?.loading ? null : <Download size="4" />}
          </Button>
        )}
        {props?.addButtonProps && (
          <RenderOnRole roles={props?.addButtonProps?.roles}>
            <Button
              $size={size}
              fixedSize
              withBorder={false}
              shape="default"
              onClick={props?.addButtonProps?.onClick}
              title= {t("add new")|| ''}
            >
              <Add size="4" />
            </Button>
          </RenderOnRole>
        )}
        {extraProps && extraProps}
      </Flex>
    </Flex>
  );
};
