import { gql, useMutation } from "@apollo/client";

export const PUBLISH_EVSE_ACCESS_GROUP = gql`
  mutation publishEvseAccessGroup($input: EvseAccessGroupInput!) {
    publishEvseAccessGroup(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const usePublishEvseAccessGroup = () => {
  const [publishEvseAccessGroup, { data, loading, error }] = useMutation<any>(
    PUBLISH_EVSE_ACCESS_GROUP,
    {
      // refetchQueries: [GET_PLATFORM, GET_PLATFORMS]
    }
  );
  return {
    publishEvseAccessGroup,
    error,
    data,
    loading,
  };
};
