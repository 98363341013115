import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  Flex,
  Select,
  Typography,
} from "../../../../../../shared/UI";
import { usePublishRemoteChangeAvailability } from "../../../../gql/mutations/usePublishRemoteChangeAvailability";
import { usePublishRemoteRefreshStatus } from "../../../../gql/mutations/usePublishRemoteRefreshStatus";
import { usePublishRemoteUnlockConnector } from "../../../../gql/mutations/usePublishRemoteUnlockConnector";
import { useChangeAvailabilityProcessed } from "../../../../gql/queries/useChangeAvailabilityProcessed";
import { useRefreshStatusProcessed } from "../../../../gql/queries/useRefreshStatusProcessed";
import { useUnlockConnectorProcessed } from "../../../../gql/queries/useUnlockConnectorProcessed";
import { t } from "i18next";


const availableTypes = ["Operative", "Inoperative"];

export const StatusEvseCommand = () => {
  const { control, watch, register, setValue } = useForm();
  const [isLoadingUnlock, setIsLoadingUnlock] = useState<boolean>(false);
  const [isrefreshingStatus, setIsrefreshingStatus] = useState<boolean>(false);
  const [isChangingAvailability, setIsChangingAvailability] =
    useState<boolean>(false);

  const { getUnlockConnectorProcessed } = useUnlockConnectorProcessed();
  const { publishRemoteUnlockConnector } = usePublishRemoteUnlockConnector();
  const { getRefreshStatusProcessed } = useRefreshStatusProcessed();
  const { publishRemoteRefreshStatus } = usePublishRemoteRefreshStatus();
  const { getChangeAvailabilityProcessed } = useChangeAvailabilityProcessed();
  const { publishRemoteChangeAvailability } =
    usePublishRemoteChangeAvailability();
  const { evse }: any = useOutletContext();

  const sendUnlockConnector = () => {
    setIsLoadingUnlock(true);
    publishRemoteUnlockConnector({
      variables: {
        input: {
          evseUid: evse?.uid,
        },
      },
    }).then((res) => {
      const requestUid = res?.data?.publishRemoteUnlockConnector?.requestUid;
      if (requestUid) {
        // DA ELIMINARE use subscribe
        setTimeout(() => {
          getUnlockConnectorProcessed({
            variables: {
              filter: {
                requestUid: requestUid,
              },
            },
          })
            .then((res) => {
              if (res.data?.UnlockConnectorProcessed?.results?.[0]?.status) {
                toast.success("successfully unlocked");
              } else {
                toast.error(
                  res.data?.UnlockConnectorProcessed?.results?.[0]?.info
                );
              }
            })
            .finally(() => {
              setIsLoadingUnlock(false);
            });
        }, 2000);
      }
    });
  };
  const handleSendRefreshStatus = () => {
    setIsrefreshingStatus(true);
    publishRemoteRefreshStatus({
      variables: {
        input: {
          evseUid: evse?.uid,
          kind: "EVSE",
        },
      },
    })
      .then((res) => {
        const requestUid = res?.data?.publishRemoteRefreshStatus?.requestUid;
        if (requestUid) {
          // DA ELIMINARE use subscribe
          setTimeout(() => {
            getRefreshStatusProcessed({
              variables: {
                filter: {
                  requestUid: requestUid,
                },
              },
            })
              .then((res) => {
                if (res.data?.RefreshStatusProcessed?.results?.[0]?.status) {
                  toast.success("Status Triggered Successfully");
                } else {
                  toast.error(
                    res.data?.RefreshStatusProcessed?.results?.[0]?.info
                  );
                }
              })
              .catch((error) => {
                setIsrefreshingStatus(false);
              })
              .finally(() => {
                setIsrefreshingStatus(false);
              });
          }, 2000);
        }
      })
      .catch((error) => {
        setIsrefreshingStatus(false);
      });
  };

  const handleChangeAvailability = () => {
    setIsChangingAvailability(true);
    publishRemoteChangeAvailability({
      variables: {
        input: {
          evseUid: evse?.uid,
          type: watch("availabilityType")?.value,
        },
      },
    })
      .then((res) => {
        const requestUid =
          res?.data?.publishRemoteChangeAvailability?.requestUid;
        if (requestUid) {
          // DA ELIMINARE use subscribe
          setTimeout(() => {
            getChangeAvailabilityProcessed({
              variables: {
                filter: {
                  requestUid: requestUid,
                },
              },
            })
              .then((res) => {
                if (
                  res.data?.ChangeAvailabilityProcessed?.results?.[0]?.status
                ) {
                  toast.success("Availability Changed Successfully");
                } else {
                  toast.error(
                    res.data?.ChangeAvailabilityProcessed?.results?.[0]?.info
                  );
                }
              })
              .catch((error) => {
                setIsChangingAvailability(false);
              })
              .finally(() => {
                setIsChangingAvailability(false);
              });
          }, 2000);
        }
      })
      .catch((error) => {
        setIsChangingAvailability(false);
      });
  };
  return (
    <Flex fullSize>
      <Typography mb={3} variant={"title20"}>
        {t ('Status')}
      </Typography>
      <Flex
        p={2}
        flexDirection={"row"}
        display={"flex"}
        style={{ gap: "10px 10px" }}
      >
        <Card bg="white" flex={1} p={3}>
          <Box mb={2}>
            <Typography variant={"title20"}>{t ('Unlock connector')}</Typography>
          </Box>

          <Button
            mt={2}
            disabled={isLoadingUnlock}
            loading$={isLoadingUnlock}
            variant="success"
            $size="large"
            onClick={sendUnlockConnector}
          >
            { t('Unlock')}
          </Button>
        </Card>
        <Card bg="white" flex={1} p={3}>
          <Box mb={2}>
            <Typography variant={"title20"}>
              Trigger Status Notification
            </Typography>
          </Box>

          <Button
            mt={2}
            disabled={isrefreshingStatus}
            loading$={isrefreshingStatus}
            variant="success"
            $size="large"
            onClick={handleSendRefreshStatus}
          >
            {t('send')}
          </Button>
        </Card>
        <Card bg="white" flex={1} p={3}>
          <Box mb={2}>
            <Typography variant={"title20"}>Change availability</Typography>
          </Box>
          <Controller
            name="availabilityType"
            control={control}
            render={({ field }) => (
              <Select
                menuPosition="fixed"
                getOptionValue={(option: any) => {
                  return option?.value;
                }}
                placeholder={"Select an availability"}
                options={availableTypes.map((type: string) => ({
                  label: type,
                  value: type,
                }))}
                {...field}
              />
            )}
          />
          <Button
            mt={2}
            disabled={
              isChangingAvailability || !watch("availabilityType")?.value
            }
            loading$={isChangingAvailability}
            variant="success"
            $size="large"
            onClick={handleChangeAvailability}
          >
            {t('send')}
          </Button>
        </Card>
      </Flex>
    </Flex>
  );
};
