import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsAccessibility from "highcharts/modules/accessibility";
interface Props {
  data?: any[];
  sessionUid: string;
}

highchartsExporting(Highcharts);
highchartsExportData(Highcharts);
highchartsAccessibility(Highcharts);

export const LineChart: React.FC<Props> = ({ data, sessionUid }) => {
  const options: Highcharts.Options = {
    chart: {
      events: {
        render: function () {
          const btn = document.querySelector(
            ".highcharts-a11y-proxy-button.highcharts-no-tooltip"
          ) as HTMLElement;
          if (btn && !btn.title)
            btn.title =
              Highcharts.getOptions().lang?.contextButtonTitle ??
              "Chart context menu";
        },
      },
      zooming: {
        type: "xy",
        key: "ctrl",
        mouseWheel: true,
        resetButton: {
          position: {
            x: 100,
            y: 0,
          },
        },
      },
      style: {
        height: 330,
      },
    },
    subtitle: {
      text: `SessionUid: ${sessionUid}`,
    },
    title: {
      text: "Session MeterValues",
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
    },
    xAxis: {
      type: "datetime",
      tickColor: "#FFFFFF",
      gridLineColor: "#FFFFFF",
      title: {
        text: "Date",
      },
      dateTimeLabelFormats: {
        day: {
          main: "%H:%M",
          range: true,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          symbol: "menuball",
          y: 0,
          menuItems: [
            "printChart",
            "separator",
            "downloadJPEG",
            "downloadPDF",
            "downloadCSV",
            "downloadPNG",
            "downloadXLS",
            "exitFullscreen",
          ],
        },
      },
    },
    yAxis: {
      tickColor: "#FFFFFF",
      gridLineColor: "#FFFFFF",
      title: {
        text: "Value",
      },

      // labels: {
      //   formatter: () => {
      //     return Highcharts.dateFormat("%H:%M %d %b %Y", value);
      //   },
      // },
    },

    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
      },
      series: {
        marker: {
          symbol: "circle",
        },
      },
    },
    tooltip: {
      shared: true,
      formatter: function (tooltip) {
        // if (this.point?.value === null) {
        //   return "Null";
        // }
        // If not null, use the default formatter
        console.log("tooltip", tooltip);
        return tooltip.defaultFormatter.call(this, tooltip);
      },
    },

    series: data?.map((dataItem) => ({
      type: dataItem.type,
      name: dataItem.id,
      data: dataItem.data,
    })),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <HighchartsReact
      allowChartUpdate
      highcharts={Highcharts}
      options={options}
    />
  );
};
