import Svg from "../../UI/Svg";
type Props = {
  size?: string;
  fill?: string;
};
const Evc: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      fill={fill}
      iconSize={size}
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2H9C6.17157 2 4.75736 2 3.87868 2.87868C3 3.75736 3 5.17157 3 8V21.25H16V17.75V16.25V8C16 5.17157 16 3.75736 15.1213 2.87868C14.2426 2 12.8284 2 10 2Z"
        //   fill="#1C274C"
      />
      <path
        d="M3 21.25H2C1.58579 21.25 1.25 21.5858 1.25 22C1.25 22.4142 1.58579 22.75 2 22.75H17.25C17.6642 22.75 18 22.4142 18 22C18 21.5858 17.6642 21.25 17.25 21.25H16H3Z"
        //   fill="#1C274C"
      />
      <path
        d="M10.1431 10.386C10.3562 10.0308 10.2411 9.5701 9.88587 9.35699C9.53069 9.14388 9.06999 9.25906 8.85688 9.61424L7.35688 12.1142C7.21786 12.3459 7.21422 12.6345 7.34735 12.8696C7.48048 13.1048 7.7298 13.2501 8 13.2501H9.67536L8.85688 14.6142C8.64377 14.9694 8.75894 15.4301 9.11413 15.6432C9.46931 15.8563 9.93001 15.7412 10.1431 15.386L11.6431 12.886C11.7821 12.6543 11.7858 12.3657 11.6527 12.1306C11.5195 11.8955 11.2702 11.7501 11 11.7501H9.32464L10.1431 10.386Z"
        //   fill="#1C274C"
      />
      <path
        d="M16 17.75H17.5714C17.9462 17.75 18.25 18.0538 18.25 18.4286V18.5C18.25 19.7427 19.2574 20.75 20.5 20.75C21.7426 20.75 22.75 19.7427 22.75 18.5V7.60177C22.75 7.44511 22.75 7.33702 22.7441 7.23161C22.6884 6.24063 22.2422 5.31214 21.5031 4.64962C21.4245 4.57915 21.3401 4.51163 21.2178 4.41378L19.9685 3.41438C19.6451 3.15562 19.1731 3.20806 18.9143 3.53151C18.6556 3.85495 18.708 4.32692 19.0315 4.58568L20.2646 5.57215C20.4091 5.68774 20.4585 5.72768 20.5019 5.76653C20.9453 6.16405 21.2131 6.72114 21.2465 7.31573C21.2497 7.37384 21.25 7.43743 21.25 7.62247V8H20.5C19.6716 8 19 8.67157 19 9.5V11.9189C19 12.5645 19.4131 13.1377 20.0257 13.3419L21.25 13.75V18.5C21.25 18.9142 20.9142 19.25 20.5 19.25C20.0858 19.25 19.75 18.9142 19.75 18.5V18.4286C19.75 17.2254 18.7746 16.25 17.5714 16.25H16V17.75Z"
        //   fill="#1C274C"
      />
    </Svg>
  );
};

export default Evc;
