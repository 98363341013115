import { Outlet } from "react-router-dom";
import { SectionContentTop, SectionLayout } from "../../../shared/layout";
import { Center } from "../../../shared/UI";

const Error = () => {
  return (
    <SectionLayout>
      <SectionContentTop fullHeight>
        <Center>
          <Outlet />
        </Center>
      </SectionContentTop>
    </SectionLayout>
  );
};

export default Error;
