/**
 * eslint-disable array-callback-return
 *
 * @format
 */

import {
  Location,
  Roles,
  TariffAssocConditionInput,
} from "@bepower/greta-types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addDays,
  endOfDay,
  format,
  isBefore,
  startOfDay,
  toDate,
} from "date-fns";
import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { groupedTariffTypeOptions } from "../../../core/model/Tariff.model";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  Flex,
  Input,
  InputLabel,
  LoadingLayer,
  Select,
  SimpleLoader,
  Typography,
} from "../../../shared/UI";

import type { PriceComponent, TariffRestriction } from "@bepower/greta-types";
import VerticalScrollContainer from "../../../shared/UI/VerticalScrollContainer";
import { Alarm } from "../../../shared/assets/svg";
import { RedirectTo } from "../../../shared/components/Redirect";
import UserService from "../../../shared/services/UserServices";
import { useGetPlatformParties } from "../../platforms/gql/queries/useGetPlatformParties";
import { usePublishTariff } from "../gql";
import { useGetTariffAssoc } from "../gql/useGetTariffAssoc";
import { usePublishTariffAssoc } from "../gql/usepublishTariffAssoc";
import { useTariffAssocs } from "../hooks/useTariffAssocs";
import Info from "./../../../shared/assets/svg/Info";
import { CountryPriceElement, FormValues, TariffSchema } from "./TariffSchema";
import { CpoDropdown } from "./tariff-form-fields/CpoDropdown";
import { CsoDropdown } from "./tariff-form-fields/CsoDropdown";
import { CustomPrice } from "./tariff-form-fields/CustomPrice";
import { DefaultPrice } from "./tariff-form-fields/DefaultPrice";
import { EmspPartyDropdown } from "./tariff-form-fields/EmspPartyDropdown";
import { LocationDropdown } from "./tariff-form-fields/LocationDropdown";
import { TariffAssoc } from "../../../shared/gql/generated/graphql";

type Props = {
  type: "CLONE" | "EDIT" | "NEW";
};

type TariffType = "AC" | "DC < 100kW" | "100kW ≤ DC < 150kW" | "DC ≥ 150kW";

type Periods = "pastGracePeriod" | "gracePeriod" | "beforeGracePeriod";

function getElementId(elementType: Periods): number {
  return {
    pastGracePeriod: 1,
    gracePeriod: 2,
    beforeGracePeriod: 3,
  }[elementType];
}

function getRestrictions(
  tariffType: TariffType,
  elementType: Periods,
  isEhub = false
): TariffRestriction {
  if (isEhub) {
    return {
      dow: null,
      fromH: null,
      maxDuration: null,
      maxKwh: null,
      maxParkingTime: null,
      maxPower: null,
      minDuration: null,
      minKwh: null,
      minParkingTime: null,
      minPower: null,
      toH: null,
    };
  }
  switch (elementType) {
    case "pastGracePeriod":
      return {
        fromH: ["AC", "AC"].includes(tariffType) ? 360 : null,
        toH: ["AC", "AC"].includes(tariffType) ? 1320 : null,
        minKwh: 0.1,
        maxKwh: null,
        minPower: null,
        maxPower: null,
        minDuration: null,
        maxDuration: null,
        dow: null,
        minParkingTime: 60,
        maxParkingTime: null,
      };

    case "gracePeriod":
      return {
        dow: null,
        fromH: null,
        maxDuration: null,
        maxKwh: null,
        maxParkingTime: 60,
        maxPower: 0.0015,
        minDuration: null,
        minKwh: null,
        minParkingTime: null,
        minPower: null,
        toH: null,
      };

    case "beforeGracePeriod":
    default:
      return {
        dow: null,
        fromH: null,
        maxDuration: null,
        maxKwh: null,
        maxParkingTime: null,
        maxPower: null,
        minDuration: null,
        minKwh: null,
        minParkingTime: null,
        minPower: null,
        toH: null,
      };
  }
}

function getPriceComponent(
  tariffType: TariffType,
  elementType: Periods,
  formData: FormValues,
  isEhub = false
): PriceComponent[] {
  return [
    { price: +formData[`${tariffType}_time`], type: "time" },
    { price: +formData[`${tariffType}_energy`], type: "energy" },
    {
      price:
        elementType === "gracePeriod" ||
        isEhub ||
        (elementType === "beforeGracePeriod" && tariffType === "AC")
          ? 0
          : +formData[`${tariffType}_parkingTime`],
      type: "parkingTime",
    },
    { price: +formData[`${tariffType}_flat`], type: "flat" },
  ];
}

function getPriceComponentCountries(
  tariffType: TariffType,
  elementType: Periods,
  item: CountryPriceElement,
  isEhub = false
): PriceComponent[] {
  return [
    { price: +item[`${tariffType}_time`], type: "time" },
    { price: +item[`${tariffType}_energy`], type: "energy" },
    {
      price:
        elementType === "gracePeriod" ||
        isEhub ||
        (elementType === "beforeGracePeriod" && tariffType === "AC")
          ? 0
          : +item[`${tariffType}_parkingTime`],
      type: "parkingTime",
    },
    { price: +item[`${tariffType}_flat`], type: "flat" },
  ];
}

const conditions = {
  AC: [
    {
      upon: "evc",
      path: "powerType",
      type: "string",
      in: ["AC_1_PHASE", "AC_3_PHASE"],
    },
  ],
  "DC < 100kW": [
    { upon: "evc", path: "powerType", type: "string", eq: "DC" },
    { type: "int", upon: "evc", path: "capacity", lt: "100" },
  ],
  "100kW ≤ DC < 150kW": [
    { type: "string", upon: "evc", path: "powerType", eq: "DC" },
    { type: "int", upon: "evc", path: "capacity", lt: "150" },
  ],
  "DC ≥ 150kW": [
    { type: "string", upon: "evc", path: "powerType", eq: "DC" },
    { type: "int", upon: "evc", path: "capacity", gte: "150" },
  ],
  eHub: [{ upon: "evc", path: "isEhub", type: "boolean", eq: "true" }],
  country: (countries: string[]) => [
    {
      upon: "location",
      path: "country",
      type: "string",
      in: countries,
    },
  ],
};

function getElement(
  type: TariffType,
  elementType: Periods,
  formData: FormValues
) {
  return {
    id: getElementId(elementType),
    restrictions: getRestrictions(type, elementType),
    priceComponent: getPriceComponent(type, elementType, formData),
  };
}

function getElementCountries(
  type: TariffType,
  elementType: Periods,
  item: CountryPriceElement,
  isEhub = false
) {
  return {
    id: getElementId(elementType),
    restrictions: getRestrictions(type, elementType, isEhub),
    priceComponent: getPriceComponentCountries(type, elementType, item, isEhub),
  };
}

export const TariffInputs: React.FC<Props> = ({ type = "EDIT" }) => {
  const { publishTariff, loadingPublishTariff } = usePublishTariff();
  const [searchParams] = useSearchParams();
  const partyId = searchParams.get("partyId");
  const partyUid = searchParams.get("partyUid");
  const platformProtocol = searchParams.get("platformProtocol");
  const {
    getPlatformPartiesQuery: getPlatformPartiesCPOQuery,
    platformParties: CpoParties,
    loading: loadingPlatformParties,
  } = useGetPlatformParties();

  const methods = useForm<any>({
    resolver: yupResolver(TariffSchema),
    shouldFocusError: true,
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = methods;
  const watchType = watch("type");
  const cpoParty = watch("cpoPartyUids");
  const label = watch("label");
  const { count } = useTariffAssocs();
  const labelPrefix = useRef<string>();
  const autoGenerate = watch("autoGenerate");

  useEffect(() => {
    let labelValue = "";
    if (watchType) labelValue = watchType?.value;
    if (cpoParty)
      labelValue = cpoParty?.label + "-" + labelValue + "-" + (count + 1);
    labelPrefix.current = labelValue;
    if (autoGenerate) {
      setValue("label", labelValue);
    }
  }, [watchType, cpoParty, count, label, setValue, autoGenerate, reset]);

  const { executeTariffAssoc, tariffAssoc, loading } = useGetTariffAssoc();

  const [redirect, setRedirect] = useState<boolean>(false);

  let canEdit = UserService?.hasResourceRole([Roles.PricesWrite], "appsync");
  canEdit = type === "EDIT" && tariffAssoc?.Active ? false : true;
  canEdit = type === "EDIT" && tariffAssoc?.Expired ? false : true;

  const navigate = useNavigate();
  const { priceUid } = useParams();
  const { publishTariffAssoc, loadingPublishTariffAssoc } =
    usePublishTariffAssoc();

  // reset cpo party when type changes
  useEffect(() => {
    if (!tariffAssoc) {
      setValue("cpoPartyUids", null);
    }
  }, [watchType, setValue, tariffAssoc]);

  function convertDateInTimestamp(date: Date): number {
    return Number(format(date, "T"));
  }
  function convertTimestampInDate(timestamp: number): any {
    return format(toDate(timestamp), "yyyy-MM-dd");
  }

  function getInputDateFormat(date: Date | number): string {
    return format(date, "yyyy-MM-dd");
  }

  const onSubmitForm = (formData: FormValues) => {
    const validFromTimeStamp = convertDateInTimestamp(
      startOfDay(formData?.validFrom)
    );

    // return console.log(formData);
    const validToTimeStamp = formData?.validTo
      ? convertDateInTimestamp(endOfDay(formData?.validTo))
      : null;
    let moreConditions: any = [];
    // if (formData?.country?.length) {
    //    moreConditions.push({ upon: 'location', path: "country", type: 'string', in: formData?.country?.map((c: any) => c.value) })
    // }

    const pricingForCountries: any[] = [];
    if (formData.priceForCountry?.length) {
      let initialID = 8;
      formData?.priceForCountry?.map((item, index) => {
        pricingForCountries.push(
          {
            tariffAltText: [],
            id: initialID + 1,
            label: "AC",
            elements: [getElementCountries("AC", "pastGracePeriod", item)],
            conditions: [
              ...conditions.AC,
              ...conditions.country(item?.country?.map((c: any) => c.value)),
              ...conditions.eHub,
            ],
          },
          //dc <100 element
          {
            tariffAltText: [],
            id: initialID + 2,
            label: "DC < 100kW",
            elements: [
              getElementCountries("DC < 100kW", "pastGracePeriod", item),
            ],
            conditions: [
              ...conditions["DC < 100kW"],
              ...conditions.country(item?.country?.map((c: any) => c.value)),
              ...conditions.eHub,
            ],
          },
          //100 kW<= DC < 150 kW
          {
            tariffAltText: [],
            id: initialID + 3,
            label: "100kW ≤ DC < 150kW",
            elements: [
              getElementCountries(
                "100kW ≤ DC < 150kW",
                "pastGracePeriod",
                item
              ),
            ],
            conditions: [
              ...conditions["100kW ≤ DC < 150kW"],
              ...conditions.country(item?.country?.map((c: any) => c.value)),
              ...conditions.eHub,
            ],
          },
          //DC >= 150 kW
          {
            tariffAltText: [],
            id: initialID + 4,
            label: "DC ≥ 150kW",
            elements: [
              getElementCountries("DC ≥ 150kW", "pastGracePeriod", item),
            ],
            conditions: [
              ...conditions["DC ≥ 150kW"],
              ...conditions.country(item?.country?.map((c: any) => c.value)),
              ...conditions.eHub,
            ],
          },
          // Not eHUB
          {
            tariffAltText: [],
            id: initialID + 5,
            label: "AC",
            elements: [
              getElementCountries("AC", "pastGracePeriod", item),
              getElementCountries("AC", "gracePeriod", item),
              getElementCountries("AC", "beforeGracePeriod", item),
            ],
            conditions: [
              ...conditions.AC,
              ...conditions.country(item?.country?.map((c: any) => c.value)),
            ],
          },
          //dc <100 element
          {
            tariffAltText: [],
            id: initialID + 6,
            label: "DC < 100kW",
            elements: [
              getElementCountries("DC < 100kW", "pastGracePeriod", item),
              getElementCountries("DC < 100kW", "gracePeriod", item),
              getElementCountries("DC < 100kW", "beforeGracePeriod", item),
            ],
            conditions: [
              ...conditions["DC < 100kW"],
              ...conditions.country(item?.country?.map((c: any) => c.value)),
            ],
          },
          //100 kW<= DC < 150 kW
          {
            tariffAltText: [],

            id: initialID + 7,
            label: "100kW ≤ DC < 150kW",
            elements: [
              getElementCountries(
                "100kW ≤ DC < 150kW",
                "pastGracePeriod",
                item
              ),
              getElementCountries("100kW ≤ DC < 150kW", "gracePeriod", item),
              getElementCountries(
                "100kW ≤ DC < 150kW",
                "beforeGracePeriod",
                item
              ),
            ],
            conditions: [
              ...conditions["100kW ≤ DC < 150kW"],
              ...conditions.country(item?.country?.map((c: any) => c.value)),
            ],
          },
          //DC >= 150 kW
          {
            tariffAltText: [],
            id: initialID + 8,
            label: "DC ≥ 150kW",
            elements: [
              getElementCountries("DC ≥ 150kW", "pastGracePeriod", item),
              getElementCountries("DC ≥ 150kW", "gracePeriod", item),
              getElementCountries("DC ≥ 150kW", "beforeGracePeriod", item),
            ],
            conditions: [
              ...conditions["DC ≥ 150kW"],
              ...conditions.country(item?.country?.map((c: any) => c.value)),
            ],
          }
        );
        initialID = initialID + 8;
        return null;
      });
    }

    const tariffElement = {
      label: formData?.label,
      tariffAltText: [],
      // conditionsArr.push({ upon: 'location', path: 'countryCode', in: formData?.country?.map((c: any) => c.value), type: 'string' })

      ...(tariffAssoc && type === "EDIT"
        ? { uid: tariffAssoc?.Tariff?.uid }
        : {}),
      pricing: [
        // Pricing for countries
        ...pricingForCountries,
        // Location Monster
        {
          tariffAltText: [],
          id: 1,
          label: "AC",
          elements: [
            {
              id: 1,
              restrictions: getRestrictions("AC", "pastGracePeriod", true),
              priceComponent: getPriceComponent(
                "AC",
                "pastGracePeriod",
                formData,
                true
              ),
            },
          ],
          conditions: [...conditions.AC, ...conditions.eHub, ...moreConditions],
        },
        //dc <100 element
        {
          tariffAltText: [],
          id: 2,
          label: "DC < 100kW",
          elements: [
            {
              id: 1,
              restrictions: getRestrictions(
                "DC < 100kW",
                "pastGracePeriod",
                true
              ),
              priceComponent: getPriceComponent(
                "DC < 100kW",
                "pastGracePeriod",
                formData,
                true
              ),
            },
          ],
          conditions: [
            ...conditions["DC < 100kW"],
            ...conditions.eHub,
            ...moreConditions,
          ],
        },
        // 100 kW<= DC < 150 kW
        {
          tariffAltText: [],
          id: 3,
          label: "100kW ≤ DC < 150kW",
          elements: [
            {
              id: 1,
              restrictions: getRestrictions(
                "100kW ≤ DC < 150kW",
                "pastGracePeriod",
                true
              ),
              priceComponent: getPriceComponent(
                "100kW ≤ DC < 150kW",
                "pastGracePeriod",
                formData,
                true
              ),
            },
          ],
          conditions: [
            ...conditions["100kW ≤ DC < 150kW"],
            ...conditions.eHub,
            ...moreConditions,
          ],
        },
        //DC >= 150 kW
        {
          tariffAltText: [],
          id: 4,
          label: "DC ≥ 150kW",
          elements: [
            {
              id: 1,
              restrictions: getRestrictions(
                "DC ≥ 150kW",
                "pastGracePeriod",
                true
              ),
              priceComponent: getPriceComponent(
                "DC ≥ 150kW",
                "pastGracePeriod",
                formData,
                true
              ),
            },
          ],
          conditions: [
            ...conditions["DC ≥ 150kW"],
            ...conditions.eHub,
            ...moreConditions,
          ],
        },
        // Locations
        {
          tariffAltText: [],
          id: 5,
          label: "AC",
          elements: [
            getElement("AC", "pastGracePeriod", formData),
            getElement("AC", "gracePeriod", formData),
            getElement("AC", "beforeGracePeriod", formData),
          ],
          conditions: [...conditions.AC, ...moreConditions],
        },
        //dc <100 element
        {
          tariffAltText: [],
          id: 6,
          label: "DC < 100kW",
          elements: [
            getElement("DC < 100kW", "pastGracePeriod", formData),
            getElement("DC < 100kW", "gracePeriod", formData),
            getElement("DC < 100kW", "beforeGracePeriod", formData),
          ],
          conditions: [...conditions["DC < 100kW"], ...moreConditions],
        },
        // 100 kW<= DC < 150 kW
        {
          tariffAltText: [],
          id: 7,
          label: "100kW ≤ DC < 150kW",
          elements: [
            getElement("100kW ≤ DC < 150kW", "pastGracePeriod", formData),
            getElement("100kW ≤ DC < 150kW", "gracePeriod", formData),
            getElement("100kW ≤ DC < 150kW", "beforeGracePeriod", formData),
          ],
          conditions: [...conditions["100kW ≤ DC < 150kW"], ...moreConditions],
        },
        //DC >= 150 kW
        {
          tariffAltText: [],
          id: 8,
          label: "DC ≥ 150kW",
          elements: [
            getElement("DC ≥ 150kW", "pastGracePeriod", formData),
            getElement("DC ≥ 150kW", "gracePeriod", formData),
            getElement("DC ≥ 150kW", "beforeGracePeriod", formData),
          ],
          conditions: [...conditions["DC ≥ 150kW"], ...moreConditions],
        },
      ],
    };

    publishTariff({
      variables: {
        input: tariffElement,
      },
    }).then((response) => {
      const tariffUid = response?.data?.publishTariff?.uid;
      let conditionsArr: TariffAssocConditionInput[] = [];
      if (
        formData?.type?.value &&
        !["CSO", "DEFAULT"].includes(formData?.type?.value)
      ) {
        if (
          formData?.type?.value === "HUBJECT_O2A" ||
          formData?.type?.value === "HUBJECT_BLT"
        ) {
          conditionsArr.push({
            type: "string",
            upon: "platform",
            path: "protocol",
            eq: "OICP",
          });
        } else if (
          formData?.type?.value === "GIREVE_O2A" ||
          formData?.type?.value === "GIREVE_BLT"
        ) {
          conditionsArr.push({
            type: "string",
            upon: "platform",
            path: "protocol",
            eq: "OCPI",
          });
          conditionsArr.push({
            type: "string",
            upon: "platform",
            path: "flavor",
            eq: "gireve",
          });
        } else if (
          [
            "Business Solution",
            "CPO Conto Terzi",
            "Be Charge - Eccezioni",
          ].includes(formData?.type?.value)
        ) {
          conditionsArr.push({
            type: "string",
            upon: "location",
            path: "businessModel",
            eq: formData?.type?.value,
          });
        }
      }
      if (formData?.emspParty?.length) {
        const emspParties = formData?.emspParty?.map(
          (item: any) => item?.value
        );
        conditionsArr.push({
          type: "string",
          upon: "otherParty",
          path: "partyIdCountry",
          in: emspParties,
        });
      }
      if (formData?.cso?.length) {
        conditionsArr.push({
          type: "string",
          upon: "location",
          path: "hostGroup",
          in: formData?.cso?.map((cso: any) => cso.label),
        });
      }
      if (formData?.locations?.length) {
        conditionsArr.push({
          type: "string",
          upon: "location",
          path: "uid",
          in: formData?.locations?.map((location: any) => location.value),
        });
      }
      const tariffAssocEl: any = {
        conditions: conditionsArr,
        ...(tariffAssoc && type === "EDIT" ? { uid: tariffAssoc?.uid } : {}),
        tariffUid,
        validFrom: validFromTimeStamp,
        label: formData?.type?.value,
        type: formData?.type?.value,
        validTo: validToTimeStamp,
        cpoPartyUids: formData?.cpoPartyUids?.value,
        csoUids: formData?.cso?.map((cso: any) => cso.value),
      };
      if (tariffUid) {
        publishTariffAssoc({
          variables: {
            input: tariffAssocEl,
          },
        }).then((response) => {
          if (response?.data) {
            toast.success(
              `tariff ${
                type === "CLONE"
                  ? "cloned"
                  : type === "EDIT"
                  ? "edited"
                  : "created"
              } successfully`
            );
            setRedirect(true);
            setTimeout(() => navigate("/tariffs"), 4000);
          }
        });
      }
    });
  };
  const isSuperAdmin = true;

  function getElementPrice(item: any, label: string, type: string) {
    if (!item || !type || !label) return;
    return item?.pricing
      ?.find((el: any) => el.label === label)
      ?.elements[0]?.priceComponent?.find((el: any) => el.type === type)?.price;
  }
  useEffect(() => {
    if (priceUid) {
      executeTariffAssoc({
        variables: {
          uid: priceUid,
        },
      }).then((response) => {
        const tariff: any = response?.data?.TariffAssoc;
        const cpoPartyUidSelectedValues = {
          label: tariff?.CPOParties?.[0]?.name,
          value: tariff?.CPOParties?.[0]?.uid,
        };
        const AcPrices = tariff?.Tariff?.pricing.find((el: any) => el.id === 5)
          ?.elements[0].priceComponent;
        const dcLess100prices = tariff?.Tariff?.pricing.find(
          (el: any) => el.id === 6
        )?.elements[0].priceComponent;
        const dcLess150prices = tariff?.Tariff?.pricing.find(
          (el: any) => el.id === 7
        )?.elements[0].priceComponent;
        const dcBigger150prices = tariff?.Tariff?.pricing.find(
          (el: any) => el.id === 8
        )?.elements[0].priceComponent;
        let priceForCountry: any[] = [];

        if (tariff?.Tariff?.PricingByCountry?.length) {
          tariff?.Tariff?.PricingByCountry?.map((item: any) => {
            if (item?.countries?.length) {
              return priceForCountry.push({
                country: item?.countries.map((item: string) => ({
                  label: item,
                  value: item,
                })),
                AC_energy: getElementPrice(item, "AC", "energy") || "0.00",

                AC_time: getElementPrice(item, "AC", "time") || "0.00",
                AC_parkingTime:
                  getElementPrice(item, "AC", "parkingTime") || "0.00",
                AC_flat: getElementPrice(item, "AC", "flat") || "0.00",
                "DC < 100kW_energy":
                  getElementPrice(item, "DC < 100kW", "energy") || "0.00",
                "DC < 100kW_time":
                  getElementPrice(item, "DC < 100kW", "time") || "0.00",
                "DC < 100kW_parkingTime":
                  getElementPrice(item, "DC < 100kW", "parkingTime") || "0.00",
                "DC < 100kW_flat":
                  getElementPrice(item, "DC < 100kW", "flat") || "0.00",
                "100kW ≤ DC < 150kW_energy":
                  getElementPrice(item, "100kW ≤ DC < 150kW", "energy") ||
                  "0.00",
                "100kW ≤ DC < 150kW_time":
                  getElementPrice(item, "100kW ≤ DC < 150kW", "time") || "0.00",
                "100kW ≤ DC < 150kW_parkingTime":
                  getElementPrice(item, "100kW ≤ DC < 150kW", "parkingTime") ||
                  "0.00",
                "100kW ≤ DC < 150kW_flat":
                  getElementPrice(item, "100kW ≤ DC < 150kW", "flat") || "0.00",
                "DC ≥ 150kW_energy":
                  getElementPrice(item, "DC ≥ 150kW", "energy") || "0.00",
                "DC ≥ 150kW_time":
                  getElementPrice(item, "DC ≥ 150kW", "time") || "0.00",
                "DC ≥ 150kW_parkingTime":
                  getElementPrice(item, "DC ≥ 150kW", "parkingTime") || "0.00",
                "DC ≥ 150kW_flat":
                  getElementPrice(item, "DC ≥ 150kW", "flat") || "0.00",
                isExist: type === "EDIT",
              });
            } else {
              return null;
            }
          });
        }

        reset({
          label: tariff?.Tariff?.label,
          type: { label: tariff?.label, value: tariff?.label },
          validFrom:
            isBefore(tariff?.validFrom, startOfDay(addDays(new Date(), 1))) &&
            type === "CLONE"
              ? format(startOfDay(addDays(new Date(), 1)), "yyyy-MM-dd")
              : convertTimestampInDate(tariff?.validFrom),
          validTo: tariff?.validTo
            ? convertTimestampInDate(tariff?.validTo)
            : undefined,
          cpoPartyUids: cpoPartyUidSelectedValues,
          cso: tariff.CSOs.map((cso: { uid: string; name: string }) => ({
            label: cso.name,
            value: cso.uid,
          })),
          locations: tariff?.Locations?.map((location: Location) => ({
            label: `${location.projectId}: ${location.label}`,
            value: location.uid,
          })),
          emspParty: tariff?.EMSPParties?.map((item: any) => ({
            label: item.name,
            value: item.PartyIdCountry,
          })),
          AC_time:
            AcPrices?.find((elementPrice: any) => elementPrice?.type === "time")
              ?.price || "0.00",
          AC_energy:
            AcPrices?.find(
              (elementPrice: any) => elementPrice?.type === "energy"
            )?.price || "0.00",
          AC_parkingTime:
            AcPrices?.find(
              (elementPrice: any) => elementPrice?.type === "parkingTime"
            )?.price || "0.00",
          AC_flat:
            AcPrices?.find((elementPrice: any) => elementPrice?.type === "flat")
              ?.price || "0.00",
          "DC < 100kW_time":
            dcLess100prices?.find(
              (elementPrice: any) => elementPrice.type === "time"
            )?.price || "0.00",
          "DC < 100kW_energy":
            dcLess100prices?.find(
              (elementPrice: any) => elementPrice.type === "energy"
            )?.price || "0.00",
          "DC < 100kW_parkingTime":
            dcLess100prices?.find(
              (elementPrice: any) => elementPrice.type === "parkingTime"
            )?.price || "0.00",
          "DC < 100kW_flat":
            dcLess100prices?.find(
              (elementPrice: any) => elementPrice.type === "flat"
            )?.price || "0.00",
          "100kW ≤ DC < 150kW_time":
            dcLess150prices?.find(
              (elementPrice: any) => elementPrice.type === "time"
            )?.price || "0.00",
          "100kW ≤ DC < 150kW_energy":
            dcLess150prices?.find(
              (elementPrice: any) => elementPrice.type === "energy"
            )?.price || "0.00",
          "100kW ≤ DC < 150kW_parkingTime":
            dcLess150prices?.find(
              (elementPrice: any) => elementPrice.type === "parkingTime"
            )?.price || "0.00",
          "100kW ≤ DC < 150kW_flat":
            dcLess150prices?.find(
              (elementPrice: any) => elementPrice.type === "flat"
            )?.price || "0.00",
          "DC ≥ 150kW_time":
            dcBigger150prices?.find(
              (elementPrice: any) => elementPrice.type === "time"
            )?.price || "0.00",
          "DC ≥ 150kW_energy":
            dcBigger150prices?.find(
              (elementPrice: any) => elementPrice.type === "energy"
            )?.price || "0.00",
          "DC ≥ 150kW_parkingTime":
            dcBigger150prices?.find(
              (elementPrice: any) => elementPrice.type === "parkingTime"
            )?.price || "0.00",
          "DC ≥ 150kW_flat":
            dcBigger150prices?.find(
              (elementPrice: any) => elementPrice.type === "flat"
            )?.price || "0.00",
          priceForCountry: priceForCountry,
        });
      });
    } else {
      reset({
        validFrom: format(startOfDay(addDays(new Date(), 1)), "yyyy-MM-dd"),
        AC_energy: "0.00",
        AC_flat: "0.00",
        AC_parkingTime: "0.00",
        AC_time: "0.00",
        "DC ≥ 150kW_energy": "0.00",
        "DC ≥ 150kW_flat": "0.00",
        "DC ≥ 150kW_parkingTime": "0.00",
        "DC ≥ 150kW_time": "0.00",
        "DC < 100kW_energy": "0.00",
        "DC < 100kW_flat": "0.00",
        "DC < 100kW_parkingTime": "0.00",
        "DC < 100kW_time": "0.00",
        "100kW ≤ DC < 150kW_energy": "0.00",
        "100kW ≤ DC < 150kW_flat": "0.00",
        "100kW ≤ DC < 150kW_parkingTime": "0.00",
        "100kW ≤ DC < 150kW_time": "0.00",
      });
    }

    getPlatformPartiesCPOQuery({
      variables: {
        filter: {
          role: "CPO",
        },
        pagination: {
          limit: 1000,
          page: 1,
        },
      },
    });
  }, [priceUid, getPlatformPartiesCPOQuery, executeTariffAssoc, reset, type]);

  useEffect(() => {
    if (platformProtocol && partyUid) {
      reset({
        type: {
          label: platformProtocol === "OCPI" ? platformProtocol : "HUBJECT_BLT",
          value: platformProtocol === "OCPI" ? platformProtocol : "HUBJECT_BLT",
        },
        validFrom: format(startOfDay(addDays(new Date(), 1)), "yyyy-MM-dd"),
        AC_energy: "0.00",
        AC_flat: "0.00",
        AC_parkingTime: "0.00",
        AC_time: "0.00",
        "DC ≥ 150kW_energy": "0.00",
        "DC ≥ 150kW_flat": "0.00",
        "DC ≥ 150kW_parkingTime": "0.00",
        "DC ≥ 150kW_time": "0.00",
        "DC < 100kW_energy": "0.00",
        "DC < 100kW_flat": "0.00",
        "DC < 100kW_parkingTime": "0.00",
        "DC < 100kW_time": "0.00",
        "100kW ≤ DC < 150kW_energy": "0.00",
        "100kW ≤ DC < 150kW_flat": "0.00",
        "100kW ≤ DC < 150kW_parkingTime": "0.00",
        "100kW ≤ DC < 150kW_time": "0.00",
      });
    }
  }, [platformProtocol, reset, partyUid]);

  const [isOpenAlarmPopup, setIsOpenAlarmPopup] = useState<boolean>(false);
  const canEditTariff = useCallback(
    (tariffAssocItem: TariffAssoc) => {
      if (type === "EDIT" && tariffAssocItem) {
        const hasRole = UserService.hasResourceRole(
          [Roles.PricesWriteAdvanced],
          "appsync"
        );
        console.log(hasRole);
        if (tariffAssocItem?.Active || tariffAssoc?.Expired) {
          if (hasRole) {
            return true;
          }
          return false;
        }

        return true;
      }
      return true;
    },
    [tariffAssoc, type]
  );

  useEffect(() => {
    if (!canEditTariff(tariffAssoc)) {
      setIsOpenAlarmPopup(true);
    }
  }, [type, canEditTariff, tariffAssoc]);
  return (
    <Flex fullSize maxWidth="100%">
      {isOpenAlarmPopup ? (
        <Dialog
          withCloseButton={false}
          closeOnClickAway={false}
          closeOnPressEscape={false}
          height="200px"
          isOpen={isOpenAlarmPopup}
          setIsOpen={setIsOpenAlarmPopup}
        >
          <DialogBody>
            <Flex
              flexShrink={0}
              flexWrap="wrap"
              fullSize
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                alignItems="center"
                display="flex"
                textAlign="center"
                flexShrink={0}
                variant="body30"
              >
                <Box mr={2}>
                  <Alarm size="5" fill="#3D729C" />
                </Box>{" "}
                {t(
                  `Since this tariff is ${
                    tariffAssoc?.Expired ? "expired" : "Active"
                  }, it can't be modified. Create a new tariff or a clone instead . `
                )}
              </Typography>
            </Flex>
          </DialogBody>
          <DialogFooter style={{ justifyContent: "center" }}>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Flex flexDirection="row">
                <Button
                  variant="success"
                  $size="default"
                  onClick={() => navigate("/tariffs/create")}
                >
                  {t("create new tariff")}
                </Button>
                <Button
                  variant="primary"
                  ml={2}
                  $size="default"
                  onClick={() => navigate(`/tariffs/${priceUid}/clone`)}
                >
                  {t("clone tariff")}
                </Button>
              </Flex>
              <Button
                variant="secondary"
                ml={2}
                $size="default"
                onClick={() => navigate("/tariffs")}
              >
                {t("back to tariffs")}
              </Button>
            </Flex>
          </DialogFooter>
        </Dialog>
      ) : null}
      {redirect ? <RedirectTo /> : null}
      {loading && (
        <LoadingLayer>
          <SimpleLoader />
        </LoadingLayer>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitForm)} style={{ height: "100%" }}>
          <Flex
            fullSize
            bg="white"
            flexWrap="wrap"
            flexDirection="row"
            style={{ gap: "10px 10px" }}
          >
            <Flex py={4} px={3} minWidth={300} bg="primary" flex={1}>
              <Flex
                pb={3}
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Typography upperCase variant={"title40"} color="white">
                  {" "}
                  {type === "NEW" ? t("Create new tariff") : null}
                </Typography>
              </Flex>
              <Flex
                flexWrap="wrap"
                flexDirection="row"
                style={{ gap: "10px 10px" }}
              >
                <Flex width="100%">
                  <InputLabel labelColor="white" children="Label" required />
                  <Input
                    style={{
                      display: "flex",
                    }}
                    disabled={!canEdit || autoGenerate}
                    error={errors?.label?.message}
                    placeholder="Label"
                    {...register("label")}
                    errorProps={{
                      color: "white",
                      borderBottom: "1px solid red",
                    }}
                    // maxLength={12}
                  />
                  {type === "NEW" ? (
                    <Flex mt={2}>
                      <Checkbox
                        variant="dark"
                        {...register("autoGenerate")}
                        spanProps={{
                          color: "white",
                          display: "flex",
                          tooltipText:
                            "the label will be auto generated from ( cpoParty + type + unique number )",
                          alignItems: "center",
                        }}
                        $size="default"
                      >
                        Auto Generate Label <Info fill="white" />
                      </Checkbox>
                    </Flex>
                  ) : null}
                </Flex>
                <Flex flex={1} minWidth={300}>
                  <InputLabel labelColor="white" children="type" required />
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPosition="fixed"
                        isDisabled={
                          !canEdit ||
                          type === "EDIT" ||
                          (platformProtocol ? true : false)
                        }
                        placeholder="Select type"
                        controlShouldRenderValue
                        options={groupedTariffTypeOptions || []}
                        error={errors.type ? " Required " : ""}
                        errorProps={{
                          color: "white",
                          borderBottom: "1px solid red",
                        }}
                        {...field}
                      />
                    )}
                  />
                </Flex>
                <Flex flex={1} minWidth={250}>
                  <InputLabel
                    labelColor="white"
                    children="Valid From"
                    required
                  />
                  <Input
                    min={getInputDateFormat(addDays(new Date(), 1))}
                    type="date"
                    disabled={
                      !canEdit || (type === "EDIT" && tariffAssoc?.Active)
                    }
                    error={errors?.validFrom?.message}
                    placeholder="12/3/2020"
                    {...register("validFrom")}
                    errorProps={{
                      color: "white",
                      borderBottom: "1px solid red",
                    }}
                  />{" "}
                </Flex>

                <Flex flex={1} minWidth={250}>
                  <InputLabel labelColor="white" children="Valid To" />
                  <Input
                    min={watch("validFrom")}
                    disabled={!canEdit}
                    type="date"
                    error={errors?.validTo?.message}
                    placeholder="12/3/2050"
                    {...register("validTo", { required: false })}
                    errorProps={{
                      color: "white",
                      borderBottom: "1px solid red",
                    }}
                  />
                </Flex>

                <CpoDropdown
                  partyId={partyId}
                  partyUid={partyUid}
                  platformProtocol={platformProtocol}
                  canEdit={canEdit}
                />
                {partyUid ||
                ![
                  "Business Solution",
                  "CPO Conto Terzi",
                  "Be Charge - Eccezioni",
                ].includes(watchType?.value) ? null : (
                  <CsoDropdown
                    partyId={partyId}
                    partyUid={partyUid}
                    platformProtocol={platformProtocol}
                    canEdit={canEdit}
                  />
                )}
                {[
                  "Business Solution",
                  "CPO Conto Terzi",
                  "Be Charge - Eccezioni",
                ].includes(watchType?.value) && watch("cso")?.length ? (
                  <LocationDropdown
                    partyId={partyId}
                    partyUid={partyUid}
                    platformProtocol={platformProtocol}
                    canEdit={canEdit}
                  />
                ) : null}
                {![
                  "DEFAULT",
                  "HUBJECT_O2A",
                  "GIREVE_O2A",
                  "Business Solution",
                  "CPO Conto Terzi",
                  "Be Charge - Eccezioni",
                ].includes(watchType?.value) ? (
                  <EmspPartyDropdown
                    canEdit={canEdit}
                    partyId={partyId}
                    partyUid={partyUid}
                    platformProtocol={platformProtocol}
                  />
                ) : null}

                {canEdit ? (
                  <Button
                    position="sticky"
                    top={0}
                    variant="white"
                    mt={3}
                    block
                    loading$={loadingPublishTariff || loadingPublishTariffAssoc}
                    onClick={handleSubmit(onSubmitForm)}
                    $size="large"
                  >
                    Save
                  </Button>
                ) : null}
              </Flex>
            </Flex>
            <Flex fullSize flexShrink={0} minWidth={700} bg="white" flex={2}>
              <VerticalScrollContainer>
                <Flex fullSize p={4}>
                  <DefaultPrice canEdit={canEdit} />
                  {[
                    "DEFAULT",
                    "Business Solution",
                    "CPO Conto Terzi",
                    "Be Charge - Eccezioni",
                  ].includes(watchType?.value) ? null : (
                    <CustomPrice canEdit={canEdit} />
                  )}
                </Flex>
              </VerticalScrollContainer>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};
