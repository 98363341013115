







import React from "react";
import Svg from "../../UI/Svg";
type Props = {
	size?: string;
	fill?: string;
};
const CSOIcon: React.FC<Props> = ({ fill, size, ...props }) => {
	return (
		<Svg fill={fill}
			{...props}
			iconSize={size} id="Capa_1" xmlns="http://www.w3.org/2000/svg"
			width="800px" height="800px" viewBox="0 0 568.478 568.478"
			xmlSpace="preserve"
		>
			<g>
				<g>
					<path d="M284.237,284.24c68.666,0,124.355-63.636,124.355-142.119S352.903,0,284.237,0c-68.667,0-124.355,63.636-124.355,142.122
			S215.571,284.24,284.237,284.24z M216.195,103.397c8.537,22.311,35.6,38.724,68.042,38.724c32.442,0,88.826-3.816,88.826,17.766
			c0,49.061-39.762,88.826-88.826,88.826c-49.027,0-88.826-39.765-88.826-88.826C195.412,138.303,203.429,118.786,216.195,103.397z"
					/>
					<path d="M497.418,426.358c0-58.88-47.708-106.589-106.589-106.589h-81.512l7.983,7.415c4.183,3.886,4.183,10.187,0,14.072
			l-17.026,15.814c-4.183,3.886-5.957,10.948-3.962,15.774l32.818,79.37c1.995,4.825,1.145,12.372-1.9,16.854l-37.476,55.169
			c-3.045,4.482-7.981,4.482-11.025,0l-37.476-55.169c-3.044-4.482-3.896-12.029-1.9-16.854l32.819-79.37
			c1.995-4.826,0.22-11.889-3.963-15.774l-17.026-15.814c-4.183-3.886-4.183-10.187,0-14.072l7.984-7.415h-81.519
			c-58.881,0-106.589,47.709-106.589,106.59v106.589c0,19.639,15.89,35.529,35.529,35.529h355.3
			c19.639,0,35.529-15.891,35.529-35.529V426.358L497.418,426.358z"/>
				</g>
			</g>
		</Svg>
	);
};

export default CSOIcon;