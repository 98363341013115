import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconServiceStation: React.FC<Props> = ({
  fill,
  size,
  ...props
}) => {
  return (
    <Svg
      width="32"
      height="35"
      viewBox="0 0 32 35"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M0.121094 34.4547V3.83438C0.121094 2.78181 0.495874 1.88074 1.24543 1.13118C1.995 0.381616 2.89606 0.00683594 3.94864 0.00683594H15.4313C16.4838 0.00683594 17.3849 0.381616 18.1345 1.13118C18.884 1.88074 19.2588 2.78181 19.2588 3.83438V17.2308H21.1726C22.2252 17.2308 23.1262 17.6056 23.8758 18.3551C24.6254 19.1047 25.0001 20.0058 25.0001 21.0583V29.6703C25.0001 30.2125 25.1835 30.6671 25.5503 31.0339C25.9172 31.4007 26.3717 31.5841 26.9139 31.5841C27.4561 31.5841 27.9107 31.4007 28.2775 31.0339C28.6443 30.6671 28.8277 30.2125 28.8277 29.6703V15.8911C28.5406 16.0506 28.2376 16.1543 27.9186 16.2021C27.5997 16.25 27.2648 16.2739 26.9139 16.2739C25.5743 16.2739 24.442 15.8114 23.517 14.8864C22.592 13.9614 22.1295 12.8291 22.1295 11.4895C22.1295 10.4688 22.4086 9.55178 22.9668 8.73842C23.5249 7.92507 24.2665 7.34296 25.1915 6.99211L21.1726 2.97318L23.1821 0.963722L30.263 7.8533C30.7415 8.33175 31.1003 8.88993 31.3395 9.52785C31.5787 10.1658 31.6983 10.8196 31.6983 11.4895V29.6703C31.6983 31.0099 31.2358 32.1423 30.3109 33.0673C29.3859 33.9922 28.2535 34.4547 26.9139 34.4547C25.5743 34.4547 24.442 33.9922 23.517 33.0673C22.592 32.1423 22.1295 31.0099 22.1295 29.6703V20.1014H19.2588V34.4547H0.121094ZM3.94864 13.4032H15.4313V3.83438H3.94864V13.4032ZM26.9139 13.4032C27.4561 13.4032 27.9107 13.2198 28.2775 12.853C28.6443 12.4862 28.8277 12.0317 28.8277 11.4895C28.8277 10.9472 28.6443 10.4927 28.2775 10.1259C27.9107 9.7591 27.4561 9.5757 26.9139 9.5757C26.3717 9.5757 25.9172 9.7591 25.5503 10.1259C25.1835 10.4927 25.0001 10.9472 25.0001 11.4895C25.0001 12.0317 25.1835 12.4862 25.5503 12.853C25.9172 13.2198 26.3717 13.4032 26.9139 13.4032ZM3.94864 30.6272H15.4313V17.2308H3.94864V30.6272Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
