import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CsoState = {
      selectedCso: any | null;
};
const initialState: CsoState = {
      selectedCso: null,
};

const cso = createSlice({
      name: "cso",
      initialState,
      reducers: {
            selectCso: (state, action: PayloadAction<CsoState | null>) => {
                  state.selectedCso = action.payload;
            },
      },
});

export const { selectCso } = cso.actions;
export default cso.reducer;
