import { getTime } from "date-fns";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  Flex,
  Input,
  InputLabel,
  LoaderWithFallback,
  Select,
  SimpleLoader,
  Typography,
} from "../../../../../shared/UI";
import { usePublishDiagnosticUpload } from "../../../gql/mutations/usePublishDiagnosticUpload";
import { usePublishEvcReboot } from "../../../gql/mutations/usePublishEvcReboot";
import { usePublishRemoteRefreshStatus } from "../../../gql/mutations/usePublishRemoteRefreshStatus";
import { useDiagnosticUploadProcessed } from "../../../gql/queries/useDiagnosticUploadProcessed";
import { useGetEvcRebootProcessed } from "../../../gql/queries/useGetEvcRebootProcessed";
import { useRefreshStatusProcessed } from "../../../gql/queries/useRefreshStatusProcessed";
import Refresh from "../../../../../shared/assets/svg/Refresh";
import { FirmwareUpdateCommand } from "./commands/FirmwareUpdateCommand";
import RenderOnRole from "../../../../../shared/UI/RenderOnRole";
import { Roles } from "@bepower/greta-types";
import { TriggerStatusNotification } from "./commands/TriggerStatusNotification";
import { t } from "i18next";

export const EvcCommands = () => {
  const [isLoadingResetEvc, setIsLoadingResetEvc] = useState<boolean>(false);
  const [isrefreshingStatus, setIsrefreshingStatus] = useState<boolean>(false);
  const [isDownloadDiagnostic, setIsDownloadDiagnostic] =
    useState<boolean>(false);

  const { publishRemoteRefreshStatus } = usePublishRemoteRefreshStatus();
  const { getRefreshStatusProcessed } = useRefreshStatusProcessed();
  const { evc, refetch, loading }: any = useOutletContext();
  const { publishEvcReboot } = usePublishEvcReboot();
  const { getEvcRebootProcessed } = useGetEvcRebootProcessed();
  const { publishDiagnosticUpload } = usePublishDiagnosticUpload();
  const { getDiagnosticUploadProcessed } = useDiagnosticUploadProcessed();

  const { register, control, watch } = useForm<any>({
    // resolver: yupResolver(resetEvcSchema),
    shouldFocusError: true,
  });

  const resetEvc = () => {
    setIsLoadingResetEvc(true);
    const resetType = watch("resetType");
    if (resetType && evc?.uid) {
      publishEvcReboot({
        variables: {
          input: {
            evcUid: evc?.uid,
            type: resetType?.value,
          },
        },
      }).then((res) => {
        const requestUid = res.data?.publishEvcReboot?.requestUid;
        if (requestUid) {
          setTimeout(() => {
            getEvcRebootProcessed({
              variables: {
                filter: {
                  requestUid: requestUid,
                },
              },
            })
              .then((res) => {
                const response = res?.data?.EvcRebootProcessed?.results?.[0];
                if (response?.status === true) {
                  toast.success("Rebooted Successfully");
                } else {
                  toast.error(response?.info);
                }
              })
              .finally(() => {
                setIsLoadingResetEvc(false);
              });
          }, 2000);
        }
      });
    }
  };

  const handleSendRefreshStatus = () => {
    const kind = watch("kind")?.value;
    setIsrefreshingStatus(true);
    if (evc?.uid && kind) {
      publishRemoteRefreshStatus({
        variables: {
          input: {
            evcUid: evc?.uid,
            kind: kind,
          },
        },
      })
        .then((res) => {
          const requestUid = res?.data?.publishRemoteRefreshStatus?.requestUid;
          if (requestUid) {
            setTimeout(() => {
              getRefreshStatusProcessed({
                variables: {
                  filter: {
                    requestUid: requestUid,
                  },
                },
              })
                .then((res) => {
                  if (res.data?.RefreshStatusProcessed?.results?.[0]?.status) {
                    toast.success("Status Triggered Successfully");
                  } else {
                    toast.error(
                      res.data?.RefreshStatusProcessed?.results?.[0]?.info
                    );
                  }
                })
                .catch((error) => {
                  setIsrefreshingStatus(false);
                })
                .finally(() => {
                  setIsrefreshingStatus(false);
                });
            }, 2000);
          }
        })
        .catch((error) => {
          setIsrefreshingStatus(false);
        });
    }
  };

  const sendDownloadDiagnostic = () => {
    const evcUid = evc?.uid;
    const from = watch("from");
    const to = watch("to");
    const ftpUrl = watch("ftpUrl")?.value;
    const interval = watch("interval");
    const retries = watch("retries");

    if (evcUid && ftpUrl) {
      setIsDownloadDiagnostic(true);
      publishDiagnosticUpload({
        variables: {
          input: {
            evcUid: evcUid,
            ftpUrl: ftpUrl,
            ...(from ? { from: getTime(new Date(from)) } : {}),
            ...(to ? { to: getTime(new Date(to)) } : {}),
            ...(interval ? { interval: interval } : {}),
            ...(retries ? { retries: retries } : {}),
          },
        },
      }).then((res) => {
        const requestUid = res.data?.publishDiagnosticUpload?.requestUid;
        if (requestUid) {
          setTimeout(() => {
            getDiagnosticUploadProcessed({
              variables: {
                filter: {
                  requestUid: requestUid,
                },
              },
            })
              .then((res) => {
                if (res.data?.DiagnosticUploadProcessed?.results?.[0]?.status) {
                  toast.success("Diagnostics Download Successfully");
                } else {
                  toast.error(
                    res.data?.DiagnosticUploadProcessed?.results?.[0]?.info
                  );
                }
              })
              .catch((error) => {
                setIsDownloadDiagnostic(false);
              })
              .finally(() => {
                setIsDownloadDiagnostic(false);
              });
          }, 2000);
        }
      });
    }
  };

  const refetchLocation = () => {
    refetch();
  };
  return (
    <LoaderWithFallback
      errorMsg="Error"
      hasError={!evc}
      LoadingMsg={<SimpleLoader />}
      loading={loading}
    >
      <Flex fullSize>
        <Flex alignItems={"flex-end"} width={"100%"} p={2}>
          <Button fixedSize onClick={refetchLocation}>
            {" "}
            <Refresh />
          </Button>
        </Flex>
        <Flex
          flexWrap={"wrap"}
          flexDirection={"row"}
          p={3}
          style={{ gap: "20px 20px" }}
        >
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}> Reset</Typography>
            <Box mt={2}>
              <Controller
                name="resetType"
                control={control}
                render={({ field }) => (
                  <Select
                    options={[
                      { label: "Soft", value: "SOFT" },
                      { label: "Hard", value: "HARD" },
                    ]}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box my={4}>
              <Button
                disabled={!watch("resetType") || isLoadingResetEvc}
                onClick={resetEvc}
                block
                loading$={isLoadingResetEvc}
                $size="default"
              >
                {t ('Send')}
              </Button>
            </Box>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <TriggerStatusNotification withOptions />
          </Card>
          <RenderOnRole roles={[Roles.CommandsAdvanced]}>
            <Card
              bg="white"
              p={3}
              display={"flex"}
              flexDirection={"column"}
              flex={1}
              minWidth={400}
            >
              <Typography variant={"title20"}> Download diagnostic</Typography>

              <Box mt={2}>
                <InputLabel children="FTP URL" required />
                <Controller
                  name="ftpUrl"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={[
                        {
                          label: "Logs Folder",
                          value:
                            "ftp://ftp_iot_user:tZLus05GTa0WP@172.16.202.35/logs",
                        },
                      ]}
                      placeholder="Select FTP url"
                      {...field}
                    />
                  )}
                />
              </Box>
              <Flex
                width={"100%"}
                flexDirection={"row"}
                style={{ gap: "10px 10px" }}
                mt={2}
              >
                <Box>
                  <InputLabel children="Retries" />
                  <Input
                    type="number"
                    placeholder="0"
                    {...register("retries")}
                  />
                </Box>
                <Box>
                  <InputLabel children="Interval (sec)" />
                  <Input
                    type="number"
                    placeholder="0"
                    {...register("interval")}
                  />
                </Box>
              </Flex>
              <Flex
                width={"100%"}
                flexDirection={"row"}
                style={{ gap: "10px 10px" }}
                mt={2}
              >
                <Box flex={1}>
                  <InputLabel children="From" />
                  <Input type="date" placeholder="0" {...register("from")} />
                </Box>
                <Box flex={1}>
                  <InputLabel children="To" />
                  <Input type="date" placeholder="0" {...register("to")} />
                </Box>
              </Flex>
              <Box my={4}>
                <Button
                  block
                  $size="default"
                  disabled={isDownloadDiagnostic}
                  loading$={isDownloadDiagnostic}
                  onClick={sendDownloadDiagnostic}
                >
                  {t('send')}
                </Button>
              </Box>
            </Card>
            <FirmwareUpdateCommand />
          </RenderOnRole>
        </Flex>
      </Flex>
    </LoaderWithFallback>
  );
};
