import { gql, useLazyQuery } from "@apollo/client";

export const GET_TARIFF = gql`
  query Tariff($uid: String!) {
    Tariff(uid: $uid) {
      pricing {
        id
        conditions {
          upon
          eq
          gt
          gte
          in
          lt
          lte
          ne
          nin
          type
          path
        }
        elements {
          id
          priceComponent {
            price
            type
          }
          restrictions {
            dow
            fromDate
            fromH
            maxDuration
            maxKwh
            maxParkingTime
            maxPower
            minDuration
            minKwh
            minParkingTime
            minPower
            toDate
            toH
          }
        }
      }
      uid
      updatedAt
    }
  }
`;
export const useGetTariff = () => {
  const [
    executeGetTariff,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_TARIFF, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
    errorPolicy: "ignore",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loadingGetTariff: loading,
    executeGetTariff,
    error,
    tariff: data?.Tariff,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
