/** @format */

import { t } from "i18next";
import * as yup from "yup";

const today = new Date();
today.setHours(12, 0, 0, 0);
export const TariffSchema = yup.object().shape({
  label: yup.string().required("required"),
  autoGenerate: yup.boolean().optional(),
  type: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required("Required")
    .typeError("Required")
    .nullable(),
  validFrom: yup
    .date()
    // .min(today, " Valid From Date Cannot be in the past")
    .required("Required"),

  validTo: yup
    .date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .nullable()
    .default(undefined)
    .min(yup.ref("validFrom"), "Valid to field must be later than Valid from"),
  emspParty: yup.array().when("type", (type: any, schema) => {
    if (type?.value === "GIREVE_BLT" || type?.value === "HUBJECT_BLT") {
      return yup
        .array()
        .required("Choose at least one eMSP")
        .of(
          yup.object({
            label: yup.string().required(),
            value: yup.string().required(),
          })
        )
        .min(1, "at least one eMSP should be selected");
    }
    return schema;
  }),
  cpoPartyUids: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required("Required")
    .nullable(),

  priceForCountry: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          country: yup
            .array()
            .of(
              yup.object({
                label: yup.string().required(),
                value: yup.string().required(),
              })
            )
            .required("Required")
            .typeError("Required")
            .min(1, "at least one country should be selected"),
          AC_energy: yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),

          AC_time: yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          AC_parkingTime: yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          AC_flat: yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "DC < 100kW_energy": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "DC < 100kW_time": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "DC < 100kW_parkingTime": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "DC < 100kW_flat": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),

          "100kW ≤ DC < 150kW_energy": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "100kW ≤ DC < 150kW_time": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "100kW ≤ DC < 150kW_parkingTime": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "100kW ≤ DC < 150kW_flat": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),

          "DC ≥ 150kW_energy": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "DC ≥ 150kW_time": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "DC ≥ 150kW_parkingTime": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          "DC ≥ 150kW_flat": yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => (Number.isNaN(value) ? undefined : value)),
          isExist: yup.boolean().notRequired(),
        })
        .required("Required")
    )
    .optional(),

  // new
  AC_energy: yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),

  AC_time: yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  AC_parkingTime: yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  AC_flat: yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "DC < 100kW_energy": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "DC < 100kW_time": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "DC < 100kW_parkingTime": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "DC < 100kW_flat": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),

  "100kW ≤ DC < 150kW_energy": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "100kW ≤ DC < 150kW_time": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "100kW ≤ DC < 150kW_parkingTime": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "100kW ≤ DC < 150kW_flat": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),

  "DC ≥ 150kW_energy": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "DC ≥ 150kW_time": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "DC ≥ 150kW_parkingTime": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  "DC ≥ 150kW_flat": yup
    .string()
    .nullable()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
});

type Prices = {
  AC_energy: string;
  AC_time: string;
  AC_parkingTime: string;
  AC_flat: string;

  "DC < 100kW_energy": string;
  "DC < 100kW_time": string;
  "DC < 100kW_parkingTime": string;
  "DC < 100kW_flat": string;

  "100kW ≤ DC < 150kW_energy": string;
  "100kW ≤ DC < 150kW_time": string;
  "100kW ≤ DC < 150kW_parkingTime": string;
  "100kW ≤ DC < 150kW_flat": string;

  "DC ≥ 150kW_energy": string;
  "DC ≥ 150kW_time": string;
  "DC ≥ 150kW_parkingTime": string;
  "DC ≥ 150kW_flat": string;
};
export interface CountryPriceElement extends Prices {
  country: { label: string; value: string }[];

  isExist: boolean;
}

export interface FormValues extends Prices {
  priceForCountry?: CountryPriceElement[];
  label: string;
  type: { label: string; value: string };
  validFrom: Date | number | any;
  validTo: Date | number | any;
  // country: { label: string, value: string }[];
  cpoPartyUids: { label: string; value: string } | null;
  emspParty: {
    label: string;
    value: string;
  }[];
  locations: {
    label: string;
    value: string;
  }[];

  autoGenerate: boolean;
  cso: string[];
}
