import Svg from "../../UI/Svg";
import React from "react";

type Props = {
  size?: string;
  fill?: string;
};

const Type1: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 597 512"  fill={fill}
    iconSize={size}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M 108.648438 0 C 95.859375 0 83.441406 4.90625 74.945312 14.46875 C 28.320312 66.949219 0 136.070312 0 211.808594 C 0 375.917969 132.960938 508.953125 296.976562 508.953125 C 460.992188 508.953125 593.953125 375.917969 593.953125 211.808594 C 593.953125 136.070312 565.632812 66.949219 519.007812 14.46875 C 510.511719 4.90625 498.09375 0 485.304688 0 Z M 145.707031 315.023438 C 145.707031 328.492188 134.796875 339.40625 121.339844 339.40625 C 107.882812 339.40625 96.972656 328.492188 96.972656 315.023438 C 96.972656 301.558594 107.882812 290.644531 121.339844 290.644531 C 134.796875 290.644531 145.707031 301.558594 145.707031 315.023438 Z M 493.066406 339.40625 C 506.523438 339.40625 517.433594 328.492188 517.433594 315.023438 C 517.433594 301.5625 506.523438 290.644531 493.066406 290.644531 C 479.609375 290.644531 468.699219 301.5625 468.699219 315.023438 C 468.699219 328.492188 479.609375 339.40625 493.066406 339.40625 Z M 525.265625 108.71875 C 525.265625 142.210938 498.128906 169.359375 464.660156 169.359375 C 431.183594 169.359375 404.050781 142.210938 404.050781 108.71875 C 404.050781 75.230469 431.183594 48.078125 464.660156 48.078125 C 498.128906 48.078125 525.265625 75.230469 525.265625 108.71875 Z M 141.417969 169.359375 C 174.890625 169.359375 202.023438 142.210938 202.023438 108.71875 C 202.023438 75.226562 174.890625 48.078125 141.417969 48.078125 C 107.945312 48.078125 80.808594 75.226562 80.808594 108.71875 C 80.808594 142.210938 107.945312 169.359375 141.417969 169.359375 Z M 363.644531 399.800781 C 363.644531 433.289062 336.511719 460.441406 303.039062 460.441406 C 269.566406 460.441406 242.429688 433.289062 242.429688 399.800781 C 242.429688 366.308594 269.566406 339.15625 303.039062 339.15625 C 336.511719 339.15625 363.644531 366.308594 363.644531 399.800781 Z M 363.644531 399.800781 "
      />
    </Svg>
  );
};

export default Type1;
