import Svg from "../../UI/Svg";
type Props = {
  iconSize?: string;
  fill?: string;
  stroke?: string;
};
const Maximize = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M0 20V12.8571H2.85714V17.1429H7.14286V20H0ZM0 7.14286V0H7.14286V2.85714H2.85714V7.14286H0ZM12.8571 20V17.1429H17.1429V12.8571H20V20H12.8571ZM17.1429 7.14286V2.85714H12.8571V0H20V7.14286H17.1429Z"
    />
  </Svg>
);
export default Maximize;
