/** @format */

import { gql, useLazyQuery } from "@apollo/client";
import debounce from "debounce";
import { useCallback, useState } from "react";
import { BecEvc, CompetitorEvc } from "../slices/EvcDataContextProvider";

const GET_SITE_SELECTION_EVCS = gql`
  query SiteSelectionEvcs(
    $filter: SiteSelectionEvcFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    SiteSelectionEvcs(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        projectWcd
        cpDc
        locationCountry
        evcModelName
        projectPhase
        installationCreatedAt
        uuid
        projectNumberOfEvc
        cpAc
        evcType
        projectCod
        cp
        evcModelCapacity
        cso
        evcModelChargeMode
        installationCod
        locationMunicipality
        locationRegion
        locationProvince
        locationProvinceCode
        locationZip
        areaKm2
        firstChargingDate
        ebitdaDate
        locationAddress
        avgMonthlyNSessions
        avgMonthlyKwh
        createdAt
        point {
          lat
          lon
        }
      }
      total
      pagination {
        cursor
        last
      }
    }
  }
`;

export const useGetSiteSelectionEvcs = () => {
  const [
    getSiteSelectionEvcs,
    { loading, error, data, refetch, fetchMore, startPolling },
  ] = useLazyQuery<{
    SiteSelectionEvcs: {
      results: {
        projectWcd: number;
        cpDc: number;
        locationCountry: string;
        evcModelName: string;
        projectPhase: string;
        installationCreatedAt: number;
        uuid: string;
        projectNumberOfEvc: number;
        cpAc: number;
        evcType: string;
        projectCod: number;
        cp: number;
        evcModelCapacity: number;
        cso: string;
        evcModelChargeMode: string;
        installationCod: number;
        locationMunicipality: string;
        locationRegion: string;
        locationProvince: string;
        locationProvinceCode: string;
        locationZip: number;
        areaKm2: number;
        firstChargingDate: number;
        ebitdaDate: number;
        locationAddress: string;
        avgMonthlyNSessions: number;
        avgMonthlyKwh: number;
        createdAt: number;
        point: {
          lat: number;
          lon: number;
        };
      }[];
      total: number;
      pagination: {
        cursor: string;
        last: boolean;
      };
    };
  }>(GET_SITE_SELECTION_EVCS, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    returnPartialData: false,
  });

  const [loadingAll, setLoadingAll] = useState(false);
  const [becEvcData, setBecEvcData] = useState<BecEvc[]>([]);
  const [competitorEvcData, setCompetitorEvcData] = useState<CompetitorEvc[]>(
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAll = useCallback(
    debounce(
      async (options: {
        bound?: google.maps.LatLngBounds;
        provinceCode?: string;
      }) => {
        const pageLimit = 1000;

        const variables = {
          filter: {
            locationProvinceCode: options.provinceCode,
            bound: options.bound && {
              top_left: {
                lat: options.bound.getNorthEast().lat(),
                lon: options.bound.getSouthWest().lng(),
              },
              bottom_right: {
                lat: options.bound.getSouthWest().lat(),
                lon: options.bound.getNorthEast().lng(),
              },
            },
          },
          sort: {
            field: "uuid",
          },
          pagination: {
            limit: pageLimit,
          },
        };

        const data = await getSiteSelectionEvcs({
          variables: variables,
        });
        const { pagination } = data?.data?.SiteSelectionEvcs ?? {};

        setLoadingAll(true);
        try {
          let len = data.data?.SiteSelectionEvcs.results.length ?? 0;

          let pag = pagination;
          let hasMore = len < (data.data?.SiteSelectionEvcs.total ?? 0);

          const dt = [data.data?.SiteSelectionEvcs.results ?? []];

          while (hasMore && pag?.last && pag?.cursor) {
            const r = await fetchMore({
              variables: {
                ...variables,
                pagination: {
                  after: pag?.cursor,
                  limit: pageLimit,
                },
              },
            });

            pag = r.data?.SiteSelectionEvcs?.pagination;
            hasMore = !!pag.cursor;
            len = r.data?.SiteSelectionEvcs?.results?.length ?? 0 + len;
            dt.push(r.data?.SiteSelectionEvcs?.results ?? []);
          }

          const results = dt.flat().map(
            (evc) =>
              ({
                evcUuid: evc.uuid,
                latitude: evc.point.lat,
                longitude: evc.point.lon,
                locationMunicipality: evc.locationMunicipality,
                installationCod: new Date(Math.floor(evc.installationCod)),
                evcModelChargeMode: evc.evcModelChargeMode as "AC" | "DC",
                cpAc: evc.cpAc,
                cpDc: evc.cpDc,
                cp: evc.cp,
                locationAddress: evc.locationAddress,
                avgMonthlyNSessions: evc.avgMonthlyNSessions,
                avgMonthlyKwh: evc.avgMonthlyKwh,
                locationCountry: evc.locationCountry,
                evcModelName: evc.evcModelName,
                projectPhase: (evc.projectPhase as any) ?? "unknown",
                installationCreatedAt: new Date(
                  Math.floor(evc.installationCreatedAt)
                ),
                projectWcd: new Date(Math.floor(evc.projectWcd)),
                projectCod: new Date(Math.floor(evc.projectCod)),
                evcType: evc.evcType,
                projectNumberOfEvc: evc.projectNumberOfEvc ?? 0,
                evcModelCapacity: evc.evcModelCapacity,
                cso: `${evc.cso}` ?? "unknown",
                locationRegion: evc.locationRegion,
                locationProvince: evc.locationProvince,
                locationProvinceCode: evc.locationProvinceCode,
                locationZip: evc.locationZip,
                areaKm2: evc.areaKm2,
                firstChargingDate: new Date(Math.floor(evc.firstChargingDate)),
                ebitdaDate: new Date(Math.floor(evc.ebitdaDate)),
              } as BecEvc)
          );

          setBecEvcData(results.filter((evc) => evc.cso === "becharge"));
          setCompetitorEvcData(
            results
              .filter((evc) => evc.cso !== "becharge")
              .map(
                (evc) =>
                  ({
                    areaKm2: evc.areaKm2,
                    cso: evc.cso,
                    evcModelChargeMode: evc.evcModelChargeMode,
                    latitude: evc.latitude,
                    locationMunicipality: evc.locationMunicipality,
                    locationProvince: evc.locationProvince,
                    locationProvinceCode: evc.locationProvinceCode,
                    locationRegion: evc.locationRegion,
                    locationZip: evc.locationZip,
                    projectPhase: evc.projectPhase as "Operation",
                    ebitdaDate: evc.ebitdaDate,
                    evcUuid: evc.evcUuid,
                    longitude: evc.longitude,
                  } satisfies CompetitorEvc)
              )
          );
        } finally {
          setLoadingAll(false);
        }
        return data;
      },
      500
    ),
    [fetchMore, getSiteSelectionEvcs]
  );

  return {
    loading: loading || loadingAll,
    error,
    getSiteSelectionEvcs,
    becEvcs: becEvcData,
    competitorsEvcs: competitorEvcData,
    count: data?.SiteSelectionEvcs.total ?? 0,
    refetch,
    startPolling,
    fetchMore,
    fetchAll,
  };
};
