import { gql, useLazyQuery } from "@apollo/client";

export const GET_EMSP_PARTIES = gql`
  query EmspParties(
    $filter: EmspPartyFilterParams
    $pagination: PaginationParams
    $sort: PlatformPartySortingParams
  ) {
    EmspParties(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        PlatformParty {
          name
          uid
          partyId
          countryCode
          role
        }
      }
      total
    }
  }
`;

export const useGetEmspParties = () => {
  const [
    getEmspParties,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_EMSP_PARTIES, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    loadingGetEmspParties: loading,
    getEmspParties,
    error,
    count: data?.EmspParties?.total,
    emspParties: data?.EmspParties?.results,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
