import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconShopping: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      width="29"
      height="36"
      viewBox="0 0 29 36"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M4.30417 35.0309C3.35685 35.0309 2.54589 34.6936 1.87128 34.019C1.19668 33.3444 0.859375 32.5334 0.859375 31.5861V10.9174C0.859375 9.97006 1.19668 9.1591 1.87128 8.4845C2.54589 7.80989 3.35685 7.47259 4.30417 7.47259H7.74896C7.74896 5.57795 8.42356 3.95603 9.77277 2.60682C11.122 1.25761 12.7439 0.583008 14.6385 0.583008C16.5332 0.583008 18.1551 1.25761 19.5043 2.60682C20.8535 3.95603 21.5281 5.57795 21.5281 7.47259H24.9729C25.9202 7.47259 26.7312 7.80989 27.4058 8.4845C28.0804 9.1591 28.4177 9.97006 28.4177 10.9174V31.5861C28.4177 32.5334 28.0804 33.3444 27.4058 34.019C26.7312 34.6936 25.9202 35.0309 24.9729 35.0309H4.30417ZM4.30417 31.5861H24.9729V10.9174H21.5281V14.3622C21.5281 14.8502 21.3631 15.2592 21.0329 15.5894C20.7028 15.9195 20.2937 16.0846 19.8057 16.0846C19.3177 16.0846 18.9086 15.9195 18.5785 15.5894C18.2484 15.2592 18.0833 14.8502 18.0833 14.3622V10.9174H11.1937V14.3622C11.1937 14.8502 11.0287 15.2592 10.6986 15.5894C10.3684 15.9195 9.95936 16.0846 9.47135 16.0846C8.98334 16.0846 8.57427 15.9195 8.24414 15.5894C7.91402 15.2592 7.74896 14.8502 7.74896 14.3622V10.9174H4.30417V31.5861ZM11.1937 7.47259H18.0833C18.0833 6.52527 17.746 5.71431 17.0714 5.03971C16.3968 4.3651 15.5859 4.0278 14.6385 4.0278C13.6912 4.0278 12.8803 4.3651 12.2057 5.03971C11.531 5.71431 11.1937 6.52527 11.1937 7.47259Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
