import React, { PropsWithChildren, Suspense } from "react";
import { Center, SimpleLoader } from "../UI";

export const SuspensedView: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Center>
          <SimpleLoader />
        </Center>
      }
    >
      {children}
    </Suspense>
  );
};
