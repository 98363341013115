import { gql, useMutation } from "@apollo/client";

export const PUBLISH_CONFIGURATION_EVSE = gql(`
mutation publishConfigurationFetch($input: ConfigurationFetchInput!) {
  publishConfigurationFetch(input: $input) {
    requestUid
  }
}

`);

export const usePublishConfigurationFetch = () => {
  const [publishConfigurationFetch, { data, loading, error }] = useMutation(
    PUBLISH_CONFIGURATION_EVSE
  );
  return {
    publishConfigurationFetch,
    error,
    remoteStartChargeTxData: data?.publishConfigurationFetch,
    loading,
  };
};
