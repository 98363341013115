import { gql, useLazyQuery } from "@apollo/client";

export const EVSEACCESSGROUPS = gql`
  query EvseAccessGroups(
    $filter: FilterParams
    $pagination: PaginationParams
    $sort: EvseAccessGroupSortingParams
  ) {
    EvseAccessGroups(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        name
        description
        PlatformParty {
          name
          uid
        }
        EVSEs(pagination: { limit: 1000 }) {
          total
        }
        uid
      }
      total
      pagination {
        cursor
        last
      }
    }
  }
`;

export const useGetEVSEAccessGroups = () => {
  const [
    getEvseAccessGroups,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(EVSEACCESSGROUPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    errorPolicy: "ignore",
  });
  const fetchMore = () => {
    if (
      data?.EvseAccessGroups?.pagination.cursor &&
      data?.EvseAccessGroups?.results?.length < data.EvseAccessGroups?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.EvseAccessGroups?.pagination.cursor,
          },
        },
        updateQuery({ EvseAccessGroups: prevData }, { fetchMoreResult }) {
          if (prevData?.results?.length) {
            return {
              EvseAccessGroups: {
                results: [
                  ...prevData?.results,
                  ...fetchMoreResult?.EvseAccessGroups?.results,
                ],
                __typename: "EvseAccessGroupList",
                total: prevData?.total,
                pagination: fetchMoreResult?.EvseAccessGroups?.pagination,
              },
            };
          } else {
            return {
              EvseAccessGroups: {
                results: [...fetchMoreResult?.EvseAccessGroups?.results],
                __typename: "EvseAccessGroupList",
                total: prevData?.total,
                pagination: fetchMoreResult?.EvseAccessGroups?.pagination,
              },
            };
          }
        },
      });
    }
  };
  return {
    loading,
    getEvseAccessGroups,
    error,
    EvseAccessGroups: data?.EvseAccessGroups?.results,
    count: data?.EvseAccessGroups?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
