import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  ColumnShape,
  Flex,
  Input,
  InputLabel,
  Popover,
  Typography,
} from "../../../../../UI";
import {
  PopoverBody,
  PopoverHeader,
} from "../../../../../UI/popover/PopoverStyledElement";
import { Filter } from "../../../../../assets/svg";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { getTime } from "date-fns";

type Props = {
  column: ColumnShape<any>;
  searchValue: string;
};

export const FilterSessionsByDate: React.FC<Props> = ({
  column,
  searchValue,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState<boolean>(false);
  const { register, watch, handleSubmit, reset } = useForm<{
    from: Date;
    to: Date;
  }>();

  const handleReset = useCallback(() => {
    searchParams.delete(searchValue);
    setSearchParams(searchParams);
    reset();
  }, [reset, searchParams, searchValue, setSearchParams]);
  const onSubmit = (formData: { from: Date; to: Date }) => {
    const from = getTime(new Date(formData.from));
    const to = getTime(new Date(formData.to));
    if (from && to) {
      searchParams.set(searchValue, JSON.stringify({ from: from, to: to }));
      setSearchParams(searchParams);
    }
  };

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      onClick={(e) => e.stopPropagation()}
    >
      {column.title}

      <Popover position="bottom" status={status} setStatus={setStatus}>
        <Button
          variant={searchParams.get(searchValue) ? "success" : "primary"}
          fixedSize
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Filter fill="white" />
        </Button>
        <PopoverBody
          display={"flex"}
          flexDirection="column"
          width={600}
          maxWidth={600}
          maxHeight={300}
          minHeight={150}
          flexShrink={0}
        >
          <PopoverHeader flexShrink={0} minWidth={200}>
            <Typography fontWeight="bold" capitalizeFirstLetter >
              {t(`${column.title} `)}{t("filter")}
            </Typography>
          </PopoverHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex p={2} flexDirection={"row"} style={{ gap: "10px 10px" }}>
              <Box>
                <InputLabel children="from" required />
                <Input type="datetime-local" {...register("from")} />
              </Box>
              <Box>
                <InputLabel children="to" required />
                <Input type="datetime-local" {...register("to")} />
              </Box>{" "}
            </Flex>
            <Flex flexDirection={"row"} style={{ gap: "10px 10px" }}>
              <Button onClick={handleSubmit(onSubmit)}>Apply</Button>
              <Button onClick={handleReset} variant="secondary">
                reset
              </Button>
            </Flex>
          </form>
        </PopoverBody>
      </Popover>
    </Flex>
  );
};
