import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Flex } from "../UI";
import { Absolute } from "../UI/Absolute";

export const StyledAbs = styled(Absolute)`
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    > span svg {
      fill: white;
    }
    opacity: 1;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  > span {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
export const SectionContent: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      position="relative"
      borderRadius={5}
      minWidth={450}
      minHeight={400}
      p={3}
    >
      {children}
    </Flex>
  );
};
