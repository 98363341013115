import { gql, useLazyQuery } from "@apollo/client";

export const GET_PLATFORM_PARTY = gql`
  query PlatformParty($uid: String!) {
    PlatformParty(uid: $uid) {
      countryCode
      image {
        category
        height
        thumbnail
        type
        url
        width
      }
      name
      partyId
      phoneNumber
      # platform {
      #     countryCode
      #     partyId
      #     uid
      # }
      # protocol
      role
      uid
      updatedAt
      website
    }
  }
`;
export const useGetPlatformParty = (variables?: any) => {
  const [
    getPlatformParty,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_PLATFORM_PARTY, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getPlatformParty,
    error,
    platformParty: data?.PlatformParty,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
