import { gql, useLazyQuery } from "@apollo/client";

const EVSESLISTWITHACCESSGROUPS = gql`
  query EVSEsListWithAccessGroups(
    $filter: EvseFilterParams
    $pagination: PaginationParams
    $sort: EvseSortingParams
  ) {
    EVSEs(filter: $filter, pagination: $pagination, sort: $sort) {
      __typename
      results {
        __typename
        assetStatus
        emi3Id
        evcUid
        label
        number
        status
        uid
        updatedAt
        statusUpdatedAt
        userGroups
        AccessGroupAssociation {
          results {
            accessGroupUid
            status
          }
        }
        EVC {
          __typename
          uid
          label
          statusUpdatedAt
          Location {
            label
            Project {
              cod
              projectId
              name
            }
          }
        }
      }
      total
    }
  }
`;

export const useGetEVSEsListWithAccessGroups = () => {
  const [
    getEvsesListWithAccessGroups,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(EVSESLISTWITHACCESSGROUPS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const fetchMore = (page: number) => {
    if (data?.EVSEs && data?.EVSEs?.total > data.EVSEs?.results.length) {
      fetchMoreItems({
        variables: {
          pagination: {
            page: page,
            limit: 20,
          },
        },
        updateQuery({ EVSEs: prevData }, { fetchMoreResult }) {
          if (prevData?.results?.length) {
            return {
              EVSEs: {
                results: [
                  ...prevData?.results,
                  ...fetchMoreResult?.EVSEs?.results,
                ],
                __typename: fetchMoreResult.EVSEs.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.EVSEs?.pagination,
              },
            };
          } else {
            return {
              EVSEs: {
                results: [...fetchMoreResult?.EVSEs?.results],
                __typename: fetchMoreResult.EVSEs.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.EVSEs?.pagination,
              },
            };
          }
        },
      });
    }
  };
  return {
    loading,
    getEvsesListWithAccessGroups,
    error,
    data,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
