import { ApolloError } from "@apollo/client";
import { t } from "i18next";
import React, { PropsWithChildren } from "react";
import { Center, SimpleLoader, Typography } from "../UI";

interface QueryResultProps {
  Loading: boolean;
  error: ApolloError | undefined;
  data?: unknown;
}

const QueryResult: React.FC<PropsWithChildren<QueryResultProps>> = ({
  Loading,
  error,
  data,
  children,
}) => {
  if (error) {
    return <Typography color="red">Error : {error.message}</Typography>;
  }

  if (Loading) {
    return (
      <Center>
        <SimpleLoader />
      </Center>
    );
  }

  if (data) {
    return <>{children}</>;
  }

  return (
    <Center>
      <Typography>{t("nothing to show")} ...</Typography>
    </Center>
  );
};

export default QueryResult;
