export function checkIfLast(
  el: string,
  index: number,
  length: number,
  divider?: string
) {
  if (!el) return;
  if (index !== length - 1) {
    if (divider) {
      return el + divider;
    } else {
      return el + "  ";
    }
  } else {
    return el;
  }
}

// Javascript program to find all element
// present in any of two given arrays
// Function which takes an array as argument
export const getExistsElementsInTwoArrays: any = (arr1: any[], arr2: any[]) => {
  // Creating a set with elements of arr1
  const set = new Set(arr1);

  // Adding elements of arr2
  arr2.forEach((element: any) => {
    set.add(element);
  });

  // Returning resultant array
  return set;
};
