import { SVGProps } from "react";
import { AttractionType } from "../../../pages/site-selection/locations/components/map-components/PoiFilter";
import { PoiIconAccommodation } from "./PoiIconAccommodation";
import { PoiIconAttraction } from "./PoiIconAttraction";
import { PoiIconBusiness } from "./PoiIconBusiness";
import { PoiIconEducation } from "./PoiIconEducation";
import { PoiIconEVC } from "./PoiIconEVC";
import { PoiIconFoodAndRestaurant } from "./PoiIconFoodAndRestaurant";
import { PoiIconFun } from "./PoiIconFun";
import { PoiIconHealthAndCare } from "./PoiIconHealthAndCare";
import { PoiIconParking } from "./PoiIconParking";
import { PoiIconServiceStation } from "./PoiIconServiceStation";
import { PoiIconShopping } from "./PoiIconShopping";
import { PoiIconSport } from "./PoiIconSport";
import { PoiIconSupermarket } from "./PoiIconSupermarket";
import { PoiIconTollBoth } from "./PoiIconTollBoth";

const MarkerPoi = ({
  attraction,
  ...props
}: SVGProps<SVGSVGElement> & {
  attraction?: AttractionType;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={53}
    fill="none"
    {...props}
  >
    <path
      d="M33.0349 4.35002L33.0349 4.34994L33.0235 4.34298C26.6382 0.46661 18.7116 0.400564 12.2655 4.17046C5.8225 7.93852 1.85903 14.9519 1.86328 22.5335L1.86329 22.5506L1.86389 22.5677C2.0555 28.0798 3.89714 33.4005 7.14208 37.8069C10.9465 42.9854 15.6324 47.4186 20.9751 50.8923L20.987 50.9L20.999 50.9074C21.4752 51.1982 22.0163 51.3639 22.5735 51.3878L22.6728 51.392L22.7711 51.3766C23.2964 51.2943 23.8017 51.1144 24.2621 50.8465L24.2866 50.8322L24.3103 50.8166C27.8087 48.5054 31.0307 45.7826 33.9104 42.7039C38.8968 37.3964 42.9893 30.4279 43.136 22.9111L43.1361 22.9037L43.1362 22.8963C43.1718 15.3372 39.3468 8.29874 33.0349 4.35002Z"
      fill="white"
      stroke="#122967"
      strokeWidth="2"
    />

    {attraction === "shopping" && (
      <g transform="translate(11,10) scale(0.8)">
        <PoiIconShopping />
      </g>
    )}

    {attraction === "cibo_ristorazione" && (
      <g transform="translate(9.5,11) scale(0.7)">
        <PoiIconFoodAndRestaurant />
      </g>
    )}

    {attraction === "intrattenimento_attrazioni" && (
      <g transform="translate(10,10) scale(0.8)">
        <PoiIconAttraction />
      </g>
    )}

    {attraction === "business" && (
      <g transform="translate(10,10) scale(0.7)">
        <PoiIconBusiness />
      </g>
    )}
    {attraction === "istruzione" && (
      <g transform="translate(5,10) scale(0.8)">
        <PoiIconEducation />
      </g>
    )}
    {attraction === "svago_divertimento" && (
      <g transform="translate(7,12) scale(0.8)">
        <PoiIconFun />
      </g>
    )}
    {attraction === "salute_benessere" && (
      <g transform="translate(10,9) scale(0.9)">
        <PoiIconHealthAndCare />
      </g>
    )}
    {attraction === "strutture_ricettive" && (
      <g transform="translate(8,11) scale(0.65)">
        <PoiIconAccommodation />
      </g>
    )}
    {attraction === "stazioni_di_servizio" && (
      <g transform="translate(11,11) scale(0.7)">
        <PoiIconServiceStation />
      </g>
    )}
    {attraction === "strutture_sportive" && (
      <g transform="translate(8,9) scale(0.8)">
        <PoiIconSport />
      </g>
    )}
    {attraction === "strutture_sportive" && (
      <g transform="translate(8,9) scale(0.8)">
        <PoiIconSport />
      </g>
    )}
    {attraction === "charging_station" && (
      <g transform="translate(11,11) scale(0.75)">
        <PoiIconEVC />
      </g>
    )}
    {attraction === "supermercati_centri_commerciali" && (
      <g transform="translate(8,11) scale(0.75)">
        <PoiIconSupermarket />
      </g>
    )}

    {attraction === "parking" && (
      <g transform="translate(14,10) scale(0.8)">
        <PoiIconParking />
      </g>
    )}

    {attraction === "casello_autostradale" && (
      <g transform="translate(8,10) scale(0.8)">
        <PoiIconTollBoth />
      </g>
    )}
  </svg>
);
export default MarkerPoi;
