import { Route, Routes } from "react-router-dom";
import { SuspensedView } from "../../../../shared/helpers/SuspensedView";
import RenderOnRole from "../../../../shared/UI/RenderOnRole";
import { DBConfig } from "../../utils/db.config";
import { OpenProject } from "../components/OpenProject";
import FreeRide from "./FreeRide";
import SiteSelectionProject from "./Project";
import WebToolDashboard from "./WebToolDashboard";

import { Roles } from "@bepower/greta-types";
import { initDB } from "react-indexed-db-hook";

initDB(DBConfig);

export const SiteSelection = () => {

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RenderOnRole roles={[
            Roles.SiteSelection
          ]} page>
            <WebToolDashboard />
          </RenderOnRole>
        }
      />

      <Route
        path="freeride"
        index
        element={
          <RenderOnRole roles={[
            Roles.SiteSelection
          ]} page>
            <FreeRide />
          </RenderOnRole>
        }
      />
      <Route
        path="project/:uuid"
        element={
          <SuspensedView>
            <RenderOnRole roles={[
                Roles.SiteSelectionWrite
            ]} page>
              <SiteSelectionProject />
            </RenderOnRole>
          </SuspensedView>
        }
      />
      <Route
        path="open-project"
        element={
          <SuspensedView>
            <RenderOnRole roles={[
                Roles.SiteSelectionWrite
            ]} page>
              <OpenProject />
            </RenderOnRole>
          </SuspensedView>
        }
      />
    </Routes>
  );
};

export default SiteSelection;
