import { FC, memo, useCallback, useState } from "react";
import { Box, Checkbox, Flex, Typography } from "../../../../../shared/UI";
import { DefaultProps } from "../../../../../shared/UI/helpers";
// @ts-ignore
import { useTranslation } from "react-i18next";
import Maximize from "../../../../../shared/assets/svg/Maximize";
import Minimize from "../../../../../shared/assets/svg/Minimize";
import { PoiIconAccommodation } from "../../../../../shared/assets/svg/PoiIconAccommodation";
import { PoiIconAttraction } from "../../../../../shared/assets/svg/PoiIconAttraction";
import { PoiIconBusiness } from "../../../../../shared/assets/svg/PoiIconBusiness";
import { PoiIconEducation } from "../../../../../shared/assets/svg/PoiIconEducation";
import { PoiIconEVC } from "../../../../../shared/assets/svg/PoiIconEVC";
import { PoiIconFoodAndRestaurant } from "../../../../../shared/assets/svg/PoiIconFoodAndRestaurant";
import { PoiIconFun } from "../../../../../shared/assets/svg/PoiIconFun";
import { PoiIconHealthAndCare } from "../../../../../shared/assets/svg/PoiIconHealthAndCare";
import { PoiIconParking } from "../../../../../shared/assets/svg/PoiIconParking";
import { PoiIconServiceStation } from "../../../../../shared/assets/svg/PoiIconServiceStation";
import { PoiIconShopping } from "../../../../../shared/assets/svg/PoiIconShopping";
import { PoiIconSport } from "../../../../../shared/assets/svg/PoiIconSport";
import { PoiIconSupermarket } from "../../../../../shared/assets/svg/PoiIconSupermarket";
import { PoiIconTollBoth } from "../../../../../shared/assets/svg/PoiIconTollBoth";
import { useSiteSelectionEvcDataContext } from "../../../slices/EvcDataContextProvider";
import { t } from "i18next";

type BoxProps = React.HTMLAttributes<HTMLDivElement> & DefaultProps;
type PoiFilterProps = BoxProps & {};

export type AttractionType = (typeof categories)[number];

const categories = [
  "shopping",
  "cibo_ristorazione",
  "intrattenimento_attrazioni",
  "business",
  "istruzione",
  "svago_divertimento",
  "salute_benessere",
  "strutture_ricettive",
  "stazioni_di_servizio",
  "strutture_sportive",
  "charging_station",
  "supermercati_centri_commerciali",
  "parking",
  "casello_autostradale",
] as const;

const mapIcon: Record<AttractionType, FC<any>> = {
  shopping: PoiIconShopping,
  cibo_ristorazione: PoiIconFoodAndRestaurant,
  intrattenimento_attrazioni: PoiIconAttraction,
  business: PoiIconBusiness,
  istruzione: PoiIconEducation,
  svago_divertimento: PoiIconFun,
  salute_benessere: PoiIconHealthAndCare,
  strutture_ricettive: PoiIconAccommodation,
  stazioni_di_servizio: PoiIconServiceStation,
  strutture_sportive: PoiIconSport,
  charging_station: PoiIconEVC,
  supermercati_centri_commerciali: PoiIconSupermarket,
  parking: PoiIconParking,
  casello_autostradale: PoiIconTollBoth,
};

const Arrow = ({
  direction,
  stroke,
  size,
  style,
  ...props
}: Omit<
  BoxProps,
  "width" | "height" | "borderLeft" | "borderRight" | "borderBottom"
> & { direction: "up" | "down"; stroke: string }) => (
  <Box
    width={"0"}
    height={"0"}
    borderLeft={`${size} solid transparent`}
    borderRight={`${size} solid transparent`}
    borderBottom={`${size} solid ${stroke || "#FFFFFF"}`}
    style={{
      transform: direction === "down" ? "rotate(180deg)" : undefined,
      ...(style ?? {}),
    }}
    {...props}
  />
);

const OpenTabButton = memo(
  ({
    style,
    isOpen,
    stroke,
    fill,
    ...props
  }: BoxProps & {
    isOpen?: boolean;
    stroke?: string;
    fill?: string;
  }) => (
    <Box
      paddingTop={"10px"}
      borderRadius={"3px"}
      borderTop={`1px solid ${stroke ?? "#3D729C"}`}
      background={fill ?? "#FFFFFF"}
      style={{
        cursor: "pointer",
        ...(style ?? {}),
      }}
      {...props}
    >
      <Box
        padding={"7px 10px 7px 10px"}
        borderTop={`1px solid ${stroke ?? "#3D729C"}`}
      >
        <Arrow
          size={"5px"}
          stroke={stroke ?? "#3D729C"}
          direction={isOpen ? "up" : "down"}
        />
      </Box>
    </Box>
  ),
  (prev, actual) =>
    prev.isOpen === actual.isOpen &&
    prev.stroke === actual.stroke &&
    prev.fill === actual.fill
);

const LocationBoxHeader = memo(
  ({
    isOpen,
    isMaximized,
    setIsOpen,
    setIsMaximized,
  }: {
    isOpen: boolean;
    isMaximized: boolean;
    setIsOpen: (value: boolean) => void;
    setIsMaximized: (value: boolean) => void;
  }) => (
    <Box
      borderRadius={isOpen ? "10.5px 10.5px 0 0" : "10.5px"}
      background="#95D8FD"
      boxShadow="0px 3.17842px 3.17842px 0px rgba(0, 0, 0, 0.25)"
      padding="10px"
    >
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {!isMaximized && (
          <Box
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setIsMaximized(!isMaximized);
            }}
          >
            <Maximize fill="#122967" iconSize="20px" />
          </Box>
        )}
        {isMaximized && (
          <Box
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setIsMaximized(!isMaximized);
            }}
          >
            <Minimize fill="#122967" iconSize="20px" />
          </Box>
        )}
        <Box>
          <Typography
            variant={"title20"}
            color="#122967"
            marginLeft={"10px"}
            upperCase={true}
          >
            {t("siteSelection.poiFilter.mapFilter")}
          </Typography>
        </Box>
        <Flex flexGrow={1} />
        <OpenTabButton
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          fill={"#122967"}
          stroke="#95D8FD"
        />
      </Flex>
    </Box>
  ),
  (prev, actual) => {
    return (
      prev.isOpen === actual.isOpen &&
      prev.isMaximized === actual.isMaximized &&
      prev.setIsOpen === actual.setIsOpen &&
      prev.setIsMaximized === actual.setIsMaximized
    );
  }
);

const AttractionCheckBox = memo(
  ({
    checked,
    setChecked,
    name,
    category,
  }: {
    checked: boolean;
    setChecked: (value: boolean) => void;
    name: string;
    category: AttractionType;
  }) => {
    const [hovered, setHovered] = useState(false);

    const Icon = mapIcon[category];

    return (
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        width={"90px"}
        height={"90px"}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => {
          setChecked(!checked);
        }}
        margin="1px"
        background={checked ? "#D4E0EF" : "none"}
        borderRadius={"3px"}
        border={hovered ? "1px solid #3D729C" : "none"}
        style={{
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        <Icon size="35px" />

        {/* {category !== "parking" && <MapPin iconSize="30px" /> } */}
        <Typography
          justifyContent={"center"}
          color={"#122967"}
          fontSize={"10px"}
        >
          {name}
        </Typography>
      </Flex>
    );
  },
  (prev, actual) => {
    return (
      prev.checked === actual.checked &&
      prev.name === actual.name &&
      prev.setChecked === actual.setChecked
    );
  }
);

const EvcFilter = memo(
  () => {
    const { t } = useTranslation();
    const {
      showBecEvcs,
      setShowBecEvcs,
      setShowCompetitorEvcs,
      showCompetitorEvcs,
    } = useSiteSelectionEvcDataContext();

    return (
      <Flex
        flexDirection={"row"}
        justifyContent="center"
        alignItems="center"
        height={58}
        marginBottom={"10px"}
      >
        <Flex alignItems="center">
          <Typography
            variant={"title20"}
            color={"#122967"}
            fontWeight={600}
            marginBottom={"12px"}
            width={"100%"}
            textAlign={"end"}
          >
            {t("siteSelection.general.legend.evc")}{" "}
            {t("siteSelection.general.legend.bec")}
          </Typography>
          <Flex flexDirection={"row"} alignItems="center">
            <Checkbox
              checked={showBecEvcs.ac}
              onChange={() => {
                setShowBecEvcs({
                  ac: !showBecEvcs.ac,
                  dc: showBecEvcs.dc,
                });
              }}
            >
              <Typography
                variant={"title20"}
                color={"#3D729C"}
                marginRight={"5px"}
              >
                {t("siteSelection.general.legend.ac")}
              </Typography>
            </Checkbox>
            <Checkbox
              checked={showBecEvcs.dc}
              onChange={() => {
                setShowBecEvcs({
                  ac: showBecEvcs.ac,
                  dc: !showBecEvcs.dc,
                });
              }}
            >
              <Typography
                variant={"title20"}
                color={"#3D729C"}
                marginRight={"5px"}
              >
                {t("siteSelection.general.legend.dc")}
              </Typography>
            </Checkbox>
          </Flex>
        </Flex>
        <Flex
          height={"69px"}
          width={"3px"}
          background={"#3D729C"}
          margin={"0px 25px"}
        />
        <Flex alignItems="center" justifyContent={"start"}>
          <Typography
            variant={"title20"}
            color={"#122967"}
            fontWeight={600}
            marginBottom={"12px"}
          >
            {t("siteSelection.general.legend.evc")}{" "}
            {t("siteSelection.general.legend.competitor")}
          </Typography>
          <Flex flexDirection={"row"} alignItems="center" width={"100%"}>
            <Checkbox
              checked={showCompetitorEvcs.ac}
              onChange={() => {
                setShowCompetitorEvcs({
                  ac: !showCompetitorEvcs.ac,
                  dc: showCompetitorEvcs.dc,
                });
              }}
            >
              <Typography
                variant={"title20"}
                color={"#3D729C"}
                marginRight={"5px"}
              >
                {t("siteSelection.general.legend.ac")}
              </Typography>
            </Checkbox>
            <Checkbox
              checked={showCompetitorEvcs.dc}
              onChange={() => {
                setShowCompetitorEvcs({
                  ac: showCompetitorEvcs.ac,
                  dc: !showCompetitorEvcs.dc,
                });
              }}
            >
              <Typography
                variant={"title20"}
                color={"#3D729C"}
                marginRight={"5px"}
              >
                {t("siteSelection.general.legend.dc")}
              </Typography>
            </Checkbox>
          </Flex>
        </Flex>
      </Flex>
    );
  },
  () => true
);

const CefFilter = memo(
  () => {
    const { t } = useTranslation();

    const { showCef1, showCef2, setCef1, setCef2 } =
      useSiteSelectionEvcDataContext();

    return (
      <Flex
        flexDirection={"row"}
        justifyContent="space-around"
        alignItems="center"
        height={58}
        backgroundColor="#BCDFF3"
        boxShadow={"0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset"}
      >
        <Flex flexDirection={"row"} alignItems="center">
          <Typography
            variant={"title20"}
            color={"#122967"}
            fontWeight={600}
            marginRight={"5px"}
          >
            {t("siteSelection.general.data.cef1")}
          </Typography>
          <Checkbox checked={showCef1} onChange={() => setCef1(!showCef1)} />
        </Flex>
        <Flex flexDirection={"row"} alignItems="center">
          <Typography
            variant={"title20"}
            color={"#122967"}
            fontWeight={600}
            marginRight={"5px"}
          >
            {t("siteSelection.general.data.cef2")}
          </Typography>
          <Checkbox checked={showCef2} onChange={() => setCef2(!showCef2)} />
        </Flex>
      </Flex>
    );
  },
  () => true
);

export const PoiFilter: React.FC<PoiFilterProps> = memo(
  ({ style, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMaximized, setIsMaximized] = useState(false);
    const { t } = useTranslation();

    const { poiFilters, togglePoiFilter } = useSiteSelectionEvcDataContext();

    const toggle = useCallback(
      (attraction: string) => () => {
        togglePoiFilter(attraction);
      },
      [togglePoiFilter]
    );

    return (
      <Box
        borderRadius="10.595px 10.595px 10.5px 10.5px"
        background="#FFF"
        minWidth={"400px"}
        maxWidth={"400px"}
        style={{
          userSelect: "none",
          ...(style ?? {}),
        }}
        {...props}
      >
        <LocationBoxHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isMaximized={isMaximized}
          setIsMaximized={setIsMaximized}
        />
        {isOpen && (
          <Box
            background="#FFFFFF"
            padding={"10px 0px"}
            borderRadius={"0 0 10.5px 10.5px"}
            marginTop={"10px"}
          >
            <EvcFilter />
            <CefFilter />
            <Flex
              flexDirection={"row"}
              flexWrap={"wrap"}
              maxWidth={400}
              padding="0px 10px"
            >
              {categories.map((attraction) => (
                <AttractionCheckBox
                  checked={poiFilters.includes(attraction)}
                  setChecked={toggle(attraction)}
                  name={t(
                    `siteSelection.general.data.poi.category.${attraction}`
                  )}
                  category={attraction}
                />
              ))}
            </Flex>
          </Box>
        )}
      </Box>
    );
  },
  () => true
);
