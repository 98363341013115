import { Controller, useFormContext } from "react-hook-form";
import { Flex, InputLabel } from "../../../../shared/UI";
import { Select } from "./../../../../shared/UI/Select";
import { useGetPlatformParties } from "../../../platforms/gql/queries/useGetPlatformParties";
import { useCallback, useEffect, useState } from "react";
import { IPlatformParty } from "../../../../core/model/platform-party.model";
import { useSelector } from "react-redux";

type Props = {
  partyId?: string | null;
  canEdit: boolean;
  partyUid?: string | null;
  platformProtocol?: string | null;
};
export const CpoDropdown: React.FC<Props> = ({ canEdit, partyUid }) => {
  const { currentCpo } = useSelector((state: any) => state.userSettings);
  const [loadingSelectedCpo, setLoadingSelectedCpo] = useState<boolean>(false);

  const {
    getPlatformPartiesQuery: getPlatformPartiesCPOQuery,
    platformParties: CpoParties,
    loading: loadingPlatformParties,
  } = useGetPlatformParties();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const filteredCpoParties = useCallback((): {
    label: string;
    value: string;
  }[] => {
    switch (watch("type.value")) {
      case "HUBJECT_O2A":
        return CpoParties?.filter(
          (item: IPlatformParty) => item?.IsHubject === true
        ).map((item: IPlatformParty) => ({
          label: item?.name,
          value: item?.uid,
        }));
      case "HUBJECT_BLT":
        return CpoParties?.filter(
          (item: IPlatformParty) => item?.IsHubject === true
        ).map((item: IPlatformParty) => ({
          label: item?.name,
          value: item?.uid,
        }));
      case "DEFAULT":
        return CpoParties?.filter(
          (item: IPlatformParty) => item?.HasDefaultTariff === false
        ).map((item: IPlatformParty) => ({
          label: item?.name,
          value: item?.uid,
        }));
      default:
        return CpoParties?.map((item: IPlatformParty) => ({
          label: item?.name,
          value: item?.uid,
        }));
    }

    // CpoParties?.map((party: any) => ({ label: party?.name, value: party?.uid }))
  }, [CpoParties, watch]);

  const selectedCpo = useCallback(
    () =>
      partyUid && currentCpo
        ? filteredCpoParties()?.find(
            (cpo: { label: string; value: string }) => cpo.value === currentCpo
          )
        : filteredCpoParties()?.[0],
    [currentCpo, filteredCpoParties, partyUid]
  );
  useEffect(() => {
    getPlatformPartiesCPOQuery({
      variables: {
        filter: {
          role: "CPO",
        },
        pagination: {
          limit: 1000,
          page: 1,
        },
      },
    });
  }, [getPlatformPartiesCPOQuery, setValue]);
  useEffect(() => {
    //FIXME
    if (partyUid) {
      setLoadingSelectedCpo(true);
      setTimeout(() => {
        setValue("cpoPartyUids", selectedCpo());
        setLoadingSelectedCpo(false);
      }, 2000);
    }
  }, [partyUid, selectedCpo, setLoadingSelectedCpo, setValue]);

  return (
    <Flex flex={1} minWidth={300}>
      <InputLabel labelColor="white" children="CPO Party" required />
      <Controller
        name="cpoPartyUids"
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <Select
            menuPosition="fixed"
            isLoading={loadingPlatformParties || loadingSelectedCpo}
            isDisabled={!canEdit || !watch("type.value")}
            getOptionValue={(option: any) => option?.value}
            placeholder={
              loadingSelectedCpo
                ? "loading the default CPO"
                : "Select CPO Party"
            }
            onChange={(e: any) => onChange(e)}
            options={filteredCpoParties() || []}
            error={
              errors.cpoPartyUids
                ? errors.cpoPartyUids.message || " Required "
                : ""
            }
            errorProps={{
              color: "white",
              borderBottom: "1px solid red",
            }}
            {...rest}
          />
        )}
      />
    </Flex>
  );
};
