import {
  OverlayView,
  Polygon,
  PolygonProps,
  useGoogleMap,
} from "@react-google-maps/api";
import { useCallback, useMemo, useState } from "react";
import {
  Bound,
  GeoJSONFeature,
  GeoJSONFeatureCollection,
} from "../../../@types/geojson";
import { calculateColor, colorToRgb } from "../../../utils/calculate-color";

const Poly = <T extends {}>({
  feature,
  options,
  ...props
}: {
  feature: GeoJSONFeature<T>;
} & PolygonProps) => {
  const mapCoords = ([lng, lat]: [number, number]) => ({ lat, lng });

  const coords =
    feature.geometry.type === "Polygon"
      ? [feature.geometry.coordinates]
      : feature.geometry.coordinates;
  const paths = coords.map((coords) => coords[0].map(mapCoords));
  return (
    <Polygon
      paths={paths}
      options={{
        strokeColor: "#000000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#0F0",
        fillOpacity: 0.35,
        ...(options ?? {}),
      }}
      {...props}
    />
  );
};

export interface GeoJSONLayerProps<
  T extends {
    weight: number;
    bound: Bound;
  }
> {
  data: GeoJSONFeatureCollection<T>;
  selected?: GeoJSONFeature<T> | null;
  onSelect?: (data: GeoJSONFeature<T>) => void;
  hideSelected?: boolean;
  selectedStrokeColor?: string;
  tooltip?: (data: T) => React.ReactNode;
  onHover?: (e: google.maps.MapMouseEvent, data: GeoJSONFeature<T>) => void;
}

export const GeoJSONLayer = <
  T extends {
    weight: number;
    bound: Bound;
  }
>({
  data,
  selected,
  onSelect,
  hideSelected = true,
  selectedStrokeColor = "#FF0000",
  tooltip,
  onHover,
}: GeoJSONLayerProps<T>) => {
  const map = useGoogleMap();
  const features = useMemo(() => data.features, [data]);
  const [hoverCoords, setHoverCoords] = useState<google.maps.LatLng | null>(
    null
  );
  
  useState<GeoJSONFeature<T> | null>( null);
  const [hoveredFeature, setHoveredFeature] =
  useState<GeoJSONFeature<T> | null>(null);
  
  const stats = useMemo(
    () => ({
      min: features
        .map((f) => f.properties?.weight ?? 0)
        .reduce((a, b) => Math.min(a, b), 0),
      max: features
        .map((f) => f.properties?.weight ?? 0)
        .reduce((a, b) => Math.max(a, b), 0),
    }),
    [features]
  );
  const calColor = useCallback(
    (feature: GeoJSONFeature<T>) => {
      if (hideSelected && selected === feature) return "transparent";
      if (selected === feature) return "#0000FF";
      if (hoveredFeature === feature) return "#00FF00";

      return colorToRgb(
        calculateColor(feature.properties?.weight ?? 0, stats.min, stats.max, true)
      );
    },
    [hideSelected, hoveredFeature, selected, stats.max, stats.min]
  );

  return (
    <>
      {features.length > 0 && map && tooltip && hoverCoords && hoveredFeature && (
        <OverlayView
        key={"tooltip"}
          position={hoverCoords}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: 10,
            y: -10,
          })}
          zIndex={10000}
          
        >
          <div
            style={{ backgroundColor: "#fff", padding: "5px", width: "200px" }}
            onMouseEnter={() => {
              setHoverCoords(null);
            }}
          >
            {tooltip(hoveredFeature?.properties)}
          </div>
        </OverlayView>
      )}
      {map &&
        features &&
        features.map((feature, i) => {
          
          return (
          <Poly
            key={i}
            feature={feature}
            // visible={
            //   hideSelected || (!hideSelected && selected !== feature)
            // }
            visible={!!(!selected || selected === feature)}
            options={{
              fillColor: calColor(feature),
              clickable: selected !== feature,
              fillOpacity: 0.6,
              strokeColor:
                selected === feature ? selectedStrokeColor : "#000000",
              zIndex: selected === feature ? 1000 : 0,
            }}
            onClick={() => {
              // !selected && setselected(feature);
              map?.fitBounds({
                east: feature.properties.bound.maxLng,
                north: feature.properties.bound.maxLat,
                south: feature.properties.bound.minLat,
                west: feature.properties.bound.minLng,
              });
              onSelect && onSelect(feature);
            }}
            onMouseOver={(e) => {
              setHoveredFeature(feature);
              // setHoverCoords(e.latLng);
            }}
            onMouseOut={() => {
              setHoveredFeature(null);
              setHoverCoords(null);
            }}
            onMouseMove={(e) => {
              setHoverCoords(e.latLng);
            }}
          />
        )})}
    </>
  );
};
