import { gql, useLazyQuery } from "@apollo/client";

const GET_EVENTS_MESSAGES = gql`
  query EventMessages(
    $index: String!
    $filter: EventMessageFilterParams
    $pagination: PaginationParams
  ) {
    Greta {
      EventMessages(index: $index, filter: $filter, pagination: $pagination) {
        total
        pagination {
          cursor
          last
        }
        results {
          timestamp
          stream
          id
          correlation
          causation
          timeout
          version
          from
          fromGroup
          fromConsumer
          data
        }
      }
    }
  }
`;

export const useEventMessages = () => {
  const [
    getEventsMessages,
    { loading, error, data, refetch, networkStatus, startPolling },
  ] = useLazyQuery(GET_EVENTS_MESSAGES, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getEventsMessages,
    error,
    events: data?.Greta?.EventMessages?.results,
    count: data?.Greta?.EventMessages?.total,
    refetch,
    startPolling,
    networkStatus,
  };
};
