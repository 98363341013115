import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};

const Info: React.FC<Props> = ({ size, fill }) => {
  return (
    <Svg
      fill="none"
      iconSize={size}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Warning / Info">
        <path
          id="Vector"
          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};

export default Info;
