import { t } from "i18next";
import { ReactNode, useState } from "react";
import { DownloadThick } from "../../../../shared/assets/svg";
import { ExcelService } from "../../../../shared/services/excelService";
import { Box, Button, Flex, Typography } from "../../../../shared/UI";
import { colors } from "../../../../shared/UI/theme/colors";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import { useSiteSelectionContext } from "../../slices/ContextProvider";
import {
  useSiteSelectionEvcDataContext
} from "../../slices/EvcDataContextProvider";
import { CityStat, ProvinceStat, RegionStat } from "../../slices/GeoDataContextProvider";
import { mapKwh } from "../../utils/map-kwh";

type Props = {};
type ProvinceRowProps = {
  province: ProvinceStat;
};

const exportToExcel = (name: string, data: CityStat[]) => {
  ExcelService?.exportAsExcelFile(
    data
      .sort((a, b) => b.kwh_ac + b.kwh_dc - (a.kwh_ac + a.kwh_dc))
      .map((d) => ({
        regione: d.DEN_REG,
        provincia: d.DEN_UTS,
        comune: d.COMUNE,
        "SP (kWh/y)": d.kwh_ac + d.kwh_dc,
        "SP AC (kWh/y)": d.kwh_ac,
        "SP DC (kWh/y)": d.kwh_dc,
        "EVC AC": d.n_ac,
        "EVC DC": d.n_dc,
      })),
    name
  );
};

const Row = (props: {
  entityName: string;
  tot: { value: number; unit: string };
  ac: { value: number; unit: string };
  dc: { value: number; unit: string };
  nAc: number;
  nDc: number;
  withBorder?: boolean;
  children?: ReactNode;
  backgroundColor?: string;
  onDownload?: () => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Flex
        flexDirection="row"
        padding={"10px 2px"}
        justifyContent="space-around"
        backgroundColor={open ? "#D9EEFF" : props.backgroundColor}
        borderBottom={
          props.withBorder ?? true ? `1px solid ${colors.primary}` : undefined
        }
        style={{ userSelect: "none" }}
      >
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          width={"30%"}
        >
          <Typography
            fontSize={"16px"}
            fontWeight={700}
            color={colors.primaryDark}
            variant="title20"
          >
            {props.entityName}
          </Typography>

          <Typography
            fontSize={"12px"}
            fontWeight={400}
            color={colors.primary}
            variant="title20"
          >
            SP {props.tot.value.toPrecision(3)} {props.tot.unit}/y
          </Typography>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          textAlign="right"
          alignItems="end"
          width={"30%"}
        >
          <Typography
            fontSize={"16px"}
            fontWeight={700}
            color={colors.primaryDark}
            variant="title20"
            textAlign={"center"}
            width={"100%"}
          >
            {t("siteSelection.general.legend.ac")}
          </Typography>

          <Typography
            fontSize={"12px"}
            fontWeight={400}
            color={colors.primary}
            variant="title20"
            textAlign={"center"}
            width={"100%"}
          >
            {props.nAc}
            {t("siteSelection.general.legend.evc")}
            {props.ac.value.toPrecision(3)} {props.ac.unit}/y
          </Typography>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          textAlign="right"
          alignItems="flex-start"
          width={"30%"}
        >
          <Typography
            fontSize={"16px"}
            fontWeight={700}
            color={colors.primaryDark}
            variant="title20"
            textAlign={"center"}
            width={"100%"}
          >
            {t("siteSelection.general.legend.dc")}
          </Typography>

          <Typography
            fontSize={"12px"}
            fontWeight={400}
            color={colors.primary}
            variant="title20"
            textAlign={"center"}
            width={"100%"}
          >
            {props.nDc} {t("siteSelection.general.legend.evc")}{" "}
            {props.dc.value.toPrecision(3)} {props.dc.unit}/y
          </Typography>
        </Flex>
        {props.children && (
          <Flex flexDirection="row">
            <Button
              variant="white"
              background={"transparent"}
              borderColor={"transparent"}
              fixedSize
              size="small"
              onClick={() => {
                props.onDownload?.();
              }}
            >
              <DownloadThick iconSize="20px" fill={colors.primaryDark} />
            </Button>

            <Button
              fontSize={"16px"}
              variant="primary"
              shape="circle"
              fixedSize
              size="small"
              onClick={() => setOpen(!open)}
            >
              <Typography
                fontSize={"20px"}
                fontWeight={700}
                color={colors.white}
                variant="title20"
              >
                {!open ? "+" : "-"}
              </Typography>
            </Button>
          </Flex>
        )}
      </Flex>
      {open && props.children}
    </Box>
  );
};

const CityRow = (props: { city: CityStat; backgroundColor?: string }) => {
  const tot = mapKwh(props.city.kwh_ac + props.city.kwh_dc);
  const ac = mapKwh(props.city.kwh_ac);
  const dc = mapKwh(props.city.kwh_dc);

  return (
    <Row
      entityName={props.city.COMUNE}
      tot={tot}
      ac={ac}
      dc={dc}
      nAc={props.city.n_ac}
      nDc={props.city.n_dc}
      withBorder={false}
      backgroundColor={props.backgroundColor}
    />
  );
};

const ProvinceRow = (props: ProvinceRowProps) => {
  const { cityStats } = useSiteSelectionEvcDataContext();

  const tot = mapKwh(props.province.kwh_ac + props.province.kwh_dc);
  const ac = mapKwh(props.province.kwh_ac);
  const dc = mapKwh(props.province.kwh_dc);

  const cities = Object.values(cityStats).filter(
    (city) => city.SIGLA === props.province.SIGLA
  );

  return (
    <Row
      entityName={props.province.DEN_UTS}
      tot={tot}
      ac={ac}
      dc={dc}
      nAc={props.province.n_ac}
      nDc={props.province.n_dc}
      backgroundColor={colors.darkWhite}
      onDownload={() => {
        exportToExcel(`somma_potenziale_${props.province.DEN_UTS}`, cities);
      }}
    >
      <Box
        width={"100%"}
        height={"250px"}
        borderBottom={`1px solid ${colors.primary}`}
      >
        <VerticalScrollContainer>
          {cities.map((city, i) => (
            <CityRow
              key={city.COMUNE}
              backgroundColor={i % 2 === 0 ? colors.darkWhite : undefined}
              city={city}
            />
          ))}
        </VerticalScrollContainer>
      </Box>
    </Row>
  );
};

const RegionRow = (props: { region: RegionStat }) => {
  const { provinceStats, cityStats } = useSiteSelectionEvcDataContext();

  const tot = mapKwh(props.region.kwh_ac + props.region.kwh_dc);
  const ac = mapKwh(props.region.kwh_ac);
  const dc = mapKwh(props.region.kwh_dc);

  const provinces = Object.values(provinceStats).filter(
    (province) => province.COD_REG === props.region.COD_REG
  );

  return (
    <Row
      entityName={props.region.DEN_REG}
      tot={tot}
      ac={ac}
      dc={dc}
      nAc={props.region.n_ac}
      nDc={props.region.n_dc}
      onDownload={() => {
        exportToExcel(
          `somma_potenziale_${props.region.DEN_REG}`,
          Object.values(cityStats).filter(
            (city) => city.COD_REG === props.region.COD_REG
          )
        );
      }}
    >
      <Box
        width={"100%"}
        height={"400px"}
        borderBottom={`1px solid ${colors.primary}`}
        borderLeft={`4px solid ${colors.primary}`}
        paddingLeft={"15px"}
      >
        <VerticalScrollContainer>
          {provinces.map((province, i) => (
            <ProvinceRow key={province.COD_PROV} province={province} />
          ))}
        </VerticalScrollContainer>
      </Box>
    </Row>
  );
};

export const CityInfoList: React.FC<Props> = () => {
  const { regionStats, provinceStats, cityStats } =
    useSiteSelectionEvcDataContext();

  const {
    state: { selectedRegion, selectedProvince, selectedCity },
  } = useSiteSelectionContext();

  const regions = Object.values(regionStats).sort((a, b) => {
    return b.kwh_ac + b.kwh_dc - (a.kwh_ac + a.kwh_dc);
  });

  const provinces = Object.values(provinceStats)
    .filter((p) => p.COD_REG === selectedRegion?.properties.COD_REG)
    .sort((a, b) => {
      return b.kwh_ac + b.kwh_dc - (a.kwh_ac + a.kwh_dc);
    });

  const cities = Object.values(cityStats)
    .filter((c) => c.SIGLA === selectedProvince?.properties.SIGLA)
    .sort((a, b) => {
      return b.kwh_ac + b.kwh_dc - (a.kwh_ac + a.kwh_dc);
    });

  return (
    <Flex height="100%">
      <Flex
        flexDirection="row"
        alignItems="center"
        width="100%"
        overflow="hidden"
        padding="20px"
      >
        <Typography
          display={"block"}
          fontSize={"18px"}
          fontWeight={400}
          width={"100%"}
          color={colors.primary}
          variant="title20"
        >
          {!selectedRegion && "Elenco Regioni con Somma Potenziale (SP)"}
          {selectedRegion &&
            !selectedProvince &&
            `Elenco Province in ${selectedRegion.properties.DEN_REG} con SP`}
          {selectedProvince &&
            !selectedCity &&
            `Elenco Comuni nella provincia di ${selectedProvince.properties.DEN_UTS} con SP`}
        </Typography>
        <Flex flexDirection="row" justifyContent="space-between">
          <Button
            variant="white"
            padding={"0px 10px"}
            width={"90px"}
            shape="rounded"
            onClick={() => {
              exportToExcel("somma_potenziale", Object.values(cityStats));
            }}
          >
            <DownloadThick iconSize="20px" fill={colors.primary} />
            <Typography
              fontSize={"18px"}
              fontWeight={400}
              color={colors.primary}
              variant="title20"
            >
              All
            </Typography>
          </Button>
        </Flex>
      </Flex>

      <VerticalScrollContainer height={"100%"} width={"100%"} padding={"20px"}>
        <Box height={"100%"}>
          {!selectedRegion &&
            regions.map((region) => (
              <RegionRow key={region.COD_REG} region={region} />
            ))}
          {selectedRegion &&
            !selectedProvince &&
            provinces.map((province) => (
              <ProvinceRow key={province.SIGLA} province={province} />
            ))}
          {selectedProvince &&
            !selectedCity &&
            cities.map((city, i) => (
              <CityRow
                backgroundColor={i % 2 === 0 ? colors.darkWhite : undefined}
                key={city.COMUNE}
                city={city}
              />
            ))}
        </Box>
      </VerticalScrollContainer>
    </Flex>
  );
};
