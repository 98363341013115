/** @format */

import { t } from "i18next";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styled from "styled-components";
import {
  Box,
  Button,
  Card,
  Flex,
  Input,
  Select,
  Typography,
} from "../../../../shared/UI";
import { Alarm, Close, Info } from "../../../../shared/assets/svg";
import { StyledTable, TRow } from "../TariffPriceContent";
import { CurrencyInput } from "react-currency-mask";
import { useEffect, useState } from "react";
import { FormValues } from "../TariffSchema";
import { useGetAggregationLocations } from "../../../../shared/gql/queries/useGetAggregationLocations";
import { LocationAggregableField } from "../../../../shared/gql/generated/graphql";

type Props = {
  canEdit: boolean;
};

export const CustomPrice: React.FC<Props> = ({ canEdit }) => {
  const [countryOptions, setCountryOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext<FormValues>();
  const { getAggrLoctions, loading: loadingGetCountriesList } =
    useGetAggregationLocations();
  const { fields, append, remove } = useFieldArray({
    name: "priceForCountry",
    control,
  });

  const appendNewPrice = () => {
    append({
      country: [],
      AC_energy: "0.00",
      AC_time: "0.00",
      AC_parkingTime: "0.00",
      AC_flat: "0.00",
      "DC < 100kW_energy": "0.00",
      "DC < 100kW_time": "0.00",
      "DC < 100kW_parkingTime": "0.00",
      "DC < 100kW_flat": "0.00",
      "100kW ≤ DC < 150kW_energy": "0.00",
      "100kW ≤ DC < 150kW_time": "0.00",
      "100kW ≤ DC < 150kW_parkingTime": "0.00",
      "100kW ≤ DC < 150kW_flat": "0.00",
      "DC ≥ 150kW_energy": "0.00",
      "DC ≥ 150kW_time": "0.00",
      "DC ≥ 150kW_parkingTime": "0.00",
      "DC ≥ 150kW_flat": "0.00",
      isExist: false,
    });
  };

  useEffect(() => {
    getAggrLoctions({
      variables: {
        filter: {
          query: "_exists_:projectId",
        },
        pagination: {
          limit: 10000,
        },
        field: LocationAggregableField.Country,
      },
    }).then((response) => {
      const countryList: any[] = response.data?.Aggregation
        ? response.data?.Aggregation?.Locations?.results
        : [];
      if (countryList?.length > 0) {
        let newCountries: { label: string; value: string }[] = [];
        countryList?.map(({ key }: { key: string }) => {
          if (key) {
            newCountries.push({ label: key, value: key });
          }
        });
        setCountryOptions((prev) => [...prev, ...newCountries]);

        return;
      }
    });
  }, [getAggrLoctions]);

  return (
    <Flex fullSize>
      {canEdit && watch("type")?.value !== "DEFAULT" ? (
        <Flex my={3} justifyContent="center" alignItems="center">
          <Typography pb={3}>
            {t(
              "If you need to add a price for some countries, you can add a custom price"
            )}
          </Typography>
          <Button onClick={appendNewPrice} $size="large">
            {t(`add ${fields?.length ? "another" : "new"} price `)}
          </Button>
        </Flex>
      ) : null}

      {fields.map((field, index) => {
        const watchCountries = watch("priceForCountry")
          ?.map(
            ({ country }: { country: { label: string; value: string }[] }) =>
              country
          )
          .flat();
        let exCountries: string[] = [];
        watchCountries?.map((country: any) => exCountries.push(country?.value));
        let list: { label: string; value: string }[] = countryOptions;
        list = list.filter((item) => !exCountries.includes(item.value));
        return (
          <Card key={field.id} position="relative" p={3} mb={3}>
            <Flex flexDirection="row" justifyContent="space-between" pb={3}>
              <Flex flex={1} width="100%">
                <Flex
                  flex={1}
                  alignItems="center"
                  flexDirection="row"
                  width="100%"
                  textAlign={"left"}
                >
                  <Typography
                    tooltipPlace="bottom"
                    tooltipText={
                      t(
                        "This price will be applied in the specified country only"
                      ) as string
                    }
                    mr={2}
                    width={30}
                  >
                    <Info fill="#3D729C" size="5" />
                  </Typography>
                  <Box width="100%" maxWidth={500}>
                    <Controller
                      name={`priceForCountry.${index}.country`}
                      control={control}
                      render={({ field: { onChange, ...rest } }) => (
                        <Select
                          isMulti
                          isLoading={loadingGetCountriesList}
                          backspaceRemovesValue={false}
                          escapeClearsValue={false}
                          closeMenuOnSelect={false}
                          menuPosition="fixed"
                          hideSelectedOptions={false}
                          // isOptionDisabled={(option: any, selectValue) => selectedCountries.includes(option.value)}
                          // isOptionDisabled={(option: any, selectValue) => selectValue.some((v: any) => v?.isFixed && v.value === option.value as any)}
                          isDisabled={!canEdit || field?.isExist}
                          // isClearable={CountryOptions.some((v: any) => selectedCountries?.includes(v?.value))}
                          placeholder={t("select country")}
                          options={list || []}
                          error={
                            t(
                              errors?.priceForCountry?.[index]?.country
                                ?.message as string
                            ) || ""
                          }
                          onChange={(val: any) => onChange(val)}
                          {...rest}
                        />
                      )}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Box>
                <Button
                  disabled={!canEdit || field?.isExist}
                  fixedSize
                  variant="danger"
                  $size="default"
                  onClick={() => remove(index)}
                >
                  <Close size="5" />
                </Button>
              </Box>
            </Flex>
            <StyledTable>
              <thead>
                <TRow style={{ height: 50 }}>
                  <th style={{ minWidth: 200 }}>
                    <Typography fontWeight="bold">Service Type</Typography>
                  </th>
                  <th>
                    <Typography fontWeight="bold">€/kWh</Typography>
                  </th>
                  <th>
                    <Typography fontWeight="bold">€/min</Typography>
                  </th>
                  <th>
                    <Flex
                      justifyContent="center"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Typography
                        pr={2}
                        tooltipText={
                          t(
                            "on quick charge EVCs (up to 22kew) the penalty between 11pm and 7am will not be applied"
                          ) as string
                        }
                      >
                        <Alarm fill="#3D729C" />
                      </Typography>
                      <Typography fontWeight="bold">Penalty €/min</Typography>
                    </Flex>
                  </th>
                  <th>
                    <Typography fontWeight="bold">Fisso€</Typography>
                  </th>
                </TRow>
              </thead>
              <tbody>
                <TRow>
                  <td style={{ paddingLeft: 10 }}>
                    <Typography fontWeight="bold">AC</Typography>
                  </td>
                  {[`AC_energy`, `AC_time`, `AC_parkingTime`, `AC_flat`].map(
                    (item: any) => (
                      <td key={`priceForCountry.${index}.${item}`}>
                        <Controller
                          name={`priceForCountry.${index}.${item}` as any}
                          control={control}
                          render={({ field: { value, onChange, ...rest } }) => (
                            <CurrencyInput
                              hideSymbol
                              max={10}
                              value={value}
                              onChangeValue={(_: any, value: any) => {
                                onChange(value);
                              }}
                              {...rest}
                              InputElement={
                                <PriceInput
                                  disabled={!canEdit}
                                  shape="square"
                                  //     error={
                                  //       t(
                                  //         errors?.priceForCountry?.[index as number]
                                  //           ?.message as any
                                  //       ) as any
                                  //     }
                                  placeholder="0.00"
                                  {...rest}
                                />
                              }
                            />
                          )}
                        />
                      </td>
                    )
                  )}
                </TRow>
                <TRow>
                  <td style={{ paddingLeft: 10 }}>
                    <Typography fontWeight="bold">{"DC<100 kW"}</Typography>
                  </td>
                  {[
                    `DC < 100kW_energy`,
                    `DC < 100kW_time`,
                    `DC < 100kW_parkingTime`,
                    `DC < 100kW_flat`,
                  ].map((item: any) => (
                    <td key={`priceForCountry.${index}.${item}`}>
                      <Controller
                        name={`priceForCountry.${index}.${item}` as any}
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <CurrencyInput
                            hideSymbol
                            max={10}
                            value={value}
                            onChangeValue={(_: any, value: any) => {
                              onChange(value);
                            }}
                            {...rest}
                            InputElement={
                              <PriceInput
                                disabled={!canEdit}
                                shape="square"
                                //     error={
                                //       t(
                                //         errors?.priceForCountry?.[index as number]
                                //           ?.message as any
                                //       ) as any
                                //     }
                                placeholder="0.00"
                                {...rest}
                              />
                            }
                          />
                        )}
                      />
                    </td>
                  ))}
                </TRow>

                <TRow>
                  <td style={{ paddingLeft: 10 }}>
                    <Typography fontWeight="bold">
                      {"100kW ≤ DC < 150kW"}
                    </Typography>
                  </td>

                  {[
                    `100kW ≤ DC < 150kW_energy`,
                    `100kW ≤ DC < 150kW_time`,
                    `100kW ≤ DC < 150kW_parkingTime`,
                    `100kW ≤ DC < 150kW_flat`,
                  ].map((item: any) => (
                    <td key={`priceForCountry.${index}.${item}`}>
                      <Controller
                        name={`priceForCountry.${index}.${item}` as any}
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <CurrencyInput
                            hideSymbol
                            max={10}
                            value={value}
                            onChangeValue={(_: any, value: any) => {
                              onChange(value);
                            }}
                            {...rest}
                            InputElement={
                              <PriceInput
                                disabled={!canEdit}
                                shape="square"
                                //     error={
                                //       t(
                                //         errors?.priceForCountry?.[index as number]
                                //           ?.message as any
                                //       ) as any
                                //     }
                                placeholder="0.00"
                                {...rest}
                              />
                            }
                          />
                        )}
                      />
                    </td>
                  ))}
                </TRow>
                <TRow>
                  <td style={{ paddingLeft: 10 }}>
                    <Typography fontWeight="bold">{"DC ≥ 150kW"}</Typography>
                  </td>
                  {[
                    `DC ≥ 150kW_energy`,
                    `DC ≥ 150kW_time`,
                    `DC ≥ 150kW_parkingTime`,
                    `DC ≥ 150kW_flat`,
                  ].map((item: any) => (
                    <td key={`priceForCountry.${index}.${item}`}>
                      <Controller
                        name={`priceForCountry.${index}.${item}` as any}
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <CurrencyInput
                            hideSymbol
                            max={10}
                            value={value}
                            onChangeValue={(_: any, value: any) => {
                              onChange(value);
                            }}
                            {...rest}
                            InputElement={
                              <PriceInput
                                disabled={!canEdit}
                                shape="square"
                                //     error={
                                //       t(
                                //         errors?.priceForCountry?.[index as number]
                                //           ?.message as any
                                //       ) as any
                                //     }
                                placeholder="0.00"
                                {...rest}
                              />
                            }
                          />
                        )}
                      />
                    </td>
                  ))}
                </TRow>
              </tbody>
            </StyledTable>
          </Card>
        );
      })}
    </Flex>
  );
};

const PriceInput = styled(Input)`
  color: ${({ theme }) => theme?.colors?.primary};
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0;
  padding-right: 0;
  max-width: 100%;
`;
