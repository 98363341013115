export enum OcppUnitOfMeasure {
  Wh = 'Wh',
  kWh = 'kWh',
  varh = 'varh',
  kvarh = 'kvarh',
  W = 'W',
  kW = 'kW',
  VA = 'VA',
  kVA = 'kVA',
  var = 'var',
  kvar = 'kvar',
  A = 'A',
  V = 'V',
  K = 'K',
  Celcius = 'Celcius',
  Fahrenheit = 'Fahrenheit',
  Percent = 'Percent',
}
