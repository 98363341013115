import { Bound, GeoJSONFeature } from "../@types/geojson";

export const calculateBound = (
    features: GeoJSONFeature<{}>[]
  ): Bound => {
    const bounds = features.reduce(
      (bounds, feature) => {
        const coords =
          feature.geometry.type === "Polygon"
            ? [feature.geometry.coordinates]
            : feature.geometry.coordinates;
        const paths = coords.map((coords) => coords[0]);
        paths.forEach((path) => {
          path.forEach(([lng, lat]) => {
            bounds.minLat = Math.min(bounds.minLat, lat);
            bounds.maxLat = Math.max(bounds.maxLat, lat);
            bounds.minLng = Math.min(bounds.minLng, lng);
            bounds.maxLng = Math.max(bounds.maxLng, lng);
          });
        });
        return bounds;
      },
      {
        minLat: 90,
        maxLat: -90,
        minLng: 180,
        maxLng: -180,
      }
    );
    return bounds;
  };
  