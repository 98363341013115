import { useEffect } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Badge,
  Box,
  Breadcrumbs,
  Crumb,
  Flex,
  Tabs,
  Typography,
} from "../../../../shared/UI";
import { Tab } from "../../../../shared/UI/Tabs";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import QueryResult from "../../../../shared/components/QueryResult";
import { useGetSession } from "../../../../shared/gql/queries/useGetSession";
import { getStatusColor } from "../../../../shared/helpers/colorHelper";
import { SectionContent } from "../../../../shared/layout";

const tabs = [
  { label: "OVERVIEW", path: "session-overview", id: 1 },
  { label: "TRIGGERS", path: "session-triggers", id: 2 },
  { label: "ACTIVITIES", path: "session-activities", id: 3 },
  { label: "SDR", path: "session-sdr", id: 3 },
];
const Session = () => {
  const { uid } = useParams();
  const { error, getSession, session, loading, refetch } = useGetSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (uid) {
      getSession({
        variables: {
          uid: uid,
        },
      });
    }
  }, [getSession, uid]);
  const place: string = "session";
  const navigateToTab = (e: any, tab: Tab) => {
    navigate(tab.path);
  };
  return (
    <QueryResult Loading={loading} data={session} error={error}>
      <SectionContent>
        <Flex fullSize position={"relative"}>
          <Breadcrumbs>
            <Crumb>
              <StyledLink to="/sessions">Sessions</StyledLink>
            </Crumb>
            <Crumb>
              <StyledLink to="#">{uid}</StyledLink>
            </Crumb>
          </Breadcrumbs>
          <Box p={2}>
            <Box>
              <Typography
                color={getStatusColor(session?.status)}
                variant={"caption20"}
              >
                {session?.status}
              </Typography>
            </Box>
            <Box>
              <Typography mr={2} variant={"caption20"}>
                <StyledLink
                  to={`/evcs/${session?.activitiesData?.[0]?.EVSE?.EVC?.uid}`}
                >
                  {session?.activitiesData?.[0]?.EVSE?.EVC?.label}
                </StyledLink>
              </Typography>
              <Badge>
                <StyledLink
                  to={`/evses/${session?.activitiesData?.[0]?.EVSE?.uid}`}
                >
                  {session?.activitiesData?.[0]?.EVSE?.label}
                </StyledLink>
              </Badge>
            </Box>
            <Box>
              <Typography variant={"caption20"}>
                <StyledLink to={`/locations/${session?.locationUid}`}>
                  {session?.Location?.address}
                </StyledLink>
              </Typography>
            </Box>
          </Box>
          <Tabs onTabClick={navigateToTab} tabs={tabs} />
          <VerticalScrollContainer bg="#eee">
            <Outlet context={{ session, loading, refetch }} />
          </VerticalScrollContainer>
        </Flex>
      </SectionContent>
    </QueryResult>
  );
};
export default Session;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
