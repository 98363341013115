import { gql, useLazyQuery } from "@apollo/client";

export const GET_PLATFORM_PARTIES = gql`
  query PlatformParties(
    $filter: PlatformPartyFilterParams
    $pagination: PaginationParams
    $sort: PlatformPartySortingParams
  ) {
    PlatformParties(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        countryCode
        IsHubject
        HasDefaultTariff
        image {
          category
          height
          thumbnail
          type
          url
          width
        }
        name
        partyId
        phoneNumber
        role
        uid
        updatedAt
        website
      }
      total
    }
  }
`;

export const useGetPlatformParties = () => {
  const [
    getPlatformPartiesQuery,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_PLATFORM_PARTIES, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getPlatformPartiesQuery,
    error,
    count: data?.PlatformParties?.total,
    platformParties: data?.PlatformParties?.results,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
