import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  Select,
  Typography,
} from "../../../../../../shared/UI";
import { usePublishRemoteRefreshStatus } from "../../../../gql/mutations/usePublishRemoteRefreshStatus";
import { useRefreshStatusProcessed } from "../../../../gql/queries/useRefreshStatusProcessed";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

type Props = {
  withOptions?: boolean;
  btnLabel?: string;
};
export const TriggerStatusNotification: React.FC<Props> = ({
  withOptions,
  btnLabel,
}) => {
  const { evc, refetch, loading }: any = useOutletContext();

  const { control, watch } = useForm<any>({
    // resolver: yupResolver(resetEvcSchema),
    shouldFocusError: true,
  });

  const { publishRemoteRefreshStatus } = usePublishRemoteRefreshStatus();
  const { getRefreshStatusProcessed } = useRefreshStatusProcessed();
  const [isrefreshingStatus, setIsrefreshingStatus] = useState<boolean>(false);

  const handleSendRefreshStatus = () => {
    const kind = withOptions ? watch("kind")?.value : "EVC";
    if (evc?.uid && kind) {
      setIsrefreshingStatus(true);

      publishRemoteRefreshStatus({
        variables: {
          input: {
            evcUid: evc?.uid,
            kind: kind,
          },
        },
      })
        .then((res) => {
          const requestUid = res?.data?.publishRemoteRefreshStatus?.requestUid;
          if (requestUid) {
            setTimeout(() => {
              getRefreshStatusProcessed({
                variables: {
                  filter: {
                    requestUid: requestUid,
                  },
                },
              })
                .then((res) => {
                  if (res.data?.RefreshStatusProcessed?.results?.[0]?.status) {
                    toast.success("Status Triggered Successfully");
                  } else {
                    toast.error(
                      res.data?.RefreshStatusProcessed?.results?.[0]?.info
                    );
                  }
                })
                .catch((error) => {
                  setIsrefreshingStatus(false);
                })
                .finally(() => {
                  setIsrefreshingStatus(false);
                });
            }, 2000);
          }
        })
        .catch((error) => {
          setIsrefreshingStatus(false);
        });
    }
  };

  return (
    <>
      {withOptions ? (
        <>
          <Typography variant={"title20"}>
            {" "}
            Trigger Status Notification
          </Typography>
          <Box mt={2}>
            <Controller
              name="kind"
              control={control}
              render={({ field }) => (
                <Select
                  options={[
                    { label: "Evc", value: "EVC" },
                    {
                      label: "Diagnostic Upload",
                      value: "FIAGNOSTIC_UPLOAD",
                    },
                    { label: "firmware update", value: "FIRMWARE_UPDATE" },
                  ]}
                  placeholder="Select Type"
                  {...field}
                />
              )}
            />
          </Box>
        </>
      ) : null}

      <Box my={withOptions ? 4 : 0}>
        <Button
          block
          $size="default"
          loading$={isrefreshingStatus}
          onClick={handleSendRefreshStatus}
          disabled={isrefreshingStatus || (!watch("kind") && withOptions)}
        >
          {btnLabel ? btnLabel : "Send"}
        </Button>
      </Box>
    </>
  );
};
