/** @format */

import { Evse } from "@bepower/greta-types";
import {
  OcppStatusNotificationReq,
  OcppMeterValuesReq,
  OcppBootNotificationReq,
  OcppAuthorizeReq,
  OcppStopTransactionReq,
  OcppChargingPointStatus,
  OcppDiagnosticsStatusNotificationReq,
  OcppRemoteStartTransactionReq,
  OcppRemoteStopTransactionReq,
  OcppTriggerMessageReq,
  OcppGetConfigurationReq,
  OcppReserveNowConf,
  OcppGetConfigurationConf,
  OcppRemoteChangeAvailabilityReq,
  OcppRemoteChangeAvailabilityConf,
  OcppChangeConfigurationReq,
  OcppChangeConfigurationConf,
  OcppGetDiagnosticsReq,
  OcppGetDiagnosticsConf,
  OcppResetReq,
  OcppResetConf,
  OcppSetChargingProfileReq,
  OcppSetChargingProfileConf,
  OcppHeartbeatReq,
  OcppFirmwareStatusNotificationReq,
  OcppRemoteUnlockConnectorReq,
  OcppRemoteUnlockConnectorConf,
  OcppUpdateFirmwareReq,
  OcppUpdateFirmwareConf,
  OcppReserveNowReq,
  OcppStartTransactionReq,
  OcppRemoteStartTransactionConf,
  OcppRemoteStopTransactionConf,
  OcppTriggerMessageConf,
  OcppClearChargingProfileReq,
  OcppClearChargingProfileConf,
  OcppDataTransferReq,
  OcppDataTransferConf,
} from "./ocpp1.6";

export type KeyValue = {
  key: string;
  readonly: boolean;
  value?: string;
};

export enum NinaVendor {
  ENSTO = `ENSTO`,
}

export enum OcppCpMethod {
  MeterValues = `MeterValues`,
  BootNotification = `BootNotification`,
  StatusNotification = `StatusNotification`,
  Authorize = `Authorize`,
  StartTransaction = `StartTransaction`,
  StopTransaction = `StopTransaction`,
  DiagnosticsStatusNotification = `DiagnosticsStatusNotification`,
  Heartbeat = `Heartbeat`,
  FirmwareStatusNotification = `FirmwareStatusNotification`,
  DataTransfer = "DataTransfer",
}

// Cs === Central System
export enum OcppCsMethos {
  CancelReservation = `CancelReservation`,
  TriggerMessage = `TriggerMessage`,
  GetConfiguration = `GetConfiguration`,
  RemoteStartTransaction = `RemoteStartTransaction`,
  RemoteStopTransaction = `RemoteStopTransaction`,
  ReserveNow = `ReserveNow`,
  ChangeAvailability = `ChangeAvailability`,
  ChangeConfiguration = `ChangeConfiguration`,
  GetDiagnostics = `GetDiagnostics`,
  Reset = `Reset`,
  SetChargingProfile = `SetChargingProfile`,
  UnlockConnector = `UnlockConnector`,
  UpdateFirmware = `UpdateFirmware`,
  ClearCache = "ClearCache",
  GetLocalListVersion = "GetLocalListVersion",
  SendLocalList = "SendLocalList",
  ClearChargingProfile = "ClearChargingProfile",
  /*
  GetCompositeSchedule = "GetCompositeSchedule",
  */
}

export interface OcppMethodDataType {
  [OcppCpMethod.MeterValues]: OcppMeterValuesReq;
  [OcppCpMethod.BootNotification]: OcppBootNotificationReq;
  [OcppCpMethod.StatusNotification]: OcppStatusNotificationReq;
  [OcppCpMethod.Authorize]: OcppAuthorizeReq;
  [OcppCpMethod.StartTransaction]: OcppStartTransactionReq;
  [OcppCpMethod.StopTransaction]: OcppStopTransactionReq;
  [OcppCpMethod.DiagnosticsStatusNotification]: OcppDiagnosticsStatusNotificationReq;
  [OcppCpMethod.Heartbeat]: OcppHeartbeatReq;
  [OcppCpMethod.FirmwareStatusNotification]: OcppFirmwareStatusNotificationReq;
  [OcppCpMethod.DataTransfer]: OcppDataTransferReq;

  // Cs Methods
  [OcppCsMethos.CancelReservation]: CancelReservationReq;
  [OcppCsMethos.RemoteStartTransaction]: OcppRemoteStartTransactionReq;
  [OcppCsMethos.RemoteStopTransaction]: OcppRemoteStopTransactionReq;
  [OcppCsMethos.TriggerMessage]: OcppTriggerMessageReq;
  [OcppCsMethos.GetConfiguration]: OcppGetConfigurationReq;
  [OcppCsMethos.ReserveNow]: OcppReserveNowReq;
  [OcppCsMethos.ChangeAvailability]: OcppRemoteChangeAvailabilityReq;
  [OcppCsMethos.ChangeConfiguration]: OcppChangeConfigurationReq;
  [OcppCsMethos.GetDiagnostics]: OcppGetDiagnosticsReq;
  [OcppCsMethos.Reset]: OcppResetReq;
  [OcppCsMethos.SetChargingProfile]: OcppSetChargingProfileReq;
  [OcppCsMethos.UnlockConnector]: OcppRemoteUnlockConnectorReq;
  [OcppCsMethos.UpdateFirmware]: OcppUpdateFirmwareReq;
  [OcppCsMethos.ClearChargingProfile]: OcppClearChargingProfileReq;
}

export interface OcppMethodDataTypeResponse {
  [OcppCsMethos.CancelReservation]: SimpleNinaResponseConf;
  [OcppCsMethos.RemoteStartTransaction]: OcppRemoteStartTransactionConf;
  [OcppCsMethos.RemoteStopTransaction]: OcppRemoteStopTransactionConf;
  [OcppCsMethos.TriggerMessage]: OcppTriggerMessageConf;
  [OcppCsMethos.GetConfiguration]: OcppGetConfigurationConf;
  [OcppCsMethos.ReserveNow]: OcppReserveNowConf;
  [OcppCsMethos.ChangeAvailability]: OcppRemoteChangeAvailabilityConf;
  [OcppCsMethos.ChangeConfiguration]: OcppChangeConfigurationConf;
  [OcppCsMethos.GetDiagnostics]: OcppGetDiagnosticsConf;
  [OcppCsMethos.Reset]: OcppResetConf;
  [OcppCsMethos.SetChargingProfile]: OcppSetChargingProfileConf;
  [OcppCsMethos.UnlockConnector]: OcppRemoteUnlockConnectorConf;
  [OcppCsMethos.UpdateFirmware]: OcppUpdateFirmwareConf;
  [OcppCsMethos.ClearCache]: SimpleNinaResponseConf;
  [OcppCsMethos.GetLocalListVersion]: OcppGetLocalListVersionConf;
  [OcppCsMethos.SendLocalList]: OcppSendLocalListConf;
  [OcppCsMethos.ClearChargingProfile]: OcppClearChargingProfileConf;
  [OcppCpMethod.DataTransfer]: OcppDataTransferConf;
}
export type NinaMeterValueConfig = {
  time: number;
  value: number;
};

export type OcppMethodMessage<T extends keyof OcppMethodDataType> = {
  method: T;
  methodMessage: OcppMethodDataType[T];
};

// TODO: Improve the type constraint
export type OcppMethodResponse<R extends keyof OcppMethodDataTypeResponse> = {
  msgId: string;
  responseData: OcppMethodDataTypeResponse[R];
};

export type NinaEvse = {
  label: string;
  connectorId: number;
  transactionId: number;
  cableConnected: boolean;
  meter: number;
  status: OcppChargingPointStatus;
  authorized: {
    status: boolean;
    type: `local` | `remote` | null;
    idTag: string | undefined;
  };
  reserved: {
    status: boolean;
    expiryDate: string | null;
    idTag: string | null;
    reservationId: number | null;
  };
} & Partial<Evse>;

export type NinaLog = {
  message: string;
  level: string;
  data?: string;
  requestData?: string;
  date: Date;
};

export type NinaState = {
  wsStatus: `CONNECTING` | `OPEN` | `CLOSING` | `CLOSED`;
  status: OcppChargingPointStatus;
  EVSEs: { [evseLabel: string]: NinaEvse };
  pendingCalls: number;
  pendingResponses: number;
  logs: NinaLog[];
};

export interface CancelReservationReq {
  reservationId: number;
}

export interface SimpleNinaResponseConf {
  status: `Accepted` | `Rejected`;
}

export interface OcppGetLocalListVersionConf {
  listVersion: number;
}

export interface OcppSendLocalListConf {
  status: `Accepted` | `Failed` | `NotSupported` | `VersionMismatch`;
}

export interface NinaTimer {
  [evseLabel: string]: NodeJS.Timeout | null;
}
