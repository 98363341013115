import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { checkIsActive } from "../helpers/routerHelper";
import { Flex } from "./Flex";
import { PaginationItem } from "./Pagination";
import WaveEffect from "./WaveEffect";
import RenderOnRole from "./RenderOnRole";
export type Tab = {
  id: number;
  label: string;
  onClickTab?: (tab: Tab) => void;
  path: string;
  roles?: string[];
};
type TapsProps = {
  tabs: Tab[];
  onTabClick: (e: ChangeEvent<unknown>, clickedItem: Tab) => void;
};
const StyledUl = styled.ul`
  display: flex;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

export const Tabs: React.FC<TapsProps> = ({ tabs, onTabClick }) => {
  const [showButtons, setShowButtons] = useState<boolean>(true);
  const containerRef = useRef<any>();
  const parentRef = useRef<any>();
  const location = useLocation();
  const handleScroll = useCallback((e: any) => {}, []);

  const [positionLeft, setPositionLeft] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const el = containerRef.current;
      setPositionLeft(el.scrollLeft);
      setWidth(el.offsetWidth);
    };

    const element = containerRef.current;
    element.addEventListener("scroll", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const div = containerRef.current;
    div.addEventListener("scroll", handleScroll);
    if (div) {
      const horizontal = div.scrollLeft;
      const scrollWidth = div.scrollWidth;
      if (horizontal < scrollWidth / 2) {
        setShowButtons(false);
      } else {
        setShowButtons(true);
      }
      const onWheel = (e: any) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        div.scrollTo({
          left: div.scrollLeft + e.deltaY,
          behavior: "smooth",
        });
      };

      div.addEventListener("wheel", onWheel);
      return () => div.removeEventListener("wheel", onWheel);
    }
  }, [handleScroll, containerRef]);
  const onPrevButtonClick = (e: any) => {
    sideScroll(containerRef.current, 25, 100, -10);
  };

  const onNextButtonClick = (e: any) => {
    sideScroll(containerRef.current, 25, 100, 10);
  };
  const onPageButtonClick = (clickedItem: Tab) => (e: any) => {
    onTabClick(e, clickedItem);
  };

  //
  //
  return (
    <Flex
      width={"100%"}
      // bg="#eee"
      alignItems={"center"}
      flexDirection={"row"}
      ref={parentRef}
      py={2}
      // borderBottom={"2px solid #3D729C"}
    >
      {/* <WaveEffect>
        <PaginationItem
          $variant="primary"
          onClick={onPrevButtonClick}
          $active={false}
          id="btnRight"
          $fill={false}
          disabled={positionLeft === 0}
        >
          {true ? "<" : "prev"}
        </PaginationItem>
      </WaveEffect> */}

      <StyledUl ref={containerRef} id="menu">
        {tabs.map((t, i: number) => {
          return (
            <RenderOnRole key={i} roles={t?.roles || []}>
              <PaginationItem
                style={{ minWidth: 100, fontWeight: "bolder" }}
                $variant={
                  checkIsActive(t.path, location.pathname) ? "primary" : "light"
                }
                $active={checkIsActive(t.path, location.pathname)}
                onClick={onPageButtonClick(t)}
              >
                {t.label}
              </PaginationItem>
            </RenderOnRole>
          );
        })}
      </StyledUl>
      {/* <WaveEffect>
        <PaginationItem
          $variant="primary"
          onClick={onNextButtonClick}
          $active={false}
          id="btnLeft"
          $fill={false}
          disabled={positionLeft === width}
        >
          {true ? ">" : "Next"}
        </PaginationItem>
      </WaveEffect> */}
    </Flex>
  );
};
