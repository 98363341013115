import { SVGProps } from "react";
const PreBacklog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#FFC700"
      stroke="#fff"
      strokeWidth={2.074}
      d="M12.372 23c5.728 0 10.372-4.701 10.372-10.5S18.1 2 12.372 2 2 6.701 2 12.5 6.644 23 12.372 23Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.415 11.937v-3.73a1.208 1.208 0 0 0-2.415 0l.003 4.168c0 .276.094.552.285.778l3.742 4.424a1.205 1.205 0 0 0 1.84-1.556l-3.455-4.083Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PreBacklog;
