import { t } from "i18next";
import { Checkbox, Flex } from "../UI"


type TableContentSettingProps = {
      columnKeys: string[];
      setColumnKeys: React.Dispatch<React.SetStateAction<string[]>>;
      defaultColumnsKeys: string[];
}



export const TableContentSetting: React.FC<TableContentSettingProps> = ({
      columnKeys, setColumnKeys, defaultColumnsKeys
}) => {
      return (
            <Flex py={3} pr={4} pl={2}>
                  <Flex flexDirection='row' p={2} >
                        <Checkbox
                              $size='default'
                              onChange={() => {
                                    if (columnKeys?.length !== defaultColumnsKeys?.length) {
                                          setColumnKeys(defaultColumnsKeys)
                                    } else {
                                          setColumnKeys([])
                                    }
                              }} checked={columnKeys.length === defaultColumnsKeys.length} >
                              {t('select all')}
                        </Checkbox>
                  </Flex>
                  {defaultColumnsKeys.map((key: string, index) => {
                        return (
                              <Flex flexDirection='row' p={2} key={index} >
                                    <Checkbox $size='default' variant='primary' id={key} type='checkbox' onChange={() => {
                                          if (columnKeys.includes(key)) {
                                                setColumnKeys((prev) => prev.filter((k) => k !== key))
                                          } else {
                                                setColumnKeys((prev) => [...prev, key])
                                          }
                                    }} checked={columnKeys.includes(key)} >

                                          {t(key)}
                                    </Checkbox>
                                    {/* <InputLabel htmlFor={key} style={{ paddingLeft: 10 }}>{key}</InputLabel> */}
                              </Flex>
                        )
                  })}
            </Flex>
      )
}
