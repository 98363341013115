import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { checkIsActive } from "../../helpers/routerHelper";
import { Flex } from "../Flex";
import { LiElement } from "../List";
import { Typography } from "../Typography";
import WaveEffect from "../WaveEffect";
import Dropdown from "./Dropdown";
import "./menuStyle.css";
import RenderOnRole from "../RenderOnRole";
import { Submenu } from "./Submenu";
import { useTheme } from "styled-components";
import { AngleDown, AngleTop } from "../../assets/svg";
import { Box } from "../Box";
export type MenuItem = {
  label: string;
  path: string;
  roles: string[];
  icon: React.ReactNode;
  submenu?: any[];
};
export type MenuitemProps = {
  item: MenuItem;
  depthLevel: number;
  isOpen: boolean;
  id: number;
  onClick: (value: string) => void;
};

const Menuitem: React.FC<MenuitemProps> = ({
  item,
  depthLevel,
  isOpen,
  id,
  onClick,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();

  let ref = useRef<any>();

  useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mouseup", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mouseup", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };
  useEffect(() => {
    if (
      !item.submenu?.some((item: any) => location.pathname.includes(item.path))
    ) {
      setDropdown(false);
    }
    // if (!isOpen) {
    //   setDropdown(false);
    // }
  }, [isOpen, item.submenu, location.pathname]);

  if (item.submenu) {
    return (
      <RenderOnRole roles={item.roles}>
        <LiElement
          selected={checkIsActive(item.path, location.pathname)}
          className="menu-item "
          ref={ref}
          {...(!isOpen
            ? {
                onMouseEnter: onMouseEnter,
                onMouseLeave: onMouseLeave,
              }
            : {})}
        >
          {item.submenu ? (
            <Typography>
              <Link
                style={
                  item.submenu?.some((item: any) =>
                    location.pathname.includes(item.path)
                  )
                    ? isOpen
                      ? {
                          borderRight: " 4px solid #122967",

                          color: "white",
                          fill: "white",
                          fontWeight: "bold",
                        }
                      : {
                          borderRight: " 4px solid #122967",
                          color: "white",
                          fill: "white`",
                        }
                    : {}
                }
                to="#"
                aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={() => {
                  setDropdown((prev) => !prev);
                  onClick(`sub-${id}`);
                }}
              >
                <Box flexShrink={0} width={"30px"}>
                  {item.icon}
                </Box>
                {isOpen ? (
                  <Flex
                    fullSize
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    style={{ paddingLeft: 5 }}
                  >
                    <Flex flex={1}>{item.label}</Flex>
                    <Flex flexShrink={0}>
                      {dropdown ? (
                        <AngleTop size="4" />
                      ) : (
                        <AngleDown size="4" />
                      )}
                    </Flex>
                  </Flex>
                ) : null}{" "}
              </Link>{" "}
              {isOpen ? (
                <RenderOnRole roles={item.roles}>
                  <Submenu
                    id={id}
                    depthLevel={depthLevel}
                    submenus={item.submenu}
                    isOpen={isOpen}
                    dropdown={dropdown}
                  />
                </RenderOnRole>
              ) : (
                <Dropdown
                  depthLevel={depthLevel}
                  submenus={item.submenu}
                  dropdown={dropdown}
                  isOpen={isOpen}
                />
              )}
            </Typography>
          ) : null}
        </LiElement>
      </RenderOnRole>
    );
  }
  return (
    <RenderOnRole roles={item.roles}>
      <LiElement
        onClick={() => {
          onClick(`sub-${id}`);
        }}
        selected={checkIsActive(item.path, location.pathname)}
      >
        <Typography tooltipText={!isOpen && depthLevel === 0 ? item.label : ""}>
          <WaveEffect variant="dark">
            <Link to={item.path}>
              <Box flexShrink={0} width={"30px"}>
                {item.icon}
              </Box>
              {isOpen || depthLevel !== 0 ? <Flex>{item.label}</Flex> : null}{" "}
            </Link>
          </WaveEffect>
        </Typography>
      </LiElement>
    </RenderOnRole>
  );
};

export default Menuitem;
