import { gql, useMutation } from "@apollo/client";
import { GET_TARIFF } from "./useGetTariff";

export const PUBLISH_TARIFF = gql`
  mutation publishTariff($input: TariffV2Input!) {
    publishTariff(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const usePublishTariff = () => {
  const [publishTariff, { data, loading, error, }] = useMutation<any>(
    PUBLISH_TARIFF,

  );
  return { publishTariff, error, data, loadingPublishTariff: loading };
};
