import { IndexedDB } from "react-indexed-db-hook";
import { SectionLayout } from "../../../../shared/layout";
import { SiteSelectionContextProvider } from "../../slices/ContextProvider";
import { SiteSelectionEvcDataContextProvider } from "../../slices/EvcDataContextProvider";
import { SiteSelectionGeoDataContextProvider } from "../../slices/GeoDataContextProvider";
import { DBConfig } from "../../utils/db.config";
import { FreeRide as FR } from "../components/FreeRide";

type Props = {};

const FreeRide: React.FC<Props> = () => {
  return (
    <SectionLayout label="site-selection">
      <SiteSelectionContextProvider>
        <SiteSelectionEvcDataContextProvider>
          <SiteSelectionGeoDataContextProvider>
            <IndexedDB {...DBConfig}>
              <FR />
            </IndexedDB>
          </SiteSelectionGeoDataContextProvider>
        </SiteSelectionEvcDataContextProvider>
      </SiteSelectionContextProvider>
    </SectionLayout>
  );
};

export default FreeRide;
