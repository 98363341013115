import { t } from "i18next"
import { LoadingLayer, Flex, SimpleLoader, Typography, Overlay } from "../UI"
import { Absolute } from "../UI/Absolute";



type Props = {
   path?: string;
}
export const RedirectTo: React.FC<Props> = ({ path }) => {
   return (
      <Overlay  >
         <SimpleLoader />
      </Overlay>
   )
}