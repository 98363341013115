/** @format */

import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../shared/messages/messages";
import {
  NewProjectPhase2,
  NewProjectPhase3,
  Project,
  ProjectStatus,
} from "../@types/project";
import {
  useAddSiteSelectionProject,
  useDeleteSiteSelectionProject,
} from "../graphql/useAddSiteSelectionProject";
import { useGetSiteSelectionProjects } from "../graphql/useGetSiteSelectionProjects";
import { useToggleSiteSelectionProjectFavorite } from "../graphql/useToggleFavorite";
import { useProject } from "./useProject";

import { isEqual, isNil } from "lodash";
import { useDeleteSiteSelectionLocation } from "../graphql/useSiteSelectionLocation";
import { DeleteSiteSelectionLocationInput } from "../../../shared/gql/generated/graphql";

export const useProjects = (
  props: {
    loadData?: boolean;
    filter?: {
      status: Project["status"] | "all";
      visibility?: Project["visibility"] | undefined;
    };
    sort?: {
      field: keyof Project;
      order: "asc" | "desc";
    };
    limit?: number;
  } = {}
) => {
  const [prevProps, setPrevProps] = useState(props);
  const [projects, setProjects] = useState<Project[]>([]);
  const state = useSelector((state: any) => state);
  const {
    currentUser,
  }: {
    currentUser?: {
      email: string;
      firstName?: string;
      lastName?: string;
    };
  } = state?.userSettings;

  const {
    getSiteSelectionProjects,
    refetch,
    fetchMore,
    siteSelectionProjects,
    count,
    loading,
  } = useGetSiteSelectionProjects();

  useEffect(() => {
    if (props.loadData === false || loading) {
      return;
    }

    if (isEqual(siteSelectionProjects, projects)) {
      return;
    }

    setProjects(siteSelectionProjects);
  }, [loading, projects, props.loadData, siteSelectionProjects]);

  const { addSiteSelectionProject } = useAddSiteSelectionProject();
  const { deleteSiteSelectionProject } = useDeleteSiteSelectionProject();

  const { deleteSiteSelectionLocation } = useDeleteSiteSelectionLocation();
  const { toggleSiteSelectionProjectFavorite } =
    useToggleSiteSelectionProjectFavorite();

  const { updateProject } = useProject();

  const equals = isEqual(prevProps, props);
  const pr = equals ? prevProps : props;

  useEffect(() => {
    if (props.loadData === false || loading || (!isNil(count) && equals)) {
      return;
    }

    getSiteSelectionProjects?.({
      variables: {
        filter: {
          status: pr.filter?.status === "all" ? undefined : pr.filter?.status,
          visibility: pr.filter?.visibility,
        },
      },
    }).then(() => {
      setPrevProps(props);
    });
  }, [projects, getSiteSelectionProjects, pr, equals, count, props, loading]);

  const user = useMemo(() => {
    if (!currentUser) return null;
    return {
      id: currentUser?.email,
      name:
        currentUser.firstName || currentUser.lastName
          ? `${currentUser.firstName ?? ""} ${
              currentUser.lastName ?? ""
            }`.trim()
          : currentUser?.email.split("@")[0],
    };
  }, [currentUser]);

  const createProject = useCallback(
    (project: NewProjectPhase2 | NewProjectPhase3) => {
      if (!user) return;

      return addSiteSelectionProject({
        variables: {
          input: {
            name: project.name,
            type: project.type,
            accessType: project.accessType,
            visibility: project.visibility,
            status: "open",
          },
        },
      })
        .then((res) => {
          if (res?.data) {
            toast.success(MESSAGES.SITE_SELECTION.success.createProject);
            setTimeout(() => refetch(), 1000);
          }

          return JSON.parse(res.data.addSiteSelectionProject.info) as {
            _index: string;
            _id: string;
          };
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    },
    [addSiteSelectionProject, user, refetch]
  );
  const updateStatus = (project: Project, status: ProjectStatus) => {
    const pr = projects?.find((pr) => project.id === pr.id) ?? project;
    if (!pr) return;

    return updateProject(pr, {
      status: status,
    }).then((response) => {
      if (response?.data) {
        toast.success(MESSAGES.SITE_SELECTION.success.editProject);
        setTimeout(() => refetch(), 1000);
      }
    });
  };

  const deleteProjects = useCallback(
    async (ids: string[]) => {
      const res = await Promise.all(
        ids.map(async (id) => {
          const pr = projects?.find((p) => p.id === id);

          if (pr) {
            await Promise.all(
              pr.locations.map((l) => {
                return deleteSiteSelectionLocation({
                  variables: {
                    input: {
                      uid: l.id,
                    } satisfies DeleteSiteSelectionLocationInput,
                  },
                });
              })
            );
          }

          return deleteSiteSelectionProject({
            variables: {
              input: {
                uid: id,
              },
            },
          });
        })
      );

      const anySuccess = res.some((r) => r?.data);
      if (anySuccess) {
        toast.success(MESSAGES.SITE_SELECTION.success.deleteProject);
        setTimeout(() => refetch(), 1000);
      }
    },
    [deleteSiteSelectionLocation, deleteSiteSelectionProject, projects, refetch]
  );

  const addFavorite = useCallback(
    async (ids: string[]) => {
      const res = await Promise.all(
        ids.map((id) => {
          return toggleSiteSelectionProjectFavorite({
            variables: {
              uid: id,
            },
          });
        })
      );
      const anySuccess = res.some((r) => r?.data);
      if (anySuccess) {
        toast.success(MESSAGES.SITE_SELECTION.success.editProject);
        setTimeout(() => refetch(), 1000);
      }
    },
    [toggleSiteSelectionProjectFavorite, refetch]
  );

  const isFavorite = useCallback(
    (id: string) => {
      if (!user) return;
      const project = projects?.find((project) => project.id === id);
      return project?.favoriteBy?.includes(user.id) ?? false;
    },
    [projects, user]
  );

  const favoriteProjects = useMemo(
    () =>
      projects
        ?.filter((project) => isFavorite(project.id))
        .map((project) => project.id),
    [projects, isFavorite]
  );

  return {
    createProject,
    projects,
    deleteProjects,
    updateStatus,
    addFavorite,
    isFavorite,
    refetch,
    fetchMore,
    favoriteProjects,
  };
};
