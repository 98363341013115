import { SectionLayout } from "../../../../shared/layout";
import { Flex } from "../../../../shared/UI";
import { Dashboard } from "../components/Dashboard";

type Props = {};

const WebToolDashboard: React.FC<Props> = () => {

  return (
    <SectionLayout label="site-selection">
      <Flex
        flex={1}
        width="100%"
        minWidth={[400, 400, 400, 400, 400, 500]}
      >

        <Dashboard width="100%" />
      </Flex>
    </SectionLayout>
  );
};

export default WebToolDashboard;
