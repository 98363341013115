/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated";

export const GET_PLATFORMS = gql(`
  query Platforms(
    $filter: FilterParams
    $pagination: PaginationParams
    $sort: PlatformSortingParams
  ) {
    Platforms(filter: $filter, pagination: $pagination, sort: $sort) {
      __typename
      pagination {
        cursor
        last
      }
      results {
        Interoperabilities(pending: true) {
          total
          results {
            Tariff {
              tariff {
                updatedAt
                uid

                label
              }
              assoc {
                label
                Tariff {
                  updatedAt
                  uid
                }
                validFrom
                validTo
                uid
                score
              }
            }
          }
        }
        HubParty {
          countryCode

          name
          partyId
          phoneNumber
          role
          uid
          updatedAt
          website
        }
        entryPointUrl
        flavor
        hubPartyUid
        name
        needsReliablePush
        offlineTokenIn
        offlineTokenOut
        protocol
        pullTokens
        skipCredentialsRefresh
        status
        uid
        updatedAt
        version
        asyncPush
      }
      total
    }
  }
`);

export const useGetPlatforms = () => {
  const [
    executeGetPlatforms,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      networkStatus,
      startPolling,
      called,
    },
  ] = useLazyQuery(GET_PLATFORMS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    initialFetchPolicy: "cache-and-network",
  });

  const fetchMore = () => {
    if (
      data?.Platforms?.pagination?.cursor &&
      data?.Platforms?.results?.length < data.Platforms?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.Platforms?.pagination.cursor,
            limit: 50,
          },
        },
        updateQuery({ Platforms: prevData }, { fetchMoreResult }) {
          if (prevData?.results?.length) {
            return {
              Platforms: {
                results: [
                  ...prevData?.results,
                  ...fetchMoreResult?.Platforms?.results,
                ],
                __typename: fetchMoreResult.Platforms.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.Platforms?.pagination,
              },
            };
          } else {
            return {
              Platforms: {
                results: [...fetchMoreResult?.Platforms?.results],
                __typename: fetchMoreResult.Platforms.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.Platforms?.pagination,
              },
            };
          }
        },
      });
    }
  };

  return {
    loading,
    executeGetPlatforms,
    error,
    count: data?.Platforms?.total,
    platforms: data?.Platforms?.results,
    pagination: data?.Platforms?.pagination,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    called,
  };
};
