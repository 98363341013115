import { Nina } from "./nina";
import {
  OcppMeterValuesReq,
  OcppReadingContext,
  OcppMeasurand,
  OcppUnitOfMeasure,
  OcppValueFormat,
  OcppPhase,
  OcppMeterValueLocation,
} from "./ocpp1.6";
import { NinaEvse } from "./nina.ocpp.types";

export function generateENSTOMeterValue(
  evse: NinaEvse,
  meterValue: number
): OcppMeterValuesReq {
  return {
    connectorId: evse.connectorId,
    transactionId: evse.transactionId === 0 ? undefined : evse.transactionId,
    meterValue: [
      {
        sampledValue: [
          {
            context: OcppReadingContext.SamplePeriodic,
            measurand: OcppMeasurand.EnergyActiveImportRegister,
            unit: OcppUnitOfMeasure.Wh,
            value: meterValue.toString(),
            location: OcppMeterValueLocation.Outlet,
            format: OcppValueFormat.Raw,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            measurand: OcppMeasurand.PowerActiveImport,
            unit: OcppUnitOfMeasure.W,
            value: `11000`,
            location: OcppMeterValueLocation.Outlet,
            format: OcppValueFormat.Raw,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            format: OcppValueFormat.Raw,
            location: OcppMeterValueLocation.Outlet,
            measurand: OcppMeasurand.PowerOffered,
            unit: OcppUnitOfMeasure.W,
            value: `22080`,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            format: OcppValueFormat.Raw,
            location: OcppMeterValueLocation.Outlet,
            measurand: OcppMeasurand.CurrentImport,
            unit: OcppUnitOfMeasure.A,
            value: `16`,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            format: OcppValueFormat.Raw,
            location: OcppMeterValueLocation.Outlet,
            measurand: OcppMeasurand.CurrentImport,
            phase: OcppPhase.L1,
            unit: OcppUnitOfMeasure.A,
            value: `16`,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            format: OcppValueFormat.Raw,
            location: OcppMeterValueLocation.Outlet,
            measurand: OcppMeasurand.CurrentImport,
            phase: OcppPhase.L2,
            unit: OcppUnitOfMeasure.A,
            value: `16`,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            format: OcppValueFormat.Raw,
            location: OcppMeterValueLocation.Outlet,
            measurand: OcppMeasurand.CurrentImport,
            phase: OcppPhase.L3,
            unit: OcppUnitOfMeasure.A,
            value: `16`,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            format: OcppValueFormat.Raw,
            location: OcppMeterValueLocation.Outlet,
            measurand: OcppMeasurand.CurrentOffered,
            unit: OcppUnitOfMeasure.A,
            value: `32`,
          },
        ],
        timestamp: Nina.date(),
      },
    ],
  };
}

// Note: Alpitronic send every tot second a meter value "Sample.Clock"
export function generateAlpitronicMeterValue(
  evse: NinaEvse,
  meterValue: number
): OcppMeterValuesReq {
  return {
    connectorId: evse.connectorId,
    transactionId: evse.transactionId === 0 ? evse.transactionId : undefined,
    meterValue: [
      {
        sampledValue: [
          {
            context: OcppReadingContext.SamplePeriodic,
            measurand: OcppMeasurand.EnergyActiveImportRegister,
            unit: OcppUnitOfMeasure.Wh,
            value: meterValue.toString(),
            location: OcppMeterValueLocation.Outlet,
            format: OcppValueFormat.Raw,
          },
          {
            context: OcppReadingContext.SamplePeriodic,
            measurand: OcppMeasurand.PowerActiveImport,
            unit: OcppUnitOfMeasure.W,
            value: "11000",
            location: OcppMeterValueLocation.Outlet,
            format: OcppValueFormat.Raw,
          },
        ],
        timestamp: Nina.date(),
      },
    ],
  };
}
