import { SVGProps } from "react";
const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#122967"
      strokeWidth={2.074}
      d="M12.372 23c5.728 0 10.372-4.701 10.372-10.5S18.1 2 12.372 2 2 6.701 2 12.5 6.644 23 12.372 23Z"
    />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={7}
      x={7}
      y={9}
      fill="none"
      {...props}
    >
      <path
        fill="#122967"
        d="M10.397 3.174C9.597 1.431 7.517.262 5.218.262h-.01C2.915.265.838 1.436.04 3.174L0 3.262l.04.087c.8 1.743 2.88 2.913 5.179 2.913h.01c2.292-.004 4.37-1.174 5.168-2.913l.04-.087-.04-.088Zm-5.168 2.67h-.01C3.12 5.843 1.223 4.81.461 3.261 1.223 1.717 3.115.684 5.209.68h.01c2.098 0 3.993 1.033 4.756 2.582-.76 1.545-2.653 2.578-4.746 2.581Z"
      />
      <path
        fill="#122967"
        fillRule="evenodd"
        d="M3.744 3.28a1.479 1.479 0 1 1 2.958 0 1.479 1.479 0 0 1-2.958 0Zm.873.038v-.913a.13.13 0 0 1 .139-.131h.576c.09 0 .155.087.127.178l-.14.433c-.012.04.016.082.056.082h.341c.105 0 .17.116.118.207l-.67.994a.136.136 0 0 1-.117.065h-.04a.138.138 0 0 1-.124-.19l.207-.503c.016-.04-.012-.084-.055-.084h-.282a.134.134 0 0 1-.136-.138Z"
        clipRule="evenodd"
      />
      <path
        fill="#122967"
        d="M5.218 1.063A2.201 2.201 0 0 0 3.02 3.261c0 1.212.985 2.199 2.198 2.199s2.2-.987 2.2-2.2c0-1.212-.987-2.199-2.2-2.199Zm0 3.98c-.982 0-1.78-.8-1.78-1.781 0-.982.799-1.78 1.78-1.78s1.78.798 1.78 1.78c0 .981-.799 1.78-1.78 1.78Z"
      />
    </svg>
  </svg>
);
export default Eye;
