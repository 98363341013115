import { OverlayView, Polygon, useGoogleMap } from "@react-google-maps/api";
import { isEqual } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSiteSelectionHexagons } from "../../../graphql/useSiteSelectionHexagons";
import { useProject } from "../../../hooks/useProject";
import { useSiteSelectionContext } from "../../../slices/ContextProvider";
import { useSiteSelectionEvcDataContext } from "../../../slices/EvcDataContextProvider";
import { calculateColor, colorToRgb } from "../../../utils/calculate-color";

type HexagonLayerProps = {
  city: string | null;
  selected?: HexagonProperties | null;
  projectId?: string | null;
  setSelected?: (data: HexagonProperties) => void;
  onSelectedClick?: (
    event: google.maps.MapMouseEvent,
    data: HexagonProperties
  ) => void;
};

export type HexagonProperties = {
  id: string;
  lat: number;
  lon: number;
  ac: number;
  dc: number;
  n_ac: number;
  n_dc: number;
  kwh_ac: number;
  kwh_dc: number;
  kwhPred: number;
  kwhPredOptimized: number;
  cef1: boolean;
  cef2: boolean;
  score: number;
  runDate: Date;
  polygon: {
    lat: number;
    lng: number;
  }[];
};

const HexagonLayerInner: React.FC<
  Omit<HexagonLayerProps, "city"> & {
    hexagons: HexagonProperties[] | null;
    loading: boolean;
  }
> = function ({ selected, setSelected, onSelectedClick, hexagons, loading }) {
  const {
    state: { selectedCity, selectedCoords, mode },
    selectHex,
    selectHexagon,
  } = useSiteSelectionContext();

  const { t } = useTranslation();

  const { showCef1, showCef2, bounds, zoom } = useSiteSelectionEvcDataContext();

  const [hoverCoords, setHoverCoords] = useState<google.maps.LatLng | null>(
    null
  );

  const [hoveredHex, setHoveredHex] = useState<HexagonProperties | null>(null);
  const map = useGoogleMap();

  const extendedBounds = useMemo(() => {
    if (!bounds) return null;
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const b = new google.maps.LatLngBounds();
    b.extend({
      lat: ne.lat() + Math.abs(ne.lat() - sw.lat()) * 0.5,
      lng: ne.lng() + Math.abs(ne.lng() - sw.lng()) * 0.5,
    });
    b.extend({
      lat: sw.lat() - Math.abs(ne.lat() - sw.lat()) * 0.5,
      lng: sw.lng() - Math.abs(ne.lng() - sw.lng()) * 0.5,
    });
    return b;
  }, [bounds]);

  const filtered = useMemo(() => {
    if (loading) return null;

    if (!hexagons) {
      return null;
    }

    if (!extendedBounds) {
      return hexagons;
    }
    return hexagons.filter((hex) => {
      return extendedBounds.contains({
        lat: hex.lat,
        lng: hex.lon,
      });
    });
  }, [extendedBounds, hexagons, loading]);

  useEffect(() => {
    if (!hexagons || !selectedCity) return;

    if (selectedCoords) {
      const feature = hexagons.find((h) => {
        const poly = new google.maps.Polygon({
          paths: h.polygon,
        });
        return google.maps.geometry.poly.containsLocation(
          new google.maps.LatLng(selectedCoords),
          poly
        );
      });

      if (feature && feature !== selected) {
        setSelected?.(feature);
        selectHex(feature.id);
        selectHexagon(feature);
      }
    }
  }, [
    hexagons,
    selectHex,
    selectHexagon,
    selected,
    selectedCity,
    selectedCoords,
    setSelected,
  ]);

  const stats = useMemo(() => {
    if (hexagons && hexagons?.length > 0) {
      const sum = hexagons.reduce((acc, curr) => {
        return acc + mode === "simple" ? curr.kwhPred : curr.kwhPredOptimized;
      }, 0);
      const avg = sum / hexagons.length;
      const max = hexagons.reduce((acc, curr) => {
        return Math.max(
          acc,
          mode === "simple" ? curr.kwhPred : curr.kwhPredOptimized
        );
      }, 0.1);
      const min = hexagons.reduce((acc, curr) => {
        return Math.min(
          acc,
          mode === "simple" ? curr.kwhPred : curr.kwhPredOptimized
        );
      }, Number.MAX_VALUE);

      return {
        avg,
        sum,
        max,
        min,
      };
    }
    return {
      avg: 0,
      sum: 0,
      max: 0.1,
      min: 0,
    };
  }, [hexagons, mode]);

  return (
    <>
      {filtered && filtered.length > 0 && map && hoverCoords && hoveredHex && (
        <OverlayView
          key={"tooltip"}
          position={hoverCoords}
          mapPaneName={OverlayView.MARKER_LAYER}
          getPixelPositionOffset={() => ({
            x: 10,
            y: -10,
          })}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "5px",
              width: "150px",
            }}
            onMouseEnter={() => {
              setHoverCoords(null);
            }}
          >
            #{hoveredHex.id} <br />
            {t("siteSelection.general.legend.ac")}:{" "}
            {mode === "simple" ? hoveredHex.ac : hoveredHex.kwh_ac} kWh <br />
            {t("siteSelection.general.legend.dc")}:{" "}
            {mode === "simple" ? hoveredHex.dc : hoveredHex.kwh_dc} kWh <br />
          </div>
        </OverlayView>
      )}
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "0",
            backgroundColor: "#fff",
            padding: "5px",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            opacity: 0.5,
          }}
        >
          {t("siteSelection.general.loading")}
        </div>
      )}

      {filtered &&
        zoom &&
        zoom > 13 &&
        filtered.map((hex) => (
          <Polygon
            key={hex.id}
            path={hex.polygon}
            options={{
              strokeColor:
                selected === hex
                  ? "#F00"
                  : hex.cef2 && hex.cef1 && showCef2
                  ? "#7E725F"
                  : hex.cef1 && showCef1
                  ? "#FA9500"
                  : "#000000",
              strokeOpacity:
                (hex.cef1 && showCef1) || (hex.cef2 && hex.cef1 && showCef2)
                  ? 0.8
                  : selected === hex
                  ? 0.6
                  : 0.4,
              strokeWeight:
                (hex.cef1 && showCef1) || (hex.cef2 && hex.cef1 && showCef2)
                  ? 5
                  : selected === hex
                  ? 3
                  : 1,
              fillColor: colorToRgb(
                calculateColor(
                  mode === "simple" ? hex.kwhPred : hex.kwhPredOptimized,
                  stats.min,
                  stats.max
                )
              ),
              fillOpacity:
                selected === hex
                  ? 0.2
                  : map?.getMapTypeId() === "roadmap"
                  ? 0.5
                  : 0.6,
              zIndex: selected === hex ? 100 : hex.cef2 ? 10 : hex.cef1 ? 5 : 4,
            }}
            onClick={(e) => {
              if (selected === hex) {
                onSelectedClick?.(e, hex);
                return;
              }
              map?.fitBounds(
                new google.maps.LatLngBounds(
                  new google.maps.LatLng(hex.polygon[1]),
                  new google.maps.LatLng(hex.polygon[4])
                ),
                {
                  top: 100,
                  bottom: 100,
                  left: 100,
                  right: 100,
                }
              );
              setSelected?.(hex);
            }}
            onMouseMove={(e) => {
              if (selected === hex) {
                hoverCoords && setHoverCoords(null);
                return;
              }
              setHoverCoords(e.latLng);
              setHoveredHex(hex);
            }}
          />
        ))}
    </>
  );
};

export const HexagonLayer: React.FC<HexagonLayerProps> = memo(
  function ({ city, selected, projectId, setSelected, onSelectedClick }) {
    const [hexagons, setHexagons] = useState<HexagonProperties[] | null>(null);
    const [first, setFirst] = useState(true);

    const {
      project,
      updateLocation,
      refetch: refetchProject,
    } = useProject(projectId);

    const {
      loading,
      hexagons: hexagonsData,
      fetchAll,
    } = useGetSiteSelectionHexagons();

    useEffect(() => {
      if (!city) {
        setHexagons(null);
        return;
      }

      if (!hexagonsData) return;

      const data = hexagonsData ?? [];
      setHexagons(data);
      setFirst(true);
    }, [city, hexagonsData]);

    const [refetch, setRefetch] = useState(true);

    useEffect(() => {
      if (!city) {
        setRefetch(true);
        return;
      }
    }, [city]);

    useEffect(() => {
      if (!city) return;

      if (loading) return;
      if (!refetch) return;

      fetchAll(city);

      setRefetch(false);
    }, [city, fetchAll, hexagons, loading, refetch]);

    useEffect(() => {
      if (!first) return;
      if (!project || !hexagons || hexagons.length === 0) return;

      setFirst(false);

      const { locations } = project;
      const toUpdate = locations.filter(
        (location) => location.forecast === null || location.hex === "" || !location.hex
      );

      if (toUpdate.length === 0) return;

      toUpdate.forEach((location) => {
        const feature = hexagons.find((h) => {
          const poly = new google.maps.Polygon({
            paths: h.polygon,
          });
          return google.maps.geometry.poly.containsLocation(
            new google.maps.LatLng(location.latitude, location.longitude),
            poly
          );
        });

        if (feature) {
          updateLocation(
            location.id,
            {
              ...location,
              hex: feature.id,
              forecast: feature.kwhPred,
            },
            false
          ).then(() => {
            // refetchProject();
            setTimeout(() => {
              return refetchProject();
            }, 500);
          });
        }
      });
    }, [first, hexagons, project, refetch, refetchProject, updateLocation]);

    return (
      <HexagonLayerInner
        hexagons={hexagons}
        loading={loading}
        selected={selected}
        setSelected={setSelected}
        onSelectedClick={onSelectedClick}
      />
    );
  },
  (prev, actual) => isEqual(prev, actual)
);
