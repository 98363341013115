import { SVGProps } from "react";

interface IResizableNumberBadgeIcon extends SVGProps<SVGSVGElement> {
  number: number;
  circleColor?: string;
  textColor?: string;
}

export const ResizableNumberBadgeIcon = ({
  number,
  circleColor = "#000",
  textColor = "black",
  ...props
}: IResizableNumberBadgeIcon) => {
  const isNumberOverThreeDigits = number > 99;
  return (
    <svg {...props}>
      <circle
        cx="5"
        cy="5"
        r={isNumberOverThreeDigits ? "5" : "5"}
        fill="white"
        stroke={circleColor}
        strokeWidth="1"
      />
      <text
        x="5"
        y="5"
        dy="2"
        fontFamily="Arial, Helvetica, sans-serif"
        fill={textColor}
        fontWeight={700}
        fontSize={isNumberOverThreeDigits ? "4.5" : "6"}
        textAnchor="middle"
      >
        {number}
      </text>
    </svg>
  );
};
