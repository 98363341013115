import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { Bound, GeoJSONFeature } from "../@types/geojson";
import { HexagonProperties } from "../locations/components/layers/HexagonLayer";
import { CityProperties, ProvinceProperties, RegionProperties, useSiteSelectionGeoDataContext } from "./GeoDataContextProvider";

interface SiteSelectionContextProps {
  state: {
    selectedRegion?: GeoJSONFeature<
      RegionProperties & {
        weight: number;
        bound: Bound;
      }
    > | null;
    selectedProvince?: GeoJSONFeature<
      ProvinceProperties & {
        weight: number;
        bound: Bound;
      }
    > | null;
    selectedCity?: GeoJSONFeature<
      CityProperties & {
        weight: number;
        bound: Bound;
      }
    > | null;
    selectedHex?: string | null;
    selectedCoords?: google.maps.LatLngLiteral | null;
    selectedHexagon?: HexagonProperties | null;
    mode: "simple" | "optimized";
    map?: google.maps.Map | null;
  };
  panTo: (coords: google.maps.LatLngLiteral | null) => void;
  selectRegion: (
    region: GeoJSONFeature<
      RegionProperties & {
        weight: number;
        bound: Bound;
      }
    > | null
  ) => void;
  selectProvince: (
    province: GeoJSONFeature<
      ProvinceProperties & {
        weight: number;
        bound: Bound;
      }
    > | null
  ) => void;
  selectCity: (
    city: GeoJSONFeature<
      CityProperties & {
        weight: number;
        bound: Bound;
      }
    > | null
  ) => void;
  selectHex: (hex: string | null) => void;
  resetPanTo: () => void;
  selectHexagon: (hex: HexagonProperties | null) => void;
  setMode: (mode: "simple" | "optimized") => void;
  setMap: (map: google.maps.Map | null) => void;
}



const SiteSelectionContext = createContext<SiteSelectionContextProps>({
  state: {
    selectedRegion: null,
    selectedProvince: null,
    selectedCity: null,
    selectedHex: null,
    selectedCoords: null,
    selectedHexagon: null,
    mode: "simple",
    map: null,
  },
  panTo: () => {},
  selectRegion: () => {},
  selectProvince: () => {},
  selectCity: () => {},
  selectHex: () => {},
  resetPanTo: () => {},
  selectHexagon: () => {},
  setMode: () => {},
  setMap: () => {},
});

function SiteSelectionContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<SiteSelectionContextProps["state"]>({
    mode: "simple",
  });

  const { getGeoByCoordinates } = useSiteSelectionGeoDataContext();

  const updateState = useCallback((newValue: any) => {
    setState(newValue);
  }, []);



  const setMap = useCallback((map: google.maps.Map | null) => {
    updateState({
      ...state,
      map,
    })
  }, [state, updateState]);


  const selectRegion = useCallback(
    (
      region: GeoJSONFeature<
        RegionProperties & {
          weight: number;
          bound: Bound;
        }
      > | null
    ) => {
      region !== state.selectedRegion &&
        updateState({ ...state, selectedRegion: region });
    },
    [state, updateState]
  );


  const selectProvince = useCallback(
    (
      province: GeoJSONFeature<
        ProvinceProperties & {
          weight: number;
          bound: Bound;
        }
      > | null
    ) => {
      province !== state.selectedProvince &&
        updateState({ ...state, selectedProvince: province });
    },
    [state, updateState]
  );

  const selectCity = useCallback(
    (
      city: GeoJSONFeature<
        CityProperties & {
          weight: number;
          bound: Bound;
        }
      > | null
    ) => {
      if (city !== state.selectedCity) {
        updateState({ ...state, selectedCity: city });
      }
    },
    [state, updateState]
  );

  const panTo = useCallback(
    (coords: google.maps.LatLngLiteral | null) => {
      if (!coords) return;

      const {
        region,
        province,
        city,
      } = getGeoByCoordinates(coords) ?? {};

      updateState({
        ...state,
        selectedRegion: region,
        selectedProvince: province,
        selectedCity: null,
      });

      state.map?.setZoom(16);
      state.map?.panTo(coords);

      setTimeout(() => {
        updateState({
          ...state,
          selectedRegion: region,
          selectedProvince: province,
          selectedCity: city,
        });

      }, 100);

    },
    [updateState, state, getGeoByCoordinates]
  );



  const selectHex = useCallback(
    (hex: string | null) => {
      if (hex !== state.selectedHex) {
        updateState({ ...state, selectedHex: hex });
      }
    },
    [state, updateState]
  );

  const selectHexagon = useCallback(
    (hex: HexagonProperties | null) => {
      if (hex !== state.selectedHexagon) {
        updateState({ ...state, selectedHexagon: hex });
      }
    },
    [state, updateState]
  );

  const setMode = useCallback(
    (mode: "simple" | "optimized") => {
      if (mode !== state.mode) {
        updateState({ ...state, mode: mode });
      }
    },
    [state, updateState]
  );

  const resetPanTo = () => {
    if (
      state.selectedCoords &&
      state.selectedHex &&
      state.selectedCity &&
      state.selectedProvince &&
      state.selectedRegion
    ) {
      updateState({ ...state, selectedCoords: null });
    }
  };

  useEffect(() => {
    if (
      state.selectedCoords &&
      state.selectedHex &&
      state.selectedCity &&
      state.selectedProvince &&
      state.selectedRegion
    ) {
      updateState({ ...state, selectedCoords: null });
    }
  }, [state, updateState]);

  //   useEffect(() => {
  //     console.log(state);
  //   }, [state]);

  return (
    <SiteSelectionContext.Provider
      value={{
        state,
        panTo,
        selectRegion,
        selectCity,
        selectHex,
        selectProvince,
        resetPanTo,
        selectHexagon,
        setMode,
        setMap,
      }}
    >
      {children}
    </SiteSelectionContext.Provider>
  );
}

const useSiteSelectionContext = () => {
  const context = useContext(SiteSelectionContext);
  if (context === undefined) {
    throw new Error(
      "useSiteSelectionContext must be used within a SiteSelectionContextProvider"
    );
  }
  return context;
};

export {
  SiteSelectionContext,
  SiteSelectionContextProvider,
  useSiteSelectionContext,
};
