import { gql, useMutation } from "@apollo/client";

export const PUBLISH_DIAGNOSTIC_UPLOAD = gql`
  mutation publishDiagnosticUpload($input: DiagnosticUploadInput!) {
    publishDiagnosticUpload(input: $input) {
      requestUid
    }
  }
`;

export const usePublishDiagnosticUpload = () => {
  const [publishDiagnosticUpload, { data, loading, error }] = useMutation<any>(
    PUBLISH_DIAGNOSTIC_UPLOAD
  );
  return {
    publishDiagnosticUpload,
    error,
    remoteStartChargeTxData: data?.publishDiagnosticUpload,
    loading,
  };
};
