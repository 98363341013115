/** @format */

import { Navigate, Outlet, Route, Routes } from "react-router-dom";

const AuthLayout = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default function PublicRoutes() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/*" element={<Navigate to="/login" />} />
      </Route>
    </Routes>
  );
}
