import { gql, useLazyQuery } from "@apollo/client";

export const CONFIGURATION_FETCH_PROCESSED = gql(`
query ConfigurationFetchProcessed($filter: RemoteCommandFilterParams, $pagination: PaginationParams, $sort: SortingParams) {
    ConfigurationFetchProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        unknownKeys
        status
        info
        requestUid
        request {
          requestUid
          evcUid
          keys
        }
      }
    }
  }
  `);

export const useConfigurationFetchProcessed = () => {
  const [
    getConfigurationFetchProcessed,
    {
      loading,
      error,
      data,
      fetchMore: fetchMoreItems,
      refetch,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(CONFIGURATION_FETCH_PROCESSED, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getConfigurationFetchProcessed,
    error,
    data: data?.ConfigurationFetchProcessed?.results,
    count: data?.PlatformPartiesInteroperabilities?.total,
    refetch,
    startPolling,
    updateQuery,
  };
};
