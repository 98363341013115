import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  InputElement,
  Typography,
} from "../../../../../shared/UI";
import { Close } from "../../../../../shared/assets/svg";
import AC from "../../../../../shared/assets/svg/AC";
import Delete from "../../../../../shared/assets/svg/Delete";
import Tick from "../../../../../shared/assets/svg/Tick";
import { Location } from "../../../@types/project";

export const Circle = ({ checked }: { checked?: boolean }) => {
  const checkmarkStyle = {
    width: "20px",
    height: "20px",
    border: "1px solid #3D729C",
    borderRadius: "50%",
    display: "inline-block",
    position: "relative",
  } as const;

  const checkmarkInnerStyle = {
    width: "8px",
    height: "13px",
    border: "solid #122967",
    borderWidth: "0 2px 2px 0",
    transform: checked ? "rotate(45deg)" : "none",
    position: "absolute",
    top: "2px",
    left: "5px",
  } as const;

  return (
    <Flex flexDirection="row" alignItems="center">
      <div style={checkmarkStyle}>
        {checked && <span style={checkmarkInnerStyle} />}
      </div>
    </Flex>
  );
};

type CommonParams = {
  evcForecast?: {
    ac: number;
    dc: number;
  };
  onClose?: () => void;
};

type NewLocationTooltipParams = CommonParams & {
  coordinates: {
    lat: number;
    lng: number;
  };
  onConfirm?: (event: {
    address: string | null;
    acNumber: number;
    dcNumber: number;
    contractualCapacity?: number | null;
    zipCode?: string | null;
  }) => void;
};

type EditLocationTooltipParams = CommonParams & {
  location: Location;
  onEdit?: (event: {
    location: Location;
    data: Partial<Omit<Location, "id">>;
  }) => void;
  onDelete?: () => void;
};

type NewOrEditLocationTooltipParams =
  | (NewLocationTooltipParams & {
      editMode: false;
      title: string;
    })
  | (EditLocationTooltipParams & {
      editMode: true;
      title: string;
    });

export const InfoOverlay = ({
  width,
  title,
  onClose,
  children,
}: PropsWithChildren<{
  width: number;
  title: string;
  onClose?: () => void;
}>) => {
  return (
    <Flex
      border={"1px solid #122967"}
      borderRadius={"0px 10px 10px 10px"}
      justifyContent="flex-start"
      width={width + "px"}
      height={"auto"}
      background="#FFFFFF"
      style={{
        cursor: "default",
      }}
      onClick={(e) => e.stopPropagation()}
      onMouseMove={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex
        alignItems="center"
        borderRadius="0px 10px 10px 0px"
        borderBottom="1px solid #122967"
        background="#D4E0EF"
        padding="10px "
        position={"relative"}
      >
        <Typography
          color="#122967"
          fontFamily="Rubik"
          fontSize="12.622px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="normal"
        >
          {title}
        </Typography>
        <Flex position={"absolute"} right={"5px"} top="7px">
          <Button
            shape="square"
            size={"small"}
            onClick={onClose}
            padding={"0px"}
            width={"20px"}
            height={"20px"}
            variant="white"
          >
            <Close size="10px" />
          </Button>
        </Flex>
      </Flex>
      <Flex>{children}</Flex>
    </Flex>
  );
};

export const NewOrEditLocationTooltip = (
  props: NewOrEditLocationTooltipParams
) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<string | null>(
    props.editMode ? props.location.address || null : null
  );
  const [zipCode, setZipCode] = useState<string | null>(
    props.editMode ? props.location.zipCode || null : null
  );
  const [acNumber, setAcNumber] = useState(
    props.editMode ? props.location.ac : 0
  );
  const [dcNumber, setDcNumber] = useState(
    props.editMode ? props.location.dc : 0
  );
  const [contractualCapacity, setContractualCapacity] = useState<number | null>(
    props.editMode ? props.location.contractualCapacity ?? null : null
  );

  const width = 220;

  const totEvcForecast =
    (props.evcForecast?.ac ?? 0) + (props.evcForecast?.dc ?? 0);

  useEffect(() => {
    if (address !== null) return;
    // Create a geocoder instance
    const geocoder = new window.google.maps.Geocoder();

    const coord = props.editMode
      ? {
          lat: props.location.latitude,
          lng: props.location.longitude,
        }
      : props.coordinates;

    // Geocode the address
    geocoder.geocode({ location: coord }, (results, status) => {
      if (status === "OK" && results) {
        setAddress(results[0].formatted_address);
        const zip = results[0].address_components.find((c) =>
          c.types.includes("postal_code")
        );
        setZipCode(zip?.long_name ?? null);
      } else {
        console.error(`Geocoder failed due to: ${status} - ${results}`);
      }
    });
  }, [address, props]);

  const countDecimals = (value: number) => {
    return value.toString().split(".")[0]?.length || 1;
  };

  const totDecimals =
    countDecimals((props.evcForecast?.ac ?? 0) + (props.evcForecast?.dc ?? 0)) +
    countDecimals(acNumber + dcNumber);
  const fontSize = useMemo(() => {
    let fontSize = "34px";
    if (totDecimals > 2) fontSize = "28px";
    if (totDecimals > 3) fontSize = "24px";
    if (totDecimals > 4) fontSize = "14px";
    return fontSize;
  }, [totDecimals]);

  return (
    <Flex
      border={"1px solid #122967"}
      borderRadius={"10px 10px 0px 10px"}
      justifyContent="flex-start"
      width={width + "px"}
      height={"auto"}
      background="#FFFFFF"
      style={{
        cursor: "default",
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Flex
        alignItems="center"
        borderRadius="10px 10px 0px 0px"
        borderBottom="1px solid #122967"
        background="#D4E0EF"
        padding="10px "
      >
        <Typography
          color="#122967"
          fontFamily="Rubik"
          fontSize="12.622px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="normal"
        >
          {props.title}
        </Typography>
      </Flex>
      <Flex alignItems="start" background="#FFFFFF" padding="10px" width="100%">
        <Box>
          <Typography color="#122967" fontSize="12px" fontWeight="700">
            {t("siteSelection.general.map.newOrEditLocation.where")}
          </Typography>
        </Box>
        <Box>
          <Typography color="#122967" fontSize="12px" fontWeight="400">
            {address ?? "-"}
          </Typography>
        </Box>
        <Box>
          <Typography color="#122967" fontSize="12px" fontWeight="700">
            {t(
              "siteSelection.general.map.newOrEditLocation.contractualCapacity"
            )}
          </Typography>
        </Box>
        <Box>
          <InputElement
            shape="rounded"
            type="number"
            variant="white"
            $fill={true}
            min={0}
            style={{
              border: "1px solid #122967",
              margin: "0px",
              padding: "0px 10px",
              marginRight: "2px",
              fontSize: "10px",
              height: "20px",
              appearance: "textfield",
              WebkitAppearance: "textfield",
              MozAppearance: "textfield",
            }}
            value={`${contractualCapacity || ""}`}
            onChange={(e) => {
              const n = parseFloat(e.target.value);
              setContractualCapacity(n);
            }}
          />
        </Box>
        <Flex flexDirection="row" marginTop={"14px"} width="100%">
          <Box>
            <Typography color="#122967" fontSize="12px" fontWeight="700">
              {t("siteSelection.general.map.newOrEditLocation.newEvc")}
            </Typography>
            <Box marginTop={"20px"}>
              <Flex flexDirection="row" width="100%" alignItems={"center"}>
                <Box>
                  <Circle checked={acNumber > 0} />
                </Box>
                <AC />
                <InputElement
                  shape="square"
                  withBorder={false}
                  width={"27px"}
                  style={{
                    borderBottom: "1px solid #122967",
                    margin: "0px",
                    padding: "0px",
                    marginRight: "2px",
                  }}
                  value={`${acNumber || ""}`}
                  onChange={(e) => {
                    const n = parseInt(e.target.value);
                    if (n >= 0 && n < 1000) setAcNumber(n);
                    if (e.target.value === "") setAcNumber(0);
                  }}
                />
                <Typography color="#122967" fontSize="12px" fontWeight="400">
                  {t("siteSelection.general.legend.ac")}
                </Typography>
              </Flex>
              <Flex
                marginTop={"7px"}
                flexDirection="row"
                width="100%"
                alignItems={"center"}
              >
                <Box>
                  <Circle checked={dcNumber > 0} />
                </Box>
                <AC />
                <InputElement
                  shape="square"
                  withBorder={false}
                  width={"27px"}
                  style={{
                    borderBottom: "1px solid #122967",
                    margin: "0px",
                    padding: "0px",
                    marginRight: "2px",
                  }}
                  value={`${dcNumber || ""}`}
                  onChange={(e) => {
                    const n = parseInt(e.target.value);
                    if (n >= 0 && n < 1000) setDcNumber(n);
                    if (e.target.value === "") setDcNumber(0);
                  }}
                />
                <Typography color="#122967" fontSize="12px" fontWeight="400">
                  {t("siteSelection.general.legend.dc")}
                </Typography>
              </Flex>
            </Box>
          </Box>
          <Box
            // margin={"0px 5px"}
            width="2px"
            height="100px"
            background="#122967"
            margin="auto"
          />
          <Box>
            <Typography color="#122967" fontSize="12px" fontWeight="700">
              {t("siteSelection.general.map.newOrEditLocation.numEvcForecast")}
            </Typography>
            <Flex
              borderRadius={"10px"}
              background="#F5F5F5"
              border={"1px solid #D4E0EF"}
              width={"66px"}
              height={"66px"}
              margin={"auto"}
              marginTop={"6px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography color="#122967" fontSize={fontSize}>
                {acNumber + dcNumber}/{totEvcForecast}
              </Typography>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Flex
        flexDirection={"row"}
        width={width - 12 + "px"}
        padding={"10px"}
        border={"1px solid #D4E0EF"}
        background={"#F5F5F5"}
        margin="0px 6px 10px 6px"
        borderRadius={"10px"}
      >
        <Flex minWidth={0} flexGrow={1} />
        <Button
          height={"26px"}
          variant="success"
          marginLeft={"10px"}
          backgroundColor={"#78931A"}
          disabled={acNumber + dcNumber === 0}
          padding={1}
          onClick={() => {
            if (props.editMode === false) {
              props.onConfirm &&
                props.onConfirm({
                  address,
                  acNumber,
                  dcNumber,
                  contractualCapacity,
                  zipCode,
                });
              setAcNumber(0);
              setDcNumber(0);
            } else {
              props.onEdit &&
                props.onEdit({
                  location: props.location,
                  data: {
                    ac: acNumber,
                    dc: dcNumber,
                    contractualCapacity,
                    address,
                    zipCode: zipCode ?? undefined,
                  },
                });
            }
          }}
          style={{ textTransform: "capitalize" }}
        >
          <Tick />{" "}
          <span
            style={{
              paddingLeft: "2px",
            }}
          >
            {t("confirm")}
          </span>
        </Button>
        {props.editMode && (
          <Button
            height={"26px"}
            variant="primary"
            marginLeft={"10px"}
            disabled={acNumber + dcNumber === 0}
            padding={1}
            onClick={() => {
              if (props.editMode === true) {
                props.onDelete && props.onDelete();
              }
            }}
            style={{ textTransform: "capitalize" }}
          >
            <Delete />{" "}
            <span
              style={{
                paddingLeft: "2px",
              }}
            >
              {t("delete")}
            </span>
          </Button>
        )}
        <Button
          width={"26px"}
          height={"26px"}
          variant="danger"
          bg="#E84120"
          padding={0}
          marginLeft={"10px"}
          onClick={() => {
            props.onClose && props.onClose();
          }}
        >
          <Close size="20px" />
        </Button>
      </Flex>
    </Flex>
  );
};

export const NewLocationTooltip = (props: NewLocationTooltipParams) => {
  const { t } = useTranslation();

  return (
    <NewOrEditLocationTooltip
      editMode={false}
      {...props}
      title={t("siteSelection.general.map.newOrEditLocation.newLocationTitle")}
    />
  );
};

export const EditLocationTooltip = (props: EditLocationTooltipParams) => {
  const { t } = useTranslation();

  return (
    <NewOrEditLocationTooltip
      editMode={true}
      {...props}
      title={t("siteSelection.general.map.newOrEditLocation.editLocationTitle")}
    />
  );
};
