import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};

const Type3a: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" 
    fill={fill}
    iconSize={size}
    viewBox="0 0 429 512">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M 429 383.753906 C 429 391.902344 426.691406 399.925781 421.890625 406.511719 C 375.28125 470.445312 299.75 512 214.5 512 C 129.25 512 53.71875 470.445312 7.109375 406.511719 C 2.308594 399.925781 0 391.902344 0 383.753906 L 0 128.246094 C 0 120.097656 2.308594 112.074219 7.109375 105.488281 C 53.71875 41.554688 129.25 0 214.5 0 C 299.75 0 375.28125 41.554688 421.890625 105.488281 C 426.691406 112.074219 429 120.097656 429 128.246094 Z M 303.4375 397.0625 C 314.996094 397.0625 324.367188 387.707031 324.367188 376.164062 C 324.367188 364.621094 314.996094 355.265625 303.4375 355.265625 C 291.878906 355.265625 282.515625 364.621094 282.515625 376.164062 C 282.515625 387.707031 291.878906 397.0625 303.4375 397.0625 Z M 261.585938 114.9375 C 261.585938 138.023438 242.847656 156.734375 219.730469 156.734375 C 196.617188 156.734375 177.878906 138.023438 177.878906 114.9375 C 177.878906 91.855469 196.617188 73.144531 219.730469 73.144531 C 242.847656 73.144531 261.585938 91.855469 261.585938 114.9375 Z M 219.730469 449.308594 C 242.847656 449.308594 261.585938 430.59375 261.585938 407.511719 C 261.585938 384.425781 242.847656 365.714844 219.730469 365.714844 C 196.617188 365.714844 177.878906 384.425781 177.878906 407.511719 C 177.878906 430.59375 196.617188 449.308594 219.730469 449.308594 Z M 408.074219 261.226562 C 408.074219 301.621094 375.28125 334.367188 334.828125 334.367188 C 294.378906 334.367188 261.585938 301.621094 261.585938 261.226562 C 261.585938 220.828125 294.378906 188.082031 334.828125 188.082031 C 375.28125 188.082031 408.074219 220.828125 408.074219 261.226562 Z M 104.632812 334.367188 C 145.085938 334.367188 177.878906 301.621094 177.878906 261.226562 C 177.878906 220.828125 145.085938 188.082031 104.632812 188.082031 C 64.179688 188.082031 31.390625 220.828125 31.390625 261.226562 C 31.390625 301.621094 64.179688 334.367188 104.632812 334.367188 Z M 104.632812 334.367188 "/>
  </Svg>
  );
};

export default Type3a;
