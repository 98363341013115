import { useParams } from "react-router-dom";
import { Flex } from "../../../../../shared/UI";
import { SessionsTable } from "../../../../../shared/gql/queries/tables/sessions/SessionsTable";

export const EvcSessions = () => {
  const { uid } = useParams();

  return (
    <Flex fullSize p={3}>
      <SessionsTable
        filters={{
          evcUid: uid,
        }}
      />
    </Flex>
  );
};
