import { useCallback, useState } from "react";
import {
  Button,
  Center,
  Checkbox,
  ColumnShape,
  Flex,
  Popover,
  SimpleLoader,
  Typography,
} from "../../UI";
import { useSearchParams } from "react-router-dom";
import {
  convertSearchParamIntoArray,
  convertStringsArrayIntoQuery,
} from "../../helpers";
import {
  PopoverBody,
  PopoverHeader,
} from "../../UI/popover/PopoverStyledElement";
import { t } from "i18next";
import { Filter } from "../../assets/svg";

type Props = {
  column: ColumnShape<any>;
  options: string[];
  loading: boolean;
  searchValue: string;
};

export const SingleCheckboxFilter: React.FC<Props> = ({
  column,
  loading,
  options,
  searchValue,
}) => {
  const [status, setStatus] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleReset = useCallback(() => {
    searchParams.delete(searchValue);
    setSearchParams(searchParams);
  }, [searchParams, searchValue, setSearchParams]);

  const onChangeList = (value: string) => (e: any) => {
    if (searchParams.get(searchValue) === value) {
      searchParams.delete(searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.set(searchValue, `${value}`);
      setSearchParams(searchParams);
    }
  };

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      onClick={(e) => e.stopPropagation()}
    >
      <Typography variant={"body10"} fontWeight="bold" capitalizeFirstLetter>
        {t(column.title as string)}
      </Typography>
      <Popover position="bottom" status={status} setStatus={setStatus}>
        <Button
          variant={searchParams.get(searchValue) ? "success" : "primary"}
          fixedSize
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Filter fill="white" />
        </Button>
        <PopoverBody
          display={"flex"}
          flexDirection="column"
          width={250}
          maxWidth={250}
          maxHeight={300}
          minHeight={300}
          flexShrink={0}
        >
          <PopoverHeader flexShrink={0} minWidth={200}>
            <Typography fontWeight="bold">{t("Status filter")}</Typography>
            <Button
              variant="danger"
              fontWeight={"bold"}
              withBorder={false}
              link
              $size="small"
              disabled={!searchParams.get(searchValue)}
              onClick={handleReset}
            >
              {t("reset")}
            </Button>
          </PopoverHeader>
          <ul
            style={{
              listStyle: "none",
              paddingTop: 5,
              maxHeight: 500,
              overflow: "scroll",
              height: "100%",
            }}
          >
            {options?.length ? (
              options?.map((option: string, index: number) => {
                return (
                  <li key={index}>
                    <Flex py={2}>
                      <Checkbox
                        value={undefined}
                        onChange={onChangeList(option)}
                        checked={searchParams.get(searchValue) === `${option}`}
                      >
                        {option}
                      </Checkbox>
                    </Flex>
                  </li>
                );
              })
            ) : loading ? (
              <Center>
                <SimpleLoader />
              </Center>
            ) : (
              <Flex
                style={{ placeItems: "center" }}
                justifyContent={"center"}
                alignItems={"center"}
                fullSize
              >
                <Typography variant={"caption20"}>No Data Found</Typography>
              </Flex>
            )}
          </ul>
        </PopoverBody>
      </Popover>
    </Flex>
  );
};
