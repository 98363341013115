import { Breadcrumbs, Crumb, Flex, StyledLink } from "../../../../shared/UI";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import { SectionContent } from "../../../../shared/layout";
import { GroupInfo } from "../components/GroupInfo";

export const AccessGroupModel = () => {
  return (
    <SectionContent>
      <Flex fullSize>
        <Breadcrumbs>
          <Crumb>
            <StyledLink to="/access-groups">Access Groups</StyledLink>
          </Crumb>
          <Crumb>
            <StyledLink to="#">Create</StyledLink>
          </Crumb>
        </Breadcrumbs>
        <VerticalScrollContainer p={3} bg="#eee">
          <GroupInfo />
        </VerticalScrollContainer>
      </Flex>
    </SectionContent>
  );
};
