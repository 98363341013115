/** @format */

import { useOutletContext } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  LoaderWithFallback,
  SimpleLoader,
  Typography,
} from "../../../../../shared/UI";
import { LocationMap } from "./map/LocationMap";
import { Refresh } from "../../../../../shared/assets/svg";
import { t } from "i18next";
import { format } from "date-fns";
import { Location } from "../../../../../shared/gql/generated/graphql";
import { getWeekdayName } from "../../../../../shared/helpers/weekHelper";

const LocationDetails = () => {
  const {
    location,
    loading,
    refetch,
  }: { location: Location; loading: boolean; refetch: any } =
    useOutletContext();

  const refetchLocation = () => {
    refetch();
  };

  return (
    <LoaderWithFallback
      errorMsg="Error"
      hasError={!location}
      LoadingMsg={<SimpleLoader />}
      loading={loading}
    >
      <Flex alignItems={"flex-end"} width={"100%"} p={2}>
        <Button fixedSize onClick={refetchLocation}>
          {" "}
          <Refresh />
        </Button>
      </Flex>
      <Flex
        style={{ gap: "15px 15px" }}
        p={3}
        flexDirection={"row"}
        flexShrink={0}
        flexWrap={"wrap"}
      >
        <Card
          bg="white"
          p={2}
          justifyContent={"center"}
          flex={1}
          minWidth={300}
          flexWrap={"wrap"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("Project")}
          </Typography>

          <Flex flex={1} minWidth={150} mb={2} pl={2}>
            <Flex flexWrap="wrap">
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ gap: 10 }}
              >
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("project id")}:
                  </Typography>
                  <Typography>{location?.Project?.uid}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography
                    display="block"
                    capitalizeFirstLetter
                    fontWeight="bold"
                  >
                    {t("name")} :
                  </Typography>
                  <Typography>{location?.Project?.name}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography
                    display="block"
                    capitalizeFirstLetter
                    fontWeight="bold"
                  >
                    {t("businessModel")}:
                  </Typography>
                  <Typography>{location?.businessModel}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography
                    display="block"
                    capitalizeFirstLetter
                    fontWeight="bold"
                  >
                    {t("host group")}:
                  </Typography>
                  <Typography>{location?.hostGroup}</Typography>
                </Flex>

                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("status")}:
                  </Typography>
                  <Typography>
                    {location?.status} / {location?.Project?.phase}
                  </Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("type")}:
                  </Typography>
                  <Typography>{location?.type}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("cod")}:
                  </Typography>
                  <Typography>
                    {location?.Project?.cod
                      ? format(location?.Project?.cod, "dd/MM/yyyy")
                      : "---"}
                  </Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("works completion date")}:
                  </Typography>
                  <Typography>
                    {location?.Project?.worksCompletionDate
                      ? format(
                          location?.Project?.worksCompletionDate,
                          "dd/MM/yyyy"
                        )
                      : "---"}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card
          bg="white"
          p={2}
          justifyContent={"center"}
          flex={1}
          minWidth={300}
          display={"flex"}
          flexDirection={"column"}
          flexWrap={"wrap"}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("roaming")}
          </Typography>

          <Flex flex={1} minWidth={150} mb={2} pl={2}>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ gap: 10 }}
            >
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography
                  display="block"
                  capitalizeFirstLetter
                  fontWeight="bold"
                >
                  {t("operator")}:
                </Typography>
                <Typography>{location?.operator}</Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  {t("sub-operator")}:
                </Typography>
                <Typography>{location?.suboperator}</Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  {t("platform party")}:
                </Typography>
                <Typography>{location?.PlatformParty?.name}</Typography>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card
          bg="white"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          p={2}
          flex={1}
          minWidth={300}
          flexWrap={"wrap"}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("pod")}
          </Typography>

          <Flex flex={1} minWidth={150} mb={2}>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ gap: 10 }}
              pl={2}
            >
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography
                  display="block"
                  capitalizeFirstLetter
                  fontWeight="bold"
                >
                  {t("pod code")}:
                </Typography>
                <Typography>{location?.Project?.cod}</Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  {t("pod capacity")}:
                </Typography>
                <Typography>{location?.Project?.POD?.capacity}</Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  {t("Owner")}:
                </Typography>
                <Typography>{location?.Project?.POD?.owner}</Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  DSO {t("name")}:
                </Typography>
                <Typography>{location?.Project?.POD?.DSO?.name}</Typography>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card
          bg="white"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          p={2}
          flex={1}
          minWidth={300}
          flexWrap={"wrap"}
          mb={1}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("opening hours")}
          </Typography>

          <Flex flex={1} minWidth={150} mb={2}>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ gap: 10 }}
              pl={2}
            >
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography
                  display="block"
                  capitalizeFirstLetter
                  fontWeight="bold"
                >
                  24/7:
                </Typography>
                <Typography>
                  {location?.openingTimes.twentyFourSeven ? "Yes" : "No"}
                </Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  {t("regular hours")}:
                </Typography>
                <ul style={{ listStyle: "none" }}>
                  {location?.openingTimes.regularHours?.map((item) => {
                    const weekday = getWeekdayName(item.weekday);
                    return (
                      <li>
                        <Typography> {t(weekday)}: </Typography>
                        <Typography>
                          from {item.periodBegin} to {item.periodEnd}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </Flex>
              {location?.openingTimes?.exceptionalOpenings?.length && (
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("exceptional openings")}:
                  </Typography>
                  <ul style={{ listStyle: "none" }}>
                    {location?.openingTimes.exceptionalOpenings?.map((item) => {
                      return (
                        <li>
                          <Typography>
                            from {item.periodBegin} to {item.periodEnd}
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <Card
        bg="white"
        p={2}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        flex={2}
        minWidth={500}
      >
        <Flex mb={3} mt={6}>
          <Flex
            fullSize
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            style={{ gap: 10 }}
          >
            <Flex flex={1} minWidth={150} mb={2}>
              <Typography
                display="block"
                capitalizeFirstLetter
                fontWeight="bold"
              >
                {t("address")}:
              </Typography>
              <Typography>{location?.address}</Typography>
            </Flex>
            <Flex flex={1} minWidth={150} mb={2}>
              <Typography
                display="block"
                capitalizeFirstLetter
                fontWeight="bold"
              >
                {t("city")}:
              </Typography>
              <Typography>{location?.city}</Typography>
            </Flex>
            <Flex flex={1} minWidth={150} mb={2}>
              <Typography
                display="block"
                capitalizeFirstLetter
                fontWeight="bold"
              >
                {t("province")}:
              </Typography>
              <Typography>{location?.province}</Typography>
            </Flex>
            <Flex flex={1} minWidth={150} mb={2}>
              <Typography
                display="block"
                capitalizeFirstLetter
                fontWeight="bold"
              >
                {t("country")}:
              </Typography>
              <Typography>{location?.country}</Typography>
            </Flex>
            <Flex flex={1} minWidth={150} mb={2}>
              <Typography
                display="block"
                capitalizeFirstLetter
                fontWeight="bold"
              >
                {t("postal code")}:
              </Typography>
              <Typography>{location?.postalCode}</Typography>
            </Flex>
            <Flex flex={1} minWidth={150} mb={2}>
              <Typography
                display="block"
                capitalizeFirstLetter
                fontWeight="bold"
              >
                {t("latitude")}:
              </Typography>
              <Typography>{location?.geolocation?.latitude}</Typography>
            </Flex>
            <Flex flex={1} minWidth={150} mb={2}>
              <Typography
                display="block"
                capitalizeFirstLetter
                fontWeight="bold"
              >
                {t("longitude")}:
              </Typography>
              <Typography>{location?.geolocation?.longitude}</Typography>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight={300} flexShrink={0} flex={1} position="relative">
          <LocationMap location={location} />
        </Flex>
      </Card>
    </LoaderWithFallback>
  );
};

export default LocationDetails;
