import { memo, useMemo } from "react";
import { Box } from "../../../../../shared/UI";
import { Bound } from "../../../@types/geojson";
import {
  CityProperties,
  useSiteSelectionGeoDataContext,
} from "../../../slices/GeoDataContextProvider";
import { mapKwh } from "../../../utils/map-kwh";
import { GeoJSONLayer, GeoJSONLayerProps } from "./GeoJSONLayer";
import { useTranslation } from "react-i18next";

type CityLayerProps = {
  provinceId: number | null;
} & Omit<
  GeoJSONLayerProps<
    CityProperties & {
      weight: number;
      bound: Bound;
    }
  >,
  "data"
>;

const Tooltip = memo(
  ({ data }: { data: CityProperties }) => {
    const { t } = useTranslation();
    const val = mapKwh(data.stats.kwh_ac + data.stats.kwh_dc);

    return (
      <Box width={"400px"}>
        {t("siteSelection.cityLayer.city")} {data.COMUNE}
        <br />
        <b>{t("siteSelection.cityLayer.statistic")}</b>
        <br />
        {data.stats && `${val.value.toFixed(2)} ${val.unit}`}
        <br />
        {data.stats && `${data.stats.n_ac} AC ${data.stats.n_dc} DC`}
      </Box>
    );
  },
  (prev, actual) => prev.data.COMUNE === actual.data.COMUNE
);

export const CityLayer: React.FC<CityLayerProps> = ({
  provinceId,
  ...props
}) => {
  const { cities = [] } = useSiteSelectionGeoDataContext();

  const filteredFeatures = useMemo(
    () => (cities ?? []).filter((p) => p.properties.COD_PROV === provinceId),
    [cities, provinceId]
  );

  return (
    <>
      {cities && (
        <GeoJSONLayer
          data={{ type: "FeatureCollection", features: filteredFeatures }}
          tooltip={(data) => <Tooltip data={data} />}
          {...props}
        />
      )}
    </>
  );
};
