/** @format */

import {
  combineReducers,
  configureStore,
  Reducer,
  Store,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import csoReducer from "../../../pages/cso/slices/cso";
import locationsReducer from "../../../pages/cso/slices/locations";
import evcReducer from "../../../pages/infrastracture/EVCs/slices/evc";
import infrastructureStore from "../../../pages/infrastracture/slices/infrastructure";
import platformReducer from "../../../pages/platforms/slices/platform";
import PricesReducer from "../../../pages/rates/slices/price";
import tariffsReducer from "../../../pages/rates/slices/tariff";
import i18nextReducer from "../../slices/i18next";
import layoutReducer from "../../slices/layoutItems";
import simulatorReducer from "../../../pages/infrastracture/EVCs/components/simulator/siimulator.slice";

import userSettingsReducer from "../../slices/userSettings";
import { api } from "../RTK/baseApi";

export function resetState() {
  return {
    type: "REST_STATE_ACTION_TYPE",
  };
}
const persistConfig = {
  key: "root",
  storage,
};
const reducers = {
  [api.reducerPath]: api.reducer,
  cso: csoReducer,
  platform: platformReducer,
  tariffs: tariffsReducer,
  locations: locationsReducer,
  prices: PricesReducer,
  layout: layoutReducer,
  i18next: i18nextReducer,
  userSettings: userSettingsReducer,
  evc: evcReducer,
  infrastructure: infrastructureStore,
  simulator: simulatorReducer,
};
const combinedReducer = combineReducers<typeof reducers>(reducers);
export type RootState = ReturnType<typeof store.getState>;

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === "REST_STATE_ACTION_TYPE") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};
const persistedReducer = persistReducer<any>(persistConfig, rootReducer);

const store: Store<any> = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),

  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
