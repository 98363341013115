import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconHealthAndCare: React.FC<Props> = ({
  fill,
  size,
  ...props
}) => {
  return (
    <Svg
      width="28"
      height="36"
      viewBox="0 0 28 36"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M11.4046 24.1713H16.5717V19.8653H20.8777V14.6981H16.5717V10.3921H11.4046V14.6981H7.09857V19.8653H11.4046V24.1713ZM13.9881 35.3668C9.99793 34.3621 6.70385 32.0728 4.1059 28.4988C1.50796 24.9248 0.208984 20.9561 0.208984 16.5927V6.08613L13.9881 0.918945L27.7673 6.08613V16.5927C27.7673 20.9561 26.4683 24.9248 23.8704 28.4988C21.2724 32.0728 17.9784 34.3621 13.9881 35.3668ZM13.9881 31.7498C16.9736 30.8025 19.4424 28.9079 21.3944 26.0659C23.3465 23.224 24.3225 20.0662 24.3225 16.5927V8.45442L13.9881 4.57904L3.65377 8.45442V16.5927C3.65377 20.0662 4.6298 23.224 6.58185 26.0659C8.53389 28.9079 11.0027 30.8025 13.9881 31.7498Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
