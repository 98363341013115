/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Platform } from "../../../shared/gql/generated/graphql";

type platformState = {
  selectedPlatform: Platform | null;
};
const initialState: platformState = {
  selectedPlatform: null,
};

const platformSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    selectPlatform: (state, action: PayloadAction<Platform | null>) => {
      state.selectedPlatform = action.payload;
    },
  },
});

export const { selectPlatform } = platformSlice.actions;
export default platformSlice.reducer;
