/** @format */

import { getTime } from "date-fns";
import React, { useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  DropdownContent,
  DropdownHeader,
  Flex,
} from "../../../../../shared/UI";
import QueryResult from "../../../../../shared/components/QueryResult";
import { useGetMeterValues } from "../../../../../shared/gql/queries/useGetMeterValues";
import { LineChart } from "./LineChart";
import { Session } from "../../../../../shared/gql/generated/graphql";

type Measurand =
  | "Current.Offered"
  | "Energy.Active.Import.Register"
  | "Power.Active.Export"
  | "Power.Active.Import"
  | "Power.Offered"
  | "SoC"
  | "Voltage";
const measurandDefaultOptions: Measurand[] = [
  "Current.Offered",
  "Energy.Active.Import.Register",
  "Power.Active.Export",
  "Power.Active.Import",
  "Power.Offered",
  "SoC",
  "Voltage",
];

type Props = {
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MeterValues: React.FC<Props> = ({ setShowTable }) => {
  const chart = useRef<HTMLDivElement | null>(null);
  const { session }: { session: Session } = useOutletContext();
  const [selectedMeasurand, setSelectedMesasurand] = useState<Measurand[]>(
    measurandDefaultOptions
  );
  const [chartType, setChartType] = useState<"line" | "column">("line");

  const { data, loading, error } = useGetMeterValues({
    variables: {
      filter: {
        transactionUid: session.activitiesData?.find(
          ({ type }) => type === "CHARGE"
        )?.transactionUid,
        measurands: selectedMeasurand,
      },
      pagination: {
        limit: 10000,
      },
    },
  });

  return (
    <Flex fullSize p={3} justifyContent={"space-between"}>
      <Flex flex={1} flexDirection={"row"} justifyContent={"space-between"}>
        <Flex flexDirection={"row"} style={{ gap: "10px 10px" }}>
          <Dropdown>
            <DropdownHeader>
              <Flex
                style={{
                  border: "1px solid #737373",
                  width: "250px",
                  alignItems: "center",
                  padding: "10px 20px",
                  borderRadius: 5,
                }}
              >
                MeterValues Type
              </Flex>
            </DropdownHeader>
            <DropdownContent style={{ width: 250, backgroundColor: "white" }}>
              <Flex flexDirection={"column"} style={{ gap: "10px 10px" }} p={3}>
                <Checkbox
                  checked={selectedMeasurand === measurandDefaultOptions}
                  children={"Select All"}
                  type="checkbox"
                  onClick={() => {
                    if (selectedMeasurand === measurandDefaultOptions) {
                      setSelectedMesasurand([]);
                    } else {
                      setSelectedMesasurand(measurandDefaultOptions);
                    }
                  }}
                />
                {[
                  "Current.Offered",
                  "Energy.Active.Import.Register",
                  "Power.Active.Export",
                  "Power.Active.Import",
                  "Power.Offered",
                  "SoC",
                  "Voltage",
                ].map((item) => {
                  return (
                    <Checkbox
                      checked={selectedMeasurand.includes(item as Measurand)}
                      children={item}
                      type="checkbox"
                      onClick={() => {
                        let isExist = selectedMeasurand.includes(
                          item as Measurand
                        );
                        if (isExist) {
                          setSelectedMesasurand((prev) =>
                            prev.filter((it) => it !== item)
                          );
                        } else {
                          setSelectedMesasurand((prev) => [
                            ...prev,
                            item as Measurand,
                          ]);
                        }
                      }}
                    />
                  );
                })}
              </Flex>
            </DropdownContent>
          </Dropdown>
          <Dropdown>
            <DropdownHeader>
              <Flex
                style={{
                  border: "1px solid #737373",
                  width: "250px",
                  alignItems: "center",
                  padding: "10px 20px",
                  borderRadius: 5,
                }}
              >
                Chart Type
              </Flex>
            </DropdownHeader>
            <DropdownContent style={{ width: 250, backgroundColor: "white" }}>
              <Flex flexDirection={"column"} style={{ gap: "10px 10px" }} p={3}>
                {["line", "column"].map((item: any, index: number) => {
                  return (
                    <Checkbox
                      key={index}
                      checked={item === chartType}
                      children={item}
                      type="checkbox"
                      onClick={() => {
                        setChartType(item);
                      }}
                    />
                  );
                })}
              </Flex>
            </DropdownContent>
          </Dropdown>
        </Flex>
        <Box>
          <Button
            $size="large"
            onClick={() => setShowTable(true)}
            style={{
              width: "250px",
              alignItems: "center",
            }}
          >
            View Triggers Table
          </Button>
        </Box>
      </Flex>

      <QueryResult data={data} error={error} Loading={loading}>
        <Flex
          minWidth={"310px"}
          flexShrink={0}
          flexGrow={1}
          ref={chart}
          height={330}
        >
          {data?.MeterValuesData?.results ? (
            <LineChart
              sessionUid={session.uid}
              data={selectedMeasurand.map((item) => {
                return {
                  id: item,
                  type: chartType,
                  data: data?.MeterValuesData?.results
                    ?.filter((it) => it.meterValue.measurand === item)
                    ?.map((meterValue) => ({
                      timestamp: meterValue.meterValue.timestamp,
                      value: meterValue.meterValue.value,
                      unit: meterValue.meterValue.value,
                      y: meterValue.meterValue.value,
                      x: new Date(getTime(meterValue.meterValue.timestamp)),
                    })),
                };
              })}
            />
          ) : null}
        </Flex>
      </QueryResult>
    </Flex>
  );
};
