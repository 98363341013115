import Svg from "../../UI/Svg";
type Props = {
  iconSize?: string;
  fill?: string;
  lightningFill?: string;
  stroke?: string;
};
const MapPinAC = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 0 45 40"
    {...props}
  >
    <rect
      x="1.22412"
      y="10.7753"
      width="40.5701"
      height="22.7193"
      rx="5.67982"
      fill="#E8EFF3"
      stroke="#DDDDDD"
      strokeWidth="1.62281"
    />
    <ellipse
      cx="22.3206"
      cy="25.3805"
      rx="8.92543"
      ry="2.43421"
      fill="#D9D9D9"
    />
    <path
      d="M28.0988 2.05173C24.534 -0.0983857 20.1108 -0.134932 16.5122 1.956C12.9136 4.04693 10.6931 7.94383 10.6954 12.1639C10.8039 15.263 11.846 18.253 13.6804 20.7278C15.8727 23.6926 18.5723 26.23 21.6496 28.2178C21.8448 28.3363 22.066 28.4034 22.2931 28.413C22.5291 28.3763 22.7565 28.296 22.9641 28.1759C24.9797 26.853 26.8363 25.2942 28.4959 23.5313C31.3713 20.4907 33.6717 16.5574 33.7539 12.3731C33.7738 8.16113 31.6286 4.24564 28.0988 2.05173Z"
      fill="#3D729C"
    />
    <path
      d="M18.3287 8.32049V14.8385C18.3287 15.3985 18.7543 15.824 19.3143 15.824H21.3525C21.6661 15.824 21.8677 16.1376 21.7557 16.4288L20.255 20.0126C19.9862 20.6621 20.4566 21.3789 21.1509 21.3789H21.4421C21.8005 21.3789 22.1141 21.1997 22.2933 20.9085L27.1314 13.8082C27.5121 13.1586 27.0418 12.3298 26.2802 12.3298H23.8164C23.5252 12.3298 23.3236 12.0387 23.4132 11.7475L24.4211 8.65647C24.6227 8.00691 24.1524 7.37975 23.5028 7.37975H19.3367C18.7543 7.35735 18.3287 7.78293 18.3287 8.32049Z"
      fill={props.lightningFill??"white"}
    />
  </Svg>
);
export default MapPinAC;
