import { gql, useMutation } from "@apollo/client";

export const PUBLISH_REMOTE_RESERVATION_REQUEST = gql`
  mutation publishRemoteReservationRequest($input: RemoteReservationInput!) {
    publishRemoteReservation(input: $input) {
      requestUid
    }
  }
`;

export const usePublishRemoteReservationRequest = () => {
  const [PublishRemoteReservationRequest, { data, loading, error }] =
    useMutation<any>(PUBLISH_REMOTE_RESERVATION_REQUEST);
  return {
    PublishRemoteReservationRequest,
    error,
    data: data?.publishRemoteReservation,
    loading,
  };
};
