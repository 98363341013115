import { useTranslation } from "react-i18next";
import { Flex } from "../../../../shared/UI";

export const TopBar: React.FC<{
  secondaryText?: string | null;
}> = ({ secondaryText }) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="row"
      borderRadius="10px 10px 0px 0px"
      background="#D9EEFF"
      boxShadow="0px 3px 3px 0px rgba(0, 0, 0, 0.25)"
      padding="20px"
      alignContent="center"
      alignItems="center"
    >
      <div
        style={{
          color: "#122967",
          fontFamily: "Rubik",
          fontSize: "26px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "normal",
          textTransform: "uppercase",
        }}
      >
        {t("siteSelection.dashboard.toolbar.title")}
      </div>
      {secondaryText && (
        <div
          style={{
            color: "#000",
            fontFamily: "Rubik",
            fontSize: "14.614px",
            fontStyle: "normal",
            fontWeight: "300",
            lineHeight: "normal",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          {secondaryText}
        </div>
      )}

      <div style={{ flex: 1 }} />
    </Flex>
  );
};