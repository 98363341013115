import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
const Dynamo: React.FC<Props> = ({ size, fill, ...props }) => {
  return (
    <Svg
      iconSize={size}
      fill={fill}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 18"
    >
      <g clipPath="url(#clip0_847_5118)">
        <path
          d="M8.35513 7.58732H12.2287C12.6907 7.58732 13.1076 7.86845 13.2396 8.28846C13.4307 8.71186 13.3091 9.18945 12.9617 9.48753L4.06817 17.0748C3.6756 17.4068 3.10134 17.4339 2.68168 17.1358C2.26167 16.8377 2.10881 16.2958 2.31343 15.8317L4.98531 9.75511H1.0805C0.648673 9.75511 0.233977 9.47398 0.0710797 9.05397C-0.0918179 8.63057 0.0311422 8.15298 0.379783 7.8549L9.27227 0.268217C9.66484 -0.0650146 10.2381 -0.0903846 10.6584 0.207112C11.0788 0.50471 11.2316 1.04564 11.0267 1.51104L8.35513 7.58732Z"
        />
      </g>
      <g clipPath="url(#clip1_847_5118)">
        <path
          d="M14.3581 7.58732H18.2316C18.6937 7.58732 19.1105 7.86845 19.2426 8.28846C19.4336 8.71186 19.312 9.18945 18.9646 9.48753L10.0711 17.0748C9.67853 17.4068 9.10427 17.4339 8.68461 17.1358C8.2646 16.8377 8.11174 16.2958 8.31636 15.8317L10.9882 9.75511H7.08343C6.6516 9.75511 6.23691 9.47398 6.07401 9.05397C5.91111 8.63057 6.03407 8.15298 6.38271 7.8549L15.2752 0.268217C15.6678 -0.0650146 16.241 -0.0903846 16.6613 0.207112C17.0817 0.50471 17.2346 1.04564 17.0296 1.51104L14.3581 7.58732Z"
        />
      </g>
      <path
        d="M15.0251 7.58728H18.8986C19.3606 7.58728 19.7775 7.86842 19.9095 8.28843C20.1006 8.71183 19.979 9.18942 19.6316 9.4875L10.7381 17.0748C10.3455 17.4067 9.77127 17.4338 9.3516 17.1358C8.93159 16.8377 8.77873 16.2957 8.98335 15.8317L11.6552 9.75508H7.75042C7.31859 9.75508 6.9039 9.47395 6.741 9.05394C6.5781 8.63054 6.70106 8.15294 7.0497 7.85487L15.9422 0.268186C16.3348 -0.0650451 16.908 -0.0904151 17.3283 0.207081C17.7487 0.50468 17.9016 1.04561 17.6966 1.51101L15.0251 7.58728Z"
      />
      <defs>
        <clipPath id="clip0_847_5118">
          <rect width="13.3403" height="17.3424" fill="white" />
        </clipPath>
        <clipPath id="clip1_847_5118">
          <rect
            width="13.3403"
            height="17.3424"
            fill="white"
            transform="translate(6.00293)"
          />
        </clipPath>
      </defs>{" "}
    </Svg>
  );
};

export default Dynamo;
