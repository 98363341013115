import { Marker, OverlayView } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Typography } from "../../../../../shared/UI";
import MarkerWipByOther from "../../../../../shared/assets/svg/MarkerWipByOther";
import { Location } from "../../../@types/project";
import { useProjects } from "../../../hooks/useProjects";
import { useSiteSelectionContext } from "../../../slices/ContextProvider";
import { buildSvgString } from "../../../utils/buildSVG";
import { InfoOverlay } from "../map-components/NewLocationTooltip";

const getWipByOtherMarker = (cpTot: number, evcModel: "AC" | "DC") => (
  <MarkerWipByOther fontFamily="Rubik" cpTot={cpTot} evcModel={evcModel} />
);

export const OtherProjectLocations = ({
  projectId,
}: {
  projectId?: string;
}) => {
  const { t } = useTranslation();
  const { projects } = useProjects({
    filter: {
      status: "open",
      visibility: projectId === undefined ? "public" : undefined,
    },
  });

  const {
    state: { selectedCity },
  } = useSiteSelectionContext();

  const locations = useMemo(
    () =>
      projects
        ?.filter((p) => p.id !== projectId)
        .flatMap((p) => p.locations ?? []) ?? [],
    [projectId, projects]
  );

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );

  return (
    <>
      {locations.map((l) => {
        const cpTot = l.ac + l.dc;
        const evcModel = l.dc > 0 ? "DC" : "AC";
        const markerComponent = getWipByOtherMarker(cpTot, evcModel);
        const svgString = buildSvgString(markerComponent);
        return (
          <Marker
            key={l.id}
            position={{
              lat: l.latitude,
              lng: l.longitude,
            }}
            icon={{
              url: svgString,
              scaledSize: new google.maps.Size(35, 35),
            }}
            onClick={() => {
              setSelectedLocation(l);
            }}
            clickable={!!selectedCity}
            zIndex={4}
          >
            {selectedLocation?.id === l.id && (
              <OverlayView
                position={{
                  lat: l.latitude,
                  lng: l.longitude,
                }}
                zIndex={100000}
                mapPaneName="overlayMouseTarget"
                getPixelPositionOffset={() => ({
                  x: 0,
                  y: -10,
                })}
              >
                <InfoOverlay
                  width={200}
                  title="IN LAVORAZIONE"
                  onClose={() => {
                    setSelectedLocation(null);
                  }}
                >
                  <Flex padding={"4px"} color="black">
                    <Flex flexDirection="row" marginTop={"5px"}>
                      <Typography fontWeight={700}>Inserita il:</Typography>
                      <Typography variant={"body10"}>
                        {l.createdAt?.toISOString().split("T")[0] ??
                          new Date().toISOString().split("T")[0]}
                      </Typography>
                    </Flex>
                    <Flex flexDirection="row">
                      <Typography fontWeight={700}>Da:</Typography>
                      <Typography>{l.createdBy ?? "unknown"}</Typography>
                    </Flex>

                    <Flex flexDirection="row" marginTop={"5px"}>
                      <Typography fontWeight={700}>
                        {t("siteSelection.general.legend.ac")}:
                      </Typography>
                      <Typography>{l.ac}</Typography>
                    </Flex>
                    <Flex flexDirection="row">
                      <Typography fontWeight={700}>
                        {t("siteSelection.general.legend.dc")}:
                      </Typography>
                      <Typography>{l.dc}</Typography>
                    </Flex>
                    <Typography marginTop={"5px"} fontWeight={700}>
                      {t("siteSelection.general.where")}
                    </Typography>
                    <Typography>{l.address ?? "-"}</Typography>
                  </Flex>
                </InfoOverlay>
              </OverlayView>
            )}
          </Marker>
        );
      })}
    </>
  );
};
