import Svg from "../../UI/Svg";
type Props = {
  iconSize?: string;
  fill?: string;
  stroke?: string;
  lightningFill?: string;
};
const MapPinDC = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 0 45 40"
    {...props}
  >
    <rect
      x="1.22412"
      y="10.7753"
      width="40.5701"
      height="22.7193"
      rx="5.67982"
      fill="#E8EFF3"
      stroke="#DDDDDD"
      strokeWidth="1.62281"
    />
    <ellipse
      cx="22.3206"
      cy="25.3805"
      rx="8.92543"
      ry="2.43421"
      fill="#D9D9D9"
    />
    <path
      d="M27.6852 2.27927C24.1204 0.129153 19.6972 0.0926072 16.0986 2.18354C12.5001 4.27447 10.2795 8.17137 10.2819 12.3914C10.3903 15.4905 11.4325 18.4805 13.2669 20.9554C15.4591 23.9202 18.1588 26.4575 21.236 28.4453C21.4313 28.5638 21.6524 28.6309 21.8795 28.6406C22.1155 28.6039 22.3429 28.5235 22.5505 28.4035C24.5661 27.0805 26.4227 25.5217 28.0823 23.7589C30.9578 20.7182 33.2581 16.785 33.3403 12.6006C33.3603 8.38867 31.215 4.47318 27.6852 2.27927Z"
      fill="#3D729C"
    />
    <path
      d="M20.6215 7.68836V13.6534C20.6215 14.1702 21.0091 14.5579 21.526 14.5579H23.378C23.658 14.5579 23.8518 14.8593 23.7442 15.1178L22.3659 18.391C22.129 18.9724 22.5597 19.64 23.1842 19.64H23.4427C23.7657 19.64 24.0457 19.4893 24.1964 19.2093L28.5897 12.7059C28.9343 12.1245 28.5036 11.3492 27.836 11.3492H25.5962C25.3378 11.3492 25.144 11.0908 25.2301 10.8109L26.1562 7.98985C26.35 7.40842 25.9193 6.82699 25.3163 6.82699H21.526C21.0306 6.80545 20.6215 7.21461 20.6215 7.68836Z"
      fill={props.lightningFill??"white"}
    />
    <path
      d="M16.7021 18.521C16.4006 19.2962 16.9174 20.2437 17.8219 20.2437H18.0157C18.4249 20.2437 18.8341 20.0284 19.0495 19.6623L22.1722 14.9463V14.9032C22.6244 14.1064 22.086 13.0512 21.1169 13.0512H19.954L20.4924 11.3715C20.7293 10.5963 20.1909 9.75646 19.3294 9.75646H16.6375C15.9483 9.73493 15.4099 10.2948 15.4099 10.9624V15.3124C15.4099 15.9799 15.9483 16.5614 16.6375 16.5614H17.5204L16.7021 18.521Z"
      fill={props.lightningFill??"white"}
    />
    <path
      d="M18.016 20.826H17.8222C17.2192 20.826 16.6808 20.546 16.3578 20.0507C16.0132 19.5339 15.9486 18.8879 16.164 18.328V18.3064L16.6377 17.1221H16.6162C15.6256 17.1221 14.8287 16.3037 14.8287 15.2916V10.9417C14.8287 10.4464 15.0226 9.99414 15.3671 9.64959C15.7117 9.30504 16.164 9.1543 16.6593 9.1543H19.3297C19.9112 9.1543 20.428 9.41271 20.7726 9.88647C21.1172 10.3602 21.2249 10.9632 21.0526 11.5231L20.7511 12.4491H21.1387C21.7848 12.4491 22.3663 12.7936 22.6678 13.3535C22.9908 13.9349 22.9908 14.6241 22.6678 15.1839L19.5235 19.9861C19.222 20.4814 18.6406 20.826 18.016 20.826ZM17.2407 18.7371C17.1546 18.974 17.1977 19.1894 17.3054 19.3832C17.37 19.4908 17.5207 19.6631 17.8222 19.6631H18.016C18.2314 19.6631 18.4468 19.5554 18.5544 19.3616L21.6987 14.6241C21.8063 14.4302 21.8063 14.1718 21.6987 13.9565C21.6341 13.8488 21.4833 13.6335 21.1603 13.6335H19.179L19.9543 11.2001C20.0189 10.9847 19.9758 10.7694 19.8466 10.5971C19.782 10.511 19.6097 10.3387 19.3513 10.3387H16.6593C16.4439 10.3387 16.2932 10.4248 16.207 10.511C16.0778 10.6402 16.0132 10.7909 16.0132 10.9632V15.3132C16.0132 15.6792 16.2932 15.9807 16.6593 15.9807H18.4037L17.2407 18.7371Z"
      fill="#3D729C"
    />
  </Svg>
);
export default MapPinDC;
