import { LoadScript } from "@react-google-maps/api";
import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SectionContent } from "../../../../shared/layout";
import { Flex } from "../../../../shared/UI";
import { useProject } from "../../hooks/useProject";
import { useSiteSelectionGeoDataContext } from "../../slices/GeoDataContextProvider";
import { ActionBar } from "./ActionBar";
import { InfoCard } from "./InfoCard";
import { Legend } from "./Legend";
import { LocationMap } from "./LocationMap";
import { TopBar } from "./TopBar";

type LocationsListProps = {};

const UpdateLocationsData = ({
  projectId,
}: {
  projectId: string | null | undefined;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [first, setFirst] = React.useState<boolean>(false);
  const { project, updateLocation } = useProject(projectId);
  const { getGeoByCoordinates } = useSiteSelectionGeoDataContext();

  useEffect(() => {
    if (loading || first) return;

    if (!project) return;
    
    const { locations } = project;
    
    const toUpdate = locations.filter(
      (location) => !location.zipCode || location.zipCode === "" || location.city === "" || location.province === "" || location.region === ""
      );

    if (toUpdate.length === 0) return;
    
    const geocoder = new window.google.maps.Geocoder();
    
    setLoading(true);
    setFirst(true);

    Promise.all(
      toUpdate.map(async (location) => {
        const addressComponent = await geocoder.geocode({
          location: {
            lat: location.latitude,
            lng: location.longitude,
          },
        });

        const zipCode = addressComponent.results?.[0].address_components.find(
          (c) => c.types.includes("postal_code")
        )?.long_name;

        const address = addressComponent.results?.[0].formatted_address;

        const { city, province, region } =
          getGeoByCoordinates({
            lat: location.latitude,
            lng: location.longitude,
          }) || {};

        return {
          ...location,
          city: city?.properties?.COMUNE || "",
          province: province?.properties?.DEN_UTS || "",
          region: region?.properties?.DEN_REG || "",
          country: "Italy",
          zipCode,
          address,
        };
      })
    )
      .then((updatedLocation) => {
        return Promise.all(
          updatedLocation.map((location) => updateLocation(location.id, location, false))
        )
      })
      .finally(() => {
        setLoading(false);
      });
  }, [first, getGeoByCoordinates, loading, project, updateLocation]);

  return null;
};

export const Project: React.FC<LocationsListProps> = () => {
  // FIXME: add technological mixture text
  // const { t } = useTranslation();
  const params = useParams<{ uuid: string }>();

  return (
    <SectionContent>
      <TopBar
      // secondaryText={t("siteSelection.freeRide.toolbar.secondaryText", {
      //   acPercentage: 70,
      //   dcPercentage: 30,
      //   lastUpdate: "2021-01-01",
      // })}
      />
      <ActionBar projectId={params.uuid} />
      <Flex flexDirection="row" minHeight="750px" height={"100%"} width="100%">
        <LoadScript
          googleMapsApiKey="AIzaSyChibtUkiRoURZd6yrh1611rI4w93SE5lM"
          libraries={["places", "geocoding"]}
        >
          <UpdateLocationsData projectId={params.uuid} />
          <InfoCard projectId={params.uuid} />
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
              padding: "20px",
              overflow: "hidden",
            }}
          >
            <LocationMap streetView={true} projectId={params.uuid} />
          </div>
          <Legend />
        </LoadScript>
      </Flex>
    </SectionContent>
  );
};
