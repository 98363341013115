/** @format */

import * as yup from "yup";

interface BaseType {
  name: string;
  entryPointUrl: string;
  status: {
    label: string;
    value: string;
  };
  protocol: {
    label: string;
    value: string;
  };
}

interface OCPIProtocol extends BaseType {
  needsReliablePush?: boolean;
  offlineTokenOut?: string;
  offlineTokenIn?: string;
  skipCredentialsRefresh?: boolean;
  pullTokens?: boolean;
  hubPartyUid?: {
    label: string;
    value: string;
  };
  flavor?: {
    label: string;
    value: string;
  };
  version?: {
    label: string;
    value: string;
  };
}

interface OICPProtocol extends BaseType {}

export type PlatformData = OCPIProtocol & OICPProtocol;

export const platformSchema = yup.object().shape(
  {
    name: yup.string().required(),
    protocol: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required(),
    entryPointUrl: yup
      .string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
        "Enter correct url!"
      )
      .required("Please enter website"),
    status: yup.object({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    needsReliablePush: yup.boolean().optional().default(false),
    offlineTokenOut: yup.string().optional().nullable(),
    offlineTokenIn: yup.string().optional().nullable(),
    pullTokens: yup.boolean().optional().nullable(),
    skipCredentialsRefresh: yup.boolean().optional().nullable(),
    hubPartyUid: yup
      .object({
        label: yup.string().optional(),
        value: yup.string().optional(),
      })
      .optional()
      .nullable(),
    flavor: yup
      .object({
        label: yup.string().optional(),
        value: yup.string().optional(),
      })
      .optional()
      .nullable(),
    version: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .when("protocol.value", {
        is: "OCPI",

        then: () =>
          yup.object({
            label: yup.string().required(),
            value: yup.string().required(),
          }),
      })
      .nullable(),
    // uncomment it after active the tariff part
    // price: yup.object({
    //   label: yup.string().required(),
    //   value: yup.string().required(),
    // }),
    // tariffName: yup.object({
    //   label: yup.string().required(),
    //   value: yup.string().required(),
    // })
  },
  [["version", "version"]]
);
