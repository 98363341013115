/** @format */

import {
  GoogleMap,
  Marker,
  OverlayView,
  StreetViewPanorama,
} from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import { useProject } from "../../hooks/useProject";
import { useSiteSelectionContext } from "../../slices/ContextProvider";
import { useSiteSelectionEvcDataContext } from "../../slices/EvcDataContextProvider";
import { BecEvcsLayer } from "./layers/BecEvcsLayer";
import { CityLayer } from "./layers/CityLayer";
import { CompetitorLayer } from "./layers/CompetitorEvcsLayer";
import { CurrentProjectLocationLayer } from "./layers/CurrentProjectLocationLayer";
import { HexagonLayer, HexagonProperties } from "./layers/HexagonLayer";
import { OtherProjectLocations } from "./layers/OtherProjectLocations";
import { PoiLayer } from "./layers/PoiLayer";
import { ProvinceLayer } from "./layers/ProvinceLayer";
import { RegionLayer } from "./layers/RegionLayer";
import { LocationList } from "./map-components/LocationList";
import { NewLocationTooltip } from "./map-components/NewLocationTooltip";
import { PoiFilter } from "./map-components/PoiFilter";

type LocationMapProps = {
  streetView?: boolean;
  projectId?: string;
};
const containerStyle = {
  width: "100%",
  height: "100%",
};

const streetViewOptions: google.maps.StreetViewPanoramaOptions = {
  pov: {
    heading: 34,
    pitch: 10,
  },
  addressControl: false,
  clickToGo: true,
  visible: true,
  panControl: false,
  fullscreenControlOptions: {
    position: 6,
  },
  enableCloseButton: true,
};

export const LocationMap: React.FC<LocationMapProps> = (props) => {
  const [streetViewVisible, setStreetViewVisible] = useState<boolean>(false);
  const [streetViewHeight, setStreetViewHeight] = useState<number>(200);

  const {
    state: {
      selectedCoords,
      selectedHexagon,
      selectedRegion,
      selectedProvince,
      selectedCity,
      mode,
    },
    selectCity,
    selectProvince,
    selectRegion,
    selectHexagon,
    setMap,
  } = useSiteSelectionContext();

  const { bounds, setBounds, setZoom } = useSiteSelectionEvcDataContext();

  // const { hexData } = useGetHexagonLayer();
  const [zoomLevel, setZoomLevel] = useState<number>(15);
  const [nextZoomOutRegionLevel, setNextZoomOutRegionLevel] =
    useState<number>(8);
  const [nextZoomOutProvinceLevel, setNextZoomOutProvinceLevel] =
    useState<number>(9);
  const [nextZoomOutCityLevel, setNextZoomOutCityLevel] = useState<number>(9);

  const { project, addLocation } = useProject(props.projectId);

  const [mapRef, setMapRef] = useState<google.maps.Map | undefined>();

  const ref = useRef<any>();
  const [center] = useState<google.maps.LatLngLiteral>({
    lat: 41.902782,
    lng: 12.496366,
  });
  const panContainerRef = useRef<any>();
  const panRef = useRef<any>();

  const [newLocation, setNewLocation] =
    useState<google.maps.LatLngLiteral | null>(null);

  useEffect(() => {
    if (zoomLevel < nextZoomOutRegionLevel && selectedRegion) {
      selectRegion(null);
    }
    if (zoomLevel < nextZoomOutProvinceLevel && selectedProvince) {
      selectProvince(null);
    }
    if (zoomLevel < nextZoomOutCityLevel && selectedCity) {
      selectCity(null);
      selectHexagon(null);
    }
    if (selectedHexagon && zoomLevel < 16) {
      selectHexagon(null);
    }
  }, [
    zoomLevel,
    selectedProvince,
    selectedRegion,
    nextZoomOutRegionLevel,
    nextZoomOutProvinceLevel,
    nextZoomOutCityLevel,
    selectedCity,
    selectRegion,
    selectProvince,
    selectCity,
    selectHexagon,
    selectedHexagon,
  ]);

  const onMapLoad = async (map: google.maps.Map) => {
    setMapRef(map);
    setMap(map);

    if (props.streetView) {
      const pan = document.getElementById("pan");
      if (pan) {
        const streetView = new google.maps.StreetViewPanorama(
          pan,
          streetViewOptions
        );
        map.setStreetView(streetView);
        streetView.addListener("visible_changed", () => {
          setStreetViewVisible(streetView.getVisible());
        });
        streetView.addListener("position_changed", () => {
          setStreetViewVisible(streetView.getVisible());
        });
      }
    }
    map.addListener("zoom_changed", () => {
      const newZoom = map.getZoom();
      newZoom && setZoomLevel(newZoom);
    });
  };

  const handleResize: React.MouseEventHandler<HTMLDivElement> = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    const el = document.getElementById("panContainer") as HTMLElement;
    const map = document.getElementById("map") as HTMLElement;
    const height = el.offsetHeight;
    const y = e.clientY ?? 0;
    let newHeight = 200;

    const mouseMoveHandler = (e: MouseEvent) => {
      const prevHeight = el.offsetHeight;

      const nh = Math.max(height + e.clientY - y, 200);
      const mapHeight = map.offsetHeight;

      const delta = nh - prevHeight;
      const mapNewHeight = mapHeight - delta;

      if (mapNewHeight < 300) return;

      newHeight = nh;

      el.style.minHeight = newHeight + "px";
      el.style.height = newHeight + "px";
    };
    const mouseUpHandler = () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      setStreetViewHeight(newHeight);
    };
    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };

  const setSelectedHandler = useCallback(
    (hex: HexagonProperties) => {
      setNewLocation(null);
      selectHexagon(hex);
    },
    [selectHexagon]
  );

  const setSelectedClickHandler = useCallback(
    (e: google.maps.MapMouseEvent, _hex: HexagonProperties) => {
      e.latLng && setNewLocation(e.latLng.toJSON());
    },
    []
  );

  return (
    <VerticalScrollContainer
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        borderRadius: "20px",
      }}
      tabIndex={-1}
    >
      {props.streetView && (
        <>
          <div
            id="panContainer"
            style={{
              minHeight: streetViewVisible
                ? `${streetViewHeight ?? 200}px`
                : "0px",
              height: streetViewVisible
                ? `${streetViewHeight ?? 200}px`
                : "0px",
              visibility: streetViewVisible ? "visible" : "hidden",
            }}
            ref={panContainerRef}
          >
            <div
              id="pan"
              ref={panRef}
              style={{
                height: "100%",
                visibility: "inherit",
              }}
            ></div>
            <div
              id="resize"
              style={{
                position: "relative",
                height: "8px",
                width: "100%",
                background: "#000000",
                cursor: "row-resize",
                overflow: "visible",
                zIndex: 1,
              }}
              onMouseDown={handleResize}
            >
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  background: "white",
                  borderRadius: "10px",
                  border: "1px solid gray",
                  height: "20px",
                  width: "50px",
                  zIndex: 1,
                  transform: "translate(-50%, -50%)",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "gray",
                  whiteSpace: "nowrap",
                  visibility: "inherit",
                }}
              >
                ==
              </div>
            </div>
          </div>
        </>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onMapLoad}
        center={center}
        id="map"
        // zoom={15}
        zoom={6}
        ref={ref}
        onTilesLoaded={() => {
          (document.querySelectorAll("#map div") as any).forEach(function (
            item: any
          ) {
            item.removeAttribute("tabindex");
          });
        }}
        onBoundsChanged={() => {
          if (selectedCoords) {
            setNextZoomOutRegionLevel(8);
            setNextZoomOutProvinceLevel(10);
            setNextZoomOutCityLevel(12);
          }

          const b2 = mapRef?.getBounds();
          const b = b2?.toJSON();
          const b1 = bounds?.toJSON();

          if (
            b &&
            (b1?.north !== b.north ||
              b1?.south !== b.south ||
              b1?.east !== b.east ||
              b1?.west !== b.west)
          ) {
            setBounds(mapRef?.getBounds() ?? null);
          }
        }}
        onZoomChanged={() => {
          setZoom(mapRef?.getZoom() ?? null);
        }}
        options={{
          mapTypeControl: true,
          streetViewControl: true,
          fullscreenControl: true,
          zoomControl: true,
          gestureHandling: "auto",
          clickableIcons: false,
          disableDefaultUI: true,
          panControl: false,

          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "administrative.province",
              stylers: [{ visibility: "on" }],
            },
          ],

          mapTypeControlOptions: {
            style: 0,
            position: 10,
          },
          fullscreenControlOptions: {
            position: 9,
          },
        }}
      >
        <RegionLayer
          onSelect={(region) => {
            selectRegion(region);
            selectProvince(null);
            setNextZoomOutRegionLevel(mapRef?.getZoom() ?? 0);
          }}
          selected={selectedRegion}
        />
        <ProvinceLayer
          regionId={selectedRegion?.properties.COD_REG ?? null}
          selected={selectedProvince}
          onSelect={(province) => {
            selectProvince(province);
            selectCity(null);
            setNextZoomOutProvinceLevel(mapRef?.getZoom() ?? 0);
          }}
        />

        <CityLayer
          provinceId={selectedProvince?.properties.COD_PROV ?? null}
          selected={selectedCity}
          onSelect={(city) => {
            selectCity(city);
            setNextZoomOutCityLevel(mapRef?.getZoom() ?? 0);
            selectHexagon(null);
            // mapRef?.setZoom(14);
          }}
        />

        {selectedCity && (
          <HexagonLayer
            city={selectedCity?.properties.COMUNE ?? null}
            selected={selectedHexagon}
            setSelected={setSelectedHandler}
            onSelectedClick={setSelectedClickHandler}
            projectId={props.projectId}
          />
        )}

        {props.projectId && (
          <LocationList
            position={"absolute"}
            right={"10px"}
            top={"10px"}
            zIndex={2}
            projectId={props.projectId}
          />
        )}

        <PoiFilter
          position={"absolute"}
          left={"10px"}
          top={"10px"}
          zIndex={2}
        />

        {selectedCity && zoomLevel > 15 && <PoiLayer />}
        {selectedCity && zoomLevel > 13 && <CompetitorLayer />}
        {selectedCity && zoomLevel > 13 && <BecEvcsLayer />}

        <OtherProjectLocations projectId={props.projectId} />
        <CurrentProjectLocationLayer projectId={props.projectId} />

        {!props.streetView && (
          <StreetViewPanorama
            options={{
              ...streetViewOptions,
              enableCloseButton: true,
              addressControlOptions: {
                position: 1,
              },
            }}
          />
        )}

        {project && newLocation && selectedHexagon && (
          <Marker
            key={"newLocation"}
            icon={{
              url: "/markers/wip-marker.svg",
              scaledSize: new google.maps.Size(40, 40),
            }}
            position={newLocation}
            zIndex={200}
          >
            {newLocation && (
              <OverlayView
                position={newLocation}
                mapPaneName="floatPane"
                getPixelPositionOffset={() => ({
                  x: -228,
                  y: -328,
                })}
              >
                <NewLocationTooltip
                  evcForecast={{
                    ac: mode === "optimized" ? selectedHexagon.n_ac : 0,
                    dc: mode === "optimized" ? selectedHexagon.n_dc : 0,
                  }}
                  coordinates={newLocation}
                  onClose={() => {
                    setNewLocation(null);
                  }}
                  onConfirm={(location) => {
                    addLocation({
                      latitude: newLocation.lat,
                      longitude: newLocation.lng,
                      ac: location.acNumber,
                      dc: location.dcNumber,
                      address: location.address,
                      contractualCapacity: location.contractualCapacity,
                      validated: null,
                      city: selectedCity?.properties.COMUNE ?? undefined,
                      region: selectedRegion?.properties.DEN_REG ?? undefined,
                      province:
                        selectedProvince?.properties.DEN_UTS ?? undefined,
                      country: "Italy",
                      hex: selectedHexagon?.id ?? undefined,
                      zipCode: location.zipCode ?? undefined,
                      forecast: selectedHexagon?.kwhPred ?? undefined,
                    });
                    setNewLocation(null);
                  }}
                />
              </OverlayView>
            )}
          </Marker>
        )}
      </GoogleMap>
    </VerticalScrollContainer>
  );
};
