import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};


const Ccs1: React.FC<Props> =({ size, fill, ...props }) => {
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" 
      fill={fill}
      iconSize={size}
       viewBox="0 0 387 512">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M 192.191406 384 C 298.335938 384 384.386719 298.039062 384.386719 192 C 384.386719 85.960938 298.335938 0 192.191406 0 C 86.046875 0 0 85.960938 0 192 C 0 298.039062 86.046875 384 192.191406 384 Z M 78.445312 274.285156 C 87.109375 274.285156 94.136719 267.269531 94.136719 258.613281 C 94.136719 249.957031 87.109375 242.9375 78.445312 242.9375 C 69.78125 242.9375 62.757812 249.957031 62.757812 258.613281 C 62.757812 267.269531 69.78125 274.285156 78.445312 274.285156 Z M 334.703125 258.613281 C 334.703125 267.269531 327.679688 274.285156 319.011719 274.285156 C 310.347656 274.285156 303.324219 267.269531 303.324219 258.613281 C 303.324219 249.957031 310.347656 242.9375 319.011719 242.9375 C 327.679688 242.9375 334.703125 249.957031 334.703125 258.613281 Z M 300.710938 164.570312 C 322.371094 164.570312 339.933594 147.027344 339.933594 125.386719 C 339.933594 103.746094 322.371094 86.203125 300.710938 86.203125 C 279.046875 86.203125 261.488281 103.746094 261.488281 125.386719 C 261.488281 147.027344 279.046875 164.570312 300.710938 164.570312 Z M 130.742188 125.386719 C 130.742188 147.027344 113.183594 164.570312 91.519531 164.570312 C 69.859375 164.570312 52.296875 147.027344 52.296875 125.386719 C 52.296875 103.746094 69.859375 86.203125 91.519531 86.203125 C 113.183594 86.203125 130.742188 103.746094 130.742188 125.386719 Z M 196.113281 352.652344 C 217.777344 352.652344 235.335938 335.109375 235.335938 313.46875 C 235.335938 291.828125 217.777344 274.285156 196.113281 274.285156 C 174.453125 274.285156 156.890625 291.828125 156.890625 313.46875 C 156.890625 335.109375 174.453125 352.652344 196.113281 352.652344 Z M 196.113281 352.652344 "/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M 41.835938 449.304688 C 41.835938 414.679688 69.933594 386.613281 104.59375 386.613281 L 282.40625 386.613281 C 317.066406 386.613281 345.164062 414.679688 345.164062 449.304688 C 345.164062 483.929688 317.066406 512 282.40625 512 L 104.59375 512 C 69.933594 512 41.835938 483.929688 41.835938 449.304688 Z M 156.890625 449.304688 C 156.890625 478.160156 133.476562 501.550781 104.59375 501.550781 C 75.710938 501.550781 52.296875 478.160156 52.296875 449.304688 C 52.296875 420.453125 75.710938 397.0625 104.59375 397.0625 C 133.476562 397.0625 156.890625 420.453125 156.890625 449.304688 Z M 282.40625 501.550781 C 311.289062 501.550781 334.703125 478.160156 334.703125 449.304688 C 334.703125 420.453125 311.289062 397.0625 282.40625 397.0625 C 253.523438 397.0625 230.109375 420.453125 230.109375 449.304688 C 230.109375 478.160156 253.523438 501.550781 282.40625 501.550781 Z M 282.40625 501.550781 "/>
    </Svg>
    );
};

export default Ccs1