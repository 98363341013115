import { gql, useLazyQuery } from "@apollo/client";

export const FIRMWARE_UPDATE_PROCESSES = gql`
  query FirmwareUpdateProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    FirmwareUpdateProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        status
        requestUid
        timestamp
        info
        request {
          evcUid
          ftpUrl
          interval
          requestUid
          retries
          retrieveAfter
        }
      }
    }
  }
`;
export const useFirmwareUpdateProcessed = () => {
  const [
    getFirmwareUpdateProcessed,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(FIRMWARE_UPDATE_PROCESSES, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getFirmwareUpdateProcessed,
    error,
    FirmwareUpdateProcessed: data?.FirmwareUpdateProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
