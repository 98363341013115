import { t } from "i18next";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Download } from "../../../../shared/assets/svg";
import { SectionList } from "../../../../shared/layout";
import { Button, Card } from "../../../../shared/UI";
import { DefaultProps } from "../../../../shared/UI/helpers";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import { NewProjectDialog } from "./NewProjectDialog";

import { Roles } from "@bepower/greta-types";
import * as XLSX from "xlsx";
import { ExcelService } from "../../../../shared/services/excelService";
import UserService from "../../../../shared/services/UserServices";
import { TopBar } from "./TopBar";

type LocationsListProps = {
  width: string;
  containerProps?: DefaultProps;
};

const buildXlsxTemplate = () => {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([
    {
      latitude: 45.464664,
      longitude: 9.18854,
      address: "via della libertà 1",
      ac: 1,
      dc: 1,
    },
  ]);

  // remove the second row
  worksheet["!ref"] = "A1:E1";

  const workbook: XLSX.WorkBook = {
    Sheets: { data: worksheet },
    SheetNames: ["data"],
  };

  const excelBuffer: any = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  ExcelService.saveAsExcelFile(excelBuffer, "template-site-selection");
};

const ProjectCard: React.FC<{
  color?: string;
  border?: string;
  children: React.ReactNode;
  disabled?: boolean;
}> = ({ color, border, children, disabled }) => {
  const b = disabled ? "2.294px solid #AAA" : border ?? `2.294px solid #269F8E`;
  const bg = disabled ? "#EEE" : color ?? `#E6F5F2`;

  return (
    <Card
      minWidth={300}
      maxWidth={"500px"}
      overflow="hidden"
      backgroundColor={bg}
      background={bg}
      position="relative"
      display="flex"
      width="500px"
      height="535px"
      borderRadius={5}
      border={b}
      flexShrink={0}
      flexDirection="column"
      alignItems={"center"}
      justifyContent={"space-between"}
      padding={70}
      style={{
        backgroundColor: (disabled ? "#EEE" : bg) + " important!",
      }}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && disabled) {
          return React.cloneElement(child, {
            disabled,
          } as any);
        }
        return child;
      })}
    </Card>
  );
};

const ProjectCardTitle: React.FC<{
  color?: string;
  children: React.ReactNode;
  disabled?: boolean;
}> = ({ color, children, disabled }) => (
  <div
    style={{
      color: disabled ? "#AAA" : color,
      textAlign: "center",
      fontFamily: "Rubik",
      fontSize: "55.059px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    }}
  >
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child) && disabled) {
        return React.cloneElement(child, {
          disabled,
        } as any);
      }
      return child;
    })}
  </div>
);

const ProjectCardAction: React.FC<{
  bg?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}> = ({ bg, children, disabled, onClick }) => (
  <Button
    bg={disabled ? "#AAA" : bg}
    color="#FFF"
    borderRadius={5}
    border={`1px solid ${disabled ? "#AAA" : bg}`}
    width="233px"
    height="64px"
    onClick={onClick}
    textAlign="center"
    fontFamily="Inter"
    fontSize="29px"
    fontStyle="normal"
    fontWeight="400"
    lineHeight="normal"
    disabled={disabled}
  >
    {children}
  </Button>
);

const ProjectCardText: React.FC<{
  color?: string;
  children: React.ReactNode;
  disabled?: boolean;
}> = ({ color, children, disabled }) => (
  <div
    style={{
      color: disabled ? "#AAA" : color,
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    }}
  >
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child) && disabled) {
        return React.cloneElement(child, {
          disabled,
        } as any);
      }
      return child;
    })}
  </div>
);

export const Dashboard: React.FC<PropsWithChildren<LocationsListProps>> = ({
  width,
  containerProps,
}) => {
  const navigate = useNavigate();

  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] =
    React.useState<boolean>(false);

  const isManager = UserService.hasResourceRole([Roles.SiteSelectionWrite], "appsync") ;
  const isReader = UserService.hasResourceRole([Roles.SiteSelection], "appsync") && !isManager;

  return (
    <SectionList maxWidth={width} width={width} {...containerProps} padding={0}>
      <TopBar />

      <VerticalScrollContainer>
        <div
          style={{
            color: "#122967",
            marginTop: "48px",
            textAlign: "center",
            fontFamily: "Rubik",
            fontSize: "53.677px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            textTransform: "uppercase",
          }}
        >
          {t("siteSelection.dashboard.title")}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexGrow: 1,
            paddingLeft: "auto",
            paddingRight: "auto",

            flexWrap: "wrap",
          }}
        >
          <ProjectCard
            color={"#E6F5F2"}
            border="2.294px solid #269F8E"
            disabled={isReader}
          >
            <ProjectCardTitle color={"#269F8E"}>
              {t("siteSelection.dashboard.newProject.title")}
            </ProjectCardTitle>
            <ProjectCardText color="#1F776B">
              {t("siteSelection.dashboard.newProject.text1")}
            </ProjectCardText>
            <ProjectCardAction
              bg={"#269F8E"}
              onClick={() => setIsNewProjectDialogOpen(true)}
            >
              {t("siteSelection.dashboard.newProject.action")}
            </ProjectCardAction>
          </ProjectCard>

          <ProjectCard
            color="#CBE8FF"
            border="2.294px solid #30A3FF"
            disabled={isReader}
          >
            <ProjectCardTitle color="#30A3FF">
              {t("siteSelection.dashboard.existingProject.title")}
            </ProjectCardTitle>
            <ProjectCardText color="#22659B">
              {t("siteSelection.dashboard.existingProject.text")}
            </ProjectCardText>
            <ProjectCardAction
              bg="#30A3FF"
              onClick={() => navigate("/site-selection/open-project")}
            >
              {t("siteSelection.dashboard.existingProject.action")}
            </ProjectCardAction>
          </ProjectCard>

          <ProjectCard color="#F8EFD1" border="2.294px solid #D3A405">
            <ProjectCardTitle color="#D3A405">
              {t("siteSelection.dashboard.freeRide.title")}
            </ProjectCardTitle>
            <ProjectCardText color="#846601">
              {t("siteSelection.dashboard.freeRide.text1")}
            </ProjectCardText>
            <ProjectCardAction
              bg="#D3A405"
              onClick={() => navigate("/site-selection/freeride")}
            >
              {t("siteSelection.dashboard.freeRide.action")}
            </ProjectCardAction>
          </ProjectCard>
        </div>
      </VerticalScrollContainer>
      <button
        onClick={() => {
          buildXlsxTemplate();
        }}
        style={{
          position: "absolute",
          bottom: "0",
          background: "#95D0FF",
          borderRadius: "10.59px 10.59px 0px 0px",
          border: "none",
          width: "100%",
          height: "75px",
          margin: "0",
          cursor: "pointer",
        }}
        disabled={isReader}
      >
        <p
          style={{
            fontFamily: "Rubik",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "center",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Download size="5" />{" "}
          {t("siteSelection.dashboard.footer.downloadTemplate")}
        </p>
      </button>
     {isManager && <NewProjectDialog
        isOpen={isNewProjectDialogOpen}
        setIsOpen={setIsNewProjectDialogOpen}
        closeOnPressEscape={false}
        closeOnClickAway={false}
        handleSubmit={(projectUid) => {
          navigate(`/site-selection/project/${projectUid}`);
        }}
      />}
    </SectionList>
  );
};
