import Svg from "../../UI/Svg";
type Props = {
  size?: string;
  fill?: string;
};
const Infrastructure: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      iconSize={size}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.496 2.703l1.284.734L8 1l4.22 2.437 1.284-.734a1 1 0 01.992 1.737l-1.3.743V10L9 12.423V14a1 1 0 11-2 0v-1.577L2.804 10V5.182l-1.3-.742a1 1 0 11.992-1.737zM8 3.31L5.79 4.585 8 5.848l2.21-1.263L8 3.31zm1 6.804l2.196-1.268v-2.52L9 7.58v2.533zM7 7.58v2.533L4.804 8.845v-2.52L7 7.58z"
        // fill="#000000"
      />
    </Svg>
  );
};

export default Infrastructure;
