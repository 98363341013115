import { Card, Flex, SkeletonLine } from "../UI"

export const ItemWithIconSkeleton: React.FC<{ width?: string }> = ({ width }) => {
   return (
      <Card minHeight={200} p={3} display='flex' flexDirection='column' alignItems='center' justifyContent='center' bg='white' minWidth={200} flex={1} >
         <Flex flex={1} maxHeight={120} width={'80px'}>
            <SkeletonLine />
         </Flex>
         <Flex my={3} height="20px" width={'90%'}>
            <SkeletonLine />
         </Flex>
         <Flex height="10px" width={'90%'}>
            <SkeletonLine />
         </Flex>

      </Card >
   )
}


export const ItemWithIconSkeletonGroup: React.FC<{ width?: string }> = ({ width }) => {
   return (
      <Flex width='100%' style={{ gap: '20px 20px' }} flexWrap='wrap' flexDirection='row'>
         <Flex flex={1} width='100%' flexDirection='row' style={{ gap: '20px 20px' }} flexWrap='wrap'>
            {[0, 1, 2, 3].map((item: number,) => {
               return <ItemWithIconSkeleton key={item} />
            })}
         </Flex>

      </Flex>
   )
}


export const ItemRowSkeleton: React.FC<{ width?: string }> = ({ width }) => {
   return (
      <Card style={{ gap: '20px 20px' }} flexWrap='wrap' display='flex' justifyContent='space-around' height={200} bg='white' minWidth={'100%'} flex={1} >
         {[0, 1, 2].map((item: number) => {
            return (
               <Flex key={item} flex={1} justifyContent='center' alignItems='center'>
                  <Flex mb={3} height={'20px'} width='70%'>
                     <SkeletonLine />
                  </Flex>
                  <Flex height={'40px'} width='70%'>
                     <SkeletonLine />
                  </Flex>
               </Flex>
            )
         })}

      </Card>
   )
}

export const ItemColumnSkeleton: React.FC<{ width?: string }> = ({ width }) => {
   return (
      <Card p={3} flexDirection='column' style={{ gap: '20px 20px' }} flexWrap='wrap' display='flex' justifyContent='space-around' height={230} bg='white' minWidth={'100%'} flex={1} >
         {[0, 1, 2].map((item: number,) => {
            return (
               <Flex flexDirection='row' key={item} flex={1} justifyContent='center' alignItems='center'>
                  <Flex mr={3} height={'40px'} width='20%'>
                     <SkeletonLine />
                  </Flex>
                  <Flex height={'20px'} width='70%'>
                     <SkeletonLine />
                  </Flex>
               </Flex>
            )
         })}

      </Card>
   )
}