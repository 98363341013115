/** @format */

import debounce from "debounce";
import { useEffect, useState } from "react";

const getDeviceConfig = (width: number) => {
  if (width < 320) {
    return "xs";
  } else if (width >= 320 && width < 720) {
    return "sm";
  } else if (width >= 720 && width < 1200) {
    return "md";
  } else if (width >= 1200 && width < 1400) {
    return "lg";
  } else if (width > 1400) {
    return "xl";
  }
};

const useBreakpoints = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoints;
