import Svg from "../../UI/Svg";
type Props = {
  iconSize?: string;
  fill?: string;
  stroke?: string;
};
const Minimize = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M5.51017 23V18.0714H0.815186V14.7857H8.64017V23H5.51017ZM14.9002 23V14.7857H22.7251V18.0714H18.0301V23H14.9002ZM0.815186 8.21429V4.92857H5.51017V0H8.64017V8.21429H0.815186ZM14.9002 8.21429V0H18.0301V4.92857H22.7251V8.21429H14.9002Z"
    />
  </Svg>
);
export default Minimize;
