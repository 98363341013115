/** @format */

import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { Nina } from "../../../../../shared/Ocpp/nina";
import { NinaState } from "../../../../../shared/Ocpp/nina.ocpp.types";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  Flex,
  InputLabel,
  Typography,
} from "../../../../../shared/UI";
import { useGetTokens } from "../../../gql/queries/useGetTokens";
import { ChargePoint } from "./ChargePoint";
import { Connector } from "./Connector";
import { Logs } from "./Logs";
import { useBlockerDialog } from "./useBlockerDialog";
import { t } from "i18next";
import { Settings } from "./Settings";
import { useTypedSelector } from "../../../../../shared/redux/store";
import { useCloseOnClickAway } from "../../../../../shared/UI/helpers/hookHelper";
import { Token } from "../../../../../shared/gql/generated/graphql";

export const Simulator = () => {
  const [nina, setNina] = useState<Nina>();
  const [ninaState, setNinaState] = useState<NinaState>();
  const [showLogsBox, setShowLogsBox] = useState<boolean>(false);
  const [showSettings, setShowSetting] = useState<boolean>(false);
  const {
    defaultRFID,
    customMeterValue,
    sendStatusNotificationToConnector0,
    sendStatusNotificationToOtherConnectors,
  } = useTypedSelector((state) => state.simulator);
  const toggleLogs = () => {
    setShowLogsBox((prev) => !prev);
  };
  const toggleSettings = () => {
    setShowSetting((prev) => !prev);
  };
  const { control, handleSubmit, reset } = useForm();

  const { getTokens } = useGetTokens();

  const passRFID = (formData: any) => {
    console.log(`passRFID`);
    const idTag = formData?.idTag?.value;
    nina?.authorizeRFID(idTag);
    return;
  };
  const { evc }: any = useOutletContext();
  const defaultOptions = useMemo(
    () => [
      {
        label: [
          <Typography fontWeight={"bold"} variant={"title20"}>
            {defaultRFID?.label}
          </Typography>,
          <Typography variant={"body10"}>
            {defaultRFID?.PlatformParty?.name}
          </Typography>,
          <Typography variant={"caption10"}>{defaultRFID?.origin}</Typography>,
        ],
        value: defaultRFID?.label,
      },
    ],
    [defaultRFID]
  );
  useEffect(() => {
    if (defaultRFID) {
      reset({
        idTag: {
          label: [
            <Typography fontWeight={"bold"} variant={"title20"}>
              {defaultRFID?.label}
            </Typography>,
            <Typography variant={"body10"}>
              {defaultRFID?.PlatformParty?.name}
            </Typography>,
            <Typography variant={"caption10"}>
              {defaultRFID?.origin}
            </Typography>,
          ],
          value: defaultRFID?.label,
        },
      });
    }
  }, [defaultRFID, reset]);

  const loadOptions = (inputValue: string) => {
    if (inputValue.length > 3) {
      return new Promise<Token[]>((resolve) => {
        getTokens({
          variables: {
            pagination: {
              limit: 10,
              page: 1,
            },
            filter: {
              text: inputValue,
            },
          },
        }).then((res) => {
          const options = res?.data?.Tokens?.results?.map((token: any) => ({
            label: [
              <Typography fontWeight={"bold"} variant={"title20"}>
                {token?.label}
              </Typography>,
              <Typography variant={"body10"}>
                {token?.PlatformParty?.name}
              </Typography>,
              <Typography variant={"caption10"}>{token?.origin}</Typography>,
            ],
            value: token?.label,
          }));
          resolve(options);
        });
      });
    }
  };

  const { blocker, isOpen, setIsOpen } = useBlockerDialog(
    ninaState?.wsStatus === "OPEN"
  );
  const confirmClose = async () => {
    await nina?.disconnect();
    blocker?.proceed?.();
    blocker?.reset?.();
  };
  const cancelClose = () => {
    blocker?.reset?.();
    setIsOpen(false);
  };
  const settingsRef = useRef<HTMLDivElement>(null);
  useCloseOnClickAway(settingsRef, () => setShowSetting(false));

  return (
    <Flex fullSize flexWrap={"wrap"} p={4} position={"relative"}>
      {isOpen && (
        <Dialog
          height={"190px"}
          width={"500px"}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeOnPressEscape={false}
          closeOnClickAway={false}
        >
          <DialogBody>
            <Flex flex={1} mt={50} p={2}>
              <Typography variant={"title20"}>
                {t(
                  "The simulator is still connected. Do you want to end the Connection? "
                )}
              </Typography>
            </Flex>

            <DialogFooter style={{ gap: 10, padding: 10 }}>
              <Button $size="default" width="120px" onClick={confirmClose}>
                Yes
              </Button>
              <Button
                variant="light"
                $size="default"
                width="120px"
                onClick={cancelClose}
              >
                No
              </Button>
            </DialogFooter>
          </DialogBody>
        </Dialog>
      )}
      <Flex fullSize style={{ gap: 10 }}>
        <ChargePoint
          nina={nina}
          setNina={setNina}
          ninaState={ninaState}
          setNinaState={setNinaState}
          evcLabel={evc?.label}
          numberOfConnectors={evc?.connectors}
          toggleLogs={toggleLogs}
          toggleSettings={toggleSettings}
        />
        {ninaState?.wsStatus === "OPEN" ? (
          <form onSubmit={handleSubmit(passRFID)}>
            <Card
              justifyContent={"center"}
              display={"flex"}
              style={{ gap: 10 }}
              bg="white"
              p={3}
              alignItems={"center"}
              flexDirection={"row"}
            >
              <Box maxWidth={600} flex={1}>
                <InputLabel children="RFID" />
                <Flex fullSize flexDirection={"row"} style={{ gap: 10 }}>
                  <Flex flex={1}>
                    <Controller
                      name="idTag"
                      control={control}
                      render={({ field }) => (
                        <AsyncSelect
                          styles={{
                            option: (styles) => {
                              return {
                                ...styles,
                                flexDirection: "column",
                                display: "flex",
                                gap: 5,
                              };
                            },
                            singleValue: (styles) => {
                              return {
                                ...styles,
                                flexDirection: "column",
                                display: "flex",
                                flexShrink: 0,
                                gap: 2,
                              };
                            },
                            placeholder: (styles) => {
                              return {
                                ...styles,
                                flexDirection: "column",
                                display: "flex",
                                flexShrink: 0,
                                gap: 2,
                              };
                            },
                          }}
                          menuPosition="fixed"
                          isClearable
                          cacheOptions
                          loadOptions={loadOptions}
                          hideSelectedOptions={false}
                          defaultOptions={defaultRFID ? defaultOptions : []}
                          placeholder={"Select Token (min 4 characters) "}
                          escapeClearsValue={false}
                          {...field}
                        />
                      )}
                    />
                  </Flex>
                  <Button
                    $fill={false}
                    width={100}
                    $size="default"
                    variant="gray"
                    onClick={handleSubmit(passRFID)}
                  >
                    Pass
                  </Button>
                </Flex>
              </Box>
            </Card>
          </form>
        ) : null}
        <Flex
          pb={1}
          flex={1}
          flexDirection={"row"}
          style={{ gap: "20px 20px " }}
        >
          {ninaState && ninaState.wsStatus === `OPEN`
            ? Object.values(ninaState.EVSEs).map((evse: any, i: number) => {
                return <Connector id={i} key={i} nina={nina} evse={evse} />;
              })
            : null}
        </Flex>

        {showLogsBox ? (
          <Flex
            flexShrink={0}
            width={"100%"}
            height={300}
            overflow={"hidden"}
            minWidth={300}
          >
            <Logs
              setShowLogsBox={setShowLogsBox}
              clearLog={() => nina?.clearLogs()}
              logs={ninaState?.logs}
            />
          </Flex>
        ) : null}
      </Flex>
      {showSettings ? (
        <Settings
          ninaStatus={ninaState?.wsStatus || undefined}
          defaultRFID={defaultRFID}
          customMeterValue={customMeterValue}
          sendStatusNotificationToConnector0={
            sendStatusNotificationToConnector0
          }
          sendStatusNotificationToOtherConnectors={
            sendStatusNotificationToOtherConnectors
          }
          setShowSettings={setShowSetting}
        />
      ) : null}
    </Flex>
  );
};
