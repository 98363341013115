import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconBusiness: React.FC<Props> = ({
  fill,
  size,
  ...props
}) => {
  return (
    <Svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M5.34492 27.7253V15.6685H8.78971V27.7253H5.34492ZM15.6793 27.7253V15.6685H19.1241V27.7253H15.6793ZM0.177734 34.6149V31.1701H34.6256V34.6149H0.177734ZM26.0137 27.7253V15.6685H29.4585V27.7253H26.0137ZM0.177734 12.2238V8.77897L17.4017 0.166992L34.6256 8.77897V12.2238H0.177734ZM7.84239 8.77897H26.961L17.4017 4.04238L7.84239 8.77897Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
