import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconTollBoth: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      width="40"
      height="36"
      viewBox="0 0 40 36"
      iconSize={size}
      fill="transparent"
      stroke="#3D729C"
      {...props}
    >
      <path
        d="M9.60654 33.0073L12.4021 2.05069H24.9289L27.7244 33.0073H9.60654Z"
        stroke={fill ?? "#3D729C"}
        stroke-opacity="0.99"
        strokeWidth="3.95685"
        fill="transparent"
      />
      <path
        d="M5.04492 13.4209H32.1592"
        stroke={fill ?? "#3D729C"}
        strokeWidth="2.32756"
        stroke-linecap="round"
        fill="transparent"
      />
      <path
        d="M5.04492 16.502H32.1592"
        stroke={fill ?? "#3D729C"}
        strokeWidth="2.32756"
        stroke-linecap="round"
        fill="transparent"
      />
      <path
        d="M29.2578 16.502L35.3112 22.1497"
        stroke={fill ?? "#3D729C"}
        strokeWidth="2.32756"
        stroke-linecap="round"
        fill="transparent"
      />
      <path
        d="M7.81962 16.6309L2.14453 22.1503"
        stroke={fill ?? "#3D729C"}
        strokeWidth="2.32756"
        stroke-linecap="round"
        fill="transparent"
      />
      <line
        x1="18.996"
        y1="3.40918"
        x2="18.996"
        y2="7.77336"
        stroke={fill ?? "#3D729C"}
        strokeWidth="1.16378"
      />
      <line
        x1="18.996"
        y1="9.31445"
        x2="18.996"
        y2="13.6786"
        stroke={fill ?? "#3D729C"}
        strokeWidth="1.16378"
      />
      <line
        opacity="0.5"
        x1="18.996"
        y1="16.7588"
        x2="18.996"
        y2="18.8125"
        stroke={fill ?? "#3D729C"}
        strokeWidth="1.16378"
      />
      <line
        x1="18.996"
        y1="20.8662"
        x2="18.996"
        y2="25.2304"
        stroke={fill ?? "#3D729C"}
        strokeWidth="1.16378"
      />
      <line
        x1="18.996"
        y1="26.7705"
        x2="18.996"
        y2="31.1347"
        stroke={fill ?? "#3D729C"}
        strokeWidth="1.16378"
      />
    </Svg>
  );
};
