/** @format */

import { Roles } from "@bepower/greta-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  Activity,
  CSOIcon,
  Evc,
  EvcCableCharge,
  Group,
  Infrastructure,
  Location,
  Party,
  Platform,
  Profile,
  Sdr,
  Session,
  Setting,
  Tariff,
} from "../assets/svg";
import { List } from "../UI";
import MenuItems from "../UI/Menu/MenuItems";
import RenderOnRole from "../UI/RenderOnRole";

const menuItems: {
  label: string;
  path: string;
  roles: string[];
  icon: React.ReactNode;
  id: number;
  submenu?: {
    label: string;
    path: string;
    roles: string[];
    icon: React.ReactNode;
  }[];
}[] = [
  {
    label: `Platforms`,
    path: "/platforms",
    roles: [Roles.Platform],
    icon: <Party size="5" />,
    id: 1,
  },
  {
    label: `Interoperability`,
    path: "/interoperability",
    roles: [Roles.PlatformInterop],
    icon: <Platform size="5" />,
    id: 1,
  },
  {
    label: `Sales`,
    path: "/sales",
    roles: [Roles.HostGroup],
    icon: <CSOIcon size="5" />,
    id: 2,
  },
  {
    label: `Tariffs`,
    path: "/tariffs",
    roles: [Roles.Prices],
    icon: <Tariff size="5" />,
    id: 3,
  },

  {
    label: "Infrastructure",
    path: "",
    roles: [Roles.Infrastructure],
    icon: <Infrastructure size="5" />,
    id: 4,
    submenu: [
      {
        label: "Locations",
        path: "/locations",
        roles: [Roles.Infrastructure],
        icon: <Location size="5" />,
      },
      {
        label: "EVCs",
        path: "/evcs",
        roles: [Roles.Infrastructure],
        icon: <Evc size="5" />,
      },
      {
        label: "EVSEs",
        path: "/evses",
        roles: [Roles.Infrastructure],
        icon: <EvcCableCharge size="5" />,
      },
      {
        label: "Access Groups",
        path: "/access-groups",
        roles: [Roles.Infrastructure, Roles.AccessGroupAssociation],
        icon: <Group size="5" />,
      },
    ],
  },
  {
    label: "Session",
    path: "",
    roles: [Roles.Session],
    icon: <Session size="5" />,
    id: 6,
    submenu: [
      {
        label: "Sessions",
        path: "/sessions",
        roles: [Roles.Session],
        icon: <Session size="5" />,
      },
      {
        label: "Activities",
        path: "/activities",
        roles: [Roles.Session],
        icon: <Activity size="5" />,
      },
      {
        label: "SDRs",
        path: "/sdrs",
        roles: [Roles.Session],
        icon: <Sdr size="5" />,
      },
    ],
  },
  {
    label: "Site Selection",
    path: "/site-selection",
    roles: [Roles.SiteSelection],
    icon: <Party size="5" />,
    id: 8,
  },
  {
    label: `Settings`,
    path: "/settings",
    roles: [],
    icon: <Setting size="5" />,
    id: 7,
    submenu: [
      {
        label: "Profile",
        path: "/profile",
        roles: [],
        icon: <Profile size="5" />,
      },
      {
        label: "User preferences",
        path: "/preferences",
        roles: [Roles.Platform],
        icon: <Profile size="5" />,
      },
    ],
  },
];
export let openMap: { [name: string]: boolean } = {
  sub1: false,
  sub2: false,
  sub3: false,
  sub4: false,
  sub5: false,
  sub6: false,
};

export function openHandler(value: string): void {
  openMap[value] = !openMap[value];
  for (const key in openMap) {
    if (key !== value) {
      openMap[key] = false;
    }
  }
}

export const Menu: React.FC<{ local: boolean }> = ({ local }) => {
  const { showDrawer } = useSelector((state: any) => state.layout);

  const onChange = (value: string) => {
    openHandler(value);
  };
  return (
    <List>
      {menuItems.map((item, index) => {
        const depthLevel = 0;
        return (
          <RenderOnRole roles={item.roles} key={index}>
            <MenuItems
              isOpen={showDrawer}
              item={item}
              key={index}
              id={item?.id}
              depthLevel={depthLevel}
              onClick={onChange}
            />
          </RenderOnRole>
        );
      })}
    </List>
  );
};
