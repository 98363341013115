import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};

const Activity: React.FC<Props> = ({ size, fill, ...props }) => {
  return (
    <Svg
      iconSize={size}
      fill={fill}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12,1A11,11,0,1,0,23,12,11.013,11.013,0,0,0,12,1Zm0,2a9.01,9.01,0,0,1,8.941,8H16a1,1,0,0,0-.929.629L14,14.307,10.929,6.629a1,1,0,0,0-1.857,0L7.323,11H3.059A9.01,9.01,0,0,1,12,3Zm0,18a9.01,9.01,0,0,1-8.941-8H8a1,1,0,0,0,.928-.629L10,9.692l3.071,7.679a1,1,0,0,0,1.858,0L16.677,13h4.264A9.01,9.01,0,0,1,12,21Z" />
    </Svg>
  );
};

export default Activity;
