import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconAccommodation: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      width="44"
      height="36"
      viewBox="0 0 44 36"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M0.59375 35.0963V13.5664L13.5117 0.648438L26.4297 13.5664V35.0963H0.59375ZM4.89974 30.7904H11.3587V24.3314H15.6647V30.7904H22.1237V15.3426L13.5117 6.73065L4.89974 15.3426V30.7904ZM11.3587 20.0254V15.7194H15.6647V20.0254H11.3587ZM30.7357 35.0963V11.7902L19.5939 0.648438H25.6761L35.0417 10.014V35.0963H30.7357ZM39.3476 35.0963V8.23774L31.7583 0.648438H37.8405L43.6536 6.46152V35.0963H39.3476Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
