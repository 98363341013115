import { SVGProps } from "react";
import AC from "./AC";
import DC from "./DC";
import { ResizableNumberBadgeIcon } from "./ResizableNumberBadgeIcon";
const MarkerLocationBasic = ({
  cpTot = 0,
  evcModel,
  ...props
}: SVGProps<SVGSVGElement> & { cpTot?: number; evcModel?: "AC" | "DC" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={32.4}
    viewBox="-3 -5 31 32"
    fill="none"
    {...props}
  >
    {/* <rect
      width={30}
      height={32}
      x={-3}
      y={-5}
      rx={3}
      fill="#fff"
      stroke="#97969D"
      strokeWidth={0.5}
    /> */}

    <g filter="url(#a)">
      <path
        fill={props.fill ?? "#B2BBC4"}
        d="M16.662 1.75H8.629a4.745 4.745 0 0 0-4.743 4.744v7.937a4.745 4.745 0 0 0 4.743 4.744h2.353l1.303 1.505a.39.39 0 0 0 .595 0l1.302-1.505h2.48a4.745 4.745 0 0 0 4.744-4.744V6.494a4.745 4.745 0 0 0-4.744-4.744Z"
      />
      <path
        fill={props.stroke ?? "#97969D"}
        d="m10.982 19.276.607-.525-.24-.278h-.367v.803Zm3.245 0v-.803h-.367l-.24.278.607.525ZM4.6 6.342a4.004 4.004 0 0 1 4.004-4.004v-1.6A5.606 5.606 0 0 0 3 6.342h1.6Zm0 8.128V6.341H3v8.134h1.6v-.007Zm4.004 4.003A4.004 4.004 0 0 1 4.6 14.47H3a5.606 5.606 0 0 0 5.604 5.603v-1.6Zm2.378 0H8.604v1.6h2.378v-1.6Zm1.923 1.803-1.322-1.525-1.208 1.05 1.322 1.525 1.208-1.05Zm-.607 0a.404.404 0 0 1 .607 0l-1.208 1.05c.48.55 1.334.55 1.815 0l-1.214-1.05Zm1.322-1.525-1.322 1.525 1.208 1.05 1.322-1.525-1.208-1.05Zm3.111-.278h-2.504v1.6h2.504v-1.6Zm4.004-4.003a4.004 4.004 0 0 1-4.004 4.003v1.6a5.606 5.606 0 0 0 5.604-5.604h-1.6Zm0-8.128v8.134h1.6V6.342h-1.6Zm-4.004-4.004a4.004 4.004 0 0 1 4.004 4.004h1.6A5.606 5.606 0 0 0 16.731.738v1.6Zm-8.127 0h8.134v-1.6H8.604v1.6Z"
      />
    </g>

    <defs>
      <filter
        id="a"
        width={30}
        height={26.04}
        x={0.48}
        y={0.738}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2.52} />
        <feGaussianBlur stdDeviation={1.26} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2895_2194"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2895_2194"
          result="shape"
        />
      </filter>
    </defs>
    {cpTot > 0 && (
      <g transform="translate(16,10)">
        <ResizableNumberBadgeIcon number={cpTot} />
      </g>
    )}

    {evcModel === "AC" && (
      <g transform="translate(0,-3) scale(0.5)">
        <AC />
      </g>
    )}

    {evcModel === "DC" && (
      <g transform="translate(0,-3) scale(0.5)">
        <DC />
      </g>
    )}
  </svg>
);
export default MarkerLocationBasic;
