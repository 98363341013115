import { t } from "i18next";
import React, { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Flex, Typography } from "../../../../shared/UI";
import { colors } from "../../../../shared/UI/theme/colors";
import { Close, DownloadThick } from "../../../../shared/assets/svg";
import Delete from "../../../../shared/assets/svg/Delete";
import { Project } from "../../@types/project";
import { useProject } from "../../hooks/useProject";
import { useProjects } from "../../hooks/useProjects";
import { useSiteSelectionContext } from "../../slices/ContextProvider";
import { downloadLocations } from "../../utils/downloadLocations";
import { CustomSelect } from "./CustomSelect";
import { DeleteProjectsDialog } from "./dialogs/DeleteProjectDialog";
import { RenameProjectDialog } from "./dialogs/RenameProjectDialog";

const StyledFlex = styled(Flex)`
  # padding: 4px 0px 4px 40px;
  cursor: pointer;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  :hover {
    background: #48bbfa;
    color: #fff;
  }

  .sub-menu {
    position: relative;
  }

  .sub-menu-1 {
    display: none;
  }

  .sub-menu:hover + .sub-menu-1 {
    display: block;
  }
`;

const StyledSubMenu = styled(Flex)``;

const NameComponent = ({ project }: { project: Project }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isDeleteProjectOpen, setIsDeleteProjectOpen] = useState(false);
  const { projects, deleteProjects } = useProjects({
    limit: 4,
  });
  const { updateAccessTypeAndVisibility, deleteAllLocations } = useProject(
    project.id
  );

  const SubMenu = ({
    children,
    className,
  }: PropsWithChildren<{
    className?: string;
    type?: "sub-menu";
  }>) => (
    <StyledSubMenu
      bg={"#F00"}
      borderRadius="8px"
      border="1px solid #3D729C"
      padding={"10px 0px"}
      background="#FFF"
      boxShadow="0px 2px 7px 0px rgba(0, 0, 0, 0.25)"
      zIndex={1000}
      onClick={(e) => e.stopPropagation()}
      className={className}
      style={{
        textOverflow: "unset",
        whiteSpace: "nowrap",
        width: "100%",
      }}
      id="sub-menu"
    >
      {children}
    </StyledSubMenu>
  );

  const MenuItem = ({
    children,
    onClick,
    className,
    padding,
    submenuRightPosition,
  }: {
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
    padding?: string;
    submenuRightPosition?: string;
  }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const ch =
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.props.type === "sub-menu") {
            return {
              type: "SubMenu",
              child,
            };
          }
        }
        return {
          type: "MenuItem",
          child,
        };
      }) ?? [];

    const countSubMenu = ch.filter((c) => c.type === "SubMenu").length;

    if (countSubMenu > 1) {
      throw new Error("Only one sub-menu is allowed");
    }

    const nonSubMenu = ch
      .filter((c) => c.type === "MenuItem")
      .map((c) => c.child);
    const subMenu = ch
      .filter((c) => c.type === "SubMenu")
      .map((c) => c.child)[0];

    return (
      <StyledFlex
        onMouseEnter={() => {
          setMenuOpen(true);
        }}
        onMouseLeave={() => {
          setMenuOpen(false);
        }}
        position={"relative"}
        className={className}
        onClick={onClick}
        padding={padding}
        color="#122967"
      >
        <Flex flexGrow={1}>
          {nonSubMenu}

          {countSubMenu > 0 && (
            <Flex position={"absolute"} right={submenuRightPosition ?? "25px"}>
              {">"}
            </Flex>
          )}
        </Flex>
        {menuOpen && (
          <Flex position={"absolute"} left={"95%"}>
            {subMenu}
          </Flex>
        )}
      </StyledFlex>
    );
  };

  const MenuHeader = ({ children }: { children: React.ReactNode }) => (
    <Flex
      paddingLeft="35px"
      style={{
        cursor: "pointer",
        fontWeight: "bold",
      }}
    >
      <Typography color="#122967" fontWeight={700} fontSize={14}>
        {children}
      </Typography>
    </Flex>
  );

  return (
    <Flex
      style={{
        textOverflow: "ellipsis",
      }}
      flexDirection="row"
    >
      <div>{project.name}</div>
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        style={{
          marginLeft: "10px",
          cursor: "pointer",
          position: "relative",
          userSelect: "none",
        }}
      >
        <div
          style={{
            transform: "rotate(90deg)",
            cursor: "pointer",
          }}
        >
          {">"}
        </div>

        {menuOpen && (
          <>
            <Flex
              position="fixed"
              top={0}
              left={0}
              width={"100%"}
              height={"100%"}
              background={"rgb(0,0,0,0)"}
              zIndex={999}
            />

            <div
              style={{
                transform: "rotate(45deg)",
                top: "24px",
                height: "12px",
                width: "12px",
                zIndex: 1001,
                background: "#FFF",
                position: "absolute",
                borderLeft: "1px solid #3D729C",
                borderTop: "1px solid #3D729C",
              }}
            />
            <Flex
              position="absolute"
              top={"100%"}
              minHeight={100}
              minWidth={250}
              bg={"#F00"}
              style={{
                transform: "translateX(-50%)",
              }}
              borderRadius="8px"
              border="1px solid #3D729C"
              background="#FFF"
              boxShadow="0px 2px 7px 0px rgba(0, 0, 0, 0.25)"
              zIndex={1000}
              paddingTop={"30px"}
              paddingBottom={"10px"}
              onClick={(e) => e.stopPropagation()}
            >
              <MenuHeader> {t("file")}</MenuHeader>
              <MenuItem
                padding="4px 0px 4px 40px"
                onClick={() => {
                  setIsDeleteProjectOpen(true);
                  setMenuOpen(false);
                }}
              >
                {t("delete")}
              </MenuItem>

              <MenuItem
                padding="4px 0px 4px 40px"
                onClick={() => {
                  setIsRenameOpen(true);
                  setMenuOpen(false);
                }}
              >
                {t("rename")}
              </MenuItem>
              <MenuItem
                padding="4px 0px 4px 40px"
                onClick={() => {
                  navigate(`/site-selection`);
                  setMenuOpen(false);
                }}
              >
                {t("close")}
              </MenuItem>
              <div
                style={{
                  position: "relative",
                }}
              >
                <MenuItem padding="4px 0px 4px 40px" className="sub-menu">
                  {t("siteSelection.general.accessType")}
                  <SubMenu type="sub-menu" className="sub-menu-1">
                    <MenuItem
                      padding="4px 30px 4px 10px"
                      submenuRightPosition="10px"
                    >
                      <div
                        style={{
                          color: project.accessType === "public" ? "blue" : "",
                        }}
                      >
                        {t(
                          "siteSelection.general.data.project.accessType.public"
                        )}
                      </div>

                      <SubMenu type="sub-menu" className="sub-menu-1">
                        <MenuItem
                          padding="4px 10px 4px 10px"
                          onClick={() => {
                            updateAccessTypeAndVisibility("public", "public");
                          }}
                        >
                          <div
                            style={{
                              color:
                                project.accessType === "public" &&
                                project.visibility === "public"
                                  ? "blue"
                                  : "",
                            }}
                          >
                            {t(
                              "siteSelection.general.data.project.visibility.public"
                            )}
                          </div>
                        </MenuItem>
                        <MenuItem
                          padding="4px 10px 4px 10px"
                          onClick={() => {
                            updateAccessTypeAndVisibility("public", "private");
                          }}
                        >
                          <div
                            style={{
                              color:
                                project.accessType === "public" &&
                                project.visibility === "private"
                                  ? "blue"
                                  : "",
                            }}
                          >
                            {t(
                              "siteSelection.general.data.project.visibility.private"
                            )}
                          </div>
                        </MenuItem>
                      </SubMenu>
                    </MenuItem>
                    <MenuItem
                      padding="4px 30px 4px 10px"
                      submenuRightPosition="10px"
                    >
                      <div
                        style={{
                          color: project.accessType === "private" ? "blue" : "",
                        }}
                      >
                        {t(
                          "siteSelection.general.data.project.accessType.private"
                        )}
                      </div>
                      <SubMenu type="sub-menu" className="sub-menu-1">
                        <MenuItem
                          padding="4px 10px 4px 10px"
                          onClick={() => {
                            updateAccessTypeAndVisibility("private", "public");
                          }}
                        >
                          <div
                            style={{
                              color:
                                project.accessType === "private" &&
                                project.visibility === "public"
                                  ? "blue"
                                  : "",
                            }}
                          >
                            {t(
                              "siteSelection.general.data.project.visibility.public"
                            )}
                          </div>
                        </MenuItem>
                        <MenuItem
                          padding="4px 10px 4px 10px"
                          onClick={() => {
                            updateAccessTypeAndVisibility("private", "private");
                          }}
                        >
                          <div
                            style={{
                              color:
                                project.accessType === "private" &&
                                project.visibility === "private"
                                  ? "blue"
                                  : "",
                            }}
                          >
                            {t(
                              "siteSelection.general.data.project.visibility.private"
                            )}
                          </div>
                        </MenuItem>
                      </SubMenu>
                    </MenuItem>
                  </SubMenu>
                </MenuItem>
              </div>
              <hr
                style={{
                  height: "1px",
                  border: "none",
                  margin: "20px 35px 20px 35px",
                  background: "#000",
                }}
              />
              <MenuHeader>{t("siteSelection.general.openRecent")}</MenuHeader>
              {projects?.map((project) => (
                <MenuItem
                  key={project.id}
                  padding="4px 0px 4px 40px"
                  onClick={() => {
                    navigate(`/site-selection/project/${project.id}`);
                    setMenuOpen(false);
                  }}
                >
                  {project.name}
                </MenuItem>
              ))}
            </Flex>
          </>
        )}
      </div>

      <RenameProjectDialog
        projectId={project.id}
        isOpen={isRenameOpen}
        setIsOpen={setIsRenameOpen}
      />

      <DeleteProjectsDialog
        ids={[project.id]}
        isOpen={isDeleteProjectOpen}
        setIsOpen={setIsDeleteProjectOpen}
        onDelete={async () => {
          if (project) {
            return deleteAllLocations().then(() =>
              deleteProjects([project?.id]).then(() => {
                navigate(`/site-selection`);
              })
            );
          }
          return Promise.resolve();
        }}
      />
    </Flex>
  );
};

const optionViews = [
  t("siteSelection.dashboard.toolbar.simpleMode"),
  t("siteSelection.dashboard.toolbar.optimizedMode"),
];

export const ActionBar: React.FC<{
  projectId?: string | null;
}> = ({ projectId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { project, deleteAllLocations } = useProject(projectId ?? null);
  const [isDeleteProjectOpen, setIsDeleteProjectOpen] = useState(false);
  const { deleteProjects } = useProjects({
    loadData: false,
  });

  const { setMode } = useSiteSelectionContext();

  const handleOptionSelected = (option: string) => {
    if (option === t("siteSelection.dashboard.toolbar.simpleMode")) {
      setMode("simple");
    } else if (option === t("siteSelection.dashboard.toolbar.optimizedMode")) {
      setMode("optimized");
    }
  };

  const disabled = !project;
  const variant = disabled ? "secondary" : "primary";

  return (
    <Flex
      position="relative"
      flexDirection="row"
      borderRadius=" 0px 0px 10px 10px"
      padding="20px"
      display="flex"
      justifyContent="space-around"
      alignContent="center"
      alignItems="center"
      background="#FFF"
      boxShadow="0px 3.17842px 3.17842px 0px rgba(0, 0, 0, 0.25)"
    >
      <Flex
        position={"absolute"}
        left={20}
        flexDirection="row"
        justifyContent="space-around"
      >
        <Button
          disabled={
            disabled ||
            !project?.locations ||
            project?.locations.filter((l) => l.validated === true).length === 0
          }
          width={48}
          height={48}
          variant={variant}
          padding={0}
          onClick={() => {
            if (
              !project ||
              project?.locations.filter((l) => l.validated === true).length ===
                0
            )
              return;

            downloadLocations(project);
          }}
        >
          <DownloadThick size="3em" />
        </Button>
      </Flex>
      <Flex
        flexGrow={1}
        alignItems={"center"}
        marginRight={"350px"}
        marginLeft={"50px"}
        style={{
          fontFamily: "Rubik",
          fontSize: "29px",
          fontStyle: "normal",
          fontWeight: "300",
          lineHeight: "30px",
          color: "#122967",
          width: "100%",
        }}
      >
        {!project && t("siteSelection.freeRide.toolbar.actionBar")}
        {project && <NameComponent project={project} />}
      </Flex>
      <Flex
        style={{ gap: 5 }}
        flexGrow={0}
        flexDirection={"row"}
        alignItems={"center"}
        position={"absolute"}
        right={"20px"}
      >
        <Flex
          flexGrow={0}
          flexDirection={"row"}
          alignItems={"center"}
          style={{ gap: 5 }}
          color={colors.primary}
          marginRight={"20px"}
        >
          {t("siteSelection.dashboard.toolbar.mode")}
          <CustomSelect
            options={optionViews}
            defaultOption={optionViews[0]}
            onOptionSelected={handleOptionSelected}
            style={{ color: colors.primary }}
          />
        </Flex>
        <Button
          disabled={disabled}
          width={"26px"}
          height={"26px"}
          variant={variant}
          padding={0}
          onClick={() => {
            setIsDeleteProjectOpen(true);
          }}
        >
          <Delete />
        </Button>
        <Button
          width={"26px"}
          height={"26px"}
          variant="primary"
          padding={0}
          onClick={() => {
            navigate(`/site-selection`);
          }}
        >
          <Close size="20px" />
        </Button>
      </Flex>

      {project && (
        <DeleteProjectsDialog
          ids={[project.id]}
          isOpen={isDeleteProjectOpen}
          setIsOpen={setIsDeleteProjectOpen}
          onDelete={async () => {
            if (project) {
              return deleteAllLocations().then(() =>
                deleteProjects([project?.id]).then(() => {
                  navigate(`/site-selection`);
                })
              );
            }
            return Promise.resolve();
          }}
        />
      )}
    </Flex>
  );
};
