import { format } from "date-fns";
import { t } from "i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  LoaderWithFallback,
  SimpleLoader,
  Typography,
} from "../../../../shared/UI";
import { useGetSdrs } from "../../../../shared/gql/queries/useGetSdrs";
import { Flex } from "./../../../../shared/UI/Flex";

export const SessionSdr = () => {
  const { uid } = useParams();

  const { SDRs, getSDRs, loading } = useGetSdrs();

  useEffect(() => {
    if (uid) {
      getSDRs({
        variables: {
          filter: {
            sessionUid: uid,
          },
        },
      });
    }
  }, [getSDRs, uid]);

  return (
    <LoaderWithFallback
      loading={loading}
      LoadingMsg={<SimpleLoader />}
      errorMsg={"error"}
      hasError={!SDRs}
    >
      <Flex fullSize bg="#f5f5f5">
        {SDRs?.map((sdr, index: number) => {
          return (
            <Flex
              flexWrap={"wrap"}
              flexDirection={"row"}
              p={3}
              style={{ gap: "20px 20px" }}
              key={index}
            >
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}> {t("create")}</Typography>
                <Typography pt={2}>
                  {sdr?.createdAt
                    ? format(sdr?.createdAt, "dd/MM/yyyy hh:mm:ss aa")
                    : "---"}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}> {t("total")}</Typography>
                <Typography pt={2}>
                  {sdr?.totalCost}
                  {sdr?.currency}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}> kWh</Typography>
                <Typography pt={2}>
                  {sdr?.activities[0]?.consumptions?.energy}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>
                  {t("Activity type")}
                </Typography>
                <Typography pt={2}>{sdr?.activities[0]?.type}</Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>
                  {t("session start")}
                </Typography>
                <Typography pt={2}>
                  {sdr?.sessionStart
                    ? format(sdr?.sessionStart, "dd/MM/yyyy hh:mm:ss aa")
                    : "---"}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t("session end")}</Typography>
                <Typography pt={2}>
                  {sdr?.sessionStop
                    ? format(sdr?.sessionStop, "dd/MM/yyyy hh:mm:ss aa")
                    : "---"}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t("parking time")}</Typography>
                <Typography pt={2}>
                  {sdr?.activities[0]?.consumptions?.parkingTime}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t("time")}</Typography>
                <Typography pt={2}>{sdr?.totalTime}</Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t("tariff")}</Typography>
                <Typography pt={2}>
                  {sdr?.tariff.tariffAltText?.[0]?.text || "---"}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t("penalty")}</Typography>
                <Typography pt={2}>---</Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t("invalid")}</Typography>
                <Typography pt={2}>{sdr?.invalid ? "Yes" : "No"}</Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t("energy")}</Typography>
                <Typography pt={2}>
                  {sdr?.activities?.[0]?.costs?.energy} €
                </Typography>
              </Card>
            </Flex>
          );
        })}
      </Flex>
    </LoaderWithFallback>
  );
};
