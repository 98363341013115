/** @format */

import { gql, useLazyQuery } from "@apollo/client";
import debounce from "debounce";
import { useCallback, useState } from "react";
import { AttractionType } from "../locations/components/map-components/PoiFilter";
import { POI } from "../slices/EvcDataContextProvider";

const GET_SITE_SELECTION_HEXAGONS = gql`
  query SiteSelectionPois(
    $filter: SiteSelectionPoiFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    SiteSelectionPois(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        id
        category
        name
        type
        point {
          lat
          lon
        }
      }
      total
      pagination {
        cursor
        last
      }
    }
  }
`;

export const useGetSiteSelectionPois = () => {
  const [
    getSiteSelectionPois,
    { loading, error, data, refetch, fetchMore, startPolling },
  ] = useLazyQuery<{
    SiteSelectionPois: {
      results: {
        id: string;
        category: string;
        name: string;
        type: string;
        point: {
          lat: number;
          lon: number;
        };
      }[];
      total: number;
      pagination: {
        cursor: string;
        last: boolean;
      };
    };
  }>(GET_SITE_SELECTION_HEXAGONS, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    returnPartialData: false,
  });

  const [loadingAll, setLoadingAll] = useState(false);
  const [poiData, setPoiData] = useState<POI[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAll = useCallback(
    debounce(async (bound: google.maps.LatLngBounds, category?: string) => {
      const pageLimit = 1000;

      const variables = {
        filter: {
          category,
          bound: {
            top_left: {
              lat: bound.getNorthEast().lat(),
              lon: bound.getSouthWest().lng(),
            },
            bottom_right: {
              lat: bound.getSouthWest().lat(),
              lon: bound.getNorthEast().lng(),
            },
          },
        },
        sort: {
          field: "id",
        },
        pagination: {
          limit: pageLimit,
        },
      };

      const data = await getSiteSelectionPois({
        variables: variables,
      });
      const { pagination } = data?.data?.SiteSelectionPois ?? {};

      setLoadingAll(true);
      try {
        let len = data.data?.SiteSelectionPois.results.length ?? 0;

        let pag = pagination;
        let hasMore = len < (data.data?.SiteSelectionPois.total ?? 0);

        const dt = [data.data?.SiteSelectionPois.results ?? []];

        while (hasMore && pag?.last && pag?.cursor) {
          const r = await fetchMore({
            variables: {
              ...variables,
              pagination: {
                after: pag?.cursor,
                limit: pageLimit,
              },
            },
          });

          pag = r.data?.SiteSelectionPois?.pagination;
          hasMore = !!pag.cursor;
          len = r.data?.SiteSelectionPois?.results?.length ?? 0 + len;
          dt.push(r.data?.SiteSelectionPois?.results ?? []);
        }

        const results = dt.flat().map(
          (poi) =>
            ({
              id: poi.id,
              category: poi.category as AttractionType,
              name: poi.name,
              type: poi.type,
              lat: poi.point.lat,
              lon: poi.point.lon,
            } satisfies POI)
        );

        setPoiData(results);
      } finally {
        setLoadingAll(false);
      }
      return data;
    }, 500),
    [fetchMore, getSiteSelectionPois]
  );

  return {
    loading: loading || loadingAll,
    error,
    getSiteSelectionPois,
    pois: poiData,
    count: data?.SiteSelectionPois.total ?? 0,
    refetch,
    startPolling,
    fetchMore,
    fetchAll,
  };
};
