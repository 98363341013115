/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated";

export const GET_TRIGGERS = gql(`
  query Triggers($filter: TriggerFilterParams, $pagination: PaginationParams) {
    Triggers(filter: $filter, pagination: $pagination) {
      __typename
      results {
        __typename
        activityUid
        sessionUid
        trigger {
          addedAt
          payload
          type
          uid
        }
      }
      total
      pagination{
        cursor
        last
      }
    }
  }
`);
export const useGetTriggers = () => {
  const [
    getTriggers,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_TRIGGERS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const fetchMore = () => {
    if (
      data?.Triggers?.pagination?.cursor &&
      data?.Triggers?.results?.length < data.Triggers?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.Triggers?.pagination.cursor,
          },
        },
        updateQuery(
          { Triggers: prevData },
          { fetchMoreResult: { Triggers: newData } }
        ) {
          if (prevData?.results?.length) {
            return {
              Triggers: {
                results: [...prevData?.results, ...newData?.results],
                __typename: newData.__typename,
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          } else {
            return {
              Triggers: {
                results: [...newData?.results],
                __typename: newData.__typename,
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          }
        },
      });
    }
  };
  return {
    loading,
    getTriggers,
    error,
    Triggers: data?.Triggers?.results,
    count: data?.Triggers?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
  };
};
