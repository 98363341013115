/** @format */

import { t } from "i18next";
import styled from "styled-components";
import { Card, Typography } from "../UI";
import Svg from "../UI/Svg";

const colors: { [index: string]: { bg: string; color: string } } = {
  rosa: {
    bg: "#FFE2E2",
    color: "#FF8A8A",
  },
  green: {
    bg: "#E6F5F2",
    color: "#269F8E",
  },
  yellow: {
    bg: "#F8EFD1",
    color: "#D3A405",
  },
  blue: {
    bg: "#CBE8FF",
    color: "#30A3FF",
  },
};

interface Props {
  icon?: React.ReactNode;
  label?: string | React.ReactElement;
  value: string | React.ReactElement;
  color: keyof typeof colors;
  width?: string;
}
const CardItem = styled(Card)<{ color: keyof typeof colors; width?: string }>`
  min-width: ${({ width }) => (width ? width : "230px")};
  flex-direction: column;
  min-height: 200px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
  ${({ color }) => `
         border:1px solid ${colors[color]?.color};
         background-color:${colors[color]?.bg};
         > span, div span {
         color:${colors[color]?.color};
            }
         ${Svg}{
            fill:${colors[color]?.color};
            width:80px;
            height:80px;   
         }

      `};
`;

export const ItemWithIcon: React.FC<Props> = ({
  icon,
  value,
  label,
  width,
  color = "rosa",
  ...props
}) => {
  return (
    <CardItem color={color} width={width}>
      {icon ? icon : null}
      {label ? (
        typeof label === "string" ? (
          <Typography
            textAlign="center"
            upperCase
            variant="title30"
            fontWeight="bold"
            pt={2}
          >
            {t(label)}
          </Typography>
        ) : (
          label
        )
      ) : null}

      {typeof value === "string" ? (
        <Typography upperCase variant="title30" pt={1}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </CardItem>
  );
};
