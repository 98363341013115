import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileSolid, Upload } from "../../../../shared/assets/svg";
import {
  Box,
  Button,
  Dialog,
  DialogBody,
  Flex,
  Input,
  Typography,
} from "../../../../shared/UI";
import { DialogBodyElement } from "../../../../shared/UI/Dialog/StyledElements";
import { DialogProps } from "../../../../shared/UI/Dialog/types";

import * as uuid from "uuid";
import * as XLSX from "xlsx";
import {
  AccessType,
  Location,
  NewProjectPhase1,
  NewProjectPhase2,
  NewProjectPhase3,
  ProjectType,
  Visibility,
} from "../../@types/project";
import { useProject } from "../../hooks/useProject";
import { useProjects } from "../../hooks/useProjects";

export const RadioButton = <T,>({
  label,
  id,
  value,
  checked,
  setChecked,
  onChange,
}: {
  label: string;
  id: string;
  value: T;
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
  onChange?: (value: T) => void;
}) => {
  const handleCheck = () => {
    setChecked?.(!checked);
  };

  const radioStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    cursor: "pointer",
  } as const;

  const checkmarkStyle = {
    width: "22px",
    height: "22px",
    border: "1px solid #3D729C",
    borderRadius: "50%",
    marginRight: "16px",
    display: "inline-block",
    position: "relative",
  } as const;

  const checkmarkInnerStyle = {
    content: "",
    width: "8px",
    height: "13px",
    border: "solid #122967",
    borderWidth: "0 2px 2px 0",
    transform: checked ? "rotate(45deg)" : "none",
    position: "absolute",
    top: "2px",
    left: "6px",
  } as const;

  return (
    <label style={radioStyle}>
      <Flex flexDirection="row" alignItems="center">
        <div style={checkmarkStyle} onClick={handleCheck}>
          {checked && <span style={checkmarkInnerStyle}></span>}
        </div>
        <Typography
          variant={"caption20"}
          fontWeight={400}
          onClick={handleCheck}
          color="#122967"
        >
          {label}
        </Typography>

        <input
          type="radio"
          checked={checked}
          onChange={(e) => {
            e.target.checked && onChange?.(value);
          }}
          style={{ display: "none" }}
          id={id}
        />
      </Flex>
    </label>
  );
};

const RadioGroup = <T,>({
  data,
  getLabel,
  id,
  defaultValue,
  checkSelected = (a, b) => a === b,
  onChange,
}: {
  data: T[];
  checkSelected?: (selectedValue: T | undefined, value: T) => boolean;
  getLabel: (value: T) => string;
  defaultValue?: T;
  id: string;
  onChange?: (value: T) => void;
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  return (
    <Flex flexDirection="column">
      {data.map((value) => (
        <RadioButton
          id={id}
          value={value}
          checked={checkSelected(selectedOption, value)}
          onChange={(v) => {
            setSelectedOption(v);
            onChange?.(v);
          }}
          label={getLabel(value)}
        />
      ))}
    </Flex>
  );
};

const ProjectCardPhase1 = ({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: (project: NewProjectPhase1) => void;
}) => {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState<string | null>(null);
  const [accessType, setAccessType] = useState<AccessType>("public");
  const [visibility, setVisibility] = useState<Visibility>("public");

  return (
    <DialogBodyElement>
      <Flex justifyContent="center" margin={"0 auto"} marginTop={10}>
        <Typography
          variant="title30"
          color="#008FDD"
          fontWeight={700}
          fontSize={30}
          upperCase={true}
          textAlign={"center"}
        >
          {t("siteSelection.dashboard.newProject.dialog.title")}
        </Typography>
        <Flex
          justifyContent="center"
          margin={"0 auto"}
          marginTop={"55px"}
          style={{
            borderBottom: "1px solid #122967",
            width: "515px",
          }}
        >
          <Input
            placeholder={
              t(
                "siteSelection.dashboard.newProject.dialog.projectNamePlaceholder"
              ) || undefined
            }
            withBorder={false}
            style={{
              paddingLeft: "0",
            }}
            onChange={(e) => setProjectName(e.target.value?.trim())}
          />
        </Flex>
        <Box
          background={"#ECF8FF"}
          border={"3.775px solid #D4E0EF"}
          borderRadius={6}
          height={150}
          width={515}
          margin={"0 auto"}
          marginTop={35}
        >
          <Typography
            variant="caption30"
            color="#122967"
            fontWeight={700}
            textAlign={"center"}
            display={"block"}
            marginTop={20}
          >
            {t("siteSelection.dashboard.newProject.dialog.accessType")}
          </Typography>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-around"}
            marginTop={12}
          >
            <Flex flexGrow={1} />
            <RadioGroup
              id="access-type"
              data={[
                {
                  id: "public",
                  value: t(
                    "siteSelection.dashboard.newProject.dialog.accessTypePublic"
                  ),
                },
                {
                  id: "private",
                  value: t(
                    "siteSelection.dashboard.newProject.dialog.accessTypePrivate"
                  ),
                },
              ]}
              defaultValue={{
                id: accessType,
                value: accessType,
              }}
              checkSelected={(a, b) => a?.id === b.id}
              getLabel={(value) => value.value}
              onChange={(value) => setAccessType(value.id as AccessType)}
            />
            <Flex
              style={{
                width: "1px",
                background: "#122967",
                margin: "0px 15px",
              }}
            />
            <RadioGroup
              id="visibility"
              data={[
                {
                  id: "public",
                  value: t(
                    "siteSelection.general.data.project.visibility.public"
                  ),
                },
                {
                  id: "private",
                  value: t(
                    "siteSelection.general.data.project.visibility.private"
                  ),
                },
              ]}
              defaultValue={{
                id: visibility,
                value: visibility,
              }}
              checkSelected={(a, b) => a?.id === b.id}
              getLabel={(value) => value.value}
              onChange={(value) => setVisibility(value.id as Visibility)}
            />
            <Flex flexGrow={1} />
          </Flex>
        </Box>
        <Flex
          flexDirection={"row"}
          justifyContent="space-around"
          marginTop={38}
        >
          <Flex flexGrow={1} />
          <Button
            bg="#008FDD"
            height={48}
            width={205}
            borderRadius={12}
            fontSize={26}
            fontWeight={700}
            onClick={onCancel}
          >
            {t("siteSelection.dashboard.newProject.dialog.cancel")}
          </Button>
          <Button
            bg="#122967"
            height={48}
            width={205}
            borderRadius={12}
            fontSize={26}
            fontWeight={700}
            marginLeft={28}
            onClick={() =>
              projectName &&
              onConfirm({
                id: uuid.v4(),
                name: projectName,
                accessType,
                visibility,
              })
            }
            disabled={!projectName || projectName?.length === 0}
          >
            {t("siteSelection.dashboard.newProject.dialog.nextStep")}
          </Button>
          <Flex flexGrow={1} />
        </Flex>
      </Flex>
    </DialogBodyElement>
  );
};

const ProjectCardPhase2 = ({
  onSelect,
}: {
  onSelect: (projectType: ProjectType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <DialogBodyElement>
      <Flex
        justifyContent="center"
        height={"100%"}
        margin={"0 auto"}
        marginTop={10}
      >
        <Flex
          flexDirection={"row"}
          justifyContent="space-around"
          alignItems={"center"}
          height={"100%"}
        >
          <Button
            bg="#008FDD"
            height={226}
            width={206}
            borderRadius={12}
            fontSize={30}
            fontWeight={700}
            onClick={() => onSelect("list")}
          >
            {t("siteSelection.dashboard.newProject.dialog.selectList")}
          </Button>
          <Button
            bg="#122967"
            height={226}
            width={206}
            borderRadius={12}
            fontSize={30}
            fontWeight={700}
            marginLeft={28}
            padding={0}
            onClick={() => onSelect("green-field")}
          >
            {t("siteSelection.dashboard.newProject.dialog.selectGreenField")}
          </Button>
        </Flex>
      </Flex>
    </DialogBodyElement>
  );
};

const ProjectCardPhase3 = ({
  onSelect,
  loading,
}: {
  onSelect: (data: any[]) => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dragging, setDragging] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<Location[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    selectedFile
      ?.arrayBuffer()
      .then((buffer) => {
        const data = XLSX.read(buffer);
        const headers = ["latitude", "longitude", "address", "ac", "dc"];
        const success = headers.every(
          (header, index) =>
            data.Sheets["data"][
              XLSX.utils.encode_cell({
                c: index,
                r: 0,
              })
            ].v === header
        );

        const json: any[] = XLSX.utils.sheet_to_json(data.Sheets["data"]);
        setData(json as Location[]);

        setSuccess(success);
      })
      .catch((e) => {
        setSuccess(false);
      });
  }, [selectedFile]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];
      setSelectedFile(droppedFile);
    }
  };

  return (
    <DialogBodyElement>
      <Flex
        justifyContent="center"
        height={"100%"}
        margin={"0 auto"}
        marginTop={10}
        position={"relative"}
      >
        {loading && (
          <Flex
            position={"absolute"}
            flexDirection={"row"}
            justifyContent="space-around"
            alignItems={"center"}
            height={"100%"}
            width={"100%"}
            zIndex={2}
            background={"rgba(255,255,255,0.7)"}
          >
            {t("siteSelection.general.loading")}
          </Flex>
        )}
        <Typography
          variant="title30"
          color="#008FDD"
          fontWeight={700}
          fontSize={30}
          upperCase={true}
          textAlign={"center"}
          display={"block"}
          overflow={"visible"}
        >
          {t("siteSelection.dashboard.newProject.dialog.uploadTitle")}
        </Typography>
        <Flex
          onDragEnter={handleDragEnter}
          onDragOver={(event) => event.preventDefault()}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          flexDirection={"row"}
          justifyContent="space-around"
          alignItems={"center"}
          height={"100%"}
          border={dragging ? "2px dashed #000" : "2px dashed #008FDD"}
          background={dragging ? "#EEE" : "none"}
          borderRadius={10}
          zIndex={1}
          style={{ cursor: "pointer" }}
          onClick={() => {
            !loading && inputRef.current?.click();
          }}
        >
          <div
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragEnter}
          >
            <Upload width="69px" height="69px" />
          </div>
          <input
            type="file"
            accept=" .xlsx, .xls"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={inputRef}
            disabled={loading}
          />
        </Flex>
        {selectedFile && (
          <Flex
            style={{
              marginTop: "45px",
            }}
          >
            <Typography
              variant="caption30"
              color="#3D729C"
              fontWeight={700}
              fontSize={22}
              marginTop={20}
            >
              {t("siteSelection.dashboard.newProject.dialog.uploadText")}
            </Typography>
            <Flex flexDirection={"row"} alignItems={"center"}>
              <Flex
                alignItems={"center"}
                style={{
                  position: "relative",
                }}
              >
                <FileSolid />
                <Typography
                  position={"absolute"}
                  width={"100%"}
                  textAlign={"center"}
                  height={"100%"}
                  color="#FFF"
                  fontSize={30}
                  fontWeight={700}
                  top={"25%"}
                >
                  X
                </Typography>
              </Flex>
              <Flex margin="0px 5px" flexGrow={1}>
                <Typography variant={"body30"} color="#000">
                  {selectedFile.name.length > 30
                    ? selectedFile.name.substring(0, 30) + "..."
                    : selectedFile.name}
                </Typography>
                <hr
                  style={{
                    border: "6px solid #008FDD",
                    width: "100%",
                    marginTop: "5px",
                    borderRadius: "100px",
                  }}
                />
              </Flex>
              <Flex>
                <Flex flexGrow={1} />
                <Flex
                  alignItems={"center"}
                  style={{
                    position: "relative",
                    borderRadius: "100%",
                    background: success ? "#78931A" : "#FF0000",
                    width: "22px",
                    height: "22px",
                    padding: "auto",
                  }}
                >
                  {success && (
                    <Flex
                      style={{
                        position: "absolute",
                        border: "2px solid #FFF",
                        borderTop: "none",
                        borderRight: "none",
                        rotate: "-50deg",
                        width: "12px",
                        height: "7px",
                        left: "5px",
                        top: "5px",
                      }}
                    />
                  )}
                  {!success && (
                    <Typography
                      width={"100%"}
                      textAlign={"center"}
                      color="#FFF"
                      fontSize={15}
                      fontWeight={500}
                      top={"20%"}
                      margin={"auto"}
                    >
                      X
                    </Typography>
                  )}
                </Flex>
              </Flex>
            </Flex>
            {!success && (
              <Typography
                variant="caption30"
                color="#FF0000"
                fontSize={22}
                marginTop={20}
              >
                {t("siteSelection.dashboard.newProject.dialog.uploadTextError")}
              </Typography>
            )}
            {success && (
              <Button
                bg="#122967"
                height={48}
                width={205}
                borderRadius={12}
                fontSize={26}
                fontWeight={700}
                marginLeft={"auto"}
                marginTop={20}
                disabled={loading}
                onClick={() => {
                  onSelect(data);
                }}
              >
                {t("siteSelection.dashboard.newProject.dialog.nextStep")}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </DialogBodyElement>
  );
};

export const NewProjectDialog = ({
  handleSubmit,
  ...props
}: Omit<DialogProps, "handleSubmit"> & {
  handleSubmit: (projectUid: string) => void;
}) => {
  const [project, setProject] = useState<
    NewProjectPhase1 | NewProjectPhase2 | NewProjectPhase3 | null
  >(null);

  const { createProject } = useProjects({
    loadData: false,
  });

  const { addLocations } = useProject();

  const [createdProjectId, setCreatedProjectId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onProjectCreated = (data: { _id: string }) => {
    setCreatedProjectId(data._id);
  };

  useEffect(() => {
    if (createdProjectId) {
      handleSubmit(createdProjectId);
    }
  }, [createdProjectId, handleSubmit]);

  const [step, setStep] = useState<
    "project-name" | "project-type" | "upload-list"
  >("project-name");

  const heightStep = {
    "project-name": "500px",
    "project-type": "444px",
    "upload-list": "570px",
  };

  return (
    <Dialog
      width="750px"
      height={heightStep[step]}
      handleClose={() => {
        setStep("project-name");
        props.handleClose?.();
      }}
      {...props}
    >
      <DialogBody>
        {step === "project-name" && (
          <ProjectCardPhase1
            onCancel={() => {
              props.setIsOpen(false);
            }}
            onConfirm={(project) => {
              setStep("project-type");
              setProject(project);
            }}
          />
        )}
        {step === "project-type" && (
          <ProjectCardPhase2
            onSelect={(projectType) => {
              const pr: NewProjectPhase2 = {
                ...project!,
                type: projectType,
              };
              setProject(pr);
              if (projectType === "green-field") {
                createProject(pr)?.then(onProjectCreated as any);
              } else {
                setStep("upload-list");
              }
            }}
          />
        )}
        {step === "upload-list" && (
          <ProjectCardPhase3
            loading={isLoading}
            onSelect={(data) => {
              const pr: NewProjectPhase3 = {
                ...(project as NewProjectPhase2)!,
                data,
              };
              setProject(pr);
              setIsLoading(true);
              createProject(pr)
                ?.then((res) => {
                  if (!res) return;
                  return setTimeout(() => {
                    return addLocations(res._id, data).then(() =>
                      onProjectCreated(res)
                    );
                  }, 500);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
        )}
      </DialogBody>
    </Dialog>
  );
};
