import { t } from "i18next";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Typography } from "../../../../../shared/UI";
import { Pagination } from "../../../../../shared/UI/Pagination";
import { DefaultProps } from "../../../../../shared/UI/helpers";
import { Close } from "../../../../../shared/assets/svg";
import MapPinAC from "../../../../../shared/assets/svg/MapPinAC";
import MapPinDC from "../../../../../shared/assets/svg/MapPinDC";
import Maximize from "../../../../../shared/assets/svg/Maximize";
import Minimize from "../../../../../shared/assets/svg/Minimize";
import Tick from "../../../../../shared/assets/svg/Tick";
import { Location } from "../../../@types/project";
import { useProject } from "../../../hooks/useProject";
import { useSiteSelectionContext } from "../../../slices/ContextProvider";
import { mapKwh } from "../../../utils/map-kwh";

type BoxProps = React.HTMLAttributes<HTMLDivElement> & DefaultProps;
type LocationListProps = BoxProps & {
  projectId: string;
};

const Arrow = ({
  direction,
  stroke,
  size,
  style,
  ...props
}: Omit<
  BoxProps,
  "width" | "height" | "borderLeft" | "borderRight" | "borderBottom"
> & { direction: "up" | "down"; stroke: string }) => (
  <Box
    width={"0"}
    height={"0"}
    borderLeft={`${size} solid transparent`}
    borderRight={`${size} solid transparent`}
    borderBottom={`${size} solid ${stroke || "#FFFFFF"}`}
    style={{
      transform: direction === "down" ? "rotate(180deg)" : undefined,
      ...(style ?? {}),
    }}
    {...props}
  />
);

const OpenTabButton = memo(
  ({
    style,
    isOpen,
    stroke,
    fill,
    ...props
  }: BoxProps & {
    isOpen?: boolean;
    stroke?: string;
    fill?: string;
  }) => (
    <Box
      paddingTop={"10px"}
      borderRadius={"3px"}
      borderTop={`1px solid ${stroke ?? "#3D729C"}`}
      background={fill ?? "#FFFFFF"}
      style={{
        cursor: "pointer",
        ...(style ?? {}),
      }}
      {...props}
    >
      <Box
        padding={"7px 10px 7px 10px"}
        borderTop={`1px solid ${stroke ?? "#3D729C"}`}
      >
        <Arrow
          size={"5px"}
          stroke={stroke ?? "#3D729C"}
          direction={isOpen ? "up" : "down"}
        />
      </Box>
    </Box>
  ),
  (prev, actual) =>
    prev.isOpen === actual.isOpen &&
    prev.stroke === actual.stroke &&
    prev.fill === actual.fill
);

const LocationBox = memo(
  ({
    id,
    ac,
    dc,
    contractualCapacity,
    address,
    validated,
    forecast,
    setLocationValidation,
    onPinClick,
  }: {
    id: string;
    ac: number;
    dc: number;
    contractualCapacity?: number | null;
    address: string | null;
    validated: boolean | null;
    forecast: number | null;
    setLocationValidation: (id: string, value: boolean | null) => void;
    onPinClick?: () => void;
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    const formattedForecast = mapKwh(forecast ?? 0);

    return (
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        //   justifyContent={"center"}
        borderRadius={"6.5px"}
        border="1.623px solid #DDD"
        background="#FAFAFA"
        padding={"10px 15px"}
      >
        <Box
          onClick={() => {
            onPinClick && onPinClick();
          }}
          style={{
            cursor: onPinClick ? "pointer" : undefined,
          }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          {dc === 0 && (
            <MapPinAC
              lightningFill={isHovered ? "#F9E66E" : "white"}
              iconSize="40px"
            />
          )}
          {dc !== 0 && (
            <MapPinDC
              lightningFill={isHovered ? "#F9E66E" : "white"}
              iconSize="40px"
            />
          )}
        </Box>
        <Flex>
          <Typography
            color="#122967"
            fontSize="16px"
            fontWeight="700"
            display={"block"}
          >
            {address ?? "-"}
          </Typography>

          <Typography color="#122967" fontSize="14px" display={"block"}>
            {ac} {t("siteSelection.general.legend.ac")} - {dc}{" "}
            {t("siteSelection.general.legend.dc")} - {contractualCapacity}{" "}
            {t("siteSelection.locationList.kWh")} | 
            {" "}{t("siteSelection.locationList.sp")} {forecast === null ? '-' : `${formattedForecast.value} ${formattedForecast.unit}`}
          </Typography>
        </Flex>
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          marginLeft={"auto"}
          height={"20px"}
        >
          <Button
            variant={"gray"}
            $fill={true}
            shape="circle"
            bg={validated ? "#78931A" : "#D9D9D9"}
            borderColor={"#3D729C"}
            padding={"0"}
            height={"20px"}
            width={"20px"}
            onClick={() => {
              setLocationValidation(id, validated ? null : true);
            }}
          >
            <Tick fill={validated ? "#FFF" : "#3D729C"} iconSize="9px" />
          </Button>
          <Button
            variant={"gray"}
            $fill={true}
            shape="circle"
            bg={validated === false ? "#E84120" : "#D9D9D9"}
            borderColor={"#3D729C"}
            marginLeft={"2px"}
            padding={"0"}
            height={"20px"}
            width={"20px"}
            onClick={() => {
              setLocationValidation(id, validated === false ? null : false);
            }}
          >
            <Close fill={validated === false ? "#FFF" : "#3D729C"} />
          </Button>
        </Flex>
      </Flex>
    );
  },
  (p, c) =>
    p.id === c.id &&
    p.validated === c.validated &&
    p.address === c.address &&
    p.ac === c.ac &&
    p.dc === c.dc &&
    p.contractualCapacity === c.contractualCapacity &&
    p.setLocationValidation === c.setLocationValidation &&
    p.onPinClick === c.onPinClick &&
    p.forecast === c.forecast
);

const LocationBoxHeader = memo(
  ({
    totalLocations,
    isOpen,
    isMaximized,
    setIsOpen,
    setIsMaximized,
  }: {
    totalLocations: number;
    isOpen: boolean;
    isMaximized: boolean;
    setIsOpen: (value: boolean) => void;
    setIsMaximized: (value: boolean) => void;
  }) => {
    const { t } = useTranslation();
    return (
      <Box
        borderRadius={isOpen ? "10.5px 10.5px 0 0" : "10.5px"}
        background="#095D8B"
        boxShadow="0px 3.17842px 3.17842px 0px rgba(0, 0, 0, 0.25)"
        padding="10px"
      >
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {!isMaximized && (
            <Box
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setIsMaximized(!isMaximized);
              }}
            >
              <Maximize fill="#FFFFFF" iconSize="20px" />
            </Box>
          )}
          {isMaximized && (
            <Box
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setIsMaximized(!isMaximized);
              }}
            >
              <Minimize fill="#FFFFFF" iconSize="20px" />
            </Box>
          )}
          <Box>
            <Typography
              variant={"title20"}
              color="#FFFFFF"
              marginLeft={"10px"}
              upperCase={true}
            >
              {t("siteSelection.locationList.locationOfInterest")}
            </Typography>
            <Typography
              variant={"title20"}
              color="#FFFFFF"
              marginRight={"10px"}
              marginLeft={"2px"}
              upperCase={true}
            >
              ({totalLocations})
            </Typography>
          </Box>
          <Flex flexGrow={1} />
          <OpenTabButton
            isOpen={isOpen}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </Flex>
      </Box>
    );
  },
  (prev, actual) => {
    return (
      prev.isOpen === actual.isOpen &&
      prev.totalLocations === actual.totalLocations &&
      prev.isMaximized === actual.isMaximized &&
      prev.setIsOpen === actual.setIsOpen &&
      prev.setIsMaximized === actual.setIsMaximized
    );
  }
);

const Pag = memo(
  ({
    page,
    pageSize,
    onPageChange,
    total,
  }: {
    page: number;
    pageSize: number;
    onPageChange: (value: number) => void;
    total: number;
  }) => (
    <Pagination
      $variant="white"
      page={page + 1}
      limit={pageSize}
      maxPage={5}
      total={total}
      hideFirstButton={total / pageSize < 5 || page < 3}
      hideLastButton={total / pageSize < 5}
      hideNextButton
      hidePrevButton
      onPageChange={(e, page) => {
        onPageChange(page - 1);
      }}
    />
  ),
  (prev, actual) => {
    return (
      prev.page === actual.page &&
      prev.pageSize === actual.pageSize &&
      prev.total === actual.total 
    );
  }
);

export const LocationList: React.FC<LocationListProps> = memo(
  ({ style, projectId, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMaximized, setIsMaximized] = useState(false);
    const { project, setLocationValidation } = useProject(projectId);
    const { panTo } = useSiteSelectionContext();

    const [page, setPage] = useState(0);
    const pageSize = 5;
    const multiplier = isMaximized ? 2 : 1;

    useEffect(() => {
      if (page * multiplier * pageSize >= (project?.locations?.length ?? 0)) {
        setPage(Math.floor(page / 2));
      }
    }, [page, project?.locations, multiplier]);

    const locations = useMemo(() => {
      if (project?.locations) {
        return project.locations
          .filter((_, i) => i % multiplier === 0)
          .slice(page * pageSize, (page + 1) * pageSize);
      }
      return [];
    }, [project?.locations, page, pageSize, multiplier]);

    const location2 = useMemo(() => {
      if (project?.locations) {
        return project.locations
          .filter((_, i) => i % multiplier === 1)
          .slice(page * pageSize, (page + 1) * pageSize);
      }
      return [];
    }, [project?.locations, page, pageSize, multiplier]);

    const handlePinClick = useCallback(
      (location: Location) => {
        panTo({
          lat: location.latitude,
          lng: location.longitude,
        });
      },
      [panTo]
    );

    return (
      <Box
        borderRadius="10.595px 10.595px 10.5px 10.5px"
        background="#FFF"
        style={{
          userSelect: "none",
          ...(style ?? {}),
        }}
        {...props}
      >
        <LocationBoxHeader
          totalLocations={project?.locations.length ?? 0}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isMaximized={isMaximized}
          setIsMaximized={setIsMaximized}
        />
        {isOpen && (
          <Box
            background="#FFFFFF"
            padding={"10px"}
            borderRadius={"0 0 10.5px 10.5px"}
            marginTop={"10px"}
          >
            <Flex flexDirection={"row"}>
              <Box marginRight={isMaximized ? "10px" : "0px"}>
                {locations.map((location) => (
                  <Box
                    key={`left-${location.id}-${location.validated}`}
                    marginBottom={"10px"}
                    minWidth={"350px"}
                  >
                    <LocationBox
                      id={location.id}
                      address={location.address}
                      ac={location.ac}
                      dc={location.dc}
                      contractualCapacity={location.contractualCapacity}
                      validated={location.validated}
                      forecast={location.forecast ?? null}
                      setLocationValidation={setLocationValidation}
                      onPinClick={() => handlePinClick(location)}
                    />
                  </Box>
                ))}
              </Box>
              {isMaximized && (
                <Box flexGrow={1}>
                  {location2.map((location) => (
                    <Box
                      key={`right-${location.id}-${location.validated}`}
                      marginLeft={"10px"}
                      marginBottom={"10px"}
                      minWidth={"350px"}
                    >
                      <LocationBox
                        id={location.id}
                        address={location.address}
                        ac={location.ac}
                        dc={location.dc}
                        contractualCapacity={location.contractualCapacity}
                        validated={location.validated}
                        forecast={location.forecast ?? null}
                        setLocationValidation={setLocationValidation}
                        onPinClick={() => handlePinClick(location)}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Flex>
            <Flex justifyContent={"end"} marginTop={"10px"}>
              <Flex flexGrow={1} />
              <Flex>
                {pageSize * multiplier < (project?.locations?.length ?? 0) && (
                  <Pag
                    page={page}
                    pageSize={pageSize * multiplier}
                    total={project?.locations?.length ?? 0}
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                  />
                )}
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
    );
  },
  (prev, actual) => prev.projectId === actual.projectId
);
