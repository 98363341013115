/** @format */

import { useMutation } from "@apollo/client";
import { gql } from "../generated";

export const PING_EVC = gql(`
  mutation pingEvc($ip:String!) {
    pingEvc(ip:$ip) {
      status
      error
    }
  }
`);

export const usePingEVC = () => {
  const [executePingEVC, { data, loading, error }] = useMutation(PING_EVC);
  return {
    executePingEVC,
    error,
    data: data?.pingEvc,
    loading,
  };
};
