/** @format */

import { FC } from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "../../App";
import UserService from "./../services/UserServices";
import ErrorsRoutes from "./ErrorsRoutes";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

type Props = {
  config: any;
};

const AppRoutes: FC<Props> = ({ config }) => {
  const isAuth = UserService?.isLoggedIn();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<App config={config} />}>
        <Route path="error/*" element={<ErrorsRoutes />} />
        {!!isAuth ? (
          <>
            <Route path="/*" element={<PrivateRoutes config={config} />} />
            <Route index element={<Navigate to="/profile" replace={true} />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<PublicRoutes />} />
            <Route index element={<Navigate to="/login" replace={true} />} />
          </>
        )}
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};
export default AppRoutes;
