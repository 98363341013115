/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Token } from "../../../../../shared/gql/generated/graphql";

export type MeterValueConfig = {
  time: number;
  value: number;
};
type SimulatorState = {
  defaultRFID: Token | null;
  customMeterValue: MeterValueConfig | null;
  sendStatusNotificationToConnector0: boolean;
  sendStatusNotificationToOtherConnectors: boolean;
};
const initialState: SimulatorState = {
  defaultRFID: null,
  customMeterValue: null,
  sendStatusNotificationToConnector0: true,
  sendStatusNotificationToOtherConnectors: true,
};

const simulatorSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    setSendStatusNotificationToConnector0: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.sendStatusNotificationToConnector0 = action.payload;
    },
    setSendStatusNotificationToOtherConnectors: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.sendStatusNotificationToOtherConnectors = action.payload;
    },
    setDefaultRFID: (state, action: PayloadAction<Token | null>) => {
      state.defaultRFID = action.payload;
    },
    setCustomMeterValue: (
      state,
      action: PayloadAction<MeterValueConfig | null>
    ) => {
      state.customMeterValue = action.payload;
    },
  },
});

export const {
  setSendStatusNotificationToConnector0,
  setSendStatusNotificationToOtherConnectors,
  setDefaultRFID,
  setCustomMeterValue,
} = simulatorSlice.actions;
export default simulatorSlice.reducer;
