import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  Flex,
  Input,
  InputLabel,
  Select,
  Typography,
} from "../../../../../../shared/UI";
import { useGetFtpUrls } from "../../../../gql/queries/useGetFtpUrls";
import { useEffect, useMemo, useState } from "react";
import { FtpUrl } from "@bepower/greta-types";
import { usePublishFirmwareUpdate } from "../../../../gql/mutations/usePublishFirmwareUpdate";
import { useFirmwareUpdateProcessed } from "../../../../gql/queries/useFirmwareUpdateProcessed";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { getTime } from "date-fns";
import { t } from "i18next";

export const FirmwareUpdateCommand = () => {
  const { register, control, watch } = useForm<any>({
    // resolver: yupResolver(resetEvcSchema),
    shouldFocusError: true,
  });
  const { evc }: any = useOutletContext();
  const { ftpUrls, loading, getFtpUrls } = useGetFtpUrls();
  const { publishFirmwareUpdate, loading: loadingPublishFirmwareUpdate } =
    usePublishFirmwareUpdate();
  const {
    getFirmwareUpdateProcessed,
    loading: loadingGetFirmwareUpdateProcess,
  } = useFirmwareUpdateProcessed();
  const [isFirmwareUpdating, setIsUpdatingFirmware] = useState<boolean>(false);

  const updateFirmware = () => {
    const ftpUrl = watch("ftpUrl")?.value;
    const retriveAfter = watch("retriveAfter");
    const retries = watch("retries");
    const interval = watch("interval");

    if (evc && ftpUrl && retriveAfter) {
      setIsUpdatingFirmware(true);

      publishFirmwareUpdate({
        variables: {
          input: {
            evcUid: evc?.uid,
            ftpUrl: ftpUrl,
            interval: retries,
            retries: interval,
            retrieveAfter: getTime(new Date(retriveAfter)),
          },
        },
      })
        .then((res) => {
          const requestUid = res?.data?.publishFirmwareUpdate?.requestUid;
          if (requestUid) {
            setTimeout(() => {
              getFirmwareUpdateProcessed({
                variables: {
                  filter: {
                    requestUid: requestUid,
                  },
                },
              })
                .then((res) => {
                  if (res.data?.FirmwareUpdateProcessed?.results?.[0]?.status) {
                    toast.success("Firmware Updated Successfully");
                  } else {
                    toast.error(
                      res.data?.FirmwareUpdateProcessed?.results?.[0]?.info
                    );
                  }
                })
                .catch((error) => {
                  setIsUpdatingFirmware(false);
                })
                .finally(() => {
                  setIsUpdatingFirmware(false);
                });
            }, 2000);
          }
        })
        .catch((error) => {
          setIsUpdatingFirmware(false);
        });
    }
  };

  useEffect(() => {
    getFtpUrls({
      variables: {
        pagination: {
          limit: 100,
        },
        filter: {
          query: "type:FIRMWARE",
        },
      },
    });
  }, [getFtpUrls]);
  const vendor = useMemo(() => (evc ? evc.vendor : null), [evc]);
  const model = useMemo(() => (evc ? evc.model : null), [evc]);

  return (
    <Card
      bg="white"
      p={3}
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      minWidth={400}
    >
      <Typography variant={"title20"}> Firmware update</Typography>
      <Flex
        width={"100%"}
        flexDirection={"row"}
        style={{ gap: "10px 10px" }}
        mt={2}
      >
        <Box flex={1} mt={1}>
          <InputLabel children="FTP Url" required />
          <Controller
            name="ftpUrl"
            control={control}
            render={({ field }) => (
              <Select
                isLoading={loading}
                options={
                  ftpUrls
                    ?.filter(
                      (item: FtpUrl) =>
                        (!item.vendor || item.vendor === vendor) &&
                        (!item.model ||
                          !item.model.length ||
                          item.model.includes(model))
                    )
                    .map((item: FtpUrl) => ({
                      label: item.label || item.url.split("/").pop(),
                      value: item.url,
                    })) || []
                }
                placeholder="Select Type"
                {...field}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <InputLabel children="Retrieve after" required />
          <Input type="date" placeholder="0" {...register("retriveAfter")} />
        </Box>
      </Flex>
      <Flex
        width={"100%"}
        flexDirection={"row"}
        style={{ gap: "10px 10px" }}
        mt={2}
      >
        <Box>
          <InputLabel children="Retries" />
          <Input type="number" placeholder="0" {...register("retries")} />
        </Box>
        <Box>
          <InputLabel children="Interval (sec)" />
          <Input type="number" placeholder="0" {...register("interval")} />
        </Box>
      </Flex>
      <Box my={4}>
        <Button
          block
          $size="default"
          disabled={isFirmwareUpdating}
          loading$={isFirmwareUpdating}
          onClick={updateFirmware}
        >
          {t('send')}
        </Button>
      </Box>
    </Card>
  );
};
