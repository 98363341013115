import { gql, useLazyQuery } from "@apollo/client";
import m from "moment";
import proj4 from "proj4";
import { useCallback, useState } from "react";
import { useIndexedDB } from "react-indexed-db-hook";
import { HexagonProperties } from "../locations/components/layers/HexagonLayer";

const sourceCRS = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
const targetCRS =
  "+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";


const base = {
  lat: 44.3968491333614,
  lng: 9.26758165147876,
  vertices: [
    [9.266892, 44.3973],
    [9.266647, 44.39663],
    [9.267337, 44.396179],
    [9.268271, 44.396398],
    [9.268516, 44.397068],
    [9.267827, 44.397519],
    [9.266892, 44.3973],
  ],
};

const [lng, lat] = proj4(sourceCRS, targetCRS, [base.lng, base.lat]);

const convertedBase = {
  lat,
  lng,
  vertices: base.vertices.map(([lng, lat]) =>
    proj4(sourceCRS, targetCRS, [lng, lat])
  ),
};

const projectHex = (lat: number, lng: number): [number, number][] => {
  const [lng1, lat1] = proj4(sourceCRS, targetCRS, [lng, lat]); // [9.26758165147876, 44.3968491333614
  const lt = convertedBase.lat - lat1;
  const lg = convertedBase.lng - lng1;
  return convertedBase.vertices
    .map(([oldLng, oldLat]) => [oldLng - lg, oldLat - lt])
    .map(([lng, lat]) => proj4(targetCRS, sourceCRS, [lng, lat]));
};



const GET_SITE_SELECTION_HEXAGONS = gql`
  query SiteSelectionHexagons(
    $filter: SiteSelectionHexagonFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    SiteSelectionHexagons(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        cef1
        cef2
        city
        id
        kwhAcOptimized
        kwhAcSimplified
        kwhDcOptimized
        kwhDcSimplified
        latitude
        longitude
        nAcOptimized
        nDcOptimized
        score
      }
      total
      pagination {
        cursor
        last
      }
    }
  }
`;

export const useGetSiteSelectionHexagons = () => {
  const { add, getByID, update } = useIndexedDB("hex");

  const [
    getSiteSelectionHexagons,
    { loading, error, data, refetch, fetchMore, startPolling },
  ] = useLazyQuery<{
    SiteSelectionHexagons: {
      results: {
        cef1: boolean;
        cef2: boolean;
        city: string;
        id: string;
        kwhAcOptimized: number;
        kwhAcSimplified: number;
        kwhDcOptimized: number;
        kwhDcSimplified: number;
        latitude: number;
        longitude: number;
        nAcOptimized: number;
        nDcOptimized: number;
        runDate: number;
        score: number;
      }[];
      total: number;
      pagination: {
        cursor: string;
        last: boolean;
      };
    };
  }>(GET_SITE_SELECTION_HEXAGONS, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    returnPartialData: false,
    
  });

  const [loadingAll, setLoadingAll] = useState(false);
  const [hexagonData, setHexagonData] = useState<HexagonProperties[]>([]);

  const fetchAll = useCallback(
    async (city: string) => {
      const dbData = await getByID(city)

      if (dbData && dbData.hex && dbData.hex.length > 0 && m(new Date()).diff(m(dbData.runDate), 'days') < 10) {
        setHexagonData(dbData.hex);
        return;
      }

      const pageLimit = 1000;

      const variables = {

        filter: {
          city,
        },
        sort: {
          field: "id",
        },
        pagination: {
          limit: pageLimit,
        },
      }

      const data = await getSiteSelectionHexagons({
        variables: variables
      });
      const { pagination } = data?.data?.SiteSelectionHexagons ?? {};

      setLoadingAll(true);
      try {
        let len = data.data?.SiteSelectionHexagons.results.length ?? 0;

        let pag = pagination;
        let hasMore = len < (data.data?.SiteSelectionHexagons.total ?? 0);

        const dt = [data.data?.SiteSelectionHexagons.results ?? []]

        while (hasMore && pag?.last && pag?.cursor) {
          const r = await fetchMore({
            variables: {
              ...variables,
              pagination: {
                after: pag?.cursor,
                limit: pageLimit,
              },
            },
          });

          pag = r.data?.SiteSelectionHexagons?.pagination;
          hasMore = !!pag.cursor;
          len = r.data?.SiteSelectionHexagons?.results?.length ?? 0 + len;
          dt.push(r.data?.SiteSelectionHexagons?.results ?? []);
        }

        const results = dt.flat().map(hex => ({
          ac: hex.kwhAcSimplified,
          dc: hex.kwhDcSimplified,
          id: hex.id,
          lat: hex.latitude,
          lon: hex.longitude,
          kwh_ac: hex.kwhAcOptimized,
          kwh_dc: hex.kwhDcOptimized,
          n_ac: hex.nAcOptimized,
          n_dc: hex.nDcOptimized,
          kwhPred: (hex.kwhAcSimplified ?? 0) + (hex.kwhDcSimplified ?? 0),
          kwhPredOptimized: (hex.kwhAcOptimized ?? 0) + (hex.kwhDcOptimized ?? 0),
          cef1: hex.cef1,
          cef2: hex.cef2,
          score: hex.score ?? 0,
          runDate: hex.runDate ? new Date(hex.runDate * 1000) : new Date(),
          polygon: projectHex(+hex.latitude, +hex.longitude).map(([lng, lat]) => ({
            lat,
            lng,
          })),
        } satisfies HexagonProperties));

        const maxDate = results.reduce((acc, cur) => {
          return Math.max(acc, cur.runDate?.getTime() ?? new Date(2000, 1, 1).getTime());
        }, new Date(2000, 1, 1).getTime())

        if (!dbData) {
          add({ hex: results, runDate: new Date(maxDate), city })
        } else {
          update({ hex: results, runDate: new Date(maxDate), city })
        }
        setHexagonData(results);
      } finally {
        setLoadingAll(false);
      }
      return data;
    },
    [add, fetchMore, getByID, getSiteSelectionHexagons]
  );

  return {
    loading: loading || loadingAll,
    error,
    getSiteSelectionHexagons,
    hexagons: hexagonData,
    count: data?.SiteSelectionHexagons.total ?? 0,
    refetch,
    startPolling,
    fetchMore,
    fetchAll,
  };
};
