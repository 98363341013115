import { useDispatch, useSelector } from "react-redux";
import { AngleDown, AngleLeft, AngleRight, AngleTop } from "../assets/svg";
import { setShowBottomContent, setShowMainList } from "../slices/layoutItems";
import { Button, Flex } from "../UI";
import { t } from "i18next";
type Props = {
  type: "mainList" | "bottomContent";
};
export const ExpendLayoutButton: React.FC<Props> = ({ type }) => {
  const dispatch = useDispatch();

  const { showMainList, showBottomContent } = useSelector(
    (state: any) => state.layout
  );

  const toggleMainList = () => {
    dispatch(setShowMainList(!showMainList));
  };
  const toggleBottomContent = () => {
    dispatch(setShowBottomContent(!showBottomContent));
  };



  return type === "mainList" ? (
    <Button
      $fill={false}
      variant="primary"
      p={0}
      onClick={toggleMainList}
      mr={2}
      border="2px solid #3D729C"
      borderRadius={5}
      flexDirection="row"
      title={t ('expand')|| ''}
    >
      <Flex p={0} ml={2} height="100%" borderLeft="2px solid #3D729C">
        {showMainList ? <AngleLeft size="5" /> : <AngleRight size="5" />}
      </Flex>
    </Button>
  ) : (
    <Button
      $fill={false}
      variant="primary"
      p={0}
      onClick={toggleBottomContent}
      mr={2}
      border="2px solid #3D729C"
      borderRadius={5}
      flexDirection="row"
      title={t ('expand')|| ''}
    >
      <Flex
        px={1}
        mt={"10px"}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="100%"
        borderTop="2px solid #3D729C"
      >
        {showBottomContent ? <AngleDown size="5" /> : <AngleTop size="5" />}
      </Flex>
    </Button>
  );
};
