import React, { PropsWithChildren } from "react";
import styled from "styled-components";



type Props = {
  bg?: '#95D0FF' | "#D9EEFF" | string;
  bottom?: boolean;
}
const SectionHeadElement = styled.div<Props>`

  position: relative;
  overflow: hidden;
  ${({ bottom }) => bottom ? `
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  ` :
    `
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  
  `}
 
  padding:15px; 
  height:60px;
  width: 100%;
  box-shadow:2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color:${({ bg = '#D9EEFF' }) => bg}
  // background-color: white;
`;

export const SectionHead: React.FC<PropsWithChildren<{ bottom?: boolean, bg?: '#95D0FF' | "#D9EEFF" | string }>> = ({ children, bottom, bg }) => {


  return (
    <SectionHeadElement bg={bg} bottom={bottom}>
      {children}
    </SectionHeadElement>
  )
};
