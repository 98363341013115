import debounce from "debounce";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Input,
  InputLabel,
  LoaderWithFallback,
  SimpleLoader,
  Typography,
} from "../../../../../../shared/UI";
import { Refresh } from "../../../../../../shared/assets/svg";
import { usePublishRemoteStartChargeTx } from "../../../../gql/mutations/usePublishRemoteStartChargeTx";
import { usePublishRemoteStopChargeTx } from "../../../../gql/mutations/usePublishRemoteStopChargeTx";
import { useChargeActivitiesLastNotCompleted } from "../../../../gql/queries/useChargeActivitiesLastNotCompleted";
import { useGetTokens } from "../../../../gql/queries/useGetTokens";
import { useStartChargeTxProcessed } from "../../../../gql/queries/useStartChargeTxProcessed";
import { useStopChargeTxProcessed } from "../../../../gql/queries/useStopChargeTxProcessed";
import { Select } from "../../../../../../shared/UI/Select";
import { Activity } from "@bepower/greta-types";
import moment from "moment";
import { t } from "i18next";

export const SessionEvseCommand = () => {
  const { control, watch, register, setValue } = useForm();
  const [isLoadingStart, setIsLoadingStart] = useState<boolean>(false);
  const [isLoadingStop, setIsLoadingStop] = useState<boolean>(false);
  const [lastActivity, setLastActivity] = useState<Activity | null>(null);
  const { PublishRemoteStopChargeTx } = usePublishRemoteStopChargeTx();
  const { getStopChargeTxProcessed } = useStopChargeTxProcessed();

  const { getStartChargeTxProcessed } = useStartChargeTxProcessed();
  const { publishRemoteStartChargeTx } = usePublishRemoteStartChargeTx();

  const {
    activities,
    getChargeActivitiesLastNotCompleted,
    refetch: refetchActivates,
    loading: loadingGetActivates,
  } = useChargeActivitiesLastNotCompleted();

  const {
    tokens,
    count,
    loading: loadingGetTokens,
    getTokens,
  } = useGetTokens();
  const [searchValue, setSearchValue] = useState<string>("");
  const { evse, refetchEvse, loading }: any = useOutletContext();
  useEffect(() => {
    getChargeActivitiesLastNotCompleted({
      variables: {
        evseUid: evse?.uid,
      },
    }).then((res) => {
      setLastActivity(res?.data?.Activities?.results?.[0]?.activity);
    });
  }, [evse?.uid, getChargeActivitiesLastNotCompleted]);

  const handleSendRemoteStop = () => {
    const transactionUid = activities?.[0]?.activity?.transactionUid;
    if (transactionUid) {
      setIsLoadingStop(true);
      PublishRemoteStopChargeTx({
        variables: {
          input: {
            transactionUid: transactionUid,
          },
        },
      }).then((res) => {
        const requestUid = res?.data?.publishRemoteStopChargeTx?.requestUid;
        if (requestUid) {
          setTimeout(() => {
            getStopChargeTxProcessed({
              variables: {
                filter: {
                  requestUid: requestUid,
                },
              },
            })
              .then((res) => {
                if (res.data?.StopChargeTxProcessed?.results?.[0]?.status) {
                  toast?.success(`Evse ${evse?.label} Stopped  Successfully`);
                  setLastActivity(null);
                } else {
                  toast.error(
                    res.data?.StopChargeTxProcessed?.results?.[0]?.info
                  );
                }
              })
              .finally(() => {
                setIsLoadingStop(false);
              });
          }, 2000);
        }
      });
    }
  };
  const handleChangeInput = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (searchValue.length > 3) {
      getTokens({
        variables: {
          pagination: {
            limit: 10,
            page: 1,
          },
          filter: {
            text: searchValue,
          },
        },
      });
    }
  }, [getTokens, searchValue]);
  const handleSendRemoteStart = () => {
    setIsLoadingStart(true);
    const { PlatformParty, __typename, ...token } = watch("token")?.value;
    if (token) {
      publishRemoteStartChargeTx({
        variables: {
          input: {
            evseUid: evse?.uid,
            locationUid: null,
            token: token,
          },
        },
      }).then((res) => {
        const requestUid = res?.data?.publishRemoteStartChargeTx?.requestUid;
        if (requestUid) {
          setTimeout(() => {
            getStartChargeTxProcessed({
              variables: {
                requestUid: requestUid,
              },
            })
              .then((res) => {
                const response =
                  res?.data?.StartChargeTxProcessed?.results?.[0];

                if (response?.status === true) {
                  toast?.success(`Evse ${evse?.label} Started  Successfully`);
                } else {
                  toast.error("Error");
                }
              })
              .finally(() => {
                setIsLoadingStart(false);
                refetchActivates().then((res) => {
                  setLastActivity(
                    res?.data?.Activities?.results?.[0]?.activity
                  );
                });
              });
          }, 2000);
        }
      });
    }
  };
  const handleRefetchActivities = () => {
    refetchActivates().then((res) => {
      if (res?.data?.Activities?.results?.[0]?.activity) {
        setLastActivity(res?.data?.Activities?.results?.[0]?.activity);
      } else {
        setLastActivity(null);
      }
    });
  };
  return (
    <Flex fullSize>
      <Flex width="100%" justifyContent={"space-between"} flexDirection={"row"}>
        <Typography variant={"title20"}>Session</Typography>
        <Button fixedSize onClick={() => refetchEvse()}>
          <Refresh />
        </Button>
      </Flex>

      <Flex
        p={2}
        flexDirection={"row"}
        display={"flex"}
        style={{ gap: "10px 10px" }}
        zIndex={100000000}
      >
        <LoaderWithFallback
          hasError={!evse}
          loading={loading}
          errorMsg={"Error"}
          LoadingMsg={<SimpleLoader />}
        >
          <Card bg="white" flex={1} p={3}>
            <Box mb={2}>
              <Typography variant={"title20"}>{t ('start charge')}</Typography>
            </Box>
            <InputLabel children="Token" />
            <Controller
              name="token"
              control={control}
              render={({ field }) => (
                <Select
                  menuPosition="fixed"
                  isLoading={loadingGetTokens}
                  isDisabled={evse?.status === "DISCONNECTED"}
                  onInputChange={debounce(handleChangeInput, 1000)}
                  getOptionValue={(option: any) => {
                    return option?.value;
                  }}
                  placeholder={"Select Token (min 4 characters) "}
                  options={
                    tokens?.map((token: any) => {
                      return {
                        label: token?.label,
                        value: token,
                      };
                    }) || []
                  }
                  {...field}
                />
              )}
            />
            <Button
              mt={2}
              disabled={
                !watch("token") ||
                evse?.status === "DISCONNECTED" ||
                isLoadingStart
              }
              loading$={isLoadingStart}
              variant="success"
              $size="large"
              onClick={handleSendRemoteStart}
            >
              {t ('Start')}
            </Button>
          </Card>
          <Card bg="white" flex={1} p={3}>
            <Box mb={2}>
              <Typography variant={"title20"}>{t('stop charge')}</Typography>
            </Box>
            <Box my={2}>
              <Flex
                width="100%"
                flexDirection={"row"}
                justifyContent={"space-between"}
                my={2}
              >
                <InputLabel
                  children={
                    lastActivity ? "Active session" : "No charge to stop"
                  }
                />{" "}
                <Button
                  fixedSize
                  variant="gray"
                  $size="small"
                  onClick={handleRefetchActivities}
                >
                  <Refresh />
                </Button>
              </Flex>
              {loadingGetActivates ? (
                <Center>
                  <SimpleLoader />
                </Center>
              ) : lastActivity ? (
                <Flex>
                  <Typography>TransactionUid :{lastActivity.uid}</Typography>
                  <Typography>
                    StartedAt :
                    {moment(lastActivity.startedAt).format(
                      "DD/MM/YYYY hh:MM:ss"
                    )}
                  </Typography>
                  <Typography>{ t ('type')} :{lastActivity.type}</Typography>
                  <Typography>
                    {t('completed')} :
                    {lastActivity.completed ? "Completed" : "NotCompleted"}
                  </Typography>
                </Flex>
              ) : null}
            </Box>
            <Button
              onClick={handleSendRemoteStop}
              variant="danger"
              $size="large"
              loading$={isLoadingStop}
              disabled={isLoadingStop || !activities?.length}
            >
              {t('Stop')}
            </Button>
          </Card>
        </LoaderWithFallback>
      </Flex>
    </Flex>
  );
};
