import { Flex } from "../../../../shared/UI";
import { GroupCheckedEvsesList } from "./GroupCheckedEvsesList";

export const GroupEvses = () => {
  return (
    <Flex fullSize>
      <GroupCheckedEvsesList />
    </Flex>
  );
};
