import { gql, useMutation } from "@apollo/client";
import { GET_PLATFORM } from "../queries/useGetPlatform";
import { GET_PLATFORMS } from "../../../../shared/gql/queries/useGetPlatforms";

const PUBLISH_OICP_PLATFORM = gql`
  mutation publishOicpPlatform($input: HubjectPlatformInput!) {
    publishOicpPlatform(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const usePublishOicpPlatform = () => {
  const [publishOicpPlatform, { data, loading, error }] = useMutation(
    PUBLISH_OICP_PLATFORM,
    {
      // refetchQueries: [GET_PLATFORM, GET_PLATFORMS]
    }
  );
  return {
    publishOicpPlatform,
    error,
    data,
    loadingPublishOicpPlatform: loading,
  };
};
