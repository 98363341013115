import { UserAction } from "@bepower/greta-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InfrastructureState = {
  selectedAction: UserAction | null;
};
const initialState: InfrastructureState = {
  selectedAction: null,
};

const InfrastructureSlice = createSlice({
  name: "Infrastructure",
  initialState,
  reducers: {
    selectAction: (state, action: PayloadAction<UserAction | null>) => {
      state.selectedAction = action.payload;
    },
  },
});

export const { selectAction } = InfrastructureSlice.actions;
export default InfrastructureSlice.reducer;
