import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconEducation: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      width="43"
      height="35"
      viewBox="0 0 43 35"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M21.0515 34.8268L7.65509 27.5545V16.0718L0 11.8615L21.0515 0.378906L42.103 11.8615V27.1717H38.2754V13.9667L34.4479 16.0718V27.5545L21.0515 34.8268ZM21.0515 18.9425L34.1608 11.8615L21.0515 4.78058L7.94215 11.8615L21.0515 18.9425ZM21.0515 30.473L30.6204 25.3058V18.0813L21.0515 23.3442L11.4826 18.0813V25.3058L21.0515 30.473Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
