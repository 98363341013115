import React from "react";
import Svg from "../../UI/Svg";
type Props = {
  size?: string;
  fill?: string;
  iconSize?: string;
};
const DownloadThick: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      fill={fill}
      iconSize={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 48"
      {...props}
    >
      <path
        d="M36.3678 19.8482H29.7242V9.88281H19.7588V19.8482H13.1152L24.7415 31.4745L36.3678 19.8482ZM13.1152 34.7963V38.1181H36.3678V34.7963H13.1152Z"
      />
    </Svg>
  );
};

export default DownloadThick;
