import { openHandler, openMap } from "../../layout/Menu";
import RenderOnRole from "../RenderOnRole";
import MenuItems, { MenuItem } from "./MenuItems";

export type DropdownProps = {
  submenus: MenuItem[];
  dropdown: boolean;
  depthLevel: any;
  isOpen: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
  submenus,
  dropdown,
  depthLevel,
  isOpen,
}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  const onChange = (value: string) => {
    openHandler(value);
    openMap[value] = !openMap[value];
  };
  return (
    <ul
      className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""} ${
        isOpen ? "relative" : "absolute"
      }`}
    >
      {" "}
      {submenus.map((submenu, index) => (
        <RenderOnRole key={index} roles={submenu.roles}>
          <MenuItems
            isOpen={isOpen}
            item={submenu}
            depthLevel={depthLevel}
            id={10000}
            onClick={onChange}
          />
        </RenderOnRole>
      ))}{" "}
    </ul>
  );
};

export default Dropdown;
