import { memo } from "react";
import Svg from "../../UI/Svg";

type Props = {
  iconSize?: string;
  fill?: string;
  stroke?: string;
};

const SvgComponent = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={14}
    viewBox="0 0 19 14"
    fill="#3D729C"
    stroke="white"
    {...props}
  >
    <rect x="0.5" y="4.5" width="18" height="5" stroke="#3D729C" fill="none" />
    <rect x="2" y="6" width="6" height="2" stroke="none" />
    <path
      d="M12.6568 12.6569L12.5062 12.4844L12.3555 12.6569C11.7473 13.3532 10.8664 13.8 9.87654 13.8C9.49918 13.8 9.2 13.4938 9.2 13.1481C9.2 12.7951 9.50651 12.4716 9.87654 12.4716C10.954 12.4716 11.8296 11.5953 11.8296 10.5185V7.88887V7.68887H11.6296H10.7531C10.3794 7.68887 10.0765 7.38658 10.0765 7.01233C10.0765 6.63807 10.3794 6.33579 10.7531 6.33579H11.6296H11.8296V6.13579V3.50616C11.8296 2.42936 10.954 1.55308 9.87654 1.55308C9.5029 1.55308 9.2 1.25057 9.2 0.876541C9.2 0.502516 9.5029 0.2 9.87654 0.2C10.8664 0.2 11.7473 0.646791 12.3555 1.34312L12.5062 1.51556L12.6568 1.34312C13.265 0.646791 14.1459 0.2 15.1358 0.2C15.5094 0.2 15.8123 0.502516 15.8123 0.876541C15.8123 1.25057 15.5094 1.55308 15.1358 1.55308C14.0583 1.55308 13.1827 2.42936 13.1827 3.50616V6.13579V6.33579H13.3827H14.2592C14.6329 6.33579 14.9358 6.6383 14.9358 7.01233C14.9358 7.38635 14.6329 7.68887 14.2592 7.68887H13.3827H13.1827V7.88887V10.5185C13.1827 11.5953 14.0583 12.4716 15.1358 12.4716C15.5102 12.4716 15.8123 12.7737 15.8123 13.1481C15.8123 13.5147 15.517 13.8 15.1358 13.8C14.1459 13.8 13.265 13.3532 12.6568 12.6569Z"
      strokeWidth="0.4"
    />
  </Svg>
);
const Rename = memo(SvgComponent);
export default Rename;
