/** @format */

import WebSocket from "isomorphic-ws";
import moment from "moment";
import { v4 } from "uuid";
import { SimulatorEvcLabel, bootNotificationData } from "./nina.evcInfo";
import { generateENSTOMeterValue } from "./nina.meterValues";
import {
  KeyValue,
  NinaEvse,
  NinaState,
  NinaTimer,
  NinaVendor,
  OcppCpMethod,
  OcppCsMethos,
  OcppMethodDataType,
  OcppMethodMessage,
  OcppMethodResponse,
  NinaMeterValueConfig,
} from "./nina.ocpp.types";
import {
  OcppBootNotificationConf,
  OcppChangeAvailabilityType,
  OcppChargingPointErrorCode,
  OcppChargingPointStatus,
  OcppDataTransferConf,
  OcppHeartbeatConf,
  OcppTriggerMessageAction,
} from "./ocpp1.6";

export class Nina {
  private protocol = "ocpp1.6";
  private headers = {
    "Sec-WebSocket-Protocol": "ocpp1.6",
    "Sec-WebSocket-Version": "13",
  };
  private configRebootReq: string[] = []; // Configuration keys that need a reboot
  private config: KeyValue[] = [];
  private ws: WebSocket;

  private pendingCalls = new Map();
  private pendingResponses = new Map();
  private authTimeout: NodeJS.Timeout | null = null;
  private reservationTimeout: NinaTimer = {};
  private heartbeatInterval: NodeJS.Timeout | null = null;
  private MSG_CALL = 2;
  private MSG_CALLRESULT = 3;
  private meterValuesInterval: NinaTimer = {};
  private meterValuesConfig: NinaMeterValueConfig | null;
  private sendStatusNotificationToConnector0: boolean;
  private sendStatusNotificationToOtherConnectors: boolean;
  private setState;
  private parkingData: {
    [key: string]: {
      MvTimestamp: number | null;
      inGrace: boolean;
      penalty: boolean;
      overtime: number;
    };
  } = {};
  public state: NinaState = {
    wsStatus: `CLOSED`,
    status: OcppChargingPointStatus.Available,
    EVSEs: {},
    pendingCalls: 0,
    pendingResponses: 0,
    logs: [],
  };

  constructor(
    private backend: string,
    private evcLabel: string,
    numberOfEvses = 2,
    setState: any,
    private meterValueConfig: NinaMeterValueConfig | null = null,
    sendStatusNotificationToConnector0: boolean = true,
    sendStatusNotificationToOtherConnectors: boolean = true
  ) {
    for (let evseNumber = 1; evseNumber <= numberOfEvses; evseNumber++) {
      const label = `${evcLabel}-${evseNumber}`;
      this.state.EVSEs[label] = {
        label,
        connectorId: evseNumber,
        cableConnected: false,
        status: OcppChargingPointStatus.Available,
        statusInfo: ``,
        meter: 0,
        transactionId: 0,
        authorized: {
          status: false,
          type: null,
          idTag: ``,
        },
        reserved: {
          status: false,
          expiryDate: null,
          idTag: null,
          reservationId: null,
        },
      };
      this.meterValuesInterval[label] = null;
      this.parkingData[label] = {
        MvTimestamp: null,
        inGrace: false,
        penalty: false,
        overtime: 25,
      };
    }
    this.ws = new WebSocket(`${this.backend}/${this.evcLabel}`, this.protocol, {
      headers: this.headers,
    });
    this.setState = setState;
    this.meterValuesConfig = meterValueConfig;
    this.sendStatusNotificationToConnector0 =
      sendStatusNotificationToConnector0;
    this.sendStatusNotificationToOtherConnectors =
      sendStatusNotificationToOtherConnectors;
    setState({ ...this.state });
    this.log(`Welcome to NINA 💫`, `DEBUG`);
    this.attachListeners();
  }

  public log(
    message: string,
    level: `DEBUG` | `INFO` | `ERROR` | `OCPP`,
    data?: any,
    requestData?: any
  ) {
    this.state.logs.push({
      date: new Date(),
      level,
      message,
      data: data ? JSON.stringify(data) : ``,
      requestData: requestData ? JSON.stringify(requestData) : ``,
    });
    console.log(message, data);
    this.setState({ ...this.state });
  }

  public clearLogs() {
    this.state.logs = [];
    this.setState({ ...this.state });
  }

  public attachListeners() {
    this.log(`Attaching listeners`, `DEBUG`);
    this.ws.onopen = (e) => {
      this.onopen(e);
      this.updateWsState();
    };
    this.ws.onmessage = (e) => {
      this.onmessage(e);
    };
    this.ws.onclose = (e) => {
      this.onclose(e);
      this.updateWsState();
    };
    this.ws.onerror = (e) => {
      this.onerror(e);
      this.updateWsState();
    };
  }

  disconnect() {
    this.state.EVSEs = {};
    this.ws.close();
  }

  onopen(event: WebSocket.Event) {
    if (this.sendStatusNotificationToConnector0) {
      this.sendStatusNotification(0);
    }
    this.sendBootNotification();
  }

  onmessage(event: WebSocket.MessageEvent) {
    const [direction, msgId, method, data] = JSON.parse(String(event.data));

    if (direction === this.MSG_CALLRESULT) {
      return this.onResponse(msgId, event);
    }

    this.pendingCalls.set(msgId, { method, data });
    this.state.pendingCalls = this.pendingCalls.size;
    this.setState({ ...this.state });
    this.log(`Incoming call`, `OCPP`, JSON.parse(String(event.data)));

    switch (method) {
      case OcppCsMethos.TriggerMessage:
        return this.onTriggerMessage(msgId, data);
      case OcppCsMethos.GetConfiguration:
        return this.reply({
          msgId,
          responseData: { configurationKey: this.config },
        });
      case OcppCsMethos.RemoteStartTransaction:
        return this.onRemoteStartTransaction(msgId, data);
      case OcppCsMethos.RemoteStopTransaction:
        return this.onRemoteStopTransaction(msgId, data);
      case OcppCsMethos.ReserveNow:
        return this.onReserveNow(msgId, data);
      case OcppCsMethos.CancelReservation:
        return this.onCancelReservation(msgId, data);
      case OcppCsMethos.ChangeAvailability:
        return this.onChangeAvailability(msgId, data);
      case OcppCsMethos.ChangeConfiguration:
        return this.onChangeConfiguration(msgId, data);
      case OcppCsMethos.GetDiagnostics:
        return this.onGetDiagnostics(msgId, data);
      case OcppCsMethos.Reset:
        return this.onReset(msgId, data);
      case OcppCsMethos.SetChargingProfile:
        return this.onSetChargingProfile(msgId, data);
      case OcppCsMethos.UnlockConnector:
        return this.onUnlockConnector(msgId, data);
      case OcppCsMethos.ClearCache:
        return this.reply({
          msgId,
          responseData: { status: `Accepted` },
        });
      case OcppCsMethos.UpdateFirmware:
        return this.onUpdateFirmware(msgId, data);
      case OcppCsMethos.GetLocalListVersion:
        return this.reply({
          msgId,
          responseData: {
            listVersion: -1,
          },
        });
      case OcppCsMethos.SendLocalList:
        return this.reply({
          msgId,
          responseData: {
            status: `NotSupported`,
          },
        });
      case OcppCsMethos.ClearChargingProfile:
        return this.reply({
          msgId,
          responseData: { status: `Unknown` },
        });
      case OcppCpMethod.DataTransfer:
        return this.reply({
          msgId,
          responseData: {
            status: `UnknownVendorId`,
            data: `Module not Implemented`,
          },
        });
      default:
        break;
    }
  }

  onResponse(msgId: string, event: WebSocket.MessageEvent) {
    const [direction, id, method, requestData] =
      this.pendingResponses.get(msgId);
    const [resDir, resId, responseData] = JSON.parse(String(event.data));
    this.log(
      `Incoming response`,
      `OCPP`,
      JSON.parse(String(event.data)),
      this.pendingResponses.get(msgId)
    );
    switch (method) {
      case `Authorize`:
        Object.keys(this.state.EVSEs).forEach((evseLabel) => {
          // Authorize Available/Preparing and not already authorized evses
          if (
            ["Available", "Preparing"].includes(
              this.state.EVSEs[evseLabel].status || ``
            ) &&
            !this.state.EVSEs[evseLabel].authorized.status
          ) {
            const { status } = responseData.idTagInfo;
            const { idTag } = requestData;
            this.state.EVSEs[evseLabel].authorized = {
              status: status === `Accepted`,
              type: `local`,
              idTag: status ? idTag : null,
            };
          }
        });
        // Start charging on the first connected authorized evse
        const evseToStart = Object.values(this.state.EVSEs).find(
          (evse) =>
            evse.authorized.status &&
            evse.authorized.idTag === requestData.idTag &&
            evse.cableConnected
        );
        if (evseToStart) {
          return this.startCharge(evseToStart);
        }
        this.authTimeout = setTimeout(() => {
          Object.keys(this.state.EVSEs).forEach((evseLabel) => {
            this.state.EVSEs[evseLabel].authorized = {
              status: false,
              type: null,
              idTag: ``,
            };
            console.log(`AuthExpired`);
            this.setState({ ...this.state });
          });
        }, 2 * 60 * 1000);
        break;
      case "StartTransaction":
        const evse = this.getEvseFromConnectorId(requestData.connectorId);
        this.state.EVSEs[evse.label].transactionId = responseData.transactionId;
        break;
      case `BootNotification`:
        const bootNotificationData: OcppBootNotificationConf = responseData;
        switch (bootNotificationData.status) {
          case `Accepted`:
            this.sendHeartbeat();
            this.heartbeatInterval = setInterval(() => {
              this.sendHeartbeat();
              console.log(this.authTimeout);
            }, bootNotificationData.interval * 1000);
            break;
          case `Pending`:
            console.log(
              `The Charging point shouldn't send any message and wait the Trigger Message for another BootNotification`
            );
            break;
          case `Rejected`:
            console.log(
              `The Charging point shouldn't send or recive any message`
            );
            break;
          default:
            setTimeout(
              () => {
                this.sendBootNotification();
              },
              bootNotificationData.interval === 0
                ? 10000
                : bootNotificationData.interval * 1000
            );
        }
        break;
      case `DataTransfer`:
        const dataTransferData: OcppDataTransferConf = responseData;
        switch (dataTransferData.status) {
          case `Accepted`:
          case `Rejected`:
          case `UnknownMessageId`:
          case `UnknownVendorId`:
          default:
            console.log(dataTransferData.status);
            console.log(dataTransferData.data);
        }
        break;
      case `Heartbeat`:
        const HeartbeatData: OcppHeartbeatConf = responseData;
        break;
      default:
        break;
    }
    this.pendingResponses.delete(msgId);
    this.state.pendingResponses = this.pendingResponses.size;
    this.setState({ ...this.state });
  }

  getEvseFromConnectorId(connectorId: number): NinaEvse {
    const evse = Object.values(this.state.EVSEs).find(
      (evse) => evse.connectorId === connectorId
    );
    if (!evse) {
      throw Error(`Invalid EVSE`);
    }
    return evse;
  }

  onReserveNow(
    msgId: string,
    //TODO add the type of data
    data: any
  ) {
    const { expiryDate, connectorId, idTag, reservationId } = data;

    const evse = this.getEvseFromConnectorId(connectorId);
    if (evse.cableConnected) {
      return this.reply({
        msgId,
        responseData: { status: `Occupied` }, // Unavailable
      });
    }
    if (evse.status !== `Available`) {
      return this.reply({
        msgId,
        responseData: { status: `Unavailable` },
      });
    }
    this.state.EVSEs[evse.label].reserved = {
      status: true,
      expiryDate,
      idTag,
      reservationId,
    };
    this.reply({
      msgId,
      responseData: { status: `Accepted` },
    });
    this.reservationTimeout[evse.label] = setTimeout(() => {
      this.state.EVSEs[evse.label].reserved = {
        status: false,
        expiryDate: null,
        idTag: null,
        reservationId: null,
      };
    }, 30 * 60 * 1000);
  }

  onTriggerMessage(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.TriggerMessage]
  ) {
    const { requestedMessage, connectorId } = data;
    console.info(`trigger message ${msgId}: ${requestedMessage}`, data);
    if (Object.values(OcppTriggerMessageAction).includes(requestedMessage)) {
      this.reply({
        msgId,
        responseData: { status: `Accepted` },
      });
    }
    switch (requestedMessage) {
      case OcppTriggerMessageAction.StatusNotification:
        this.sendStatusNotification(connectorId ?? 0);
        break;
      case OcppTriggerMessageAction.BootNotification:
        console.log(`Message BootNotification not implemented yet`);
        this.sendBootNotification();
        break;
      case OcppTriggerMessageAction.DiagnosticsStatusNotification:
        this.sendDiagnosticsStatus(true);
        break;
      case OcppTriggerMessageAction.Heartbeat:
        this.sendHeartbeat();
        break;
      case OcppTriggerMessageAction.FirmwareStatusNotification:
        this.sendFirmwareStatusNotification(true);
        break;
      case OcppTriggerMessageAction.MeterValues:
        if (connectorId === 0 || !connectorId) {
          Object.keys(this.state.EVSEs).forEach((label) =>
            this.sendMeter(this.state.EVSEs[label])
          );
        } else {
          const evse = this.getEvseFromConnectorId(connectorId);
          this.sendMeter(evse);
        }
        break;
      default:
        this.reply({
          msgId,
          responseData: { status: `Rejected` },
        });
        break;
    }
  }

  onRemoteStartTransaction(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.RemoteStartTransaction]
  ) {
    const { idTag, connectorId } = data;
    if (!connectorId) {
      return this.reply({
        msgId,
        responseData: { status: `Rejected` },
      });
    }
    const evse = this.getEvseFromConnectorId(connectorId);
    const isAlreadyAuthorized =
      evse.authorized.status && evse.authorized.idTag !== idTag;
    const canStartCharge = ["Available", "Preparing"].includes(
      evse.status || ``
    );
    if (this.isEvseReserved(evse) && evse.reserved.idTag !== idTag) {
      console.log(`Reserved by someone else`);
      return this.reply({
        msgId,
        responseData: { status: `Rejected` },
      });
    }
    // check if connector is not already authorized
    if (isAlreadyAuthorized || !canStartCharge) {
      console.log(`Connector already authorized by someone else`);
      return this.reply({
        msgId,
        responseData: { status: `Rejected` },
      });
    }
    // seat auth
    this.state.EVSEs[evse.label].authorized = {
      status: true,
      type: `remote`,
      idTag,
    };
    // Start
    if (evse.cableConnected) {
      this.startCharge(evse);
    }
    // Reply
    this.state.EVSEs[evse.label].meter = 0;
    this.setState({ ...this.state });
    return this.reply({ msgId, responseData: { status: `Accepted` } });
  }

  onRemoteStopTransaction(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.RemoteStopTransaction]
  ) {
    const { transactionId } = data;
    const evse = Object.values(this.state.EVSEs).find(
      (evse) => evse.transactionId === transactionId
    );
    if (!evse) {
      return this.reply({
        msgId,
        responseData: { status: `Rejected` },
      });
    }
    // Stop
    this.stopCharge(evse);
    // Reply
    this.setState({ ...this.state });
    return this.reply({ msgId, responseData: { status: `Accepted` } });
  }

  onerror(event: WebSocket.ErrorEvent) {
    console.error(`onerror`, event);
  }

  onclose(event: WebSocket.CloseEvent) {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    console.error(`onclose`, event);
  }

  private updateWsState() {
    switch (this.ws.readyState) {
      case 0:
        this.state.wsStatus = "CONNECTING";
        break;
      case 1:
        this.state.wsStatus = "OPEN";
        break;
      case 2:
        this.state.wsStatus = "CLOSING";
        break;
      case 3:
        this.state.wsStatus = "CLOSED";
        break;
      default:
        break;
    }
    this.setState({ ...this.state });
  }

  send(event: OcppMethodMessage<OcppCpMethod>) {
    const msgId = v4();
    const payload = [this.MSG_CALL, msgId, event.method, event.methodMessage];
    this.pendingResponses.set(msgId, payload);
    this.state.pendingResponses = this.pendingResponses.size;
    this.setState({ ...this.state });
    this.log(`Outgoing call`, `OCPP`, payload);
    return this.ws.send(JSON.stringify(payload));
  }

  reply(responseMessage: OcppMethodResponse<OcppCsMethos>) {
    const { msgId, responseData } = responseMessage;
    const payload = [this.MSG_CALLRESULT, msgId, responseData];
    const originalMessage = this.pendingCalls.get(msgId);
    this.pendingCalls.delete(msgId);
    this.state.pendingCalls = this.pendingCalls.size;
    this.setState({ ...this.state });
    this.log(`Replying to call`, `OCPP`, payload, originalMessage);
    return this.ws.send(JSON.stringify(payload));
  }

  static date(date?: Date) {
    return (
      moment(date || new Date())
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss")
        .toString() + "Z"
    );
  }

  public authorizeRFID(idTag: string) {
    // Is something already authorized with this RFID?
    const previouslyAuthorizedEvse = Object.values(this.state.EVSEs).filter(
      (evse) => evse.authorized.status && evse.authorized.idTag === idTag
    );
    if (previouslyAuthorizedEvse) {
      previouslyAuthorizedEvse.forEach((authorizedEvse) => {
        if (authorizedEvse.status !== `Charging`) {
          // De-authorize not charging evse
          this.state.EVSEs[authorizedEvse.label].authorized = {
            status: false,
            type: null,
            idTag: ``,
          };
        } else {
          // Stop charging evse
          this.stopCharge(authorizedEvse);
          this.state.EVSEs[authorizedEvse.label].meter = 0;
        }
      });
    }
    // Need to send authorize?
    const needToAuth =
      Object.values(this.state.EVSEs).filter((evse) => !evse.authorized.status)
        .length > 0;
    if (needToAuth && !previouslyAuthorizedEvse.length) {
      console.log(`need to auth`);
      this.send({
        method: OcppCpMethod.Authorize,
        methodMessage: { idTag },
      });
    }
  }

  public toggleCable(evseLabel: string) {
    console.log(`Connect cable`, evseLabel);
    const evse = this.state.EVSEs[evseLabel];
    // Connect cable
    this.state.EVSEs[evseLabel].cableConnected = !evse.cableConnected;
    // Need to start or stop?
    if (evse.cableConnected && evse.authorized.status) {
      return this.startCharge(evse);
    }
    if (
      !evse.cableConnected &&
      ["SuspendedEV", "Charging"].includes(evse.status)
    ) {
      return this.stopCharge(evse);
    }

    // Change status
    switch (evse.status) {
      case "Available":
        this.changeEVSEStatus(evseLabel, OcppChargingPointStatus.Preparing);
        break;
      case "Preparing":
      case "Finishing":
      case "SuspendedEV":
        this.changeEVSEStatus(evseLabel, OcppChargingPointStatus.Available);
        break;
      default:
        break;
    }
    this.setState({ ...this.state });
  }

  startCharge(evse: NinaEvse) {
    console.log("Start charge");
    // Check reservations
    if (this.isEvseReserved(evse)) {
      if (evse.reserved.idTag !== evse.authorized.idTag) {
        return false;
      }
    }

    // Send Start
    this.send({
      method: OcppCpMethod.StartTransaction,
      methodMessage: {
        connectorId: evse.connectorId,
        idTag: evse.authorized.idTag!,
        meterStart: evse.meter,
        reservationId: this.isEvseReserved(evse)
          ? evse.reserved.reservationId!
          : 0,
        timestamp: Nina.date(),
      },
    });

    if (this.isEvseReserved(evse)) {
      // Conclude reservation
      console.log("Conclude reservation");
      this.state.EVSEs[evse.label].reserved = {
        status: false,
        idTag: null,
        expiryDate: null,
        reservationId: null,
      };
      if (this.reservationTimeout[evse.label]) {
        clearTimeout(this.reservationTimeout[evse.label]!);
        this.reservationTimeout[evse.label] = null;
      }
    }
    // Change Status
    this.changeEVSEStatus(evse.label, OcppChargingPointStatus.Charging);
    // Remove auth for other EVSEs
    Object.values(this.state.EVSEs).forEach((otherEvse) => {
      if (
        evse.label !== otherEvse.label &&
        otherEvse.authorized.idTag === evse.authorized.idTag
      ) {
        console.log(`Remove auth for other EVSEs`);
        this.state.EVSEs[otherEvse.label].authorized = {
          status: false,
          type: null,
          idTag: ``,
        };
      }
    });

    if (this.authTimeout) {
      clearTimeout(this.authTimeout);
      this.authTimeout = null;
    }
    if (this.meterValueConfig) {
      this.meterValuesInterval[evse.label] = setInterval(() => {
        if (this?.state?.EVSEs?.[evse?.label]) {
          this.sendMeter(
            this.state.EVSEs[evse.label],
            Number(this.meterValueConfig?.value)
          );
        }
      }, Number(this.meterValueConfig.time) * 60 * 1000);
    } else {
      this.meterValuesInterval[evse.label] = setInterval(() => {
        if (this?.state?.EVSEs?.[evse?.label]) {
          this.sendMeter(this.state.EVSEs[evse.label]);
        }
      }, 1 * 60 * 1000);
    }

    this.setState({ ...this.state });
  }

  isEvseReserved(evse: NinaEvse) {
    console.log("isEvseReserved", evse.reserved.status);
    const isDateExpired = moment(evse.reserved.expiryDate).isBefore(moment());
    console.log(`isDateExpired?`, isDateExpired);
    return evse.reserved.status && !isDateExpired;
  }

  changeEVSEStatus(evseLabel: string, status: OcppChargingPointStatus) {
    if (this.state.EVSEs[evseLabel].statusInfo === `Scheduled`) {
      // "Unavailable" because the only case when a status i Scheduled is when Change availability try to change a connector status that are in middle of a charging session
      status = OcppChargingPointStatus.Unavailable;
      this.state.EVSEs[evseLabel].statusInfo = undefined;
    }
    this.state.EVSEs[evseLabel].status = status;
    this.sendStatusNotification(this.state.EVSEs[evseLabel].connectorId);
    this.setState({ ...this.state });
  }

  stopCharge(evse: NinaEvse) {
    console.log("Stop charge");
    // Send Stop
    const lastMvTime =
      this.parkingData[evse.label].MvTimestamp ?? new Date().getTime();
    const parkingOvertime = this.parkingData[evse.label].overtime;
    this.send({
      method: OcppCpMethod.StopTransaction,
      methodMessage: {
        connectorId: evse.connectorId,
        idTag: evse.authorized.idTag,
        transactionId: evse.transactionId,
        meterStop: evse.meter,
        timestamp: this.parkingData[evse.label].penalty
          ? Nina.date(new Date(lastMvTime + 60000 * (60 + parkingOvertime)))
          : Nina.date(),
      },
    });
    // Change Status
    this.changeEVSEStatus(
      evse.label,
      this.state.EVSEs[evse.label].cableConnected
        ? OcppChargingPointStatus.Finishing
        : OcppChargingPointStatus.Available
    );
    // Remove transaction and auth
    this.state.EVSEs[evse.label].transactionId = 0;
    this.state.EVSEs[evse.label].authorized = {
      status: false,
      type: null,
      idTag: ``,
    };
    if (this.meterValuesInterval[evse.label]) {
      clearInterval(this.meterValuesInterval[evse.label]!);
      this.meterValuesInterval[evse.label] = null;
    }
    this.parkingData[evse.label].inGrace = false;
    this.setState({ ...this.state });
  }

  onCancelReservation(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.CancelReservation]
  ) {
    const { reservationId } = data;
    const reservedEvse = Object.values(this.state.EVSEs).find(
      (evse) => evse.reserved.reservationId === reservationId
    );
    if (reservedEvse) {
      // Removing the reservation
      this.state.EVSEs[reservedEvse.label].reserved = {
        status: false,
        expiryDate: null,
        idTag: null,
        reservationId: null,
      };
    }
    return this.reply({
      msgId,
      responseData: { status: reservedEvse ? `Accepted` : `Rejected` },
    });
  }

  onChangeAvailability(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.ChangeAvailability]
  ) {
    const { connectorId, type } = data;

    const changeAvailabilityFn = (connector: number) => {
      const evse = this.getEvseFromConnectorId(connector);
      const isNoOperative = [
        OcppChargingPointStatus.Unavailable,
        OcppChargingPointStatus.Faulted,
      ].includes(this.state.EVSEs[evse.label].status);
      switch (type) {
        case OcppChangeAvailabilityType.OPERATIVE:
          if (isNoOperative) {
            this.state.EVSEs[evse.label].status =
              OcppChargingPointStatus.Available;

            this.sendStatusNotification(
              this.state.EVSEs[evse.label].connectorId
            );
          }
          return this.reply({
            msgId,
            responseData: { status: `Accepted` },
          });
        case OcppChangeAvailabilityType.INOPERATIVE:
          if (
            this.state.EVSEs[evse.label].status ===
            OcppChargingPointStatus.Available
          ) {
            this.state.EVSEs[evse.label].status =
              OcppChargingPointStatus.Unavailable;
            this.sendStatusNotification(
              this.state.EVSEs[evse.label].connectorId
            );
            return this.reply({
              msgId,
              responseData: { status: `Accepted` },
            });
          } else if (isNoOperative) {
            return this.reply({
              msgId,
              responseData: { status: `Accepted` },
            });
          } else if (this.state.EVSEs[evse.label].transactionId !== 0) {
            this.state.EVSEs[evse.label].statusInfo = `Scheduled`;
            return this.reply({
              msgId,
              responseData: { status: `Scheduled` },
            });
          }
          break;
        default:
          return this.reply({
            msgId,
            responseData: { status: `Rejected` },
          });
      }
    };
    if (connectorId === 0) {
      return Object.keys(this.state.EVSEs).map((evse) => {
        return changeAvailabilityFn(this.state.EVSEs[evse].connectorId);
      });
    } else {
      return changeAvailabilityFn(connectorId);
    }
  }

  onChangeConfiguration(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.ChangeConfiguration]
  ) {
    const configKeys = this.config.map((conf) => conf.key);
    if (configKeys.includes(data.key)) {
      const { key, value } = data;
      this.config.forEach((conf) => {
        if (conf.key === key && conf.readonly === true) {
          return this.reply({
            msgId,
            responseData: {
              status: `Rejected`,
            },
          });
        } else if (conf.key === key) {
          conf.value = value;
        }
      });
      return this.reply({
        msgId,
        responseData: {
          status: this.configRebootReq.includes(key)
            ? `Accepted`
            : `RebootRequired`,
        },
      });
    } else {
      return this.reply({
        msgId,
        responseData: { status: `NotSupported` },
      });
    }
  }

  onGetDiagnostics(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.GetDiagnostics]
  ) {
    const { location } = data; // the location (directory) where the diagnostic file shall be uploaded to.
    const replyResult = this.reply({
      msgId,
      responseData: {
        fileName: `Optional. This contains
      the name of the file with
      diagnostic information
      that will be uploaded.
      This field is not present
      when no diagnostic
      information is available.`,
      },
    });
    this.sendDiagnosticsStatus(false);
    return replyResult;
  }

  onReset(msgId: string, data: OcppMethodDataType[OcppCsMethos.Reset]) {
    const { type } = data;
    switch (type) {
      case `Soft`:
        // FIXME: Refactor workflow
        const activeTransaction =
          Object.keys(this.state.EVSEs).filter(
            (label) => this.state.EVSEs[label].transactionId !== 0
          ).length > 0;
        return this.reply({
          msgId,
          responseData: { status: activeTransaction ? `Rejected` : `Accepted` },
        });

      case `Hard`:
        Object.keys(this.state.EVSEs).forEach((label) => {
          if (this.state.EVSEs[label].transactionId !== 0) {
            this.stopCharge(this.state.EVSEs[label]);
          }
        });
        let replyResult = this.reply({
          msgId,
          responseData: { status: `Accepted` },
        });
        this.sendBootNotification();
        return replyResult;
      default:
        return this.reply({
          msgId,
          responseData: { status: `Rejected` },
        });
    }
  }

  onSetChargingProfile(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.SetChargingProfile]
  ) {
    // TODO: implement Charging profile in the Nina object, in reality only the Aplitronic EVC have the Charging profile.
    const { connectorId, csChargingProfiles } = data;
    const evse = this.getEvseFromConnectorId(connectorId);
    if (this.state.EVSEs[evse.label].EVC?.vendor === NinaVendor.ENSTO) {
      this.reply({
        msgId,
        responseData: { status: `NotImplemented` },
      });
    }
    return this.reply({
      msgId,
      responseData: { status: `NotImplemented` },
    });
  }

  onUnlockConnector(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.UnlockConnector]
  ) {
    const evse = this.getEvseFromConnectorId(data.connectorId);
    if (evse.cableConnected === false) {
      return this.reply({
        msgId,
        responseData: {
          status: `UnlockFailed`,
        },
      });
    } else if (evse.transactionId !== 0) {
      this.stopCharge(evse);
    }
    return this.reply({
      msgId,
      responseData: {
        status: `Unlocked`,
      },
    });
  }

  onUpdateFirmware(
    msgId: string,
    data: OcppMethodDataType[OcppCsMethos.UpdateFirmware]
  ) {
    const { location, retrieveDate } = data;
    if (!location || !retrieveDate) {
      return this.reply({
        msgId,
        responseData: {
          status: `Rejected`,
          error: `missing required information`,
        },
      });
    }
    const replyResult = this.reply({
      msgId,
      responseData: {
        status: `Accepted`,
      },
    });
    this.sendFirmwareStatusNotification(false);
    return replyResult;
  }

  sendBootNotification() {
    if (Object.keys(bootNotificationData).includes(this.evcLabel)) {
      this.send({
        method: OcppCpMethod.BootNotification,
        methodMessage: bootNotificationData[this.evcLabel as SimulatorEvcLabel],
      });
    } else {
      console.log(
        `The label: ${this.evcLabel} doesn't have the bootNotification Data`
      );
    }
  }

  sendDataTransfer() {
    return this.send({
      method: OcppCpMethod.DataTransfer,
      methodMessage: { status: `Installed` },
    });
  }

  sendDiagnosticsStatus(triggerMessage: boolean) {
    this.send({
      method: OcppCpMethod.DiagnosticsStatusNotification,
      methodMessage: {
        status: triggerMessage ? `Idle` : `Uploaded`,
      },
    });
  }

  sendHeartbeat() {
    return this.send({
      method: OcppCpMethod.Heartbeat,
      methodMessage: {},
    });
  }

  sendFirmwareStatusNotification(triggerMessage: boolean) {
    this.send({
      method: OcppCpMethod.FirmwareStatusNotification,
      methodMessage: {
        status: triggerMessage ? `Idle` : `Installed`,
      },
    });
  }
  public sendStatusNotification(connectorId: number) {
    console.log(`StatusNotification`, connectorId);
    if (!this.state) {
      console.log("Empty state");
      return;
    }
    this.send({
      method: OcppCpMethod.StatusNotification,
      methodMessage: {
        connectorId: connectorId,
        errorCode: OcppChargingPointErrorCode.NoError,
        status:
          connectorId === 0
            ? this.state.status
            : this.getEvseFromConnectorId(connectorId).status,
        info:
          connectorId !== 0 &&
          this.getEvseFromConnectorId(connectorId).status ===
            OcppChargingPointStatus.SuspendedEV
            ? `No energy flowing to vehicle`
            : "",
      },
    });
    if (connectorId === 0 && this.sendStatusNotificationToOtherConnectors) {
      for (
        let index = 1;
        index <= Object.keys(this.state.EVSEs).length;
        index++
      ) {
        this.sendStatusNotification(index);
      }
    }
  }

  sendMeter(evse: NinaEvse, value?: number) {
    if (value) {
      this.state.EVSEs[evse.label].meter += value;
      if (
        this.state.EVSEs[evse.label].status ===
        OcppChargingPointStatus.Preparing
      ) {
        this.changeEVSEStatus(evse.label, OcppChargingPointStatus.Charging);
      }
    }

    switch (this.state.EVSEs[evse.label].EVC?.vendor) {
      case `ENSTO`:
        this.send({
          method: OcppCpMethod.MeterValues,
          methodMessage: generateENSTOMeterValue(
            evse,
            this.state.EVSEs[evse.label].meter
          ),
        });
        break;
      default:
        this.send({
          method: OcppCpMethod.MeterValues,
          methodMessage: generateENSTOMeterValue(
            evse,
            this.state.EVSEs[evse.label].meter
          ),
        });
    }
    if (
      !this.parkingData[evse.label].inGrace &&
      !this.parkingData[evse.label].penalty
    ) {
      this.parkingData[evse.label].MvTimestamp = new Date().getTime();
    }
  }

  setParkingPenalty(evse: NinaEvse) {
    if (
      evse &&
      this.state.EVSEs[evse.label].status ===
        OcppChargingPointStatus.SuspendedEV &&
      this.parkingData[evse.label].inGrace
    ) {
      this.parkingData[evse.label].penalty = true;
      this.sendMeter(evse);
      return;
    } else {
      return;
    }
  }

  startGracePeriod(evse: NinaEvse) {
    if (
      this.state.EVSEs[evse.label].status === OcppChargingPointStatus.Charging
    ) {
      this.parkingData[evse.label].inGrace = true;
      this.changeEVSEStatus(evse.label, OcppChargingPointStatus.SuspendedEV);
      this.sendStatusNotification(evse.connectorId);
      this.sendMeter(evse);
      return;
    } else {
      return;
    }
  }
}
