import { SVGProps } from "react";
import AC from "./AC";
import DC from "./DC";
import { ResizableNumberBadgeIcon } from "./ResizableNumberBadgeIcon";

const MarkerWipByOther = ({
  cpTot = 0,
  evcModel,
  ...props
}: SVGProps<SVGSVGElement> & { cpTot?: number; evcModel?: "AC" | "DC" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={27}
    fill="none"
    viewBox="-3 -5 32 31"
    {...props}
  >
    <g filter="url(#filter0_d_3367_2824)">
      <path
        d="M16.6619 1.75H8.62935C6.01088 1.75 3.88574 3.87514 3.88574 6.49362V14.4313C3.88574 17.0498 6.01088 19.1749 8.62935 19.1749H10.9822L12.2851 20.6802C12.4432 20.8636 12.7278 20.8636 12.8796 20.6802L14.1825 19.1749H16.6619C19.2803 19.1749 21.4055 17.0498 21.4055 14.4313V6.49362C21.4055 3.87514 19.2803 1.75 16.6619 1.75Z"
        fill="white"
      />
      <path
        d="M10.9819 19.2763L11.5891 18.7514L11.3487 18.4731H10.9819V19.2763ZM14.2265 19.2763V18.4731H13.8597L13.6194 18.7514L14.2265 19.2763ZM4.60018 6.34208C4.60018 4.13471 6.3901 2.33846 8.60378 2.33846V0.738281C5.51095 0.738281 3 3.24924 3 6.34208H4.60018ZM4.60018 14.4695V6.34208H3V14.4758H4.60018V14.4695ZM8.60378 18.4731C6.39642 18.4731 4.60018 16.6832 4.60018 14.4695H3C3 17.5623 5.51095 20.0733 8.60378 20.0733V18.4731ZM10.9819 18.4731H8.60378V20.0733H10.9819V18.4731ZM12.9046 20.2757L11.5828 18.7514L10.3747 19.8013L11.6966 21.3256L12.9046 20.2757ZM12.2975 20.2757C12.4556 20.0922 12.7402 20.0922 12.9046 20.2757L11.6966 21.3256C12.1773 21.8758 13.0311 21.8758 13.5118 21.3256L12.2975 20.2757ZM13.6194 18.7514L12.2975 20.2757L13.5055 21.3256L14.8274 19.8013L13.6194 18.7514ZM16.7312 18.4731H14.2265V20.0733H16.7312V18.4731ZM20.7348 14.4695C20.7348 16.6768 18.9448 18.4731 16.7312 18.4731V20.0733C19.824 20.0733 22.3349 17.5623 22.3349 14.4695H20.7348ZM20.7348 6.34208V14.4758H22.3349V6.34208H20.7348ZM16.7312 2.33846C18.9385 2.33846 20.7348 4.12839 20.7348 6.34208H22.3349C22.3349 3.24924 19.824 0.738281 16.7312 0.738281V2.33846ZM8.60378 2.33846H16.7375V0.738281H8.60378V2.33846Z"
        fill="#122967"
      />
    </g>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="8"
      y="7"
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
    >
      <path
        d="M10.3966 3.17448C9.59615 1.43111 7.51654 0.261719 5.2181 0.261719H5.20756C2.91494 0.265354 0.838604 1.43584 0.040349 3.17448L0 3.26172L0.040349 3.34896C0.840785 5.09233 2.92039 6.26172 5.21883 6.26172H5.22937C7.52127 6.25808 9.59833 5.0876 10.3966 3.34896L10.4369 3.26172L10.3966 3.17448ZM5.22864 5.84333H5.2181C3.12068 5.84333 1.22428 4.81061 0.462014 3.26172C1.22319 1.71683 3.11523 0.683747 5.20865 0.680112H5.21847C7.31661 0.680112 9.21229 1.71283 9.97456 3.26172C9.21411 4.80661 7.32207 5.83969 5.22864 5.84333Z"
        fill="#122967"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74414 3.27988C3.74414 2.46272 4.40608 1.80078 5.22324 1.80078C6.0404 1.80078 6.70306 2.46272 6.70306 3.27988C6.70306 4.09704 6.0404 4.75971 5.22324 4.75971C4.40608 4.75971 3.74414 4.09704 3.74414 3.27988ZM4.61654 3.31783V2.40537C4.61654 2.33011 4.67539 2.27053 4.75593 2.27367H5.33204C5.42186 2.27367 5.48691 2.36147 5.45903 2.4524L5.31965 2.88512C5.30726 2.92588 5.33514 2.96664 5.3754 2.96664H5.71611C5.82142 2.96664 5.88647 3.08266 5.83381 3.17359L5.16478 4.16759C5.14 4.20835 5.09664 4.23343 5.04708 4.23343H5.00681C4.91079 4.23343 4.84575 4.13309 4.88292 4.04216L5.09044 3.54046C5.10593 3.4997 5.07805 3.4558 5.03469 3.4558H4.75283C4.67539 3.4558 4.61654 3.39622 4.61654 3.31783Z"
        fill="#122967"
      />
      <path
        d="M5.218 1.0625C4.00535 1.0625 3.01953 2.04905 3.01953 3.2617C3.01953 4.47435 4.00535 5.4609 5.218 5.4609C6.43066 5.4609 7.41721 4.47435 7.41721 3.2617C7.41721 2.04905 6.43066 1.0625 5.218 1.0625ZM5.218 5.04214C4.23618 5.04214 3.43829 4.24316 3.43829 3.2617C3.43829 2.28024 4.23654 1.48126 5.218 1.48126C6.19947 1.48126 6.99845 2.28024 6.99845 3.2617C6.99845 4.24316 6.19947 5.04214 5.218 5.04214Z"
        fill="#122967"
      />
    </svg>
    <defs>
      <filter
        id="filter0_d_3367_2824"
        x="0.480002"
        y="0.738281"
        width="24.375"
        height="26.04"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.52" />
        <feGaussianBlur stdDeviation="1.26" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3367_2824"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3367_2824"
          result="shape"
        />
      </filter>
    </defs>

    {cpTot > 0 && (
      <g transform="translate(16,10)">
        <ResizableNumberBadgeIcon number={cpTot} />
      </g>
    )}

    {evcModel === "AC" && (
      <g transform="translate(0,-3) scale(0.5)">
        <AC />
      </g>
    )}

    {evcModel === "DC" && (
      <g transform="translate(0,-3) scale(0.5)">
        <DC />
      </g>
    )}
  </svg>
);
export default MarkerWipByOther;
