/** @format */

import { useMutation } from "@apollo/client";
import { gql } from "../generated";

export const RESTART_EVC = gql(`
  mutation restartEvc($input: RestartEvcInput!) {
    restartEvc(input:$input) {
      status
      error
    }
  }
`);

export const useRestartEVC = () => {
  const [executeRestartEVC, { data, loading, error }] =
    useMutation(RESTART_EVC);
  return {
    executeRestartEVC,
    error,
    data: data?.restartEvc,
    loading,
  };
};
