import { OcppBootNotificationReq } from "./ocpp1.6";

export type SimulatorEvcLabel = `LABK001`;

export const bootNotificationData: Record<
  SimulatorEvcLabel,
  OcppBootNotificationReq
> = {
  LABK001: {
    chargePointVendor: "Keba",
    chargePointModel: "KECONTACT P30",
    chargePointSerialNumber: "17099470",
    iccid: "8988303000007074708",
  },
};
