import { Link, useLocation } from "react-router-dom";
import { checkIsActive } from "../../helpers/routerHelper";
import { Flex } from "../Flex";
import { LiElement, List } from "../List";
import { MenuItem } from "./MenuItems";
import { Typography } from "../Typography";
import RenderOnRole from "../RenderOnRole";
import { openMap } from "../../layout/Menu";

export type DropdownProps = {
  submenus: MenuItem[];
  dropdown: boolean;
  depthLevel: any;
  isOpen: boolean;
  id: number;
};
export const Submenu: React.FC<DropdownProps> = ({
  submenus,
  dropdown,
  depthLevel,
  isOpen,
  id,
}) => {
  const location = useLocation();

  return openMap[`sub-${id}`] === true ? (
    <List>
      {submenus?.map((item, index) => (
        <RenderOnRole roles={item.roles} key={index}>
          <LiElement
            style={{ marginLeft: 10 }}
            selected={checkIsActive(item.path, location.pathname)}
            isSelectedParent={false}
          >
            <Typography tooltipText={!isOpen ? item.label : ""}>
              <Link to={item.path}>
                {item.icon}
                {isOpen ? (
                  <div style={{ paddingLeft: 5 }}>{item.label}</div>
                ) : null}{" "}
              </Link>{" "}
            </Typography>
          </LiElement>
        </RenderOnRole>
      ))}
    </List>
  ) : null;
};
