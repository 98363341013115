import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useCurrentUser = () => {
    const userSettings = useSelector((state: any) => state.userSettings);
    const {
        currentUser,
    }: {
        currentUser?: {
        email: string;
        firstName?: string;
        lastName?: string;
        };
    } = userSettings;
    
    const user = useMemo(() => {
        
        if (!currentUser) return {
        id: "unknown",
        name: "unknown",
        
        };
        return{
        id: currentUser?.email,
        name:
        currentUser?.firstName || currentUser?.lastName
            ? `${currentUser?.firstName ?? ""} ${currentUser?.lastName ?? ""}`.trim()
            : currentUser?.email.split("@")[0],
    }}, [currentUser]);
    
    return user;
    }