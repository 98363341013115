import React from "react";
import Svg from "../../UI/Svg";

type Props ={
    size?:string;
    fill?:string
};

const Type3c: React.FC<Props>=({fill,size,...props}) =>{
    return(
        <Svg 
        fill={fill}
        iconSize={size}
        {...props}
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 52">        
      <path d= "M20 0C8.95431 0 0 8.9543 0 20V32C0 41.45 6.55407 49.3693 15.3646 51.4601C16.465 50.5685 18.1329 50 20 50C21.8671 50 23.535 50.5685 24.6354 51.4601C33.4459 49.3693 40 41.45 40 32V20C40 8.95431 31.0457 0 20 0Z" 
      />
       <circle cx="9" cy="12" r="2" fill="white"/>
  <circle cx="30" cy="12" r="2" fill="white"/>
  <circle cx="8" cy="23" r="3" fill="white"/>
  <circle cx="31" cy="23" r="3" fill="white"/>
  <circle cx="8" cy="34" r="3" fill="white"/>
  <circle cx="31" cy="34" r="3" fill="white"/>
  <circle cx="20" cy="43" r="3" fill="white"/>
</Svg>
    );
};

export default Type3c;