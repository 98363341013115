import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import BG_IMG from "../assets/bg-effect.png";
import { Flex } from "../UI";

const SectionContentBottomElement = styled(Flex)`
  width: 100%;
  max-width: 100%;
  flex: 1;
  flex-grow: 1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: auto;
  position: relative;
  background-image: url(${BG_IMG});
  background-color: white;
  background-position: left-top;
  transition: all 0.3s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

`;

export const SectionContentBottom: React.FC<PropsWithChildren<any>> = ({
  children,
  height = "calc(100%-60px)"
  ,
  ...props
}) => {
  return (
    <SectionContentBottomElement maxHeight={height} height={height} {...props}>
      {children}
    </SectionContentBottomElement>
  );
};
