import React from "react";
import UserService from "../services/UserServices";
import { Center } from "./Center";
import { Navigate, redirect } from "react-router-dom";

type RenderOnRoleProps = {
  roles: string[];
  children: any;
  page?: boolean;
};

const RenderOnRole: React.FC<RenderOnRoleProps> = ({
  roles,
  children,
  page,
}) => {
  if (!roles.length) return children;
  if (!UserService.hasResourceRole(roles, "appsync"))
    return page ? <Navigate to="/profile" replace /> : null;
  return children;
};

export default RenderOnRole;
