/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated/gql";

export const GET_LOCATION = gql(`
  query Location($uid: String!) {
    Location(uid: $uid) {
      address
      chargingWhenClosed
      city
      country
      cpoPartyUid
      createdAt
      facilities
      label
      businessModel
      hostGroup
      EVCs{
        results{
                  isEhub
                }
      }
      directions {
        language
        text
      }

      geolocation {
        latitude
        longitude
        name
      }
      images {
        category
        height
        thumbnail
        type
        url
        width
      }

      openingTimes {
        twentyFourSeven
        regularHours {
          periodBegin
          periodEnd
          weekday
        }
        exceptionalOpenings {
          periodBegin
          periodEnd
        }
        exceptionalClosings {
          periodBegin
          periodEnd
        }
      }
      PlatformParty{
      name
      }
      Project {
        phase
        worksCompletionDate
            cod 
            thirdPartiesOwnership 
            thirdPartiesMaintenance 
            name
            projectId
            uid 
            POD {
              owner
              DSO {
                name
              }
            }
        }
      operator
      postalCode
      projectId
      province
      region
      status
      suboperator
      timezone
      type
      uid
      updatedAt
      upmId
      userGroups
    }
  }
`);
export const useGetLocation = () => {
  const [
    getLocation,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_LOCATION, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getLocation,
    error,
    location: data?.Location,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
