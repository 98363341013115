import { useOutletContext } from "react-router-dom";
import {
  Badge,
  Card,
  Flex,
  LoaderWithFallback,
  SimpleLoader,
  Typography,
  Button, 
} from "../../../../../shared/UI";
import { getStatusColor } from "../../../../../shared/helpers/colorHelper";
import { Refresh } from "../../../../../shared/assets/svg";
import { t } from "i18next";
import { getConnectorTypeIcon } from "../../../../../shared/helpers/evseIconHelper";
import React from "react";
import Ccs1 from "../../../../../shared/assets/svg/Ccs1";
import Type1 from "../../../../../shared/assets/svg/Type1";
import Type2 from "../../../../../shared/assets/svg/Type2";
import Type3a from "../../../../../shared/assets/svg/Type3a";
import { width } from "styled-system";
import Type3c from "../../../../../shared/assets/svg/Type3c";
export const EvseDetails = () => {
  const { evse, loading, refetchEvse }: any = useOutletContext();
  return (
    <Flex fullSize>
      <Flex width="100%" justifyContent={"space-between"} flexDirection={"row"}>
        <Typography py={2} variant={"title30"}>
          EVSE
        </Typography>
        <Button fixedSize onClick={() => refetchEvse()}>
          <Refresh />
        </Button>
      </Flex>
      <LoaderWithFallback
        hasError={!evse}
        loading={loading}
        errorMsg={"Error"}
        LoadingMsg={<SimpleLoader />}
      >
        <Flex
          flexWrap={"wrap"}
          flexDirection={"row"}
          style={{ gap: "20px 20px" }}
        >
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography>N° {t('conectors')}</Typography>
            <Typography variant={"title20"} pt={2} fontWeight={600}>
              {evse?.connectors?.length || "---"}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            {" "}
            <Typography>{t('status')}</Typography>
            <Typography
              pt={2}
              fontWeight={600}
              color={getStatusColor(evse?.status)}
            >
              {evse?.status || "---"}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            {" "}
            <Typography>Asset Status </Typography>
            <Typography
              color={getStatusColor(evse?.assetStatus)}
              pt={2}
              fontWeight={600}
            >
              {evse?.assetStatus || "---"}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            {" "}
            <Typography>emi3Id</Typography>
            <Typography pt={2} fontWeight={600}>
              {evse?.emi3Id}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            {" "}
            <Typography>Capabilities</Typography>
            <Typography pt={2} fontWeight={600}>
              {evse?.capabilities?.map((item: string) => (
                <Badge m={1}>{item}</Badge>
              ))}
            </Typography>
          </Card>
        </Flex>
        <Flex mt={3} width="100%">
          <Typography py={2} variant={"title30"}>
            {t('connectors')}
          </Typography>
          <Flex
            flexWrap={"wrap"}
            flexDirection={"row"}
            style={{ gap: "20px 20px" }}
            p={3}
            flexShrink={0}
            
          >
            <Card
              bg="white"
              p={3}
              display={"flex"}
              flexDirection={"column"}
              flexWrap={"wrap"}
              minWidth={400}
              justifyContent={"center"}
              flex={1}

            >
             
              
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                style={{ gap: 10 }}
              >
                <Flex flex={3} minWidth={150} mb={2}>
                
              <Typography  >
                { t ('connector')} </Typography>
               
                <Flex  marginTop={3}>
              {getConnectorTypeIcon(evse?.connectors?.[0]?.standard)}
              </Flex>
              </Flex>
              <Flex flex={6}  marginLeft={6}>
              
              <Typography>{t('format')}</Typography>
              <Flex  marginTop={2}>
              <Typography  fontWeight={600}>
                {evse?.connectors?.[0]?.format}
              </Typography>
              </Flex>
              <Flex  marginTop={3}>
              <Typography>Standard</Typography>
            
              <Typography pt={2} fontWeight={600}>
                {evse?.connectors?.[0]?.standard}
              </Typography>
              </Flex>
              <Flex  marginTop={3}>              
              <Typography>Power type (AC/DC) </Typography>
              <Typography pt={2} fontWeight={600}>
                {evse?.connectors?.[0]?.powerType}
              </Typography>
            
            
              </Flex>
              </Flex>
              </Flex>
            
            </Card>

            <Card
              bg="white"
              p={3}
              display={"flex"}
              flexDirection={"column"}
              flex={1}
              minWidth={400}
              justifyContent={"center"}
              flexWrap={"wrap"}
              mb={1}
            >
             <Flex flex={3} minWidth={150} mb={2} pl={2}>
             <Flex flexWrap="wrap">
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ gap: 10 }}
              >
              <Flex flex={2} minWidth={150} mb={2}>
              <Typography>Power type (AC/DC) </Typography>
              <Typography pt={2} fontWeight={600}>
                {evse?.connectors?.[0]?.powerType}
              </Typography>
              <Flex  marginTop={3}>
              <Typography>Voltage (V) </Typography>
              
              <Typography pt={2} fontWeight={600}>
                {evse?.connectors?.[0]?.voltage}
              </Typography>
              </Flex>
              <Flex  marginTop={3}>
              <Typography>Capacity </Typography>
              
              <Typography pt={2} fontWeight={600}>
                {evse?.connectors?.[0]?.voltage}
              </Typography>
              </Flex>
              </Flex>
              <Flex flex={2}  marginLeft={4}>
              <Typography>Amperage (A) </Typography>
              <Typography pt={2} fontWeight={600}>
                {evse?.connectors?.[0]?.amperage}
              </Typography>
              <Flex  marginTop={3}> 
              <Typography>Uid</Typography>
              <Typography pt={2} fontWeight={600}>
                {evse?.connectors?.[0]?.uid}
              </Typography>
              </Flex>
              </Flex>
              
              </Flex>
              </Flex>
              </Flex>
            </Card>            
            </Flex>
        </Flex>
      </LoaderWithFallback>
    </Flex>
  );

};
