
export enum OcppChargingPointErrorCode {
  ConnectorLockFailure = "ConnectorLockFailure",
  EVCommunicationError = "EVCommunicationError",
  GroundFailure = "GroundFailure",
  HighTemperature = "HighTemperature",
  InternalError = "InternalError",
  LocalListConflict = "LocalListConflict",
  NoError = "NoError",
  OtherError = "OtherError",
  OverCurrentFailure = "OverCurrentFailure",
  PowerMeterFailure = "PowerMeterFailure",
  PowerSwitchFailure = "PowerSwitchFailure",
  ReaderFailure = "ReaderFailure",
  ResetFailure = "ResetFailure",
  UnderVoltage = "UnderVoltage",
  OverVoltage = "OverVoltage",
  WeakSignal = "WeakSignal",
}

export enum OcppChargingPointStatus {
  Available = "Available",
  Preparing = "Preparing",
  Charging = "Charging",
  SuspendedEVSE = "SuspendedEVSE",
  SuspendedEV = "SuspendedEV",
  Finishing = "Finishing",
  Reserved = "Reserved",
  Unavailable = "Unavailable",
  Faulted = "Faulted",
}

export interface OcppStatusNotificationReq {
  connectorId: number;
  errorCode: OcppChargingPointErrorCode;
  info?: string;
  status: OcppChargingPointStatus
  timestamp?: string;
  vendorId?: string;
  vendorErrorCode?: string;
}

export interface OcppStatusNotificationConf {}