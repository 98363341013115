import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IPlatform } from "../../../../core/model/platform.model";
import { Close, Edit } from "../../../../shared/assets/svg";
import { ExpendLayoutButton } from "../../../../shared/components/ExpendLayoutButton";

import { SectionHead } from "../../../../shared/layout/SectionHead";
import {
  Breadcrumbs,
  Button,
  Crumb,
  Flex,
  StyledLink,
  Typography,
} from "../../../../shared/UI";
import RenderOnRole from "../../../../shared/UI/RenderOnRole";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import { usePlatform } from "../../hooks/usePlatform";
import { selectPlatform } from "../../slices";
import { PlatformDetailsForm } from "./PlatformDetailsForm";
import { PlatformShowFields } from "./PlatformShowFields";
import { Roles } from "@bepower/greta-types";
import { t } from "i18next";

type Props = {
  setSelectedPlatform?: React.Dispatch<React.SetStateAction<IPlatform | null>>;
  place?: "platform" | "platforms";
};

const PlatformContent: React.FC<Props> = ({ setSelectedPlatform, place }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedPlatform } = useSelector((state: any) => state.platform);

  const { platform, loadingGetPlatform } = usePlatform();

  const CancelSelectedPlatform = useCallback(() => {
    dispatch(selectPlatform(null));
  }, [dispatch]);

  const navigateToPlatform = () => {
    navigate(`/platforms/${platform.uid}/edit`);
  };

  return (
    <Flex fullSize>
      <SectionHead>
        <Flex flexDirection="row" alignItems="center">
          {place === "platform" && (
            <Breadcrumbs>
              <Crumb>
                <StyledLink to="/platforms">Platforms</StyledLink>
              </Crumb>
              <Crumb>
                <StyledLink to="#">{selectedPlatform?.name}</StyledLink>
              </Crumb>
            </Breadcrumbs>
          )}
          {place === "platforms" && (
            <Flex
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex flexDirection="row" alignItems="center">
                <ExpendLayoutButton type="mainList" />
                <Typography
                  color="primaryDark"
                  variant="title20"
                  fontWeight="bold"
                >
                  {selectedPlatform?.name?.toUpperCase()}
                </Typography>
              </Flex>
              <Flex flexDirection="row">
                <RenderOnRole roles={[Roles.PlatformWrite]}>
                  <Button
                    $size="small"
                    fixedSize
                    background="transparent"
                    variant="primary"
                    $fill={false}
                    onClick={navigateToPlatform}
                    title= {t('edit platform')|| ""}
                  >
                    <Edit size="4" />
                  </Button>
                </RenderOnRole>

                <Button
                  $fill={false}
                  fixedSize
                  background="transparent"
                  variant="danger"
                  $size="small"
                  ml={10}
                  onClick={CancelSelectedPlatform}
                >
                  <Close size="5" />
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </SectionHead>

      <VerticalScrollContainer>
        {place === "platforms" ? (
          <PlatformShowFields
            loading={loadingGetPlatform}
            platform={platform}
          />
        ) : (
          <PlatformDetailsForm />
        )}
      </VerticalScrollContainer>
    </Flex>
  );
};
export default PlatformContent;
