import * as React from "react"
import { SVGProps } from "react"
const StandBy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#FFC700"
      stroke="#fff"
      strokeWidth={2.074}
      d="M12.372 23c5.728 0 10.372-4.701 10.372-10.5S18.1 2 12.372 2 2 6.701 2 12.5 6.644 23 12.372 23Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14 7.729a5.002 5.002 0 0 1-1.5 9.77A5 5 0 0 1 11 7.73V6.174a6.5 6.5 0 1 0 3 0v1.555Z"
      clipRule="evenodd"
    />
    <path stroke="#fff" d="M12.5 5v7" />
  </svg>
)
export default StandBy
