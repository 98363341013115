import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type languageOption = 'en' | 'it' | string
type Props = {
   locale: languageOption
};

const i18next = createSlice({
   name: "i18next",
   initialState: {
      locale: 'it'

   } as Props,
   reducers: {
      setLocale: (
         state,
         action: PayloadAction<languageOption>
      ) => {
         state.locale = action.payload
      },


   },
});

export const { setLocale } =
   i18next.actions;
export default i18next.reducer;
