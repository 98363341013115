import { gql, useLazyQuery } from "@apollo/client";

export const GET_TARIFFS_ASSOC = gql`
  query TariffAssoc($uid: String!) {
    TariffAssoc(uid: $uid) {
      CPOParties {
        name
        uid
      }
      Active
      Expired
      cpoPartyUids
      EMSPParties {
        name
        PartyIdCountry
      }
      Locations {
        uid
        label
        projectId
      }
      tariffUid
      label
      uid
      updatedAt
      updatedBy
      validFrom
      validTo
      conditions {
        upon
        eq
        gt
        gte
        in
        lt
        lte
        ne
        nin
        path
        type
      }
      CSOs {
        name
        uid
      }
      Tariff {
        uid
        updatedAt
        label

        PricingByCountry {
          countries
          pricing {
            elements {
              id
              priceComponent {
                price
                type
              }
            }
            label
            id
          }
        }

        pricing {
          label
          elements {
            id
            priceComponent {
              price
              type
            }
            restrictions {
              dow
              fromDate
              fromH
              maxDuration
              maxKwh
              maxParkingTime
              maxPower
              minDuration
              minKwh
              minParkingTime
              minPower
              toDate
              toH
            }
          }
          conditions {
            upon
            eq
            gt
            gte
            in
            lt
            lte
            nin
            ne
            path
            type
          }
          id
        }
      }
    }
  }
`;

export const useGetTariffAssoc = () => {
  const [
    executeTariffAssoc,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_TARIFFS_ASSOC, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
    errorPolicy: "ignore",
    // refetchWritePolicy: "overwrite",
    // pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    executeTariffAssoc,
    error,
    tariffAssoc: data?.TariffAssoc,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
