import { LoadScript } from "@react-google-maps/api";
import React from "react";
import { SectionContent } from "../../../../shared/layout";
import { Flex } from "../../../../shared/UI";
import { ActionBar } from "./ActionBar";
import { InfoCard } from "./InfoCard";
import { Legend } from "./Legend";
import { LocationMap } from "./LocationMap";
import { TopBar } from "./TopBar";

type LocationsListProps = {};

export const FreeRide: React.FC<LocationsListProps> = () => {
  // FIXME: add technological mixture text
  // const { t } = useTranslation();
  return (
    <SectionContent>
      <TopBar
        // secondaryText={t("siteSelection.freeRide.toolbar.secondaryText", {
        //   acPercentage: 70,
        //   dcPercentage: 30,
        //   lastUpdate: "2021-01-01",
        // })}
      />
      <ActionBar />
      <Flex flexDirection="row" height="100%" width="100%">
        <LoadScript
          googleMapsApiKey="AIzaSyChibtUkiRoURZd6yrh1611rI4w93SE5lM"
          libraries={["places"]}
        >
          <InfoCard />
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
              padding: "20px",
              overflow: "hidden",
            }}
          >
            <LocationMap streetView={true} />
          </div>
          <Legend />
        </LoadScript>
      </Flex>
    </SectionContent>
  );
};
