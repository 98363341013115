import { gql, useMutation } from "@apollo/client";

export const PUBLISH_REMOTE_START_CHARGE_TX = gql`
  mutation publishRemoteStartChargeTx($input: RemoteStartChargeTxInput!) {
    publishRemoteStartChargeTx(input: $input) {
      requestUid
    }
  }
`;

export const usePublishRemoteStartChargeTx = () => {
  const [publishRemoteStartChargeTx, { data, loading, error }] =
    useMutation<any>(PUBLISH_REMOTE_START_CHARGE_TX);
  return {
    publishRemoteStartChargeTx,
    error,
    remoteStartChargeTxData: data?.publishRemoteStartChargeTx,
    loading,
  };
};
