import { gql, useMutation } from "@apollo/client";
import { CHARGE_ACTIVITIES_LAST_NOT_COMPLETED } from "../queries/useChargeActivitiesLastNotCompleted";

export const PUBLISH_REMOTE_UNLOCK_CONNECTOR = gql`
  mutation publishRemoteUnlockConnector($input: RemoteUnlockConnectorInput!) {
    publishRemoteUnlockConnector(input: $input) {
      requestUid
    }
  }
`;

export const usePublishRemoteUnlockConnector = () => {
  const [publishRemoteUnlockConnector, { data, loading, error }] =
    useMutation<any>(PUBLISH_REMOTE_UNLOCK_CONNECTOR);

  return {
    publishRemoteUnlockConnector,
    error,
    remoteUnlockConnector: data?.publishRemoteUnlockConnector,
    loading,
  };
};
