/** @format */

import React, { PropsWithChildren } from "react";
import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";
import { ColorProps, LayoutProps } from "styled-system";
import { Typography } from "./Typography";

type Props = LayoutProps &
  ColorProps & {
    "data-testid"?: string;
  } & Omit<
    ITooltip,
    | "type"
    | "textColor"
    | "backgroundColor"
    | "className"
    | "wrapper"
    | "overridePosition"
    | "insecure"
  >;

const StyledTooltip = ReactTooltip;

export const Tooltip: React.FC<PropsWithChildren<Props>> = ({
  children,
  backgroundColor: defaultBackground,
  globalCloseEvents,
  color,
  place = "right",
  events,
  closeEvents,
  offset,
  delayHide,
  ...props
}) => {
  return (
    <StyledTooltip
      globalCloseEvents={globalCloseEvents}
      place={place}
      events={events}
      delayHide={delayHide}
      offset={offset}
      closeEvents={closeEvents}
      {...props}
    >
      <Typography color="white" variant="body10">
        {children}
      </Typography>
    </StyledTooltip>
  );
};
