


import Svg from "../../UI/Svg";

type Props = {
   size?: string;
   fill?: string;
};

const Tariff: React.FC<Props> = ({ size, fill }) => {
   return (
      <Svg iconSize={size}
         viewBox="0 0 121 114" fill={fill} xmlns="http://www.w3.org/2000/svg">
         <path d="M9.03034 108C6.62225 108 4.51517 107.1 2.7091 105.3C0.903034 103.5 0 101.4 0 99V9C0 6.6 0.903034 4.5 2.7091 2.7C4.51517 0.9 6.62225 0 9.03034 0H99.3337C101.742 0 103.849 0.9 105.655 2.7C107.461 4.5 108.364 6.6 108.364 9V67.2C106.959 66.4 105.504 65.75 103.999 65.25C102.494 64.75 100.939 64.3 99.3337 63.9V9H9.03034V99H57.1921C57.2925 100.6 57.443 102.15 57.6437 103.65C57.8443 105.15 58.1955 106.6 58.6972 108H9.03034ZM27.091 83.1C28.2951 83.1 29.3486 82.65 30.2516 81.75C31.1547 80.85 31.6062 79.8 31.6062 78.6C31.6062 77.4 31.1547 76.35 30.2516 75.45C29.3486 74.55 28.2951 74.1 27.091 74.1C25.887 74.1 24.8334 74.55 23.9304 75.45C23.0274 76.35 22.5758 77.4 22.5758 78.6C22.5758 79.8 23.0274 80.85 23.9304 81.75C24.8334 82.65 25.887 83.1 27.091 83.1ZM27.091 58.5C28.2951 58.5 29.3486 58.05 30.2516 57.15C31.1547 56.25 31.6062 55.2 31.6062 54C31.6062 52.8 31.1547 51.75 30.2516 50.85C29.3486 49.95 28.2951 49.5 27.091 49.5C25.887 49.5 24.8334 49.95 23.9304 50.85C23.0274 51.75 22.5758 52.8 22.5758 54C22.5758 55.2 23.0274 56.25 23.9304 57.15C24.8334 58.05 25.887 58.5 27.091 58.5ZM27.091 33.9C28.2951 33.9 29.3486 33.45 30.2516 32.55C31.1547 31.65 31.6062 30.6 31.6062 29.4C31.6062 28.2 31.1547 27.15 30.2516 26.25C29.3486 25.35 28.2951 24.9 27.091 24.9C25.887 24.9 24.8334 25.35 23.9304 26.25C23.0274 27.15 22.5758 28.2 22.5758 29.4C22.5758 30.6 23.0274 31.65 23.9304 32.55C24.8334 33.45 25.887 33.9 27.091 33.9ZM46.9578 58.5H83.6811V49.5H46.9578V58.5ZM46.9578 33.9H83.6811V24.9H46.9578V33.9ZM46.9578 83.1H60.3527C61.1554 81.4 62.0836 79.825 63.1371 78.375C64.1906 76.925 65.3194 75.5 66.5235 74.1H46.9578V83.1Z" fill={fill} />
         <path d="M97.0077 98.1562C94.9554 98.1562 93.2109 97.4453 91.7742 96.0234C90.3376 94.6016 89.6193 92.875 89.6193 90.8438C89.6193 88.8125 90.3376 87.0859 91.7742 85.6641C93.2109 84.2422 94.9554 83.5312 97.0077 83.5312C99.0601 83.5312 100.805 84.2422 102.241 85.6641C103.678 87.0859 104.396 88.8125 104.396 90.8438C104.396 92.875 103.678 94.6016 102.241 96.0234C100.805 97.4453 99.0601 98.1562 97.0077 98.1562ZM77.3052 106.688C76.279 106.688 75.4067 106.332 74.6884 105.621C73.9701 104.91 73.6109 104.047 73.6109 103.031V78.6562C73.6109 77.6406 73.9701 76.7773 74.6884 76.0664C75.4067 75.3555 76.279 75 77.3052 75H116.71C117.736 75 118.609 75.3555 119.327 76.0664C120.045 76.7773 120.405 77.6406 120.405 78.6562V103.031C120.405 104.047 120.045 104.91 119.327 105.621C118.609 106.332 117.736 106.688 116.71 106.688H77.3052ZM83.4622 103.031H110.553C110.553 101.325 111.148 99.8828 112.339 98.7047C113.529 97.5266 114.986 96.9375 116.71 96.9375V84.75C114.986 84.75 113.529 84.1609 112.339 82.9828C111.148 81.8047 110.553 80.3625 110.553 78.6562H83.4622C83.4622 80.3625 82.867 81.8047 81.6767 82.9828C80.4863 84.1609 79.0291 84.75 77.3052 84.75V96.9375C79.0291 96.9375 80.4863 97.5266 81.6767 98.7047C82.867 99.8828 83.4622 101.325 83.4622 103.031ZM113.016 114H69.9167C68.8905 114 68.0183 113.645 67.3 112.934C66.5816 112.223 66.2225 111.359 66.2225 110.344V82.3125H69.9167V110.344H113.016V114Z" fill={fill} />
      </Svg>

   );
};

export default Tariff;
