/** @format */

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { GlobalStyle } from "./shared/UI/helpers/globalStyle";
import theme from "./shared/UI/theme";
import store, { persistor } from "./shared/redux/store";
import AppRoutes from "./shared/routes/AppRoutes";
import UserService from "./shared/services/UserServices";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import React from "react";
import "./base.scss";
import i18n from "./common/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const getEnvironment = async () => {
  const setEnv = async (env: any) => {
    return env.json ? await env.json() : env;
  };
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  let resEnv;
  try {
    resEnv = await fetch("/config.json", {
      headers,
    });
    if (resEnv.ok === false) {
      // eslint-disable-next-line no-throw-literal
      throw "Error , can't fetch config file ";
    }
    return await setEnv(resEnv);
  } catch (e) {
    throw e;
  }
};

getEnvironment().then((data) => {
  UserService.initKeycloak(() =>
    root.render(
      <StyledThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <ToastContainer
              closeOnClick={false}
              closeButton={true}
              position="bottom-right"
            />
            <I18nextProvider i18n={i18n}>
              <React.Suspense fallback="Loading...">
                <AppRoutes config={data} />
              </React.Suspense>
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </StyledThemeProvider>
    )
  );
});
// config keycloak
