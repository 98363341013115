/** @format */
import { useCallback, useEffect, useState } from "react";
import {
  useBlocker as reactUseBlocker,
  useBeforeUnload as _useBeforeUnload,
} from "react-router-dom";
export const useBlockerDialog = (
  doBlock: boolean,
  confirmCallBack?: () => void,
  cancelCallBack?: () => void
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const blocker = reactUseBlocker(
    ({ currentLocation, nextLocation }: any) => doBlock
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setIsOpen(true);
    }
  }, [blocker.state]);

  _useBeforeUnload(
    useCallback(
      (e) => {
        if (doBlock) {
          e.preventDefault();
          return (e.returnValue = "test");
        }
      },
      [doBlock]
    )
  );

  return {
    blocker,
    isOpen,
    setIsOpen,
  };
};
