const DV: {
  [key: string]: any;
} = {
  // color
  BLUE: "#3D729C",
  INDIGO: "#675aa9",
  PURPLE: "#6658dd",
  PINK: "#f672a7",
  RED: "#f1556c",
  ORANGE: "#fd7e14",
  YELLOW: "#f7b84b",
  GREEN: "#1abc9c",
  TEAL: "#02a8b5",
  CYAN: "#4fc6e1",
  WHITE: "#fff",
  GRAY_100: "#f1f5f7",
  GRAY_200: "#eef0f3",
  GRAY_300: "#dee2e6",
  GRAY_400: "#ced4da",
  GRAY_500: "#b1bbc4",
  GRAY_600: "#98a6ad",
  GRAY_700: "#6c757d",
  GRAY_800: "#343a40",
  GRAY_900: "#323a46",
  BLACK: "#000",
  LIGHT_FONT_COLOR: "#fff",
  DARK_FONT_COLOR: "#000",

  // util
  DARK_LEVEL: 0.075,
  DARKER_LEVEL: 0.175,
  LIGHT_LEVEL: 0.075,
  LIGHTER_LEVEL: 0.175,
  SHADOW_FADE_LEVEL: 0.5,
  JSX_ELEMENT_PROPS: {},

  // alert
  ALERT_BG_LEVEL: -0.8,
  ALERT_BORDER_LEVEL: -0.72,
  ALERT_FONT_LEVEL: 0.4,
  ALERT_LINK_FONT_DARKEN_LEVEL: 0.1,
  ALERT_HR_DARKEN_LEVEL: 0.05,

  // breadcrumb
  BREADCRUMB_BG_COLOR: "#fff",
  BREADCRUMB_FONT_COLOR: "#6c757d",
  BREADCRUMB_FONT_FILL_LIGHT_COLOR: "#fff",
  BREADCRUMB_FONT_FILL_DARK_COLOR: "rgba(0,0,0,.5)",

  // button
  BUTTON_BG_COLOR: "#fff",

  // dropdown
  DROPDOWN_BG_COLOR: "#fff",

  // form
  FORM_RADIO_COLOR: "#fff",
  FORM_RADIO_BORDER_COLOR: "#dbdbdb",
  FORM_INPUT_BG_COLOR: "#fff",
  FORM_INPUT_BORDER_COLOR: "#dbdbdb",
  FORM_DISABLED_BG_COLOR: "#f2f2f2",
  FORM_INPUT_PLACEHOLDER_COLOR: "#b1bbc4",
  FORM_INPUT_PLACEHOLDER_FILL_FADE_LEVEL: 0.3,
  FORM_INPUT_DISABLED_BACKGROUND: '#f2f2f2',

  // pagination
  PAGINATION_BG_COLOR: "#fff",
  PAGINATION_BORDER_COLOR: "#dee2e6",

  // popover
  POPOVER_BORDER_DARKEN_LEVEL: 0.15,
  POPOVER_HEADER_BG_DARKEN_LEVEL: 0.03,

  // progress bar
  PROGRESS_BAR_BG: "transparent",
  PROGRESS_BAR_BG_FILL: "#eee",
  PROGRESS_BAR_BG_ALT_FADE_LEVEL: 0.3,

  // table
  TABLE_BG_LEVEL: -0.72,
  TABLE_BORDER_LEVEL: -0.4,
  TABLE_STRIPED_BG_LEVEL: [0.045, 0.035, 0.025],
  TABLE_HOVER_BG_LEVEL: [0.05, 0.04, 0.03],
  TABLE_LIGHT_BG_COLOR: "#fff",
  TABLE_LIGHT_BORDER_COLOR: "#e9ecef",
  TABLE_DARK_BG_COLOR: "#323a42",
  TABLE_DARK_BORDER_COLOR: "#414c5c",

  // wave effect
  WAVE_EFFECT_TRANSITION_TYPE: "ease-out",
  WAVE_EFFECT_TRANSITION_DURATION: 750,
};

export default DV;
