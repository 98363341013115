import { gql, useMutation } from "@apollo/client";

const ADD_SITE_SELECTION = gql`
  mutation addSiteSelectionProject($input: AddSiteSelectionProjectInput!) {
    addSiteSelectionProject(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const useAddSiteSelectionProject = () => {
  const [addSiteSelectionProject, { loading, data, error }] =
    useMutation(ADD_SITE_SELECTION);


  return { addSiteSelectionProject, error, data, loading };
};


const UPDATE_SITE_SELECTION = gql`
  mutation updateSiteSelectionProject($input: UpdateSiteSelectionProjectInput!) {
    updateSiteSelectionProject(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const useUpdateSiteSelectionProject = () => {
  const [updateSiteSelectionProject, { loading, data, error }] =
    useMutation(UPDATE_SITE_SELECTION);


  return { updateSiteSelectionProject, error, data, loading };
};


const DELETE_SITE_SELECTION = gql`
  mutation deleteSiteSelectionProject($input: DeleteSiteSelectionProjectInput!) {
    deleteSiteSelectionProject(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const useDeleteSiteSelectionProject = () => {
  const [deleteSiteSelectionProject, { loading, data, error }] =
    useMutation(DELETE_SITE_SELECTION);


  return { deleteSiteSelectionProject, error, data, loading };
};
