import { Evc } from "@bepower/greta-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type EvcState = {
  selectedEvc: Evc | null;
};
const initialState: EvcState = {
  selectedEvc: null,
};

const evcSlice = createSlice({
  name: "evc",
  initialState,
  reducers: {
    selectEvc: (state, action: PayloadAction<Evc | null>) => {
      state.selectedEvc = action.payload;
    },
  },
});

export const { selectEvc } = evcSlice.actions;
export default evcSlice.reducer;
