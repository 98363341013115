import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "debounce";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  Flex,
  Input,
  InputLabel,
  Select,
} from "../../../../shared/UI";
import { useGetPlatformParties } from "../../../platforms/gql/queries/useGetPlatformParties";
import { usePublishEvseAccessGroup } from "../../gql/mutations/usePublishEvseAccessGroup";

const evseAccessGroupSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().optional(),
  cpoParty: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
});

export const GroupInfo = () => {
  const { accessGroup }: any = useOutletContext();
  const [searchValue, setSearchValue] = useState<string>("");
  const {
    loading: loadingPublishEvseAccessGroup,
    publishEvseAccessGroup,
    data,
  } = usePublishEvseAccessGroup();
  const navigate = useNavigate();
  const { count, getPlatformPartiesQuery, platformParties, loading } =
    useGetPlatformParties();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    resolver: yupResolver(evseAccessGroupSchema),
    shouldFocusError: true,
  });
  const onSubmitForm = (data: any) => {
    publishEvseAccessGroup({
      variables: {
        input: {
          cpoPartyUid: data?.cpoParty?.value,
          description: data?.description,
          name: data?.name,
          ...(accessGroup?.uid ? { uid: accessGroup.uid } : {}),
        },
      },
    }).then((res) => {
      if (res.data) {
        navigate("/access-groups");
      }
    });
  };
  const handleChangeInput = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (accessGroup) {
      reset({
        name: accessGroup?.name,
        cpoParty: {
          label: accessGroup?.PlatformParty?.name,
          value: accessGroup?.PlatformParty?.uid,
        },
        description: accessGroup?.description,
      });
    }
  }, [accessGroup, reset]);
  useEffect(() => {
    getPlatformPartiesQuery({
      variables: {
        filter: {
          role: ["CPO"],
          text: searchValue,
        },
      },
    });
  }, [getPlatformPartiesQuery, searchValue]);
  return (
    <Card p={3} bg="white">
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmitForm)}>
        <Flex
          style={{ gap: "10px 10px" }}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          <Box flex={1} minWidth={300}>
            <InputLabel children="Name" required />
            <Input
              placeholder="Name"
              {...register("name", {
                required: { value: true, message: "required" },
              })}
              error={errors?.name ? errors?.name?.message : ""}
            />
          </Box>
          <Box flex={1} minWidth={300}>
            <InputLabel children="description" />
            <Input
              placeholder="description"
              error={errors?.description ? errors?.description?.message : ""}
              {...register("description")}
            />
          </Box>
          <Box flex={1} minWidth={300}>
            <InputLabel children="CPO party" required />
            <Controller
              name="cpoParty"
              control={control}
              render={({ field }) => (
                <Select
                  required
                  onInputChange={debounce(handleChangeInput, 1000)}
                  isLoading={loading}
                  options={
                    platformParties?.map((party: any) => ({
                      label: party?.name,
                      value: party?.uid,
                    })) || []
                  }
                  placeholder="CPO Party"
                  error={
                    errors.cpoParty ? errors.cpoParty.message || "required" : ""
                  }
                  {...field}
                />
              )}
            />
          </Box>
        </Flex>
        <Box mt={3}>
          <Button
            loading$={loadingPublishEvseAccessGroup}
            disabled={loadingPublishEvseAccessGroup}
            onClick={handleSubmit(onSubmitForm)}
            $size="default"
          >
            {accessGroup ? "Update" : "Save"}
          </Button>
        </Box>
      </form>
    </Card>
  );
};
