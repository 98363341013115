export const getWeekdayName = (weekday: number): string => {
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  return weekdays[weekday - 1];
};
