import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";

const EVSEACCESSGROUPEVSEDETAILS = gql`
  query EvseAccessGroupEvseDetails(
    $uid: String!
    $pagination: PaginationParams
  ) {
    EvseAccessGroup(uid: $uid) {
      name
      uid
      EVSEs(pagination: $pagination) {
        total
        pagination {
          cursor
          last
        }
        results {
          EVSE {
            uid
            status
            number
            label
            EVC {
              __typename
              uid
              label
              statusUpdatedAt
              Location {
                label
                Project {
                  cod
                  projectId
                  name
                }
              }
            }
          }
          updatedAt
          status
          evseUid
          accessGroupUid
        }
      }
    }
  }
`;

export const useGetEVSEAccessGroupEvseDetails = () => {
  const [aggrData, setAggrData] = useState<any[]>([]);
  const [
    getEvseAccessGroupsEvseDetails,
    {
      loading,
      error,
      data,
      client,
      refetch,
      fetchMore: fetchMoreItems,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(EVSEACCESSGROUPEVSEDETAILS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const fetchMore = (page: number) => {
    if (
      data?.EvseAccessGroup &&
      data?.EvseAccessGroup?.EVSEs?.results?.length <
        data?.EvseAccessGroup?.EVSEs?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            page: page,
            limit: 20,
          },
        },

        updateQuery({ EvseAccessGroup: prevData }, { fetchMoreResult }) {
          const newQuery = {
            EvseAccessGroup: {
              EVSEs: {
                results: [
                  ...prevData?.EVSEs?.results,
                  ...fetchMoreResult?.EvseAccessGroup?.EVSEs?.results,
                ],
                total: fetchMoreResult?.EvseAccessGroup.total,
                pagination: fetchMoreResult?.EvseAccessGroup?.EVSEs?.pagination,
                __typename: fetchMoreResult.EvseAccessGroup?.EVSEs?.__typename,
              },
              name: fetchMoreResult.EvseAccessGroup.name,
              uid: fetchMoreResult.EvseAccessGroup.uid,
              __typename: fetchMoreResult.EvseAccessGroup.__typename,
            },
          };
          return newQuery;
        },
      });
    }
  };
  return {
    loading,
    getEvseAccessGroupsEvseDetails,
    error,
    data,
    count: data?.EvseAccessGroup?.EVSEs?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
  };
};
