/** @format */

import { useState } from "react";
import { Nina } from "../../../../../shared/Ocpp/nina";
import { NinaEvse } from "../../../../../shared/Ocpp/nina.ocpp.types";
import { OcppChargingPointStatus } from "../../../../../shared/Ocpp/ocpp1.6";
import {
  Badge,
  Button,
  Card,
  Flex,
  Input,
  Typography,
} from "../../../../../shared/UI";
import { Box } from "./../../../../../shared/UI/Box";

type ConnectorProps = {
  id: number;
  evse: NinaEvse;
  nina?: Nina;
};
type MeterValue = {
  label: string;
  value: number;
};
const meterValuesOptions: MeterValue[] = [
  {
    label: "+ 0.001 kWh",
    value: 1,
  },

  {
    label: "+ 1.6 kWh",
    value: 1600,
  },
  {
    label: "+5 kWh",
    value: 5000,
  },
];

export const Connector: React.FC<ConnectorProps> = ({ id, evse, nina }) => {
  const [metervalue, setMeterValue] = useState<number | undefined>(undefined);

  const handleCustomMetterValue = (evse: NinaEvse) => (e: any) => {
    e.preventDefault();
    if (metervalue) {
      nina?.sendMeter(evse, Number(metervalue));
      setMeterValue(undefined);
    }
  };

  return (
    <Card
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      bg="white"
      p={3}
      height="100%"
      style={{ gap: 10 }}
      flexShrink={0}
    >
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={3}
      >
        <Flex alignContent={"center"} alignItems={"center"}>
          <Typography variant={"title30"} fontWeight={"bold"}>
            {evse?.label}
          </Typography>
        </Flex>
        <Badge
          borderColor={connectorData[evse.status].backgroundColor}
          bg={connectorData[evse.status].backgroundColor}
        >
          <Typography
            color={connectorData[evse.status].color}
            variant={"title20"}
            px={3}
          >
            {connectorData[evse.status].text}
          </Typography>
        </Badge>
      </Flex>
      <Flex height={2} borderBottom={"2px solid #eee"} />

      <Flex flex={1} flexDirection={"column"} style={{ gap: 5 }} pl={2}>
        {evse.authorized?.status ? (
          <>
            <Flex flexDirection={"row"}>
              <Typography fontWeight={"bold"} color="#737373">
                {" "}
                Authorized:
              </Typography>
              <Typography pl={1} color="#737373">
                {evse.authorized.type}-{evse.authorized.idTag}
              </Typography>
            </Flex>

            {!!evse.transactionId && (
              <Flex flexDirection={"row"}>
                <Typography fontWeight={"bold"} color="#737373">
                  {" "}
                  Transaction Id :
                </Typography>
                <Typography pl={1} color="#737373">
                  {evse.transactionId}
                </Typography>
              </Flex>
            )}
            {(evse.status === `Charging` || evse.status === `SuspendedEV`) && (
              <Flex flexDirection={"row"}>
                <Typography fontWeight={"bold"} color="#737373">
                  {" "}
                  Current MeterValue:
                </Typography>
                <Typography pl={1} color="#737373">
                  {evse.meter}
                </Typography>
              </Flex>
            )}
            {evse.reserved?.status && (
              <Flex flexDirection={"row"}>
                <Typography fontWeight={"bold"}> reserved :</Typography>
                <Typography>
                  ? `Reserved (until ${evse.reserved?.expiryDate} - $
                  {evse.reserved?.idTag})`
                </Typography>
              </Flex>
            )}
          </>
        ) : (
          <Typography color="#737373">Authorized : false</Typography>
        )}
      </Flex>

      <Flex height={200}>
        {evse.status === `Charging` || evse.status === `SuspendedEV` ? (
          <Flex p={2} flex={1} flexDirection={"column"} style={{ gap: 10 }}>
            <Flex flexDirection={"column"} style={{ gap: 10 }}>
              <Flex
                width={"100%"}
                flexDirection="row"
                flexWrap={"wrap"}
                style={{ gap: 10 }}
              >
                {meterValuesOptions.map((op) => (
                  <Flex>
                    <Button
                      minWidth={120}
                      variant="gray"
                      withBorder
                      $fill={false}
                      $size="default"
                      onClick={() => nina?.sendMeter(evse, op?.value)}
                    >
                      {op.label}
                    </Button>
                  </Flex>
                ))}
              </Flex>
              <form onSubmit={handleCustomMetterValue(evse)}>
                <Flex
                  justifyContent={"left"}
                  flexDirection={"row"}
                  style={{ gap: 10 }}
                  width={"100%"}
                >
                  <Box>
                    <Input
                      autoFocus
                      type="number"
                      value={metervalue || ""}
                      onChange={(e: any) => setMeterValue(e.target.value)}
                      min={0}
                      $size="small"
                      width={250}
                      height={35}
                      placeholder="0.000"
                      hint="Type a number and press Enter to send"
                      suffix={metervalue ? `+ ${metervalue / 1000} kWh` : null}
                      // suffixAbsolute={true}
                      suffixProps={{
                        backgroundColor: "primary",
                        alignItems: "center",
                        px: 2,
                        color: "white",
                      }}
                    />
                  </Box>
                </Flex>
              </form>
            </Flex>
            <Flex height={2} borderBottom={"2px solid #eee"} />
            <Flex flexShrink={0} flexDirection={"row"} style={{ gap: 10 }}>
              <Button
                onClick={() => nina?.startGracePeriod(evse)}
                variant="gray"
                withBorder
                $fill={false}
                $size="default"
                flexShrink={0}
              >
                Start Grace Period
              </Button>
              <Button
                onClick={() => nina?.setParkingPenalty(evse)}
                variant="gray"
                withBorder
                $fill={false}
                $size="default"
                flexShrink={0}
              >
                ParkingTime
              </Button>
            </Flex>
          </Flex>
        ) : null}
      </Flex>

      <Flex p={2} alignItems={"left"} justifyContent={"center"}>
        <Button
          mt={2}
          maxWidth={300}
          $size="large"
          variant={evse.cableConnected ? "success" : "secondary"}
          onClick={() => {
            nina?.toggleCable(evse.label);
          }}
          disabled={evse.status === `Charging`}
        >
          {!evse.cableConnected ? `Connect Cable` : `Disconnect Cable`}
        </Button>
      </Flex>
    </Card>
  );
};

export const connectorData = {
  Available: {
    text: "Available",
    backgroundColor: "#4caf50",
    color: "white",
    status: OcppChargingPointStatus.Available,
  },
  Preparing: {
    text: "Preparing",
    backgroundColor: "#009688",
    color: "white",
    status: OcppChargingPointStatus.Preparing,
  },
  Charging: {
    text: "Charging",
    backgroundColor: "#3f51b5",
    color: "white",
    status: OcppChargingPointStatus.Charging,
  },
  SuspendedEV: {
    text: "Suspended EV",
    backgroundColor: "#9c27b0",
    color: "white",
    status: OcppChargingPointStatus.SuspendedEV,
  },
  SuspendedEVSE: {
    text: "Suspended EVSE",
    backgroundColor: "#9c27b0",
    color: "white",
    status: OcppChargingPointStatus.SuspendedEVSE,
  },
  Finishing: {
    text: "Finishing",
    backgroundColor: "#9c27b0",
    color: "white",
    status: OcppChargingPointStatus.Finishing,
  },
  Reserved: {
    text: "Reserved",
    backgroundColor: "#ffc107",
    color: "black",
    status: OcppChargingPointStatus.Reserved,
  },
  Unavailable: {
    text: "Unavailable",
    backgroundColor: "#f57c00",
    color: "white",
    status: OcppChargingPointStatus.Unavailable,
  },
  Faulted: {
    text: "Faulted",
    backgroundColor: "red",
    color: "white",
    status: OcppChargingPointStatus.Faulted,
  },
};
