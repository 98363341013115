import { gql, useLazyQuery } from "@apollo/client";

export const GET_PLATFORM = gql`
  query Platform($uid: String!) {
    Platform(uid: $uid) {
      Interoperabilities(pending: true, pagination: { limit: 1000 }) {
        total
        results {
          Tariff {
            tariff {
              updatedAt
              pricing {
                id
                conditions {
                  upon
                  eq
                  gt
                  gte
                  in
                  lt
                  lte
                  ne
                  nin
                  path
                  type
                }
                elements {
                  id
                  priceComponent {
                    price
                    type
                  }
                  restrictions {
                    dow
                    fromDate
                    fromH
                    maxDuration
                    maxParkingTime
                    maxKwh
                    maxPower
                    minDuration
                    minKwh
                    minParkingTime
                    minPower
                    toDate
                    toH
                  }
                }
              }
              uid

              label
            }
            assoc {
              label
              conditions {
                upon
                eq
                gt
                gte
                in
                lt
                lte
                ne
                nin
                path
                type
              }
              Tariff {
                updatedAt
                pricing {
                  id
                  conditions {
                    upon
                    eq
                    gt
                    gte
                    in
                    lt
                    lte
                    ne
                    nin
                    path
                    type
                  }
                  elements {
                    id
                    priceComponent {
                      price
                      type
                    }
                    restrictions {
                      dow
                      fromDate
                      fromH
                      maxDuration
                      maxParkingTime
                      maxKwh
                      maxPower
                      minDuration
                      minKwh
                      minParkingTime
                      minPower
                      toDate
                      toH
                    }
                  }
                }
                uid
              }
              validFrom
              validTo
              uid
              cpoPartyUids
              score
            }
          }
          OtherParty {
            countryCode
            image {
              category
              height
              thumbnail
              type
              url
              width
            }
            name
            partyId
            phoneNumber
            role
            uid
            updatedAt
            website
          }
          accessGroups
          cpoPartyUid
          otherPartyUid
          platformUid
          protocol
          updatedAt
        }
      }
      HubParty {
        countryCode
        image {
          category
          height
          thumbnail
          type
          url
          width
        }
        name
        partyId
        phoneNumber
        role
        uid
        updatedAt
        website
      }
      entryPointUrl
      flavor
      hubPartyUid
      name
      needsReliablePush
      offlineTokenIn
      offlineTokenOut
      protocol
      skipCredentialsRefresh
      pullTokens
      asyncPush
      status
      uid
      updatedAt
      version
    }
  }
`;
export const useGetPlatform = () => {
  const [
    getPlatform,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_PLATFORM, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getPlatform,
    error,
    data: data?.Platform,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
