import { gql, useLazyQuery } from "@apollo/client";

export const GET_EVC_REBOOT_PROCESSED = gql`
  query EvcRebootProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    EvcRebootProcessed(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        __typename
        status
        timestamp
        info
        requestUid
        request {
          evcUid
          requestUid
          type
        }
      }
    }
  }
`;
export const useGetEvcRebootProcessed = () => {
  const [
    getEvcRebootProcessed,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(GET_EVC_REBOOT_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getEvcRebootProcessed,
    error,
    EvcRebootProcessed: data,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
