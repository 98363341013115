/** @format */

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetTariffAssocs } from "../gql/useGetTariffAssocs";

export const useTariffAssocs = ({
  execute = true,
}: { execute?: boolean } | undefined = {}) => {
  const [searchParams] = useSearchParams();

  const text = searchParams?.get("text");
  const country = searchParams?.get("country");
  const protocol = searchParams?.get("protocol");
  const tariffSort = searchParams?.get("tariffSort");

  const {
    tariffAssocsList,
    count,
    executeTariffAssocs,
    loading,
    refetch,
    startPolling,
    fetchMore,
  } = useGetTariffAssocs();

  useEffect(() => {
    execute &&
      executeTariffAssocs({
        variables: {
          filter: {
            ...(text ? { text: text } : {}),
            ...(country ? { country: country } : {}),
            ...(protocol ? { label: protocol } : {}),
          },
          pagination: {
            limit: 20,
            after: null,
          },
          sort: {
            field: tariffSort?.split("-")?.[0],
            order: tariffSort?.split("-")?.[1],
          },
        },
      });
  }, [country, execute, executeTariffAssocs, protocol, tariffSort, text]);

  return { refetch, startPolling, tariffAssocsList, count, loading, fetchMore };
};
