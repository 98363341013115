/** @format */

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { persistCache } from "apollo3-cache-persist";
import { unionBy } from "lodash";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import store, { resetState } from "./shared/redux/store";
import LocalStorageService from "./shared/services/LocalStorageService";
import UserService from "./shared/services/UserServices";
import { PaginationParams } from "./shared/gql/generated/graphql";

// const wait = (ms: any) => new Promise((res) => setTimeout(res, ms));
// const delayRefetchedQuery = async (observableQuery: any) => {
//   await wait(1000)
//   observableQuery.refetch()
// }

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    errorPolicy: "ignore",
    refetchWritePolicy: "overwrite",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
    notifyOnNetworkStatusChange: true,
  },
  mutate: {
    errorPolicy: "ignore",
    awaitRefetchQueries: true,
    // onQueryUpdated: delayRefetchedQuery,
    fetchPolicy: "no-cache",
  },
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, errorType }: any) => {
      if (errorType === "UnauthorizedException") {
        LocalStorageService.clear();
        UserService.doLogout();
      }
      toast.error(message);
    });
  }

  if (networkError) {
    toast.error(`Network error: ${networkError}`);
  }
});

function getMergeItems(
  incoming: {
    results: any[];
    pagination: PaginationParams;
    total: number;
    __typename: string;
  },
  existing?: any[]
) {
  if (existing) {
    const { results, ...rest } = incoming;

    return {
      results: unionBy([...existing, ...results], "uid"),
      ...rest,
    };
  } else {
    return incoming;
  }
}
const formatDateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response: any) => {
    // console.log(response.data);
    // response.data.date = new Date(Date.now());
    //use this function if u want to change data before save it in cache

    return response;
  });
});
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // Platforms: {
        //   keyArgs: ["limit", "filter"],
        //   merge(existing: { results: [] }, incoming) {
        //     return getMergeItems(incoming, existing?.results);
        //   },
        // },
      },
    },
  },
});
persistCache({
  cache,
  storage: window.localStorage,
});

type Props = {
  config: any;
};

export const doLogout = () => {
  UserService.doLogout();
  store.dispatch(resetState());
  localStorage.clear();
};

const App: React.FC<Props> = ({ config }) => {
  const token = UserService?.getToken();
  if (!token) {
    return null;
  }

  const httpLink = new HttpLink({
    uri: config?.greta?.endpoint,
    headers: {
      authorization: UserService?.getToken() as string,
    },
  });

  const client = new ApolloClient({
    link: formatDateLink.concat(from([errorLink, httpLink])),

    cache,
    defaultOptions,
  });
  return (
    <ApolloProvider client={client}>
      <Outlet />
    </ApolloProvider>
  );
};

export default App;
