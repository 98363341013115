/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated";

export const GET_LOCATIONS = gql(`
  query Locations(
    $filter: LocationFilterParams
    $pagination: PaginationParams
    $sort: LocationSortingParams
  ) {
    Locations(filter: $filter, pagination: $pagination, sort: $sort) {
      total
      pagination {
        cursor
        last
      }
      results {
        address
        city
        country
        cpoPartyUid
        createdAt
        label
        status
        projectId
        province
        region
        hostGroup
        region
        uid
        updatedAt
        businessModel
        openingTimes {
          exceptionalClosings {
            periodBegin
            periodEnd
          }
          exceptionalOpenings {
            periodBegin 
            periodEnd 
          }
          regularHours {
            periodBegin
            periodEnd
            weekday
          }
          twentyFourSeven
        }
        type 
        Project {
            cod 
            thirdPartiesOwnership 
            name
            projectId
            uid 
        }
       
        EVCs {
          total
          results {
            connectionType
            isEhub
            

          }
        }
      }
    }
  }
`);

export const useGetLocations = () => {
  const [
    executeGetLocations,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_LOCATIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
  });

  const fetchMore = () => {
    if (
      data?.Locations?.pagination?.cursor &&
      data?.Locations?.results?.length < data.Locations?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.Locations?.pagination?.cursor,
            limit: 50,
          },
        },
        updateQuery({ Locations: prevData }, { fetchMoreResult }) {
          if (prevData?.results?.length) {
            return {
              Locations: {
                results: [
                  ...prevData?.results,
                  ...fetchMoreResult?.Locations?.results,
                ],
                __typename: fetchMoreResult.Locations.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.Locations?.pagination,
              },
            };
          } else {
            return {
              Locations: {
                results: [...fetchMoreResult?.Locations?.results],
                __typename: fetchMoreResult.Locations.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.Locations?.pagination,
              },
            };
          }
        },
      });
    }
  };

  return {
    loading,
    executeGetLocations,
    error,
    count: data?.Locations?.total,
    locations: data?.Locations?.results,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
