import { SVGProps } from "react";
const Backlog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#FFC700"
      stroke="#fff"
      strokeWidth={2.074}
      d="M12.372 23c5.728 0 10.372-4.701 10.372-10.5S18.1 2 12.372 2 2 6.701 2 12.5 6.644 23 12.372 23Z"
    />
    <path
      fill="#fff"
      d="M17.353 9.221a.756.756 0 0 1 0 1.07l-6.042 6.04a.756.756 0 0 1-1.069 0l-3.02-3.02a.756.756 0 0 1 1.068-1.069l2.488 2.485 5.508-5.506a.756.756 0 0 1 1.07 0h-.003Z"
    />
  </svg>
);
export default Backlog;
