import React, { useMemo, useState } from "react";
import BaseTable, {
  AutoResizer,
  CallOrReturn,
  ColumnShape,
} from "react-base-table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Card, Flex, Typography } from "../../../../shared/UI";
import { DownloadThick, FileSolid } from "../../../../shared/assets/svg";
import Delete from "../../../../shared/assets/svg/Delete";
import Rename from "../../../../shared/assets/svg/Rename";
import Star from "../../../../shared/assets/svg/Star";
import { Project, ProjectStatus } from "../../@types/project";
import { useProjects } from "../../hooks/useProjects";
import { downloadLocations } from "../../utils/downloadLocations";
import { TopBar } from "./TopBar";
import { DeleteProjectsDialog } from "./dialogs/DeleteProjectDialog";
import { RenameProjectDialog } from "./dialogs/RenameProjectDialog";

const StyledTable = styled(BaseTable)`
  .row-selected {
    background-color: #d8edfe;
    border-left: 10px solid #008fdd;
  }

  .row {
    border: none;
    border-bottom: 1px solid #d8d8d8;
  }

  .header-class {
    background-color: #fff;
    color: #000;
    border-top: none;
    border-bottom: 1px solid #d8d8d8;
  }

  .table {
    border: none;
  }
` as React.ComponentType as new <T>() => BaseTable<T>;

const SelectionCell = <T extends {}>(props: {
  cellData: any;
  columns: ColumnShape<T>[];
  column: ColumnShape<T>;
  columnIndex: number;
  rowData: T;
  rowIndex: number;
  container: BaseTable<T>;
  isScrolling?: boolean | undefined;
}) => {
  const _handleChange = (e: any) => {
    e.stopPropagation();
    const { rowData, column } = props;
    const { onChange } = column;
    onChange({ rowData });
  };

  const { rowData, column } = props;
  const { rowKey, isSelected } = column;
  const checked = isSelected?.(rowData);

  const inputId = `__selection-${rowData[rowKey as keyof T]}`;

  return (
    <label htmlFor={inputId}>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          margin: "0",
          padding: "0",
        }}
        onClick={_handleChange}
      >
        <input
          id={inputId}
          type="checkbox"
          checked={checked}
          onChange={_handleChange}
        />
      </div>
    </label>
  );
};

const Action = ({
  Icon,
  onClick,
  text,
  disabled = false,
}: {
  text: string;
  Icon: React.FC<{
    fill?: string;
    stroke?: string;
    iconSize?: string;
  }>;
  onClick: () => void;
  disabled?: boolean;
}) => (
  <Flex
    flexDirection={"row"}
    alignItems={"center"}
    marginLeft={"20px"}
    minHeight={"30px"}
    onClick={() => {
      !disabled && onClick();
    }}
    style={{
      cursor: "pointer",
    }}
    aria-disabled={disabled}
  >
    <div>
      {disabled && <Icon stroke="#BDBDBD" fill="#BDBDBD" />}
      {!disabled && <Icon />}
    </div>
    <Typography
      display={"block"}
      color={disabled ? "#BDBDBD" : undefined}
      fontSize={"14px"}
      marginLeft={"5px"}
    >
      {text}
    </Typography>
  </Flex>
);

const CellContainer = styled.div`
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  height: 100%;
  overflow: hidden;
  margin: 0 -5px;
  padding: 5px;
  border: 1px dashed transparent;
`;

const Select = styled.select`
  width: 100%;
  border: none;
  background-color: transparent;
`;

const EditableCell = (props: {
  cellData: any;
  columns: ColumnShape<Project>[];
  column: ColumnShape<Project>;
  columnIndex: number;
  rowData: Project;
  rowIndex: number;
  container: BaseTable<Project>;
  isScrolling?: boolean | undefined;
  updateStatus: (project: Project, status: ProjectStatus) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CellContainer
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Select
        value={props.rowData.status}
        defaultValue={props.rowData.status}
        onChange={(e) => {
          props.updateStatus(props.rowData, e.target.value as ProjectStatus);
        }}
      >
        <option value="open">
          {t("siteSelection.general.data.project.status.open")}
        </option>
        <option value="archived">
          {t("siteSelection.general.data.project.status.archived")}
        </option>
        <option value="completed">
          {t("siteSelection.general.data.project.status.completed")}
        </option>
      </Select>
    </CellContainer>
  );
};

export const FavoriteCell = (props: {
  cellData: any;
  columns: ColumnShape<Project>[];
  column: ColumnShape<Project>;
  columnIndex: number;
  rowData: Project;
  rowIndex: number;
  container: BaseTable<Project>;
  isScrolling?: boolean | undefined;
  isFavorite: boolean;
  onFavorite: (ids: string[]) => void;
}) => {
  return (
    <CellContainer>
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        onClick={(event) => {
          event.stopPropagation();
          props.onFavorite([props.rowData.id]);
        }}
      >
        <Star
          stroke={"#FFD700"}
          iconSize="30px"
          fill={props.isFavorite ? "#FFD700" : "#FFF"}
        />
      </Flex>
    </CellContainer>
  );
};

export const OpenProject = () => {
  const [filterStatus, setFilterStatus] = useState<ProjectStatus | "all">(
    "open"
  );

  const props = useMemo(
    () => ({
      filter: {
        status: filterStatus,
      },
    }),
    [filterStatus]
  );

  const {
    projects,
    addFavorite,
    isFavorite,
    updateStatus,
    deleteProjects,
    fetchMore,
    refetch,
  } = useProjects(props);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isRenameOpen, setIsRenameOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const columns: ColumnShape<Project>[] = useMemo(
    () => [
      {
        key: "checked",
        width: 40,
        align: "center",
        resizable: false,
        cellRenderer: SelectionCell as any,
        isSelected: (rowData: Project) => selectedRows.includes(rowData.id),
        selectedRowKeys: selectedRows,
        onChange: ({ selected, rowData }: any) => {
          if (!selectedRows.includes(rowData.id)) {
            setSelectedRows([rowData.id, ...selectedRows]);
          } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowData.id));
          }
        },
      } satisfies ColumnShape<Project> & {
        isSelected: (rowData: Project) => boolean;
      } as any,
      {
        key: "name",
        width: 300,
        title: (
          <Flex flexDirection={"row"} alignItems="center">
            <span
              style={{
                marginRight: 10,
              }}
            >
              <FileSolid stroke="#122967" iconSize="20px" fill="#FFF" />
            </span>
            name
          </Flex>
        ) as any,
        dataKey: "name",
        cellRenderer: (e) => (
          <Flex flexDirection="row" alignItems={"center"}>
            <Flex
              style={{
                marginRight: 10,
                position: "relative",
              }}
            >
              <FileSolid stroke="#122967" iconSize="30px" fill="#FFF" />
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                style={{
                  position: "absolute",
                  top: "25%",
                  width: 19,
                  height: 17,
                  backgroundColor: "#122967",
                  fontSize: 7,
                  color: "#FFF",
                  left: "-4px",
                }}
              >
                {t("siteSelection.general.legend.evc")}
              </Flex>
            </Flex>
            <Typography display={"block"} color="122967">
              {e.cellData}
            </Typography>
          </Flex>
        ),
        resizable: false,
      } satisfies ColumnShape<Project>,
      {
        key: "createdBy",
        width: 200,
        title: "created By",
        dataKey: "createdBy",
        cellRenderer: (e) => {
          return (
            <Typography display={"block"} color="122967">
              {e.rowData.createdBy}
            </Typography>
          );
        },
        resizable: false,
      } satisfies ColumnShape<Project>,
      {
        key: "updatedAt",
        width: 200,
        title: "updated At",
        dataKey: "updatedAt",
        cellRenderer: (e) => (
          <Typography display={"block"} color="122967">
            {e.rowData.updatedAt?.toLocaleDateString()}
          </Typography>
        ),
        resizable: false,
      } satisfies ColumnShape<Project>,
      {
        key: "status",
        width: 150,
        title: "status",
        dataKey: "status",
        cellRenderer: (props) => (
          <EditableCell {...props} updateStatus={updateStatus} />
        ),
        resizable: false,
      } satisfies ColumnShape<Project>,
      {
        key: "visibility",
        width: 200,
        title: "visibility",
        dataKey: "visibility",
        cellRenderer: (e) => (
          <Flex flexDirection={"row"} alignItems={"center"}>
            <div
              style={{
                minWidth: 10,
                minHeight: 10,
                maxWidth: 10,
                maxHeight: 10,
                borderRadius: "50%",
                backgroundColor:
                  e.rowData.visibility === "private" ? "red" : "green",
                marginRight: 10,
              }}
            />
            <Typography width={"100%"} display={"block"} color="122967">
              {t(`siteSelection.general.data.project.visibility.${e.cellData}`)}
            </Typography>
          </Flex>
        ),
        resizable: false,
      } satisfies ColumnShape<Project>,
      // FIXME: fix favorites
      // {
      //   key: "favorite",
      //   width: 100,
      //   dataGetter: (e) => {
      //     return isFavorite(e.rowData.id);
      //   },
      //   cellRenderer: (e) => (
      //     <FavoriteCell
      //       {...e}
      //       isFavorite={isFavorite(e.rowData.id) ?? false}
      //       onFavorite={addFavorite}
      //     />
      //   ),
      //   resizable: false,
      // } satisfies ColumnShape<Project>,
    ],
    [selectedRows, updateStatus]
  );

  const _rowClassName: CallOrReturn<
    string,
    { columns: ColumnShape<Project>[]; rowData: Project; rowIndex: number }
  > = ({ rowData }: { rowData: Project }): string => {
    const rowClass = "row";
    const key = rowData.id;
    return [rowClass, selectedRows.includes(key) ? "row-selected" : ""]
      .filter(Boolean)
      .join(" ");
  };

  return (
    <Card
      minWidth={300}
      overflow="hidden"
      position="relative"
      display="flex"
      flexDirection={"column"}
      width="100%"
      height="100%"
      style={{ borderRadius: 0 }}
      withEffect={true}
    >
      <TopBar />
      <Flex
        justifyContent={"center"}
        width={"100%"}
        textAlign={"center"}
        alignItems={"center"}
        marginTop="75px"
        marginBottom={"50px"}
      >
        <Typography fontSize={"53px"} color="#122967" upperCase={true}>
          {t("siteSelection.openProject.title")}
        </Typography>
      </Flex>
      <Flex marginLeft={75} marginRight={75} height="100%">
        <Flex
          flexDirection={"row"}
          justifyContent={"center"}
          width={"100%"}
          textAlign={"center"}
          alignItems={"center"}
          marginBottom={"40px"}
        >
          {(
            ["all", "open", "completed", "archived"] satisfies (
              | ProjectStatus
              | "all"
            )[]
          ).map((status) => (
            <Typography
              fontSize={status === filterStatus ? "24px" : "14px"}
              fontWeight={status === filterStatus ? "bold" : "normal"}
              key={status}
              style={{
                marginRight: 20,
                cursor: "pointer",
                textDecoration: status === filterStatus ? "underline" : "",
              }}
              onClick={() => {
                setFilterStatus(status);
              }}
            >
              {t(`siteSelection.general.data.project.status.${status}`)}
            </Typography>
          ))}

          <Action
            Icon={(props) => <Delete fill="#3D729C" {...props} />}
            text={t(`siteSelection.openProject.actions.delete`)}
            onClick={() => {
              setIsConfirmDeleteOpen(true);
            }}
            disabled={selectedRows.length === 0}
          />
          {/* FIXME: fix favorites */}
          {/* <Action
              Icon={(props) => (
                <Star iconSize="20px" stroke="#3D729C" {...props} />
              )}
              text={t(`siteSelection.openProject.actions.favorite`)}
              onClick={() => {
                addFavorite(selectedRows);
              }}
              disabled={selectedRows.length === 0}
            /> */}
          <Action
            Icon={(props) => (
              <DownloadThick iconSize={"20px"} fill="#3D729C" {...props} />
            )}
            text={t(`siteSelection.openProject.actions.download`)}
            onClick={() => {
              selectedRows.forEach((id) => {
                const pr = projects.find((p) => p.id === id);
                pr && downloadLocations(pr);
              });
            }}
            disabled={selectedRows.length === 0}
          />
          <Action
            Icon={(props) => <Rename fill="#3D729C" {...props} />}
            text={t(`siteSelection.openProject.actions.rename`)}
            onClick={() => {
              setIsRenameOpen(true);
            }}
            disabled={selectedRows.length !== 1}
          />

          <Flex flexGrow={1} />
        </Flex>
        <AutoResizer>
          {({ width, height }) => (
            <StyledTable<Project>
              {...{ width, height }}
              className="table"
              estimatedRowHeight={50}
              columns={[...columns]}
              data={projects}
              rowClassName={_rowClassName}
              headerClassName={"header-class"}
              headerHeight={50}
              onEndReachedThreshold={10}
              onEndReached={fetchMore}
              rowEventHandlers={{
                onClick: ({ rowData }) => {
                  navigate(`/site-selection/project/${rowData.id}`);
                },
              }}
              style={{
                border: "none",
                backgroundColor: "#F00",
              }}
            />
          )}
        </AutoResizer>
      </Flex>
      <DeleteProjectsDialog
        isOpen={isConfirmDeleteOpen}
        setIsOpen={setIsConfirmDeleteOpen}
        ids={selectedRows}
        onDelete={() => {
          deleteProjects(selectedRows);
          setSelectedRows([]);
        }}
      />
      <RenameProjectDialog
        projectId={selectedRows[0]}
        isOpen={isRenameOpen}
        setIsOpen={setIsRenameOpen}
        onConfirm={() => {
          setTimeout(() => {
            refetch();
          }, 1000);
        }}
      />
    </Card>
  );
};
