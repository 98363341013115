/** @format */

import { Evse } from "@bepower/greta-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Card,
  ColumnShape,
  Dialog,
  DialogBody,
  DialogHeader,
  Flex,
  Table,
  Typography,
} from "../../../../shared/UI";
import { Add, Delete } from "../../../../shared/assets/svg";
import { FiltersList } from "../../../../shared/components/FiltersList";
import { SmartTable } from "../../../../shared/components/SmartTable";
import { getStatusColor } from "../../../../shared/helpers/colorHelper";
import { usePublishEvseAccessGroupAssociation } from "../../gql/mutations/usePublishEvseAccessGroupAssociation";
import { useGetEVSEAccessGroupEvseDetails } from "../../gql/queries/useEvseAccessGroupEvseDetails";
import { useGetEVSEsListWithAccessGroups } from "../../gql/queries/useGetEVSEsListWithAccessGroups";
import { toast } from "react-toastify";
import { EvseAccessGroupAssociation } from "../../../../shared/gql/generated/graphql";

export let checkedMap: { [key: string]: boolean } = {};

function columnDefaultOptions(key: string) {
  return {
    key: key,
    title: key,
    dataKey: key,
    flexGrow: 0,
    width: 50,
  };
}

type Column = ColumnShape<Evse>;

export const GroupCheckedEvsesList: React.FC = () => {
  const [AddMode, setAddMode] = useState<boolean>(false);
  const [RemoveMode, setRemoveMode] = useState<boolean>(false);
  const [selectedRowsToDelete, setSelectedRowToDelete] = useState<string[]>([]);
  const [selectedRowsToAdd, setSelectedRowsToAdd] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<{
    type: "ADD" | "Delete";
    message: string;
  } | null>(null);

  const { publishEvseAccessGroupAssociation } =
    usePublishEvseAccessGroupAssociation();

  const handleRowSelection = ({ rowData, type }: any) => {
    if (type === "Add") {
      const selectedRowKeys = [...selectedRowsToAdd];
      const rowIndex = selectedRowKeys.indexOf(rowData.uid);
      if (rowIndex !== -1) {
        selectedRowKeys.splice(rowIndex, 1);
      } else {
        selectedRowKeys.push(rowData.uid);
      }
      setSelectedRowsToAdd(selectedRowKeys);
    } else {
      const selectedRowKeys = [...selectedRowsToDelete];
      const rowIndex = selectedRowKeys.indexOf(rowData.uid);
      if (rowIndex !== -1) {
        selectedRowKeys.splice(rowIndex, 1);
      } else {
        selectedRowKeys.push(rowData.uid);
      }
      setSelectedRowToDelete(selectedRowKeys);
    }
  };

  const smallColumns: Column[] = [
    {
      key: "add",
      title: "+",
      dataKey: "add",
      flexGrow: 0,
      width: 50,
      align: "center",
      hidden: !AddMode,
      resizable: false,
      selectedRowKeys: { selectedRowsToAdd },
      headerRenderer: ({ column }) => {
        return <Add fill="white" />;
      },
      cellRenderer: ({ rowData }: any) => (
        <input
          onClick={(e: any) => e.stopPropagation()}
          type="checkbox"
          checked={selectedRowsToAdd.includes(rowData.uid)}
          onChange={() => handleRowSelection({ rowData, type: "Add" })}
          disabled={rowData.readonly}
          style={{ width: 20, height: 20 }}
        />
      ),
    },
    {
      key: "remove",
      title: "remove",
      dataKey: "remove",
      flexGrow: 0,
      width: 50,
      align: "left",
      hidden: !RemoveMode,
      resizable: false,
      selectedRowKeys: { selectedRowsToDelete },
      headerRenderer: ({ column }) => {
        return <Delete />;
      },
      cellRenderer: ({ rowData }: any) => (
        <input
          onClick={(e: any) => e.stopPropagation()}
          type="checkbox"
          checked={selectedRowsToDelete.includes(rowData.uid)}
          onChange={() => handleRowSelection({ rowData, type: "Remove" })}
          disabled={rowData.readonly}
          style={{ width: 20, height: 20 }}
        />
      ),
    },
    {
      key: "label",
      title: "label",
      dataKey: "label",

      flexGrow: 3,
      width: 120,
      resizable: true,
      sortable: true,
    },
    {
      key: "status",
      title: "status",
      dataKey: "status",
      flexGrow: 2,
      width: 120,
      resizable: true,
      dataGetter: ({ rowData }) => {
        return (
          <Typography color={getStatusColor(rowData?.status as string)}>
            {rowData?.status}
          </Typography>
        );
      },
    },
    {
      key: "projectID ",
      title: "Project ID ",
      dataKey: "projectID",
      width: 120,
      align: "left",
      flexGrow: 2,
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.Project?.projectId;
      },
      resizable: true,
    },
    {
      key: "projectName",
      title: "Project Name",
      dataKey: "projectName",
      width: 120,
      align: "left",
      flexGrow: 2,
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.Project?.name;
      },
      resizable: true,
    },

    {
      key: "location",
      title: "location",
      dataKey: "location",
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.label;
      },
      flexGrow: 3,
      width: 200,
      resizable: true,
      sortable: true,
    },
    {
      key: "COD",
      title: "COD",
      dataKey: "COD",
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.Project?.cod;
      },
      flexGrow: 3,
      width: 120,
      resizable: true,
      sortable: true,
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { uid } = useParams();

  const {
    data,
    getEvseAccessGroupsEvseDetails,
    loading,
    count,
    refetch,
    fetchMore: fetchMoreEvseAccessGroupsEvseDetails,
  } = useGetEVSEAccessGroupEvseDetails();
  const { accessGroup }: any = useOutletContext();

  const tableRef = React.useRef<any>();
  const navigate = useNavigate();

  const handleClickRow = (row: any) => {
    // navigate(`/evses/${row?.uid}`);
  };
  const [page, setPage] = useState<number>(1);
  // const pageRef = React.useRef<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const text = searchParams.get("text");

  const handleRefresh = useCallback(() => {
    tableRef?.current?.scrollToTop(0);
    refetch();
  }, [refetch]);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    getEvseAccessGroupsEvseDetails({
      variables: {
        uid: uid,
        pagination: {
          page: page,
          limit: 10,
        },
      },
    }).then((res) => {
      res.data?.EvseAccessGroup?.EVSEs?.results?.forEach((evse: any) => {
        checkedMap[evse?.evseUid] = true;
      });
    });
  }, [
    accessGroup.cpoPartyUid,
    getEvseAccessGroupsEvseDetails,
    searchParams,
    text,
    uid,
    page,
  ]);
  const handleAddEVSEsToAccessGroup = async () => {
    try {
      setIsLoading(true);
      await Promise.all(
        selectedRowsToAdd.map((evseUid: string) => {
          return publishEvseAccessGroupAssociation({
            variables: {
              input: {
                accessGroupUid: accessGroup?.uid,
                evseUid: evseUid,
                status: "ACTIVE",
              },
            },
          });
        })
      ).then((res) => {
        let hasError: boolean = false;
        res.forEach((item) => {
          if (item.data.publishEvseAccessGroupAssociation.statusCode !== 201) {
            hasError = true;
          }
        });
        if (!hasError) {
          setTimeout(() => {
            setSelectedRowsToAdd([]);
            setAddMode(false);
            refetch();
            setIsLoading(false);
            toast.success("EVSEs added successfully");
          }, 3000);
        }
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const handleRemoveEVSEsToAccessGroup = async () => {
    try {
      setIsLoading(true);
      await Promise.all(
        selectedRowsToDelete.map((evseUid: string) => {
          return publishEvseAccessGroupAssociation({
            variables: {
              input: {
                accessGroupUid: accessGroup?.uid,
                evseUid: evseUid,
                status: "REMOVED",
              },
            },
          });
        })
      ).then((res) => {
        let hasError: boolean = false;
        res.forEach((item) => {
          if (item.data.publishEvseAccessGroupAssociation.statusCode !== 201) {
            hasError = true;
          }
        });
        if (!hasError) {
          setTimeout(() => {
            setSelectedRowToDelete([]);
            setRemoveMode(false);
            refetch();
            setIsLoading(false);
            toast.success("EVSEs removed successfully");
          }, 3000);
        }
      });
    } catch (e) {
      setIsLoading(false);
    }
  };
  const handleToggleAddMode = () => {
    if (AddMode && selectedRowsToAdd.length > 0) {
      setErrorType({
        type: "ADD",
        message: `You have selected ${selectedRowsToAdd.length} items . Do you want to deselect All?`,
      });
      setIsOpen(true);
    } else {
      setAddMode((prev) => !prev);
    }
  };

  return (
    <Flex fullSize>
      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <Flex>
          {selectedRowsToDelete.length > 0 && (
            <Box>
              <Flex flexDirection={"row"} justifyContent={"space-between"}>
                <Flex
                  flexDirection={"row"}
                  style={{ gap: "10px 10px" }}
                  alignItems={"center"}
                >
                  <Typography pr={3}>
                    {" "}
                    <strong>Selected EVSEs to remove :</strong>{" "}
                    {selectedRowsToDelete.length}
                  </Typography>
                  <Button
                    onClick={() => setSelectedRowToDelete([])}
                    variant="light"
                    withBorder
                    $size="small"
                  >
                    deselect
                  </Button>
                  <Button
                    onClick={handleRemoveEVSEsToAccessGroup}
                    variant="primary"
                    $size="small"
                    width={140}
                    loading$={isLoading}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Box>
          )}
          {selectedRowsToAdd.length > 0 && (
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              style={{ gap: "10px 10px" }}
            >
              <Typography pr={3}>
                <strong>Selected EVSE To Add :</strong>
                {selectedRowsToAdd.length}
              </Typography>
              <Button onClick={() => setSelectedRowsToAdd([])} $size="small">
                deselect
              </Button>
              <Button
                onClick={handleAddEVSEsToAccessGroup}
                $size="small"
                width={140}
                loading$={isLoading}
              >
                Save
              </Button>
            </Flex>
          )}
        </Flex>

        <Flex
          p={2}
          justifyContent={"flex-end"}
          flexDirection={"row"}
          style={{ gap: "10px 10px " }}
        >
          <Button
            disabled={RemoveMode}
            $size="small"
            onClick={handleToggleAddMode}
            icon={<Add />}
            iconPosition="right"
          >
            Add
          </Button>
          <Button
            disabled={AddMode}
            onClick={() => setRemoveMode((prev) => !prev)}
            icon={<Delete />}
            iconPosition="right"
            $size="small"
          >
            Remove
          </Button>
        </Flex>
      </Flex>
      <Flex
        p={2}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      ></Flex>

      {AddMode ? (
        <AccessGroupEvseWithAddAction
          selectedRowsToAdd={selectedRowsToAdd}
          setSelectedRowsToAdd={setSelectedRowsToAdd}
          text={text as string}
        />
      ) : (
        <Flex fullSize>
          <FiltersList
            onClickRefreshButton={handleRefresh}
            textInputProps={{
              onChange: handleChangeInput,
              defaultValue: searchParams?.get("text") || "",
            }}
          />
          <Card
            minWidth={300}
            minHeight={300}
            overflow="hidden"
            bg="white"
            position="relative"
            display="flex"
            width="100%"
            height="100%"
            borderRadius={"none"}
          >
            <SmartTable
              tableRef={tableRef}
              fixed={false}
              rowHeight={50}
              columns={smallColumns as any}
              // onEndReached={() => {
              //   if (data?.EvseAccessGroup?.EVSEs?.results?.length < count) {
              //     pageRef.current += 1;
              //     fetchMoreEvseAccessGroupsEvseDetails(pageRef.current);
              //   }
              // }}
              loading={loading}
              data={
                data?.EvseAccessGroup?.EVSEs?.results?.map(
                  (evse: any) => evse?.EVSE
                ) || []
              }
              paginationProps={{
                page: page,
                limit: 10,
                count: count,
                onPageChange(event, page) {
                  setPage(page);
                },
              }}
              selectedRowProps={{
                selectedRow: "",
                onRowChange: () => null,
              }}
            />
          </Card>
        </Flex>
      )}
      {isOpen && (
        <Dialog
          width="500px"
          height="200px"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          withCloseButton={false}
        >
          <DialogHeader>
            <Typography variant={"title30"} color="warning">
              {" "}
              Warning!
            </Typography>
          </DialogHeader>
          <DialogBody>
            <Typography variant="body20">{errorType?.message}</Typography>
          </DialogBody>
          <Flex
            flexDirection={"row"}
            style={{ gap: "10px 10px" }}
            alignItems={"left"}
            p={2}
          >
            <Box>
              <Button
                variant="gray"
                onClick={() => {
                  setIsOpen(false);
                  setSelectedRowsToAdd([]);
                  setAddMode(false);
                  // handleToggleAddMode();
                }}
                $size="small"
              >
                deselect All
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                $size="large"
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </Dialog>
      )}
    </Flex>
  );
};

export const AccessGroupEvseWithAddAction: React.FC<{
  text: string;
  selectedRowsToAdd: string[];
  setSelectedRowsToAdd: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ text, selectedRowsToAdd, setSelectedRowsToAdd }) => {
  const { accessGroup }: any = useOutletContext();
  const tableRef = React.useRef<any>();
  const pageRef = React.useRef<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    loading: loadingGetEvsesListWithAccessGroups,
    data,
    fetchMore: fetchMoreAccessGroupEVSEs,
    getEvsesListWithAccessGroups,
    refetch: refetchAccessGroupEVSEs,
  } = useGetEVSEsListWithAccessGroups();

  const columns: Column[] = [
    {
      ...columnDefaultOptions("add"),
      width: 50,
      align: "center",
      resizable: false,
      selectedRowKeys: { selectedRowsToAdd },
      headerRenderer: ({ column }) => {
        return <Add fill="white" />;
      },
      cellRenderer: ({ rowData }: any) => (
        <input
          onClick={(e: any) => e.stopPropagation()}
          type="checkbox"
          checked={selectedRowsToAdd.includes(rowData.uid)}
          onChange={() => handleRowSelection({ rowData, type: "Add" })}
          disabled={rowData.readonly}
          style={{ width: 20, height: 20 }}
        />
      ),
    },

    {
      ...columnDefaultOptions("label"),
      flexGrow: 3,
      width: 120,
      resizable: true,
      sortable: true,
    },
    {
      key: "status",
      title: "status",
      dataKey: "status",
      flexGrow: 2,
      width: 120,
      resizable: true,
      dataGetter: ({ rowData }) => {
        return (
          <Typography color={getStatusColor(rowData?.status as string)}>
            {rowData?.status}
          </Typography>
        );
      },
    },
    {
      key: "projectID ",
      title: "Project ID ",
      dataKey: "projectID",
      width: 120,
      align: "left",
      flexGrow: 2,
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.Project?.projectId;
      },
      resizable: true,
    },
    {
      key: "projectName",
      title: "Project Name",
      dataKey: "projectName",
      width: 120,
      align: "left",
      flexGrow: 2,
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.Project?.name;
      },
      resizable: true,
    },

    {
      key: "location",
      title: "location",
      dataKey: "location",
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.label;
      },
      flexGrow: 3,
      width: 200,
      resizable: true,
      sortable: true,
    },
    {
      key: "COD",
      title: "COD",
      dataKey: "COD",
      dataGetter: ({ rowData }) => {
        return rowData?.EVC?.Location?.Project?.cod;
      },
      flexGrow: 3,
      width: 120,
      resizable: true,
      sortable: true,
    },
  ];
  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleRowSelection = ({ rowData, type }: any) => {
    const selectedRowKeys = [...selectedRowsToAdd];
    const rowIndex = selectedRowKeys.indexOf(rowData.uid);
    if (rowIndex !== -1) {
      selectedRowKeys.splice(rowIndex, 1);
    } else {
      selectedRowKeys.push(rowData.uid);
    }
    setSelectedRowsToAdd(selectedRowKeys);
  };

  const handleRefresh = useCallback(() => {
    pageRef.current = 1;
    tableRef?.current?.scrollToTop(0);
    refetchAccessGroupEVSEs();
  }, [refetchAccessGroupEVSEs]);

  useEffect(() => {
    pageRef.current = 1;
    getEvsesListWithAccessGroups({
      variables: {
        filter: {
          cpoPartyUid: accessGroup?.cpoPartyUid,
          text: text,
        },
        pagination: {
          limit: 20,
          page: pageRef.current,
        },
      },
    });
    //   .then((res) => {
    //   const response: any = res.data?.EVSEs?.results;
    //   let newArr: string[] = [];
    //   response?.forEach((evse: any) => {
    //     evse?.AccessGroupAssociation?.results?.forEach(
    //       (association: EvseAccessGroupAssociation) => {
    //         if (
    //           association.accessGroupUid === accessGroup?.uid &&
    //           association.status === "ACTIVE"
    //         ) {
    //           newArr.push(evse.uid);
    //         }
    //       }
    //     );
    //     setSelectedRowsToAdd(newArr);
    //   });
    // });
  }, [
    accessGroup?.cpoPartyUid,
    accessGroup?.uid,
    getEvsesListWithAccessGroups,
    setSelectedRowsToAdd,
    text,
  ]);
  return (
    <Flex fullSize>
      <FiltersList
        onClickRefreshButton={handleRefresh}
        textInputProps={{
          onChange: handleChangeInput,
          defaultValue: searchParams?.get("text") || "",
        }}
      />
      <Card
        minWidth={300}
        minHeight={300}
        overflow="hidden"
        bg="white"
        position="relative"
        display="flex"
        width="100%"
        height="100%"
        borderRadius={"none"}
      >
        <Table
          tableRef={tableRef}
          fixed={false}
          rowHeight={50}
          columns={columns as any}
          onEndReached={() => {
            if (
              data?.EVSEs &&
              data?.EVSEs?.total > data.EVSEs?.results.length
            ) {
              pageRef.current += 1;
              fetchMoreAccessGroupEVSEs(pageRef.current);
            }
          }}
          loadingMore={loadingGetEvsesListWithAccessGroups}
          data={
            data?.EVSEs?.results?.filter((evse: any) => {
              return !evse?.AccessGroupAssociation?.results?.some(
                (association: EvseAccessGroupAssociation) =>
                  association.accessGroupUid === accessGroup?.uid &&
                  association.status === "ACTIVE"
              );
            }) || []
          }
        />
      </Card>
    </Flex>
  );
};
