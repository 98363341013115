import { StandaloneSearchBox } from "@react-google-maps/api";
import { memo, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Search } from "../../../../shared/assets/svg";
import Hexagon from "../../../../shared/assets/svg/Hexagon";
import { Button, Flex } from "../../../../shared/UI";
import { colors } from "../../../../shared/UI/theme/colors";
import { useProjects } from "../../hooks/useProjects";
import { useSiteSelectionContext } from "../../slices/ContextProvider";
import { useSiteSelectionEvcDataContext } from "../../slices/EvcDataContextProvider";
import { mapKwh } from "../../utils/map-kwh";
import { CityInfoList } from "./CityInfoList";

const SearchBox = () => (
  <StandaloneSearchBox>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        margin: "0px 0px",
      }}
    >
      <input
        type="search"
        placeholder="Search"
        id="addressInput"
        style={{
          width: "100%",
          height: "40px",
          background: "#FFFFFF",
          boxSizing: "border-box",
          padding: "0px 10px",
          fontFamily: "Rubik",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "300",
          lineHeight: "normal",
          color: "#000000",
          borderRadius: "10px",
          border: "1px solid #3D729C",
        }}
      />
      <div
        style={{
          position: "absolute",
          right: "12px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <Search />
      </div>
    </div>
  </StandaloneSearchBox>
);

const Tooltip = ({ children }: PropsWithChildren<{}>) => (
  <span
    style={{
      visibility: "visible",
      width: "160px",
      backgroundColor: "#333",
      color: "#122967",
      padding: "14px",
      position: "absolute",
      zIndex: 1,
      bottom: "50%",
      left: "50%",
      opacity: 1,
      transition: "opacity 0.2s",
      border: "1px solid #122967",
      borderRadius: "10px 10px 10px 0px",
      background: "#FFF",
      textAlign: "left",
    }}
  >
    {children}
  </span>
);

const HelpIcon = memo(
  ({ tooltipText }: { tooltipText: string }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const toggleTooltip = () => {
      setShowTooltip(!showTooltip);
    };

    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        <div
          style={{
            display: "flex",
            borderRadius: "100%",
            background: "#48BBFA",
            width: "20px",
            height: "20px",
            alignItems: "center",
            textAlign: "center",
            color: "#FFF",
            cursor: "pointer",
            fontFamily: "Rubik",
            fontSize: "12.021px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            justifyContent: "center",
          }}
          onMouseEnter={toggleTooltip}
          onMouseLeave={toggleTooltip}
        >
          ?{showTooltip && <Tooltip>{tooltipText}</Tooltip>}
        </div>
      </div>
    );
  },
  (prev, actual) => prev.tooltipText === actual.tooltipText
);

const EvcInfoCard = memo(
  (props: { title: string; countAC: number; countDC: number }) => {
    return (
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        minWidth={"140px"}
      >
        <Flex
          style={{
            fontSize: "17px",
            color: "#3D729C",
          }}
        >
          {props.title}
        </Flex>
        <hr
          style={{
            border: "1px solid #D1D2D4",
            padding: "0px 25%",
            marginTop: "10px",
          }}
        />
        <Flex
          style={{
            fontSize: "55px",
            color: "#122967",
            marginTop: "5px",
          }}
        >
          {props.countAC + props.countDC}
        </Flex>
        <Flex
          style={{
            width: "100%",
            fontSize: "10px",
            color: "#FFF",
            marginTop: "5px",
            background: "#3D729C",
            alignItems: "center",
            padding: "3px",
          }}
        >
          {props.countAC} AC - {props.countDC} DC
        </Flex>
      </Flex>
    );
  },
  (prev, actual) =>
    prev.countAC === actual.countAC && prev.countDC === actual.countDC
);

const EvcForecastInfoCard = memo(
  (props: {
    title: string;
    count: number;
    icon: any;
    color: string;
    kWh: number;
  }) => {
    const mWh = props.kWh / 1000;
    const GWh = mWh / 1000;
    const consumption =
      GWh > 1
        ? `${GWh.toFixed(2)} GWh/y`
        : mWh > 1
        ? `${mWh.toFixed(2)} MWh/y`
        : `${props.kWh} kWh/y`;

    return (
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-around"}
        minWidth={"100px"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            width: "100%",
            fontSize: "10px",
            color: "#FFF",
            marginTop: "5px",
            background: props.color,
            alignItems: "center",
            padding: "4px",
            height: "24px",
          }}
        >
          {props.title} {props.icon}
        </Flex>
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          flexGrow={1}
          style={{
            color: props.color,
            marginTop: "5px",
          }}
        >
          <p
            style={{
              fontSize: props.count > 99 ? "35px" : "55px",
            }}
          >
            {props.count}
          </p>
          <p
            style={{
              fontSize: "12px",
              marginLeft: "5px",
            }}
          >
            EVC
          </p>
        </Flex>
        <hr
          style={{
            border: `1.5px solid ${props.color}`,
            padding: "0px",
            marginTop: "10px",
            width: "100%",
          }}
        />
        <Flex
          margin={"13px 0px"}
          style={{
            fontSize: "15px",
            color: props.color,
          }}
        >
          {consumption}
        </Flex>
      </Flex>
    );
  },
  (prev, actual) =>
    prev.count === actual.count &&
    prev.kWh === actual.kWh &&
    prev.color === actual.color
);

const ForecastCard = memo(
  (props: {
    forecast: {
      ac: number;
      dc: number;
      nAc: number;
      nDc: number;
    };
  }) => {
    const {
      state: { mode, selectedHexagon },
    } = useSiteSelectionContext();
    const { t } = useTranslation();

    const totalForecast = useMemo(() => {
      const val = mapKwh(props.forecast.ac + props.forecast.dc);
      return {
        consumption: val.value,
        unit: val.unit,
      };
    }, [props.forecast]);

    return (
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius="10px"
        border="2px solid #D4E0EF"
        background={mode === "optimized" ? "#F5F5F5" : "#BCDFF3"}
        padding={"0px"}
        style={{
          width: "100%",
        }}
      >
        <Flex flexDirection={"row"} alignItems={"center"} padding={"15px"}>
          <div
            style={{
              color: "#3D729C",
              fontSize: "26px",
              marginRight: "5px",
            }}
          >
            {mode === "optimized"
              ? t("siteSelection.general.infoCard.forecast.title")
              : "CONFIGURAZIONE SEMPLIFICATA"}
          </div>
          <HelpIcon
            tooltipText={t("siteSelection.general.infoCard.help.forecast")}
          />
        </Flex>
        {/* START HEXAGON RANKING */}
        {mode === "simple" && selectedHexagon && selectedHexagon.score > 0 && (
          <Flex padding={"15px"} width={"100%"} position={"relative"}>
            <hr
              style={{
                border: "1px solid #3D729C",
              }}
            />
            <Flex
              p={"0 1rem"}
              position={"absolute"}
              top={"50%"}
              left={"50%"}
              background={"white"}
              borderRadius={"10px"}
              border={"2px solid #3D729C"}
              style={{
                textTransform: "uppercase",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Flex
                position={"relative"}
                alignItems={"center"}
                flexDirection={"row"}
              >
                <Flex position={"absolute"} width={40} margin={0}>
                  <Hexagon
                    flatTop={true}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      fill: colors.primary,
                      stroke: colors.primaryDark,
                      strokeWidth: 5,
                    }}
                  />
                  <Flex
                    position={"absolute"}
                    top={"50%"}
                    left={"50%"}
                    fontWeight={"700"}
                    color="#FFF"
                    style={{
                      fontSize: 14,
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {selectedHexagon.score.toFixed(0)}%
                  </Flex>
                </Flex>

                <Flex
                  marginLeft={"45px"}
                  fontFamily={"Rubik"}
                  fontSize={"11px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"16px"}
                  color={"#000"}
                >
                  <div>
                    {t(
                      "siteSelection.general.infoCard.forecast.hexagonRanking"
                    )}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
        {/* END HEXAGON RANKING */}
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          padding={"15px"}
          justifyContent={"space-between"}
          style={{
            width: "100%",
          }}
        >
          <Flex
            justifyContent={"space-around"}
            alignItems={"center"}
            alignContent={"center"}
            padding={"15px"}
            style={{
              minWidth: "50%",
              // background: "#BCDFF3",
              background: mode === "optimized" ? "#BCDFF3" : "#122967",
              height: "165px",
              color: mode === "optimized" ? "#3D729C" : "#FFF",
            }}
          >
            <p
              style={{
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              {t("siteSelection.general.infoCard.forecast.totalForecast")}
            </p>

            <p
              style={{
                fontSize: "40px",
                fontWeight: "700",
              }}
            >
              {totalForecast.unit !== "kWh"
                ? totalForecast.consumption.toFixed(2)
                : totalForecast.consumption}
            </p>

            <p
              style={{
                fontSize: "22px",
              }}
            >
              {totalForecast.unit}/y
            </p>
          </Flex>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-around"}
            style={{
              marginLeft: "10px",
              color: "#02C1B3",
              fontSize: "24px",
              whiteSpace: "nowrap",
              fontWeight: "500",
              width: "100%",
              height: "100%",
            }}
          >
            <EvcForecastInfoCard
              title="AC"
              icon=""
              // color={"#3D729C"}
              color={mode === "optimized" ? "#3D729C" : "#3D729C"}
              count={props.forecast.nAc}
              kWh={props.forecast.ac}
            />

            <EvcForecastInfoCard
              title="DC"
              // color="#443F6A"
              color={mode === "optimized" ? "#443F6A" : "#3D729C"}
              icon=""
              count={props.forecast.nDc}
              kWh={props.forecast.dc}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  },
  (prev, actual) => prev.forecast === actual.forecast
);

const CompetitorCard = memo(
  (props: { ac: number; dc: number }) => {
    const { t } = useTranslation();
    return (
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        marginTop={"20px"}
        justifyContent={"space-between"}
      >
        <div
          style={{
            color: "#3D729C",
            fontSize: "34px",
            flexWrap: "wrap",
            whiteSpace: "pre-wrap",
          }}
        >
          {t("siteSelection.general.infoCard.competitor.title")}
        </div>
        {/* TODO: add graph here */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="91"
          height="92"
          viewBox="0 0 91 92"
          fill="none"
        >
          <path
            d="M85.6124 23.8008L71.2156 30.9032C73.711 35.1263 75.0547 40.1172 75.0547 45.492C75.0547 56.4336 69.104 66.0314 60.082 71.2143L68.5282 84.8433C81.9652 76.973 90.9872 62.1923 90.9872 45.492C91.1792 37.6217 89.0676 30.3273 85.6124 23.8008Z"
            fill="#F69100"
          />
          <path
            d="M45.4941 75.0536C33.4008 75.0536 23.0351 67.7593 18.4281 57.3936L4.03125 64.496C11.3256 80.2365 27.0662 91.1781 45.4941 91.1781C53.9402 91.1781 61.8105 88.8746 68.529 84.8435L60.0829 71.2145C55.8598 73.7099 50.8689 75.0536 45.4941 75.0536Z"
            fill="#02C1B3"
          />
          <path
            d="M15.9325 46.1224C15.9325 35.9487 21.1154 26.9267 28.9856 21.5519L20.5395 7.92285C8.06222 16.177 0 30.1899 0 45.9305C0 52.649 1.53566 59.1756 4.22306 64.9343L18.6199 57.8319C16.8923 54.3766 15.9325 50.3455 15.9325 46.1224Z"
            fill="#02C1B3"
          />
          <path
            d="M45.494 15.9325C56.4356 15.9325 66.0335 22.0751 71.2163 30.9051L85.6131 23.8027C77.9348 9.59787 62.7702 0 45.494 0C45.1101 0 44.9181 0 44.5342 0V16.1244C44.9181 15.9325 45.1101 15.9325 45.494 15.9325Z"
            fill="#F69100"
          />
          <path
            opacity="0.38"
            d="M44.5343 16.1244V0C35.7043 0.191958 27.4501 2.87935 20.3477 7.48634L28.7938 21.1153C33.4008 18.044 38.7756 16.1244 44.5343 16.1244Z"
            fill="#E9F4FF"
          />
        </svg>

        <Flex
          justifyContent={"space-around"}
          marginRight={"30px"}
          height={"100%"}
          width={"100%"}
          style={{
            width: "auto",
          }}
        >
          <Flex
            flexDirection={"row"}
            marginLeft={"10px"}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <div
              style={{
                borderRadius: "100%",
                minWidth: "15px",
                minHeight: "15px",
                maxWidth: "15px",
                maxHeight: "15px",
                background: "#F69100",
              }}
            />
            <div
              style={{
                marginLeft: "10px",
                color: "#F69100",
                fontSize: "24px",
                whiteSpace: "nowrap",
                fontWeight: "500",
              }}
            >
              AC - {props.ac}
            </div>
          </Flex>
          <Flex
            flexDirection={"row"}
            marginLeft={"10px"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <div
              style={{
                borderRadius: "100%",
                minWidth: "15px",
                minHeight: "15px",
                maxWidth: "15px",
                maxHeight: "15px",
                background: "#02C1B3",
              }}
            />
            <div
              style={{
                marginLeft: "10px",
                color: "#02C1B3",
                fontSize: "24px",
                whiteSpace: "nowrap",
                fontWeight: "500",
              }}
            >
              DC - {props.dc}
            </div>
          </Flex>
        </Flex>
      </Flex>
    );
  },
  (prev, actual) => prev.ac === actual.ac && prev.dc === actual.dc
);

export const InfoCard = (props: { projectId?: string }) => {
  const { t } = useTranslation();

  const [view, setView] = useState<"info" | "city-list">("info");

  const {
    state: {
      selectedCity,
      selectedHexagon,
      selectedProvince,
      selectedRegion,
      mode,
    },
  } = useSiteSelectionContext();

  const { becEvcs, competitorsEvcs, becEvcsWip, regionStats } =
    useSiteSelectionEvcDataContext();

  const { projects } = useProjects({
    filter: {
      status: "open",
      visibility: props.projectId === undefined ? "public" : undefined,
    },
  });

  const filterBySelectedArea = useCallback(
    (boundOnly = false) =>
      (l: { latitude: number; longitude: number }) => {
        if (selectedHexagon) {
          const poly = new google.maps.Polygon({
            paths: selectedHexagon.polygon,
          });
          return google.maps.geometry.poly.containsLocation(
            new google.maps.LatLng({
              lat: l.latitude,
              lng: l.longitude,
            }),
            poly
          );
        } else if (selectedCity || selectedProvince || selectedRegion) {
          const feature = selectedCity ?? selectedProvince ?? selectedRegion!;

          const bound = new google.maps.LatLngBounds(
            new google.maps.LatLng(
              feature.properties.bound.minLat,
              feature.properties.bound.minLng
            ),
            new google.maps.LatLng(
              feature.properties.bound.maxLat,
              feature.properties.bound.maxLng
            )
          );
          if (
            !bound.contains(new google.maps.LatLng(l.latitude, l.longitude))
          ) {
            return false;
          }

          if (boundOnly && !selectedCity) return true;

          const coords =
            feature.geometry.type === "Polygon"
              ? [feature.geometry.coordinates]
              : feature.geometry.coordinates;

          const i = coords.find((c) => {
            const poly = new google.maps.Polygon({
              paths: c[0].map((c) => ({ lat: c[1], lng: c[0] })),
            });
            return google.maps.geometry.poly.containsLocation(
              new google.maps.LatLng({
                lat: l.latitude,
                lng: l.longitude,
              }),
              poly
            );
          });

          return !!i;
        } else {
          return true;
        }
      },
    [selectedCity, selectedHexagon, selectedProvince, selectedRegion]
  );

  const counter = useCallback(
    (
      data: {
        latitude: number;
        longitude: number;
        ac: number;
        dc: number;
      }[],
      flag: boolean
    ) => {
      return data.filter(filterBySelectedArea(flag)).reduce(
        (acc, curr) => {
          return {
            ac: Math.floor(acc.ac) + Math.floor(curr.ac),
            dc: Math.floor(acc.dc) + Math.floor(curr.dc),
          };
        },
        {
          ac: 0,
          dc: 0,
        }
      );
    },
    [filterBySelectedArea]
  );

  const onGroundEvcs = useMemo(() => {
    const a = becEvcs?.filter(filterBySelectedArea(true)).reduce(
      (acc, curr) => {
        return {
          ac: acc.ac + (curr.evcModelChargeMode === "AC" ? 1 : 0),
          dc: acc.dc + (curr.evcModelChargeMode === "DC" ? 1 : 0),
        };
      },
      {
        ac: 0,
        dc: 0,
      }
    );

    return a;
  }, [becEvcs, filterBySelectedArea]);

  const competitorsEvcsStats = useMemo(() => {
    const a = competitorsEvcs?.filter(filterBySelectedArea(true)).reduce(
      (acc, curr) => {
        return {
          ac: acc.ac + (curr.evcModelChargeMode === "AC" ? 1 : 0),
          dc: acc.dc + (curr.evcModelChargeMode === "DC" ? 1 : 0),
        };
      },
      {
        ac: 0,
        dc: 0,
      }
    );

    return a;
  }, [competitorsEvcs, filterBySelectedArea]);

  const countEvcs = useMemo(
    () => counter(projects?.flatMap((p) => p.locations ?? []) ?? [], false),
    [counter, projects]
  );

  const countWipEvcs = useMemo(
    () =>
      counter(
        becEvcsWip?.map((e) => ({
          latitude: e.latitude,
          longitude: e.longitude,
          ac: e.evcModelChargeMode === "AC" ? 1 : 0,
          dc: e.evcModelChargeMode === "DC" ? 1 : 0,
        })) ?? [],
        false
      ),
    [becEvcsWip, counter]
  );

  const forecast = useMemo(() => {
    if (selectedHexagon) {
      return {
        ac: mode === "optimized" ? selectedHexagon.kwh_ac : selectedHexagon.ac,
        dc: mode === "optimized" ? selectedHexagon.kwh_dc : selectedHexagon.dc,
        nAc: mode === "optimized" ? selectedHexagon.n_ac : 0,
        nDc: mode === "optimized" ? selectedHexagon.n_dc : 0,
      };
    } else if (selectedCity) {
      return {
        ac: selectedCity.properties.stats.kwh_ac,
        dc: selectedCity.properties.stats.kwh_dc,
        nAc: selectedCity.properties.stats.n_ac,
        nDc: selectedCity.properties.stats.n_dc,
      };
    } else if (selectedProvince) {
      return {
        ac: selectedProvince.properties.stats.kwh_ac,
        dc: selectedProvince.properties.stats.kwh_dc,
        nAc: selectedProvince.properties.stats.n_ac,
        nDc: selectedProvince.properties.stats.n_dc,
      };
    } else if (selectedRegion) {
      return {
        ac: selectedRegion.properties.stats.kwh_ac,
        dc: selectedRegion.properties.stats.kwh_dc,
        nAc: selectedRegion.properties.stats.n_ac,
        nDc: selectedRegion.properties.stats.n_dc,
      };
    }
    return Object.values(regionStats).reduce(
      (acc, curr) => {
        return {
          ac: acc.ac + curr.kwh_ac,
          dc: acc.dc + curr.kwh_dc,
          nAc: acc.nAc + curr.n_ac,
          nDc: acc.nDc + curr.n_dc,
        };
      },
      {
        ac: 0,
        dc: 0,
        nAc: 0,
        nDc: 0,
      }
    );
  }, [
    mode,
    regionStats,
    selectedCity,
    selectedHexagon,
    selectedProvince,
    selectedRegion,
  ]);

  return (
    <Flex
      style={{
        borderRadius: "10px",
        border: "1px solid #3D729C",
        background: "#FFF",
        boxShadow: "9px 12px 8px 0px rgba(0, 0, 0, 0.25)",
        padding: "20px",
        paddingTop: "25px",
        marginTop: "20px",
        marginBottom: "20px",
        minWidth: "545px",
        fontFamily: "Rubik",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
      }}
    >
      <SearchBox />
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        marginTop={"20px"}
        width={"min"}
        position={"relative"}
      >
        <div
          style={{
            marginRight: "5px",
            fontFamily: "Rubik",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            color: "#3D729C",
          }}
        >
          {t("siteSelection.general.infoCard.title")}
        </div>
        <HelpIcon
          tooltipText={t("siteSelection.general.infoCard.help.becStations")}
        />
        {!selectedCity && (
          <Flex position={"absolute"} right={"0px"} top={"0px"}>
            <Button
              variant={"primary"}
              color={"white"}
              backgroundColor={"#3D729C"}
              border={"1px solid #3D729C"}
              onClick={() => setView(view === "info" ? "city-list" : "info")}
            >
              {view === "info" ? (
                <>
                  {selectedProvince && "Comuni"}
                  {!selectedProvince && selectedRegion && "Province"}
                  {!selectedRegion && "Regioni"}
                </>
              ) : (
                "Info"
              )}
            </Button>
          </Flex>
        )}
      </Flex>
      {(view === "info" || selectedCity) && (
        <>
          <Flex flexDirection={"row"}>
            <Flex
              style={{
                alignItems: "center",
                color: "#3D729C",
                fontSize: "12px",
                padding: "4px 6px",
                justifyContent: "center",
                borderRadius: "5px",
                background: "#D9D9D9",
              }}
            >
              {!selectedRegion && "Italia"}
              {selectedRegion?.properties.DEN_REG}{" "}
              {selectedProvince && ` > ${selectedProvince.properties.DEN_UTS}`}{" "}
              {selectedCity && ` > ${selectedCity.properties.COMUNE}`}{" "}
              {selectedHexagon && ` > #${selectedHexagon.id}`}
            </Flex>
            <Flex flexGrow={1} />
          </Flex>
          <Flex flexGrow="1" />
          <Flex
            flexDirection={"row"}
            alignItems={"center"}
            marginTop={"20px"}
            justifyContent={"space-between"}
          >
            <EvcInfoCard
              title={t("siteSelection.general.infoCard.evcInfoCard.wip")}
              countAC={countEvcs?.ac ?? 0}
              countDC={countEvcs?.dc ?? 0}
            />
            <EvcInfoCard
              title={t("siteSelection.general.infoCard.evcInfoCard.allWip")}
              countAC={countWipEvcs?.ac ?? 0}
              countDC={countWipEvcs?.dc ?? 0}
            />
            <EvcInfoCard
              title={t("siteSelection.general.infoCard.evcInfoCard.onGround")}
              countAC={onGroundEvcs?.ac ?? 0}
              countDC={onGroundEvcs?.dc ?? 0}
            />
          </Flex>
          <Flex flexGrow="2" />
          <CompetitorCard
            ac={competitorsEvcsStats?.ac ?? 0}
            dc={competitorsEvcsStats?.dc ?? 0}
          />
          <Flex flexGrow="2" />
          <ForecastCard forecast={forecast} />
        </>
      )}
      {view === "city-list" && !selectedCity && <CityInfoList />}
    </Flex>
  );
};
