import React, { useEffect, useRef } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Typography,
} from "../../../../../shared/UI";
import VerticalScrollContainer from "../../../../../shared/UI/VerticalScrollContainer";
import { Close } from "../../../../../shared/assets/svg";
import { NinaLog } from "../../../../../shared/Ocpp/nina.ocpp.types";
import moment from "moment";

type LogsProps = {
  logs?: NinaLog[];
  clearLog: () => void;
  setShowLogsBox: React.Dispatch<React.SetStateAction<boolean>>;
};
export const Logs: React.FC<LogsProps> = ({
  logs,
  clearLog,
  setShowLogsBox,
}) => {
  const clearLogs = () => {
    clearLog();
  };
  const hideLogsBox = () => {
    setShowLogsBox(false);
  };

  const logsEndRef = useRef<any>(null);
  const scrollToBottom = () =>
    logsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  useEffect(() => scrollToBottom(), [logs]);

  return (
    <Flex fullSize bg="primary">
      <Flex
        width={"100%"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={2}
      >
        <Typography mb={2} color="white" variant={"title20"}>
          Logs
        </Typography>
        <Flex style={{ gap: "10px" }} flexDirection={"row"}>
          {logs?.length ? (
            <Button variant="light" link onClick={clearLogs}>
              clear Logs
            </Button>
          ) : null}
          <Button variant="light" fixedSize link onClick={hideLogsBox}>
            <Close size="4" />
          </Button>
        </Flex>
      </Flex>
      <Flex fullSize>
        <VerticalScrollContainer>
          <Flex p={2}>
            {logs?.length ? (
              logs.map((el, index) => (
                <Card
                  bg="white"
                  p={2}
                  mb={2}
                  key={index}
                  style={{ borderBottom: "1px solid #737373", flexShrink: 0 }}
                  maxWidth={"300px"}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Flex
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Typography mb={1} fontWeight={"bold"} variant={"body10"}>
                      {el.message}
                    </Typography>
                    <Typography flexShrink={0} variant={"caption10"}>
                      <Badge>{el.level}</Badge>
                    </Typography>
                  </Flex>
                  {el.data ? (
                    <Typography
                      flex={1}
                      style={{ wordBreak: "break-all" }}
                      variant={"body10"}
                      mb={2}
                    >
                      {el.data}
                    </Typography>
                  ) : null}
                  {el.requestData ? (
                    <Typography color="#737373" variant={"caption10"}>
                      {el.requestData}
                    </Typography>
                  ) : null}
                  <Typography color="#737373" variant={"caption10"}>
                    {moment(el.date).format(`H:mm:ss`)}
                  </Typography>
                </Card>
              ))
            ) : (
              <Center>
                <Typography color="white" variant={"caption30"}>
                  Nothing to show
                </Typography>
              </Center>
            )}
          </Flex>

          <Box ref={logsEndRef}></Box>
        </VerticalScrollContainer>
      </Flex>
    </Flex>
  );
};
