import { gql, useLazyQuery } from "@apollo/client";

export const GET_USERS_ACTIONS = gql`
  query UsersActions($pagination: PaginationParams, $filter: FilterParams) {
    Greta {
      UsersActions(pagination: $pagination, filter: $filter) {
        total
        pagination {
          cursor
          last
        }
        results {
          fieldName
          email
          timestamp
          parentTypeName
          id
          stream
          uid
          userUid
          data
        }
      }
    }
  }
`;
export const useGetUsersActions = () => {
  const [
    getUsersActions,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_USERS_ACTIONS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    initialFetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const fetchMore = () => {
    if (
      data?.Greta?.UsersActions?.pagination?.cursor &&
      data?.Greta?.UsersActions?.results?.length <
        data.Greta?.UsersActions?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data.Greta?.UsersActions?.pagination.cursor,
            limit: 50,
          },
        },
        updateQuery(prevData, newData) {
          if (prevData?.Greta?.UsersActions) {
            return {
              Greta: {
                UsersActions: {
                  results: [
                    ...prevData?.Greta?.UsersActions?.results,
                    ...newData?.fetchMoreResult?.Greta?.UsersActions?.results,
                  ],
                  total: prevData?.Greta?.UsersActions?.total,
                  pagination:
                    newData?.fetchMoreResult?.Greta?.UsersActions?.pagination,
                },
                __typename: "Greta",
              },
            };
          } else {
            return {
              Greta: {
                UsersActions: {
                  results:
                    newData?.fetchMoreResult?.Greta?.UsersActions?.results,
                  total: prevData?.Greta?.UsersActions?.total,
                  pagination:
                    newData?.fetchMoreResult?.Greta?.UsersActions?.pagination,
                },
                __typename: "Greta",
              },
            };
          }
        },
      });
    }
  };
  return {
    loading,
    getUsersActions,
    error,
    usersActions: data?.Greta?.UsersActions?.results,
    count: data?.Greta?.UsersActions?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
  };
};
