import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Flex } from "../../../../../shared/UI";
import { EvcsTable } from "../../../../../shared/gql/queries/tables/evcs/EVCsTable";

const LocationEvcs = () => {
  const { uid: locationUid } = useParams();
  const [activeTab, setActiveTab] = useState<"EVSEs" | "EVCs">("EVCs");

  return (
    <Flex p={3} width={"100%"} height={"100%"}>
      <Flex flexDirection="row" justifyContent="flex-end" p={2}>
        {/* <Button
          variant={activeTab === "EVSEs" ? "primary" : "secondary"}
          onClick={() => setActiveTab("EVSEs")}
          mx={2}
        >
          EVSEs
        </Button> */}
        <Button
          variant={activeTab === "EVCs" ? "primary" : "secondary"}
          onClick={() => setActiveTab("EVCs")}
        >
          EVCs
        </Button>
      </Flex>
      {activeTab === "EVCs" ? (
        <EvcsTable
          filter={{
            locationUid: locationUid,
          }}
        />
      ) : null}
    </Flex>
  );
};

export default LocationEvcs;
