import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CsoState = {
      selectedLocation: any;
};
const initialState: CsoState = {
      selectedLocation: null
};

const locations = createSlice({
      name: "locations",
      initialState,
      reducers: {
            setSelectedLocation: (state, action: PayloadAction<any>) => {
                  state.selectedLocation = action.payload;
            }
      },
});

export const { setSelectedLocation } = locations.actions;
export default locations.reducer;
