/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated";

export const GET_SDRs = gql(`
  query SDRs(
    $filter: SdrFilterParams
    $pagination: PaginationParams
    $sort: SdrSortingParams
  ) {
    SDRs(filter: $filter, pagination: $pagination, sort: $sort) {
      total
      pagination {
        cursor
        last
      }
      results {
        __typename
        invalid
         tariff {
          tariffAltText{
            language
            text
          }
         }
        activities {
          __typename
          actualEnd
          authorizationMethod
          authorizationReference
          completed
          consumptions {
            __typename
            energy
            parkingTime
            time
          }
        
          costs {
            __typename
            energy
            parkingTime
            time
          }
          createdAt
          endedAt
          evc {
            __typename
            assetStatus
            code
            createdAt
            description
            label
            evse {
              __typename
              label
              uid
            }
            protocol
            status
            uid
            updatedAt
          }
          evcUid
          evseUid
          expectedEnd
          startedAt
          status
          totalCost
          transactionUid
          type
          uid
          updatedAt
        }
        createdAt
        currency
        location {
          __typename
          address
          city
          country
          cpoPartyUid
          createdAt
          label
          status
          type
          uid
          updatedAt
          upmId
        }
        origin
        sessionStart
        sessionStop
        sessionUid
        token {
          __typename
          label
          uid
          origin
          emspPartyUid
        }
        totalCost
        totalTime
        uid
      }
      total
    }
  }
`);
export const useGetSdrs = () => {
  const [
    getSDRs,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_SDRs, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
  });
  const fetchMore = () => {
    if (
      data?.SDRs?.pagination?.cursor &&
      data?.SDRs?.results?.length < data.SDRs?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.SDRs?.pagination.cursor,
          },
        },
        updateQuery({ SDRs: prevData }, { fetchMoreResult }) {
          if (prevData?.results?.length) {
            return {
              SDRs: {
                results: [
                  ...prevData?.results,
                  ...fetchMoreResult?.SDRs?.results,
                ],
                __typename: fetchMoreResult.SDRs.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.SDRs?.pagination,
              },
            };
          } else {
            return {
              SDRs: {
                results: [...fetchMoreResult?.SDRs?.results],
                __typename: fetchMoreResult.SDRs.__typename,
                total: prevData?.total,
                pagination: fetchMoreResult?.SDRs?.pagination,
              },
            };
          }
        },
      });
    }
  };
  return {
    loading,
    getSDRs,
    error,
    count: data?.SDRs?.total,
    SDRs: data?.SDRs?.results,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
