import { gql, useLazyQuery } from "@apollo/client";

export const STOP_CHARGE_PROCESSED = gql`
  query StopChargeTxProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    StopChargeTxProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        status
        info
        timestamp
        requestUid
        request {
          requestUid
          transactionUid
        }
      }
    }
  }
`;
export const useStopChargeTxProcessed = () => {
  const [
    getStopChargeTxProcessed,
    {
      loading,
      error,
      data,
      refetch,
      updateQuery,
      networkStatus,
      startPolling,
      client,
    },
  ] = useLazyQuery(STOP_CHARGE_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });
  return {
    loading,
    client,
    getStopChargeTxProcessed,
    error,
    stopChargeTxProcessed: data?.StopChargeTxProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
