import { gql, useLazyQuery } from "@apollo/client";

const GET_AGGREGATION_EVC = gql(`
  query EVCsAggregate($field: EvcAggregableField!, $filter: EvcFilterParams, $limit: Int) {
  Aggregation {
    EVCs(field: $field, filter: $filter, limit: $limit) {
      __typename
      results {
        __typename
        doc_count
        key
      }
      total
    }
  }
}
`);

export const useGetAggregationEVCs = () => {
  const [
    getEvcsAggrField,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_AGGREGATION_EVC, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getEvcsAggrField,
    error,
    data: data?.Aggregation?.EVCs?.results,
    count: data?.Aggregation?.EVCs?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
