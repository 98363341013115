/** @format */

import { useState } from "react";
import { Session } from "../../../../generated/graphql";
import { ColumnShape } from "../../../../../UI";
import { FilterContainer } from "../../../../../components/table-filter/FilterContainer";

type Props = {
  column: ColumnShape<Session>;
};
export const FilterSessionsByStatus: React.FC<Props> = ({ column }) => {
  const [status, setStatus] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <FilterContainer
      loading={false}
      options={["ACTIVE", "COMPLETED"]}
      searchValue="status"
      status={status}
      setStatus={setStatus}
      column={column}
      multiCheck={false}
    />
  );
};
