import { SVGProps, memo } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={69}
    height={69}
    fill="none"
    {...props}
  >
    <path
      fill="#008FDD"
      d="M32.344 46.503V19.837l-8.625 8.625-3.09-3.09L34.5 11.5l13.872 13.872-3.09 3.09-8.626-8.625v26.666h-4.312ZM15.812 57.5c-1.15 0-2.156-.431-3.018-1.294-.863-.862-1.294-1.869-1.294-3.019V42.91h4.313v10.279h37.374V42.909H57.5v10.279c0 1.15-.431 2.156-1.294 3.018-.862.863-1.869 1.294-3.019 1.294H15.813Z"
    />
  </svg>
)
const Upload = memo(SvgComponent)
export default Upload
