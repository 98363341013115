/** @format */

import { gql, useQuery } from "@apollo/client";

export const GRETA_INFO = gql`
  query gretaInfo {
    Greta {
      identity {
        email
        firstName
        lastName
        username
        locale
        roles {
          realm
          greta
        }
        cpoUid
        userGroups
      }
      stage
    }
  }
`;

export const useGetUserInfo = () => {
  const {
    loading,
    error,
    data,
    refetch,
    fetchMore,
    updateQuery,
    networkStatus,
    startPolling,
    called,
  } = useQuery(GRETA_INFO, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
    errorPolicy: "ignore",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    error,
    userInfo: data?.Greta,
    refetch,
    fetchMore,
    startPolling,
    called,
    networkStatus,
    updateQuery,
  };
};
