import { t } from "i18next";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Flex, InputLabel } from "../../../../shared/UI";
import MultiSelect from "../../../../shared/components/MultiSelect";
import { useGetEmspParties } from "../../gql";
import { Typography } from "./../../../../shared/UI/Typography";
import { Info } from "../../../../shared/assets/svg";

type Props = {
  partyId?: string | null;
  canEdit: boolean;
  partyUid?: string | null;
  platformProtocol?: string | null;
};

export const EmspPartyDropdown: React.FC<Props> = ({
  partyId,
  canEdit,
  partyUid,
  platformProtocol,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { count, loadingGetEmspParties, getEmspParties, emspParties } =
    useGetEmspParties();

  const selectedEmsParty = useCallback(
    () =>
      partyUid
        ? emspParties?.find(
            (party: any) => party?.PlatformParty?.uid === partyUid
          )?.PlatformParty
        : null,
    [emspParties, partyUid]
  );

  const cpoPartyUid = watch("cpoPartyUids")?.value;
  const typeWatch = watch("type")?.value;
  const protocol = useMemo(
    () => (typeWatch === "HUBJECT_BLT" ? "OICP" : "OCPI"),
    [typeWatch]
  );

  useEffect(() => {
    if (cpoPartyUid && protocol) {
      getEmspParties({
        variables: {
          filter: {
            cpoPartyUid,
            protocol,
            gireve: typeWatch === "GIREVE_BLT",
          },
          pagination: {
            limit: 1000,
          },
        },
      });
    }
  }, [
    getEmspParties,
    cpoPartyUid,
    protocol,
    partyUid,
    platformProtocol,
    typeWatch,
  ]);
  useEffect(() => {
    if (selectedEmsParty()) {
      setValue("emspParty", [
        {
          label: `${selectedEmsParty()?.name} 
          `,
          value: `${selectedEmsParty()?.partyId}-${
            selectedEmsParty()?.countryCode
          }`,
        },
      ]);
    }
  }, [selectedEmsParty, setValue]);

  return (
    <Flex flex={1} minWidth={300}>
      <InputLabel
        labelColor="white"
        children="EMSP PARTY"
        required={
          ["GIREVE_BLT", "HUBJECT_BLT"].includes(typeWatch) || partyId
            ? true
            : false
        }
      />

      <Controller
        name="emspParty"
        control={control}
        render={({ field }) => (
          <MultiSelect
            isSelectAll={true}
            isMulti
            closeMenuOnSelect={false}
            menuPosition="fixed"
            controlShouldRenderValue
            isLoading={loadingGetEmspParties}
            getOptionValue={(option: any) => option?.value}
            hideSelectedOptions={false}
            isDisabled={!canEdit || !!partyUid || !protocol || !cpoPartyUid}
            placeholder={
              emspParties?.filter(
                (party: {
                  PlatformParty: {
                    role: string;
                  };
                }) => party.PlatformParty.role === "EMSP"
              )?.length
                ? `${
                    emspParties?.filter(
                      (party: {
                        PlatformParty: {
                          role: string;
                        };
                      }) => party.PlatformParty.role === "EMSP"
                    )?.length
                  } ${t("options")}`
                : "Select Emsp party"
            }
            options={
              emspParties
                ?.filter(
                  (party: {
                    PlatformParty: {
                      name: string;
                      partyId: string;
                      uid: string;
                      countryCode: string;
                      role: string;
                    };
                  }) => party.PlatformParty.role === "EMSP"
                )
                .sort(
                  (
                    a: {
                      PlatformParty: {
                        name: string;
                        partyId: string;
                        uid: string;
                        countryCode: string;
                        role: string;
                      };
                    },
                    b: {
                      PlatformParty: {
                        name: string;
                        partyId: string;
                        uid: string;
                        countryCode: string;
                        role: string;
                      };
                    }
                  ) => a.PlatformParty.name.localeCompare(b.PlatformParty.name)
                )
                .map(
                  (party: {
                    PlatformParty: {
                      name: string;
                      partyId: string;
                      uid: string;
                      countryCode: string;
                      role: string;
                    };
                  }): any => ({
                    label: `${party.PlatformParty?.name} `,
                    value: `${party.PlatformParty?.partyId}-${party.PlatformParty?.countryCode}`,
                  })
                ) || []
            }
            error={
              errors.emspParty ? errors.emspParty.message || t("required") : ""
            }
            errorProps={{
              color: "white",
              borderBottom: "1px solid red",
            }}
            {...field}
          />
        )}
      />
      <Typography
        display="flex"
        flexDirection="row"
        variant="caption10"
        color="white"
        pt={1}
      >
        {t(
          `Select to create a tariff valid only for specified eMSP Parties. ${
            !["GIREVE_BLT", "HUBJECT_BLT"].includes(typeWatch)
              ? "Leave empty to create a tariff valid for all eMSP Parties."
              : ""
          }`
        )}
      </Typography>
    </Flex>
  );
};
