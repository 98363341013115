import { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import BackgroundImg from "../assets/bgImage.png";
import SmallLogo from "../assets/logo/becharge-logos-small.png";
import Logo from "../assets/logo/new_logo.png";
import { AngleLeft, AngleRight } from "../assets/svg";
import { CpoSelect } from "../components/CpoSelect";
import useBreakpoints from "../hooks/useBreakpoints";
import { setShowDrawer } from "../slices/layoutItems";
import { Absolute } from "./Absolute";
import { Box } from "./Box";
import { Button } from "./Button";
type DrawerContainerProps = {
  children: React.ReactNode;
  showDrawer: boolean;
};

const DrawerContainer = styled.div<DrawerContainerProps>`
  width: ${({ showDrawer }) => (showDrawer ? "180px" : "60px")};
  min-width: ${({ showDrawer }) => (showDrawer ? "180px" : "60px")};
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  background-image: url(${BackgroundImg});
  background-position: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.4s;
`;
const LogoContainer = styled.div`
  width: 100px;
  height: 200px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 15px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
`;
const ContentContainer = styled.div`
  z-index: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Drawer: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const point = useBreakpoints();

  const { showDrawer } = useSelector((state: any) => state.layout);
  const toggleShowDrawer = () => {
    dispatch(setShowDrawer(!showDrawer));
  };
  // useEffect(() => {
  //   if (["lg", "sm", "md"].includes(String(point))) {
  //     dispatch(setShowDrawer(false));
  //   } else {
  //     dispatch(setShowDrawer(true));
  //   }
  // }, [dispatch, point]);

  return (
    <DrawerContainer showDrawer={showDrawer}>
      <ContentContainer>
        {showDrawer ? (
          <LogoContainer>
            <img alt="be charge" src={Logo} width="80%" />
          </LogoContainer>
        ) : (
          <Box height={70}>
            <img alt="be charge" src={SmallLogo} width={40} />
          </Box>
        )}

        {children}
      </ContentContainer>
      <Absolute top={"0%"} right={-15} zIndex={1000000}>
        <Button
          fixedSize
          $size="small"
          shape="default"
          variant="primary"
          onClick={toggleShowDrawer}
        >
          {showDrawer ? <AngleLeft size="5" /> : <AngleRight size="5" />}
        </Button>
      </Absolute>
      {/* <CpoSelect /> */}
    </DrawerContainer>
  );
};
