import { gql, useMutation } from "@apollo/client";

export const PUBLISH_REMOTE_REFRESH_STATUS = gql`
  mutation publishRemoteRefreshStatus($input: RemoteRefreshStatusInput!) {
    publishRemoteRefreshStatus(input: $input) {
      requestUid
    }
  }
`;

export const usePublishRemoteRefreshStatus = () => {
  const [publishRemoteRefreshStatus, { data, loading, error }] =
    useMutation<any>(PUBLISH_REMOTE_REFRESH_STATUS);
  return {
    publishRemoteRefreshStatus,
    error,
    remoteRefreshStatus: data?.publishRemoteRefreshStatus,
    loading,
  };
};
