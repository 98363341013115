import { useSearchParams } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  ColumnShape,
  Flex,
  Input,
  Popover,
  SimpleLoader,
  Typography,
} from "../../UI";
import { Close, Filter } from "../../assets/svg";
import {
  PopoverBody,
  PopoverHeader,
} from "../../UI/popover/PopoverStyledElement";
import { t } from "i18next";
import {
  convertSearchParamIntoArray,
  convertStringsArrayIntoQuery,
} from "../../helpers";
import { useCallback } from "react";

type Props = {
  column: ColumnShape<any>;
  searchValue: string;
  status: boolean;
  setStatus: React.Dispatch<React.SetStateAction<boolean>>;
  options: string[];
  loading: boolean;
  searchInputValue?: string;
  setSearchInputValue?: React.Dispatch<React.SetStateAction<string>>;
  multiCheck?: boolean;
};

export const FilterContainer: React.FC<Props> = ({
  column,
  status,
  setStatus,
  searchValue,
  options,
  loading,
  searchInputValue,
  setSearchInputValue,
  multiCheck = true,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let selectedItems: string[] =
    convertSearchParamIntoArray(searchParams?.get(searchValue), " OR ") || [];

  const handleReset = useCallback(() => {
    searchParams.delete(searchValue);
    setSearchParams(searchParams);
  }, [searchParams, searchValue, setSearchParams]);

  const onChangeList = (value: string) => (e: any) => {
    if (multiCheck) {
      if (selectedItems?.includes(value)) {
        selectedItems = selectedItems?.filter(
          (option: string) => option !== value
        );
        if (!selectedItems?.length) {
          searchParams.delete(searchValue);
        } else {
          searchParams.set(
            searchValue,
            convertStringsArrayIntoQuery(selectedItems)
          );
        }

        setSearchParams(searchParams);
      } else {
        selectedItems.push(value);
        searchParams.set(
          searchValue,
          convertStringsArrayIntoQuery(selectedItems)
        );
        setSearchParams(searchParams);
      }
    } else {
      if (searchParams.get(searchValue) === value) {
        searchParams.delete(searchValue);
        setSearchParams(searchParams);
      } else {
        searchParams.set(searchValue, `${value}`);
        setSearchParams(searchParams);
      }
    }
  };

  const removeItemFromSearchParams = (it: string) => () => {
    selectedItems = selectedItems.filter((item: string) => item !== it);
    if (!selectedItems.length) {
      searchParams.delete(searchValue);
    } else {
      searchParams.set(
        searchValue,
        convertStringsArrayIntoQuery(selectedItems)
      );
    }
    setSearchParams(searchParams);
  };

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      onClick={(e) => e.stopPropagation()}
    >
      <Typography variant={"body10"} capitalizeFirstLetter fontWeight="bold">
        {t(column.title as string)}
      </Typography>
      <Popover position="bottom" status={status} setStatus={setStatus}>
        <Button
          variant={searchParams.get(searchValue) ? "success" : "primary"}
          fixedSize
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Filter fill="white" />
        </Button>
        <PopoverBody
          display={"flex"}
          flexDirection="column"
          width={250}
          maxWidth={250}
          maxHeight={300}
          minHeight={300}
          flexShrink={0}
        >
          <PopoverHeader flexShrink={0} minWidth={200}>
            <Typography fontWeight="bold">{t("Type filter")}</Typography>
            <Button
              variant="danger"
              fontWeight={"bold"}
              withBorder={false}
              link
              $size="small"
              disabled={!searchParams.get(searchValue)}
              onClick={handleReset}
            >
              {t("reset")}
            </Button>
          </PopoverHeader>
          <Flex
            flexWrap="wrap"
            style={{ gap: "10px 10px" }}
            maxWidth={200}
            flexDirection="row"
            my={2}
            height={"auto"}
            maxHeight={80}
            flexShrink={0}
            overflow="auto"
          >
            {convertSearchParamIntoArray(
              searchParams.get(searchValue),
              " OR "
            ).map((item: string, index) => (
              <Badge position="relative" key={index} flexShrink={0} p={2}>
                {item}
                <Box
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  clickable
                  onClick={removeItemFromSearchParams(item)}
                  px={1}
                >
                  <Close fill="#122967" />
                </Box>
              </Badge>
            ))}
          </Flex>

          {setSearchInputValue && (
            <Flex
              maxWidth="100%"
              flexDirection="row"
              my={1}
              borderBottom="1px solid #eee"
            >
              <Input
                $size="small"
                withBorder={false}
                value={searchInputValue}
                onChange={(e: any) => setSearchInputValue(e.target.value)}
                placeholder="Search"
              />
              <Button
                onClick={() => setSearchInputValue("")}
                variant="secondary"
                link
                fixedSize
              >
                <Close size="4" />
              </Button>
            </Flex>
          )}
          <ul
            style={{
              listStyle: "none",
              paddingTop: 5,
              maxHeight: 500,
              overflow: "scroll",
              height: "100%",
            }}
          >
            {options?.length > 0 ? (
              options?.map((option: string, index: number) => {
                return (
                  <li key={index}>
                    <Flex py={2}>
                      <Checkbox
                        $size="small"
                        value={undefined}
                        onChange={onChangeList(option)}
                        checked={selectedItems.includes(option)}
                      >
                        {option}
                      </Checkbox>
                    </Flex>
                  </li>
                );
              })
            ) : loading ? (
              <Center>
                <SimpleLoader />
              </Center>
            ) : (
              <Flex
                style={{ placeItems: "center" }}
                justifyContent={"center"}
                alignItems={"center"}
                fullSize
              >
                <Typography variant={"caption20"}>No Data Found</Typography>
              </Flex>
            )}
          </ul>
        </PopoverBody>
      </Popover>
    </Flex>
  );
};
