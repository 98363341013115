import { gql, useMutation } from "@apollo/client";


const ADD_SITE_SELECTION_LOCATION = gql`
  mutation addSiteSelectionLocation($input: AddSiteSelectionLocationInput!) {
    addSiteSelectionLocation(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const useAddSiteSelectionLocation = () => {
  const [addSiteSelectionLocation, { loading, data, error }] =
    useMutation(ADD_SITE_SELECTION_LOCATION);

  return { addSiteSelectionLocation, error, data, loading };
};


const UPDATE_SITE_SELECTION_LOCATION = gql`
  mutation updateSiteSelectionLocation($input: UpdateSiteSelectionLocationInput!) {
    updateSiteSelectionLocation(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const useUpdateSiteSelectionLocation = () => {
  const [updateSiteSelectionLocation, { loading, data, error }] =
    useMutation(UPDATE_SITE_SELECTION_LOCATION);


  return { updateSiteSelectionLocation, error, data, loading };
};


const DELETE_SITE_SELECTION = gql`
  mutation deleteSiteSelectionLocation($input: DeleteSiteSelectionLocationInput!) {
    deleteSiteSelectionLocation(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const useDeleteSiteSelectionLocation = () => {
const [deleteSiteSelectionLocation, { loading, data, error }] =
    useMutation(DELETE_SITE_SELECTION);


  return { deleteSiteSelectionLocation, error, data, loading };
};

