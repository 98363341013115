import { Session } from "@bepower/greta-types";
import { format } from "date-fns";
import { useOutletContext } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  LoaderWithFallback,
  SimpleLoader,
  Typography,
} from "../../../../shared/UI";
import { Refresh } from "../../../../shared/assets/svg";
import { t } from "i18next";

export const SessionOverview = () => {
  const {
    session,
    loading,
    refetch,
  }: { session: Session; loading: boolean; refetch: any } = useOutletContext();
  // const chargeAct = session?.Activities.results.find(
  //   (act: Activity) => act.type === "CHARGE"
  // );
  const refetchSession = () => {
    refetch();
  };
  return (
    <LoaderWithFallback
      loading={loading}
      LoadingMsg={<SimpleLoader />}
      errorMsg={"error"}
      hasError={!session}
    >
      <Flex alignItems={"flex-end"} width={"100%"} p={2}>
        <Button fixedSize onClick={refetchSession} title={t('download')|| ""}>
          {" "}
          <Refresh />
        </Button>
      </Flex>
      <Flex fullSize>
        <Flex
          flexWrap={"wrap"}
          flexDirection={"row"}
          p={3}
          style={{ gap: "20px 20px" }}
        >
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}> Started</Typography>
            <Typography>
              {session?.startedAt
                ? format(session?.startedAt, "dd/MM/yyyy HH:mm:ss")
                : "---"}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}>Ended</Typography>
            <Typography>
              {session?.endedAt
                ? format(session?.endedAt, "dd/MM/yyyy HH:mm:ss")
                : "---"}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}>Token</Typography>
            <Typography>{session?.Token?.label}</Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}> Status</Typography>
            <Typography
              color={session?.status === "COMPLETED" ? "green" : "blue"}
            >
              {session?.status}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}>EVC</Typography>
            <Typography>{session?.status}</Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}>EMSP</Typography>
            <Typography>{session?.Token?.PlatformParty?.name}</Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}>{t("type")}</Typography>
            <Typography>
              {session?.activitiesData?.[0]?.authorizationMethod}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}>{t("kWh delivered")}</Typography>
            <Typography>
              {session?.activitiesData?.[0]?.consumptions?.energy}
            </Typography>
          </Card>
          <Card
            bg="white"
            p={3}
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            minWidth={400}
          >
            <Typography variant={"title20"}>EVSE</Typography>
            <Typography>{session?.activitiesData?.[0].EVSE?.label}</Typography>
          </Card>
        </Flex>
      </Flex>
    </LoaderWithFallback>
  );
};
