import { gql, useMutation } from "@apollo/client";

const TOGGLE_FAVORITE = gql`
  mutation toggleFavoriteSiteSelectionProject($uid: String!) {
    toggleFavoriteSiteSelectionProject(uid: $uid) {
      info
      result
      statusCode
      uid
    }
  } 
`;

export const useToggleSiteSelectionProjectFavorite = () => {
  const [toggleSiteSelectionProjectFavorite, { loading, data, error }] =
    useMutation(TOGGLE_FAVORITE);


  return { toggleSiteSelectionProjectFavorite, error, data, loading };
};



