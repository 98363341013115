import { SectionLayout } from "../../../../shared/layout";
import { SectionContentTop } from '../../../../shared/layout/SectionContentTop';
import PlatformContent from "./PlatformContent";
import { PlatformDetailsForm } from "./PlatformDetailsForm";

export const PlatformScreen = () => {

  return (
    <SectionLayout label="PLATFORM" >
      <SectionContentTop fullHeight>
        <PlatformContent place={"platform"} />
      </SectionContentTop>
    </SectionLayout>

  );
};
