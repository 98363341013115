import { memo } from "react";
import { useTranslation } from "react-i18next";
import AC from "../../../../shared/assets/svg/AC";
import Backlog from "../../../../shared/assets/svg/Backlog";
import DC from "../../../../shared/assets/svg/DC";
import Eye from "../../../../shared/assets/svg/Eye";
import Hexagon from "../../../../shared/assets/svg/Hexagon";
import MarkerLocationBasic from "../../../../shared/assets/svg/MarkerLocationBasic";
import MarkerOperationPrivate from "../../../../shared/assets/svg/MarkerOperationPrivate";
import MarkerOperationPublic from "../../../../shared/assets/svg/MarkerOperationPublic";
import PreBacklog from "../../../../shared/assets/svg/PreBacklog";
import StandBy from "../../../../shared/assets/svg/StandBy";
import { Flex } from "../../../../shared/UI";

const colors = [
  { bgColor: "#095D8B", leg: "siteSelection.general.legend.highDemand" },
  { bgColor: "#008FDD", leg: "" },
  { bgColor: "#48BBFA", leg: "" },
  { bgColor: "#BCDFF3", leg: "" },
  { bgColor: "#FFFFFF", leg: "siteSelection.general.legend.lowDemand" },
];

const Marker = ({ icon, text }: { icon: any; text: string }) => (
  <Flex flexDirection="row" justifyContent={"center"} alignItems={"center"}>
    <div style={{ marginRight: "4px" }}>{icon}</div>
    {text}
  </Flex>
);

const LegendComponent = () => {
  const { t } = useTranslation();

  return (
    <Flex minWidth="180px" height="100%" paddingTop="20px" paddingBottom="20px">
      {colors.map((color, i) => (
        <Flex flexDirection="row">
          {/* START HEXAGON */}
          <Flex
            display="flex"
            flexDirection="column"
            alignItems="start"
            width={40}
            margin={0}
            padding={0}
          >
            <Hexagon
              flatTop={true}
              key={i}
              style={{
                fill: color.bgColor,
                stroke: "#122967",
                strokeWidth: 5,
              }}
            />
          </Flex>
          <Flex
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
            alignItems="start"
            margin={0}
            padding={0}
            paddingRight={0}
            paddingLeft={10}
            fontSize={"11px"}
            color={"#000"}
          >
            <div>{t(color.leg)}</div>
          </Flex>
        </Flex>
      ))}
      <Flex flexDirection={"row"}>
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="start"
          width={40}
          margin={0}
          padding={0}
          marginTop={24}
          justifyContent={"center"}
        >
          <Hexagon
            flatTop={true}
            style={{
              stroke: "#7E725F",
              strokeWidth: 70,
            }}
          />
        </Flex>
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent={"center"}
          margin={0}
          marginTop={24}
          padding={10}
          paddingRight={0}
          fontSize={"11px"}
          color={"#000"}
        >
          <p style={{ textTransform: "uppercase" }}>
            {t("siteSelection.general.legend.cef1")} +{" "}
            {t("siteSelection.general.legend.cef2")}
          </p>
        </Flex>
      </Flex>

      <Flex flexDirection={"row"}>
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="start"
          width={40}
          margin={0}
          padding={0}
          justifyContent={"center"}
        >
          <Hexagon
            flatTop={true}
            style={{
              stroke: "#FA9500",
              strokeWidth: 70,
            }}
          />
        </Flex>
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="start"
          margin={0}
          padding={10}
          paddingRight={0}
          fontSize={"11px"}
          color={"#000"}
          justifyContent={"center"}
        >
          <p style={{ textTransform: "uppercase" }}>
            {t("siteSelection.general.legend.cef1")}
          </p>
        </Flex>
      </Flex>

      {/* END HEXAGON */}

      <Flex flexGrow={1} />

      {/* START MARKERS */}

      <Flex
        display="flex"
        flexDirection="column"
        alignItems="start"
        margin={0}
        padding={0}
        fontSize={"11px"}
        color={"#000"}
      >
        <Marker
          icon={<MarkerOperationPublic strokeWidth={0} />}
          text={t("siteSelection.general.legend.operationPublic")}
        />

        <Marker
          icon={<MarkerOperationPrivate strokeWidth={0} />}
          text={t("siteSelection.general.legend.operationPrivate")}
        />
        <Marker
          icon={<MarkerLocationBasic strokeWidth={0} />}
          text={t("siteSelection.general.legend.competitor")}
        />

        <Marker
          icon={
            <MarkerLocationBasic
              fill="#FFFFFF"
              stroke="#122967"
              strokeWidth={0}
            />
          }
          text={t("siteSelection.general.legend.scouting")}
        />

        <Marker
          icon={
            <MarkerLocationBasic
              fill="#FFC700"
              stroke="#FFC700"
              strokeWidth={0}
            />
          }
          text={t("siteSelection.general.legend.workInProgress")}
        />
      </Flex>

      <Flex
        display="flex"
        flexDirection="column"
        alignItems="start"
        alignContent={"center"}
        justifyContent={"center"}
        margin={0}
        padding={0}
        marginTop={30}
        fontSize={"11px"}
        color={"#000"}
      >
        <Marker
          icon={<AC strokeWidth={0} />}
          text={t("siteSelection.general.legend.ac")}
        />

        <Marker
          icon={<DC strokeWidth={0} />}
          text={t("siteSelection.general.legend.dc")}
        />
        <Marker
          icon={<PreBacklog strokeWidth={0} />}
          text={t("siteSelection.general.legend.preBacklog")}
        />

        <Marker
          icon={<Backlog strokeWidth={0} />}
          text={t("siteSelection.general.legend.backlog")}
        />

        <Marker
          icon={<StandBy strokeWidth={0} />}
          text={t("siteSelection.general.legend.standBy")}
        />

        <Marker
          icon={<Eye strokeWidth={0} />}
          text={t("siteSelection.general.legend.byOther")}
        />
      </Flex>

      {/* END MARKERS */}
    </Flex>
  );
};

export const Legend = memo(LegendComponent, () => true);
