const colors0 = [
  [255, 255, 255, 150],
  [188, 223, 243, 150],
  [72, 187, 250, 150],
  [0, 143, 221, 150],
  [9, 93, 139, 150],
];

const colors1 = [
  [255, 255, 255, 150],
  [175, 227, 255, 150],
  [100, 200, 255, 150],
  [0, 143, 221, 150],
  [9, 93, 139, 150],
];

const colors2 = [
  [255, 255, 255, 150],
  [215, 234, 213, 150],
  [141, 202, 196, 150],
  [92, 161, 198, 150],
  [9, 93, 139, 150],
];
const colors3 = [
  [255, 255, 255, 150],
  [255, 228, 116, 150],
  [227, 169, 33, 150],
  [0, 143, 221, 150],
  [9, 90, 135, 150],
];

const colors = colors2;

// #64c8ff in rgb format is rgb(100, 200, 255)

export const calculateColor = (
  value: number,
  min: number,
  max: number,
  interpolate = false
): [number, number, number, number] => {
  if (min === max) return colors[0] as [number, number, number, number];

  const frac = ((value - min) / (max - min)) * colors.length;

  if (interpolate) {
    const floor = Math.min(Math.floor(frac), colors.length - 1);
    const color = colors[floor].map(
      (c, i) =>
        c * (frac - floor) +
        colors[Math.min(floor + 1, colors.length - 1)][i] * (1 - (frac - floor))
    );
    return color as [number, number, number, number];
  }
  
  const round = Math.min(Math.round(frac), colors.length - 1);
  return colors[round] as [number, number, number, number];
};

export const colorToRgb = ([r, g, b]: [number, number, number, number]) =>
  `rgb(${r},${g},${b})`;
