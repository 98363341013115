






import React from "react";
import Svg from "../../UI/Svg";
type Props = {
   size?: string;
   fill?: string;
};
const Status: React.FC<Props> = ({ fill, size, ...props }) => {
   return (

      <Svg
         fill='none'
         iconSize={size}
         {...props} viewBox="0 0 151 76" xmlns="http://www.w3.org/2000/svg">
         <path d="M35 47.1045L24.5 36.7612L21 40.209L35 54L65 24.4478L61.5 21L35 47.1045Z" fill="#30A3FF" />
         <path d="M130 24.3236L126.676 21L113.5 34.1764L100.324 21L97 24.3236L110.176 37.5L97 50.6764L100.324 54L113.5 40.8236L126.676 54L130 50.6764L116.824 37.5L130 24.3236Z" fill="#30A3FF" />
         <line x1="83" y1="11" x2="83" y2="65" stroke="#30A3FF" strokeWidth="4" />
         <rect x="1.5" y="1.5" width="148" height="73" rx="8.5" stroke="#30A3FF" strokeWidth="3" />
      </Svg>



   );
};

export default Status;