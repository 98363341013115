import { gql, useLazyQuery } from "@apollo/client";

export const START_CHARGE_PROCESSED = gql`
  query StartChargeTxProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    StartChargeTxProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        status
        transactionUid
        timestamp
        request {
          authorizationReference
          evseUid
          locationUid
          requestUid
        }
      }
    }
  }
`;
export const useStartChargeTxProcessed = () => {
  const [
    getStartChargeTxProcessed,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(START_CHARGE_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getStartChargeTxProcessed,
    error,
    startChargeTxProcessed: data?.StartChargeTxProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
