/** @format */

import { Roles } from "@bepower/greta-types";
import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Sdrs from "../../pages/Sdrs/screens/Sdrs";
import { EvcAudit } from "../../pages/infrastracture/EVCs/components/tabs/EvcAudit";
import { EvcCommands } from "../../pages/infrastracture/EVCs/components/tabs/EvcCommands";
import { EvcConfigurations } from "../../pages/infrastracture/EVCs/components/tabs/EvcConfigurations";
import EvcDetails from "../../pages/infrastracture/EVCs/components/tabs/EvcDetails";
import { EvcEvses } from "../../pages/infrastracture/EVCs/components/tabs/EvcEVSEs";
import { EvcSessions } from "../../pages/infrastracture/EVCs/components/tabs/EvcSessions";
import Simulator from "../../pages/infrastracture/EVCs/components/tabs/EvcSimulator";
import { EvseSessions } from "../../pages/infrastracture/Evses/components/tabs/EVSESessions";
import { EvseAccessGroups } from "../../pages/infrastracture/Evses/components/tabs/EvseAccessGroups";
import { EvseCommands } from "../../pages/infrastracture/Evses/components/tabs/EvseCommands";
import { EvseDetails } from "../../pages/infrastracture/Evses/components/tabs/EvseDetails";
import { GroupEvses } from "../../pages/infrastracture/access-group/components/GroupEvses";
import { GroupInfo } from "../../pages/infrastracture/access-group/components/GroupInfo";
import { AccessGroupModel } from "../../pages/infrastracture/access-group/screens/AccessGroupModel";
import LocationDetails from "../../pages/infrastracture/locations/components/locationDetail/LocationDetails";
import LocationEvcs from "../../pages/infrastracture/locations/components/locationDetail/LocationEvcs";
import LocationSessions from "../../pages/infrastracture/locations/components/locationDetail/LocationSessions";
import { Location } from "../../pages/infrastracture/locations/screens/Location";
import { SessionActivities } from "../../pages/infrastracture/sessions/components/SessionActivities";
import { SessionOverview } from "../../pages/infrastracture/sessions/components/SessionOverview";
import { SessionSdr } from "../../pages/infrastracture/sessions/components/SessionSdr";
import { SessionTriggers } from "../../pages/infrastracture/sessions/components/SessionTriggers";
import Session from "../../pages/infrastracture/sessions/screens/Session";
import { PlatformScreen } from "../../pages/platforms/components/platform/PlatformScreen";
import TariffModel from "../../pages/rates/components/TariffModel";
import SiteSelection from "../../pages/site-selection/locations/screens/SiteSelection";
import RenderOnRole from "../UI/RenderOnRole";
import { SuspensedView } from "../helpers/SuspensedView";
import MasterLayout from "../layout/MasterLayout";
import ErrorsRoutes from "./ErrorsRoutes";

type Props = {
  config: any;
};
const CSO = lazy(() => import("../../pages/cso/screens/CSOs"));
const Rates = lazy(() => import("../../pages/rates/screens/Tariffs"));
const Profile = lazy(() => import("../../pages/profile/screens/Profile"));
const Preferences = lazy(
  () => import("../../pages/settings/Preferences/Screens/Preferences")
);

const Platforms = lazy(() => import("../../pages/platforms/screens/Platforms"));
const PartyScreen = lazy(
  () =>
    import(
      "../../pages/platforms/components/platform/parties/party/PartyScreen"
    )
);
const PlatformShowScreen = lazy(
  () => import("../../pages/platforms/components/platform/PlatformShowScreen")
);
const PlatformParties = lazy(
  () =>
    import(
      "../../pages/platforms/components/platform/parties/PlatformPartiesScreen"
    )
);
const PlatformModel = lazy(
  () => import("../../pages/platforms/components/platform/PlatformModel")
);
const PlatformPartyModel = lazy(
  () =>
    import(
      "../../pages/platforms/components/platform/parties/party/PlatformPartyModel"
    )
);

const CsoScreen = lazy(() => import("../../pages/cso/components/CsoScreen"));
const Interoperabilities = lazy(
  () => import("../../pages/interoperability/screens/Interoperabilities")
);
const Interoperability = lazy(
  () => import("../../pages/interoperability/screens/Interoperability")
);

const TariffEditScreen = lazy(
  () => import("../../pages/rates/components/TariffEditScreen")
);
const TariffCloneScreen = lazy(
  () => import("../../pages/rates/components/TariffCloneScreen")
);
const TariffShowScreen = lazy(
  () => import("../../pages/rates/components/TariffShowScreen")
);
const TariffFind = lazy(
  () => import("../../pages/rates/components/tariffFind/TariffFind")
);
const Locations = lazy(
  () => import("../../pages/infrastracture/locations/screens/Locations")
);
const EVC = lazy(() => import("../../pages/infrastracture/EVCs/screens/EVC"));

const Sessions = lazy(
  () => import("../../pages/infrastracture/sessions/screens/Sessions")
);

const AccessGroups = lazy(
  () => import("../../pages/infrastracture/access-group/screens/AccessGroups")
);
const AccessGroup = lazy(
  () => import("../../pages/infrastracture/access-group/screens/AccessGroup")
);
const Evses = lazy(
  () => import("../../pages/infrastracture/Evses/screens/Evses")
);
const Evse = lazy(
  () => import("../../pages/infrastracture/Evses/screens/Evse")
);
const GroupExternalEMSP = lazy(
  () =>
    import(
      "../../pages/infrastracture/access-group/components/GroupExternalEMSP"
    )
);

const GroupParty = lazy(
  () => import("../../pages/infrastracture/access-group/components/GroupParty")
);
const Activities = lazy(
  () => import("../../pages/activites/screens/Activities")
);

const EVCs = lazy(() => import("../../pages/infrastracture/EVCs/screens/EVCs"));
const PrivateRoutes: React.FC<Props> = ({ config }) => {
  const { local } = config;

  return (
    <Routes>
      <Route element={<MasterLayout local={local} />}>
        <Route path="/*" element={<Navigate to="/error/404" />} />
        <Route
          path="profile"
          element={
            <SuspensedView>
              <Profile />
            </SuspensedView>
          }
        />
        <Route
          path="preferences"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Platform]} page>
                <Preferences />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="platforms"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Platform]} page>
                <Platforms />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="interoperability"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PlatformInterop]} page>
                <Interoperabilities />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="interoperability/connection/create"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PlatformInterop]} page>
                <Interoperability />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="interoperability/:uid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PlatformInterop]} page>
                <Interoperability />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="platforms/:uid"
          element={
            <RenderOnRole roles={[Roles.Platform]} page>
              <PlatformShowScreen />
            </RenderOnRole>
          }
        />
        <Route
          path="platforms/:uid/edit"
          element={
            <RenderOnRole roles={[Roles.Platform, Roles.PlatformWrite]} page>
              <PlatformScreen />
            </RenderOnRole>
          }
        />
        <Route
          path="platforms/:platformUid/parties"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Platform]} page>
                <PlatformParties />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="platforms/:uid/parties/create"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PlatformWrite]} page>
                <PlatformPartyModel />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="parties/:partyUid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Platform]} page>
                <PartyScreen />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="platforms/:uid/parties/:partyUid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Platform]} page>
                <PartyScreen />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="platforms/create"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PlatformWrite]} page>
                <PlatformModel />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="sales"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.HostGroup]} page>
                <CSO isLocale={config?.local} />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="sales/:csoUid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.HostGroup]} page>
                <CsoScreen />
              </RenderOnRole>
            </SuspensedView>
          }
        />

        <Route
          path="tariffs"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Prices]} page>
                <Rates />
              </RenderOnRole>
            </SuspensedView>
          }
        />

        <Route
          path="tariffs/create"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PricesWrite]} page>
                <TariffModel />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="tariffs/find"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PricesWrite]} page>
                <TariffFind />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="tariffs/:priceUid/edit"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PricesWrite]} page>
                <TariffEditScreen />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="tariffs/:tariffUid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Prices]} page>
                <TariffShowScreen />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="tariffs/:priceUid/clone"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.PricesWrite]} page>
                <TariffCloneScreen />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        {/* greta p2 routes*/}

        {/* location nested routes */}

        <Route
          path="locations"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <Locations />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="locations/:uid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <Location />
              </RenderOnRole>
            </SuspensedView>
          }
        >
          <Route
            path="location-details"
            index
            element={
              <SuspensedView>
                <RenderOnRole roles={[Roles.Infrastructure]} page>
                  <LocationDetails />
                </RenderOnRole>
              </SuspensedView>
            }
          />
          <Route
            path="location-evc-evse"
            element={
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <LocationEvcs />
              </RenderOnRole>
            }
          />
          <Route
            path="location-sessions"
            element={
              <SuspensedView>
                <RenderOnRole roles={[Roles.Infrastructure]} page>
                  <LocationSessions />
                </RenderOnRole>
              </SuspensedView>
            }
          />
          <Route
            index
            element={<Navigate to="location-details" replace={true} />}
          />
        </Route>

        <Route
          path="evcs"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <EVCs />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="evcs/:uid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <EVC />
              </RenderOnRole>
            </SuspensedView>
          }
        >
          <Route
            path="evc-details"
            index
            element={
              <SuspensedView>
                <RenderOnRole roles={[Roles.Infrastructure]} page>
                  <EvcDetails />
                </RenderOnRole>
              </SuspensedView>
            }
          />
          <Route
            path="evc-simulator"
            index
            element={
              <RenderOnRole
                roles={[Roles.Infrastructure, Roles.Simulator]}
                page
              >
                <Simulator />
              </RenderOnRole>
            }
          />
          <Route
            path="evc-sessions"
            index
            element={
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <EvcSessions />
              </RenderOnRole>
            }
          />
          <Route
            path="evc-commands"
            index
            element={
              <RenderOnRole roles={[Roles.Infrastructure, Roles.Commands]} page>
                <EvcCommands />
              </RenderOnRole>
            }
          />
          <Route
            path="evc-audit"
            index
            element={
              <RenderOnRole roles={[Roles.Infrastructure, Roles.Audit]} page>
                <EvcAudit />
              </RenderOnRole>
            }
          />
          <Route
            path="evc-configurations"
            index
            element={
              <RenderOnRole
                roles={[Roles.Infrastructure, Roles.Configurations]}
                page
              >
                <EvcConfigurations />
              </RenderOnRole>
            }
          />
          <Route
            path="evc-evses"
            index
            element={
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <EvcEvses />
              </RenderOnRole>
            }
          />
          <Route index element={<Navigate to="evc-details" replace={true} />} />
        </Route>
        <Route
          path="evses"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <Evses />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="evses/:uid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <Evse />
              </RenderOnRole>
            </SuspensedView>
          }
        >
          <Route
            path="evse-details"
            index
            element={
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <EvseDetails />
              </RenderOnRole>
            }
          />
          <Route
            path="evse-sessions"
            index
            element={
              <RenderOnRole roles={[Roles.Infrastructure]} page>
                <EvseSessions />
              </RenderOnRole>
            }
          />
          <Route
            path="evse-commands"
            index
            element={
              <RenderOnRole roles={[Roles.Infrastructure, Roles.Commands]} page>
                <EvseCommands />
              </RenderOnRole>
            }
          />

          <Route
            path="evse-accessGroups"
            index
            element={
              <RenderOnRole
                roles={[Roles.Infrastructure, Roles.AccessGroupAssociation]}
                page
              >
                <EvseAccessGroups />
              </RenderOnRole>
            }
          />
          <Route
            index
            element={<Navigate to="evse-details" replace={true} />}
          />
        </Route>
        <Route
          path="access-groups"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.AccessGroupAssociation]} page>
                <AccessGroups />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="access-groups/create"
          element={
            <RenderOnRole roles={[Roles.AccessGroupAssociation]} page>
              <AccessGroupModel />
            </RenderOnRole>
          }
        />
        <Route
          path="access-groups/:uid"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.AccessGroupAssociation]} page>
                <AccessGroup />
              </RenderOnRole>
            </SuspensedView>
          }
        >
          <Route
            path="group-info"
            index
            element={
              <SuspensedView>
                <RenderOnRole roles={[Roles.AccessGroupAssociation]} page>
                  <GroupInfo />
                </RenderOnRole>
              </SuspensedView>
            }
          />
          <Route
            path="group-evses"
            element={
              <RenderOnRole roles={[Roles.AccessGroupAssociation]} page>
                <GroupEvses />
              </RenderOnRole>
            }
          />
          <Route
            path="group-party"
            element={
              <RenderOnRole roles={[Roles.AccessGroupAssociation]} page>
                <GroupParty />
              </RenderOnRole>
            }
          />
          <Route
            path="group-external-emsp"
            element={
              <RenderOnRole roles={[Roles.AccessGroupAssociation]} page>
                <GroupExternalEMSP />
              </RenderOnRole>
            }
          />
          <Route index element={<Navigate to="group-info" replace={true} />} />
        </Route>
        <Route
          path="sessions"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Session]} page>
                <Sessions />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="sessions/:uid"
          element={
            <RenderOnRole roles={[Roles.Session]} page>
              <Session />
            </RenderOnRole>
          }
        >
          <Route
            path="session-overview"
            element={
              <RenderOnRole roles={[Roles.Session]} page>
                <SessionOverview />
              </RenderOnRole>
            }
          />
          <Route
            path="session-triggers"
            element={
              <RenderOnRole roles={[Roles.Session]} page>
                <SessionTriggers />
              </RenderOnRole>
            }
          />
          <Route
            path="session-activities"
            element={
              <RenderOnRole roles={[Roles.Session]} page>
                <SessionActivities />
              </RenderOnRole>
            }
          />
          <Route
            path="session-sdr"
            element={
              <RenderOnRole roles={[Roles.Session]} page>
                <SessionSdr />
              </RenderOnRole>
            }
          />
          <Route
            index
            element={<Navigate to="session-overview" replace={true} />}
          />
        </Route>

        <Route
          // site-selection has sub-routes defined in the SiteSelection component
          path="site-selection/*"
          element={
            <RenderOnRole roles={[Roles.SiteSelection]} page>
              <SiteSelection />
            </RenderOnRole>
          }
        />

        <Route
          path="activities"
          element={
            <SuspensedView>
              <RenderOnRole roles={[Roles.Session]} page>
                <Activities />
              </RenderOnRole>
            </SuspensedView>
          }
        />
        <Route
          path="sdrs"
          element={
            <RenderOnRole roles={[Roles.Session]} page>
              <Sdrs />
            </RenderOnRole>
          }
        />
        <Route path="/error/404" element={<ErrorsRoutes />} />

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};
export default PrivateRoutes;
