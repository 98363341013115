import { gql, useMutation } from "@apollo/client";
import { EVSEACCESSGROUPS } from "../queries/useGetEVSEAccessGroups";

export const PUBLISH_EVSE_ACCESS_GROUP = gql`
  mutation publishEvseAccessGroupAssociation(
    $input: EvseAccessGroupAssociationInput!
  ) {
    publishEvseAccessGroupAssociation(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const usePublishEvseAccessGroupAssociation = () => {
  const [publishEvseAccessGroupAssociation, { data, loading, error }] =
    useMutation<any>(PUBLISH_EVSE_ACCESS_GROUP, {
      // refetchQueries: [EVSEACCESSGROUPS],
    });
  return {
    publishEvseAccessGroupAssociation,
    error,
    data,
    loading,
  };
};
