import { useOutletContext } from "react-router-dom";
import { Badge, Box, Card, Flex, Typography } from "../../../../shared/UI";
import { Activity, Session } from "@bepower/greta-types";
import { format } from "date-fns";
import { getStatusColor } from "./../../../../shared/helpers/colorHelper";
import { t } from "i18next";

export const SessionActivities = () => {
  const { session }: { session: Session } = useOutletContext();

  return (
    <Flex fullSize p={3}>
      {session?.activitiesData?.map((activity: any, index: number) => {
        return (
          <Card bg="#f5f5f5" mb={4} key={index}>
            <Box>
              <Badge>{activity?.type}</Badge>
            </Box>
            <Flex
              flexWrap={"wrap"}
              flexDirection={"row"}
              p={3}
              style={{ gap: "20px 20px" }}
            >
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t('started')}</Typography>
                <Typography p={2}>
                  {activity?.startedAt
                    ? format(activity?.startedAt, "dd/MM/yyyy hh:mm:ss aa")
                    : "---"}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}> {t('actual end')}</Typography>
                <Typography pt={2}>
                  {activity?.endedAt
                    ? format(activity?.endedAt, "dd/MM/yyyy hh:mm:ss aa ")
                    : "---"}
                </Typography>
              </Card>

              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>{t('expected end')} </Typography>
                <Typography pt={2}>
                  {activity?.expectedEnd
                    ? format(activity?.expectedEnd, "dd/MM/yyyy hh:mm:ss aa ")
                    : "---"}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}> Completed</Typography>
                <Typography
                  variant={"title20"}
                  color={getStatusColor(
                    String(activity?.completed ? "COMPLETED" : "ACTIVE")
                  )}
                  pt={2}
                >
                  {activity?.completed ? "Completed" : "Not Completed"}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>
                  {t("status activity")}
                </Typography>
                <Typography
                  variant={"title20"}
                  pt={2}
                  color={getStatusColor(String(activity?.status))}
                >
                  {activity?.status}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}>
                  {t("kWh delivered")}
                </Typography>
                <Typography variant={"title20"} pt={2}>
                  {activity?.consumptions?.energy}
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}> EVC</Typography>
                <Typography pt={2}>
                  {" "}
                  <Badge>{activity?.EVSE?.EVC?.label}</Badge>
                </Typography>
              </Card>
              <Card
                bg="white"
                p={3}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                minWidth={400}
              >
                <Typography variant={"title20"}> EVSE</Typography>
                <Typography pt={2}>
                  <Badge>{activity?.EVSE?.label}</Badge>
                </Typography>
              </Card>
            </Flex>
          </Card>
        );
      })}
    </Flex>
  );
};
