import React from "react";
import { useState, useRef } from "react";
import { components, InputAction, StylesConfig } from "react-select";
import { Select as ReactSelect } from "../UI";

export type Option = {
  value: number | string;
  label: string;
};

const MultiSelect = React.forwardRef<any, any>((props, ref) => {
  const [selectInput, setSelectInput] = useState<string>("");
  const isAllSelected = useRef<boolean>(false);
  const selectAllLabel = useRef<string>("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options: Option[], input: string) =>
    options?.filter(({ label }: Option) =>
      label.toLowerCase().includes(input.toLowerCase())
    ) || [];

  const comparator = (v1: Option, v2: Option) =>
    (v1.value as number) - (v2.value as number);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props: any) => (
    <components.Option {...props}>
      {props.value === "*" &&
      !isAllSelected.current &&
      filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          style={{ cursor: "pointer" }}
          checked={props.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{props.label} </label>
    </components.Option>
  );

  const Input = (props: any) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }: Option, input: string) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (
    inputValue: string,
    event: { action: InputAction }
  ) => {
    if (props?.handleChangeInput) {
      props?.handleChangeInput(inputValue);
    }
    if (event.action === "input-change") {
      setSelectInput(inputValue);
    } else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected: Option[], actionMeta: any) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props?.onChange(
        [...props?.options]
          ?.filter(({ label }: Option) =>
            label?.toLowerCase()?.includes(selectInput?.toLowerCase())
          )
          ?.sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }: Option) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles: StylesConfig = {
    multiValueLabel: (def: any) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def: any) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base: any) => ({
      ...base,
      maxHeight: "55px",
      overflow: "auto",
    }),

    option: (styles: any, { isSelected, isFocused }: any) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def: any) => ({ ...def, zIndex: 9999 }),
  };

  isAllSelected.current =
    JSON.stringify(filteredSelectedOptions) === JSON.stringify(filteredOptions);

  if (filteredSelectedOptions?.length > 0) {
    if (filteredSelectedOptions?.length === filteredOptions?.length)
      selectAllLabel.current = `All (${filteredOptions.length}) selected`;
    else
      selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
  } else selectAllLabel.current = "Select all";

  allOption.label = selectAllLabel.current;

  return (
    <ReactSelect
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      options={[allOption, ...props.options]}
      onChange={handleChange}
      components={{
        Option: Option,
        Input: Input,
        ...props.components,
      }}
      filterOption={customFilterOption}
      menuPlacement={props.menuPlacement ?? "auto"}
      styles={customStyles}
      ref={ref}
      isMulti
      closeMenuOnSelect={false}
      tabSelectsValue={true}
      backspaceRemovesValue={false}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
    />
  );
});

export default MultiSelect;
