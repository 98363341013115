import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { SdrsList } from "../components/SdrsList";

import { useDispatch, useSelector } from "react-redux";
import { setShowMainList } from "../../../shared/slices/layoutItems";
import { SectionLayout } from "../../../shared/layout";
import { Flex } from "../../../shared/UI";

const Sdrs: React.FC = () => {
  const dispatch = useDispatch();
  const { uid: sessionUid } = useParams();
  const { showMainList } = useSelector((state: any) => state.layout);

  useEffect(() => {
    if (!sessionUid && !showMainList) {
      dispatch(setShowMainList(true));
    }
  }, [dispatch, sessionUid, showMainList]);

  return (
    <SectionLayout label="EVC">
      <Flex flex={1} width="100%" minWidth={[400, 400, 400, 400, 400, 500]}>
        <SdrsList width="100%" />
      </Flex>
    </SectionLayout>
  );
};

export default Sdrs;
