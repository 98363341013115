/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated";

export const GET_Session = gql(`
  query Session($uid: String!) {
    Session(uid: $uid) {
      __typename
        Location {
              address
            }
      activitiesData {
        __typename
        EVSE {
          __typename
          label
          EVC {
            __typename
            assetStatus
            branding
            capacity
            code
            configurationGroupUid
            connectionType
            createdAt
            customerService
            description
            enstoDlmMaster
            firmwareVersion
            floorLevel
            iccid
            imsi
            installation
            installedAt
            ip
            label
            locationUid
          
            meterSerialNumber
            meterType
            model
            owner
            podUid
            powerType
            protocol
            safetyPerson
            safetyPhone
            serialNumber
            simSupplier
            status
            statusUpdatedAt
            uid
            updatedAt
            v1gEnabled
            vendor
          }
          assetStatus
          emi3Id
          evcUid
          number
          status
          statusUpdatedAt
          uid
          updatedAt
        }
        actualEnd
        authorizationMethod
        authorizationReference
        completed
        consumptions {
          __typename
          energy
          parkingTime
          time
        }
        costs {
          __typename
          energy
          parkingTime
          time
        }
        createdAt
        endedAt
        evcUid
        evseUid
        expectedEnd
        startedAt
        status
        totalCost
        transactionUid
        type
        uid
        updatedAt
      }
      activities {
        __typename
        uid
        type
      }
      Token {
        __typename
        PlatformParty {
          countryCode
          image {
            category
            height
            thumbnail
            type
            url
            width
          }
          name
          partyId
          phoneNumber
          role
          uid
          website
          updatedAt
        }
        authId
        emspPartyUid
        expiresAt
        group
        label
        language
        uid
        updatedAt
        valid
        value
        whitelist
        origin
      }
      updatedAt
      uid
      tokenUid
      tariffUid
      startedAt
      status
      origin
      locationUid
      endedAt
    }
  }
`);
export const useGetSession = () => {
  const [
    getSession,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(GET_Session, {
    initialFetchPolicy: "network-only",
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getSession,
    error,
    session: data?.Session,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
