/** @format */

import { useOutletContext } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  LoaderWithFallback,
  SimpleLoader,
  StyledLink,
  Typography,
} from "../../../../../shared/UI";
import { LocationMap } from "../../../locations/components/locationDetail/map/LocationMap";
import Refresh from "../../../../../shared/assets/svg/Refresh";
import { TriggerStatusNotification } from "./commands/TriggerStatusNotification";
import RenderOnRole from "../../../../../shared/UI/RenderOnRole";
import { Roles } from "@bepower/greta-types";
import { t } from "i18next";
import React from "react";
import { format } from "date-fns";
import { usePingEVC } from "../../../../../shared/gql/mutations/usePingEVC";
import { toast } from "react-toastify";
import { useRestartEVC } from "../../../../../shared/gql/mutations/useRestartEVC";

const EvcDetails: React.FC = () => {
  const { evc, loading, refetch }: any = useOutletContext();
  const { data, error, executePingEVC, loading: loadingPingEvc } = usePingEVC();
  const { executeRestartEVC, loading: loadingRestartEvc } = useRestartEVC();

  const refetchLocation = () => {
    refetch();
  };
  const restartEvc = () => {
    executeRestartEVC({
      variables: {
        input: {
          masterIp: `http://${evc?.ip}:82/settings`,
          slaveIp: `http://${evc?.ip}:81/settings`,
        },
      },
    }).then((res) => {
      const pingResponse = res.data?.restartEvc;
      if (pingResponse?.status === "success" && !pingResponse?.error) {
        toast.success(`la ${evc.label} EVC È stato riavviato con successo!`);
      } else {
        toast.error(pingResponse?.error);
      }
    });
  };
  const pingEvc = () => {
    executePingEVC({
      variables: {
        ip: `http://${evc?.ip}`,
        // ip: `https://jsonplaceholder.typicode.com/todos/1`,
      },
    }).then((res) => {
      const pingResponse = res.data?.pingEvc;
      if (pingResponse?.status === "success" && !pingResponse?.error) {
        toast.success(`la ${evc.label} EVC è raggiungibile!`);
      } else {
        toast.error(pingResponse?.error);
      }
    });
  };
  return (
    <>
      <Flex alignItems={"flex-end"} width={"100%"} p={2}>
        <Button fixedSize onClick={refetchLocation}>
          {" "}
          <Refresh />
        </Button>
      </Flex>
      <Flex
        style={{ gap: "15px 15px" }}
        p={3}
        flexDirection={"row"}
        flexShrink={0}
        flexWrap={"wrap"}
      >
        <Card
          bg="white"
          p={2}
          justifyContent={"center"}
          flex={1}
          minWidth={300}
          flexWrap={"wrap"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("location")}
          </Typography>

          <Flex flex={1} minWidth={150} mb={2} pl={2}>
            <Flex flexWrap="wrap">
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ gap: 10 }}
              >
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography
                    display="block"
                    capitalizeFirstLetter
                    fontWeight="bold"
                  >
                    {t("label")}:
                  </Typography>
                  <Typography>{evc?.label}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("address")}:
                  </Typography>
                  <Typography>{evc?.Location?.address}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("city")}:
                  </Typography>
                  <Typography>{evc?.Location?.city}</Typography>
                </Flex>

                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("country")}:
                  </Typography>
                  <Typography>{evc?.Location?.country}</Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card
          bg="white"
          p={2}
          justifyContent={"center"}
          flex={1}
          minWidth={300}
          flexWrap={"wrap"}
          flexDirection={"column"}
          display={"flex"}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("connection")}
          </Typography>
          <Flex flex={1} minWidth={150} mb={2} pl={2}>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ gap: 10 }}
            >
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  {t("Status")}:
                </Typography>
                <Typography>{evc?.status}</Typography>
                {evc?.statusUpdatedAt && (
                  <Typography variant={"caption10"}>
                    {format(evc?.statusUpdatedAt, "dd/MM/yyyy HH:mm:ss")}
                  </Typography>
                )}
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  Firmware {t("status")}:
                </Typography>
                <Typography>{evc?.statusFirmware}</Typography>
                {evc?.firmwareTimestamp && (
                  <Typography variant={"caption10"}>
                    {format(evc?.firmwareTimestamp, "dd/MM/yyyy HH:mm:ss")}
                  </Typography>
                )}
              </Flex>

              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  {t("connection type")}:
                </Typography>
                <Typography>{evc?.connectionType}</Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  IP:
                </Typography>
                <StyledLink target="_blank" to={`http://${evc.ip}`}>
                  {evc?.ip}
                </StyledLink>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  iccid:
                </Typography>
                <Typography>{evc?.iccid}</Typography>
              </Flex>
              <Flex flex={1} minWidth={150} mb={2}>
                <Typography capitalizeFirstLetter fontWeight="bold">
                  simSupplier:
                </Typography>
                <Typography>{evc?.simSupplier}</Typography>
              </Flex>
            </Flex>
            <Flex
              flexDirection={"row"}
              flexWrap={"wrap"}
              style={{ gap: "10px 10px" }}
              justifyContent={"center"}
              width={"100%"}
              pr={2}
            >
              {evc?.vendor === "ENSTO" && (
                <RenderOnRole roles={[Roles.CommandsAdvanced]}>
                  <Flex flex={1} minWidth={"150px"}>
                    <Button
                      $size="default"
                      onClick={pingEvc}
                      loading$={loadingPingEvc}
                      disabled={loadingPingEvc || !evc?.ip}
                    >
                      Ping
                    </Button>
                  </Flex>
                  <Flex flex={1} minWidth={"150px"}>
                    <Button
                      $size="default"
                      onClick={restartEvc}
                      loading$={loadingRestartEvc}
                      disabled={loadingRestartEvc || !evc?.ip}
                    >
                      {t("force restart")}
                    </Button>
                  </Flex>
                </RenderOnRole>
              )}
              <RenderOnRole roles={[Roles.Commands]}>
                <Flex flex={2} minWidth={"200px"}>
                  <TriggerStatusNotification btnLabel="Trigger Status" />
                </Flex>
              </RenderOnRole>
            </Flex>
          </Flex>
        </Card>

        <Card
          bg="white"
          p={2}
          justifyContent={"center"}
          flex={1}
          minWidth={300}
          flexWrap={"wrap"}
          display={"flex"}
          flexDirection={"column"}
          maxWidth={"100%"}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("installation")}
          </Typography>

          <Flex flex={1} minWidth={150} mb={2} pl={2}>
            <Flex flexWrap="wrap">
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ gap: 10 }}
              >
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    Asset Status:
                  </Typography>
                  <Typography>{evc?.assetStatus}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("installation")}:
                  </Typography>
                  <Typography>{evc?.installation}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("installation date")}:
                  </Typography>
                  <Typography>
                    {evc?.installedAt
                      ? format(evc?.installedAt, "dd/MM/yyyy HH:mm")
                      : null}
                  </Typography>
                </Flex>
                <Flex flex={1} minWidth={300} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    Evc Uid:
                  </Typography>
                  <Typography>{evc?.uid}</Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card
          bg="white"
          p={2}
          justifyContent={"center"}
          flex={1}
          minWidth={300}
          flexWrap={"wrap"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            p={[3, 4, 4, 4, 3, 4]}
            textAlign="center"
            fontSize={[20, 20, 20, 13, 15, 27]}
            upperCase
            variant={"title20"}
          >
            {t("model")}
          </Typography>

          <Flex flex={1} minWidth={150} mb={2} pl={2}>
            <Flex flexWrap="wrap">
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ gap: 10 }}
              >
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("serial number")} :
                  </Typography>
                  <Typography>{evc?.serialNumber}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("capacity")} (kW):
                  </Typography>
                  <Typography>{evc?.capacity}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("type")} :
                  </Typography>
                  <Typography>{evc?.powerType}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    Firmware {t("version")}:
                  </Typography>
                  <Typography>{evc?.firmwareVersion}</Typography>
                </Flex>
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("model")}:
                  </Typography>
                  <Typography>{evc?.model}</Typography>
                </Flex>{" "}
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("vendor")}:
                  </Typography>
                  <Typography>{evc?.vendor}</Typography>
                </Flex>{" "}
                <Flex flex={1} minWidth={150} mb={2}>
                  <Typography capitalizeFirstLetter fontWeight="bold">
                    {t("owner")}:
                  </Typography>
                  <Typography>{evc?.owner}</Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>

      <Card
        bg="white"
        p={2}
        justifyContent={"center"}
        flex={1}
        minWidth={500}
        display={"flex"}
        flexDirection={"column"}
        flexShrink={0}
        mx={3}
        mb={2}
      >
        <Flex
          fullSize
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          style={{ gap: 15 }}
          minWidth={350}
        >
          <Flex flex={1} mt={1}>
            <Typography display="block" capitalizeFirstLetter fontWeight="bold">
              {t("address")}:
            </Typography>
            <Typography>{evc?.Location?.address}</Typography>
          </Flex>

          <Flex flex={1} mt={1}>
            <Typography display="block" capitalizeFirstLetter fontWeight="bold">
              {t("city")}:
            </Typography>
            <Typography>{evc?.Location?.city}</Typography>
          </Flex>

          <Flex flex={1} mt={1}>
            <Typography display="block" capitalizeFirstLetter fontWeight="bold">
              {t("province")}:
            </Typography>
            <Typography>{evc?.Location?.province}</Typography>
          </Flex>

          <Flex flex={1} mt={1}>
            <Typography display="block" capitalizeFirstLetter fontWeight="bold">
              {t("country")}:
            </Typography>
            <Typography>{evc?.Location?.country}</Typography>
          </Flex>
          <Flex flex={1} mt={1}>
            <Typography display="block" capitalizeFirstLetter fontWeight="bold">
              {"Postal Code"}:
            </Typography>
            <Typography>{evc?.Location?.postalCode}</Typography>
          </Flex>
          <Flex flex={1} mt={1}>
            <Typography display="block" capitalizeFirstLetter fontWeight="bold">
              {t("latitude")}:
            </Typography>
            <Typography>{evc?.geolocation?.latitude}</Typography>
          </Flex>
          <Flex flex={1} mt={1}>
            <Typography display="block" capitalizeFirstLetter fontWeight="bold">
              {t("longitude")}:
            </Typography>
            <Typography>{evc?.geolocation?.longitude}</Typography>
          </Flex>
        </Flex>

        <Flex minHeight={300} flexShrink={0} position="relative">
          <LocationMap
            location={{
              geolocation: evc?.geolocation,
              label: evc?.geolocation?.name,
            }}
          />
        </Flex>
      </Card>
    </>
  );
};

export default EvcDetails;
