import { Marker, OverlayView } from "@react-google-maps/api";
import { useState } from "react";
import MarkerWip from "../../../../../shared/assets/svg/MarkerWip";
import { useProject } from "../../../hooks/useProject";
import { useSiteSelectionContext } from "../../../slices/ContextProvider";
import { buildSvgString } from "../../../utils/buildSVG";
import { EditLocationTooltip } from "../map-components/NewLocationTooltip";

const getWipMarker = (cpTot: number, evcModel: "AC" | "DC") => (
  <MarkerWip fontFamily="sans-serif" cpTot={cpTot} evcModel={evcModel} />
);

export const CurrentProjectLocationLayer = ({
  projectId,
}: {
  projectId?: string;
}) => {
  const [infoWindowData, setInfoWindowData] = useState<{
    type: "location" | "marker" | "poi";
    data: any;
  }>();

  const [mapRef] = useState<google.maps.Map | undefined>();

  const { project, updateLocation, deleteLocation } = useProject(projectId);

  const {
    state: { selectedHexagon, selectedCity },
  } = useSiteSelectionContext();

  return (
    <>
      {project?.locations.map((location: any, index: number) => {
        const cpTot = location.ac + location.dc;
        const evcModel = location.dc > 0 ? "DC" : "AC";
        const markerComponent = getWipMarker(cpTot, evcModel);
        const svgString = buildSvgString(markerComponent);
        return (
          <Marker
            key={index}
            icon={{
              url: svgString,
              scaledSize: new google.maps.Size(45, 45),
            }}
            position={{
              lat: location.latitude,
              lng: location.longitude,
            }}
            onClick={() => {
              setInfoWindowData({
                type: "location",
                data: { id: index },
              });
            }}
            clickable={!!selectedCity}
            zIndex={100}
          >
            {infoWindowData &&
              infoWindowData.type === "location" &&
              infoWindowData.data.id === index && (
                <OverlayView
                  position={{
                    lat: location.latitude,
                    lng: location.longitude,
                  }}
                  zIndex={3}
                  mapPaneName="overlayMouseTarget"
                  getPixelPositionOffset={() => ({
                    x: -228,
                    y: -328,
                  })}
                  onLoad={(o) => {
                    mapRef?.setCenter(mapRef?.getCenter()!);
                  }}
                  key={`location-${index}`}
                >
                  <EditLocationTooltip
                    evcForecast={{
                      ac: Math.round((selectedHexagon?.ac ?? 0) / 10000),
                      dc: Math.round((selectedHexagon?.dc ?? 0) / 50000),
                    }}
                    onClose={() => {
                      setInfoWindowData(undefined);
                    }}
                    onEdit={(location) => {
                      updateLocation(location.location.id, {
                        ac: location.data.ac,
                        dc: location.data.dc,
                        address: location.data.address,
                        contractualCapacity: location.data.contractualCapacity,
                        validated: location.data.validated,
                      });
                      setInfoWindowData(undefined);
                    }}
                    onDelete={() => {
                      deleteLocation(location.id);
                      setInfoWindowData(undefined);
                    }}
                    location={location}
                  />
                </OverlayView>
              )}
          </Marker>
        );
      })}
      ;
    </>
  );
};
