import { gql, useMutation } from "@apollo/client";
import { GET_TARIFFS_ASSOC } from "./useGetTariffAssoc";
import { GET_TARIFFS_ASSOCS } from "./useGetTariffAssocs";
import { TARIFF_FIND } from "./useGetTariffFind";
import { GET_TARIFFS } from "./useGetTariffs";
import { GET_PLATFORM } from './../../platforms/gql/queries/useGetPlatform';
import { GET_PLATFORMS } from "../../platforms/gql";

const PUBLISH_TARIFF_ASSOC = gql`
mutation publishTariffAssoc($input:TariffAssocInput!){
   publishTariffAssoc(input:$input){ 
      info 
      result 
      statusCode
      uid
   }
}
`




export const usePublishTariffAssoc = () => {

   const [publishTariffAssoc, { data, loading, error, }] = useMutation<any>(
      PUBLISH_TARIFF_ASSOC,
      {
         // refetchQueries: [GET_TARIFFS_ASSOC, TARIFF_FIND, GET_PLATFORM, GET_PLATFORMS]
      }


   );
   return { publishTariffAssoc, error, data, loadingPublishTariffAssoc: loading };

}