import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  Flex,
  Input,
  Typography
} from "../../../../../shared/UI";
import { DialogBodyElement } from "../../../../../shared/UI/Dialog/StyledElements";
import { useProject } from "../../../hooks/useProject";

export const RenameProjectDialog = ({
  isOpen,
  setIsOpen,
  projectId,
  onConfirm
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: SetStateAction<boolean>) => void;
  onConfirm?: () => void;
  projectId?: string;
}) => {
  const { t } = useTranslation();
  const { project, renameProject } = useProject(projectId);

  const [name, setName] = useState(project?.name ?? "");

  useEffect(() => {
    setName(project?.name ?? "");
  }, [project?.name]);

  return (
    <Dialog width="500px" height="250px" isOpen={isOpen} setIsOpen={setIsOpen}>
      <DialogBodyElement>
        <Typography
          variant="title30"
          color="#008FDD"
          fontWeight={700}
          fontSize={30}
          upperCase={true}
          textAlign={"center"}
          marginTop={10}
          marginBottom={10}
        >
          {t("siteSelection.openProject.renameProjectDialog.title")}
        </Typography>

        <Input
          placeholder={
            t("siteSelection.openProject.renameProjectDialog.placeholder") ?? ""
          }
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <Flex
          flexDirection={"row"}
          justifyContent="space-around"
          marginTop={38}
        >
          <Flex flexGrow={1} />
          <Button
            variant="secondary"
            height={48}
            width={150}
            borderRadius={12}
            fontSize={16}
            style={{ textTransform: "none" }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t("siteSelection.openProject.renameProjectDialog.cancel")}
          </Button>
          <Button
            variant="primary"
            height={48}
            width={150}
            borderRadius={12}
            fontSize={16}
            marginLeft={"5px"}
            style={{ textTransform: "none" }}
            disabled={
              (name?.trim() ?? "") === "" ||
              name.trim() === project?.name.trim()
            }
            onClick={() => {
              projectId && renameProject(name);
              setIsOpen(false);
              onConfirm?.();

            }}
          >
            {t("siteSelection.openProject.renameProjectDialog.confirm")}
          </Button>
        </Flex>
      </DialogBodyElement>
    </Dialog>
  );
};
