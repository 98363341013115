import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconParking: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      width="26"
      height="36"
      viewBox="0 0 26 36"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M0.529297 35.1051V0.657227H13.9257C17.1153 0.657227 19.8265 1.77359 22.0592 4.00633C24.292 6.23906 25.4083 8.95024 25.4083 12.1399C25.4083 15.3295 24.292 18.0407 22.0592 20.2734C19.8265 22.5061 17.1153 23.6225 13.9257 23.6225H8.18439V35.1051H0.529297ZM8.18439 15.9674H14.3085C15.361 15.9674 16.2621 15.5926 17.0117 14.8431C17.7612 14.0935 18.136 13.1924 18.136 12.1399C18.136 11.0873 17.7612 10.1862 17.0117 9.43666C16.2621 8.6871 15.361 8.31232 14.3085 8.31232H8.18439V15.9674Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
