import { t } from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Card, Flex, Input, Typography } from "../../../../shared/UI";
import { Alarm, Info } from "../../../../shared/assets/svg";
import { StyledTable, TRow } from "../TariffPriceContent";
import { CurrencyInput } from "react-currency-mask";

type Props = {
  canEdit: boolean;
};

export const DefaultPrice: React.FC<Props> = ({ canEdit }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <Card p={3}>
      <Flex flexDirection="row" mb={2} alignItems="center">
        <Typography
          display="flex"
          alignItems="center"
          tooltipPlace="top"
          tooltipText={
            t(
              "this price will be applied if the location country price not specified"
            ) as string
          }
          mr={2}
        >
          <Info fill="#3D729C" size="5" />
        </Typography>
        <Typography upperCase variant="title20">
          {t("price")}
        </Typography>
      </Flex>

      <StyledTable>
        <thead>
          <TRow style={{ height: 50 }}>
            <th style={{ minWidth: 200 }}>
              <Typography fontWeight="bold">Service Type</Typography>
            </th>
            <th>
              <Typography fontWeight="bold">€/kWh</Typography>
            </th>
            <th>
              <Typography fontWeight="bold">€/min</Typography>
            </th>
            <th>
              <Flex
                justifyContent="center"
                flexDirection="row"
                alignItems="center"
              >
                <Typography
                  pr={2}
                  tooltipText={
                    t(
                      "on quick charge EVCs (up to 22kew) the penalty between 11pm and 7am will not be applied"
                    ) as string
                  }
                >
                  <Alarm fill="#3D729C" />
                </Typography>
                <Typography fontWeight="bold">Penalty €/min</Typography>
              </Flex>
            </th>
            <th>
              <Typography fontWeight="bold">Fisso€</Typography>
            </th>
          </TRow>
        </thead>
        <tbody>
          <TRow>
            <td style={{ paddingLeft: 10 }}>
              <Typography fontWeight="bold">AC</Typography>
            </td>
            {[`AC_energy`, `AC_time`, `AC_parkingTime`, `AC_flat`].map(
              (item: string) => (
                <td key={item}>
                  <Controller
                    name={item}
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <CurrencyInput
                        hideSymbol
                        max={10}
                        value={value}
                        onChangeValue={(_: any, value: any) => {
                          onChange(value);
                        }}
                        {...rest}
                        InputElement={
                          <PriceInput
                            disabled={!canEdit}
                            shape="square"
                            error={
                              String(t(errors?.[item]?.message as string)) || ""
                            }
                            placeholder="0.00"
                            {...rest}
                          />
                        }
                      />
                    )}
                  />
                </td>
              )
            )}
          </TRow>
          <TRow>
            <td style={{ paddingLeft: 10 }}>
              <Typography upperCase fontWeight="bold">
                {"DC < 100kW"}
              </Typography>
            </td>

            {[
              `DC < 100kW_energy`,
              `DC < 100kW_time`,
              `DC < 100kW_parkingTime`,
              `DC < 100kW_flat`,
            ].map((item: string) => (
              <td key={item}>
                <Controller
                  name={item}
                  control={control}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <CurrencyInput
                      hideSymbol
                      max={10}
                      value={value}
                      onChangeValue={(_: any, value: any) => {
                        onChange(value);
                      }}
                      {...rest}
                      InputElement={
                        <PriceInput
                          disabled={!canEdit}
                          shape="square"
                          error={
                            String(t(errors?.[item]?.message as string)) || ""
                          }
                          placeholder="0.00"
                          {...rest}
                        />
                      }
                    />
                  )}
                />
              </td>
            ))}
          </TRow>
          <TRow>
            <td style={{ paddingLeft: 10 }}>
              <Typography upperCase fontWeight="bold">
                {"100kW ≤ DC < 150kW"}
              </Typography>
            </td>

            {[
              `100kW ≤ DC < 150kW_energy`,
              `100kW ≤ DC < 150kW_time`,
              `100kW ≤ DC < 150kW_parkingTime`,
              `100kW ≤ DC < 150kW_flat`,
            ].map((item: string) => (
              <td key={item}>
                <Controller
                  name={item}
                  control={control}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <CurrencyInput
                      hideSymbol
                      max={10}
                      value={value}
                      onChangeValue={(_: any, value: any) => {
                        onChange(value);
                      }}
                      {...rest}
                      InputElement={
                        <PriceInput
                          disabled={!canEdit}
                          shape="square"
                          error={
                            String(t(errors?.[item]?.message as string)) || ""
                          }
                          placeholder="0.00"
                          {...rest}
                        />
                      }
                    />
                  )}
                />
              </td>
            ))}
          </TRow>
          <TRow>
            <td style={{ paddingLeft: 10 }}>
              <Typography upperCase fontWeight="bold">
                {"DC ≥ 150kW"}
              </Typography>
            </td>

            {[
              `DC ≥ 150kW_energy`,
              `DC ≥ 150kW_time`,
              `DC ≥ 150kW_parkingTime`,
              `DC ≥ 150kW_flat`,
            ].map((item: string) => (
              <td key={item}>
                <Controller
                  name={item}
                  control={control}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <CurrencyInput
                      hideSymbol
                      max={10}
                      value={value}
                      onChangeValue={(_: any, value: any) => {
                        onChange(value);
                      }}
                      {...rest}
                      InputElement={
                        <PriceInput
                          disabled={!canEdit}
                          shape="square"
                          error={
                            String(t(errors?.[item]?.message as string)) || ""
                          }
                          placeholder="0.00"
                          {...rest}
                        />
                      }
                    />
                  )}
                />
              </td>
            ))}
          </TRow>
        </tbody>
      </StyledTable>
    </Card>
  );
};

const PriceInput = styled(Input)`
  color: ${({ theme }) => theme?.colors?.primary};
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0;
  padding-right: 0;
  max-width: 100%;
`;
