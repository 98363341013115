import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconSport: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M18.8164 36.2754C16.3467 36.2754 14.0257 35.8067 11.8535 34.8694C9.68135 33.9321 7.79186 32.66 6.18504 31.0532C4.57823 29.4464 3.30616 27.5569 2.36885 25.3847C1.43155 23.2126 0.962891 20.8916 0.962891 18.4219C0.962891 15.9521 1.43155 13.6312 2.36885 11.459C3.30616 9.28682 4.57823 7.39733 6.18504 5.79051C7.79186 4.18369 9.68135 2.91163 11.8535 1.97432C14.0257 1.03701 16.3467 0.568359 18.8164 0.568359C21.2861 0.568359 23.6071 1.03701 25.7793 1.97432C27.9514 2.91163 29.8409 4.18369 31.4478 5.79051C33.0546 7.39733 34.3266 9.28682 35.2639 11.459C36.2012 13.6312 36.6699 15.9521 36.6699 18.4219C36.6699 20.8916 36.2012 23.2126 35.2639 25.3847C34.3266 27.5569 33.0546 29.4464 31.4478 31.0532C29.8409 32.66 27.9514 33.9321 25.7793 34.8694C23.6071 35.8067 21.2861 36.2754 18.8164 36.2754ZM27.7431 13.9585L30.1534 13.1551L30.8675 10.7449C29.9153 9.31658 28.7697 8.08915 27.4307 7.06257C26.0917 6.036 24.6188 5.26978 23.012 4.76393L20.6017 6.46002V8.95951L27.7431 13.9585ZM9.88964 13.9585L17.031 8.95951V6.46002L14.6208 4.76393C13.014 5.26978 11.5411 6.036 10.2021 7.06257C8.86307 8.08915 7.71747 9.31658 6.76528 10.7449L7.47942 13.1551L9.88964 13.9585ZM8.01503 27.7057L10.0682 27.5272L11.4072 25.1169L8.81843 17.3507L6.31894 16.458L4.53359 17.797C4.53359 19.7311 4.80139 21.4942 5.337 23.0861C5.8726 24.678 6.76528 26.2179 8.01503 27.7057ZM18.8164 32.7047C19.59 32.7047 20.3488 32.6452 21.0927 32.5261C21.8366 32.4071 22.5656 32.2286 23.2798 31.9905L24.5295 29.3125L23.369 27.3486H14.2638L13.1033 29.3125L14.353 31.9905C15.0672 32.2286 15.7962 32.4071 16.5401 32.5261C17.284 32.6452 18.0427 32.7047 18.8164 32.7047ZM14.7994 23.7779H22.8334L25.3329 16.6365L18.8164 12.0839L12.3891 16.6365L14.7994 23.7779ZM29.6178 27.7057C30.8675 26.2179 31.7602 24.678 32.2958 23.0861C32.8314 21.4942 33.0992 19.7311 33.0992 17.797L31.3139 16.5472L28.8144 17.3507L26.2256 25.1169L27.5646 27.5272L29.6178 27.7057Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
