import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Flex } from "../UI";
import BG_IMG from "../assets/bg-effect.png";
import { DefaultProps } from "../UI/helpers";

interface Props extends DefaultProps {
  fullHeight?: boolean;
}
const SectionContentTopElement = styled(Flex) <Props>`
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  position: relative;
  overflow: auto;
  background-color: white;
  background-position: left-top;
  background-image: url(${BG_IMG});
  transition: all 0.5s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

`;

export const SectionContentTop: React.FC<PropsWithChildren<Props>> = ({
  fullHeight,
  children,
  ...props
}) => {
  return (
    <SectionContentTopElement
      height={fullHeight ? "100%" : "calc(60px)"}
      maxHeight={fullHeight ? "100%" : "calc(60px)"}
      fullHeight={fullHeight}
      {...props}
    >
      {children}
    </SectionContentTopElement>
  );
};
