/** @format */

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Error from "../../pages/errors/screen/Error";
import { SuspensedView } from "../helpers/SuspensedView";

const Error404 = React.lazy(
  () => import("../../pages/errors/components/Error404")
);
const Error500 = React.lazy(
  () => import("../../pages/errors/components/Error500")
);

const ErrorsRoutes = () => {
  return (
    <Routes>
      <Route element={<Error />}>
        <Route
          path="404"
          element={
            <SuspensedView>
              <Error404 />
            </SuspensedView>
          }
        />
        <Route
          path="500"
          element={
            <SuspensedView>
              <Error500 />
            </SuspensedView>
          }
        />
        <Route
          index
          element={
            <SuspensedView>
              <Error404 />
            </SuspensedView>
          }
        />
        <Route path="/*" element={<Navigate to="/404" />} />
      </Route>
    </Routes>
  );
};

export default ErrorsRoutes;
