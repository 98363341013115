import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconFoodAndRestaurant: React.FC<Props> = ({
  fill,
  size,
  ...props
}) => {
  return (
    <Svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M1.6112 35.0309C1.35284 35.0309 1.14472 34.952 0.986829 34.7941C0.828943 34.6362 0.75 34.4281 0.75 34.1697V31.5861H24.8635V34.1697C24.8635 34.4281 24.7846 34.6362 24.6267 34.7941C24.4688 34.952 24.2607 35.0309 24.0023 35.0309H1.6112ZM0.75 28.1413V25.5577C0.75 25.2994 0.828943 25.0913 0.986829 24.9334C1.14472 24.7755 1.35284 24.6965 1.6112 24.6965H9.36198V21.2517H16.2516V24.6965H24.0023C24.2607 24.6965 24.4688 24.7755 24.6267 24.9334C24.7846 25.0913 24.8635 25.2994 24.8635 25.5577V28.1413H0.75ZM28.3083 35.0309V24.5243C27.2749 23.3473 26.4424 22.1847 25.8108 21.0364C25.1793 19.8882 24.8635 18.4959 24.8635 16.8596V0.583008H35.1979V16.8596C35.1979 18.4959 34.8893 19.8954 34.2721 21.058C33.6549 22.2206 32.8153 23.376 31.7531 24.5243V31.5861H35.1979V35.0309H28.3083ZM28.3083 10.9174H31.7531V4.0278H28.3083V10.9174ZM30.0307 21.3379C30.5474 20.735 30.9637 20.0461 31.2795 19.271C31.5952 18.4959 31.7531 17.6921 31.7531 16.8596V14.3622H28.3083V16.8596C28.3083 17.6921 28.4519 18.4959 28.7389 19.271C29.026 20.0461 29.4566 20.735 30.0307 21.3379Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
