import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};
export const PoiIconEVC: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={fill}
      iconSize={size}
      {...props}
    >
      <path
        d="M8.82877 30.8977L13.6132 23.2426H10.7425V17.5013L5.95811 25.1564H8.82877V30.8977ZM4.04434 13.6737H15.527V4.10489H4.04434V13.6737ZM4.04434 30.8977H15.527V17.5013H4.04434V30.8977ZM0.216797 34.7252V4.10489C0.216797 3.05231 0.591577 2.15125 1.34114 1.40168C2.0907 0.652124 2.99177 0.277344 4.04434 0.277344H15.527C16.5795 0.277344 17.4806 0.652124 18.2302 1.40168C18.9797 2.15125 19.3545 3.05231 19.3545 4.10489V17.5013H21.7467C22.6717 17.5013 23.4612 17.8282 24.115 18.4821C24.7689 19.136 25.0958 19.9254 25.0958 20.8504V29.7016C25.0958 30.2438 25.3191 30.7382 25.7657 31.1848C26.2122 31.6313 26.7066 31.8546 27.2488 31.8546C27.823 31.8546 28.3253 31.6313 28.7559 31.1848C29.1865 30.7382 29.4018 30.2438 29.4018 29.7016V11.76H28.9234C28.3811 11.76 27.9266 11.5766 27.5598 11.2098C27.193 10.843 27.0096 10.3884 27.0096 9.84621V6.01866H27.9665V3.148H29.8803V6.01866H31.794V3.148H33.7078V6.01866H34.6647V9.84621C34.6647 10.3884 34.4813 10.843 34.1145 11.2098C33.7477 11.5766 33.2932 11.76 32.7509 11.76H32.2725V29.7016C32.2725 31.0412 31.7861 32.2134 30.8132 33.2182C29.8404 34.2229 28.6523 34.7252 27.2488 34.7252C25.8773 34.7252 24.6971 34.2229 23.7084 33.2182C22.7196 32.2134 22.2252 31.0412 22.2252 29.7016V20.8504C22.2252 20.6909 22.1853 20.5713 22.1056 20.4916C22.0258 20.4118 21.9062 20.372 21.7467 20.372H19.3545V34.7252H0.216797Z"
        fill={fill ?? "#3D729C"}
      />
    </Svg>
  );
};
