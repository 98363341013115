import { GroupedOption } from "../../shared/UI";

export enum TariffType {
  OCPI = "OCPI",
  GIREVE_O2A = "GIREVE_O2A",
  HUBJECT_O2A = "HUBJECT_O2A",
  GIREVE_BLT = "GIREVE_BLT",
  HUBJECT_BLT = "HUBJECT_BLT",
  DEFAULT = "DEFAULT",
}

export const TariffTypeOptions = [
  {
    label: "OCPI",
    value: "OCPI",
  },
  {
    label: "GIREVE_O2A",
    value: "GIREVE_O2A",
  },
  {
    label: "HUBJECT_O2A",
    value: "HUBJECT_O2A",
  },
  {
    label: "GIREVE_BLT",
    value: "GIREVE_BLT",
  },
  {
    label: "HUBJECT_BLT",
    value: "HUBJECT_BLT",
  },
  {
    label: "DEFAULT",
    value: "DEFAULT",
  },

  // new
];
const BusinessOptions = [
  {
    label: "Business Solution",
    value: "Business Solution",
  },
  {
    label: "CPO Conto Terzi",
    value: "CPO Conto Terzi",
  },
  {
    label: "Be Charge - Eccezioni",
    value: "Be Charge - Eccezioni",
  },
];
export const groupedTariffTypeOptions: readonly GroupedOption[] = [
  {
    label: "Platform",
    options: TariffTypeOptions,
  },
  {
    label: "Sales",
    options: BusinessOptions,
  },
];

export const CountryOptions = [
  {
    label: "IT",
    value: "IT",
  },
  {
    label: "ES",
    value: "ES",
  },
  {
    label: "DE",
    value: "DE",
  },
  {
    label: "FR",
    value: "FR",
  },
  {
    label: "CA",
    value: "CA",
  },
];
export const AllTariffTypeOptions: { label: string; value: string }[] = [
  ...TariffTypeOptions,
  ...BusinessOptions,
];
