import { gql, useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import { Location, Project } from "../@types/project";

const GET_SITE_SELECTION_PROJECTS = gql`
  query SiteSelectionProject($uid: String!) {
    SiteSelectionProject(uid: $uid) {
      accessType
      createdAt
      createdBy
      favoriteBy
      name
      status
      type
      uid
      updatedAt
      visibility
      locations {
        results {
          ac
          dc
          address
          city
          province
          region
          country
          createdAt
          createdBy
          uid
          updatedAt
          latitude
          longitude
          validated
          contractualCapacity
          zipCode
          forecast
          hex
        }
      }
    }
  }
`;

export const useGetSiteSelectionProject = () => {
  const [
    getSiteSelectionProject,
    { loading, error, data, refetch, startPolling },
  ] = useLazyQuery(GET_SITE_SELECTION_PROJECTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const project = useMemo(() => {
    if (data?.SiteSelectionProject) {
      return {
        ...data?.SiteSelectionProject,
        id: data?.SiteSelectionProject.uid,
        createdBy: data?.SiteSelectionProject.createdBy
          .split("@")[0]
          .replace(".", " "),
        locations: (data?.SiteSelectionProject.locations?.results ?? [])
          .map(
            (location: any) =>
              ({
                id: location.uid,
                ac: Math.ceil(location.ac),
                dc: Math.ceil(location.dc),
                address: location.address,
                city: location.city,
                country: location.country,
                hex: location.hex,
                province: location.province,
                region: location.region,
                createdAt: new Date(location.createdAt),
                createdBy: location.createdBy.split("@")[0].replace(".", " "),
                updatedAt: new Date(location.updatedAt),
                latitude: location.latitude,
                longitude: location.longitude,
                validated: location.validated,
                contractualCapacity: location.contractualCapacity,
                zipCode: location.zipCode,
                forecast: location.forecast,
              } as Location)
          )
          .sort(
            (a: Location, b: Location) => a.forecast === b.forecast ? a.id.localeCompare(b.id) : (b.forecast ?? 0) - (a.forecast ?? 0)
          ),
      } as Project;
    }
    return null;
  }, [data?.SiteSelectionProject]);
  return {
    loading,
    error,
    getSiteSelectionProject,
    siteSelectionProject: project,
    count: data?.SiteSelectionProjects?.total,
    refetch,
    startPolling,
  };
};
