/** @format */

import { gql } from "../generated/gql";

export const GET_EVCS = gql(`
  query EVCs(
    $filter: EvcFilterParams
    $pagination: PaginationParams
    $sort: EvcSortingParams
  ) {
    EVCs(filter: $filter, pagination: $pagination, sort: $sort) {
      __typename
      results {
        __typename
        assetStatus
        branding
        capacity
        code
        createdAt
        directions {
          language
          text
        }
        Location {
          uid
          label
          projectId
        }
        evse {
          __typename
          uid
        }
        evses {
          uid
        }
        simulator
        firmwareVersion
        floorLevel
        iccid
        installedAt
        ip
        label
        locationUid
        meterSerialNumber
        meterType
        model
        owner
        protocol
        serialNumber
        simSupplier
        status
        uid
        updatedAt
        statusUpdatedAt
        vendor
        statusDiagnostic
        diagnosticTimestamp
        statusFirmware
        firmwareTimestamp
      }
      total
      pagination {
        cursor
        last
      }
    }
  }
`);
