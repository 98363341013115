import { gql, useLazyQuery } from "@apollo/client";

export const STOP_CHARGE_PROCESSED = gql`
  query ChangeAvailabilityProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    ChangeAvailabilityProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        requestUid
        result
        timestamp
        status
        info
        request {
          evseUid
          requestUid
          type
        }
      }
    }
  }
`;
export const useChangeAvailabilityProcessed = () => {
  const [
    getChangeAvailabilityProcessed,
    {
      loading,
      error,
      data,
      refetch,
      updateQuery,
      networkStatus,
      startPolling,
      client,
    },
  ] = useLazyQuery(STOP_CHARGE_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });
  return {
    loading,
    client,
    getChangeAvailabilityProcessed,
    error,
    changeAvailabilityProcessed: data?.ChangeAvailabilityProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
