







import React from "react";
import Svg from "../../UI/Svg";
type Props = {
   size?: string;
   fill?: string;
};
const Protocol2: React.FC<Props> = ({ fill, size, ...props }) => {
   return (
      <Svg
         fill='none'
         iconSize={size}
         {...props} viewBox="0 0 52 76" xmlns="http://www.w3.org/2000/svg">
         <mask id="mask0_1702_5660" maskUnits="userSpaceOnUse" x="0" y="9" width="52" height="67">
            <path d="M0 9.142H51.3862V76H0V9.142Z" fill="white" />
            <path d="M34.8596 42.929C34.8596 47.7461 30.9546 51.6511 26.1375 51.6511C21.3203 51.6511 17.4153 47.7461 17.4153 42.929C17.4153 38.1119 21.3203 34.2068 26.1375 34.2068C30.9546 34.2068 34.8596 38.1119 34.8596 42.929Z" fill="white" />
            <path d="M0.432396 9.57439H50.9538V75.5676H0.432396V9.57439ZM34.4272 42.929C34.4272 47.5073 30.7158 51.2187 26.1375 51.2187C21.5592 51.2187 17.8477 47.5073 17.8477 42.929C17.8477 38.3507 21.5592 34.6392 26.1375 34.6392C30.7158 34.6392 34.4272 38.3507 34.4272 42.929ZM26.1375 52.0835C31.1934 52.0835 35.292 47.9849 35.292 42.929C35.292 37.8731 31.1934 33.7744 26.1375 33.7744C21.0815 33.7744 16.9829 37.8731 16.9829 42.929C16.9829 47.9849 21.0815 52.0835 26.1375 52.0835Z" stroke="white" strokeWidth="0.864792" />
         </mask>
         <g mask="url(#mask0_1702_5660)">
            <path fillRule="evenodd" clipRule="evenodd" d="M51.3863 72.9439V12.2273C51.3863 10.5301 50.0104 9.15439 48.313 9.15439H39.8926V13.3572H42.2928C45.141 13.3572 47.4493 15.6654 47.4493 18.5132V66.6557C47.4493 69.5035 45.141 71.8117 42.2928 71.8117V71.8071H9.09579V71.8117C6.24754 71.8117 3.93922 69.5035 3.93922 66.6557V18.5132C3.93922 15.6654 6.24754 13.3572 9.09579 13.3572H11.4959V9.15439H3.07785C1.38041 9.15439 0.00468573 10.5301 0.00468573 12.2273H0V72.9416H0.00468573C0.00468573 74.6389 1.38041 76.0147 3.07785 76.0147H48.313C50.0104 76.0169 51.3863 74.6412 51.3863 72.9439Z" fill="#269F8E" />
         </g>
         <path fillRule="evenodd" clipRule="evenodd" d="M40.7545 61.7409H23.9183C23.3441 61.7409 22.8778 61.2748 22.8778 60.7006C22.8778 60.1264 23.3441 59.6602 23.9183 59.6602H40.7545C41.3288 59.6602 41.795 60.1264 41.795 60.7006C41.795 61.2748 41.3288 61.7409 40.7545 61.7409ZM45.369 66.6581V18.5133C45.369 16.816 43.9931 15.4404 42.2958 15.4404H39.8955V17.0641C39.8955 17.6383 39.4292 18.1044 38.855 18.1044H12.5418C11.9676 18.1044 11.5013 17.6383 11.5013 17.0641V15.4404H9.09869C7.40125 15.4404 6.02539 16.8161 6.02539 18.5133V66.6557C6.02539 68.353 7.40125 69.7288 9.09869 69.7288V69.7333H42.2958V69.7288C43.9931 69.731 45.369 68.3553 45.369 66.6581ZM23.9183 56.4494C23.3441 56.4494 22.8778 55.9833 22.8778 55.4091C22.8778 54.8349 23.3441 54.3687 23.9183 54.3687H40.7545C41.3288 54.3687 41.795 54.8349 41.795 55.4091C41.795 55.9833 41.3288 56.4494 40.7545 56.4494H23.9183ZM23.9183 46.8149C23.3441 46.8149 22.8778 46.3488 22.8778 45.7745C22.8778 45.2005 23.3441 44.7342 23.9183 44.7342H40.7545C41.3288 44.7342 41.795 45.2005 41.795 45.7745C41.795 46.3488 41.3288 46.8149 40.7545 46.8149H23.9183ZM23.9183 41.5211C23.3441 41.5211 22.8778 41.055 22.8778 40.4808C22.8778 39.9067 23.3441 39.4404 23.9183 39.4404H40.7545C41.3288 39.4404 41.795 39.9067 41.795 40.4808C41.795 41.055 41.3288 41.5211 40.7545 41.5211H23.9183ZM23.9183 31.889C23.3441 31.889 22.8778 31.4227 22.8778 30.8487C22.8778 30.2745 23.3441 29.8083 23.9183 29.8083H40.7545C41.3288 29.8083 41.795 30.2745 41.795 30.8487C41.795 31.4227 41.3288 31.889 40.7545 31.889H23.9183ZM23.9183 26.5952C23.3441 26.5952 22.8778 26.1291 22.8778 25.5549C22.8778 24.9807 23.3441 24.5145 23.9183 24.5145H40.7545C41.3288 24.5145 41.795 24.9807 41.795 25.5549C41.795 26.1291 41.3288 26.5952 40.7545 26.5952H23.9183Z" fill="#269F8E" />
         <path d="M17.4408 24.412C17.5547 24.1477 17.4698 23.8392 17.2363 23.6694C17.0029 23.4997 16.6845 23.5151 16.4665 23.7041L11.527 28.0244C11.3341 28.1941 11.2646 28.466 11.3553 28.7052C11.446 28.9444 11.6775 29.1064 11.9341 29.1064H14.0855L12.6017 32.5664C12.4879 32.8306 12.5728 33.1392 12.8063 33.3089C13.0397 33.4787 13.3581 33.4632 13.5761 33.2742L18.5156 28.954C18.7085 28.7843 18.778 28.5123 18.6873 28.2732C18.5966 28.034 18.367 27.8739 18.1084 27.8739H15.9571L17.4408 24.412Z" fill="white" />
         <path d="M17.1473 24.2855L17.1471 24.2861L15.6633 27.748L15.4724 28.1935H15.9571H18.1084C18.2337 28.1935 18.3447 28.271 18.3884 28.3865L18.6873 28.2732L18.3884 28.3865C18.431 28.4988 18.3992 28.6305 18.3047 28.7138C18.3047 28.7139 18.3046 28.7139 18.3045 28.714L13.3668 33.0327C13.3666 33.0329 13.3665 33.033 13.3663 33.0331C13.2593 33.1255 13.1058 33.1316 12.9942 33.0504L12.8063 33.3089L12.9942 33.0504C12.8811 32.9683 12.8408 32.8194 12.8953 32.6929L12.8955 32.6924L14.3792 29.2323L14.5703 28.7868H14.0855H11.9341C11.8119 28.7868 11.6983 28.7084 11.6541 28.5919L11.3553 28.7052L11.6541 28.5919C11.6115 28.4795 11.6434 28.3479 11.7378 28.2646C11.7379 28.2645 11.738 28.2644 11.7381 28.2643L16.6758 23.9456C16.676 23.9455 16.6761 23.9454 16.6762 23.9453C16.7832 23.8528 16.9368 23.8468 17.0484 23.9279C17.1614 24.0101 17.2018 24.159 17.1473 24.2855ZM13.9485 29.426L12.6017 32.5664L13.7378 29.1064L13.7918 28.9804L14.0855 29.1064V29.426H13.9485Z" stroke="white" strokeWidth="0.639194" />
         <path d="M17.4408 39.2247C17.5547 38.9605 17.4698 38.6519 17.2363 38.4822C17.0029 38.3125 16.6845 38.3279 16.4665 38.5169L11.527 42.8371C11.3341 43.0069 11.2646 43.2788 11.3553 43.518C11.446 43.7571 11.6775 43.9191 11.9341 43.9191H14.0855L12.6017 47.3792C12.4879 47.6434 12.5728 47.952 12.8063 48.1217C13.0397 48.2914 13.3581 48.276 13.5761 48.087L18.5156 43.7668C18.7085 43.597 18.778 43.3251 18.6873 43.0859C18.5966 42.8468 18.367 42.6867 18.1084 42.6867H15.9571L17.4408 39.2247Z" fill="white" />
         <path d="M17.1473 39.0983L17.1471 39.0988L15.6633 42.5608L15.4724 43.0063H15.9571H18.1084C18.2337 43.0063 18.3447 43.0838 18.3884 43.1993L18.6873 43.0859L18.3884 43.1993C18.431 43.3116 18.3992 43.4433 18.3047 43.5266C18.3047 43.5266 18.3046 43.5267 18.3045 43.5268L13.3668 47.8455C13.3666 47.8456 13.3665 47.8458 13.3663 47.8459C13.2593 47.9383 13.1058 47.9444 12.9942 47.8632L12.8063 48.1217L12.9942 47.8632C12.8811 47.781 12.8408 47.6321 12.8953 47.5056L12.8955 47.5051L14.3792 44.0451L14.5703 43.5995H14.0855H11.9341C11.8119 43.5995 11.6983 43.5212 11.6541 43.4047L11.3553 43.518L11.6541 43.4047C11.6115 43.2923 11.6434 43.1607 11.7378 43.0774C11.7379 43.0773 11.738 43.0772 11.7381 43.0771L16.6758 38.7584C16.676 38.7583 16.6761 38.7582 16.6762 38.758C16.7832 38.6656 16.9368 38.6596 17.0484 38.7407C17.1614 38.8229 17.2018 38.9718 17.1473 39.0983ZM13.9485 44.2387L12.6017 47.3792L13.7378 43.9191L13.7918 43.7932L14.0855 43.9191V44.2387H13.9485Z" stroke="white" strokeWidth="0.639194" />
         <path d="M17.4408 54.0375C17.5547 53.7733 17.4698 53.4647 17.2363 53.295C17.0029 53.1253 16.6845 53.1407 16.4665 53.3297L11.527 57.6499C11.3341 57.8196 11.2646 58.0916 11.3553 58.3307C11.446 58.5699 11.6775 58.7319 11.9341 58.7319H14.0855L12.6017 62.1919C12.4879 62.4562 12.5728 62.7648 12.8063 62.9345C13.0397 63.1042 13.3581 63.0888 13.5761 62.8998L18.5156 58.5795C18.7085 58.4098 18.778 58.1379 18.6873 57.8987C18.5966 57.6596 18.367 57.4995 18.1084 57.4995H15.9571L17.4408 54.0375Z" fill="white" />
         <path d="M17.1473 53.9111L17.1471 53.9116L15.6633 57.3736L15.4724 57.8191H15.9571H18.1084C18.2337 57.8191 18.3447 57.8965 18.3884 58.012L18.6873 57.8987L18.3884 58.012C18.431 58.1244 18.3992 58.256 18.3047 58.3393C18.3047 58.3394 18.3046 58.3395 18.3045 58.3396L13.3668 62.6583C13.3666 62.6584 13.3665 62.6585 13.3663 62.6587C13.2593 62.7511 13.1058 62.7571 12.9942 62.676L12.8063 62.9345L12.9942 62.676C12.8811 62.5938 12.8408 62.4449 12.8953 62.3184L12.8955 62.3179L14.3792 58.8579L14.5703 58.4123H14.0855H11.9341C11.8119 58.4123 11.6983 58.3339 11.6541 58.2174L11.3553 58.3307L11.6541 58.2174C11.6115 58.1051 11.6434 57.9734 11.7378 57.8901C11.7379 57.8901 11.738 57.89 11.7381 57.8899L16.6758 53.5712C16.676 53.5711 16.6761 53.5709 16.6762 53.5708C16.7832 53.4784 16.9368 53.4723 17.0484 53.5535C17.1614 53.6357 17.2018 53.7846 17.1473 53.9111ZM13.9485 59.0515L12.6017 62.1919L13.7378 58.7319L13.7918 58.606L14.0855 58.7319V59.0515H13.9485Z" stroke="white" strokeWidth="0.639194" />
         <path fillRule="evenodd" clipRule="evenodd" d="M37.8112 6.49026H32.1791C31.6049 6.49026 31.1387 6.0241 31.1387 5.44989C31.1387 2.43898 28.6994 0 25.6882 0C22.6769 0 20.2376 2.43898 20.2376 5.44989C20.2376 6.0241 19.7713 6.49026 19.1971 6.49026H13.5835C13.5835 16.0513 13.5835 16.0054 13.5835 16.0237H37.8135C37.8112 15.7642 37.8112 6.74757 37.8112 6.49026Z" fill="#269F8E" />
      </Svg>


   );
};

export default Protocol2;