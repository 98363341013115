



import React from "react";
import Svg from "../../UI/Svg";
type Props = {
   size?: string;
   fill?: string;
};
const CountryFlag: React.FC<Props> = ({ fill, size, ...props }) => {
   return (

      <Svg fill={fill}
         iconSize={size}
         {...props} viewBox="-0.86 0 33.604 33.604" xmlns="http://www.w3.org/2000/svg">

         <g transform="translate(-481.237 -128.948)">

            <path d="M482.237,162.552a1,1,0,0,1-1-1v-32.6h21.707v17.8H483.237v14.8A1,1,0,0,1,482.237,162.552Zm1-17.8h17.707v-13.8H483.237Z" />

            <path d="M502.951,135.709l7.547.051a.631.631,0,0,1,.625.629v10.576a.629.629,0,0,1-.629.629h-7.149a.4.4,0,0,1-.394-.394V135.709m-2-2.014v13.5a2.394,2.394,0,0,0,2.394,2.394h7.149a2.629,2.629,0,0,0,2.629-2.629V136.389a2.629,2.629,0,0,0-2.611-2.629l-9.561-.065Z" />

         </g>

      </Svg>


   );
};

export default CountryFlag;

