/** @format */

import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { concat, unionBy } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Session,
  SessionFilterParams,
  SessionSortField,
  SortOrder,
  Token,
} from "../../../generated/graphql";
import { useGetTokens } from "../../../../../pages/infrastracture/gql/queries/useGetTokens";
import { Badge, Card, ColumnShape, Flex, Typography } from "../../../../UI";
import { FiltersList } from "../../../../components/FiltersList";
import { SmartTable } from "../../../../components/SmartTable";
import { convertSearchParamIntoArray } from "../../../../helpers";
import { SectionList } from "../../../../layout";
import { GET_Sessions } from "../../useGetSessions";
import { FilterSessionsByDate } from "./filters/FilterSessionsByDate";
import { FilterSessionsByStatus } from "./filters/FilterSessionsByStatus";
import { FilterSessionsByToken } from "./filters/FilterSessionsByToken";

type Props = {
  filters?: SessionFilterParams;
};
type Column = ColumnShape<Session>;

export const SessionsTable: React.FC<Props> = ({ filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const text = searchParams?.get("text");
  const navigate = useNavigate();
  const pageRef = useRef<number>(1);

  const sessionsSort = searchParams.get("sessionsSort");
  const startedAt = searchParams.get("startedAt");
  const endedAt = searchParams.get("endedAt");
  const status = searchParams.get("status");
  const sessionToken = searchParams.get("token");
  const { getTokens, tokens } = useGetTokens();

  const defaultColumns: Column[] = useMemo(
    () => [
      {
        key: "EVC",
        title: "EVC",
        dataKey: "EVC",
        width: 120,
        align: "center",
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Badge $fill fontWeight="bold">
              {rowData?.activitiesData?.[0]?.EVSE?.EVC?.label}
            </Badge>
          );
        },
      },
      {
        key: "EVSE",
        title: "EVSE",
        dataKey: "EVSE",
        width: 120,
        align: "center",
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Badge $fill variant="warning" fontWeight="bold">
              {rowData?.activitiesData?.[0]?.EVSE?.label}
            </Badge>
          );
        },
      },
      {
        key: "status",
        title: "status",
        dataKey: "status",
        width: 120,
        align: "center",
        sortable: true,
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Typography
              color={rowData?.status === "COMPLETED" ? "#78931A" : "#3D729C"}
            >
              {rowData?.status}
            </Typography>
          );
        },
        headerRenderer: ({ column }) => {
          return <FilterSessionsByStatus column={column} />;
        },
      },
      {
        key: "startedAt",
        title: "Started At",
        dataKey: "started at",

        dataGetter: ({ rowData }) => {
          return (
            <Typography>
              {rowData?.startedAt
                ? format(rowData?.startedAt, "dd/MM/yyyy HH:mm:ss")
                : null}
            </Typography>
          );
        },
        flexGrow: 1,
        width: 150,
        resizable: true,
        sortable: true,
        headerRenderer: ({ column }) => {
          return (
            <FilterSessionsByDate searchValue="startedAt" column={column} />
          );
        },
      },
      {
        key: "endedAt",
        title: "Ended At",
        dataKey: "ended at",
        sortable: true,
        flexGrow: 1,
        width: 150,
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Typography>
              {rowData?.endedAt
                ? format(rowData?.endedAt, "dd/MM/yyyy HH:mm:ss")
                : null}
            </Typography>
          );
        },
        headerRenderer: ({ column }) => {
          return <FilterSessionsByDate searchValue="endedAt" column={column} />;
        },
      },

      {
        key: "kWh",
        title: "kWh",
        dataKey: "kWh",
        flexGrow: 1,
        width: 120,
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Typography>
              {rowData?.activitiesData?.[0]?.consumptions?.energy}
            </Typography>
          );
        },
      },

      {
        key: "tokenUser",
        title: "token user",
        dataKey: "tokenUser",
        flexGrow: 2,
        width: 300,
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Badge $fill fontWeight="bold">
              {rowData?.Token?.label}
            </Badge>
          );
        },
        headerRenderer: ({ column }) => {
          return <FilterSessionsByToken column={column} />;
        },
      },
      {
        key: "startType",
        title: "start type",
        dataKey: "startType",
        flexGrow: 1,
        width: 120,
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Badge fontWeight="bold">
              {rowData?.activitiesData?.[0]?.authorizationMethod}
            </Badge>
          );
        },
      },
      {
        key: "EMSP",
        title: "EMSP",
        dataKey: "EMSP",
        flexGrow: 1,
        width: 200,
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Typography fontSize={12}>
              {rowData?.Token?.PlatformParty?.name}
            </Typography>
          );
        },
      },
      {
        key: "sessionUid",
        title: "session uid",
        dataKey: "sessionUid",
        flexGrow: 2,
        width: 300,
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Badge flexShrink={0} variant="secondary">
              {rowData?.uid}
            </Badge>
          );
        },
      },
    ],
    []
  );
  const [columnKeys, setColumnKeys] = useState<string[]>(
    defaultColumns.map((column) => String(column.key))
  );
  const columns = defaultColumns.filter((column) =>
    columnKeys.some((key) => key === String(column.key))
  );

  useEffect(() => {
    if (sessionToken) {
      getTokens({
        variables: {
          pagination: {
            limit: 20,
          },
          filter: {
            text: sessionToken,
          },
        },
      });
    }
  }, [getTokens, sessionToken]);

  const [
    getSessions,
    { loading, data, refetch, client, fetchMore: fetchMoreItems },
  ] = useLazyQuery(GET_Sessions, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    pageRef.current = 1;
    getSessions({
      variables: {
        filter: {
          text: text,
          status: convertSearchParamIntoArray(status, " OR "),
          ...(startedAt ? { startedAt: JSON.parse(startedAt) } : {}),
          ...(endedAt ? { endedAt: JSON.parse(endedAt) } : {}),

          tokenUid: tokens?.find((token: Token) => token.label === sessionToken)
            ?.uid,
          ...filters,
        },
        sort: {
          field: sessionsSort?.split("-")?.[0] as SessionSortField,
          order: sessionsSort?.split("-")?.[1] as SortOrder,
        },
        pagination: {
          limit: 20,
          page: pageRef.current,
        },
      },
    });
    return () => {
      client.cache.evict({
        id: "ROOT_QUERY",
        fieldName: "Sessions",
        broadcast: true,
      });
      client.cache.gc();
    };
  }, [
    client.cache,
    endedAt,
    filters,
    getSessions,
    sessionToken,
    sessionsSort,
    startedAt,
    status,
    text,
    tokens,
  ]);

  const handleClickRow = (row: any) => {
    // dispatch(setSelectedLocation(row));
    navigate(`/sessions/${row?.uid}`);
  };

  const onChangeSearchInput = (e: any) => {
    if (e.target.value) {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    } else {
      searchParams.set("text", "");
      setSearchParams(searchParams);
    }
  };

  const tableRef = React.useRef<any>();
  const handleRefresh = useCallback(() => {
    tableRef?.current?.scrollToTop(0);
    pageRef.current = 1;
    refetch({
      pagination: {
        limit: 20,
        page: 1,
      },
    });
  }, [tableRef, refetch]);

  const handleSortColumn = useCallback(
    ({ key, order }: { key: React.Key; order: any }) => {
      searchParams.set(`sessionsSort`, `${key}-${order}`);

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Flex width={"100%"} height={"100%"}>
      <SectionList width="100%" height="100%">
        <FiltersList
          textInputProps={{
            onChange: onChangeSearchInput,
            defaultValue: text || "",
          }}
          onClickRefreshButton={handleRefresh}
        />
        {/* <TableDataDetailsHeader
          page={pageRef.current}
          total={data?.Sessions?.total || 0}
          fetched={data?.Sessions?.results?.length || 0}
        /> */}

        <Card
          minWidth={300}
          minHeight={400}
          overflow="hidden"
          bg="white"
          position="relative"
          display="flex"
          width="100%"
          height="100%"
          borderRadius={5}
        >
          <SmartTable
            tableRef={tableRef}
            fixed={true}
            rowHeight={50}
            columns={columns as any}
            onEndReached={() => {
              if (
                data?.Sessions &&
                data?.Sessions?.total > data?.Sessions?.results?.length
              ) {
                pageRef.current += 1;

                fetchMoreItems({
                  variables: {
                    pagination: {
                      page: pageRef.current,
                      limit: 20,
                    },
                  },
                  updateQuery(previousQueryResult, options) {
                    return {
                      Sessions: {
                        results: unionBy(
                          concat(
                            previousQueryResult?.Sessions?.results,
                            options.fetchMoreResult.Sessions.results
                          ),
                          "uid"
                        ),
                        total: options.fetchMoreResult.Sessions.total,
                        __typename: options.fetchMoreResult.Sessions.__typename,
                        pagination: options.fetchMoreResult.Sessions.pagination,
                      },
                    };
                  },
                });
              }
            }}
            loadingMore={loading}
            data={data?.Sessions?.results || []}
            selectedRowProps={{
              onRowChange: handleClickRow,
              selectedRow: "",
            }}
            onColumnSort={handleSortColumn}
            sortBy={{
              key: searchParams.get(`sessionsSort`)?.split("-")[0] as string,
              order:
                (searchParams.get(`sessionsSort`)?.split("-")[1] as any) ||
                "asc",
            }}
          />
        </Card>
      </SectionList>
    </Flex>
  );
};
