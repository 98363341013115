/** @format */

import { t } from "i18next";
import {
  Card,
  Flex,
  LoaderWithFallback,
  Typography,
} from "../../../../shared/UI";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import {
  Flavor,
  Platform as PlatformSVG,
  Protocol2,
  Status,
  Version,
} from "../../../../shared/assets/svg";
import { ItemWithIcon } from "../../../../shared/components/ItemWithIcon";
import {
  ItemColumnSkeleton,
  ItemRowSkeleton,
  ItemWithIconSkeletonGroup,
} from "../../../../shared/components/Skeletons";
import useBreakpoints from "../../../../shared/hooks/useBreakpoints";
import UserService from "../../../../shared/services/UserServices";
import { Platform } from "../../../../shared/gql/generated/graphql";

export const PlatformShowFields: React.FC<{
  show?: boolean;
  platform: Platform;
  loading: boolean;
}> = ({ show, platform, loading }) => {
  const breakPoint = useBreakpoints();

  return (
    <LoaderWithFallback
      loading={loading}
      LoadingMsg={<PlatformSkeleton />}
      errorMsg={<Typography>{t("no data available")}</Typography>}
      hasError={!platform}
    >
      <VerticalScrollContainer p={[4, 4, 4, 4, 4, 5]}>
        <Flex>
          <Flex
            style={{ gap: "20px 20px" }}
            flexWrap="wrap"
            flexDirection="row"
          >
            {show ? (
              <ItemWithIcon
                icon={<PlatformSVG />}
                label="name"
                value={platform?.name}
                color="rosa"
              />
            ) : null}
            <Card
              minWidth={200}
              border="1px solid #269F8E"
              flexDirection="column"
              bg="#E6F5F2"
              flex={1}
              height="200px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Protocol2 size="80px" />
              <Typography
                color="#269F8E"
                upperCase
                variant="title30"
                fontWeight="bold"
                pt={2}
              >
                {t("protocol")}
              </Typography>
              <Typography color="#269F8E" upperCase variant="title30" pt={1}>
                {platform?.protocol}
              </Typography>
            </Card>
            <Card
              minWidth={200}
              border="1px solid #30A3FF"
              flexDirection="column"
              bg="#CBE8FF"
              flex={1}
              height="200px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Status size="100px" />
              <Typography
                color="#30A3FF"
                upperCase
                variant="title30"
                fontWeight="bold"
                pt={2}
              >
                {t("status")}
              </Typography>
              <Typography color="#30A3FF" upperCase variant="title30" pt={1}>
                {platform?.status || "---"}
              </Typography>
            </Card>
            {UserService.hasRealmRole(["bus_dev"]) ? null : (
              <Card
                minWidth={200}
                border="1px solid #D3A405"
                flexDirection="column"
                bg="#F8EFD1"
                flex={1}
                height="200px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Version size="80px" />
                <Typography
                  color="#D3A405"
                  upperCase
                  variant="title30"
                  fontWeight="bold"
                  pt={2}
                >
                  {t("version")}
                </Typography>
                <Typography color="#D3A405" upperCase variant="title30" pt={1}>
                  {platform?.version || "---"}
                </Typography>
              </Card>
            )}
            <Card
              minWidth={200}
              border="1px solid #FF8A8A"
              flexDirection="column"
              bg="#FFE2E2"
              flex={1}
              height="200px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Flavor size="80px" />
              <Typography
                color="#FF8A8A"
                upperCase
                variant="title30"
                fontWeight="bold"
                pt={2}
              >
                {t("flavor")}
              </Typography>
              <Typography color="#FF8A8A" upperCase variant="title30" pt={1}>
                {platform?.flavor || "---"}
              </Typography>
            </Card>
          </Flex>
          {UserService.hasRealmRole(["bus_dev"]) ? null : (
            <>
              <Card
                width="100%"
                p={4}
                margin="auto"
                bg="#FAFAFB"
                display="flex"
                borderRadius={5}
                mt={3}
                border="1px solid #122967"
                style={{ gap: "20px 20px" }}
                flexWrap="wrap"
                flexDirection="row"
              >
                <Flex
                  borderRight={
                    ["xl"].includes(breakPoint as string)
                      ? "2px solid #122967"
                      : "none"
                  }
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                  minWidth={200}
                >
                  <Typography
                    textAlign="center"
                    upperCase
                    variant="title20"
                    fontWeight="bold"
                  >
                    {t("needs reliable push")}
                  </Typography>
                  <Typography
                    textAlign="center"
                    mt={2}
                    color={platform?.needsReliablePush ? "#269F8E" : "#FF8A8A"}
                    upperCase
                    variant="title40"
                    fontSize={64}
                    fontWeight="bold"
                  >
                    {platform?.needsReliablePush ? "ON" : "OFF"}
                  </Typography>
                </Flex>
                <Flex
                  borderRight={
                    ["xl"].includes(breakPoint as string)
                      ? "2px solid #122967"
                      : "none"
                  }
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                  minWidth={200}
                >
                  <Typography
                    textAlign="center"
                    upperCase
                    variant="title20"
                    fontWeight="bold"
                  >
                    {t("Async push")}
                  </Typography>
                  <Typography
                    textAlign="center"
                    mt={2}
                    color={platform?.asyncPush ? "#269F8E" : "#FF8A8A"}
                    upperCase
                    variant="title40"
                    fontSize={64}
                    fontWeight="bold"
                  >
                    {platform?.asyncPush ? "ON" : "OFF"}
                  </Typography>
                </Flex>
                <Flex
                  borderRight={
                    ["xl"].includes(breakPoint as string)
                      ? "2px solid #122967"
                      : "none"
                  }
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                  minWidth={200}
                >
                  <Typography
                    textAlign="center"
                    upperCase
                    variant="title20"
                    fontWeight="bold"
                  >
                    {t("skip credentials refresh")}
                  </Typography>
                  <Typography
                    textAlign="center"
                    mt={2}
                    color={
                      platform?.skipCredentialsRefresh ? "#269F8E" : "#FF8A8A"
                    }
                    upperCase
                    variant="title40"
                    fontSize={64}
                    fontWeight="bold"
                  >
                    {platform?.skipCredentialsRefresh ? "ON" : "OFF"}
                  </Typography>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                  minWidth={200}
                >
                  <Typography
                    textAlign="center"
                    upperCase
                    variant="title20"
                    fontWeight="bold"
                  >
                    {t("pull tokens")}
                  </Typography>
                  <Typography
                    textAlign="center"
                    mt={2}
                    color={platform?.pullTokens ? "#269F8E" : "#FF8A8A"}
                    upperCase
                    variant="title40"
                    fontSize={64}
                    fontWeight="bold"
                  >
                    {platform?.pullTokens ? "ON" : "OFF"}
                  </Typography>
                </Flex>
              </Card>

              <Card
                px={3}
                bg="white"
                display="flex"
                borderRadius={5}
                mt={3}
                border="1px solid #122967"
                flexDirection="column"
              >
                <Flex
                  p={3}
                  style={{ gap: "10px 10px" }}
                  flexWrap="wrap"
                  flexDirection="row"
                  borderBottom="1px solid #3D729C"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                >
                  <Flex
                    borderTopRightRadius={10}
                    borderBottomLeftRadius={10}
                    p="10px 20px"
                    minWidth={200}
                    bg="#269F8E"
                  >
                    <Typography
                      color="white"
                      upperCase
                      variant="title20"
                      fontWeight="bold"
                    >
                      {t("offline Tokens")}
                    </Typography>
                  </Flex>
                  <Flex
                    pt={2}
                    flexDirection="column"
                    minWidth={300}
                    flex={1}
                    justifyItems="center"
                    alignItems="left"
                  >
                    <Flex
                      mb={2}
                      flex={1}
                      flexDirection="row"
                      alignItems="center"
                    >
                      {platform?.offlineTokenIn ? (
                        <Typography
                          tooltipText={String(t("offline token in"))}
                          style={{ whiteSpace: "nowrap", overflow: "scroll" }}
                          variant="title20"
                          display="flex"
                          alignItems="center"
                        >
                          {" "}
                          {platform?.offlineTokenIn}
                        </Typography>
                      ) : null}
                    </Flex>
                    <Flex flex={1} flexDirection="row">
                      {platform?.offlineTokenOut ? (
                        <Typography
                          height="100%"
                          textAlign="center"
                          tooltipText={String(t("offline token out"))}
                          style={{ whiteSpace: "nowrap", overflow: "scroll" }}
                          variant="title20"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {" "}
                          {platform?.offlineTokenOut}
                        </Typography>
                      ) : null}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  p={3}
                  style={{ gap: "10px 10px" }}
                  flexWrap="wrap"
                  flexDirection="row"
                  borderBottom="1px solid #3D729C"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                >
                  <Flex
                    borderTopRightRadius={10}
                    borderBottomLeftRadius={10}
                    p="10px 20px"
                    minWidth={200}
                    bg="#008FDD"
                  >
                    <Typography
                      color="white"
                      upperCase
                      variant="title20"
                      fontWeight="bold"
                    >
                      {t("party hub")}
                    </Typography>
                  </Flex>
                  <Flex minWidth={300} flex={1} justifyContent="center">
                    <Typography
                      style={{ whiteSpace: "nowrap", overflow: "scroll" }}
                      pl={2}
                      variant="title20"
                      fontWeight="bold"
                    >
                      {" "}
                      {platform?.HubParty?.name || "---"}
                    </Typography>
                  </Flex>
                </Flex>
                <Flex
                  p={3}
                  style={{ gap: "10px 10px" }}
                  flexWrap="wrap"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                >
                  <Flex
                    borderTopRightRadius={10}
                    borderBottomLeftRadius={10}
                    p="10px 20px"
                    minWidth={200}
                    bg="#D3A405"
                  >
                    <Typography
                      color="white"
                      upperCase
                      variant="title20"
                      fontWeight="bold"
                    >
                      {t("entry point url")}
                    </Typography>
                  </Flex>
                  <Flex
                    minWidth={300}
                    overflow={"hidden"}
                    flexWrap="nowrap"
                    flex={1}
                    justifyContent="center"
                  >
                    <Typography
                      pl={2}
                      style={{ whiteSpace: "nowrap", overflow: "scroll" }}
                      variant="title20"
                      fontWeight="bold"
                    >
                      {" "}
                      {platform?.entryPointUrl || "---"}
                    </Typography>
                  </Flex>
                </Flex>
              </Card>
            </>
          )}
        </Flex>
      </VerticalScrollContainer>
    </LoaderWithFallback>
  );
};

const PlatformSkeleton = () => {
  return (
    <VerticalScrollContainer p={[4, 4, 4, 4, 4, 5]}>
      <Flex style={{ gap: "20px 20px" }} flexWrap="wrap" flexDirection="row">
        <ItemWithIconSkeletonGroup />
        <ItemRowSkeleton />
        <ItemColumnSkeleton />
      </Flex>
    </VerticalScrollContainer>
  );
};
