import { memo } from "react";
import { Box } from "../../../../../shared/UI";
import { Bound } from "../../../@types/geojson";
import {
  RegionProperties,
  useSiteSelectionGeoDataContext,
} from "../../../slices/GeoDataContextProvider";
import { mapKwh } from "../../../utils/map-kwh";
import { GeoJSONLayer, GeoJSONLayerProps } from "./GeoJSONLayer";
import { useTranslation } from "react-i18next";

type RegionLayerProps = {} & Omit<
  GeoJSONLayerProps<
    RegionProperties & {
      weight: number;
      bound: Bound;
    }
  >,
  "data"
>;

const Tooltip = memo(
  ({ data }: { data: RegionProperties }) => {
    const { t } = useTranslation();
    const val = mapKwh(data.stats.kwh_ac + data.stats.kwh_dc);
    return (
      <Box width={"400px"}>
        {t("siteSelection.regionLayer.region")} {data.DEN_REG}
        <br />
        <b>{t("siteSelection.regionLayer.statistic")}</b>
        <br />
        {data.stats && `${val.value.toFixed(2)} ${val.unit}`}
        <br />
        {data.stats && `${data.stats.n_ac} AC ${data.stats.n_dc} DC`}
      </Box>
    );
  },
  (prev, actual) => prev.data.COD_REG === actual.data.COD_REG
);

export const RegionLayer: React.FC<RegionLayerProps> = (props) => {
  const { regions = [] } = useSiteSelectionGeoDataContext();

  return (
    <>
      {regions && (
        <GeoJSONLayer
          data={{ type: "FeatureCollection", features: regions }}
          tooltip={(data) => <Tooltip data={data} />}
          {...props}
        />
      )}
    </>
  );
};
