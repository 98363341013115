/** @format */

import {
  Badge,
  Button,
  Card,
  Flex,
  Typography,
} from "../../../../../shared/UI";
import { Absolute } from "../../../../../shared/UI/Absolute";
import { Info, Setting } from "../../../../../shared/assets/svg";
import { Nina } from "../../../../../shared/Ocpp/nina";
import { NinaState } from "../../../../../shared/Ocpp/nina.ocpp.types";
import { useTypedSelector } from "../../../../../shared/redux/store";

type ChargePointProps = {
  nina: any;
  ninaState?: NinaState;
  setNina: React.Dispatch<React.SetStateAction<any>>;
  setNinaState: React.Dispatch<React.SetStateAction<any>>;
  evcLabel: string;
  toggleLogs: () => void;
  toggleSettings: () => void;
  numberOfConnectors: number;
};

export const ChargePoint: React.FC<ChargePointProps> = ({
  nina,
  setNina,
  ninaState,
  setNinaState,
  evcLabel,
  toggleLogs,
  toggleSettings,
  numberOfConnectors,
}) => {
  const {
    customMeterValue,
    sendStatusNotificationToConnector0,
    sendStatusNotificationToOtherConnectors,
  } = useTypedSelector((state) => state.simulator);
  const connectButton = () => {
    try {
      if (ninaState?.wsStatus !== "OPEN") {
        console.log("CONNECT");
        const nina = new Nina(
          `wss://ocpp-universal.test.cpo.bepower.io`,
          evcLabel,
          numberOfConnectors,
          setNinaState,
          customMeterValue,
          sendStatusNotificationToConnector0,
          sendStatusNotificationToOtherConnectors
        );
        setNina(nina);
      } else {
        console.log("DISCONNECTED");
        nina.disconnect();
      }
    } catch (err) {
      console.error(`Error connecting for EVC ${evcLabel}`, err);
      return null;
    }
  };

  return (
    <Card
      p={2}
      flexDirection={"column"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      position={"relative"}
      bg="white"
      flexShrink={0}
    >
      <Typography flexShrink={0} variant={"title30"}>
        {evcLabel}{" "}
      </Typography>

      <Absolute
        top={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          onClick={toggleLogs}
          variant="gray"
          fixedSize
          $fill={false}
          m={2}
          size="small"
        >
          <Info fill="#737373" size="5" />
        </Button>
        <Button
          onClick={toggleSettings}
          variant="gray"
          fixedSize
          $fill={false}
          m={2}
          size="small"
        >
          <Setting fill="#737373" size="5" />
        </Button>
      </Absolute>
      <Flex fullSize>
        <Absolute
          top={0}
          left={0}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Badge m={2} size="small">
            {ninaState?.wsStatus} - PC: {ninaState?.pendingCalls} - PR:{" "}
            {ninaState?.pendingResponses}
          </Badge>
        </Absolute>
      </Flex>
      <Flex
        flexDirection="row"
        p={2}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        style={{ gap: "10px 10px" }}
        flexWrap={"wrap"}
      >
        <Button
          width={250}
          $size={"large"}
          variant={ninaState?.wsStatus === "OPEN" ? "danger" : "success"}
          $fill={false}
          onClick={connectButton}
        >
          {ninaState?.wsStatus !== "OPEN" ? "Connect" : "Disconnect"}
        </Button>
      </Flex>
    </Card>
  );
};
