

import React from "react";
import Svg from "../../UI/Svg";
type Props = {
  size?: string;
  fill?: string;
};
const Role: React.FC<Props> = ({ fill, size, ...props }) => {
  return (

    <Svg fill={fill}
      iconSize={size}
      {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <title>network-role</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="Q3_icons" data-name="Q3 icons">
          <path d="M42,24l-6-6v4H19.7a7.4,7.4,0,0,0-1.6-3.2L26.8,10H36v4l6-6L36,2V6H25.2L15.1,16.1l-.3.4A8.7,8.7,0,0,0,12,16a8,8,0,0,0,0,16,8.7,8.7,0,0,0,2.8-.5l.3.4L25.2,42.1H36V46l6-6-6-6v4.1H26.8l-8.7-8.9A8.8,8.8,0,0,0,19.7,26H36v4ZM12,28a4,4,0,1,1,4-4A4,4,0,0,1,12,28Z" />
        </g>
      </g>
    </Svg>

  );
};

export default Role;

