import { gql, useLazyQuery } from "@apollo/client";

export const GET_TARIFFS_ASSOCS = gql`
  query TariffAssocs(
    $pagination: PaginationParams
    $filter: TariffAssocFilterParams
    $sort: TariffAssocSortingParams
  ) {
    TariffAssocs(pagination: $pagination, filter: $filter, sort: $sort) {
      total
      __typename
      pagination {
        cursor
        last
      }
      results {
        CPOParties {
          name
          uid
        }
        CSOs {
          name
          uid
        }
        cpoPartyUids
        tariffUid
        EMSPParties {
          name
          PartyIdCountry
        }
        label
        Active
        Expired
        uid
        updatedAt
        validFrom
        validTo
        Tariff {
          uid
          updatedAt
          label
          PricingByCountry {
            countries
          }
        }
      }
    }
  }
`;

export const useGetTariffAssocs = () => {
  const [
    executeTariffAssocs,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      updateQuery,

      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_TARIFFS_ASSOCS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
  });

  const fetchMore = () => {
    if (
      data?.TariffAssocs?.pagination?.cursor &&
      data?.TariffAssocs?.results?.length < data.TariffAssocs?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.TariffAssocs?.pagination.cursor,
          },
        },
        updateQuery(
          { TariffAssocs: prevData },
          { fetchMoreResult: { TariffAssocs: newData } }
        ) {
          if (prevData?.results?.length) {
            return {
              TariffAssocs: {
                results: [...prevData?.results, ...newData?.results],
                __typename: "TariffAssocList",
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          } else {
            return {
              TariffAssocs: {
                results: [...newData.results],
                __typename: "TariffAssocList",
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          }
        },
      });
    }
  };

  return {
    loading,
    executeTariffAssocs,
    error,
    count: data?.TariffAssocs?.total,
    tariffAssocsList: data?.TariffAssocs?.results,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
