import { gql, useLazyQuery } from "@apollo/client";
import { Location, Project } from "../@types/project";

const GET_SITE_SELECTION_PROJECTS = gql`
  query SiteSelectionProjects(
    $filter: SiteSelectionProjectFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    SiteSelectionProjects(filter: $filter, pagination: $pagination, sort: $sort) {
      __typename
      results {
        __typename
          uid
          name 
          type
          status
          visibility
          accessType
          favoriteBy
          createdBy
          createdAt
          updatedAt
          locations {
            results {
              ac
              dc
              address
              city
              country
              hex
              province
              region
              createdAt
              createdBy
              uid
              updatedAt
              latitude
              longitude
              validated
              contractualCapacity
              zipCode
              forecast
            }
            total
            pagination {
              cursor
              last
            }
          }
      }
      total
      pagination {
        cursor
        last
      }
    }
  }
`;

export const useGetSiteSelectionProjects = () => {
  const [
    getSiteSelectionProjects,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_SITE_SELECTION_PROJECTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  
  const fetchMore = () => {
    if (
      data?.SiteSelectionProjects?.pagination?.cursor &&
      data?.SiteSelectionProjects?.results?.length < data.SiteSelectionProjects?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.SiteSelectionProjects?.pagination.cursor,
          },
        },
        updateQuery(
          { SiteSelectionProjects: prevData },
          { fetchMoreResult: { SiteSelectionProjects: newData } }
        ) {
          if (prevData?.results?.length) {
            return {
              SiteSelectionProjects: {
                results: [...prevData?.results, ...newData?.results],
                __typename: "SiteSelectionProjectList",
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          } else {
            return {
              SiteSelectionProjects: {
                results: [...newData?.results],
                __typename: "SiteSelectionProjectList",
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          }
        },
      });
    }
  };

  return {
    loading,
    getSiteSelectionProjects,
    error,
    siteSelectionProjects: (data?.SiteSelectionProjects?.results as any [])?.map((project: any) => {
      return {
        id: project.uid,
        name: project.name,
        type: project.type,
        accessType: project.accessType,
        visibility: project.visibility,
        status: project.status,
        favoriteBy: project.favoriteBy,
        createdBy: project.createdBy.split("@")[0].replace('.', ' '),
        createdAt: new Date(project.createdAt),
        updatedAt: new Date(project.updatedAt),
        locations: (project.locations?.results ?? []).map((location: any) => ({
          id: location.uid,
          ac: Math.ceil(location.ac),
          dc: Math.ceil(location.dc),
          address: location.address,
          hex: location.hex,
          province: location.province,
          region: location.region,
          city: location.city,
          country: location.country,
          createdAt: new Date(location.createdAt),
          createdBy: location.createdBy.split("@")[0].replace('.', ' '),
          updatedAt: new Date(location.updatedAt),
          latitude: location.latitude,
          longitude: location.longitude,
          validated: location.validated,
          contractualCapacity: location.contractualCapacity,
          zipCode: location.zipCode,
          forecast: location.forecast,
        } as Location)).sort((a: Location, b: Location) => (a.forecast ?? 0) - (b.forecast ?? 0))
        ,
      }  as Project;
    }) ,
    count: data?.SiteSelectionProjects?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
  };
};
