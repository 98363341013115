import { IndexedDB } from "react-indexed-db-hook";
import { SectionLayout } from "../../../../shared/layout";
import { SiteSelectionContextProvider } from "../../slices/ContextProvider";
import { SiteSelectionEvcDataContextProvider } from "../../slices/EvcDataContextProvider";
import { SiteSelectionGeoDataContextProvider } from "../../slices/GeoDataContextProvider";
import { DBConfig } from "../../utils/db.config";
import { Project } from "../components/Project";

type Props = {};

const SiteSelectionProject: React.FC<Props> = () => {
  return (
    <SectionLayout label="site-selection">
      <SiteSelectionGeoDataContextProvider>
      <SiteSelectionContextProvider>
        <SiteSelectionEvcDataContextProvider>
            <IndexedDB {...DBConfig}>
              <Project />
            </IndexedDB>
        </SiteSelectionEvcDataContextProvider>
      </SiteSelectionContextProvider>
          </SiteSelectionGeoDataContextProvider>
    </SectionLayout>
  );
};

export default SiteSelectionProject;
