/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated";

export const GET_Sessions = gql(`
  query Sessions(
    $filter: SessionFilterParams
    $pagination: PaginationParams
    $sort: SessionSortingParams
  ) {
    Sessions(filter: $filter, pagination: $pagination, sort: $sort) {
      __typename
      results {
        __typename
        activitiesData {
          __typename
          EVSE {
            __typename
            EVC {
              __typename
              assetStatus
              branding
              capacity
              code
              configurationGroupUid
              connectionType
              createdAt
              customerService
              description
              enstoDlmMaster
              firmwareVersion
              floorLevel
              iccid
              imsi
              installation
              installedAt
              ip
              label
              locationUid
              meterSerialNumber
              meterType
              model
              owner
              podUid
              powerType
              protocol
              safetyPerson
              safetyPhone
              serialNumber
              simSupplier
              status
              statusUpdatedAt
              uid
              updatedAt
              v1gEnabled
              vendor
            }
            assetStatus
            label
            number
            status
            uid
            updatedAt
          }
          actualEnd
          authorizationMethod
          authorizationReference
          completed
          consumptions {
            __typename
            energy
            parkingTime
            time
          }
          costs {
            __typename
            energy
            parkingTime
            time
          }
          createdAt
          endedAt
          evcUid
          evseUid
          expectedEnd
          startedAt
          status
          totalCost
          transactionUid
          type
          uid
          updatedAt
        }
        Location {
          __typename
          address
          city
          country
          cpoPartyUid
          createdAt
          label
        }
        Token {
          __typename
          PlatformParty {
            countryCode
            partyId
            phoneNumber
            name
            role
            uid
            updatedAt
            website
          }
          authId
          emspPartyUid
          expiresAt
          group
          label
          language
          origin
          uid
          updatedAt
          valid
          value
          whitelist
        }
        endedAt
        locationUid
        origin
        startedAt
        status
        tariffUid
        tokenUid
        uid
        updatedAt
      }
      total
      pagination {
        cursor
        last
      }
    }
  }
`);
export const useGetSessions = () => {
  const [
    getSessions,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore: fetchMoreItems,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_Sessions, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  // const fetchMore = () => {
  //   if (
  //     data?.Sessions?.pagination?.cursor &&
  //     data?.Sessions?.results?.length < data.Sessions?.total
  //   ) {
  //     fetchMoreItems({
  //       variables: {
  //         pagination: {
  //           after: data?.Sessions?.pagination.cursor,
  //           limit: 50,
  //         },
  //       },
  //     });
  //   }
  // };
  return {
    loading,
    getSessions,
    error,
    sessions: data?.Sessions.results,
    count: data?.Sessions?.total,
    refetch,
    // fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
