import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useGetSiteSelectionEvcs } from "../graphql/useSiteSelectionEvcs";
import { useGetSiteSelectionPois } from "../graphql/useSiteSelectionPois";
import { AttractionType } from "../locations/components/map-components/PoiFilter";
import { useSiteSelectionContext } from "./ContextProvider";
import { CityStat, ProvinceStat, RegionStat, useSiteSelectionGeoDataContext } from "./GeoDataContextProvider";


export type BecEvc = {
  projectWcd: Date;
  cpDc: number;
  locationCountry: string;
  evcModelName: string;
  projectPhase:
    | "Backlog"
    | "Commissioning"
    | "Operation"
    | "Pre-Backlog"
    | "StandBy"
    | "Terminated"
    | "unknown"
  installationCreatedAt: Date;
  latitude: number;
  evcUuid: string;
  longitude: number;
  projectNumberOfEvc: number;
  cpAc: number;
  evcType: string;
  projectCod: Date;
  cp: number;
  evcModelCapacity: number;
  cso: string;
  evcModelChargeMode: "AC" | "DC";
  installationCod: Date;
  locationMunicipality: string;
  locationRegion: string;
  locationProvince: string;
  locationProvinceCode: string;
  locationZip: number;
  areaKm2: number;
  firstChargingDate: Date;
  ebitdaDate: Date;
  locationAddress: string;
  avgMonthlyNSessions: number;
  avgMonthlyKwh: number;
};


export type CompetitorEvc = {
  evcModelChargeMode: "AC" | "DC";
  projectPhase:
    | "Backlog"
    | "Commissioning"
    | "Operation"
    | "Pre-Backlog"
    | "StandBy"
    | "Terminated";
  longitude: number;
  ebitdaDate: Date;
  cso: string;
  evcUuid: string;
  latitude: number;
  locationMunicipality: string;
  locationRegion: string;
  locationProvince: string;
  locationProvinceCode: string;
  locationZip: number;
  areaKm2: number;
};

export type POI = {
  id: string;
  category: AttractionType;
  name: string;
  type: string;
  lat: number;
  lon: number;
};

interface SiteSelectionEvcDataContextProps {
  becEvcs: BecEvc[] | null;
  becEvcsOnGround: BecEvc[] | null;
  becEvcsWip: BecEvc[] | null;
  competitorsEvcs: CompetitorEvc[] | null;
  poiFilters: string[];
  filteredPois: POI[];
  filteredCompetitorsEvcs: CompetitorEvc[];
  filteredBecEvcs: BecEvc[];
  cityStats: {
    [key: string]: CityStat;
  };
  provinceStats: {
    [key: number]: ProvinceStat;
  };
  regionStats: {
    [key: number]: RegionStat;
  };
  showCef1: boolean;
  showCef2: boolean;
  showBecEvcs: {
    ac: boolean;
    dc: boolean;
  }
  showCompetitorEvcs: {
    ac: boolean;
    dc: boolean;
  }
  bounds: google.maps.LatLngBounds | null;
  zoom: number | null;
  setShowBecEvcs: (show: { ac: boolean; dc: boolean }) => void;
  setShowCompetitorEvcs: (show: { ac: boolean; dc: boolean }) => void;
  setCef1: (show: boolean) => void;
  setCef2: (show: boolean) => void;
  togglePoiFilter: (filter: string) => void;
  filterPois: (type: string[], bound?: google.maps.LatLngBounds) => POI[];
  setBounds: (bounds: google.maps.LatLngBounds | null) => void;
  setZoom: (zoom: number | null) => void;
}

const SiteSelectionEvcDataContext =
  createContext<SiteSelectionEvcDataContextProps>({
    becEvcs: null,
    competitorsEvcs: null,
    poiFilters: [],
    filteredPois: [],
    filteredCompetitorsEvcs: [],
    becEvcsOnGround: [],
    filteredBecEvcs: [],
    becEvcsWip: [],
    cityStats: {},
    provinceStats: {},
    regionStats: {},
    showCef1: false,
    showCef2: false,
    showBecEvcs: {
      ac: true,
      dc: true,
    },
    showCompetitorEvcs: {
      ac: true,
      dc: true,
    },
    bounds: null,
    zoom: null,
    setShowBecEvcs: () => {},
    setShowCompetitorEvcs: () => {},
    setCef1: () => {},
    setCef2: () => {},
    togglePoiFilter: () => {},
    filterPois: () => [],
    setBounds: () => {},
    setZoom: () => {},
  });

function SiteSelectionEvcDataContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {

  const [poiFilters, setPoiFilters] = useState<string[]>(["parking"]);
  const [showCef1, setShowCef1] = useState<boolean>(false);
  const [showCef2, setShowCef2] = useState<boolean>(false);
  const [showBecEvcs, setShowBecEvcs] = useState<{
    ac: boolean;
    dc: boolean;
  }>({
    ac: true,
    dc: true,
  });
  const [showCompetitorEvcs, setShowCompetitorEvcs] = useState<{
    ac: boolean;
    dc: boolean;
  }>({
    ac: false,
    dc: false,
  });
  const [pois, setPois] = useState<POI[] | null>();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [zoom, setZoom] = useState<number | null>(null);

  const {
    state: { selectedCity, selectedProvince  },
  } = useSiteSelectionContext();

  const {
    pois: fetchedPois, fetchAll
  } = useGetSiteSelectionPois();

  const {
    cityStats,
    provinceStats,
    regionStats,
  } = useSiteSelectionGeoDataContext();

  const {
    becEvcs, competitorsEvcs, fetchAll: fetchAllEvcs
  } = useGetSiteSelectionEvcs();
  
  const code = selectedProvince?.properties.SIGLA
  useEffect(() => {
    if (!code) return;
    fetchAllEvcs({
      provinceCode: code,
    })
  }, [code, fetchAllEvcs]);


  useEffect(() => {

    if (!selectedCity) return;
    if (!bounds) return;

    if (!zoom || zoom < 16) return;

    if(poiFilters.length === 0) return;


    fetchAll(bounds, poiFilters.length===1?poiFilters[0]:undefined);

  }, [bounds, fetchAll, poiFilters, selectedCity, zoom]);

  useEffect(() => {
    if (!fetchedPois) return;
    setPois(fetchedPois);
  }, [fetchedPois]);

  const filterPois = useCallback(
    (type: string[], bound?: google.maps.LatLngBounds | null) => {
      if (!pois) return [];

      const r = pois.filter(
        (poi) =>
          type.includes(poi.category) &&
          (bound?.contains(new google.maps.LatLng(poi.lat, poi.lon)) ?? true)
      );
      return r;
    },
    [pois]
  );

  const filteredPois = useMemo(
    () => filterPois(poiFilters, bounds),
    [bounds, filterPois, poiFilters]
  );

  const filteredCompetitorsEvcs = useMemo(() => {
    if (!selectedCity || !competitorsEvcs) return [];

    const bound = new google.maps.LatLngBounds(
      new google.maps.LatLng(
        selectedCity.properties.bound.minLat,
        selectedCity.properties.bound.minLng
      ),
      new google.maps.LatLng(
        selectedCity.properties.bound.maxLat,
        selectedCity.properties.bound.maxLng
      )
    );

    return (
      competitorsEvcs?.filter((evc) =>
        bound.contains(new google.maps.LatLng(evc.latitude, evc.longitude))
      ) ?? []
    );
  }, [competitorsEvcs, selectedCity]);

  const filteredBecEvcs = useMemo(() => {
    if (!selectedCity || !becEvcs) return [];

    const bound = new google.maps.LatLngBounds(
      new google.maps.LatLng(
        selectedCity.properties.bound.minLat,
        selectedCity.properties.bound.minLng
      ),
      new google.maps.LatLng(
        selectedCity.properties.bound.maxLat,
        selectedCity.properties.bound.maxLng
      )
    );

    return (
      becEvcs?.filter((evc) =>
        bound.contains(new google.maps.LatLng(evc.latitude, evc.longitude))
      ) ?? []
    );
  }, [becEvcs, selectedCity]);

  const togglePoiFilter = useCallback((filter: string) => {
    setPoiFilters((filters) => {
      if (filters.includes(filter)) {
        return filters.filter((f) => f !== filter);
      }
      return [...filters, filter];
    });
  }, []);

  const becEvcsOnGround = useMemo(() => {
    if (!becEvcs) return [];
    return becEvcs.filter(
      (evc) =>
        evc.projectPhase === "Operation" || evc.projectPhase === "Commissioning"
    );
  }, [becEvcs]);

  const becEvcsWip = useMemo(() => {
    if (!becEvcs) return [];
    return becEvcs.filter(
      (evc) =>
        evc.projectPhase !== "Operation" && evc.projectPhase !== "Commissioning"
    );
  }, [becEvcs]);

  return (
    <SiteSelectionEvcDataContext.Provider
      value={{
        becEvcs,
        competitorsEvcs,
        filterPois,
        poiFilters,
        togglePoiFilter,
        filteredPois,
        filteredCompetitorsEvcs,
        becEvcsOnGround,
        becEvcsWip,
        filteredBecEvcs,
        bounds,
        setBounds,
        cityStats,
        provinceStats,
        regionStats,
        showCef1,
        showCef2,
        setCef1: setShowCef1,
        setCef2: setShowCef2,
        showBecEvcs,
        showCompetitorEvcs,
        setShowBecEvcs,
        setShowCompetitorEvcs,
        zoom,
        setZoom
      }}
    >
      {children}
    </SiteSelectionEvcDataContext.Provider>
  );
}

const useSiteSelectionEvcDataContext = () => {
  const context = useContext(SiteSelectionEvcDataContext);
  if (context === undefined) {
    throw new Error(
      "useSiteSelectionEvcDataContext must be used within a SiteSelectionEvcDataContextProvider"
    );
  }
  return context;
};

export {
  SiteSelectionEvcDataContext,
  SiteSelectionEvcDataContextProvider,
  useSiteSelectionEvcDataContext
};

