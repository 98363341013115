import { gql, useLazyQuery } from "@apollo/client";

export const CHARGE_ACTIVITIES_LAST_NOT_COMPLETED = gql`
  query ChargeActivitiesLastNotCompleted($evseUid: String = "") {
    Activities(
      filter: { type: ["CHARGE"], completed: no, evseUid: $evseUid }
      pagination: { limit: 1 }
      sort: { order: desc, field: startedAt }
    ) {
      results {
        activity {
          type
          uid
          status
          startedAt
          evseUid
          transactionUid
          completed
        }
      }
    }
  }
`;
export const useChargeActivitiesLastNotCompleted = () => {
  const [
    getChargeActivitiesLastNotCompleted,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(CHARGE_ACTIVITIES_LAST_NOT_COMPLETED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getChargeActivitiesLastNotCompleted,
    error,
    activities: data?.Activities?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
