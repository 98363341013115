/** @format */

import { NetworkStatus, useLazyQuery, useQuery } from "@apollo/client";
import { Roles } from "@bepower/greta-types";
import { t } from "i18next";
import { concat } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  BooleanCustom,
  Evc,
  EvcAggregableField,
  EvcFilterParams,
  InputMaybe,
} from "../../../generated/graphql";
import { EvcFilter } from "../../../../../pages/infrastracture/EVCs/components/EvcsList/EvcFilters";
import { useGetEvsesShort } from "../../../../../pages/infrastracture/gql/queries/useGetEvsesShort";
import {
  Box,
  Button,
  Card,
  ColumnShape,
  LoaderWithFallback,
  Popover,
  SimpleLoader,
  SortOrder,
  Typography,
} from "../../../../UI";
import Search from "../../../../assets/svg/Search";
import { FiltersList } from "../../../../components/FiltersList";
import { SmartTable } from "../../../../components/SmartTable";
import { TableContentSetting } from "../../../../components/TableContentSetting";
import { SingleCheckboxFilter } from "../../../../components/table-filter/SingleCheckboxFilter";
import { convertSearchParamIntoArray } from "../../../../helpers";
import { getStatusColor } from "../../../../helpers/colorHelper";
import { SectionList } from "../../../../layout";
import UserService from "../../../../services/UserServices";
import { GET_EVCS } from "../../useGetEVCs";

type Props = {
  width?: string;
  maxWidth?: string;
  local?: boolean;
  filter?: InputMaybe<EvcFilterParams>;
};
type Column = ColumnShape<Evc>;

export const EvcsTable: React.FC<Props> = ({
  width = "100%",
  maxWidth = "100%",
  local = true,
  filter,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenTableSetting, setIsOpenTableSetting] = useState<boolean>(false);
  const pageRef = useRef<number>(1);
  const tableRef = React.useRef<any>();
  const text = searchParams?.get("text");
  const evcSort = searchParams?.get("evcSort");

  const defaultColumns: Column[] = useMemo(
    () => [
      {
        key: "label", // FIXME: this is EVC not EVSE
        title: "label",
        dataKey: "label",
        sortable: true,

        width: 120,
        align: "center",
        resizable: true,
        cellRenderer: ({ column, rowData, cellData }: any) => (
          <Box onClick={(e: any) => e.stopPropagation()}>
            <CellWithEvses column={column} rowData={rowData} />
          </Box>
        ),
      },

      {
        key: "status",
        title: "status",
        dataKey: "status",
        width: 120,
        align: "left",
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Typography
              fontWeight={600}
              variant={"caption10"}
              color={getStatusColor(String(rowData?.status))}
            >
              {rowData?.status}
            </Typography>
          );
        },
        headerRenderer: ({ column }) => {
          return (
            <EvcFilter column={column} field={EvcAggregableField.Status} />
          );
        },
      },

      {
        key: "assetStatus",
        title: "asset status",
        dataKey: "asset Status",
        width: 160,
        align: "left",
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Typography
              variant={"caption10"}
              color={getStatusColor(String(rowData?.assetStatus))}
            >
              {rowData?.assetStatus}
            </Typography>
          );
        },
        headerRenderer: ({ column }) => {
          return (
            <EvcFilter column={column} field={EvcAggregableField.AssetStatus} />
          );
        },
      },
      {
        key: "vendor",
        title: "vendor",
        dataKey: "vendor",
        width: 120,
        align: "center",
        resizable: true,
        headerRenderer: ({ column }) => {
          return (
            <EvcFilter column={column} field={EvcAggregableField.Vendor} />
          );
        },
      },
      {
        key: "model",
        title: "model",
        dataKey: "model",
        flexGrow: 1,
        width: 120,
        resizable: true,
        headerRenderer: ({ column }) => {
          return <EvcFilter column={column} field={EvcAggregableField.Model} />;
        },
      },
      {
        key: "simulator",
        title: "simulator",
        dataKey: "simulator",
        dataGetter: ({ rowData }) => {
          return rowData?.simulator ? "Yes" : "No";
        },
        hidden:
          !UserService.hasResourceRole([Roles.Simulator], "appsync") || !local,
        flexGrow: 1,
        width: 120,
        resizable: true,
        headerRenderer: ({ column }) => {
          return (
            <SingleCheckboxFilter
              column={column}
              searchValue="Evc-simulator"
              loading={false}
              options={["yes"]}
            />
          );
        },
      },
      {
        key: "serialNumber",
        title: "serial number",
        dataKey: "serialNumber",
        flexGrow: 1,
        width: 120,
        resizable: true,
        sortable: true,
      },
      {
        key: "ip",
        title: "ip",
        dataKey: "ip",
        flexGrow: 1,
        width: 120,
        resizable: true,
        sortable: true,
      },
      {
        key: "projectId",
        title: "project id",
        dataKey: "projectId",
        flexGrow: 1,
        width: 120,
        resizable: true,
        dataGetter: ({ rowData }: any) => {
          return rowData?.Location?.projectId;
        },
      },
      {
        key: "floorLevel",
        title: "floorlevel",
        dataKey: "floorLevel",
        flexGrow: 1,
        width: 120,
        resizable: true,
      },

      {
        key: "address",
        title: "address",
        dataKey: "address",
        width: 230,
        align: "left",
        resizable: true,
        dataGetter: ({ rowData }) => {
          return (
            <Typography variant={"caption10"}>
              {rowData?.Location?.label}
            </Typography>
          );
        },
      },
    ],
    [local]
  );

  const [columnKeys, setColumnKeys] = useState<string[]>(
    defaultColumns.map((column: any) => String(column.key))
  );

  const columns = defaultColumns.filter((column: any) =>
    columnKeys.some((key) => key === String(column.key))
  );
  const [
    getEvcsList,
    { loading, data, refetch, fetchMore, client, error, networkStatus },
  ] = useLazyQuery(GET_EVCS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    refetchWritePolicy: "overwrite",

    notifyOnNetworkStatusChange: true,
  });

  const onChangeSearchInput = (e: any) => {
    if (e.target.value) {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    } else {
      searchParams.set("text", "");
      setSearchParams(searchParams);
    }
  };

  const handleRefresh = useCallback(() => {
    tableRef?.current?.scrollToTop(0);
    pageRef.current = 1;
    refetch();
  }, [refetch]);

  const handleDownloadEvcs = () => {
    //
  };
  const handleSortColumn = useCallback(
    ({ key, order }: { key: React.Key; order: SortOrder }) => {
      searchParams.set(`evcSort`, `${key}-${order}`);

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
  const handleClickRow = useCallback(
    (row: any) => {
      navigate(`/evcs/${row?.uid}`);
    },
    [navigate]
  );

  useEffect(() => {
    pageRef.current = 1;

    getEvcsList({
      variables: {
        pagination: {
          limit: 20,
          page: pageRef.current,
        },
        filter: {
          ...(text ? { text: text } : {}),
          status: convertSearchParamIntoArray(
            searchParams.get("Evc-status"),
            " OR "
          ),
          vendor: convertSearchParamIntoArray(
            searchParams.get("Evc-vendor"),
            " OR "
          ),
          model: convertSearchParamIntoArray(
            searchParams.get("Evc-model"),
            " OR "
          ),
          simulator: searchParams.get("Evc-simulator") as BooleanCustom,
          assetStatus: convertSearchParamIntoArray(
            searchParams.get("Evc-assetStatus"),
            " OR "
          ),
          ...filter,
        },
        ...(evcSort
          ? {
              sort: {
                field: evcSort?.split("-")?.[0] as any,
                order: evcSort?.split("-")?.[1] as any,
              },
            }
          : {}),
      },
    });
    return () => {
      client.cache.evict({
        id: "ROOT_QUERY",
        fieldName: "EVCs",
        broadcast: true,
      });
      client.cache.gc();
    };
  }, [client.cache, evcSort, filter, getEvcsList, searchParams, text]);

  return (
    <SectionList maxWidth={width} width={width}>
      <FiltersList
        size="small"
        textInputProps={{
          onChange: onChangeSearchInput,
          defaultValue: text || "",
        }}
        onClickRefreshButton={handleRefresh}
        downloadButtonProps={{
          onClick: handleDownloadEvcs,
          loading: false,
        }}
        tableSettingProps={{
          isOpen: isOpenTableSetting,
          setIsOpen: setIsOpenTableSetting,
          children: (
            <>
              <TableContentSetting
                columnKeys={columnKeys}
                setColumnKeys={setColumnKeys}
                defaultColumnsKeys={defaultColumns.map(({ title }) =>
                  String(title)
                )}
              />
            </>
          ),
        }}
        extraProps={
          <Button fixedSize onClick={() => navigate("/tariffs/find")} ml={2}>
            <Search size="4" />
          </Button>
        }
      />
      {/* <TableDataDetailsHeader
        total={data?.EVCs?.total || 0}
        fetched={data?.EVCs?.results?.length || 0}
        page={pageRef.current}
      /> */}
      <Card
        minWidth={300}
        overflow="hidden"
        bg="white"
        position="relative"
        display="flex"
        width="100%"
        height="100%"
        style={{ borderRadius: 0 }}
      >
        <SmartTable
          tableRef={tableRef}
          fixed={true}
          columns={columns as any}
          forceUpdateTable={networkStatus === NetworkStatus.refetch}
          onEndReached={() => {
            if (data?.EVCs && data?.EVCs?.total > data?.EVCs?.results?.length) {
              pageRef.current += 1;

              fetchMore({
                variables: {
                  pagination: {
                    page: pageRef.current,
                    limit: 20,
                  },
                },
                updateQuery(previousQueryResult, options) {
                  return {
                    EVCs: {
                      results: concat(
                        previousQueryResult?.EVCs?.results,
                        options.fetchMoreResult.EVCs.results
                      ),
                      total: options.fetchMoreResult?.EVCs?.total,
                      __typename: options.fetchMoreResult.EVCs?.__typename,
                      pagination: options.fetchMoreResult.EVCs?.pagination,
                    },
                  };
                },
              });
            }
          }}
          loadingMore={loading}
          data={
            networkStatus === NetworkStatus.refetch
              ? []
              : data?.EVCs.results || []
          }
          selectedRowProps={{
            onRowChange: handleClickRow,
            selectedRow: "",
          }}
          onColumnSort={handleSortColumn}
          sortBy={{
            key: searchParams.get(`evcSort`)?.split("-")[0] as string,
            order:
              (searchParams.get(`evcSort`)?.split("-")[1] as SortOrder) ||
              "asc",
          }}
        />
      </Card>
    </SectionList>
  );
};

const evseColumns: Column[] = [
  {
    key: "label",
    title: "evse",
    dataKey: "label",
    width: 120,
    align: "left",
    resizable: true,
    flexGrow: 1,
  },
  {
    key: "status",
    title: "status",
    dataKey: "status",
    width: 140,
    align: "left",
    resizable: true,
    flexGrow: 1,
  },
];

const CellWithEvses = ({ column, rowData }: { column: any; rowData: Evc }) => {
  const [status, setStatus] = useState<boolean>(false);
  const { EVSEs, loading, getEvses } = useGetEvsesShort();
  const navigate = useNavigate();
  useEffect(() => {
    if (status && rowData?.uid) {
      getEvses({
        variables: {
          filter: {
            evcUid: rowData.uid,
          },
        },
      });
    }
  }, [getEvses, rowData.uid, status]);
  const onRowClick = (row: any) => {
    navigate(`/evses/${row?.uid}`);
  };

  return (
    <Popover position="right" setStatus={setStatus} status={status}>
      <Button onClick={(e: any) => e.stopPropagation()}>
        {t(rowData.label as string)} ({rowData?.evses?.length})
      </Button>
      <Card
        zIndex={330000000000}
        width={500}
        overflow="hidden"
        display="flex"
        height="250px"
        style={{ borderRadius: 0 }}
      >
        <LoaderWithFallback
          loading={loading}
          hasError={!EVSEs}
          errorMsg="No data found"
          LoadingMsg={<SimpleLoader />}
        >
          {" "}
          <SmartTable
            data={EVSEs || []}
            fixed={false}
            columns={evseColumns as any}
            selectedRowProps={{
              selectedRow: "",
              onRowChange: onRowClick,
            }}
          />
        </LoaderWithFallback>
      </Card>
    </Popover>
  );
};
