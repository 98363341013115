import { t } from "i18next";
import { Breadcrumbs, Crumb, Flex, StyledLink } from "../../../shared/UI";
import VerticalScrollContainer from "../../../shared/UI/VerticalScrollContainer";
import { SectionContentTop, SectionLayout } from "../../../shared/layout";
import { SectionHead } from "../../../shared/layout/SectionHead";
import { TariffInputs } from "./TariffInputs";

const TariffModel = () => {
  return (
    <SectionLayout label="Create New Tariff">
      <SectionContentTop fullHeight>
        <SectionHead>
          <Flex fullSize alignItems="left" px={2} justifyContent="center">
            <Breadcrumbs>
              <Crumb>
                <StyledLink to="/tariffs">{t("tariffs")}</StyledLink>
              </Crumb>
              <Crumb>
                <StyledLink to="#">{t("new")}</StyledLink>
              </Crumb>
            </Breadcrumbs>
          </Flex>
        </SectionHead>
        <VerticalScrollContainer>
          <Flex fullSize>
            <TariffInputs type="NEW" />
          </Flex>
        </VerticalScrollContainer>
      </SectionContentTop>
    </SectionLayout>
  );
};

export default TariffModel;
