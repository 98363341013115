import { gql, useMutation } from "@apollo/client";
import { GET_PLATFORMS } from "../../../../shared/gql/queries/useGetPlatforms";
import { GET_PLATFORM } from "./../queries/useGetPlatform";

export const PUBLISH_OCPI_PLATFORM = gql`
  mutation publishOcpiPlatform($input: OcpiPlatformInput!) {
    publishOcpiPlatform(input: $input) {
      info
      result
      statusCode
      uid
    }
  }
`;

export const usePublishOcpiPlatform = () => {
  const [publishOcpiPlatform, { data, loading, error }] = useMutation<any>(
    PUBLISH_OCPI_PLATFORM,
    {
      // refetchQueries: [GET_PLATFORM, GET_PLATFORMS]
    }
  );
  return {
    publishOcpiPlatform,
    error,
    data,
    loadingPublishOcpiPlatform: loading,
  };
};
