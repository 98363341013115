/** @format */

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Box,
  Breadcrumbs,
  Card,
  Crumb,
  Flex,
  StyledLink,
  Typography,
} from "../../../../shared/UI";
import { Tab, Tabs } from "../../../../shared/UI/Tabs";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import { SectionContent } from "../../../../shared/layout";
import { useGetLocation } from "../../../../shared/gql/queries/useGetLocation";
import { Roles } from "@bepower/greta-types";
import { format } from "date-fns";
import { t } from "i18next";
import QueryResult from "../../../../shared/components/QueryResult";

export const Location: React.FC = () => {
  const { uid: locationUid } = useParams();
  const navigate = useNavigate();

  const navigateToTab = (e: any, tab: Tab) => {
    navigate(tab.path);
  };
  const { loading, location, getLocation, refetch, error } = useGetLocation();
  const { currentCpo } = useSelector((state: any) => state.userSettings);

  const tabs = [
    { label: "DETAILS", path: "location-details", id: 1 },
    { label: "EVC-EVSE", path: "location-evc-evse", id: 2 },
    {
      label: "SESSIONS",
      path: "location-sessions",
      id: 3,
      roles: [Roles.Session],
    },
  ];

  useEffect(() => {
    if (locationUid) {
      getLocation({
        variables: {
          uid: locationUid,
        },
      });
    }
  }, [currentCpo, getLocation, locationUid]);
  return (
    <QueryResult Loading={loading} data={location} error={error}>
      <SectionContent>
        <Flex fullSize position={"relative"}>
          <Breadcrumbs>
            <Crumb>
              <StyledLink to="/locations">Locations</StyledLink>
            </Crumb>
            <Crumb>
              <StyledLink to="#">{location?.label}</StyledLink>
            </Crumb>
          </Breadcrumbs>
          <Box p={2}>
            <Typography variant={"title20"}>{location?.label}</Typography>
            <Box>
              <Typography pr={2} variant={"caption20"}>
                {location?.Project?.name}
              </Typography>
              <Badge>{location?.projectId}</Badge>
            </Box>
            <Box>
              <Typography variant={"caption20"}>{location?.address}</Typography>
            </Box>
            <Box>
              <Typography variant={"caption20"}>
                {location?.Project?.cod
                  ? format(location?.Project?.cod, "dd/MM/yyyy")
                  : "---"}
              </Typography>
            </Box>
            <Box>
              {location?.openingTimes.twentyFourSeven ? (
                <Typography variant={"caption20"}>
                  24/7:
                  {location?.openingTimes.twentyFourSeven ? "Yes" : "No"}
                </Typography>
              ) : (
                <Typography
                  tooltipText={
                    <Card width="100%" height={"100%"} p={3} bg="white">
                      {location?.openingTimes?.regularHours?.map((item) => {
                        return (
                          <Box mb={1}>
                            <Typography>
                              {t("day")} {item.weekday} :
                            </Typography>
                            <Typography>
                              {t("from")}
                              {item.periodBegin}
                              {t("to")}
                              {item.periodEnd}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Card>
                  }
                  variant={"caption20"}
                >
                  {location?.openingTimes?.regularHours?.length} {t("days")}
                </Typography>
              )}
            </Box>
            <Box>
              {location?.Project?.thirdPartiesMaintenance ? (
                <Typography>
                  {t("third parties maintenance")}:
                  {location?.Project?.thirdPartiesMaintenance ? "Yes" : ""}
                </Typography>
              ) : null}
            </Box>
            <Box>
              {location?.Project?.thirdPartiesOwnership ? (
                <Typography variant={"caption20"}>
                  {t("third parties ownership")}:
                  {location?.Project?.thirdPartiesMaintenance ? "Yes" : ""}
                </Typography>
              ) : null}
            </Box>
            <Box>
              {location?.EVCs?.results?.[0]?.isEhub ? (
                <Typography variant={"caption20"}>
                  IsEhub :{location?.EVCs?.results?.[0]?.isEhub ? "Yes" : ""}
                </Typography>
              ) : null}
            </Box>
          </Box>

          <Tabs onTabClick={navigateToTab} tabs={tabs} />
          <VerticalScrollContainer bg="#eee">
            <Outlet
              context={{
                location: location,
                loading: loading,
                refetch: refetch,
              }}
            />
          </VerticalScrollContainer>
        </Flex>
      </SectionContent>
    </QueryResult>
  );
};
