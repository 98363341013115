import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetPlatform } from "../gql";

export const usePlatform = () => {
  const { selectedPlatform } = useSelector((state: any) => state.platform);
  const {
    getPlatform,
    loading: loadingGetPlatform,
    data: platform,
    refetch: refetchPlatform,
    networkStatus,
  } = useGetPlatform();
  useEffect(() => {
    getPlatform({
      variables: {
        uid: selectedPlatform?.uid,
      },
    });
  }, [getPlatform, selectedPlatform]);

  return {
    loadingGetPlatform,
    getPlatform,
    platform,
    networkStatus,
    refetchPlatform,
  };
};
