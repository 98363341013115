/** @format */

import { Sdr, SdrSortField } from "@bepower/greta-types";
import moment from "moment";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, ColumnShape, SortOrder, Typography } from "../../../shared/UI";
import { DefaultProps } from "../../../shared/UI/helpers";
import { FiltersList } from "../../../shared/components/FiltersList";
import { SmartTable } from "../../../shared/components/SmartTable";
import { TableContentSetting } from "../../../shared/components/TableContentSetting";
import { SectionList } from "../../../shared/layout";
import { useGetSdrs } from "../../../shared/gql/queries/useGetSdrs";
import { getStatusColor } from "../../../shared/helpers/colorHelper";

type Column = ColumnShape<Sdr>;

const SdrsColumns: Column[] = [
  {
    key: "EVC",
    title: "EVC",
    dataKey: "EVC",
    width: 100,
    align: "center",
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography fontWeight="bold">
          {rowData?.activities?.[0]?.evc?.label}
        </Typography>
      );
    },
  },
  {
    key: "EVSE",
    title: "evse",
    dataKey: "EVSE",
    flexGrow: 2,
    width: 100,
    resizable: true,
    sortable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography fontWeight="bold">
          {rowData?.activities?.[0]?.evc?.evse?.label}
        </Typography>
      );
    },
  },
  {
    key: "Type",
    title: "type",
    dataKey: "Type",
    flexGrow: 1,
    width: 100,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography color={getStatusColor(rowData?.activities?.[0]?.type)}>
          {rowData?.activities?.[0]?.type}
        </Typography>
      );
    },
  },
  {
    key: "Token",
    title: "token",
    dataKey: "Token",
    width: 250,
    resizable: true,
    flexGrow: 2,
    sortable: true,
    align: "left",
    dataGetter: ({ rowData }) => {
      return <Typography>{rowData?.token?.label}</Typography>;
    },
  },
  {
    key: "Protocol",
    title: "protocol",
    dataKey: "Protocol",
    width: 100,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return <Typography>{rowData?.activities?.[0]?.evc?.protocol}</Typography>;
    },
  },
  {
    key: "Start",
    title: "start",
    dataKey: "Start",
    width: 200,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography>
          {moment(rowData.sessionStart).format("DD/MM/yyyy HH:mm:ss")}
        </Typography>
      );
    },
  },
  {
    key: "Stop",
    title: "stop ",
    dataKey: "Stop",
    width: 200,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography>
          {moment(rowData.sessionStop).format("DD/MM/yyyy HH:mm:ss")}
        </Typography>
      );
    },
  },
  {
    key: "kWh",
    title: "kWh",
    dataKey: "kWh",
    width: 100,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography>
          {rowData?.activities?.[0]?.consumptions?.energy}
        </Typography>
      );
    },
  },
  {
    key: "Session Uid",
    title: "session uid",
    dataKey: "Session Uid",
    width: 400,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return <Typography>{rowData?.sessionUid}</Typography>;
    },
  },
];

type SdrsListProps = {
  width: string;
  containerProps?: DefaultProps;
};

export const SdrsList: React.FC<PropsWithChildren<SdrsListProps>> = ({
  // Sdrs: Sdr[];
  // loading: boolean;
  // fetchMore: any;
  width,
  containerProps,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [columnKeys, setColumnKeys] = useState<string[]>(
    SdrsColumns.map((column) => String(column.key))
  );
  const [isOpenTableSetting, setIsOpenTableSetting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const SdrSort = searchParams.get("SdrSort");

  const columns = SdrsColumns.filter((column) =>
    columnKeys.some((key) => key === String(column.key))
  );

  const text = searchParams.get("text");
  const { loading, fetchMore, refetch, SDRs, getSDRs } = useGetSdrs();

  useEffect(() => {
    getSDRs({
      variables: {
        pagination: {
          limit: 20,
        },
        filter: {
          text: text,
        },

        sort: {
          field: SdrSort?.split("-")?.[0] as any,
          order: SdrSort?.split("-")?.[1] as any,
        },
      },
    });
  }, [getSDRs, text, SdrSort]);

  const handleSortColumn = useCallback(
    ({ key, order }: { key: any; order: "asc" | "desc" }) => {
      searchParams.set(`SdrSort`, `${key}-${order}`);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
  const tableRef = React.useRef<any>();
  const handleRefresh = useCallback(() => {
    tableRef?.current?.scrollToTop(0);
    refetch();
  }, [tableRef, refetch]);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
  const navigate = useNavigate();
  const handleClickRow = (row: any) => {
    navigate(`/sessions/${row?.uid}/session-sdr`);
  };

  return (
    <SectionList maxWidth={width} width={width} {...containerProps}>
      <FiltersList
        size="small"
        downloadButtonProps={{
          loading: false,
        }}
        tableSettingProps={{
          isOpen: isOpenTableSetting,
          setIsOpen: setIsOpenTableSetting,
          children: (
            <TableContentSetting
              columnKeys={columnKeys}
              setColumnKeys={setColumnKeys}
              defaultColumnsKeys={SdrsColumns.map(({ key }) => String(key))}
            />
          ),
        }}
        onClickRefreshButton={handleRefresh}
        textInputProps={{
          onChange: handleChangeInput,
          defaultValue: searchParams?.get("text") || "",
        }}
      />
      <Card
        minWidth={300}
        overflow="hidden"
        bg="white"
        position="relative"
        display="flex"
        width="100%"
        height="100%"
        borderRadius={5}
      >
        <SmartTable
          tableRef={tableRef}
          fixed
          rowHeight={50}
          columns={columns as any}
          onEndReached={fetchMore}
          loading={loading}
          data={SDRs || []}
          selectedRowProps={{
            onRowChange: handleClickRow,
            selectedRow: "",
          }}
          onColumnSort={handleSortColumn}
          sortBy={{
            key: searchParams.get(`Sdrsort`)?.split("-")[0] as string,
            order:
              (searchParams.get(`SdrSort`)?.split("-")[1] as SortOrder) ||
              "asc",
          }}
        />
      </Card>
    </SectionList>
  );
};
