import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import LocalStorageService from "../../services/LocalStorageService";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      "https://8wlwyrznvl.execute-api.eu-central-1.amazonaws.com/test/webgui/api",
    prepareHeaders: async (headers) => {
      const user = await LocalStorageService.get("jwt-token");
      const hasUser = !!user;
      if (hasUser) {
        headers.set("Authorization", `Bearer ${user}`);
      }
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: "api",
  tagTypes: ["User", "Auth"],
});
