import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Input,
  InputLabel,
  LoadingLayer,
  Select,
  SimpleLoader,
  Switch,
  Typography,
} from "../../../../shared/UI";
import { platformSchema } from "./platformSchema";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Platform,
  platformFlavor,
  platformStatusOptions,
  platformVersion,
  PLATFORM_PROTOCOL_FORM_OPTIONS,
} from "../../../../core/model/platform.model";
import { MESSAGES } from "../../../../shared/messages/messages";
import RenderOnRole from "../../../../shared/UI/RenderOnRole";
import { usePublishOcpiPlatform, usePublishOicpPlatform } from "../../gql";
import { useGetPlatformParties } from "../../gql/queries/useGetPlatformParties";
import UserService from "./../../../../shared/services/UserServices";
import { t } from "i18next";
import { RedirectTo } from "../../../../shared/components/Redirect";
import { Roles } from "@bepower/greta-types";

type Props = {
  type?: "page" | "dialog";
  platform?: Platform;
};

function getObjectValue(defaultValue?: string) {
  if (!defaultValue) return null;
  return { label: defaultValue, value: defaultValue };
}

const PlatformInputs: React.FC<Props> = ({ platform }) => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState<boolean>(false);
  const canEdit = UserService?.hasResourceRole([Roles.PlatformWrite], "appsync");
  const {
    publishOcpiPlatform,
    data: OICPPublished,
    loadingPublishOcpiPlatform,
  } = usePublishOcpiPlatform();
  const {
    loadingPublishOicpPlatform,
    publishOicpPlatform,
    data: OCPIPublished,
  } = usePublishOicpPlatform();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(platformSchema),
    shouldFocusError: true,
  });

  const { getPlatformPartiesQuery, platformParties } = useGetPlatformParties();

  useEffect(() => {
    if (platform) {
      reset({
        name: platform?.name,
        entryPointUrl: platform?.entryPointUrl,
        protocol: getObjectValue(platform?.protocol),
        status: getObjectValue(platform?.status),
        flavor: getObjectValue(platform?.flavor),
        version: platformVersion?.find(
          (item: any) => item.label === platform.version
        ),
        hubPartyUid: {
          label: platform?.HubParty?.name,
          value: platform?.HubParty?.uid,
        },
        offlineTokenIn: platform?.offlineTokenIn,
        offlineTokenOut: platform?.offlineTokenOut,
        pullTokens: platform?.pullTokens,
        needsReliablePush: platform?.needsReliablePush,
        skipCredentialsRefresh: platform?.skipCredentialsRefresh,
        asyncPush: platform?.asyncPush
      });
    }
  }, [platform, reset]);

  const protocolType = watch("protocol")?.value;
  const onSubmitForm = useCallback(
    (formData: any) => {
      const { flavor, version, hubPartyUid, protocol, status, ...data } =
        formData;
      protocolType === "OCPI"
        ? publishOcpiPlatform({
            variables: {
              input: {
                flavor: flavor?.value,
                version: version?.value,
                hubPartyUid: hubPartyUid?.value,
                protocol: protocol.value,
                status: status.value,
                ...data,
                ...(platform?.uid ? { uid: platform.uid } : {}),
              },
            },
          }).then((response) => {
            if (response?.data) {
              toast.success(
                platform
                  ? MESSAGES.PLATFORM.success.edit
                  : MESSAGES.PLATFORM.success.create
              );
              setRedirect(true);
              setTimeout(() => navigate("/platforms"), 3000);
            }
          })
        : publishOicpPlatform({
            variables: {
              input: {
                protocol: protocol.value,
                status: status.value,
                name: data?.name,
                needsReliablePush: data.needsReliablePush,
                entryPointUrl: data?.entryPointUrl,
                ...(platform?.uid ? { uid: platform.uid } : {}),
              },
            },
          }).then((response) => {
            if (response?.data) {
              toast.success(
                platform
                  ? MESSAGES.PLATFORM.success.edit
                  : MESSAGES.PLATFORM.success.create
              );
              setRedirect(true);
              setTimeout(() => navigate("/platforms"), 3000);
            }
          });
    },
    [protocolType, publishOcpiPlatform, publishOicpPlatform]
  );

  useEffect(() => {
    protocolType === "OCPI" &&
      getPlatformPartiesQuery({
        variables: {
          pagination: {
            limit: 10000,
            page: 1,
          },
        },
      });
  }, [getPlatformPartiesQuery, protocolType]);

  return (
    <Flex p={[2, 2, 2, 2, 4, 4]} display="flex" position="relative">
      {redirect ? <RedirectTo path="platforms" /> : null}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Flex flexDirection="row" mb={2} width="100%">
          <Flex flex={1} mr={2}>
            <InputLabel children="name" required />
            <Input
              disabled={!canEdit}
              error={errors?.name?.message}
              placeholder="Name"
              {...register("name")}
            />{" "}
          </Flex>
          <Flex flex={1}>
            <InputLabel children="protocol" required />
            <Controller
              name="protocol"
              control={control}
              render={({ field }) => (
                <Select
                  isDisabled={!!platform || !canEdit}
                  placeholder="OCPI"
                  options={PLATFORM_PROTOCOL_FORM_OPTIONS}
                  error={errors.protocol ? "Protocol is required field" : ""}
                  {...field}
                />
              )}
            />
          </Flex>
          <Flex flex={1} mx={2}>
            <InputLabel children="status" required />
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  isDisabled={!canEdit}
                  placeholder="E.g.AGREED"
                  controlShouldRenderValue
                  options={platformStatusOptions}
                  error={errors.status ? "Status is required field" : ""}
                  {...field}
                  {...field}
                />
              )}
            />
          </Flex>

          <Flex flex={1}>
            <InputLabel children="entry point url" required />
            <Input
              disabled={!canEdit}
              error={errors?.entryPointUrl?.message}
              placeholder="Entry Point Url"
              {...register("entryPointUrl")}
            />
          </Flex>
        </Flex>
        {protocolType === "OICP" ? (
          <Flex>
            <Flex flex={1} mt={2}>
              <InputLabel children="needs reliable push" />
              <Switch disabled={!canEdit} {...register("needsReliablePush")} />
            </Flex>
          </Flex>
        ) : null}

        {protocolType === "OCPI" ? (
          <Flex width="100%" my={4}>
            <Flex flexDirection="row" width="100%">
              <Flex flex={1} mr={2}>
                <InputLabel children="Offline token in" required={false} />
                <Input
                  disabled={!canEdit}
                  error={errors?.offlineTokenIn?.message}
                  placeholder={String(
                    t("click the button to generate un token")
                  )}
                  {...register("offlineTokenIn")}
                  // suffix={canEdit ? "Generate" : null}
                  suffixProps={{
                    bg: "primary",
                    alignItems: "center",
                    color: "white",
                    px: 2,
                    height: "auto",
                    onClick: () => setValue("offlineTokenIn", uuidv4()),
                  }}
                />
                {canEdit ? (
                  <Box mt={2}>
                    <Button
                      onClick={() => setValue("offlineTokenIn", uuidv4())}
                    >
                      {t("generate")}
                    </Button>
                  </Box>
                ) : null}
              </Flex>
              <Flex flex={1}>
                <InputLabel children="offline token out" required={false} />
                <Input
                  disabled={!canEdit}
                  shape="default"
                  // suffix={canEdit ? "Generate" : null}
                  suffixProps={{
                    bg: "primary",
                    alignItems: "center",
                    color: "white",
                    px: 2,
                    height: "auto",
                    onClick: () => setValue("offlineTokenOut", uuidv4()),
                  }}
                  error={errors?.offlineTokenOut?.message}
                  placeholder={String(
                    t("click the button to generate un token")
                  )}
                  {...register("offlineTokenOut")}
                />
                {canEdit ? (
                  <Box mt={2}>
                    <Button
                      onClick={() => setValue("offlineTokenOut", uuidv4())}
                    >
                      {t("generate")}
                    </Button>
                  </Box>
                ) : null}
              </Flex>
            </Flex>
            <Card
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              my={4}
              bg="#ECE9F1"
              color="white"
              p={2}
            >
              <Flex flex={1} justifyContent="space-between">
                <InputLabel children="pull tokens" />
                <Switch disabled={!canEdit} {...register("pullTokens")} />
              </Flex>
              <Flex flex={1} justifyContent="space-between">
                <InputLabel children="async push" />
                <Switch disabled={!canEdit} {...register("asyncPush")} />
              </Flex>

              <Flex mx={3} flex={1}>
                <InputLabel children="skip credentials refresh" />
                <Switch
                  disabled={!canEdit}
                  {...register("skipCredentialsRefresh")}
                />
              </Flex>

              <Flex flex={1}>
                <InputLabel children="needs Reliable Push" />
                <Switch
                  disabled={!canEdit}
                  {...register("needsReliablePush")}
                />
              </Flex>
            </Card>
            <Flex flexDirection="row" py={3} justifyContent="space-between">
              <Flex flex={1}>
                <InputLabel children="Party Hub" />
                <Controller
                  name="hubPartyUid"
                  control={control}
                  render={({ field }) => (
                    <Select
                      menuPosition="fixed"
                      isDisabled={!canEdit}
                      options={platformParties?.map(
                        ({ name, uid }: { name: string; uid: string }) => ({
                          label: name,
                          value: uid,
                        })
                      )}
                      {...field}
                    />
                  )}
                />
              </Flex>
              <Flex flex={1} mx={2}>
                <InputLabel children="Flavor" />
                <Controller
                  name="flavor"
                  control={control}
                  render={({ field }) => (
                    <Select
                      menuPosition="fixed"
                      isDisabled={!canEdit}
                      options={platformFlavor}
                      {...field}
                    />
                  )}
                />
              </Flex>
              <Flex flex={1}>
                <InputLabel children="version" required />
                <Controller
                  name="version"
                  control={control}
                  render={({ field }) => (
                    <Select
                      menuPosition="fixed"
                      isDisabled={!canEdit}
                      error={
                        errors?.version ? "version is a required field" : ""
                      }
                      options={platformVersion}
                      // placeholder="E.g.AGREED"
                      {...field}
                    />
                  )}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : null}
        <RenderOnRole roles={[Roles.PlatformWrite]}>
          <Button
            block
            mt={2}
            loading$={loadingPublishOcpiPlatform || loadingPublishOicpPlatform}
            onClick={handleSubmit(onSubmitForm)}
            $size="large"
          >
            {loadingPublishOcpiPlatform || loadingPublishOicpPlatform
              ? "Loading..."
              : "Save"}{" "}
          </Button>
        </RenderOnRole>
      </form>
    </Flex>
  );
};

export default PlatformInputs;
