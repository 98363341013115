import { gql,useMutation } from "@apollo/client"





export const PUBLISH_CONFIGURATION_CHANGE = gql(`
mutation publishConfigurationChange($input: ConfigurationChangeInput!) {
    publishConfigurationChange(input: $input) {
      requestUid
    }
  }
  `)
;
  export const usePublishConfigurationChange = () =>{
    const [publishConfigurationChange, { data, loading, error }] = 
    useMutation<any>(PUBLISH_CONFIGURATION_CHANGE);
    return{
        publishConfigurationChange,
        data,
        loading,
       error};
        
  
  };