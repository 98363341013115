import groupBy from "lodash/groupBy";
import { createContext, useContext, useEffect, useState } from "react";
import { Bound, GeoJSONFeature } from "../@types/geojson";
import { calculateBound } from "../utils/calculate-bounds";


export type CityStat = {
  COD_PROV: number;
  COD_UTS: number;
  COMUNE: string;
  DEN_PROV: string;
  DEN_UTS: string;
  SIGLA: string;
  COD_REG: number;
  DEN_REG: string;
  ac: number;
  dc: number;
  n_ac: number;
  n_dc: number;
  kwh_ac: number;
  kwh_dc: number;
};

export type ProvinceStat = {
  COD_PROV: number;
  COD_UTS: number;
  DEN_PROV: string;
  DEN_UTS: string;
  SIGLA: string;
  COD_REG: number;
  DEN_REG: string;
  ac: number;
  dc: number;
  n_ac: number;
  n_dc: number;
  kwh_ac: number;
  kwh_dc: number;
};

export type RegionStat = {
  COD_REG: number;
  DEN_REG: string;
  ac: number;
  dc: number;
  n_ac: number;
  n_dc: number;
  kwh_ac: number;
  kwh_dc: number;
};


export type RegionProperties = {
  Shape_Area: number;
  COD_REG: number;
  DEN_REG: string;
  stats: RegionStat;
  weight: number;
  bound: Bound;
};

export type ProvinceProperties = {
  Shape_Area: number;
  DEN_PROV: string;
  COD_PROV: number;
  COD_REG: number;
  DEN_UTS: string;
  SIGLA: string;
  stats: ProvinceStat;
  weight: number;
  bound: Bound;
};

export type CityProperties = {
  COD_RIP: number;
  COD_REG: number;
  COD_PROV: number;
  COD_CM: number;
  COD_UTS: number;
  PRO_COM: number;
  PRO_COM_T: string;
  COMUNE: string;
  COMUNE_A: string;
  CC_UTS: number;
  Shape_Leng: number;
  Shape_Area: number;
  stats: CityStat;
  weight: number;
  bound: Bound;
};

const buildFindFeature = (coords: google.maps.LatLngLiteral) => (f: GeoJSONFeature<any>) => {
  const c =
    f.geometry.type === "Polygon"
      ? [f.geometry.coordinates]
      : f.geometry.coordinates;

  const i = c.find((c) => {
    const poly = new google.maps.Polygon({
      paths: c[0].map((c) => ({ lat: c[1], lng: c[0] })),
    });
    return google.maps.geometry.poly.containsLocation(
      new google.maps.LatLng(coords),
      poly
    );
  });

  return !!i;
};

interface SiteSelectionGeoDataContextProps {
  regions: GeoJSONFeature<RegionProperties>[] | null;
  provinces: GeoJSONFeature<ProvinceProperties>[] | null;
  cities: GeoJSONFeature<CityProperties>[] | null;
  cityStats: { [key: string]: CityStat };
  provinceStats: { [key: number]: ProvinceStat };
  regionStats: { [key: number]: RegionStat };
  getGeoByCoordinates: (coords: google.maps.LatLngLiteral) => {
    region: GeoJSONFeature<RegionProperties> | null;
    province: GeoJSONFeature<ProvinceProperties> | null;
    city: GeoJSONFeature<CityProperties> | null;
  } | null;
}

const SiteSelectionGeoDataContext =
  createContext<SiteSelectionGeoDataContextProps>({
    regions: null,
    provinces: null,
    cities: null,
    cityStats: {},
    provinceStats: {},
    regionStats: {},
    getGeoByCoordinates: () => null,
  });

function SiteSelectionGeoDataContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {

  const [cityStats, setCityStats] = useState<{ [key: string]: CityStat }>({});
  const [provinceStats, setProvinceStats] = useState<{
    [key: number]: ProvinceStat;
  }>({});
  const [regionStats, setRegionStats] = useState<{
    [key: number]: RegionStat;
  }>({});

  const [regions, setRegions] = useState<
    GeoJSONFeature<RegionProperties>[] | null
  >(null);

  const [provinces, setProvinces] = useState<
    GeoJSONFeature<ProvinceProperties>[] | null
  >(null);
  const [cities, setCities] = useState<GeoJSONFeature<CityProperties>[] | null>(
    null
  );

  const getGeoByCoordinates = (coords: google.maps.LatLngLiteral) => {

    const r = regions?.find(buildFindFeature(coords));
    const p = provinces?.find(buildFindFeature(coords));
    const c = cities?.find(buildFindFeature(coords));

    if (!r || !p || !c) return null;

    return {
      region: r,
      province: p,
      city: c,
    };

  }


  useEffect(() => {
    fetch(`/data/city_stats.json`)
      .then((res) => res.json())
      .then((data: CityStat[]) => {
        const c = Object.fromEntries(data.map((d) => [d.COMUNE, d]));
        setCityStats(c);

        const prov = Object.fromEntries(
          Object.entries(groupBy(data, "COD_PROV")).map(([k, v]) => [
            k,
            v.slice(1).reduce(
              (acc, c) => ({
                COD_PROV: acc.COD_PROV,
                COD_UTS: 0,
                DEN_PROV: acc.DEN_PROV,
                DEN_UTS: acc.DEN_UTS,
                SIGLA: acc.SIGLA,
                COD_REG: acc.COD_REG,
                DEN_REG: acc.DEN_REG,
                ac: acc.ac + c.ac,
                dc: acc.dc + c.dc,
                n_ac: acc.n_ac + c.n_ac,
                n_dc: acc.n_dc + c.n_dc,
                kwh_ac: acc.kwh_ac + c.kwh_ac,
                kwh_dc: acc.kwh_dc + c.kwh_dc,
                COMUNE: "",
              }),
              v[0]
            ),
          ])
        );

        setProvinceStats(prov);

        const reg = Object.fromEntries(Object.entries(groupBy(prov, "COD_REG")).map(([k, v]) =>
          [k, v.reduce((acc, c) => ({
            COD_REG: c.COD_REG,
            DEN_REG: c.DEN_REG,
            ac: acc.ac + c.ac,
            dc: acc.dc + c.dc,
            n_ac: acc.n_ac + c.n_ac,
            n_dc: acc.n_dc + c.n_dc,
            kwh_ac: acc.kwh_ac + c.kwh_ac,
            kwh_dc: acc.kwh_dc + c.kwh_dc,
          }), {
            COD_REG: parseInt(k),
            DEN_REG: "",
            ac: 0,
            dc: 0,
            n_ac: 0,
            n_dc: 0,
            kwh_ac: 0,
            kwh_dc: 0,
          })]
        ));

        setRegionStats(reg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    if (!regionStats) return;

    fetch(`/data/regioni.geojson`)
      .then((res) => res.json())
      .then((data) => {
        setRegions(
          data?.features.map((f: any) => {
            const stats = regionStats[f.properties.COD_REG] ?? {};
            const weight = (stats.kwh_ac ?? 0) + (stats.kwh_dc ?? 0);

            return (
              {
                ...f,
                properties: {
                  ...f.properties,
                  weight,
                  bound: calculateBound([f]),
                  stats: stats,
                },
              } ?? []
            );
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [regionStats]);

  useEffect(() => {
    if (!provinceStats) return;

    fetch(`/data/province.geojson`)
      .then((res) => res.json())
      .then((data) => {
        return setProvinces(
          data?.features.map((f: any) => {
            const stats = provinceStats[f.properties.COD_PROV] ?? {};
            const weight = (stats.kwh_ac ?? 0) + (stats.kwh_dc ?? 0);

            return {
              ...f,
              properties: {
                ...f.properties,
                weight: weight,
                bound: calculateBound([f]),
                stats: stats,
              },
            };
          }) ?? []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [provinceStats]);
  
  useEffect(() => {
    if (!cityStats || Object.keys(cityStats).length === 0) return;

    fetch(`/data/comuni.geojson`)
      .then((res) => res.json())
      .then((data) => {
        setCities(
          data?.features.map((f: any) => {
            const stats = cityStats[f.properties.COMUNE] ?? {};
            const weight = (stats.kwh_ac ?? 0) + (stats.kwh_dc ?? 0);
            return {
              ...f,
              properties: {
                ...f.properties,
                weight: weight,
                bound: calculateBound([f]),
                stats: stats,
              },
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cityStats]);

  return (
    <SiteSelectionGeoDataContext.Provider
      value={{
        regions,
        provinces,
        cities,
        cityStats,
        provinceStats,
        regionStats,
        getGeoByCoordinates
      }}
    >
      {children}
    </SiteSelectionGeoDataContext.Provider>
  );
}

const useSiteSelectionGeoDataContext = () => {
  const context = useContext(SiteSelectionGeoDataContext);
  if (context === undefined) {
    throw new Error(
      "useSiteSelectionGeoDataContext must be used within a SiteSelectionGeoDataContextProvider"
    );
  }
  return context;
};

export {
  SiteSelectionGeoDataContext,
  SiteSelectionGeoDataContextProvider,
  useSiteSelectionGeoDataContext,
};
