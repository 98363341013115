import { Evc, UserAction } from "@bepower/greta-types";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  Badge,
  Box,
  Card,
  ColumnShape,
  Flex,
  Typography,
} from "../../../../../shared/UI";
import { FiltersList } from "../../../../../shared/components/FiltersList";
import { SmartTable } from "../../../../../shared/components/SmartTable";
import { SectionList } from "../../../../../shared/layout";
import { useGetUsersActions } from "../../../gql/queries/useGetUsersActions";
import { selectAction } from "../../../slices/infrastructure";
import { EventMessages } from "./EventMessages";
import { cache } from "../../../../../App";
type Column = ColumnShape<UserAction>;

const defaultColumns: Column[] = [
  {
    key: "Date",
    title: "date",
    dataKey: "Date",
    dataGetter: ({ rowData }) => {
      return (
        <Typography fontSize={10} fontWeight="bold">
          {rowData?.timestamp
            ? format(rowData?.timestamp, "dd/MM/yyyy HH:mm:ss")
            : null}
        </Typography>
      );
    },
    flexGrow: 1,
    width: 150,
    resizable: true,
    sortable: true,
  },
  {
    key: "user",
    title: "user",
    dataKey: "user",
    flexGrow: 1,
    width: 300,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography fontSize={10} fontWeight="bold">
          {rowData?.email}
        </Typography>
      );
    },
  },
  {
    key: "fieldName",
    title: "field name",
    dataKey: "fieldName",
    flexGrow: 1,
    width: 300,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Flex flexDirection={"column"}>
          <Box>
            <Badge >{rowData?.parentTypeName}</Badge>
          </Box>
          <Typography my={1} variant={"caption10"} fontWeight={"bold"}>
            {rowData?.fieldName}
          </Typography>
          <Typography fontSize={10}>{rowData?.uid}</Typography>
        </Flex>
      );
    },
  },
  {
    key: "Message",
    title: "message",
    dataKey: "Message",
    flexGrow: 1,
    width: 300,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Box>
          <Badge variant="warning" $fill={true}>
            <Typography my={1} variant={"caption10"} fontWeight={"bold"}>
              {rowData?.stream}
            </Typography>
          </Badge>
          <Box pt={1}>
            <Typography>{rowData?.id}</Typography>
          </Box>
        </Box>
      );
    },
  },
];
export const EvcAudit = () => {
  const { evc }: { evc: Evc } = useOutletContext();
  const dispatch = useDispatch();
  const { getUsersActions, usersActions, loading, refetch, fetchMore } =
    useGetUsersActions();
  const [searchParams, setSearchParams] = useSearchParams();
  const text = searchParams?.get("text");
  const [columnKeys, setColumnKeys] = useState<string[]>(
    defaultColumns.map((column) => String(column.key))
  );
  const { selectedAction } = useSelector((state: any) => state.infrastructure);

  const columns = defaultColumns.filter((column) =>
    columnKeys.some((key) => key === String(column.key))
  );
  const getAuditSearch = useCallback(() => {
    const uids = [evc?.uid, evc?.EVSEs?.results?.map((evse: any) => evse?.uid)];
    const query = uids?.map(
      (uid) => `("${String(uid).split("-").join('" AND "')})`
    );
    return query.join(`\" OR \"`);
  }, [evc?.EVSEs?.results, evc?.uid]);

  const onChangeSearchInput = (e: any) => {
    if (e.target.value) {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    } else {
      searchParams.set("text", "");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    getUsersActions({
      variables: {
        pagination: {
          after: null,
          limit: 50,
        },
        filter: {
          query: text ? text : getAuditSearch(),
        },
      },
    });
    return () => {
      dispatch(selectAction(null));
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "Greta",
        broadcast: true,
      });
      cache.gc();
    };
  }, [dispatch, getAuditSearch, getUsersActions, text]);

  const tableRef = React.useRef<any>();
  const handleRefresh = useCallback(() => {
    tableRef?.current?.scrollToTop(0);
    refetch();
  }, [tableRef, refetch]);

  const handleClickRow = useCallback(
    (row: any) => {
      dispatch(selectAction(row));
    },
    [dispatch]
  );
  return (
    <Flex p={3} flexDirection={"row"} maxWidth={"100%"} fullSize>
      <SectionList width={selectedAction ? "60%" : "100%"} height="100%">
        <FiltersList
          textInputProps={{
            onChange: onChangeSearchInput,
            defaultValue: text || "",
          }}
          onClickRefreshButton={handleRefresh}
        />
        <Card
          minWidth={300}
          minHeight={400}
          overflow="hidden"
          bg="white"
          position="relative"
          display="flex"
          width="100%"
          height="100%"
          borderRadius={5}
        >
          <SmartTable
            tableRef={tableRef}
            fixed={selectedAction}
            rowHeight={50}
            columns={columns as any}
            onEndReached={fetchMore}
            loading={loading}
            data={usersActions || []}
            selectedRowProps={{
              onRowChange: handleClickRow,
              selectedRow: selectedAction?.uid,
            }}

            //  onColumnSort={handleSortColumn}
            //  sortBy={{
            //    key: searchParams.get(`locationSort`)?.split("-")[0] as string,
            //    order:
            //      (searchParams.get(`locationSort`)?.split("-")[1] as SortOrder) ||
            //      "asc",
            //  }}
          />
        </Card>
      </SectionList>{" "}
      {selectedAction ? (
        <Card width="30%" maxWidth={"400px"} ml={3} bg="white">
          <EventMessages />
        </Card>
      ) : null}
    </Flex>
  );
};
