export function convertToGraphqlQuery(data: any) {
  let queryString = "";
  Object.entries(data)?.map(([key, value]: any) => {
    if (value) {
      if (Array.isArray(value)) {
        if (!value.length) return;
        let agrValue = "";
        value?.map(({ value: v }: any) => {
          if (agrValue === "") {
            agrValue = `${key}:${v}`;
          } else {
            agrValue = agrValue + ` OR ${key}:${v}`;
          }
        });
        if (queryString === "") {
          queryString = `(${agrValue})`;
        } else {
          queryString = `${queryString} AND (${agrValue})`;
        }
        return;
      }
      if (queryString === "") {
        queryString = `${key}:${value?.["value"]}`;
      } else {
        queryString = `${queryString} AND ${key}:${value?.["value"]}`;
      }
    }
  });
  return queryString;
}

export function convertStringsArrayIntoQuery(arr: string[]): string {
  let queryString = "";
  if (!arr.length) {
    queryString = "";
  } else if (arr.length === 1) {
    queryString = arr[0];
  } else {
    queryString = arr.join(" OR ");
  }

  return queryString;
}
export function convertSearchParamIntoArray(
  param: string | null,
  splitter: string
) {
  if (!param) return [];
  return param.split(splitter);
}
