import { useParams } from "react-router-dom";
import { Flex } from "../../../../../shared/UI";
import { SessionsTable } from "../../../../../shared/gql/queries/tables/sessions/SessionsTable";

const LocationSessions = () => {
  const { uid: locationUid } = useParams();

  return (
    <Flex fullSize p={3}>
      <SessionsTable
        filters={{
          locationUid: locationUid,
        }}
      />
    </Flex>
  );
};

export default LocationSessions;
