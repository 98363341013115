import { SVGProps } from "react"
const DC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#2B9AD6"
      stroke="#fff"
      strokeWidth={2.1}
      d="M12.5 23c5.8 0 10.501-4.701 10.501-10.5S18.3 2 12.501 2C6.7 2 2 6.701 2 12.5S6.701 23 12.5 23Z"
    />
    <path
      fill="#fff"
      d="M11.261 7.019v5.816c0 .504.378.882.882.882h1.806c.273 0 .462.294.357.546l-1.344 3.193c-.23.567.19 1.218.798 1.218h.252a.806.806 0 0 0 .735-.42l4.285-6.343c.336-.566-.084-1.322-.735-1.322h-2.184c-.252 0-.441-.253-.357-.526l.902-2.75a.858.858 0 0 0-.819-1.134h-3.696a.852.852 0 0 0-.882.84Z"
    />
    <path
      fill="#fff"
      d="M7.44 17.583c-.295.756.21 1.68 1.091 1.68h.19c.398 0 .797-.21 1.007-.567l3.046-4.6v-.041c.44-.777-.084-1.806-1.03-1.806H10.61l.526-1.638c.23-.756-.295-1.575-1.135-1.575H7.376a1.166 1.166 0 0 0-1.197 1.176v4.242c0 .65.525 1.218 1.197 1.218h.861l-.798 1.91Z"
    />
    <path
      fill="#169CD8"
      d="M8.72 19.83h-.189a1.673 1.673 0 0 1-1.428-.756 1.843 1.843 0 0 1-.189-1.68v-.02l.462-1.156h-.02c-.967 0-1.744-.798-1.744-1.785v-4.242c0-.483.19-.924.525-1.26.336-.336.777-.483 1.26-.483h2.604c.568 0 1.072.252 1.408.714.336.462.44 1.05.273 1.596l-.294.903h.378c.63 0 1.197.336 1.49.882a1.804 1.804 0 0 1 0 1.785l-3.066 4.683a1.748 1.748 0 0 1-1.47.82Zm-.756-2.037a.73.73 0 0 0 .063.63.56.56 0 0 0 .504.273h.19c.21 0 .42-.105.524-.294l3.067-4.62a.712.712 0 0 0 0-.65.59.59 0 0 0-.525-.316H9.855l.756-2.373a.65.65 0 0 0-.105-.588.624.624 0 0 0-.483-.252H7.397a.614.614 0 0 0-.44.168.604.604 0 0 0-.19.441v4.242c0 .357.273.651.63.651h1.701l-1.134 2.688Z"
    />
  </svg>
)
export default DC
