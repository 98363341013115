import React from "react";
import Ccs1 from "../assets/svg/Ccs1";
import CcsCombo2 from "../assets/svg/CcsCombo2";
import { Chademo } from "../assets/svg/Chademo";
import Shuko from "../assets/svg/Shuko";
import Type1 from "../assets/svg/Type1";
import Type2 from "../assets/svg/Type2";
import Type3a from "../assets/svg/Type3a";
import Type3c from "../assets/svg/Type3c";




export const getConnectorTypeIcon = (name: string) => {
  switch (name) {
    case connectorType.ccs1:
      return <Ccs1 size="big" />;
      case connectorType.type1:
        return <Type1 size="big" />;

    case connectorType.type2:
      return <Type2 size="big" />;


    case connectorType.type3a:
      return <Type3a />;

    case connectorType.type3c:
      return <Type3c size="big" />;

    case connectorType.shuko:
      return <Shuko size="big"/>;

    case connectorType.chaDeMO:
      return <Chademo size="big" />;

    case connectorType.ccsCombo2:
      return <CcsCombo2 size="big" />;

    default:
      return null;
  }
};

export const connectorType = {
  ccs1: "IEC_62196_T1_COMBO",
  ccsCombo2: "IEC_62196_T2_COMBO",
  chaDeMO: "CHADEMO",
  shuko: "DOMESTIC_A",
  type1: "IEC_62196_T1",
  type2: "IEC_62196_T2",
  type3a: "IEC_62196_T3A",
  type3c: "IEC_62196_T3C",
};
