/** @format */

import { useQuery } from "@apollo/client";
import { gql } from "../generated";
import { MeterValuesDataQueryVariables } from "../generated/graphql";

const GET_METERVALUES_DATA = gql(`
            query MeterValuesData(
               $filter: MeterValuesDataFilterParams
               $pagination: PaginationParams
            ) {
               MeterValuesData(filter: $filter, pagination: $pagination) {
                  total
                  pagination {
                     cursor
                  }
                  results {
                     evcUid
                     evseUid
                     transactionUid
                     meterValue {
                        location
                        context
                        measurand
                        phase
                        timestamp
                        unit
                        value
                     }
                     uid
                     timestamp
                  }
               }
            }

`);

export const useGetMeterValues = ({
  variables,
}: {
  variables?: MeterValuesDataQueryVariables;
}) => {
  const { data, loading, error } = useQuery(GET_METERVALUES_DATA, {
    variables: {
      ...variables,
    },
  });

  return { data, loading, error };
};
