import { Marker, OverlayView, useGoogleMap } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Typography } from "../../../../../shared/UI";
import MarkerLocationBasic from "../../../../../shared/assets/svg/MarkerLocationBasic";
import {
  CompetitorEvc,
  useSiteSelectionEvcDataContext,
} from "../../../slices/EvcDataContextProvider";
import { buildSvgString } from "../../../utils/buildSVG";
import { InfoOverlay } from "../map-components/NewLocationTooltip";

const getBasicLocationMarker = (cpTot: number, evcModel: "AC" | "DC") => (
  <MarkerLocationBasic
    fontFamily="sans-serif"
    cpTot={cpTot}
    evcModel={evcModel}
  />
);

export const CompetitorLayer = () => {
  const { t } = useTranslation();
  const { filteredCompetitorsEvcs, showCompetitorEvcs } =
    useSiteSelectionEvcDataContext();
  const map = useGoogleMap();

  const [selectedEvc, setSelectedEvc] = useState<CompetitorEvc | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const bigger = (map?.getZoom() ?? 20) > 15;

  const pinSize = useMemo(() => {
    return !bigger
      ? new google.maps.Size(35, 35)
      : new google.maps.Size(55, 55);
  }, [bigger]);

  const markerDataUriAC = useMemo(
    () => buildSvgString(getBasicLocationMarker(0, "AC")),
    []
  );
  const markerDataUriDC = useMemo(
    () => buildSvgString(getBasicLocationMarker(0, "DC")),
    []
  );

  return (
    <>
      {filteredCompetitorsEvcs
        ?.filter((evc) => {
          return (
            (evc.evcModelChargeMode === "DC" && showCompetitorEvcs.dc) ||
            (evc.evcModelChargeMode === "AC" && showCompetitorEvcs.ac)
          );
        })
        ?.map((evc) => {
          const evcModel = evc.evcModelChargeMode;
          const svgString =
            evcModel === "AC" ? markerDataUriAC : markerDataUriDC;
          return (
            <Marker
              key={evc.evcUuid}
              position={{
                lat: evc.latitude,
                lng: evc.longitude,
              }}
              icon={{
                url: svgString,
                scaledSize: pinSize,
              }}
              // icon={markerDataUri}
              zIndex={2}
              onClick={() => {
                setSelectedEvc(evc);
                setIsOpen(true);
              }}
            >
              {selectedEvc?.evcUuid === evc.evcUuid && (
                <OverlayView
                  position={{
                    lat: evc.latitude,
                    lng: evc.longitude,
                  }}
                  zIndex={1}
                  mapPaneName="overlayMouseTarget"
                  getPixelPositionOffset={() => ({
                    x: 0,
                    y: -15,
                  })}
                  onLoad={() => {
                    map?.setCenter(map?.getCenter()!);
                    setTimeout(() => {
                      setIsOpen(true);
                    }, 10);
                  }}
                  onUnmount={() => {
                    setIsOpen(false);
                  }}
                >
                  {isOpen ? (
                    <InfoOverlay
                      width={200}
                      title="Competitor"
                      onClose={() => {
                        setSelectedEvc(null);
                      }}
                    >
                      <Flex padding={"4px"} color="black">
                        <Typography marginTop={"5px"} fontWeight={700}>
                          {t("siteSelection.general.cso")}
                        </Typography>
                        <Typography variant={"body10"}>{evc.cso}</Typography>

                        <Flex marginTop={"5px"} flexDirection="row">
                          <Typography fontWeight={700}>
                            {t("siteSelection.general.type")}
                          </Typography>
                          <Typography>{evc.evcModelChargeMode}</Typography>
                        </Flex>

                        <Typography marginTop={"5px"} fontWeight={700}>
                          {t("siteSelection.general.where")}
                        </Typography>
                        <Typography>
                          {evc.locationMunicipality ?? "-"}
                        </Typography>
                      </Flex>
                    </InfoOverlay>
                  ) : (
                    <></>
                  )}
                </OverlayView>
              )}
            </Marker>
          );
        })}
    </>
  );
};
