import { gql, useLazyQuery } from "@apollo/client";

const GET_CSOS = gql`
  query CSOs(
    $filter: CSOFilterParams
    $pagination: PaginationParams
    $sort: CSOSortingParams
  ) {
    CSOs(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        Metadata
        locationsCount
        name
        uid
        BusinessModels {
          businessModel
          locationsCount
        }
        Tariff {
          uid
          Tariff {
            label
          }
          label
        }
      }

      total
      __typename
      pagination {
        cursor
        last
      }
    }
  }
`;
export const useGetCSOs = () => {
  const [
    executeGetCSOs,
    { loading, error, fetchMore: fetchMoreItems, data, refetch, startPolling },
  ] = useLazyQuery(GET_CSOS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
  });

  const fetchMore = () => {
    if (
      data?.CSOs?.pagination.cursor &&
      data?.CSOs?.results?.length < data.CSOs?.total
    ) {
      fetchMoreItems({
        variables: {
          pagination: {
            after: data?.CSOs?.pagination.cursor,
          },
        },
        updateQuery(
          { CSOs: prevData },
          { fetchMoreResult: { CSOs: newData } }
        ) {
          if (prevData?.results?.length) {
            return {
              CSOs: {
                results: [...prevData?.results, ...newData?.results],
                __typename: "CSOList",
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          } else {
            return {
              CSOs: {
                results: [...newData.results],
                __typename: "CSOList",
                total: prevData?.total,
                pagination: newData?.pagination,
              },
            };
          }
        },
      });
    }
  };

  return {
    loading,
    executeGetCSOs,
    error,
    fetchMore,
    count: data?.CSOs?.total,
    CSOs: data?.CSOs?.results,
    refetch,
    startPolling,
  };
};
