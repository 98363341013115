import { IndexedDBProps } from "react-indexed-db-hook";

export const DBConfig = {
    name: "SiteSelectionDB",
    version: 1,
    objectStoresMeta: [
      {
        store: "hex",
        storeConfig: { keyPath: "city", autoIncrement: false },
        storeSchema: [
            { name: "city", keypath: "city", options: { unique: true } },
            { name: "runDate", keypath: "runDate", options: { unique: false } },
          { name: "hex", keypath: "hex", options: { unique: false } },
        ],
      },
    ],
  } satisfies Omit<IndexedDBProps, 'children'>;