import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box } from "../UI";
import { Flex } from "../UI/Flex";
import { Absolute } from "./../UI/Absolute";

export type SectionLayoutProps = {
  label?: string;
};
const AbsoluteDiv = styled(Absolute)`
  background-color: #f5f5f5;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 331px;
  width: 100%;
  // margin: 10px auto;
  display: inline-block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const SectionLayoutElement = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 100%;
  background-color: ${({ theme }) => theme?.colors?.secondary};
  overflow: auto;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: min(10vh, 1.5rem);
  > ${Box} {
    transition: all 2s ease-in-out;
  }
`;

export const SectionLayout: React.FC<PropsWithChildren<SectionLayoutProps>> = ({
  label,
  children,
}) => {
  return (
    <SectionLayoutElement>
      <Flex flexDirection="row" fullSize>
        {children}
      </Flex>
    </SectionLayoutElement>
  );
};
