import { t } from "i18next";
import { useState } from "react";
import styled from "styled-components";
import { Box, Card, Flex, Select, Typography } from "../../../shared/UI";
import { checkIfLast } from "./../../../shared/helpers/arrayHelper";

function getElementPrice(item: any, label: string, type: string) {
  if (!item || !type || !label) return;
  return item?.pricing
    ?.find((el: any) => el.label === label)
    ?.elements[0]?.priceComponent?.find((el: any) => el.type === type)?.price;
}

type Props = {
  tariffAssoc: any;
};

export const TariffPriceContent: React.FC<Props> = ({ tariffAssoc }) => {
  const [selectedPrice, setSelectedPrice] = useState<any>(
    tariffAssoc?.Tariff?.PricingByCountry.find(
      (item: any) => item?.countries.length === 0
    ) || tariffAssoc?.Tariff?.PricingByCountry[0]
  );

  return (
    <Flex
      p={4}
      overflow="scroll"
      fullSize
      alignItems="center"
      justifyContent="space-around"
      margin="auto"
    >
      {tariffAssoc?.Tariff?.PricingByCountry.length > 1 ? (
        <Flex
          mb={3}
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            fontSize={[16, 16, 16, 16, 16, 22]}
            fontWeight="bold"
            upperCase
            pb={3}
            variant="title30"
            color="primaryDark"
          >
            {t("change countries to see their prices")}
          </Typography>
          <Box maxWidth={500} minWidth={500}>
            <Select
              isClearable={false}
              placeholder="Select Price"
              defaultValue={{
                label: selectedPrice?.countries?.length
                  ? selectedPrice?.countries.map(
                      (country: string, index: number) =>
                        checkIfLast(
                          country,
                          index,
                          selectedPrice?.countries?.length
                        )
                    )
                  : "Other Countries",
                value: selectedPrice,
              }}
              onChange={(value: any) => {
                setSelectedPrice(value?.value);
              }}
              options={tariffAssoc?.Tariff?.PricingByCountry?.map(
                (item: any) => ({
                  label: item?.countries.length
                    ? item.countries.map((country: string, index: number) =>
                        checkIfLast(country, index, item?.countries?.length)
                      )
                    : "Other Countries",
                  value: item,
                })
              )}
            />
          </Box>
        </Flex>
      ) : null}
      {selectedPrice ? (
        <Card width={"100%"} bg="white" overflow="hidden" p={2}>
          <>
            <Flex p={2} textAlign="center" alignItems="center">
              <Typography variant="title40" upperCase>
                {selectedPrice?.countries?.length
                  ? selectedPrice?.countries?.map(
                      (country: string, index: number) =>
                        checkIfLast(
                          country,
                          index,
                          selectedPrice?.countries?.length
                        )
                    )
                  : null}
              </Typography>
            </Flex>
            <StyledTable>
              <thead>
                <TRow style={{ height: 50 }}>
                  <th style={{ minWidth: 150 }}>Service Type</th>
                  <th>€/kWh</th>
                  <th>€/min</th>
                  <th>Penalty €/min</th>
                  <th>Fisso€</th>
                </TRow>
              </thead>
              <tbody>
                <PriceElement PriceElement={selectedPrice} />
              </tbody>
            </StyledTable>
          </>
        </Card>
      ) : null}
    </Flex>
  );
};

type PriceElementProps = {
  PriceElement: any;
};

export const PriceElement: React.FC<PriceElementProps> = ({ PriceElement }) => {
  return PriceElement?.pricing?.map((el: any, index: number) => {
    return (
      <TRow key={index}>
        <td style={{ padding: "10px" }}>
          <Typography variant="title30" fontSize={[12, 12, 12, 14, 14]}>
            {el?.label}
          </Typography>
        </td>
        <td>
          <Typography variant="title30">
            {getElementPrice(PriceElement, el?.label, "energy")}
          </Typography>
        </td>
        <td>
          <Typography variant="title30">
            {getElementPrice(PriceElement, el?.label, "time")}
          </Typography>
        </td>
        <td>
          <Typography variant="title30">
            {getElementPrice(PriceElement, el?.label, "parkingTime")}
          </Typography>
        </td>
        <td>
          <Typography variant="title30">
            {getElementPrice(PriceElement, el?.label, "flat")}
          </Typography>
        </td>
      </TRow>
    );
  });
};

export const StyledTable = styled.table`
  max-height: 400px;
  width: 100%;
  overflow-x: auto;
  background-color: ${({ theme }) => theme?.colors?.darkWhite};
  margin: auto;
  padding: 5px;
  overflow: hidden;
  border: 1px solid #737373;
  border-collapse: collapse;
`;
export const TRow = styled.tr`
  > th {
    background-color: #d9eeff;
    color: ${({ theme }) => theme?.colors?.primary};
    padding: 10px;
  }
  > th:not(:first-child),
  td:not(:first-child) {
    text-align: center;
  }
  > th,
  td {
    text-align: left;
    border: 1px solid #737373;
    border-collapse: collapse;
  }
  > td {
    min-width: 90px;
    > span {
      font-weight: bold;
    }
  }
`;
