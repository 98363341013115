



import Svg from "../../UI/Svg";

type Props = {
   size?: string;
   fill?: string;
};

const FutureTariff: React.FC<Props> = ({ size, fill }) => {
   return (
      <Svg iconSize={size}
         viewBox="0 0 48 34" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M20.6262 22.6578L14.7383 16.9229V8.8488H17.0323V15.9797L22.2703 21.0355L20.6262 22.6578Z" fill="#3D729C" />
         <path d="M16.641 33.0069C14.3489 33.0069 12.1942 32.5714 10.1771 31.7004C8.16005 30.8294 6.39509 29.6451 4.88228 28.1476C3.36946 26.6501 2.17754 24.8927 1.30652 22.8756C0.435508 20.8586 0 18.7039 0 16.4118C0 14.1196 0.435508 11.9803 1.30652 9.99377C2.17754 8.00724 3.36946 6.27285 4.88228 4.79059C6.39509 3.30834 8.16005 2.13934 10.1771 1.2836C12.1942 0.427868 14.3489 0 16.641 0C19.086 0 21.401 0.534835 23.5862 1.6045C25.7714 2.67417 27.6586 4.14115 29.2478 6.00543V1.14607H31.9984V10.6814H22.4172V7.93083H27.2307C25.886 6.37217 24.3044 5.11913 22.486 4.17171C20.6675 3.22429 18.7192 2.75058 16.641 2.75058C12.8208 2.75058 9.55062 4.0571 6.8306 6.67015C4.11059 9.2832 2.75058 12.4846 2.75058 16.2743C2.75058 20.1556 4.09531 23.4563 6.78476 26.1763C9.47421 28.8964 12.7596 30.2564 16.641 30.2564C20.4612 30.2564 23.6855 28.9116 26.3139 26.2222C28.9422 23.5327 30.2564 20.2779 30.2564 16.4576H33.0069C33.0069 21.0419 31.4177 24.9462 28.2393 28.1705C25.0608 31.3948 21.1947 33.0069 16.641 33.0069Z" fill="#3D729C" />
         <rect x="27.6182" y="10.7778" width="19.5347" height="12.125" rx="1.34722" fill="white" />
         <path d="M33.5424 17.7244C33.0577 17.7244 32.6456 17.5525 32.3062 17.2087C31.9669 16.8648 31.7972 16.4473 31.7972 15.9562C31.7972 15.465 31.9669 15.0475 32.3062 14.7037C32.6456 14.3598 33.0577 14.1879 33.5424 14.1879C34.0272 14.1879 34.4393 14.3598 34.7787 14.7037C35.118 15.0475 35.2877 15.465 35.2877 15.9562C35.2877 16.4473 35.118 16.8648 34.7787 17.2087C34.4393 17.5525 34.0272 17.7244 33.5424 17.7244ZM28.8884 19.7873C28.646 19.7873 28.44 19.7014 28.2703 19.5295C28.1006 19.3575 28.0158 19.1488 28.0158 18.9032V13.0091C28.0158 12.7635 28.1006 12.5548 28.2703 12.3829C28.44 12.211 28.646 12.125 28.8884 12.125H38.1965C38.4389 12.125 38.6449 12.211 38.8146 12.3829C38.9843 12.5548 39.0691 12.7635 39.0691 13.0091V18.9032C39.0691 19.1488 38.9843 19.3575 38.8146 19.5295C38.6449 19.7014 38.4389 19.7873 38.1965 19.7873H28.8884ZM30.3428 18.9032H36.7421C36.7421 18.4906 36.8827 18.1419 37.1639 17.857C37.4451 17.5721 37.7893 17.4297 38.1965 17.4297V14.4826C37.7893 14.4826 37.4451 14.3402 37.1639 14.0553C36.8827 13.7704 36.7421 13.4217 36.7421 13.0091H30.3428C30.3428 13.4217 30.2022 13.7704 29.921 14.0553C29.6398 14.3402 29.2956 14.4826 28.8884 14.4826V17.4297C29.2956 17.4297 29.6398 17.5721 29.921 17.857C30.2022 18.1419 30.3428 18.4906 30.3428 18.9032ZM37.3239 21.5556H27.1431C26.9007 21.5556 26.6947 21.4696 26.525 21.2977C26.3553 21.1258 26.2705 20.917 26.2705 20.6714V13.8932H27.1431V20.6714H37.3239V21.5556Z" fill="#3D729C" />
      </Svg>
   );
};

export default FutureTariff;
