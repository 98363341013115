export interface OcppRemoteChangeAvailabilityReq {
  connectorId: number;
  type: OcppChangeAvailabilityType;
}

export enum OcppChangeAvailabilityType {
  OPERATIVE = "Operative",
  INOPERATIVE = "Inoperative",
}

export interface OcppRemoteChangeAvailabilityConf {
  status: OcppChangeAvailabilityStatus;
}

export enum OcppChangeAvailabilityStatusEnum {
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  SCHEDULED = "Scheduled",
}

export type OcppChangeAvailabilityStatus =
  `${OcppChangeAvailabilityStatusEnum}`;
