export const MESSAGES = {
    ACTIVITY: {},
    AUTH: {
        success: {
            login: 'You have successfully logged-in',
        },
    },
    FORGOT_PASSWORD: {
        success: {
            forgot: 'An activation link has been successfully sent to your email.',
        },
        error: {
            forgot: 'Something went wrong. Please try again!',
        }
    },
    RESET_PASSWORD: {
        success: {
            reset: 'Your password has been reset successfully.',
        },
        error: {
            reset: 'Something went wrong. Please try again!',
        }
    },
    EVC: {
        success: {
            create: 'The Evc has been created',
            edit: 'The Evc has been edited',
            changeLocation: 'The Location has been changed',
        },
    },
    EVSE: {
        success: {
            create: 'The Evse has been created',
            edit: 'The Evse has been edited',
            addInAccessGroup: 'The Evse has been added to access group',
            removeFromAccessGroup: 'The Evse has been removed to access group',
            addInUserGroup: 'The Evse has been added to user group',
            removeFromUserGroup: 'The Evse has been removed to user group',
        },
    },
    LOCATION: {
        success: {
            create: 'The Location has been created',
            edit: 'The Location has been edited',
        },
    },
    SDR: {},
    REMOTE_COMMAND: {
        success: {
            chargeStart: 'EVSE Started Successfully',
            chargeStop: 'EVSE Stopped successfully',
            chargeUnlock: 'EVSE Unlocked successfully',
            diagnosticEvc: 'Diagnostics Download Successfully',
            firwareUpdateEvc: 'Firmware Updated Successfully',
            rebootEvc: 'Rebooted Successfully',
            triggerStatusNotification: 'Status Triggered Successfully',
            configurationFetchEvc: 'Configuraton Requested Successfully',
            configurationChangeEvc: 'Change Requested Successfully',
            changeAvailability: {
                accepted: 'EVSE availability successfully changed',
                scheduled: 'EVSE availability change successfully scheduled',
            }
        },
        warning: {
            changeAvailability: {
                unknown: 'Unknown evse response received',
                rejected: 'EVSE availability change has been rejected',
            }
        },
        error: {
            info: 'Disconnected',
        },
    },
    SESSION: {},
    TOKEN: {
        success: {
            create: 'The Token has been created',
            edit: 'The Token has been edited',
        },
    },
    USER: {
        success: {
            create: 'The User has been created',
            edit: 'The User has been edited',
        },
        error: {
            create: 'The User has not been created successfully.This User already exists. Please try again!',
            edit: "The User has not been edited successfully. This User already exists.  Please try again!",
        }
    },
    PLATFORM: {
        success: {
            create: 'The Platform has been created',
            edit: 'The Platform has been edited',
        },
    },
    PLATFORM_PARTY: {
        success: {
            edit: 'The Platform party has been edited',
            create: 'The Platform party has been created',
        },
    },
    PLATFORM_INTEROPERABILITY: {
        success: {
            edit: 'The Platform interoperability has been edited',
            create: 'The Platform interoperability has been created',
        },
    },
    PLATFORM_CONNECTION: {
        success: {
            create: 'The connection has been created',
            edit: 'The connection has been edited',
        },
    },
    ACCESS_GROUP: {
        success: {
            create: 'The access group has been created',
            edit: 'The access group has been edited',
        },
    },
    USER_GROUP: {
        success: {
            create: 'The user group has been created',
            edit: 'The user group has been edited',
        },
    },
    SITE_SELECTION: {
        success: {
            createProject: 'The site selection project has been created',
            editProject: 'The site selection project has been edited',
            deleteProject: 'The site selection project has been deleted',
        },
        
    },
    TRIGGER: {},
};
