import { gql, useLazyQuery } from "@apollo/client";

export const REFRESH_STATUS_PROCESSED = gql`
  query RefreshStatusProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    RefreshStatusProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        requestUid
        status
        info
        timestamp
        request {
          evcUid
          evseUid
          kind
          requestUid
        }
      }
    }
  }
`;
export const useRefreshStatusProcessed = () => {
  const [
    getRefreshStatusProcessed,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(REFRESH_STATUS_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getRefreshStatusProcessed,
    error,
    refreshStatusProcessed: data?.RefreshStatusProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
