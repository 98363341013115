import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, Flex, Typography } from "../../../../../shared/UI";
import { DialogBodyElement } from "../../../../../shared/UI/Dialog/StyledElements";

export const DeleteProjectsDialog = ({
  isOpen,
  setIsOpen,
  ids,
  onDelete,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: SetStateAction<boolean>) => void;
  ids: string[];
  onDelete?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog width="500px" height="300px" isOpen={isOpen} setIsOpen={setIsOpen}>
      <DialogBodyElement>
        <Flex justifyContent="center" margin={"0 auto"} marginTop={10}>
          <Typography
            variant="title30"
            color="#008FDD"
            fontWeight={700}
            fontSize={30}
            upperCase={true}
            textAlign={"center"}
          >
            {t("siteSelection.openProject.confirmDeleteDialog.title")}
          </Typography>

          <Typography
            variant="caption30"
            fontSize={20}
            textAlign={"center"}
            marginTop={20}
          >
            {t("siteSelection.openProject.confirmDeleteDialog.text", {
              projectsCount: ids.length,
            })}
          </Typography>

          <Flex
            flexDirection={"row"}
            justifyContent="space-around"
            marginTop={38}
          >
            <Flex flexGrow={1} />
            <Button
              bg="#008FDD"
              height={48}
              width={150}
              borderRadius={12}
              fontSize={16}
              style={{ textTransform: "none" }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t("siteSelection.openProject.confirmDeleteDialog.cancel")}
            </Button>
            <Button
              bg="red"
              height={48}
              width={150}
              borderRadius={12}
              fontSize={16}
              marginLeft={"5px"}
              style={{ textTransform: "none" }}
              onClick={() => {
                setIsOpen(false);
                onDelete?.();
              }}
            >
              {t("siteSelection.openProject.confirmDeleteDialog.confirm")}
            </Button>
          </Flex>
        </Flex>
      </DialogBodyElement>
    </Dialog>
  );
};
