import { useParams } from "react-router-dom";

import { SessionsTable } from "../../../../../shared/gql/queries/tables/sessions/SessionsTable";

export const EvseSessions = () => {
  const { uid } = useParams();

  return (
    <SessionsTable
      filters={{
        evseUid: uid,
      }}
    />
  );
};
