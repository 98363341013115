



import React from "react";
import Svg from "../../UI/Svg";
type Props = {
	size?: string;
	fill?: string;
};
const Back: React.FC<Props> = ({ fill, size, ...props }) => {
	return (

		<Svg
			fill={fill}
			iconSize={size}
			{...props}
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 384.965 384.965" xmlSpace="preserve">
			<g>
				<path id="Arrow_Left" d="M3.544,201.041L3.544,201.041L3.544,201.041l119.942,120.195c7.627,7.94,21.029,1.949,20.728-8.83v-59.863
		c0.048,0,0.096,0.024,0.144,0.024h228.576c6.617,0,12.03-5.414,12.03-12.03v-96.242c0-6.617-5.414-12.03-12.03-12.03H144.359
		c-0.048,0-0.096,0.024-0.144,0.024V72.485c0.289-10.731-13.017-16.554-20.632-8.926L3.556,183.849
		C-1.183,188.601-1.183,196.301,3.544,201.041z M120.094,101.43v54.906h0.205h12.03h11.886v-0.024c0.048,0,0.096,0.024,0.144,0.024
		h12.03h204.515v72.182H156.389h-12.03c-0.048,0-0.096,0.024-0.144,0.024v-0.024h-11.886h-12.03h-0.205v54.954l-90.829-91.021
		L120.094,101.43z"/>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
		</Svg>
	);
};

export default Back;
