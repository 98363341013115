import { gql, useLazyQuery } from "@apollo/client";

export const GET_EVSES_SHORT = gql(`
  query EVSEsShort(
    $filter: EvseFilterParams
    $pagination: PaginationParams
    $sort: EvseSortingParams
  ) {
    EVSEs(filter: $filter, pagination: $pagination, sort: $sort) {
      __typename
      results {
        __typename
        assetStatus
        label
        status
        uid
        updatedAt
        statusUpdatedAt
      }
      total
    }
  }
`);
export const useGetEvsesShort = () => {
  const [
    getEvses,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(GET_EVSES_SHORT);

  return {
    loading,
    getEvses,
    error,
    EVSEs: data?.EVSEs?.results,
    count: data?.EVSEs?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
