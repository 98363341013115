import { useNavigate } from "react-router-dom";
import {
  Flex, LoaderWithFallback, SimpleLoader,
  Typography
} from "../../../../shared/UI";
import VerticalScrollContainer from "../../../../shared/UI/VerticalScrollContainer";
import { usePlatform } from "../../hooks/usePlatform";
import PlatformInputs from "./platformInputs";

type Props = {
};
export const PlatformDetailsForm: React.FC<Props> = ({

}) => {
  const navigate = useNavigate();
  const { platform, loadingGetPlatform } = usePlatform()
  return (
    <LoaderWithFallback
      loading={loadingGetPlatform}
      LoadingMsg={<SimpleLoader />}
      errorMsg={<Typography>No data available</Typography>}
      hasError={!platform}
    >
      {/* {platform ? (
        <ItemTitleContainer  >
          <Typography upperCase  fontWeight='bold' >
            {platform?.name}
          </Typography>
        </ItemTitleContainer>
      ) : null} */}

      <VerticalScrollContainer>

        <Flex  >
          <Flex fullSize p={2}>

            <PlatformInputs platform={platform} />

          </Flex>

        </Flex>
      </VerticalScrollContainer>
    </LoaderWithFallback>

  );
};
