import React, { PropsWithChildren } from "react";
import { Card } from "../UI";
import { DefaultProps } from "../UI/helpers";

export const SectionList: React.FC<PropsWithChildren<DefaultProps>> = ({
  children,
  width = "490px",
}) => {

  return (
    <Card
      display="flex"
      flexDirection='column'
      flex={1}
      width={width}
      maxWidth={width}
      minWidth={width}
      position="relative"
      backgroundColor="white"
      overflow='hidden'
      borderRadius={5}
    >

      {children}
    </Card >
  );
};
