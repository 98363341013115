import { gql, useLazyQuery } from "@apollo/client";

export const GET_FTP_URLS = gql`
  query FtpUrls(
    $filter: FtpUrlFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    FtpUrls(filter: $filter, pagination: $pagination, sort: $sort) {
      results {
        uid
        label
        description
        createdAt
        type
        vendor
        model
        url
      }
      total
    }
  }
`;
export const useGetFtpUrls = () => {
  const [
    getFtpUrls,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(GET_FTP_URLS, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getFtpUrls,
    error,
    ftpUrls: data?.FtpUrls?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
