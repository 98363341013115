import { Marker, OverlayView, useGoogleMap } from "@react-google-maps/api";
import { useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Flex, Typography } from "../../../../../shared/UI";
import MarkerPoi from "../../../../../shared/assets/svg/MarkerPoi";
import {
  POI,
  useSiteSelectionEvcDataContext
} from "../../../slices/EvcDataContextProvider";
import { InfoOverlay } from "../map-components/NewLocationTooltip";
import { AttractionType } from "../map-components/PoiFilter";

const markerSvgString = encodeURIComponent(renderToStaticMarkup(<MarkerPoi />));

const buildSvgString = (attraction: AttractionType): string => {
  const s = encodeURIComponent(
    renderToStaticMarkup(<MarkerPoi attraction={attraction} />)
  );
  return `data:image/svg+xml,${s}`;
};

const mapSvg: Partial<Record<AttractionType, string>> = {
    parking: buildSvgString("parking"),
    shopping: buildSvgString("shopping"),
    cibo_ristorazione: buildSvgString("cibo_ristorazione"),
    intrattenimento_attrazioni: buildSvgString("intrattenimento_attrazioni"),
    business: buildSvgString("business"),
    istruzione: buildSvgString("istruzione"),
    svago_divertimento: buildSvgString("svago_divertimento"),
    salute_benessere: buildSvgString("salute_benessere"),
    strutture_ricettive: buildSvgString("strutture_ricettive"),
    stazioni_di_servizio: buildSvgString("stazioni_di_servizio"),
    strutture_sportive: buildSvgString("strutture_sportive"),
    charging_station: buildSvgString("charging_station"),
    supermercati_centri_commerciali: buildSvgString(
      "supermercati_centri_commerciali"
    ),
      // casello_autostradale: buildSvgString("casello_autostradale"),
  }



const markerDataUri = `data:image/svg+xml,${markerSvgString}`;

export const PoiLayer = () => {
  const { filteredPois } = useSiteSelectionEvcDataContext();
  const map = useGoogleMap();

  const [selectedEvc, setSelectedEvc] = useState<POI | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  
  return (
    <>
      {filteredPois?.map((evc) => {
        return (
          <Marker
            key={`${evc.lat}-${evc.lon}`}
            position={{
              lat: evc.lat,
              lng: evc.lon,
            }}
            icon={{
              url: mapSvg[evc.category] ?? markerDataUri,
              scaledSize: new google.maps.Size(30, 35),
              fillColor: "#FFC700",
            }}
            // icon={markerDataUri}
            zIndex={1}
            onClick={() => {
              setSelectedEvc(evc);
              setIsOpen(true);
            }}
          >
            {selectedEvc?.id === evc?.id && (
              <OverlayView
                position={{
                  lat: evc.lat,
                  lng: evc.lon,
                }}
                zIndex={100000}
                mapPaneName="overlayMouseTarget"
                getPixelPositionOffset={() => ({
                  x: 0,
                  y: -15,
                })}
                onLoad={() => {
                  map?.setCenter(map?.getCenter()!);
                  setTimeout(() => {
                    setIsOpen(true);
                  }, 10);
                }}
                onUnmount={() => {
                  setIsOpen(false);
                }}
              >
                {isOpen ? (
                  <InfoOverlay
                    width={200}
                    title="POI"
                    onClose={() => {
                      setSelectedEvc(null);
                    }}
                  >
                    <Flex padding={"4px"} color="black">
                      <Typography marginTop={"5px"} fontWeight={700}>
                        {t(
                          `siteSelection.general.data.poi.category.${evc.category}`
                        )}
                      </Typography>
                      <Typography marginTop={"5px"} fontWeight={700}>
                        {evc.name}
                      </Typography>
                    </Flex>
                  </InfoOverlay>
                ) : (
                  <></>
                )}
              </OverlayView>
            )}
          </Marker>
        );
      })}
    </>
  );
};
