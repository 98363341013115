import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TariffState = {
   selectedTariff: any | null;
};
const initialState: TariffState = {
   selectedTariff: null,
};

const tariffs = createSlice({
   name: "rates",
   initialState,
   reducers: {
      selectTariff: (state, action: PayloadAction<TariffState | null>) => {
         state.selectedTariff = action.payload;
      },
   },
});

export const { selectTariff } = tariffs.actions;
export default tariffs.reducer;
