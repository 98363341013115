import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};

const Shuko: React.FC<Props> = ({ size, fill, ...props }) => {
  return (
    <Svg
      iconSize={size}
      fill={fill}
      viewBox="0 0 533 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 184.917969 508.3125 L 184.917969 488.367188 L 348.082031 488.367188 L 348.082031 508.3125 C 455.359375 473.800781 533 373.058594 533 254.15625 C 533 135.253906 455.359375 34.511719 348.082031 0 L 348.082031 19.945312 L 184.917969 19.945312 L 184.917969 0 C 77.640625 34.511719 0 135.253906 0 254.15625 C 0 373.058594 77.640625 473.800781 184.917969 508.3125 Z M 174.039062 254.15625 C 174.039062 281.230469 152.125 303.175781 125.09375 303.175781 C 98.058594 303.175781 76.144531 281.230469 76.144531 254.15625 C 76.144531 227.082031 98.058594 205.132812 125.09375 205.132812 C 152.125 205.132812 174.039062 227.082031 174.039062 254.15625 Z M 418.785156 303.175781 C 445.820312 303.175781 467.734375 281.230469 467.734375 254.15625 C 467.734375 227.082031 445.820312 205.132812 418.785156 205.132812 C 391.75 205.132812 369.835938 227.082031 369.835938 254.15625 C 369.835938 281.230469 391.75 303.175781 418.785156 303.175781 Z M 418.785156 303.175781 "
      />
    </Svg>
  );
};

export default Shuko;
