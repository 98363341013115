import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PriceState = {
   showPrices: boolean;
};
const initialState: PriceState = {
   showPrices: false,
};

const prices = createSlice({
   name: "prices",
   initialState,
   reducers: {
      setShowPrices: (state, action: PayloadAction<boolean>) => {
         state.showPrices = action?.payload;
      },
   },
});

export const { setShowPrices } = prices.actions;
export default prices.reducer;
