import { Location } from "@bepower/greta-types";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Typography } from "../../../../../../shared/UI";
import VerticalScrollContainer from "../../../../../../shared/UI/VerticalScrollContainer";

type LocationMapProps = {
  location: Partial<Location>;
};
const containerStyle = {
  width: "100%",
  height: "400px",
};

export const LocationMap: React.FC<LocationMapProps> = ({ location }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyChibtUkiRoURZd6yrh1611rI4w93SE5lM",
    authReferrerPolicy: "origin",
    id: "google-map-script",
  });
  const markers = useMemo(
    () => [
      {
        address: location.label,
        lat: Number(location?.geolocation?.latitude),
        lng: Number(location?.geolocation?.longitude),
      },
    ],
    [
      location?.geolocation?.latitude,
      location?.geolocation?.longitude,
      location?.label,
    ]
  );

  const [mapRef, setMapRef] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [infoWindowData, setInfoWindowData] = useState<any>();
  const onMapLoad = (map: any) => {
    setMapRef(map);
    //  const bounds = new google.maps.LatLngBounds();
    //  markers?.forEach(({ lat, lng }: any) => bounds.extend({ lat, lng }));
    //  map.fitBounds(bounds);
  };

  const handleMarkerClick = (
    id: any,
    lat: number,
    lng: number,
    address: string
  ) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  return (
    <VerticalScrollContainer>
      {" "}
      {!isLoaded ? (
        <Typography>Loading...</Typography>
      ) : (
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onMapLoad}
          onClick={() => setIsOpen(false)}
          center={{
            lat: Number(location?.geolocation?.latitude),
            lng: Number(location?.geolocation?.longitude),
          }}
          zoom={15}
        >
          {markers.map(({ address = "", lat, lng }, ind) => (
            <Marker
              key={ind}
              position={{ lat, lng }}
              onClick={() => {
                handleMarkerClick(ind, lat, lng, address);
              }}
            >
              {isOpen && infoWindowData?.id === ind && (
                <InfoWindow
                  onCloseClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Flex justifyContent="flex-start">
                    <Typography>{infoWindowData.address}</Typography>
                    <Box mt={2}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={"https://maps.google.com?q=" + lat + "," + lng}
                      >
                        Visualizza su Google Maps
                      </Link>
                    </Box>
                  </Flex>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      )}
    </VerticalScrollContainer>
  );
};
