export const getStatusColor = (status?: string) => {
  if (!status) return "black";
  switch (status.toUpperCase()) {
    case "ACTIVE":
      return "#008b00";
    case "COMPLETED":
      return "#008b00";
    case "OK":
      return "#008b00";
    case "ENDED":
      return "#008b00";
    case "NORMAL":
      return "#008b00";
    case "AVAILABLE":
      return "#008b00";
    case "ERROR":
      return "#B00020";
    case "CHARGING":
      return "#B00020";
    case "DISCONNECTED":
      return "#A4ABB6";
    case "DISABLED":
      return "#A4ABB6";
    case "UNAVAILABLE":
      return "#A4ABB6";

    case "ENABLE":
      return "#2DCCFF";
    case "STANDBY":
      return "#2DCCFF";
    case "PLANNED":
      return "#2DCCFF";
    case "CHARGE":
      return "#2DCCFF";
    case "INVALID":
      return "#FFAF3D";
    case "EXPIRED":
      return "#FFAF3D";
    default:
      return "#000000";
  }
};
