/** @format */

import { useLazyQuery } from "@apollo/client";
import { gql } from "../generated";

const AGGREGATION_LOCATIONS = gql(`
  query AggregationLocations(
    $field: LocationAggregableField!
    $filter: LocationFilterParams
    $pagination: PaginationParams
  ) {
    Aggregation {
      Locations(filter: $filter, pagination: $pagination, field: $field) {
        total
        results {
          key
          doc_count
        }
      }
    }
  }
`);

// spostami in prices
export const useGetAggregationLocations = () => {
  const [
    getAggrLoctions,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(AGGREGATION_LOCATIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getAggrLoctions,
    error,
    data: data?.Aggregation?.Locations?.results,
    count: data?.Aggregation?.Locations?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
