import React from "react";
import Svg from "../../UI/Svg";
type Props = {
  size?: string;
  fill?: string;
};
const Protocol: React.FC<Props> = ({ fill, size, ...props }) => {
  return (
    <Svg
      fill={fill}
      iconSize={size}
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15,19a1,1,0,0,0-1-1H13V17h3a2,2,0,0,0,2-2V8L13,3H8A2,2,0,0,0,6,5V15a2,2,0,0,0,2,2h3v1H10a1,1,0,0,0-1,1H2v2H9a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1h7V19ZM12,4.5,16.5,9H12Z"
        fillRule="evenodd"
      />
      <rect width="24" height="24" fill="none" />
    </Svg>
  );
};

export default Protocol;
