import { Cso } from "@bepower/greta-types";
import { t } from "i18next";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Flex, InputLabel, Typography } from "../../../../shared/UI";
import MultiSelect from "../../../../shared/components/MultiSelect";
import { useGetCSOs } from "../../../cso/gql/queries/useGetCsos";
type props = {
  partyId?: string | null;
  canEdit: boolean;
  partyUid?: string | null;
  platformProtocol?: string | null;
};

export const CsoDropdown: React.FC<props> = ({ canEdit }) => {
  const { loading, executeGetCSOs, CSOs, count } = useGetCSOs();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const wType = watch("type")?.value;

  useEffect(() => {
    executeGetCSOs({
      variables: {
        pagination: {
          limit: 10000,
        },
        filter: {
          businessModel: wType,
        },
      },
    });
  }, [executeGetCSOs, wType]);

  return (
    <Flex flex={1} minWidth={300}>
      <InputLabel labelColor="white" children="Host Group" required={false} />

      <Controller
        name="cso"
        control={control}
        render={({ field }) => (
          <MultiSelect
            isSelectAll={true}
            isMulti
            closeMenuOnSelect={false}
            menuPosition="fixed"
            controlShouldRenderValue
            isLoading={loading}
            getOptionValue={(option: any) => option?.value}
            hideSelectedOptions={false}
            isDisabled={!canEdit}
            placeholder={count ? `${count} ${t("options")}` : "Select CSO"}
            options={
              CSOs?.map((cso: Cso): any => ({
                label: cso?.name,
                value: cso?.uid,
              })) || []
            }
            error={errors.cso ? errors.cso.message || t("required") : ""}
            errorProps={{
              color: "white",
              borderBottom: "1px solid red",
            }}
            {...field}
          />
        )}
      />
      <Typography variant="caption10" color="white" pt={1}>
        {t(
          "Select to create a tariff valid only for specified Host groups. Leave empty otherwise."
        )}
      </Typography>
    </Flex>
  );
};
