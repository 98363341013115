







import React from "react";
import Svg from "../../UI/Svg";
type Props = {
   size?: string;
   fill?: string;
};
const Profile: React.FC<Props> = ({ fill, size, ...props }) => {
   return (


      <Svg fill={fill}
         iconSize={size}
         {...props}
         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
         <path d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
         <path d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </Svg>


   );
};

export default Profile;


