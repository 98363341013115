import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Card,
  ColumnShape,
  Flex,
  StyledLink,
  Typography,
} from "../../../../../shared/UI";
import { useGetEvc } from "../../../gql/queries/useGetEvc";
import { useCallback, useEffect } from "react";
import { SmartTable } from "../../../../../shared/components/SmartTable";
import { Evse } from "@bepower/greta-types";
import React from "react";
import { FiltersList } from "../../../../../shared/components/FiltersList";
import { SectionList } from "../../../../../shared/layout";

type Column = ColumnShape<Evse>;

const columns: Column[] = [
  {
    key: "label",
    title: "label",
    dataKey: "label",
    width: 120,
    align: "center",
    resizable: true,
  },
  {
    key: "number",
    title: "number",
    dataKey: "number",
    width: 120,
    align: "center",
    resizable: true,
  },
  {
    key: "status",
    title: "status",
    dataKey: "status",
    width: 120,
    align: "center",
    resizable: true,
  },
  {
    key: "assetStatus",
    title: "asset status",
    dataKey: "assetStatus",
    width: 120,
    align: "center",
    resizable: true,
  },
  {
    key: "connectingType",
    title: "connecting type",
    dataKey: "connectingType",
    width: 120,
    align: "center",
    resizable: true,
  },
];
export const EvcEvses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const text = searchParams.get("text");
  const { evc, loading, refetch }: any = useOutletContext();

  const navigate = useNavigate();

  const handleClickRow = (row: any) => {
    navigate(`/evses/${row?.uid}`);
  };

  const TableRef = React.useRef<any>();
  const handleRefresh = useCallback(() => {
    TableRef?.current?.scrollToTop(0);
    refetch();
  }, [TableRef, refetch]);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
  return (
    <Flex p={3} fullSize>
      <SectionList width={"100%"}>
        <FiltersList
          onClickRefreshButton={handleRefresh}
          textInputProps={{
            onChange: handleChangeInput,
            defaultValue: searchParams?.get("text") || "",
          }}
        />
        <SmartTable
          loading={loading}
          selectedRowProps={{
            onRowChange: handleClickRow,
            selectedRow: "",
          }}
          data={evc?.EVSEs?.results || []}
          columns={columns as any}
        />
      </SectionList>
    </Flex>
  );
};
