import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Props = {
  showMainList: boolean;
  showDrawer: boolean;
  showMainContent: boolean;
  showSmallList: boolean;
  showSmallContent: boolean;
  showBottomContent: boolean;
};

const layoutItems = createSlice({
  name: "platformsList",
  initialState: {
    showMainList: true,
    showDrawer: true,
    showBottomContent: false,
    showSmallContent: false,
    showSmallList: true,
  } as Props,
  reducers: {
    setShowMainList: (state, action: PayloadAction<boolean>) => {
      state.showMainList = action?.payload;
    },
    setShowDrawer: (state, action: PayloadAction<boolean>) => {
      state.showDrawer = action?.payload;
    },
    setShowMainContent: (state, action: PayloadAction<boolean>) => {
      state.showMainContent = action?.payload;
    },
    setShowSmallContent: (state, action: PayloadAction<boolean>) => {
      state.showSmallContent = action?.payload;
    },
    setShowSmallList: (state, action: PayloadAction<boolean>) => {
      state.showSmallList = action?.payload;
    },
    setShowBottomContent: (state, action: PayloadAction<boolean>) => {
      state.showBottomContent = action?.payload;
    },
  },
});

export const {
  setShowMainList,
  setShowDrawer,
  setShowBottomContent,
  setShowMainContent,
  setShowSmallContent,
  setShowSmallList,
} = layoutItems.actions;
export default layoutItems.reducer;
