import React, { useRef, useState } from "react";
import styled from "styled-components";

interface OptionsListProps {
  isOpen: boolean;
}

const CustomSelectWrapper = styled.div`
  min-width: 160px;
  position: relative;
  border-radius: 10px;
  border: 1px solid #3d729caa;
  box-shadow: 0px 3.178px 3.178px 0px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  z-index: 4;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  background: #fff;
  border: none;
  text-transform: uppercase;
  padding: 0.5rem 0;
  font-weight: 700;
  color: currentColor;
  cursor: pointer;
  border-radius: 10px;
`;

const OptionsList = styled.ul<OptionsListProps>`
  background: #fff;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 1rem;
  margin-top: -0.5rem;
  box-shadow: 0px 3.178px 3.178px 0px rgba(0, 0, 0, 0.25);
  display: ${(props) => (props.isOpen ? "block" : "none")};
  border-radius: 0 0 10px 10px;
  padding-bottom: 0.5rem;
  border: 1px solid #3d729caa;
  border-top: none;
`;

const OptionItem = styled.li<{
  hoverColor?: string;
  hoverTextColor?: string;
}>`
  list-style: none;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.hoverColor || "#3d729c"};
    color: ${(props) => props.hoverTextColor || "#fff"};
  }
`;

export function CustomSelect({
  options,
  defaultOption,
  style,
  hoverColor,
  hoverTextColor,
  onOptionSelected,
}: {
  options: string[];
  defaultOption?: string;
  style?: React.CSSProperties;
  hoverColor?: string;
  hoverTextColor?: string;
  onOptionSelected: (option: string) => void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(
    defaultOption || options[0]
  );

  const selectRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    onOptionSelected(option);
  };


  return (
    <CustomSelectWrapper ref={selectRef} style={style}>
     
      <SelectButton onClick={toggleMenu}>
        {selectedOption}{" "}
        <svg
          width="13"
          height="6"
          viewBox="0 0 13 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          transform={isOpen ? "rotate(180)" : undefined}
        >
          <path
            d="M6.92741 5.64551L0.928382 0.176753L12.9264 0.176757L6.92741 5.64551Z"
            fill="currentColor"
          />
        </svg>
      </SelectButton>
      <OptionsList isOpen={isOpen}>
        {options.map((option) => (
          <OptionItem
            key={option}
            hoverColor={hoverColor || "#3d729c"}
            hoverTextColor={hoverTextColor || "#fff"}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </OptionItem>
        ))}
      </OptionsList>
    </CustomSelectWrapper>
  );
}
