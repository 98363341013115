import Svg from "../../UI/Svg";
type Props = {
  iconSize?: string;
  fill?: string;
  stroke?: string;
};
const Tick = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M14.7905 0.920561C15.2119 1.34199 15.2119 2.02638 14.7905 2.44781L6.15912 11.0786C5.73767 11.5 5.05323 11.5 4.63177 11.0786L0.31609 6.76321C-0.105363 6.34178 -0.105363 5.65738 0.31609 5.23596C0.737543 4.81453 1.42198 4.81453 1.84344 5.23596L5.39713 8.78605L13.2665 0.920561C13.688 0.499136 14.3724 0.499136 14.7939 0.920561H14.7905Z" />
  </Svg>
);
export default Tick;
