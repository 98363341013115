import { gql, useLazyQuery } from "@apollo/client";

export const CONFIGURATION_CHANGE_PROCESSED = gql(`
query ConfigurationChangeProcessed($filter: RemoteCommandFilterParams, $pagination: PaginationParams, $sort: SortingParams) {
    ConfigurationChangeProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        status
        info
        timestamp
        result {
          key
          status
          result
          error
        }
        rebootRequired
        request {
          requestUid
          configuration {
            key
            value
          }
          evcUid
        }
      }
    }
  }
  `);

export const useConfigurationChangeProcessed = () => {
  const [
    getConfigurationChangeProcessed,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(CONFIGURATION_CHANGE_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getConfigurationChangeProcessed,
    error,
    diagnosticUploadProcessed: data?.DiagnosticUploadProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
