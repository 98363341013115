import { gql, useLazyQuery } from "@apollo/client";

export const DIAGNOSTIC_UPLOAD_PROCESSED = gql`
  query DiagnosticUploadProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    DiagnosticUploadProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        status
        timestamp
        requestUid
        info
        filename
        request {
          evcUid
          from
          ftpUrl
          interval
          requestUid
          retries
          to
        }
      }
    }
  }
`;
export const useDiagnosticUploadProcessed = () => {
  const [
    getDiagnosticUploadProcessed,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(DIAGNOSTIC_UPLOAD_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getDiagnosticUploadProcessed,
    error,
    diagnosticUploadProcessed: data?.DiagnosticUploadProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
