import Svg from "../../UI/Svg";

type Props = {
  size?: string;
  fill?: string;
};

const Session: React.FC<Props> = ({ size, ...props }) => {
  return (
    <Svg
      iconSize={size}
      {...props}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9294 2.75024C7.9302 2.71797 4.71797 5.9302 4.75024 9.92939C4.78259 13.938 8.06196 17.2174 12.0706 17.2498C16.0698 17.282 19.282 14.0698 19.2498 10.0706C19.2174 6.06196 15.938 2.78259 11.9294 2.75024ZM3.25029 9.9415C3.21126 5.10443 7.10443 1.21126 11.9415 1.25029C16.7691 1.28925 20.7108 5.23089 20.7497 10.0585C20.7826 14.1381 18.0185 17.5463 14.25 18.4902V19.5218C14.25 19.736 14.25 19.9329 14.2387 20.0982C14.2266 20.2759 14.199 20.4712 14.1168 20.6697C13.9392 21.0985 13.5985 21.4392 13.1697 21.6168C13.0263 21.6762 12.8846 21.7071 12.75 21.7242V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V21.7242C11.1154 21.7071 10.9737 21.6762 10.8303 21.6168C10.4015 21.4392 10.0608 21.0985 9.88321 20.6697C9.80099 20.4712 9.77338 20.2759 9.76126 20.0982C9.74998 19.9329 9.74999 19.736 9.75 19.5218L9.75 18.4239C6.03406 17.383 3.28281 13.9708 3.25029 9.9415ZM11.25 18.7064V19.5C11.25 19.7432 11.2504 19.8881 11.2578 19.9961C11.2623 20.0629 11.2684 20.0913 11.2703 20.0987C11.2956 20.1575 11.3425 20.2044 11.4013 20.2297C11.4087 20.2316 11.4371 20.2377 11.5039 20.2422C11.6119 20.2496 11.7568 20.25 12 20.25C12.2432 20.25 12.3881 20.2496 12.4961 20.2422C12.5629 20.2377 12.5913 20.2316 12.5987 20.2297C12.6575 20.2044 12.7044 20.1575 12.7297 20.0987C12.7316 20.0913 12.7377 20.0629 12.7422 19.9961C12.7496 19.8881 12.75 19.7432 12.75 19.5V18.7284C12.5219 18.7444 12.2913 18.7516 12.0585 18.7497C11.786 18.7475 11.5163 18.7329 11.25 18.7064ZM13.3741 6.4569C13.6741 6.74256 13.6856 7.21729 13.4 7.51724L11.7497 9.25H13.9997C14.2997 9.25 14.5709 9.4288 14.6891 9.70456C14.8073 9.98032 14.7497 10.3 14.5428 10.5172L11.6857 13.5172C11.4 13.8172 10.9253 13.8288 10.6253 13.5431C10.3254 13.2574 10.3138 12.7827 10.5995 12.4828L12.2497 10.75H9.99972C9.6997 10.75 9.42855 10.5712 9.31036 10.2954C9.19218 10.0197 9.24971 9.70002 9.45662 9.48276L12.3138 6.48276C12.5994 6.18281 13.0742 6.17123 13.3741 6.4569Z"
        fill={props.fill}
      />
    </Svg>
  );
};

export default Session;
