import { gql, useLazyQuery } from "@apollo/client";

export const TOKENS = gql`
  query Tokens(
    $filter: TokenFilterParams
    $pagination: PaginationParams
    $sort: TokenSortingParams
  ) {
    Tokens(filter: $filter, pagination: $pagination, sort: $sort) {
      __typename
      pagination {
        __typename
        last
      }
      results {
        __typename
        authId
        emspPartyUid
        expiresAt
        group
        label
        language
        origin
        uid
        updatedAt
        valid
        value
        whitelist
        PlatformParty {
          name
        }
      }
      sort {
        __typename
        field
        order
      }
      total
    }
  }
`;

export const useGetTokens = () => {
  const [
    getTokens,
    {
      loading,
      error,
      data,
      refetch,
      fetchMore,
      updateQuery,
      networkStatus,
      startPolling,
    },
  ] = useLazyQuery(TOKENS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    refetchWritePolicy: "overwrite",
    notifyOnNetworkStatusChange: true,
    initialFetchPolicy: "network-only",
  });

  return {
    loading,
    getTokens,
    error,
    tokens: data?.Tokens?.results,
    count: data?.Tokens?.total,
    refetch,
    fetchMore,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
