import { gql, useMutation } from "@apollo/client";

export const PUBLISH_EVC_REBOOT = gql`
  mutation publishEvcReboot($input: EvcRebootInput!) {
    publishEvcReboot(input: $input) {
      requestUid
    }
  }
`;

export const usePublishEvcReboot = () => {
  const [publishEvcReboot, { data, loading, error }] =
    useMutation<any>(PUBLISH_EVC_REBOOT);
  return {
    publishEvcReboot,
    error,
    data: data?.publishEvcReboot,
    loading,
  };
};
