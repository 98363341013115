/** @format */

import { useEffect, useState } from "react";
import { FilterContainer } from "../../../../../components/table-filter/FilterContainer";
import { ColumnShape } from "../../../../../UI";
import { Session, Token } from "../../../../generated/graphql";
import { useGetTokens } from "../../../../../../pages/infrastracture/gql/queries/useGetTokens";

type Props = {
  column: ColumnShape<Session>;
};
export const FilterSessionsByToken: React.FC<Props> = ({ column }) => {
  const [status, setStatus] = useState<boolean>(false);
  const { getTokens, loading, tokens } = useGetTokens();
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (status) {
      getTokens({
        variables: {
          pagination: {
            limit: 100,
          },
          filter: {
            text: searchValue,
          },
        },
      });
    }
  }, [getTokens, searchValue, status]);
  return (
    <FilterContainer
      loading={loading}
      options={tokens?.map((token: Token) => token.label)}
      searchValue="token"
      status={status}
      setStatus={setStatus}
      column={column}
      searchInputValue={searchValue}
      setSearchInputValue={setSearchValue}
      multiCheck={false}
    />
  );
};
