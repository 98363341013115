import { format } from "date-fns";
import { t } from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnShape } from "react-base-table";
import { useForm } from "react-hook-form";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  Popover,
  SortOrder,
  Table,
  Typography,
} from "../../../../../shared/UI";
import { TableSetting } from "../../../../../shared/assets/svg";
import { FiltersList } from "../../../../../shared/components/FiltersList";
import { SectionList } from "../../../../../shared/layout";
import { usePublishConfigurationChange } from "../../../gql/mutations/usePublishConfigurationChange";
import { usePublishConfigurationFetch } from "../../../gql/mutations/usePublishEvcConfigurationFetch";
import { useConfigurationChangeProcessed } from "../../../gql/queries/useConfigurationChangeProcessed";
import { useConfigurationFetch } from "../../../gql/queries/useConfigurationFetchEvc";
import { useConfigurationFetchProcessed } from "../../../gql/queries/useConfigurationFetchProcessed";

type Column = ColumnShape<any>;

export const EvcConfigurations: React.FC = () => {
  const { data, loading, getConfigurationFetch, refetch } =
    useConfigurationFetch();
  const { publishConfigurationFetch } = usePublishConfigurationFetch();
  const { publishConfigurationChange } = usePublishConfigurationChange();
  const { getConfigurationChangeProcessed } = useConfigurationChangeProcessed();
  const { getConfigurationFetchProcessed } = useConfigurationFetchProcessed();

  const [searchParams, setSearchParams] = useSearchParams();
  const { evc }: any = useOutletContext();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [heddinElements, setHeddinElements] = useState<string[]>([]);

  const { register, handleSubmit, setValue } = useForm();
  const [isRefetchingConfigEvc, setIsRefetchingConfigEvc] =
    useState<boolean>(false);
  const [isPublishingConfigurationChange, setIsPublishingConfigurationChange] =
    useState<boolean>(false);

  const filteredData = useMemo((): any[] => {
    let dataArr: any[] = data?.configuration;
    const text = searchParams.get("evcConfigText");
    const sort = searchParams.get("evcConfigSort");
    if (sort) {
      const sortKey = sort.split("-")[0] as string;
      const sortValue = (sort.split("-")[1] as SortOrder) || "asc";
      dataArr = dataArr?.sort((a, b): any =>
        sortValue === `asc`
          ? a[sortKey].toUpperCase().charCodeAt(0) -
            b[sortKey].toUpperCase().charCodeAt(0)
          : b[sortKey].toUpperCase().charCodeAt(0) -
            a[sortKey].toUpperCase().charCodeAt(0)
      );
    }

    if (text) {
      dataArr = dataArr?.filter((item: any) =>
        JSON.stringify(item).toLowerCase().includes(text.toLowerCase())
      );
    }

    return dataArr;
  }, [data, searchParams]);

  const handleRowSelection = ({ rowData }: any) => {
    const selectedRowKeys = [...selectedRows];
    const rowIndex = selectedRowKeys.indexOf(rowData.key);

    if (rowIndex !== -1) {
      selectedRowKeys.splice(rowIndex, 1);
      setValue(rowData.key, null);
    } else {
      selectedRowKeys.push(rowData.key);
      setValue(rowData.key, rowData.value);
    }

    setSelectedRows(selectedRowKeys);
  };
  const columns: Column[] = [
    {
      key: "checkbox",
      title: "edit",
      dataKey: "checkbox",
      flexGrow: 0,
      width: 50,
      align: "left",
      resizable: false,
      selectedRowKeys: { selectedRows },
      hidden: heddinElements.includes("edit"),

      cellRenderer: ({ rowData }: any) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(rowData.key)}
          onChange={() => handleRowSelection({ rowData })}
          disabled={rowData.readonly}
          style={{ width: 20, height: 20 }}
        />
      ),
    },
    {
      key: "key",
      title: "key",
      dataKey: "key",
      width: 200,
      align: "left",
      flexGrow: 1,
      resizable: true,
      sortable: true,
      hidden: heddinElements.includes("key"),

      dataGetter: ({ rowData }) => {
        return <Typography>{rowData?.key}</Typography>;
      },
    },
    {
      key: "value",
      sortable: true,
      flexGrow: 2,
      title: "value",
      dataKey: "value",
      width: 200,
      align: "left",
      resizable: true,
      hidden: heddinElements.includes("value"),

      cellRenderer: ({ cellData, container, rowData }: any) => {
        return selectedRows.includes(rowData.key) ? (
          <div>
            <Input {...register(rowData.key)} placeholder="Write some value " />
          </div>
        ) : (
          <Typography>{rowData.value}</Typography>
        );
      },
    },

    {
      key: "readonly",
      title: "read only",
      dataKey: "readonly",
      width: 100,
      align: "left",
      resizable: true,
      hidden: heddinElements.includes("Read Only"),

      dataGetter: ({ rowData }) => {
        return <Typography> {rowData?.readonly ? "YES" : "NO"}</Typography>;
      },
    },
    {
      key: "timestampFetch",
      title: "time stamp fetch",
      dataKey: "timestampFetch",
      flexGrow: 0,
      width: 150,
      align: "left",
      resizable: true,
      hidden: heddinElements.includes("time Stamp Fetch"),

      dataGetter: ({ rowData }) => {
        return (
          <Typography>
            {" "}
            {rowData?.timestampFetch
              ? format(rowData?.timestampFetch, "dd/MM/yyyy HH:mm:ss")
              : null}
          </Typography>
        );
      },
    },
    {
      key: "timestampChange",
      title: "time stamp change",
      dataKey: "timestampChange",
      width: 150,
      align: "left",
      resizable: true,
      hidden: heddinElements.includes("time stamp Change"),

      dataGetter: ({ rowData }) => {
        return (
          <Typography>
            {rowData?.timestampChange
              ? format(rowData?.timestampChange, "dd/MM/yyyy HH:mm:ss")
              : null}{" "}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    getConfigurationFetch({
      variables: {
        evcUid: evc?.uid,
      },
    });
  }, [evc?.uid, getConfigurationFetch]);

  const handleSortColumn = useCallback(
    ({ key, order }: { key: any; order: "asc" | "desc" }) => {
      searchParams.set(`evcConfigSort`, `${key}-${order}`);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const TableRef = React.useRef<any>();
  const handleRefresh = useCallback(() => {
    TableRef?.current?.scrollToTop(0);
    refetch();
  }, [TableRef, refetch]);

  const onChangeInput = (e: any) => {
    if (e.target.value) {
      searchParams.set("evcConfigText", e.target.value);
    } else {
      searchParams.delete("evcConfigText");
    }
    setSearchParams(searchParams);
  };

  const handleSendRefreshConfigEvcFetch = () => {
    if (evc?.uid) {
      setIsRefetchingConfigEvc(true);
      publishConfigurationFetch({
        variables: {
          input: {
            evcUid: evc?.uid,
            keys: [],
          },
        },
      })
        .then((res: any) => {
          const requestUid = res?.data?.publishConfigurationFetch?.requestUid;
          if (requestUid) {
            setTimeout(() => {
              getConfigurationFetchProcessed({
                variables: {
                  filter: {
                    requestUid: requestUid,
                  },
                },
              })
                .then((res) => {
                  if (
                    res.data?.ConfigurationFetchProcessed?.results?.[0]?.status
                  ) {
                    toast.success("Configurations Requested Successfully");
                    refetch();
                  } else {
                    toast.error(
                      res.data?.ConfigurationFetchProcessed?.results?.[0]?.info
                    );
                  }
                })
                .catch((error) => {
                  setIsRefetchingConfigEvc(false);
                })
                .finally(() => {
                  setIsRefetchingConfigEvc(false);
                });
            }, 3000);
          }
        })
        .catch(() => {
          setIsRefetchingConfigEvc(false);
        });
    }
  };
  const onSubmit = (data: any) => {
    let filterdConfigurationObj = { ...data };
    Object.keys(filterdConfigurationObj).forEach((key) => {
      if (filterdConfigurationObj[key] === null) {
        delete filterdConfigurationObj[key];
      }
    });
    const configurationArray = Object.keys(filterdConfigurationObj).map(
      (key) => ({
        key: key,
        value: filterdConfigurationObj[key],
      })
    );
    setIsPublishingConfigurationChange(true);
    publishConfigurationChange({
      variables: {
        input: {
          evcUid: evc?.uid,
          configuration: configurationArray,
        },
      },
    })
      .then((res: any) => {
        const requestUid = res?.data?.publishConfigurationChange?.requestUid;
        if (requestUid) {
          setTimeout(() => {
            getConfigurationChangeProcessed({
              variables: {
                filter: {
                  requestUid: requestUid,
                },
              },
            })
              .then((res) => {
                if (
                  res.data?.ConfigurationChangeProcessed?.results?.[0]?.status
                ) {
                  toast.success("Change Requested Successfully");
                  refetch();
                } else {
                  toast.error(
                    res.data?.ConfigurationChangeProcessed?.results?.[0]?.info
                  );
                }
              })
              .catch((error) => {
                setIsPublishingConfigurationChange(false);
              })
              .finally(() => {
                setIsPublishingConfigurationChange(false);
                handleSendRefreshConfigEvcFetch();
                setSelectedRows([]);
              });
          }, 3000);
        }
      })
      .catch(() => {
        setIsPublishingConfigurationChange(false);
      });
  };
  const onResetFilters = () => {
    // TODO  restare input value e sort values
  };
  const [status, setStatus] = useState<boolean>(false);
  return (
    <Flex p={3} fullSize>
      <SectionList width="100%" height="100%">
        <FiltersList
          onClickRefreshButton={handleRefresh}
          resetCallback={onResetFilters}
          textInputProps={{
            onChange: onChangeInput,
            defaultValue: searchParams?.get("evcConfigText") || "",
          }}
          extraProps={
            <Popover status={status} setStatus={setStatus}>
              <Button fixedSize>
                <TableSetting />
              </Button>
              <Card p={3}>
                <ul>
                  {columns?.map((col: ColumnShape<any>) => (
                    <li
                      style={{
                        width: 200,
                        listStyle: "none",
                        marginBottom: 5,
                      }}
                    >
                      <Checkbox
                        checked={!heddinElements.includes(col.title as string)}
                        onChange={() => {
                          if (heddinElements.includes(col.title as string)) {
                            setHeddinElements((prev) =>
                              prev.filter((el: string) => el !== col.title)
                            );
                          } else {
                            setHeddinElements((prev) => [
                              ...prev,
                              String(col.title),
                            ]);
                          }
                        }}
                      >
                        {col.title}
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </Card>
            </Popover>
          }
        />

        <Flex p={3}>
          <Flex flexDirection={"row"} mb={2} style={{ gap: "10px 10px" }}>
            <Button
              loading$={isRefetchingConfigEvc}
              onClick={handleSendRefreshConfigEvcFetch}
            >
              {t("Fetch Evc Data")}
            </Button>
            {Object.keys(selectedRows).length > 0 && (
              <Button
                onClick={handleSubmit(onSubmit)}
                loading$={isPublishingConfigurationChange}
                disabled={isPublishingConfigurationChange}
              >
                {t("Save changes")}
              </Button>
            )}
          </Flex>
          <Flex
            flexDirection={"row"}
            style={{ gap: "10px 10px" }}
            justifyContent={"space-between"}
          >
            <Flex style={{ gap: "10px 10px" }}>
              <Typography variant={"caption20"} color="#737373">
                {t("Select the items you want to change and edit them")}
              </Typography>
              <Typography>
                <strong>{t("Items Selected")}: </strong>
                {Object.keys(selectedRows).length}{" "}
                {Object.keys(selectedRows).length > 1 ? t("items") : t("item")}
              </Typography>
            </Flex>
            <Flex style={{ gap: "10px 10px" }}>
              <Typography>
                <strong>{t("Last Fetch")} :</strong>
                {data?.timestamp
                  ? format(data?.timestamp, "dd/MM/yyyy HH:mm:ss")
                  : "---"}
              </Typography>
              <Typography>
                <strong>{t("Last Change")} :</strong>
                {data?.configuration?.length
                  ? format(
                      Math.max.apply(
                        null,
                        data?.configuration.map((a: any) => a.timestampChange)
                      ),
                      "dd/MM/yyyy HH:mm:ss"
                    )
                  : null}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
        <Card width={"100%"} height="100%" overflow={"hidden"}>
          <Table
            loading={loading}
            estimatedRowHeight={50}
            columns={columns as any}
            tableRef={TableRef}
            data={filteredData}
            rowKey={"key"}
            rowHeight={50}
            fixed={false}
            onColumnSort={handleSortColumn}
            sortBy={{
              key: searchParams.get(`evcConfigSort`)?.split("-")[0] as string,
              order:
                (searchParams
                  .get(`evcConfigSort`)
                  ?.split("-")[1] as SortOrder) || "asc",
            }}
          />
        </Card>
      </SectionList>
    </Flex>
  );
};
