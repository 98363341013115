import { SVGProps } from "react"
const AC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#2B9AD6"
      stroke="#fff"
      strokeWidth={2.074}
      d="M12.372 22.738c5.728 0 10.372-4.7 10.372-10.5 0-5.799-4.644-10.5-10.372-10.5S2 6.44 2 12.238c0 5.8 4.644 10.5 10.372 10.5Z"
    />
    <path
      fill="#fff"
      d="M8.845 7.157v6.111a.9.9 0 0 0 .913.924h1.888c.29 0 .477.294.373.567l-1.39 3.36c-.248.61.187 1.281.83 1.281h.27a.911.911 0 0 0 .788-.44l4.48-6.658c.353-.609-.082-1.386-.788-1.386h-2.281c-.27 0-.457-.273-.374-.546l.934-2.898c.187-.609-.25-1.197-.85-1.197h-3.86a.872.872 0 0 0-.933.882Z"
    />
  </svg>
)
export default AC
