import { gql, useLazyQuery } from "@apollo/client";

export const CONFIGURATION_FETCH = gql(`
    query ConfigurationFetch($evcUid: String!) {
      EVC(uid: $evcUid) {
        code
        Configuration{
          configuration{
            key
            readonly
            value
            timestampFetch
            timestampChange
          }
          evcUid
          fetchType
          timestamp
        }
      }
    }
`);

export const useConfigurationFetch = () => {
  const [
    getConfigurationFetch,
    { loading, error, data, refetch, updateQuery, startPolling },
  ] = useLazyQuery(CONFIGURATION_FETCH, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    initialFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    getConfigurationFetch,
    error,
    data: data?.EVC?.Configuration,
    refetch,
    startPolling,
    updateQuery,
  };
};
