import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type languageOption = "en" | "it" | string;
export type User = {
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  cpoUid: string[] | null;
};
type Props = {
  currentCpo: string | null;
  cpoUid: string[] | null;
  currentUser: User | null;
  cpoModifiedByUser: boolean;
};

const userSettings = createSlice({
  name: "userSettings",
  initialState: {
    currentCpo: "8feb988f-2876-4a45-93fe-363ba35c39f2",
    cpoUid: null,
    currentUser: null,
    cpoModifiedByUser: false,
  } as Props,
  reducers: {
    setCurrentCpo: (state, action: PayloadAction<string | null>) => {
      state.currentCpo = action.payload;
    },
    setCpoUid: (state, action: PayloadAction<string[] | null>) => {
      state.cpoUid = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload;
    },
    setCpoModifiedByUser: (state, action: PayloadAction<boolean>) => {
      state.cpoModifiedByUser = action.payload;
    },
  },
});

export const {
  setCurrentCpo,
  setCpoUid,
  setCurrentUser,
  setCpoModifiedByUser,
} = userSettings.actions;
export default userSettings.reducer;
