import { CSSProperties } from "react";
// @ts-ignore
import H from "react-hexagon";


const Hexagon = ({children, ...props}: {
  diagonal?: number;
  flatTop?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  href?: string;
  target?: string;
  hexProps?: React.HTMLAttributes<React.ReactNode>;
  backgroundImage?: string;
  backgroundWidth?: number;
  backgroundHeight?: number;
  backgroundScale?: number;
  backgroundSize?: number;
  className?: string;
  children?: React.ReactNode;
}) => (
    <H {...props} >{children}</H>
)

Hexagon.defaultProps = {
  diagonal: 500,
  flatTop: false,
  style: {},
};

export default Hexagon;