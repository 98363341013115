import { memo, useMemo } from "react";
import { Box } from "../../../../../shared/UI";
import { Bound } from "../../../@types/geojson";
import {
  ProvinceProperties,
  useSiteSelectionGeoDataContext,
} from "../../../slices/GeoDataContextProvider";
import { mapKwh } from "../../../utils/map-kwh";
import { GeoJSONLayer, GeoJSONLayerProps } from "./GeoJSONLayer";
import { useTranslation } from "react-i18next";

export type ProvinceLayerProps = {
  regionId: number | null;
} & Omit<
  GeoJSONLayerProps<
    ProvinceProperties & {
      weight: number;
      bound: Bound;
    }
  >,
  "data"
>;

const Tooltip = memo(
  ({ data }: { data: ProvinceProperties }) => {
    const { t } = useTranslation();
    const val = mapKwh(data.stats.kwh_ac + data.stats.kwh_dc);
    return (
      <Box width={"400px"}>
        {t("siteSelection.provinceLayer.province")} {data.DEN_UTS}
        <br />
        <b>{t("siteSelection.provinceLayer.statistic")}</b>
        <br />
        {data.stats && `${val.value.toFixed(2)} ${val.unit}`}
        <br />
        {data.stats && `${data.stats.n_ac} AC ${data.stats.n_dc} DC`}
      </Box>
    );
  },
  (prev, actual) => prev.data.COD_PROV === actual.data.COD_PROV
);

export const ProvinceLayer: React.FC<ProvinceLayerProps> = ({
  regionId,
  ...props
}) => {
  const { provinces } = useSiteSelectionGeoDataContext();

  const filteredFeatures = useMemo(
    () => provinces?.filter((p) => p.properties.COD_REG === regionId) ?? [],
    [provinces, regionId]
  );

  return (
    <>
      {provinces && (
        <GeoJSONLayer
          data={{ type: "FeatureCollection", features: filteredFeatures }}
          tooltip={(data) => <Tooltip data={data} />}
          {...props}
        />
      )}
    </>
  );
};
