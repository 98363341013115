import { Evc, EventMessage } from "@bepower/greta-types";
import { snakeCase } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { Box, Button, Flex, Typography } from "../../../../../shared/UI";
import { Absolute } from "../../../../../shared/UI/Absolute";
import VerticalScrollContainer from "../../../../../shared/UI/VerticalScrollContainer";
import { Close } from "../../../../../shared/assets/svg";
import { useEventMessages } from "../../../gql/queries/useGetEventMessages";
import { selectAction } from "../../../slices/infrastructure";
import { t } from "i18next";

export const EventMessages: React.FC = () => {
  const { selectedAction } = useSelector((state: any) => state.infrastructure);
  const { evc }: { evc: Evc } = useOutletContext();
  const dispatch = useDispatch();
  const {
    getEventsMessages,
    events,
    loading: loadingGetEventMessages,
  } = useEventMessages();

  useEffect(() => {
    if (selectedAction) {
      getEventsMessages({
        variables: {
          filter: {
            id: selectedAction?.id,
          },
          index: `events-stream-${snakeCase(selectedAction.stream)}`,
        },
      });
    }
  }, [getEventsMessages, selectedAction]);
  const removeSelectedAction = () => {
    dispatch(selectAction(null));
  };
  return (
    <VerticalScrollContainer>
      <Flex mb={4} fullSize>
        <Absolute top={0} right={0} m={2}>
          <Button variant="secondary" fixedSize onClick={removeSelectedAction}>
            <Close />
          </Button>
        </Absolute>
        {events?.map((event: EventMessage, index: number) => {
          return (
            <Flex p={3} key={index}>
              <Flex mb={2}>
                <Typography mb={2} variant={"title20"}>
                  Payload
                </Typography>
                <Box pl={3}>
                  <Typography upperCase fontWeight={"bold"}>
                    EVC Uid:{" "}
                  </Typography>
                  <Typography
                    clipboardText={JSON.parse(event?.data)?.evcUid}
                    color="#FF6B6B"
                  >
                    {JSON.parse(event?.data)?.evcUid}
                  </Typography>
                </Box>
                <Box pl={3}>
                  <Typography upperCase fontWeight={"bold"}>
                    keys :{" "}
                  </Typography>
                  <Typography>
                    {JSON.parse(event?.data)?.keys || "[]"}
                  </Typography>
                </Box>
              </Flex>
              <Flex flexShrink={0}>
                <Typography pb={2} variant={"title20"}>
                  Message
                </Typography>
                <Flex pl={3}>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      Timestamp :{" "}
                    </Typography>
                    <Typography color="#009688">{event?.timestamp}</Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      Causation :{" "}
                    </Typography>
                    <Typography color="#FF6B6B">{event?.causation}</Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                     {t ('correlation')} :{" "}
                    </Typography>
                    <Typography color="#FF6B6B">
                      {event?.correlation}
                    </Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      Id :{" "}
                    </Typography>
                    <Typography color="#FF6B6B">{event?.id}</Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      { t ('from') } :{" "}
                    </Typography>
                    <Typography color="#FF6B6B">{event?.from}</Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      {t('from consumer')} :{" "}
                    </Typography>
                    <Typography color="#FF6B6B">
                      {event?.fromConsumer}
                    </Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      Stream :{" "}
                    </Typography>
                    <Typography color="#FF6B6B">{event?.stream}</Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      Timeout :
                    </Typography>
                    <Typography color="#009688">{event?.timeout}</Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography upperCase fontWeight={"bold"}>
                      {t('version')} :{" "}
                    </Typography>
                    <Typography color="#FF6B6B">{event?.version}</Typography>
                  </Box>
                </Flex>
              </Flex>
              <Flex mt={2}>
                <Typography mb={2} variant={"title20"}>
                  {t('Date')}
                </Typography>
                <Box pl={3}>
                  <Typography upperCase fontWeight={"bold"}>
                    Request Uid:
                  </Typography>
                  <Typography color="#FF6B6B">
                    {JSON.parse(event?.data)?.requestUid}
                  </Typography>
                </Box>
                <Box pl={3}>
                  <Typography upperCase fontWeight={"bold"}>
                    Evc Uid:
                  </Typography>
                  <Typography color="#FF6B6B">
                    {JSON.parse(event?.data)?.evcUid}
                  </Typography>
                </Box>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </VerticalScrollContainer>
  );
};
