/** @format */

import debounce from "debounce";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Flex, InputLabel } from "../../../../shared/UI";
import { components } from "../../../../shared/UI/Select";
import MultiSelect from "../../../../shared/components/MultiSelect";
import { useGetLocations } from "../../../../shared/gql/queries/useGetLocations";
import { useTypedSelector } from "../../../../shared/redux/store";

type Props = {
  partyId?: string | null;
  canEdit: boolean;
  partyUid?: string | null;
  platformProtocol?: string | null;
};
const MultiValue = (props: any) => {
  return (
    <components.MultiValue {...props}>
      {props?.data?.label.split(":")?.[0]}
    </components.MultiValue>
  );
};
export const LocationDropdown: React.FC<Props> = ({ canEdit }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const { control, watch } = useFormContext();
  const { executeGetLocations, loading, locations } = useGetLocations();
  const selectedBusinessModel = watch("type")?.value;
  const selectedHostGroups = watch("cso");
  const { currentCpo } = useTypedSelector((state: any) => state.userSettings);

  useEffect(() => {
    executeGetLocations({
      variables: {
        filter: {
          hostGroup: selectedHostGroups.map((item: any) => item.label),
          businessModel: selectedBusinessModel,
          text: inputValue,
        },
        pagination: {
          limit: 20,
        },
      },
    });
  }, [
    currentCpo,
    executeGetLocations,
    inputValue,
    selectedBusinessModel,
    selectedHostGroups,
  ]);
  const handleChangeInput = (value: string) => {
    setInputValue(value);
  };
  return (
    <Flex flex={1} minWidth={300}>
      <InputLabel labelColor="white" children="Locations" />
      <Controller
        name="locations"
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <MultiSelect
            isSelectAll={true}
            isMulti
            closeMenuOnSelect={false}
            menuPosition="fixed"
            handleChangeInput={debounce(handleChangeInput, 300)}
            isLoading={loading}
            components={{ MultiValue }}
            isDisabled={!canEdit || !watch("type.value")}
            getOptionValue={(option: any) => option?.value}
            placeholder={loading ? "loading get locations" : "Select location"}
            onChange={(e: any) => onChange(e)}
            options={locations || []}
            // error={
            //   errors?.location ? errors.location.message || " Required " : ""
            // }
            errorProps={{
              color: "white",
              borderBottom: "1px solid red",
            }}
            {...rest}
          />
        )}
      />
    </Flex>
  );
};
