export enum OcppTriggerMessageAction {
  BootNotification = "BootNotification",
  DiagnosticsStatusNotification = "DiagnosticsStatusNotification",
  FirmwareStatusNotification = "FirmwareStatusNotification",
  Heartbeat = "Heartbeat",
  MeterValues = "MeterValues",
  StatusNotification = "StatusNotification",
}

export interface OcppTriggerMessageReq {
  requestedMessage: OcppTriggerMessageAction;
  connectorId?: number;
}

export type OcppTriggerMessageStatus = `${OcppTriggerMessageStatusEnum}`;

export enum OcppTriggerMessageStatusEnum {
  Accepted = "Accepted",
  Rejected = "Rejected",
  NotImplemented = "NotImplemented",
}

export interface OcppTriggerMessageConf {
  status: OcppTriggerMessageStatus;
}
