import { gql, useMutation } from "@apollo/client";

export const PUBLISH_FIRMWARE_UPDATE = gql`
  mutation publishFirmwareUpdate($input: FirmwareUpdatedInput!) {
    publishFirmwareUpdate(input: $input) {
      requestUid
    }
  }
`;

export const usePublishFirmwareUpdate = () => {
  const [publishFirmwareUpdate, { data, loading, error }] = useMutation<any>(
    PUBLISH_FIRMWARE_UPDATE
  );
  return {
    publishFirmwareUpdate,
    error,
    data: data?.publishFirmwareUpdate,
    loading,
  };
};
