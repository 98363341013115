import { SVGProps } from "react";
import AC from "./AC";
import DC from "./DC";
import { ResizableNumberBadgeIcon } from "./ResizableNumberBadgeIcon";

const MarkerWip = ({
  cpTot = 0,
  evcModel,
  ...props
}: SVGProps<SVGSVGElement> & { cpTot?: number; evcModel?: "AC" | "DC" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={27}
    fill="none"
    viewBox="-3 -5 32 31"
    {...props}
  >
    <g filter="url(#filter0_d_3112_4164)">
      <path
        d="M16.6619 1.01172H8.62935C6.01088 1.01172 3.88574 3.13686 3.88574 5.75534V13.693C3.88574 16.3115 6.01088 18.4366 8.62935 18.4366H10.9822L12.2851 19.9419C12.4432 20.1253 12.7278 20.1253 12.8796 19.9419L14.1825 18.4366H16.6619C19.2803 18.4366 21.4055 16.3115 21.4055 13.693V5.75534C21.4055 3.13686 19.2803 1.01172 16.6619 1.01172Z"
        fill="white"
      />
      <path
        d="M10.9819 18.5381L11.5891 18.0131L11.3487 17.7348H10.9819V18.5381ZM14.2265 18.5381V17.7348H13.8597L13.6194 18.0131L14.2265 18.5381ZM4.60018 5.6038C4.60018 3.39643 6.3901 1.60018 8.60378 1.60018V0C5.51095 0 3 2.51096 3 5.6038H4.60018ZM4.60018 13.7312V5.6038H3V13.7375H4.60018V13.7312ZM8.60378 17.7348C6.39642 17.7348 4.60018 15.9449 4.60018 13.7312H3C3 16.824 5.51095 19.335 8.60378 19.335V17.7348ZM10.9819 17.7348H8.60378V19.335H10.9819V17.7348ZM12.9046 19.5374L11.5828 18.0131L10.3747 19.063L11.6966 20.5873L12.9046 19.5374ZM12.2975 19.5374C12.4556 19.354 12.7402 19.354 12.9046 19.5374L11.6966 20.5873C12.1773 21.1376 13.0311 21.1376 13.5118 20.5873L12.2975 19.5374ZM13.6194 18.0131L12.2975 19.5374L13.5055 20.5873L14.8274 19.063L13.6194 18.0131ZM16.7312 17.7348H14.2265V19.335H16.7312V17.7348ZM20.7348 13.7312C20.7348 15.9386 18.9448 17.7348 16.7312 17.7348V19.335C19.824 19.335 22.3349 16.824 22.3349 13.7312H20.7348ZM20.7348 5.6038V13.7375H22.3349V5.6038H20.7348ZM16.7312 1.60018C18.9385 1.60018 20.7348 3.39011 20.7348 5.6038H22.3349C22.3349 2.51096 19.824 0 16.7312 0V1.60018ZM8.60378 1.60018H16.7375V0H8.60378V1.60018Z"
        fill="#122967"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3112_4164"
        x="0.480002"
        y="0"
        width="24.375"
        height="26.04"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.52" />
        <feGaussianBlur stdDeviation="1.26" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3112_4164"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3112_4164"
          result="shape"
        />
      </filter>
    </defs>

    {cpTot > 0 && (
      <g transform="translate(16,10)">
        <ResizableNumberBadgeIcon number={cpTot} />
      </g>
    )}

    {evcModel === "AC" && (
      <g transform="translate(0,-3) scale(0.5)">
        <AC />
      </g>
    )}

    {evcModel === "DC" && (
      <g transform="translate(0,-3) scale(0.5)">
        <DC />
      </g>
    )}
  </svg>
);
export default MarkerWip;
