import { memo } from "react";
import Svg from "../../UI/Svg";

type Props = {
  iconSize?: string;
  fill?: string;
  stroke?: string;
};

const SvgComponent = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={55}
    fill="none"
    viewBox="0 0 41 55"
    {...props}
  >
    <path
      fill={props.fill ?? "#122967"}
      d="M0 6.833A6.84 6.84 0 0 1 6.833 0h17.084v13.667a3.413 3.413 0 0 0 3.416 3.416H41v30.75a6.84 6.84 0 0 1-6.833 6.834H6.833A6.84 6.84 0 0 1 0 47.833v-41Zm41 6.834H27.333V0L41 13.667Z"
    />
  </Svg>
);
const FileSolid = memo(SvgComponent);
export default FileSolid;
