import { gql, useMutation } from "@apollo/client";

export const PUBLISH_REMOTE_STOP_CHARGE_TX = gql`
  mutation publishRemoteStopChargeTx($input: RemoteStopChargeTxInput!) {
    publishRemoteStopChargeTx(input: $input) {
      requestUid
    }
  }
`;

export const usePublishRemoteStopChargeTx = () => {
  const [PublishRemoteStopChargeTx, { data, loading, error }] =
    useMutation<any>(PUBLISH_REMOTE_STOP_CHARGE_TX);
  return {
    PublishRemoteStopChargeTx,
    error,
    data: data?.publishRemoteStopChargeTx,
    loading,
  };
};
