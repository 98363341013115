import { Trigger } from "@bepower/greta-types";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Button, ColumnShape, Flex } from "../../../../shared/UI";
import { FiltersList } from "../../../../shared/components/FiltersList";
import { SmartTable } from "../../../../shared/components/SmartTable";
import { useGetTriggers } from "../../../../shared/gql/queries/useGetTriggers";
import { SectionList } from "../../../../shared/layout";
import { ExcelService } from "../../../../shared/services/excelService";
import { MeterValues } from "./graphics/MeterValuesWrapper";
import { format } from "date-fns";

type Column = ColumnShape<Trigger>;

const columns: Column[] = [
  {
    key: "type",
    title: "type",
    dataKey: "type",
    width: 120,
    align: "left",
    resizable: true,
    flexGrow: 1,
    dataGetter: ({ rowData }) => {
      return rowData?.trigger?.type;
    },
  },
  {
    key: "addedAt",
    title: "added At",
    dataKey: "addedAt",
    width: 140,
    align: "left",
    resizable: true,
    flexGrow: 2,
    dataGetter: ({ rowData }) => {
    return  format(rowData?.trigger?.addedAt, "dd/MM/yyyy HH:mm:ss")
    },
  },
  {
    key: "reason",
    title: "reason",
    dataKey: "reason",
    width: 140,
    align: "left",
    resizable: true,
    flexGrow: 2,
    dataGetter: ({ rowData }) => {
      return JSON.parse(rowData?.trigger.payload)?.reason;
    },
  },
];

export const SessionTriggers = () => {
  const { getTriggers, Triggers, fetchMore, loading, refetch } =
    useGetTriggers();
  const { uid: SessionUid } = useParams();

  useEffect(() => {
    getTriggers({
      variables: {
        pagination: {
          limit: 20,
          after: null,
        },
        filter: {
          sessionUid: SessionUid,
        },
      },
    });
  }, [SessionUid, getTriggers]);
  const expandColumnKey = "label";

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const text = searchParams.get("text");
  const [showTable, setShowTable] = useState<boolean>(true);
  const handleClickRow = (row: any) => {
    // dispatch(setSelectedLocation(row));
    //  navigate(`/evcs/${row?.uid}`);
  };

  const onChangeSearchInput = (e: any) => {
    if (e.target.value) {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    } else {
      searchParams.set("text", "");
      setSearchParams(searchParams);
    }
  };

  const tableRef = React.useRef<any>();
  const handleRefresh = useCallback(() => {
    tableRef?.current?.scrollToTop(0);
    refetch();
  }, [tableRef, refetch]);

  const downloadTriggersList = () => {
    const triggers = Triggers?.map(({ trigger }) => {
      const payload = JSON.parse(trigger.payload);
      return {
        uid: trigger.uid,
        Type: trigger.type,
        AddedAt: trigger.addedAt,
        transactionUid: payload?.transactionUid,
        evcUid: payload.evcUid,
        evseUid: payload.evseUid,
        tokenUid: payload.tokenUid,
        reason: payload.reason,
        state: payload.state,
        meter: payload.meterValue,
      };
    });
    console.log(Triggers);
    if (triggers) {
      ExcelService?.exportAsExcelFile(triggers || [], "triggers");
    }
  };
  return (
    <Flex p={3} fullSize position={"relative"}>
      <SectionList width="100%" height="100%">
        <Flex flex={1}>
          {showTable ? (
            <>
              <FiltersList
                textInputProps={{
                  onChange: onChangeSearchInput,
                  defaultValue: text || "",
                }}
                onClickRefreshButton={handleRefresh}
                downloadButtonProps={{
                  onClick: downloadTriggersList,
                  loading: loading,
                }}
                extraProps={
                  <Button onClick={() => setShowTable((prev) => !prev)}>
                    {showTable
                      ? "View MeterValues Graphics"
                      : "View Triggers Table"}
                  </Button>
                }
              />
              <SmartTable
                selectedRowProps={{
                  onRowChange: handleClickRow,
                  selectedRow: "",
                }}
                tableRef={tableRef}
                fixed={false}
                expandColumnKey={expandColumnKey}
                columns={columns as any}
                onEndReached={fetchMore}
                loading={loading}
                data={Triggers || []}
              />
            </>
          ) : (
            <MeterValues setShowTable={setShowTable} />
          )}
        </Flex>
      </SectionList>
    </Flex>
  );
};
