import { SessionEvseCommand } from "./commands/SessionEvseCommand";
import { StatusEvseCommand } from "./commands/StatusEvseCommand";
import { ReservationEvseCommand } from "./commands/ReservationEvseCommand";
import { Flex } from "../../../../../shared/UI";

export const EvseCommands = () => {
  return (
    <Flex fullSize>
      <SessionEvseCommand />
      <StatusEvseCommand />
      <ReservationEvseCommand />
    </Flex>
  );
};
