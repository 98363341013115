import { gql, useMutation } from "@apollo/client";

export const PUBLISH_REMOTE_CHANGE_AVAILABILITY = gql`
  mutation publishRemoteChangeAvailability(
    $input: RemoteChangeAvailabilityInput!
  ) {
    publishRemoteChangeAvailability(input: $input) {
      requestUid
    }
  }
`;

export const usePublishRemoteChangeAvailability = () => {
  const [publishRemoteChangeAvailability, { data, loading, error }] =
    useMutation<any>(PUBLISH_REMOTE_CHANGE_AVAILABILITY);

  return {
    publishRemoteChangeAvailability,
    error,
    RemoteChangeAvailability: data?.publishRemoteChangeAvailability,
    loading,
  };
};
