import { IPlatformParty } from "./platform-party.model";
import { PlatformParty } from "@bepower/greta-types";

export enum PlatformProtocol {
  OCPI = "OCPI",
  OICP = "OICP",
}

export const platformStatusOptions = [
  {
    label: "AGREED",
    value: "AGREED",
  },
  {
    label: "ACTIVE",
    value: "ACTIVE",
  },
  {
    label: "ENDED",
    value: "ENDED",
  },
];
export interface Platform {
  id: number;
  uid: string;
  name: string;
  protocol: PlatformProtocol;
  internal: boolean;
  baseUrl: string;
  entryPointUrl: string;
  platformsParty?: IPlatformParty[] | PlatformParty[];
  status: string;
  offlineTokenIn: string;
  offlineTokenOut: string;
  pullTokens: boolean;
  needsReliablePush: boolean;
  skipCredentialsRefresh: boolean;
  asyncPush?: boolean;
  hubPartyUid?: string;
  flavor?: string;
  version: string;
  Interoperabilities: {
    total: number;
    results: any[];
  };
  HubParty?: IPlatformParty;
}
export interface IPlatform extends Platform {
  platformsParty?: IPlatformParty[];
}

export interface PlatformExtended extends Platform {
  platformsParty?: PlatformParty[];
}

export const PLATFORM_PROTOCOL_FORM_OPTIONS = [
  { label: PlatformProtocol.OCPI, value: PlatformProtocol.OCPI },
  { label: PlatformProtocol.OICP, value: PlatformProtocol.OICP },
  // { label: 'ALTRO', value: 'ALTRO' },
];
export const platformFlavor = [
  {
    label: "gireve",
    value: "gireve",
  },
];

//FIXME CREATE ENUM OPTIONS
export const platformVersion = [
  {
    label: "2.1.1",
    value: "v2_1_1",
  },
  {
    label: "2.2.1",
    value: "v2_2_1",
  },
];

export enum OcpiPlatformVersion {
  V2_1_1 = "v2_1_1",
  V2_2_1 = "v2_2_1",
}
