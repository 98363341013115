import { ExcelService } from "../../../shared/services/excelService";
import { Project } from "../@types/project";

const columns = [
"Project Id",
"Nome",
"SLA Location",
"Stato",
"Country [Scouting]",
"Region [Scouting]",
"Province [Scouting]",
"Project City [Scouting]",
"Address [Scouting]",
"Zip Cap [Scouting]",
"Google maps link [Scouting]",
"Latitude [Scouting]",
"Longitude [Scouting]",
"Number of EVC [Scouting]",
"Contractual capacity [kW] [Scouting]",
"Type Of Installation [Scouting]",
"Type Of Site [Scouting]",
"Type Of Access [Scouting]",
"Hours of access [Scouting]",
"Owner Parking Area [Scouting]",
"Third Parties Ownership [Scouting]",
"Host Group [Scouting]",
"Interoperability [Scouting]",
"Display_on_bec_app [Scouting]",
"Contact Name [Scouting]",
"Contact Phone [Scouting]",
"Contact email [Scouting]",
"External Consulting Firm Name [Scouting]",
"Commercial Fee [Scouting]",
"Backlog Date [Scouting]",
"Project Summary [Scouting]",
"È E-Hub?",
"POD Ownership (name of Company)",
"Type of area",
"Contract signature start of concession",
"Contract - Concession expiration",
"CEF 1 Required",
"CEF 2 Required",
"PNRR 1 Required",
"PNRR 2 Required",
"PNRR 3 Required",
"Model 1",
"Numero model 1",
"Model 2",
"Numero model 2",
"Model 3",
"Numero model 3",
"Valore Canone Unico",
"Development signature competence",
"Descrizione",
"Business model",
"EVC Ownership",
"Gruppo Proprietario",
"Installation deadline ",
"Test deadline ",
"Type of Contract",
]

export const downloadLocations = (project: Project) => {

  const deepserLocations: Record<(typeof columns)[number], string | number>[]= project.locations.filter(l => l.validated).map((l) => ({
    "Project Id": "",
    "Nome": `${project.name} - ${l.city} - ${l.address}`,
    "SLA Location": "",
    "Stato": "Scouting",
    "Country [Scouting]": l.country ?? "",
    "Region [Scouting]": l.region ?? "",
    "Province [Scouting]": l.province ?? "",
    "Project City [Scouting]": l.city ?? "",
    "Address [Scouting]": l.address ?? "",
    "Zip Cap [Scouting]": l.zipCode ?? "",
    "Google maps link [Scouting]": `https://www.google.com/maps/search/?api=1&query=${l.latitude},${l.longitude}`,
    "Latitude [Scouting]": l.latitude.toFixed(7),
    "Longitude [Scouting]": l.longitude.toFixed(7),
    "Number of EVC [Scouting]": l.ac+l.dc,
    "Contractual capacity [kW] [Scouting]": l.contractualCapacity ?? "",
    "Type Of Installation [Scouting]": "",
    "Type Of Site [Scouting]": "",
    "Type Of Access [Scouting]": "",
    "Hours of access [Scouting]": "",
    "Owner Parking Area [Scouting]": "",
    "Third Parties Ownership [Scouting]": "",
    "Host Group [Scouting]": "",
    "Interoperability [Scouting]": "",
    "Display_on_bec_app [Scouting]": "",
    "Contact Name [Scouting]": "",
    "Contact Phone [Scouting]": "",
    "Contact email [Scouting]": "",
    "External Consulting Firm Name [Scouting]": "",
    "Commercial Fee [Scouting]": "",
    "Backlog Date [Scouting]": "",
    "Project Summary [Scouting]": "",
    "È E-Hub?": "",
    "POD Ownership (name of Company)": "",
    "Type of area": "",
    "Contract signature start of concession": "",
    "Contract - Concession expiration": "",
    "CEF 1 Required": "",
    "CEF 2 Required": "",
    "PNRR 1 Required": "",
    "PNRR 2 Required": "",
    "PNRR 3 Required": "",
    "Model 1": "",
    "Numero model 1": "",
    "Model 2": "",
    "Numero model 2": "",
    "Model 3": "",
    "Numero model 3": "",
    "Valore Canone Unico": "",
    "Development signature competence": "",
    "Descrizione": `Info Site Selection: 
Nome Progetto: ${project.name} 
Creato da: ${project.createdBy } 
Data creazione: ${project.createdAt? new Date(project.createdAt).toISOString() : '-'} 
AC: ${l.ac} 
DC: ${l.dc} 
forecast: ${l.forecast??0} kWh/y 
    `,
    "Business model": "",
    "EVC Ownership": "",
    "Gruppo Proprietario": "",
    "Installation deadline ": "",
    "Test deadline ": "",
    "Type of Contract": "",
  } satisfies Record<(typeof columns)[number], string | number>))

  const toExport = deepserLocations.length > 0 ? deepserLocations : [Object.fromEntries(columns.map(c => [c, ""])) ]

    return ExcelService?.exportAsExcelFile(
      toExport,
        project?.name
      );

}