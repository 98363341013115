/** @format */

import {
  AddSiteSelectionLocationInput,
  DeleteSiteSelectionLocationInput,
} from "@bepower/greta-types";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../shared/messages/messages";
import { AccessType, Location, Project, Visibility } from "../@types/project";
import { useUpdateSiteSelectionProject } from "../graphql/useAddSiteSelectionProject";
import { useGetSiteSelectionProject } from "../graphql/useGetSiteSelectionProject";
import {
  useAddSiteSelectionLocation,
  useDeleteSiteSelectionLocation,
  useUpdateSiteSelectionLocation,
} from "../graphql/useSiteSelectionLocation";
import { useCurrentUser } from "./useCurrentUser";

const definedOrDefault = (value: any, defaultValue: any) =>
  value === undefined ? defaultValue : value;

export const useProject = (projectId: string | null = null) => {
  const {
    siteSelectionProject: project,
    getSiteSelectionProject,
    refetch,
  } = useGetSiteSelectionProject();

  const user = useCurrentUser();

  const { updateSiteSelectionProject, loading: updateLoading } =
    useUpdateSiteSelectionProject();
  const { addSiteSelectionLocation } = useAddSiteSelectionLocation();

  const { updateSiteSelectionLocation } = useUpdateSiteSelectionLocation();

  const { deleteSiteSelectionLocation } = useDeleteSiteSelectionLocation();

  useEffect(() => {
    if (projectId) {
      getSiteSelectionProject({
        variables: {
          uid: projectId,
        },
      }).then((res) => {
        if (res.data.SiteSelectionProject === null) {
          setTimeout(() => {
            getSiteSelectionProject({
              variables: {
                uid: projectId,
              },
            });
          }, 500);
        }
      });
    }
  }, [projectId, getSiteSelectionProject]);

  const updateProject = useCallback(
    async (
      project: Project | null,
      data: Partial<
        Pick<Project, "name" | "accessType" | "type" | "visibility" | "status">
      >
    ) => {
      if (!project) return;

      const updated = {
        uid: project.id,
        name: definedOrDefault(data.name, project.name),
        accessType: definedOrDefault(data.accessType, project.accessType),
        visibility: definedOrDefault(data.visibility, project.visibility),
        status: definedOrDefault(data.status, project.status),
        type: definedOrDefault(data.type, project.type),
      };

      return updateSiteSelectionProject({
        variables: {
          input: updated,
        },
      });
    },
    [updateSiteSelectionProject]
  );

  const updateWithRefetch = useCallback(
    async (
      data: Partial<
        Pick<Project, "name" | "accessType" | "type" | "visibility" | "status">
      >
    ) => {
      const response = await updateProject(project, data);
      if (response?.data) {
        toast.success(MESSAGES.SITE_SELECTION.success.editProject);
        setTimeout(() => refetch(), 1000);
      }
    },
    [project, refetch, updateProject]
  );

  const isFavorite = () => !!project && project.favoriteBy.includes(user.id);

  const renameProject = (name: string) => updateWithRefetch({ name });

  const updateAccessTypeAndVisibility = (
    accessType: AccessType,
    visibility: Visibility
  ) => updateWithRefetch({ accessType, visibility });

  const addLocation = (
    location: Omit<Location, "id" | "createdAt" | "updatedAt" | "createdBy">
  ) => {
    if (!project) return;

    return addSiteSelectionLocation({
      variables: {
        input: {
          ac: location.ac,
          address: location.address,
          city: location.city ?? "",
          country: location.country ?? "",
          dc: location.dc,
          hex: location.hex ?? "",
          latitude: location.latitude,
          longitude: location.longitude,
          projectUid: project.id,
          province: location.province ?? "",
          region: location.region ?? "",
          validated: location.validated ?? null,
          contractualCapacity: location.contractualCapacity ?? 0,
          zipCode: location.zipCode ?? "",
          forecast: location.forecast ?? null,
        } satisfies AddSiteSelectionLocationInput & {
          zipCode: string;
          forecast: number | null;
        },
      },
    })
      .then((res) => {
        if (res?.data) {
          toast.success(MESSAGES.SITE_SELECTION.success.editProject);
          setTimeout(() => refetch(), 1000);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addLocations = (
    projectUid: string,
    locations: Omit<Location, "id" | "createdAt" | "updatedAt" | "createdBy">[]
  ) => {
    return Promise.all(
      locations.map((location) =>
        addSiteSelectionLocation({
          variables: {
            input: {
              ac: location.ac,
              address: location.address,
              city: location.city ?? "",
              country: "Italy",
              dc: location.dc,
              hex: location.hex ?? "",
              latitude: location.latitude,
              longitude: location.longitude,
              projectUid,
              province: location.province ?? "",
              region: location.region ?? "",
              validated: location.validated ?? null,
              contractualCapacity: location.contractualCapacity ?? 0,
              zipCode: location.zipCode ?? "",
              forecast: location.forecast ?? null,
            } satisfies AddSiteSelectionLocationInput & {
              zipCode: string;
              forecast: number | null;
            },
          },
        })
      )
    )
      .then((res) => {
        toast.success(MESSAGES.SITE_SELECTION.success.editProject);
        setTimeout(() => refetch(), 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const updateLocation = async (
    id: string,
    data: Partial<Omit<Location, "id">>,
    withNotification: boolean = true
  ) => {
    if (!project) return;
    const l = project?.locations?.find((l) => l.id === id);
    if (!l) return;

    return updateSiteSelectionLocation({
      variables: {
        input: {
          address:
            data.address?.replaceAll(/'/g, "") ??
            l.address?.replaceAll(/'/g, "") ??
            null,
          contractualCapacity:
            data.contractualCapacity ?? l.contractualCapacity ?? null,
          uid: id,
          validated:
            data.validated === undefined ? l.validated : data.validated ?? null,
          zipCode: data.zipCode ?? l.zipCode ?? "",
          ac: data.ac ?? l.ac ?? null,
          dc: data.dc ?? l.dc ?? null,
          country: data.country ?? l.country ?? "Italy",
          region: data.region ?? l.region ?? "",
          province: data.province ?? l.province ?? "",
          city: data.city ?? l.city ?? "",
          hex: data.hex ?? l.hex ?? "",
          forecast: data.forecast ?? l.forecast ?? 0,
        }, //satisfies UpdateSiteSelectionLocationInput,
      },
    }).then((res) => {
      if (withNotification && res?.data) {
        toast.success(MESSAGES.SITE_SELECTION.success.editProject);
        setTimeout(() => refetch(), 1000);
      }
    });
  };

  const deleteLocation = (id: string, withNotification = true) => {
    if (!project) return;

    return deleteSiteSelectionLocation({
      variables: {
        input: {
          uid: id,
        } satisfies DeleteSiteSelectionLocationInput,
      },
    }).then((res) => {
      if (withNotification && res?.data) {
        toast.success(MESSAGES.SITE_SELECTION.success.editProject);
        setTimeout(() => refetch(), 1000);
      }
    });
  };

  const deleteAllLocations = () => {
    if (!project) return Promise.resolve();

    return Promise.all(
      project.locations.map((l) => deleteLocation(l.id, false))
    );
  };

  const setLocationValidation = (id: string, validated: boolean | null) => {
    updateLocation(id, { validated });
  };

  return {
    project,
    updateProject,
    addLocations,
    updateLoading,
    isFavorite,
    renameProject,
    updateAccessTypeAndVisibility,
    addLocation,
    updateLocation,
    deleteLocation,
    setLocationValidation,
    deleteAllLocations,
    refetch,
  };
};
