import { gql, useLazyQuery } from "@apollo/client";

export const UNLOCK_CONNECTOR_PROCESSED = gql`
  query UnlockConnectorProcessed(
    $filter: RemoteCommandFilterParams
    $pagination: PaginationParams
    $sort: SortingParams
  ) {
    UnlockConnectorProcessed(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      results {
        __typename
        status
        info
        timestamp
        requestUid
        request {
          requestUid
          transactionUid
        }
      }
    }
  }
`;
export const useUnlockConnectorProcessed = () => {
  const [
    getUnlockConnectorProcessed,
    { loading, error, data, refetch, updateQuery, networkStatus, startPolling },
  ] = useLazyQuery(UNLOCK_CONNECTOR_PROCESSED, {
    fetchPolicy: "no-cache", // Used for first execution
    nextFetchPolicy: "no-cache", // Used for subsequent executions
    initialFetchPolicy: "no-cache",
  });

  return {
    loading,
    getUnlockConnectorProcessed,
    error,
    unlockConnectorProcessed: data?.UnlockConnectorProcessed?.results,
    refetch,
    startPolling,
    networkStatus,
    updateQuery,
  };
};
