import { useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  ColumnShape,
  Flex,
  SimpleLoader,
  Typography,
} from "../../../../../shared/UI";
import { useGetEVSEAccessGroups } from "../../../gql/queries/useGetEVSEAccessGroups";
import { SectionList } from "../../../../../shared/layout";
import { SmartTable } from "../../../../../shared/components/SmartTable";
import { EvseAccessGroup } from "@bepower/greta-types";
import { Box } from "../../../../../shared/UI/Box";
import { usePublishEvseAccessGroupAssociation } from "../../../gql/mutations/usePublishEvseAccessGroupAssociation";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FiltersList } from "../../../../../shared/components/FiltersList";
import React from "react";

type Column = ColumnShape<EvseAccessGroup>;

export const checkedMap: { [key: string]: boolean } = {};
export const updatingMap: { [key: string]: boolean } = {};

const CellWithCheckbox = ({
  column,
  rowData,
}: {
  column: EvseAccessGroup;
  rowData: EvseAccessGroup;
}) => {
  const { uid: evseUid } = useParams();
  const [isLoadingPublishAccessgroup, setIsLoadingPublishAccessGroup] =
    useState<boolean>(false);
  const { data, publishEvseAccessGroupAssociation, loading } =
    usePublishEvseAccessGroupAssociation();
  const { evse }: any = useOutletContext();
  const handleChange = (e: any) => {
    checkedMap[rowData.uid] = e.target?.checked;
    updatingMap[rowData.uid] = true;
    setIsLoadingPublishAccessGroup(true);
    publishEvseAccessGroupAssociation({
      variables: {
        input: {
          accessGroupUid: rowData?.uid,
          evseUid: evseUid,
          status: e.target?.checked ? "ACTIVE" : "REMOVED",
        },
      },
    })
      .then((res) => {
        toast.success(
          e.target?.checked
            ? `EVSE ${evse.label} added`
            : `EVSE ${evse.label} removed`
        );
        updatingMap[rowData.uid] = false;
        setIsLoadingPublishAccessGroup(false);
      })
      .catch(() => {
        updatingMap[rowData.uid] = false;
        setIsLoadingPublishAccessGroup(false);
      });
  };
  return isLoadingPublishAccessgroup ? (
    <SimpleLoader />
  ) : (
    <Checkbox
      disabled={updatingMap[rowData.uid]}
      checked={checkedMap[rowData.uid]}
      onChange={handleChange}
    />
  );
};
const defaultColumns: Column[] = [
  {
    key: "chec",
    title: "",
    dataKey: "chec",
    flexGrow: 0,
    width: 95,
    align: "left",
    resizable: false,
    cellRenderer: ({ column, rowData }: any) => {
      return (
        <Box onClick={(e: any) => e.stopPropagation()}>
          <CellWithCheckbox column={column} rowData={rowData} />
        </Box>
      );
    },
  },
  {
    key: "name",
    title: "role",
    dataKey: "name",
    width: 120,
    align: "left",
    flexGrow: 2,

    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography fontSize={10} fontWeight="bold">
          {rowData?.name}
        </Typography>
      );
    },
  },

  {
    key: "description",
    title: "description",
    dataKey: "description",
    flexGrow: 3,
    width: 120,
    resizable: true,
    dataGetter: ({ rowData }) => {
      return (
        <Typography fontSize={10} fontWeight="bold">
          {rowData?.description}
        </Typography>
      );
    },
  },
];
export const EvseAccessGroups = () => {
  const [columnKeys, setColumnKeys] = useState<string[]>(
    defaultColumns.map((column) => String(column.key))
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const text = searchParams?.get("text");
  const { EvseAccessGroups, fetchMore, loading, getEvseAccessGroups, refetch } =
    useGetEVSEAccessGroups();
  const columns = defaultColumns.filter((column) =>
    columnKeys.some((key) => key === String(column.key))
  );
  const { evse }: any = useOutletContext();
  useEffect(() => {
    evse?.AccessGroupAssociation?.results.map((association: any) => {
      if (association.status === "ACTIVE") {
        checkedMap[association.accessGroupUid] = true;
      }
    });
  }, [evse, evse?.AccessGroupAssociation?.results]);
  useEffect(() => {
    getEvseAccessGroups({
      variables: {
        pagination: {
          limit: 20,
          after: null,
        },
        filter: {
          text: text,
        },
      },
    });
  }, [getEvseAccessGroups, text]);

  const onChangeSearchInput = (e: any) => {
    if (e.target.value) {
      searchParams.set("text", e.target.value);
      setSearchParams(searchParams);
    } else {
      searchParams.set("text", "");
      setSearchParams(searchParams);
    }
  };

  const tableRef = React.useRef<any>();
  const handleRefresh = useCallback(() => {
    tableRef?.current?.scrollToTop(0);
    refetch();
  }, [tableRef, refetch]);
  return (
    <SectionList width={"100%"}>
      <FiltersList
        textInputProps={{
          onChange: onChangeSearchInput,
          defaultValue: text || "",
        }}
        onClickRefreshButton={handleRefresh}
      />
      <SmartTable
        selectedRowProps={{
          selectedRow: "",
          onRowChange: () => null,
        }}
        data={EvseAccessGroups}
        columns={columns as any}
        loading={loading}
        onEndReached={fetchMore}
        ref={tableRef}
      />
    </SectionList>
  );
};
