export const mapKwh = (kwh: number) => {
  if (kwh < 1000) {
    return {
      value: kwh,
      unit: "kWh",
    };
  }

  if (kwh < 1000000) {
    return {
      value: kwh / 1000,
      unit: "MWh",
    };
  }

  return {
    value: kwh / 1000000,
    unit: "GWh",
  };
};
