/** @format */

import { useEffect, useState } from "react";
import { MultiCheckboxFilter } from "../../../../../shared/components/table-filter/MultiCheckboxFilter";
import { useGetAggregationEVCs } from "../../../gql/queries/useGetAggrigationEvcs";
import { ColumnShape } from "../../../../../shared/UI";
import { EvcAggregableField } from "../../../../../shared/gql/generated/graphql";

type Props = {
  field: EvcAggregableField;
  column: ColumnShape<any>;
};

export const EvcFilter: React.FC<Props> = ({ field, column }) => {
  const { data, loading, fetchMore, count, getEvcsAggrField } =
    useGetAggregationEVCs();
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    getEvcsAggrField({
      variables: {
        field: field,
        pagination: {
          limit: 1000,
        },
        filter: {
          text: inputValue,
        },
      },
    });
  }, [field, getEvcsAggrField, inputValue]);
  return (
    <MultiCheckboxFilter
      loading={loading}
      searchValue={`Evc-${field}`}
      options={data?.map(({ key }: { key: string }) => key) || []}
      column={column}
      inputValue={inputValue}
      setInputValue={setInputValue}
      count={count || 0}
    />
  );
};
