import React from "react";
import { useCallback } from "react";
import { ColumnShape, Flex, Table, TableProps } from "../UI";
import { Pagination } from "../UI/Pagination";

type Column = ColumnShape<any>;
type Props = {
  data?: any[];
  loading?: boolean;
  selectedRowProps: {
    selectedRow: string;
    onRowChange: (row: any) => void;
  };
  paginationProps?: {
    onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
    page: number;
    count: number;
    limit?: number;
    extraProps?: any;
    size?: any;
    padding?: number;
  };
  ExtraProps?: React.Component;
} & Partial<TableProps>;

export const SmartTable: React.FC<Props> = ({
  data,
  loading,
  columns,
  selectedRowProps,
  paginationProps,
  ...props
}) => {
  const handleClickRow = useCallback(
    ({ rowData }: Partial<Column>) => {
      selectedRowProps.onRowChange(rowData);
    },
    [selectedRowProps]
  );

  return (
    <Flex
      bg="white"
      flex={1}
      width="100%"
      maxWidth="100%"
      overflow="hidden"
      height="100%"
    >
      <Flex flex={1} width="100%" position={"relative"}>
        <Table<any>
          data={data || []}
          columns={columns as any}
          loading={loading}
          loadingMore={false}
          selectedKey={"uid"}
          rowKey={"uid"}
          selectedValue={selectedRowProps?.selectedRow}
          rowEventHandlers={{ onClick: handleClickRow }}
          {...props}
        />
      </Flex>
      {paginationProps && (
        <Flex
          justifyContent="space-between"
          flexDirection="row"
          p={paginationProps?.padding || 3}
          style={{ alignItems: "center" }}
        >
          <Pagination
            page={paginationProps?.page}
            onPageChange={paginationProps?.onPageChange}
            limit={paginationProps?.limit}
            total={paginationProps?.count || 0}
            $fill={false}
            $variant="secondary"
            $fixedSize
            $shape="default"
            // maxPage={3}
            hideFirstButton
            hideLastButton
            hideNextButton
            hidePrevButton
          />
          {paginationProps.extraProps}
        </Flex>
      )}
    </Flex>
  );
};
